import API from "../../../../global/api";

export const AddRequestUsers = () => {
    return {
      type: "ADD_REQUEST_USERS",
    };
  };
  export const AddSuccessUsers = (data) => {
    return {
      type: "ADD_SUCCESS_USERS",
      payload: data,
    };
  };
  export const AddErrorUsers = () => {
    return {
      type: "ADD_ERROR_USERS",
    };
  };
  export const addUser = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(AddRequestUsers());
        API.post(`/users/register`, data).then((res) => {
            handleVisible(res.data.message);
            let getData = res.data;
            dispatch(AddSuccessUsers(getData));
        }).catch((error) => {
            handleDangerVisible(error.response.data.message);
            dispatch(AddErrorUsers());
        })
    };
};
  export const postUsersDetails = (data) => {
    console.log(data,"datatttt")
    const admin_id=localStorage.getItem("adminId")
    return (dispatch) => {
      dispatch(AddRequestUsers());
      API.post(`/users/register`, {
        name:data.name,
        username:data.username,
        password:data.password,
        admin_id:admin_id,
      }).then((res) => {   
        let data1 = res.data;
        dispatch(AddSuccessUsers(data1));
      }).catch((error)=> 
      dispatch(AddErrorUsers()));
    };
  };


  export const UpdateRequestUsers = () => {
    return {
      type: "UPDATE_REQUEST_USERS",
    };
  };
  export const UpdateSuccessUsers = (data) => {
    return {
      type: "UPDATE_SUCCESS_USERS",
      payload: data,
    };
  };
  export const UpdateErrorUsers = () => {
    return {
      type: "UPDATE_ERROR_USERS",
    };
  };

  export const updateUser= (data, oldData) => {
    return dispatch => {
    dispatch( UpdateRequestUsers());
    API.put(`/users/update-user`,
     {
    user_id: oldData.user_id, 
    password: data.password,
    last_name: data.last_name,
    first_name: data.first_name,
    user_name: data.user_name,
    }, {
  })
  .then(res => {
      dispatch(UpdateSuccessUsers([data]))
  }).catch((error)=> 
  dispatch(UpdateErrorUsers()));
  }
  };

  export const DeleteRequestUsers = () => {
    return {
      type: "DELETE_REQUEST_USERS",
    };
  };
  export const DeleteSuccessUsers = (user_id) => {
    return {
      type: "DELETE_SUCCESS_USERS",
      user_id: user_id,
    };
  };
  export const DeleteErrorUsers = () => {
    return {
      type: "DELETE_ERROR_USERS",
    };
  };

  export const deleteUser= (user_id) => {
    return dispatch => {
    dispatch( DeleteRequestUsers());
    API.delete(`/users/${user_id}`)
  .then(res => {
      let data = [];
      data =res.data;
      dispatch(DeleteSuccessUsers(user_id))
      dispatch(getUsers())
  }).catch((error)=> 
  dispatch(DeleteErrorUsers()));
}
};

const recieveUsers = (data) =>( {
  type : 'GET_SUCCESS_USERS',
  payload : data
});

export const getUsers= () => {
  return dispatch => {
  API.get(`/users/get-users`)
.then(res => {
    let data = [];
    data =res.data;
    dispatch(recieveUsers(data))
})
}
};

export const selectedUser= (data) =>( {
  type : 'SUCCESS_SELECTED_USER',
  data: data
});

export const RequestselectedUser= () =>( {
  type : 'REQUEST_SELECTED_USER',
});

export const ErrorselectedUser= () =>( {
  type : 'ERROR_SELECTED_USER',
});


export const AddRequestVendors = () => {
    return {
      type: "ADD_REQUEST_VENDORS",
    };
  };
  export const AddSuccessVendors = (data) => {
    return {
      type: "ADD_SUCCESS_VENDORS",
      payload: data,
    };
  };
  export const AddErrorVendors = () => {
    return {
      type: "ADD_ERROR_VENDORS",
    };
  };

  export const postVendorsDetails = (data) => {
    return (dispatch) => {
      dispatch(AddRequestVendors());
      API.post(`/vendor/register`, data).then((res) => {   
        //let data1 = res.data;
        dispatch(getVendorsDetails());
        dispatch(AddSuccessVendors(data));
      }).catch((error)=> 
      dispatch(AddErrorVendors()));
    };
  };


  export const getRequestVendors = () => {
    return {
      type: "GET_REQUEST_VENDORS",
    };
  };
  export const getSuccessVendors = (data) => {
    return {
      type: "GET_SUCCESS_VENDORS",
      payload: data,
    };
  };
  export const getErrorVendors = () => {
    return {
      type: "GET_ERROR_VENDORS",
    };
  };

  export const getVendorsDetails = () => {
    return (dispatch) => {
      dispatch(getRequestVendors());
      API.get(`/vendor/get`).then((res) => {   
        let data1 = res.data;
        dispatch(getSuccessVendors(data1));
      }).catch((error)=> 
      dispatch(getErrorVendors()));
    };
  };

  export const requestDeleteVendors = () => {
    return {
        type: "REQUEST_DELETE_VENDORS",
    };
};
export const successDeleteVendors = (vendorId) => {
    return {
        type: "SUCCESS_DELETE_VENDORS",
        vendorId: vendorId,
    };
};
export const errorDeleteVendors = () => {
    return {
        type: "ERROR_DELETE_VENDORS",
    };
};

export const deleteVendors = (vendorId) => {
    return dispatch => {
        dispatch(requestDeleteVendors());
        API.delete(`/vendor/delete/${vendorId}`)
            .then(res => {
                //   let data = [];
                //   data =res.data;
                dispatch(getVendorsDetails());
                dispatch(successDeleteVendors(vendorId))
            }).catch((error) =>
                dispatch(errorDeleteVendors()));
    }
};



export const UpdateRequestVendors = () => {
  return {
      type: "UPDATE_REQUEST_VENDORS",
  };
};
export const UpdateSuccessVendors = (data) => {
  return {
      type: "UPDATE_SUCCESS_VENDORS",
      payload: data,
  };
};

export const UpdateErrorVendors = () => {
  return {
      type: "UPDATE_ERROR_VENDORS",
  };
};

export const updateVendors = (data, oldData) => {
  console.log(data, "dataaaa");
  return dispatch => {
      dispatch(UpdateRequestVendors());
      API.put(`/vendor/update_vendor/${oldData.vendorId}`,
          {
            firstName: data.firstName,
            lastName: data.lastName,
            username: data.username,
            businessName: data.businessName,
            companyName: data.companyName,
            phone: data.phone,
            email: data.email
          }, {
      })
          .then(res => {
              dispatch(UpdateSuccessVendors([data]))
          }).catch((error) =>
              dispatch(UpdateErrorVendors()));
  }
};

export const requestAddInventory = () => {
  return {
      type: "REQUEST_ADD_INVENTORY",
  };
};
export const successAddInventory = (data) => {
  return {
      type: "SUCCESS_ADD_INVENTORY",
      payload: data,
  };
};
export const errorAddInventory = () => {
  return {
      type: "ERROR_ADD_INVENTORY",
  };
};

export const addInventory = (data) => {
  return (dispatch) => {
      dispatch(requestAddInventory());
      API.post(`/inventory/add-inventory`, data).then((res) => {
          //let getData = res.data;         
          dispatch(getInventory());
          dispatch(successAddInventory(data));
      }).catch((error) => {
          dispatch(errorAddInventory());
      })
  }
};
export const requestGetInventory = () => {
  return {
      type: "REQUEST_GET_INVENTORY",
  };
};
export const successGetInventory = (data) => {
  return {
      type: "SUCCESS_GET_INVENTORY",
      payload: data,
  };
};
export const errorGetInventory = () => {
  return {
      type: "ERROR_GET_INVENTORY",
  };
};

export const getInventory = () => {
  return (dispatch) => {
      dispatch(requestGetInventory());
      API.get(`/inventory/all-inventory`).then((res) => {
          let getData = res.data;
          //dispatch(getsubCategoryData());
          dispatch(successGetInventory(getData));
      }).catch((error) => {
          dispatch(errorGetInventory());
      })
  };
};
export const requestDeleteInventory = () => {
  return {
      type: "REQUEST_DELETE_INVENTORY",
  };
};
export const successDeleteInventory = (user_id) => {
  return {
      type: "SUCCESS_DELETE_INVENTORY",
      user_id: user_id,
  };
};
export const errorDeleteInventory = () => {
  return {
      type: "ERROR_DELETE_INVENTORY",
  };
};

export const deleteInventory = (inventoryId) => {
  return dispatch => {
      dispatch(requestDeleteInventory());
      API.delete(`/inventory/delete-inventory/${inventoryId}`)
          .then(res => {
              //   let data = [];
              //   data =res.data;
              dispatch(getInventory());
              dispatch(successDeleteInventory(inventoryId))
          }).catch((error) =>
              dispatch(errorDeleteInventory()));
  }
};
export const UpdateRequestInventory = () => {
  return {
      type: "UPDATE_REQUEST_Inventory",
  };
};
export const UpdateSuccessInventory = (data) => {
  return {
      type: "UPDATE_SUCCESS_Inventory",
      payload: data,
  };
};

export const UpdateErrorInventory = () => {
  return {
      type: "UPDATE_ERROR_Inventory",
  };
};

export const updateInventory = (data, oldData) => {
  return dispatch => {
      dispatch(UpdateRequestInventory());
      API.put(`/inventory/update-inventory/${oldData.inventoryId}`,
          {
              companyName: data.companyName,
              productName: data.productName,
              productPrice: data.productPrice,
              arrival_date: data.arrival_date,
              VendorName: data.VendorName,
              invertoryDesc: data.invertoryDesc,
              currentBlnc: data.currentBlnc,
              minBlnc: data.minBlnc,
              maxBlnc: data.maxBlnc,
              wareHouseId: data.wareHouseId,
              categoryId: data.categoryId,
              subCategoryId: data.subCategoryId
          }, {
      })
          .then(res => {
              dispatch(UpdateSuccessInventory([data]))
          }).catch((error) =>
              dispatch(UpdateErrorInventory()));
  }
};
export const requestChangePassword = () => {
  return {
    type: "REQUEST_CHANGE_PASSWORD",
  };
};
export const successChangePassword = (data) => {
  return {
    type: "SUCCESS_CHANGE_PASSWORD",
    payload: data,
  };
};
export const errorChangePassword= () => {
  return {
    type: "ERROR_CHANGE_PASSWORD",
  };
};

export const changePassword = (id,data,handleVisible,handleDangerVisible) => {
  return (dispatch) => {
    dispatch(requestChangePassword());
    API.put(`users/change_passward/${id}`, data).then((res) => {   
      //let data1 = res.data;
      // dispatch(getVendorsDetails());
      dispatch(successChangePassword(data));
      handleVisible()
    }).catch((error)=> {
    dispatch(errorChangePassword());
    handleDangerVisible()})
  };
};

export const updateRequestUsersStatus = () => {
  return {
    type: "UPDATE_REQUEST_USERS_STATUS",
  };
};
export const updateSuccessUsersStatus = (user_id) => {
  return {
    type: "UPDATE_SUCCESS_USERS_STATUS",
    user_id: user_id,
  };
};
export const updateErrorUsersStatus = () => {
  return {
    type: "UPDATE_ERROR_USERS_STATUS",
  };
};

export const updateUsersStatus= (user_id,data) => {
  return dispatch => {
  dispatch( updateRequestUsersStatus());
  API.put(`/users/active_or_inactive/${user_id}`,data)
.then(res => {
    let data = [];
    data =res.data;
    dispatch(updateSuccessUsersStatus(user_id))
    dispatch(getUsers())
}).catch((error)=> 
dispatch(updateErrorUsersStatus()));
}
};