import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import {isNull } from "lodash";
import AddMenu from "./AddMenu";
import MenuList from "./MenuList";

const TotalMenuData = (props) => {
    const permissions = JSON.parse(localStorage.getItem("permissions"));

    return (
        <>
            <Tabs defaultActiveKey={!isNull(permissions) && permissions.some(p => p['sub_id'] === 90) ?
                    "AddMenu" : !isNull(permissions) && permissions.some(p => p['sub_id'] === 91) ?
                        "MenuList" :  ""} id="AddMenu" className="mb-3">
                <Tab eventKey="AddMenu" title="Add Menu"
                disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 90) ? false : true}>  
                   <AddMenu />
                </Tab>
                <Tab eventKey="MenuList" title="Menu List"
               disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 91) ? false : true}> 
                    <MenuList/>
                </Tab>
            </Tabs>
        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
    };
};
const mapStateToProps = (state, ownProps) => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TotalMenuData);