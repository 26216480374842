import React, { useEffect, useState, forwardRef } from "react";
import {
  Row,
  Col,
  Form,
  Container,
  Button,
  Modal,
  Alert,
} from "react-bootstrap";
import "./add-details.css";
import {
  getWarehouseData,
  deleteWareHouse,
  updateWareHouse,
  getMasterControl,
} from "./AddDetails.actions";
import { connect } from "react-redux";
import { isEmpty, isNull, isUndefined } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";

const MasterControlTableList = (props) => {
  const wareHouseData = props.masterControlList;

  useEffect(() => {
    props.getMasterControl();
  }, []);

  return (
    <>
      {props.isFetchingmasterControlList ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <MaterialTable
              title="Master Control Details"
              columns={[
                { title: "Master Control Name", field: "unit" },
                { title: "Master Control  Desc", field: "desc" },
              ]}
              data={wareHouseData}
              options={{
                actionsColumnIndex: -1,
                filtering: true,
                exportButton: true,
                exportAllData: true,
                paging: true,
                pageSize: 10, // make initial page size
                emptyRowsWhenPaging: false, // To avoid of having empty rows
                pageSizeOptions: [10, 20, 50], // rows selection options
                maxBodyHeight: "600px",
                headerStyle: {
                  position: "sticky",
                  top: 0,
                  color: "#00BBBB",
                  fontWeight: "550",
                  onRowAdd: "none",
                },
              }}
              // editable={{
              //   onRowUpdate: (newData, oldData) =>
              //     new Promise((resolve) => {
              //       setTimeout(() => {
              //         resolve();
              //         {
              //           props.updateWareHouse(newData, oldData)
              //         }
              //       }, 600);
              //     }),
              //   onRowDelete: (oldData) =>
              //     new Promise((resolve) => {
              //       setTimeout(() => {
              //         resolve();
              //         props.deleteWareHouse(oldData.wareHouseId);
              //       }, 600);
              //     }),
              // }}
            />
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getMasterControl: () => dispatch(getMasterControl()),
    // deleteWareHouse: (wareHouseId) => dispatch(deleteWareHouse(wareHouseId)),
    // updateWareHouse: (newData, oldData) => dispatch(updateWareHouse(newData, oldData)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  masterControlList: state.AddDataReducer.masterControlList,
  isFetchingmasterControlList: state.AddDataReducer.isFetchingmasterControlList,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MasterControlTableList);
