import React, { useState } from "react";
import { Container, Form, Row, Col, Button, FormGroup } from "react-bootstrap";
import {
  addVisitorMarquee,
  deleteVisitor,
  getCustomerMarquee,
  getVisitorMarquee,
  UpdateVisitor,
} from "./MarqueeDetails.actions";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import { useEffect } from "react";
import Swal from "sweetalert";
import { isEmpty, find } from "lodash";
import Select from "react-select";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { IconButton } from "@material-ui/core";
import jsPDF from "jspdf";
import logo from "../../../../../Images/logo.png";
const AddVisitorMarquee = (props) => {
  const [customerName, setCustomerName] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [customerCNIC, setCustomerCNIC] = useState("");
  const [customerAddress, setCustomerAddress] = useState("");
  const [visitorPurpose, setVisitorPurpose] = useState("");
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
  const [submit, setSubmit] = useState(false);
  function uploadCustomer() {
    props.addVisitorMarquee(
      {
        customer_name: customerName,
        customer_phone: customerPhone,
        customer_address: customerAddress,
        customer_email: customerEmail,
        customer_cnic: customerCNIC,
        visitors_purpose: visitorPurpose,
      },
      handleVisible,
      handleDangerVisible
    );
  }
  const handleVisible = () => {
    setAlertVisible(true);
    clearStates();
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: "Added successfully...",
      icon: "success",
      button: "Ok",
    });
  };
  const handleDangerVisible = () => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: "Something went wrong...",
      icon: "error",
      button: "Ok",
    });
  };
  const clearStates = () => {
    setCustomerAddress("");
    setCustomerCNIC("");
    setCustomerEmail("");
    setCustomerName("");
    setCustomerPhone("");
    setSubmit(false);
  };
  /* const [state, setState] = React.useState({
        columns: [
            { title: 'Visitor Name', field: 'customer_name' },
            { title: 'Visitor Phone', field: 'customer_phone' },
            { title: 'Visitor Purpose', field: 'visitors_purpose' },
        ],
    });*/
  const VisitorData = isEmpty(props.visitorMarqueeList)
    ? []
    : props.visitorMarqueeList.reverse();
  useEffect(() => {
    props.getVisitorMarquee(1);
    // props.getMasterControl(1)
  }, []);
  return (
    <>
      <Container fluid>
        <Form>
          <Row>
            <Col lg="6" md="12" xs="12">
              <Form.Group className="mb-3" controlId="garana">
                <Form.Label>
                  <b>Enter Visitor Name *</b>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Visitor Name...."
                  value={customerName}
                  onChange={(e) => setCustomerName(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col lg="6" md="12" xs="12">
              <Form.Group className="mb-3" controlId="garana">
                <Form.Label>
                  <b>Enter Visitor Phone *</b>
                </Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Visitor Phone...."
                  value={customerPhone}
                  onChange={(e) => setCustomerPhone(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg="6" md="12" xs="12">
              <Form.Group className="mb-3" controlId="garana">
                <Form.Label>
                  <b>Enter Visitor Email :</b>
                </Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Visitor Email...."
                  value={customerEmail}
                  onChange={(e) => setCustomerEmail(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col lg="6" md="12" xs="12">
              <Form.Group className="mb-3" controlId="garana">
                <Form.Label>
                  <b>Enter Visitor CNIC *</b>
                </Form.Label>
                <Form.Control
                  type="number"
                  minLength={13}
                  maxLength={13}
                  placeholder="Visitor CNIC without dashes...."
                  value={customerCNIC}
                  onChange={(e) => setCustomerCNIC(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg="6" md="12" xs="12">
              <Form.Group className="mb-3" controlId="garana">
                <Form.Label>
                  <b>Enter Visitor Address :</b>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Visitor Address...."
                  value={customerAddress}
                  onChange={(e) => setCustomerAddress(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col lg="6" md="12" xs="12">
              <Form.Group className="mb-3" controlId="garana">
                <Form.Label>
                  <b>Enter Visitor Purpose *</b>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Visitor Purpose...."
                  value={visitorPurpose}
                  onChange={(e) => setVisitorPurpose(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <div className="sendDiv">
            {isEmpty(customerName) ||
            isEmpty(customerPhone) ||
            isEmpty(customerCNIC) ||
            isEmpty(visitorPurpose) ? (
              <Button
                className="sendButton"
                disabled
                style={{ backgroundColor: "black" }}
                onClick={() => {
                  uploadCustomer();
                  // setSubmit(true);
                }}
              >
                Save
              </Button>
            ) : (
              <Button
                className="sendButton"
                onClick={() => {
                  uploadCustomer();
                  // setSubmit(true);
                }}
              >
                Save
              </Button>
            )}
          </div>
        </Form>
      </Container>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addVisitorMarquee: (data, handleVisible, handleDangerVisible) =>
      dispatch(addVisitorMarquee(data, handleVisible, handleDangerVisible)),
    getVisitorMarquee: (id) => dispatch(getVisitorMarquee(id)),
    deleteVisitor: (menu_id) => dispatch(deleteVisitor(menu_id)),
    UpdateVisitor: (newData, oldData) =>
      dispatch(UpdateVisitor(newData, oldData)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  addCustomer: state.MarqueeReducer.addCustomer,
  isAddingCustomerData: state.MarqueeReducer.isAddingCustomerData,
  isFetchingCustomerDataMarquee:
    state.MarqueeReducer.isFetchingCustomerDataMarquee,
  customerMarqueeList: state.MarqueeReducer.customerMarqueeList,
  visitorMarqueeList: state.MarqueeReducer.visitorMarqueeList,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddVisitorMarquee);
