import React, { useState } from "react";
import {
  Container,
  Form,
  Row,
  Col,
  Button,
  FormGroup,
  FormControl,
} from "react-bootstrap";
import {
  addMenu,
  getMenu,
  deleteMenu,
  getMasterControl /*UpdateHRMenu */,
  SearchMarqueeMenuReport,
  UpdateMenu,
} from "./MarqueeDetails.actions";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import { useEffect } from "react";
import Swal from "sweetalert";
import { isEmpty, find, sumBy } from "lodash";
import jsPDF from "jspdf";
import logo from "../../../../../Images/logo.png";
import Select from "react-select";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { IconButton } from "@material-ui/core";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
const MenuList = (props) => {
  const menuData = isEmpty(props.menuList) ? [] : props.menuList.reverse();

  function MenuPDF(data) {
    props.SearchMarqueeMenuReport(data);
  }
  useEffect(() => {
    props.getMenu(2);
  }, []);
  return (
    <>
      <Container fluid>
        {props.isFetchingMenuData ? (
          <div className="loader-div">
            <Button variant="info" disabled>
              <Spinner
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
                variant="info"
              />
              Fetching Menu Data
            </Button>
          </div>
        ) : (
          <MaterialTable
            // style={{zIndex: 0}}
            title="Menu"
            columns={[
              { title: "Menu Name", field: "menu_name" },
              {
                title: "Menu Price",
                field: "menu_price",
                render: (rowData) => `${rowData.menu_price}/ Per Person`,
              },
              { title: "Menu Description", field: "menu_desc" },
            ]}
            data={menuData}
            options={{
              actionsColumnIndex: -1,
              filtering: true,
              exportButton: true,
              paging: true,
              pageSize: 50,
              emptyRowsWhenPaging: false, // To avoid of having empty rows
              pageSizeOptions: [20, 50, 100, 150, 200],
              exportAllData: true,
              exportPdf: (columns, data) => {
                const doc = new jsPDF();
                //render:rowData=>rowData.hall_name
                const columnTitles = [
                  {
                    title: "Menu Name",
                    field: "menu_name",
                    render: (rowData) => rowData.menu_name,
                  },
                  {
                    title: "Menu Price",
                    field: "menu_price",
                    render: (rowData) => rowData.menu_price,
                  },
                  {
                    title: "Menu Description",
                    field: "menu_desc",
                    render: (rowData) => rowData.menu_desc,
                  },
                ].map((columnDef) => columnDef.title);
                const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
                const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
                const companyNtn = `${process.env.REACT_APP_COMPANY_NTN}`;
                const pdfData = data.map((rowData) => [
                  rowData.menu_name,
                  `${rowData.menu_price}/Per Person`,
                  rowData.menu_desc,
                ]);
                let content = {
                  startY: 50,
                  startX: 5,
                  head: [columnTitles],
                  body: pdfData,
                };
                doc.addImage(logo, "PNG", 10, 1, 50, 50);
                doc.setFontSize(20);
                doc.setFont(undefined, "bold");
                doc.text(companyName, 50, 22, 0, 0);
                doc.setFontSize(13);
                doc.setFont("TimesNewRoman", "Normal");
                doc.text(companyAddress, 50, 29, 0, 0);
                doc.text(companyNtn, 50, 36, 0, 0);
                doc.autoTable(content);
                doc.save(`Menu Table.pdf`);
              },
              maxBodyHeight: "600px",
              headerStyle: {
                position: "sticky",
                top: 0,
                color: "#00BBBB",
                fontWeight: "550",
                onRowAdd: "none",
              },
            }}
            detailPanel={(rowData) => {
              return (
                <MaterialTable
                  title="Menu Items"
                  columns={[
                    {
                      title: "Dish Name",
                      field: "master_control_item.master_control_item_name",
                    },
                    { title: "Price", field: "menu_data_price" },
                  ]}
                  data={rowData.menu_data}
                  options={{
                    actionsColumnIndex: -1,
                    toolbar: false,
                    exportButton: true,
                    sorting: true,
                    paging: true,
                    pageSize: 200, // make initial page size
                    emptyRowsWhenPaging: false, // To avoid of having empty rows
                    pageSizeOptions: [50, 100, 150, 200],
                    maxBodyHeight: "600px",
                    headerStyle: {
                      position: "sticky",
                      top: 0,
                      color: "#00BBBB",
                      fontWeight: "550",
                      onRowAdd: "none",
                    },
                  }}
                />
              );
            }}
            actions={[
              {
                icon: LocalPrintshopIcon,
                disabled: false,
                onClick: (event, rowData) => {
                  //idpass
                  MenuPDF(rowData);
                  //  previewVoucher(rowData, "Completed");
                  //    console.log(rowData, "rdata")
                },
              },
            ]}
            editable={{
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    {
                      // console.log('newData', newData),
                      props.UpdateMenu(newData, oldData);
                    }
                  }, 600);
                }),
              onRowDelete: (oldData) =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    props.deleteMenu(oldData.menu_id);
                    console.log(oldData, "old");
                  }, 600);
                }),
            }}
          />
        )}
      </Container>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMenu: (id) => dispatch(getMenu(id)),
    UpdateMenu: (newData, oldData) => dispatch(UpdateMenu(newData, oldData)),
    deleteMenu: (menu_id) => dispatch(deleteMenu(menu_id)),
    SearchMarqueeMenuReport: (data) => dispatch(SearchMarqueeMenuReport(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  isFetchingMenuData: state.MarqueeReducer.isFetchingMenuData,
  menuList: state.MarqueeReducer.menuList,
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuList);
