import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, Alert } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import "jspdf-autotable";
import { addProductCall, getProductCall } from "./production.actions";

const MainProduct = (props) => {
  const [product, setProduct] = useState("");
  // const [description, setDescription] = useState("");

  /* const [state, setState] = useState({
        columns: [
            { title: 'Product', field: 'name' },
            // { title: 'Brand Description', field: 'description' },
        ],
    });*/

  function sendData() {
    props.addProductCall({
      name: product,
      // description: description,
    });
  }

  useEffect(() => {
    props.getProductCall();
  }, []);

  return (
    <Container fluid>
      <div className="main">
        <div>
          <Form>
            <Row>
              <Col lg="12" md="12" xs="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Enter Product:</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="productDetail"
                    placeholder="Product...."
                    onBlur={(e) => setProduct(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>

            {/* <Row>
                            <Col lg="12" md="12" xs="12">
                                <Form.Group className="mb-3">
                                    <Form.Label><b>Description:</b></Form.Label>
                                    <Form.Control type="text" id="productDescription" placeholder="Description...."
                                        onBlur={(e) => setDescription(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row> */}

            <div className="sendDiv">
              <Button className="sendButton" onClick={() => sendData()}>
                Save
              </Button>
            </div>

            <MaterialTable
              title="Product Details"
              columns={[
                { title: "Product", field: "name" },
                // { title: 'Brand Description', field: 'description' },
              ]}
              data={props.productInfo}
              options={{
                actionsColumnIndex: -1,
                filtering: true,
                exportButton: true,
                exportAllData: true,
                maxBodyHeight: "600px",
                headerStyle: {
                  position: "sticky",
                  top: 0,
                  color: "#00BBBB",
                  fontWeight: "550",
                  onRowAdd: "none",
                },
              }}
              editable={{
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      // props.updateWareHouse(newData, oldData)
                    }, 600);
                  }),

                onRowDelete: (oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      //   props.deleteWareHouse(oldData.wareHouseId);
                    }, 600);
                  }),
              }}
            />
          </Form>
        </div>
      </div>
    </Container>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addProductCall: (data) => dispatch(addProductCall(data)),
    getProductCall: () => dispatch(getProductCall()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  productInfo: state.productionReducer.productInfo,
});

export default connect(mapStateToProps, mapDispatchToProps)(MainProduct);
