import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Svg,
  Line,
  Image,
} from "@react-pdf/renderer";
import logo from "../../../../Images/logo.png";
import { groupBy, isEmpty, get, isUndefined } from "lodash";

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#E4E4E4",
  },
  section: {
    margin: 5,
    padding: 5,
    flexGrow: 1,
  },
  textStyles: {
    marginLeft: 15,
  },
  image: {
    width: "30%",
  },
});

var groupedLevel1;
var keys;
let arrayData = [];
const PDF = (props) => (
  (arrayData = props.array),
  (groupedLevel1 = groupBy(!isEmpty(arrayData) && arrayData, function (x) {
    return x.levelTwoData.level_one.name;
  })),
  (keys = !isEmpty(groupedLevel1) && Object.keys(groupedLevel1)),
  (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Image style={styles.image} src={logo} />
          <Text style={{ textAlign: "center", padding: "5%" }}>
            Company Name: {process.env.REACT_APP_COMPANY_NAME}
          </Text>
          {keys
            ? keys.map((y, index) => {
                let x = get(groupedLevel1, `${y}`);
                return (
                  <View key={index}>
                    <Text>
                      {x[0].levelTwoData.level_one.key}-{" "}
                      {x[0].levelTwoData.level_one.name}
                    </Text>
                    {x
                      ? x.map((xy, index1) => {
                          return (
                            <View key={index1}>
                              <Text style={styles.textStyles}>
                                {xy.levelTwoData.allKey}-{" "}
                                {xy.levelTwoData.levelTwoName}{" "}
                                {!isUndefined(xy.amount) &&
                                  xy.amount.total_amount}
                              </Text>
                            </View>
                          );
                        })
                      : ""}
                  </View>
                );
              })
            : ""}
        </View>
      </Page>
    </Document>
  )
);
export default PDF;
