import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Container,
  Button,
  FormGroup,
  Alert,
} from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty, isNull } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import "jspdf-autotable";
import {
  addSubEvent,
  getSubEvent,
  getEvent,
  updateSubEvent,
  deleteSubEvent,
  getDashboardMarquee,
} from "./MarqueeDetails.actions";
import Select from "react-select";
import Swal from "sweetalert";
import jsPDF from "jspdf";
import logo from "../../../../../Images/logo.png";

const SubEventList = (props) => {
  const getEventList =
    !isEmpty(props.eventList) &&
    props.eventList.map((x) => {
      let data = { value: x.event_id, label: x.event_name };
      return data;
    });
  console.log("getEventList", getEventList);
  const subEventsData = isEmpty(props.subEventsList)
    ? []
    : props.subEventsList.reverse();
  console.log("getSubEventList", subEventsData);

  useEffect(() => {
    props.getSubEvent(2);
  }, []);
  return (
    <>
      <Container fluid>
        <div className="main">
          <div>
            <Form>
              {props.isFetchingSubEventData ? (
                <div className="loader-div">
                  <Button variant="info" disabled>
                    <Spinner
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      variant="info"
                    />
                    Fetching SubEvent Data
                  </Button>
                </div>
              ) : (
                <MaterialTable
                  title="SubEvents"
                  columns={[
                    { title: "SubEvent Name", field: "sub_event_name" },
                    { title: "SubEvent Description", field: "sub_event_desc" },
                    {
                      title: "Event Name",
                      field: "event.event_name",
                      editable: "never",
                    },
                  ]}
                  data={subEventsData}
                  options={{
                    actionsColumnIndex: -1,
                    filtering: true,
                    exportButton: true,
                    pageSize: 50,
                    emptyRowsWhenPaging: false, // To avoid of having empty rows
                    pageSizeOptions: [20, 50, 100, 150, 200],
                    exportAllData: true,
                    exportPdf: (columns, data) => {
                      const doc = new jsPDF();
                      //render:rowData=>rowData.hall_name
                      const columnTitles = [
                        {
                          title: "SubEvent Name",
                          field: "sub_event_name",
                          render: (rowData) => rowData.sub_event_name,
                        },
                        {
                          title: "SubEvent Description",
                          field: "sub_event_desc",
                          render: (rowData) => rowData.sub_event_desc,
                        },
                        {
                          title: "Event Name",
                          field: "event.event_name",
                          editable: "never",
                          render: (rowData) => rowData.event.event_name,
                        },
                      ].map((columnDef) => columnDef.title);
                      const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
                      const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
                      const companyNtn = `${process.env.REACT_APP_COMPANY_NTN}`;
                      const pdfData = data.map((rowData) => [
                        rowData.sub_event_name,
                        rowData.sub_event_desc,
                        rowData.event.event_name,
                      ]);
                      let content = {
                        startY: 50,
                        startX: 5,
                        head: [columnTitles],
                        body: pdfData,
                      };
                      doc.addImage(logo, "PNG", 10, 1, 50, 50);
                      doc.setFontSize(20);
                      doc.setFont(undefined, "bold");
                      doc.text(companyName, 50, 22, 0, 0);
                      doc.setFontSize(13);
                      doc.setFont("TimesNewRoman", "Normal");
                      doc.text(companyAddress, 50, 29, 0, 0);
                      doc.text(companyNtn, 50, 36, 0, 0);
                      doc.autoTable(content);
                      doc.save(`Sub Event Table.pdf`);
                    },
                    maxBodyHeight: "600px",
                    headerStyle: {
                      position: "sticky",
                      top: 0,
                      color: "#00BBBB",
                      fontWeight: "550",
                      onRowAdd: "none",
                    },
                  }}
                  editable={{
                    onRowUpdate: (newData, oldData) =>
                      new Promise((resolve) => {
                        setTimeout(() => {
                          resolve();
                          {
                            props.updateSubEvent(newData, oldData);
                          }
                        }, 600);
                      }),
                    onRowDelete: (oldData) =>
                      new Promise((resolve) => {
                        setTimeout(() => {
                          resolve();
                          props.deleteSubEvent(oldData.sub_event_id);
                        }, 600);
                      }),
                  }}
                />
              )}
            </Form>
          </div>
        </div>
      </Container>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSubEvent: (id) => dispatch(getSubEvent(id)),
    getEvent: (id) => dispatch(getEvent(id)),
    updateSubEvent: (newData, oldData) =>
      dispatch(updateSubEvent(newData, oldData)),
    deleteSubEvent: (SubEventId) => dispatch(deleteSubEvent(SubEventId)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  subEventsList: state.MarqueeReducer.subEventsList,
  isFetchingSubEventData: state.HrReducer.isFetchingSubEventData,
});

export default connect(mapStateToProps, mapDispatchToProps)(SubEventList);
