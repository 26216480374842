const initialState = {
  isFetchingAddAging: false,
  agingList: [],
};

const AddAgingReducer = (state = initialState, action) => {
  switch (action.type) {
    case "REQUEST_ADD_AGING":
      return { ...state, isFetchingAddAging: true };
    case "SUCCESS_ADD_AGING":
      return {
        ...state,
        isFetchingAddAging: false,
        agingList: action.payload,
      };
    case "ERROR_ADD_AGING":
      return { ...state, isFetchingAddAging: false };

    default:
      return state;
  }
};
export default AddAgingReducer;
