import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Container,
  Button,
  FormGroup,
  Alert,
} from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty, isNull, upperCase, uniqBy } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import "jspdf-autotable";

import Select from "react-select";
import Swal from "sweetalert";
import { deleteSalaryIncrement, getSalaryIncrement } from "./salary.actions";
import { baseURL, headers } from "../../../../../global/api";
import { appendNonSignificantZeros } from "../../../../../global/globalFunctions";

const SalaryIncrementList = (props) => {
  useEffect(() => {
    // props.getSalaryIncrement();
    console.log("iiiiiiiiiiiiiiiiii");
  }, []);
  return (
    <>
      {props.isFetchingDivisionData ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {/* {props.isFetchingwareHouseList ? 'Loading...' : 'Loading...'} */}
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <div>
              <Form>
                <MaterialTable
                  title="Increment/Decrement"
                  columns={uniqBy(
                    [
                      {
                        title: "Employee Id",
                        field: "employee_id",
                        // render: (rowData) =>
                        //   appendNonSignificantZeros(rowData.employee_id),
                      },
                      { title: "Employee Name", field: "employee.name" },
                      // { title: 'Increment Id', field: 'increment_id' },
                      {
                        title: "Value",
                        field: "increment_value",
                        render: (rowData) =>
                          isNull(rowData.increment_value)
                            ? "-"
                            : rowData.increment_value,
                      },
                      {
                        title: "Type",
                        field: "type",
                        // render: (rowData) =>
                        //   isNull(rowData.decrement_value)
                        //     ? "-"
                        //     : rowData.decrement_value,
                      },
                      { title: "Date", field: "increment_date" },
                      {
                        title: "Merge Status",
                        field: "already_merged",
                        render: (rowData) =>
                          rowData.already_merged ? "Merged" : "Unmerged",
                      },
                    ],
                    "title"
                  )}
                  data={
                    // incDecData
                    (query) =>
                      new Promise((resolve, reject) => {
                        let url = `${baseURL}/increment/get_increment?`;
                        url += "size=" + query.pageSize;
                        url += "&page=" + (query.page + 1);
                        url += "&search=" + query.search;
                        fetch(url, {
                          method: "GET",
                          headers: headers,
                        })
                          .then((response) => response.json())
                          .then((result) => {
                            resolve({
                              data: result.data,
                              page: result.page - 1,
                              totalCount: result.total_results,
                            });
                          });
                      })
                  }
                  options={{
                    actionsColumnIndex: -1,
                    filtering: true,
                    exportButton: true,
                    maxBodyHeight: "600px",
                    headerStyle: {
                      position: "sticky",
                      top: 0,
                      color: "#00BBBB",
                      fontWeight: "550",
                      onRowAdd: "none",
                    },
                  }}
                  // actions={[
                  //     (rowData) => {
                  //         return { icon: EditIcon, disabled: false }
                  //     },
                  // ]}
                  editable={{
                    isEditable: false,

                    // onRowUpdate: (newData, oldData) =>
                    //     new Promise((resolve) => {
                    //         setTimeout(() => {
                    //             resolve();
                    //             if (oldData) {
                    //                 props.HRUpdateEmployee(newData, oldData)

                    //             }
                    //         }, 600);
                    //     }),
                    onRowDelete: (oldData) =>
                      new Promise((resolve) => {
                        setTimeout(() => {
                          resolve();
                          props.deleteSalaryIncrement(oldData);
                        }, 600);
                      }),
                  }}
                />
              </Form>
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSalaryIncrement: () => dispatch(getSalaryIncrement()),
    deleteSalaryIncrement: (data) => dispatch(deleteSalaryIncrement(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SalaryIncrementList);
