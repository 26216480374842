import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import {isNull } from "lodash";
import AddCustomerMarquee from "./AddCustomerMarquee";
import CustomerMarqueeList from "./CustomerMarqueeList";
import AddVisitorMarquee from "./AddVisitorMarquee";
import VisitorMarqueeList from "./VisitorMarqueeList";



const TotalVisitorData = (props) => {
    const permissions = JSON.parse(localStorage.getItem("permissions"));

    return (
        <>
            <Tabs defaultActiveKey={!isNull(permissions) && permissions.some(p => p['sub_id'] === 105) ?
                    "AddVisitor" : !isNull(permissions) && permissions.some(p => p['sub_id'] === 106) ?
                        "VisitorList" :  ""} id="AddVisitor" className="mb-3">
                <Tab eventKey="AddVisitor" title="Add Visitor"
                disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 105) ? false : true}>  
                    <AddVisitorMarquee />
                </Tab>
                <Tab eventKey="VisitorList" title="Visitor List"
              disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 106) ? false : true}> 
                  <VisitorMarqueeList/>
                </Tab>
            </Tabs>
        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
    };
};
const mapStateToProps = (state, ownProps) => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TotalVisitorData);