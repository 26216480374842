import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Container,
  Button,
  FormGroup,
  Alert,
} from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty, isNull, upperCase } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import "jspdf-autotable";
import Select from "react-select";
import Swal from "sweetalert";
import {
  deleteShiftControl,
  getEmployeeData,
  getShiftControl,
  updateShiftControl,
} from "./HRDetails.actions";

const ShiftControlList = (props) => {
  const employeeDataList = !isEmpty(props.employeeList)
    ? props.employeeList
    : [];
  const shiftIsAllocated = (shift_control_id) => {
    if (!isEmpty(employeeDataList)) {
      for (let index = 0; index < employeeDataList.length; index++) {
        if (!isEmpty(employeeDataList[index].salaries)) {
          if (
            employeeDataList[index].salaries[0].salary_creation_based ===
            "Shift"
          ) {
            if (
              employeeDataList[index].salaries[0].shift_id === shift_control_id
            ) {
              console.log("employeeWithThisShift", employeeDataList[index]);
              return false;
            }
          }
        }
      }
    }
    return true;
  };

  const shiftData = props.shiftControl;

  useEffect(() => {
    props.getShiftControl();
    props.getEmployeeData();
  }, []);
  return (
    <>
      {props.isFetchingShiftControlData || props.isFetchingShiftControlData ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {/* {props.isFetchingwareHouseList ? 'Loading...' : 'Loading...'} */}
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <div>
              <Form>
                <MaterialTable
                  title="Shift Details"
                  columns={[
                    { title: "Shift Name", field: "shift_name" },
                    { title: "Arrival In", field: "arrival_in" },
                    { title: "Break Out", field: "break_out" },
                    { title: "Break In", field: "break_in" },
                    { title: "Departure", field: "departure" },
                    {
                      title: "Allowed Late Arrival In",
                      field: "allow_late_arrival_in",
                    },
                    {
                      title: "Allowed Early Break Out",
                      field: "allow_early_break_out",
                    },
                    {
                      title: "Allowed Early Break In",
                      field: "allow_early_break_in",
                    },
                    {
                      title: "Allowed Late Departure",
                      field: "allow_late_departure",
                    },
                    { title: "Jummah Arrival In", field: "jummah_arrivel_in" },
                    { title: "Jummah Break Out", field: "break_jummah_out" },
                    { title: "Jummah Break In", field: "break_jummah_in" },
                    { title: "Jummah Departure", field: "jummah_departure" },
                    {
                      title: "Jummah Allowed Late Arrival In",
                      field: "jummah_allow_late_arrival_in",
                    },
                    {
                      title: "Jummah Allowed Early Break Out",
                      field: "jummah_allow_early_break_out",
                    },
                    {
                      title: "Jummah Allowed Early Break In",
                      field: "jummah_allow_early_break_in",
                    },
                    {
                      title: "Jummah Allowed Late Departure",
                      field: "jummah_allow_late_departure",
                    },
                  ]}
                  data={shiftData}
                  options={{
                    actionsColumnIndex: -1,
                    filtering: true,
                    exportButton: true,
                    exportAllData: true,
                    maxBodyHeight: "600px",
                    headerStyle: {
                      position: "sticky",
                      top: 0,
                      color: "#00BBBB",
                      fontWeight: "550",
                      onRowAdd: "none",
                    },
                  }}
                  editable={{
                    // isEditable: rowData => false,
                    onRowUpdate: (newData, oldData) =>
                      new Promise((resolve) => {
                        setTimeout(() => {
                          resolve();
                          {
                            props.updateShiftControl(newData, oldData);
                          }
                        }, 600);
                      }),

                    isDeletable: (rowData) =>
                      shiftIsAllocated(rowData.shift_control_id), //false to disable
                    // isDeleteHidden: rowData => true,
                    onRowDelete: (oldData) =>
                      new Promise((resolve) => {
                        setTimeout(() => {
                          resolve();
                          props.deleteShiftControl(oldData.shift_control_id);
                        }, 600);
                      }),
                  }}
                />
              </Form>
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getShiftControl: () => dispatch(getShiftControl()),
    deleteShiftControl: (shift_control_id) =>
      dispatch(deleteShiftControl(shift_control_id)),
    updateShiftControl: (newData, oldData) =>
      dispatch(updateShiftControl(newData, oldData)),
    getEmployeeData: () => dispatch(getEmployeeData()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  employeeList: state.HrReducer.employeeList,
  isFetchingShiftControlData: state.HrReducer.isFetchingShiftControlData,
  isFetchingShiftControlData: state.HrReducer.isFetchingShiftControlData,
  shiftControl: state.HrReducer.shiftControl,
});

export default connect(mapStateToProps, mapDispatchToProps)(ShiftControlList);
