import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import "./add-details.css";
import { connect } from "react-redux";
import IssuanceDetail from "./IssuanceDetail";
import AddIssuance from "./AddIssuance";
import IssuanceReport from "./IssuanceReport";
import { isNull } from "lodash";
import IssuanceReportByDate from "./IssuanceReportByDate";

const Issuance = (props) => {
    const permissions = JSON.parse(localStorage.getItem("permissions"));

    return (
        <>
            <Tabs
                defaultActiveKey={!isNull(permissions) && permissions.some(p => p['sub_id'] === 17) ?
                    "IssuanceList" : !isNull(permissions) && permissions.some(p => p['sub_id'] === 18) ?
                        "AddIssuance" : !isNull(permissions) && permissions.some(p => p['sub_id'] === 19) ?
                            "IssuanceReport" : ""}
                id="IssuanceList"
                className="mb-3"
                unmountOnExit={true}>
                <Tab
                    eventKey="IssuanceList"
                    title="Issuance Details"
                    unmountOnExit={true}
                    disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 17) ? false : true}>
                    <IssuanceDetail />
                </Tab>
                <Tab
                    eventKey="AddIssuance"
                    title="Add Issuance"
                    unmountOnExit={true}
                    disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 18) ? false : true}>
                    <AddIssuance />
                </Tab>
                <Tab
                    eventKey="IssuanceReport"
                    title="Issuance Report By Id"
                    unmountOnExit={true}
                    disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 19) ? false : true}>
                    <IssuanceReport />
                </Tab>
                <Tab
                    eventKey="IssuanceReportByDate"
                    title="Issuance Report By Date"
                    unmountOnExit={true}
                    disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 19) ? false : true}>
                    <IssuanceReportByDate />
                </Tab>
            </Tabs>
        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
    };
};
const mapStateToProps = (state, ownProps) => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Issuance);