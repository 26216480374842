const initialState = {
  isFetchingTransactionSummary: false,
  isFetchingGetVouchers: false,
  summary: [],
  voucherListData: [],
};

const TransactionReducer = (state = initialState, action) => {
  switch (action.type) {
    case "REQUEST_TRANSACTION_SUMMARY":
      return { ...state, isFetchingTransactionSummary: true };
    case "SUCCESS_TRANSACTION_SUMMARY":
      return {
        ...state,
        isFetchingTransactionSummary: false,
        summary: action.payload,
      };
    case "ERROR_TRANSACTION_SUMMARY":
      return { ...state, isFetchingTransactionSummary: false };

    case "REQUEST_GET_VOUCHERS":
      return { ...state, isFetchingGetVouchers: true };
    case "SUCCESS_GET_VOUCHERS":
      return {
        ...state,
        isFetchingGetVouchers: false,
        voucherListData: action.payload.data,
      };
    case "ERROR_GET_VOUCHERS":
      return { ...state, isFetchingGetVouchers: false };

    default:
      return state;
  }
};
export default TransactionReducer;
