import API from "../../../../global/api";
import { get } from 'lodash';

//LEVEL 1
export const requestLevel1GetData = () => {
    return {
        type: "REQUEST_LEVEL1_GET_DATA",
    };
};
export const successLevel1GetData = (data) => {
    return {
        type: "SUCCESS_LEVEL1_GET_DATA",
        payload: data,
    };
};
export const errorLevel1GetData = () => {
    return {
        type: "ERROR_LEVEL1_GET_DATA",
    };
};

export const getLevel1Data = () => {
    return (dispatch) => {
        dispatch(requestLevel1GetData());
        API.get(`/levelOne/get_all_levelone`).then((res) => {
            let getData = res.data.Name;
            dispatch(successLevel1GetData(getData));
        }).catch((error) => {
            dispatch(errorLevel1GetData());
        })
    };
};



export const getLevelOneName = (data) => {
    return (dispatch) => {
        dispatch(requestLevelOneData());
        API.get(`/levelTwo/get-by-levelOne/${data}`).then((res) => {
           // dispatch(getLevel2Data());
            dispatch(successLevelOneData(res.data));
        }).catch((error) => {
            dispatch(errorAddLevelOne());
        })
    };
};

export const requestLevelOneData = () => {
    return {
        type: "REQUEST_LEVEL1_DATA",
    };
};
export const successLevelOneData = (data) => {
    return {
        type: "SUCCESS_LEVEL1_DATA",
        payload: data,
    };
};
export const errorAddLevelOne = () => {
    return {
        type: "ERROR_LEVEL1_DATA",
    };
};

export const getLevelTwoName = (data) => {
    return (dispatch) => {
        dispatch(requestLevelTwoData());
    
        API.get(`/levelThree/get-by-levelTwo/${data}`).then((res) => {
           // dispatch(getLevel2Data());
            dispatch(successLevelTwoData(res.data));
        }).catch((error) => {
            dispatch(errorAddLevelTwo());
        })
    };
};


export const getLevelFourName = (data) => {
    return (dispatch) => {
        dispatch(requestLevelFourData());
    
        API.get(`levelFour/get-by-levelThree/${data}`).then((res) => {
           // dispatch(getLevel2Data());
            dispatch(successLevelFourData(res.data));
        }).catch((error) => {
            dispatch(errorAddLevelFour());
        })
    };
};

export const requestLevelFourData = () => {
    return {
        type: "REQUEST_LEVEL4_DATA",
    };
};
export const successLevelFourData = (data) => {
    return {
        type: "SUCCESS_LEVEL4_DATA",
        payload: data,
    };
};
export const errorAddLevelFour = () => {
    return {
        type: "ERROR_LEVEL4_DATA",
    };
};

export const requestLevelTwoData = () => {
    return {
        type: "REQUEST_LEVEL2_DATA",
    };
};
export const successLevelTwoData = (data) => {
    return {
        type: "SUCCESS_LEVEL2_DATA",
        payload: data,
    };
};
export const errorAddLevelTwo = () => {
    return {
        type: "ERROR_LEVEL2_DATA",
    };
};