import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Container,
  Button,
  FormGroup,
  Alert,
} from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty, isNull, upperCase } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import "jspdf-autotable";
import {
  UpdateHRDivision,
  deleteHRDivision,
  getHRDivision,
} from "./HRDetails.actions";
import Select from "react-select";
import Swal from "sweetalert";

const DivisionList = (props) => {
  const divisionData = isEmpty(props.divisionList)
    ? []
    : props.divisionList.reverse();
  console.log(divisionData);

  useEffect(() => {
    props.getHRDivision();
  }, []);
  return (
    <>
      {props.isFetchingDivisionData ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {/* {props.isFetchingwareHouseList ? 'Loading...' : 'Loading...'} */}
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <div>
              <Form>
                <MaterialTable
                  title="Division"
                  columns={[
                    { title: "Division Id", field: "division_id" },
                    { title: "Division Name", field: "division_name" },
                    { title: "Division Description", field: "division_desc" },
                  ]}
                  data={divisionData}
                  options={{
                    actionsColumnIndex: -1,
                    filtering: true,
                    exportButton: true,
                    exportAllData: true,
                    maxBodyHeight: "600px",
                    headerStyle: {
                      position: "sticky",
                      top: 0,
                      color: "#00BBBB",
                      fontWeight: "550",
                      onRowAdd: "none",
                    },
                  }}
                  editable={{
                    onRowUpdate: (newData, oldData) =>
                      new Promise((resolve) => {
                        setTimeout(() => {
                          resolve();
                          {
                            // console.log('newData', newData),
                            props.UpdateHRDivision(newData, oldData);
                          }
                        }, 600);
                      }),
                    onRowDelete: (oldData) =>
                      new Promise((resolve) => {
                        setTimeout(() => {
                          resolve();
                          props.deleteHRDivision(oldData.division_id);
                          console.log(oldData, "old");
                        }, 600);
                      }),
                  }}
                />
              </Form>
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getHRDivision: () => dispatch(getHRDivision()),
    UpdateHRDivision: (newData, oldData) =>
      dispatch(UpdateHRDivision(newData, oldData)),
    deleteHRDivision: (division_id) => dispatch(deleteHRDivision(division_id)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  isFetchingDivisionData: state.HrReducer.isFetchingDivisionData,
  divisionList: state.HrReducer.divisionList,
});

export default connect(mapStateToProps, mapDispatchToProps)(DivisionList);
