import API from "../../../../../global/api";

export const requestGetArticle = () => {
    return {
        type: "REQUEST_GET_ARTICLE",
    };
};
export const successGetArticle = (data) => {
    return {
        type: "SUCCESS_GET_ARTICLE",
        payload: data,
    };
};
export const errorGetArticle = () => {
    return {
        type: "ERROR_GET_ARTICLE",
    };
};

export const getArticle = () => {
    return (dispatch) => {
        dispatch(requestGetArticle());
        API.get(`/atricle/get-article`).then((res) => {
            let getData = res.data;
            dispatch(successGetArticle(getData));
        }).catch((error) => {
            dispatch(errorGetArticle());
        })
    };
};


export const requestGetEmployee = () => {
    return {
        type: "REQUEST_GET_EMPLOYEE",
    };
};
export const successGetEmployee = (data) => {
    return {
        type: "SUCCESS_GET_EMPLOYEE",
        payload: data,
    };
};
export const errorGetEmployee = () => {
    return {
        type: "ERROR_GET_EMPLOYEE",
    };
};

export const getEmployeeData = () => {
    return (dispatch) => {
        dispatch(requestGetEmployee());
        API.get(`/employee/get_all_data`).then((res) => {
            let getData = res.data;
            dispatch(successGetEmployee(getData));
        }).catch((error) => {
            dispatch(errorGetEmployee());
        })
    };
};




export const requestGetProcess = () => {
    return {
        type: "REQUEST_GET_PROCESS",
    };
};
export const successGetProcess = (data) => {
    return {
        type: "SUCCESS_GET_PROCESS",
        payload: data,
    };
};
export const errorGetProcess = () => {
    return {
        type: "ERROR_GET_PROCESS",
    };
};

export const getProcessData = () => {
    return (dispatch) => {
        dispatch(requestGetProcess());
        API.get(`/catProcess/get_all_process_data`).then((res) => {
            let getData = res.data;
            dispatch(successGetProcess(getData));
        }).catch((error) => {
            dispatch(errorGetProcess());
        })
    };
};





export const requestAddLabour = () => {
    return {
        type: "REQUEST_ADD_LABOUR",
    };
};
export const successAddLabour = (data) => {
    return {
        type: "SUCCESS_ADD_LABOUR",
        payload: data,
    };
};
export const errorAddLabour = () => {
    return {
        type: "ERROR_ADD_LABOUR",
    };
};

export const addLabourData = (data) => {
    return (dispatch) => {
        dispatch(requestAddLabour());
        API.post(`/article/add-labour-data`, data).then((res) => {
            let getData = res.data;
            dispatch(successAddLabour(data));
        }).catch((error) => {
            dispatch(errorAddLabour());
        })
    };
};



export const requestGetProcessCategory = () => {
    return {
        type: "REQUEST_GET_PROCESS_CATEGORY",
    };
};
export const successGetProcessCategory = (data) => {
    return {
        type: "SUCCESS_GET_PROCESS_CATEGORY",
        payload: data,
    };
};
export const errorGetProcessCategory = () => {
    return {
        type: "ERROR_GET_PROCESS_CATEGORY",
    };
};

export const getProcessCategoryData = () => {
    return (dispatch) => {
        dispatch(requestGetProcessCategory());
        API.get(`/categoryProcess/get_all_catProcess_data`).then((res) => {
            let getData = res.data;
            dispatch(successGetProcessCategory(getData));
        }).catch((error) => {
            dispatch(errorGetProcessCategory());
        })
    };
};





export const requestGetProcessDataByCategory = () => {
    return {
        type: "REQUEST_GET_PROCESS_DATA_BY_CATEGORY",
    };
};
export const successGetProcessDataByCategory = (data) => {
    return {
        type: "SUCCESS_GET_PROCESS_DATA_BY_CATEGORY",
        payload: data,
    };
};
export const errorGetProcessDataByCategory = () => {
    return {
        type: "ERROR_GET_PROCESS_DATA_BY_CATEGORY",
    };
};

export const getProcessDataByCategory = (categoryId) => {
    return (dispatch) => {
        dispatch(requestGetProcessDataByCategory());
        API.get(`/categoryProcess/get-process-data/${categoryId}`).then((res) => {
            let getData = res.data;
            dispatch(successGetProcessDataByCategory(getData));
        }).catch((error) => {
            dispatch(errorGetProcessDataByCategory());
        })
    };
};