import React, { useEffect, useState } from "react";
import {
  InputGroup,
  Row,
  Col,
  Form,
  Table,
  Container,
  Button,
  FormGroup,
  Modal,
  Alert,
} from "react-bootstrap";
import "./add-details.css";
//import { getSectorsList } from "../uploadData/uploadData.actions";
import {
  getWarehouseData,
  getCategoryData,
  getsubCategoryData,
  getAttributeData,
  deleteAttribute,
  resetReducer,
} from "./AddDetails.actions";
import { connect } from "react-redux";
import { isEmpty, get, map, find } from "lodash";
//import moment from 'moment';
import Spinner from "react-bootstrap/Spinner";
//import { searchBlock } from "../search/search.action";
import WareHouseModal from "./WareHouseModal";
import MaterialTable from "material-table";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { IconButton } from "@material-ui/core";

const Attribute = (props) => {
  const [attribute, setAttributeName] = useState();
  // const [subCategoryId, setsubCategoryId] = useState(0);
  // const [categoryId, setcategoryId] = useState(isEmpty(subCategoryId)? 0: find(props.categoryList, x =>x.categoryId == find(props.subCategoryList, x => x.subCategoryId == subCategoryId ).categoryId).categoryId);
  const [wareHouseId, setwareHouseId] = useState();
  const [inputFields, setInputField] = useState([{ typeName: " " }]);
  const handleChangeInput = (index, event) => {
    const values = [...inputFields];
    values[index][event.target.id] = event.target.value;
    setInputField(values);
  };
  const handleAddFields = (index) => {
    setInputField([...inputFields, { typeName: " " }]);
  };
  const handleRemoveFields = (index) => {
    const values = [...inputFields];
    if ((index = 1)) values.splice(index, 1);
    setInputField(values);
  };
  const [show, setShow] = useState(false);
  //const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function handleClose() {
    // props.getSectorsSummaryGetData({
    //     summarySectorId: sector
    //     });
    setShow(false);
  }

  function uploadSummary() {
    props.getSectorsSummaryData({
      //   category_name: category,
    });
    //    {props.isState && handleShow()};
  }
  function uploadAttribute() {
    // props.getSectorsSummaryData({
    //     warehouse_name: warehouse,
    // });
    //    {props.isState && handleShow()};
    // props.addAttributeName({
    //     attributeName: attribute,
    //     // subCategoryId: subCategoryId,
    //     // categoryId: categoryId,
    //     wareHouseId: wareHouseId,
    //     typeName: map(inputFields, 'typeName'),
    // });
    setAttributeName(" ");
    setInputField([" "]);
  }
  const wareHouseData = props.attributeList;
  // const CategoryData = props.subCategoryList;
  // const subCategoryData = props.attributeList;
  const [open, setOpen] = React.useState(props.dataAddedAttribute);
  /* const [state, setState] = React.useState({
        columns: [
            { title: 'WareHouse Name', field: 'attribute.warehouse.wareHouseName' },
            // { title: 'Category Name', field: 'sub-category.category.categoryName' },
            // { title: 'Sub Category Name', field: 'sub-category.subCategoryName' },
            { title: 'Attribute Name', field: 'attribute.attributeName' },
            { title: 'Attribute Property Name', field: 'typeName' },
        ],
    });*/
  useEffect(() => {
    props.warehouseDataGetCall();
    // props.getCategoryData();
    // props.getsubCategoryData();
    props.getAttributeData();
    props.resetState();
  }, []);
  return (
    <>
      {props.isFetchingSectorsSummary ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {props.isFetchingSectorsSummary ? "Loading..." : "Loading..."}
          </Button>
        </div>
      ) : (
        <Container fluid>
          {props.dataAddedAttribute && (
            <Alert
              variant={"success"}
              onClose={() => props.resetState()}
              dismissible
            >
              Added Data...
            </Alert>
          )}
          <div className="main">
            <Row>
              <Col xl="12" lg="12" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b> Select Warehouse </b>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    //   selected={sector}
                    value={wareHouseId}
                    onChange={(e) => setwareHouseId(e.target.value)}
                  >
                    <option selected disabled>
                      Select an Option
                    </option>
                    {!isEmpty(props.warehouseList) &&
                      props.warehouseList.map((warehouse) => (
                        <option
                          id={warehouse.wareHouseId}
                          value={warehouse.wareHouseId}
                        >
                          {warehouse.wareHouseName}
                        </option>
                      ))}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col lg="12" md="12" xs="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Enter Attribute Name :</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Attribute Name...."
                    value={attribute}
                    onChange={(e) => setAttributeName(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Col xl="12" lg="12" md="12" sm="12">
              {inputFields.map((inputField, index) => (
                <div>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      <b>Attribute Properties :</b>
                    </Form.Label>
                    <Form.Control
                      id={"typeName"}
                      type="text"
                      placeholder="Attribute Properties...."
                      onBlur={(e) => handleChangeInput(index, e)}
                    />
                  </Form.Group>

                  <FormGroup>
                    <Row>
                      <Col lg="6" style={{ marginTop: "10px" }}></Col>

                      <Col lg="6">
                        <IconButton
                          title="Click to Add Property"
                          onClick={() => {
                            handleAddFields(index);
                          }}
                          style={{
                            float: "right",
                            color: "black",
                          }}
                        >
                          <AddIcon />
                        </IconButton>

                        <IconButton
                          title="Click to Remove Property"
                          onClick={() => handleRemoveFields(index)}
                          style={{ float: "right", color: "black" }}
                        >
                          <RemoveIcon />
                        </IconButton>
                      </Col>
                    </Row>
                  </FormGroup>
                </div>
              ))}
            </Col>
            <div className="sendDiv">
              <Button className="sendButton" onClick={uploadAttribute}>
                Add
              </Button>
            </div>
            {/* {open && <WareHouseModal open={open} />} */}
            <MaterialTable
              title="Attribute Details"
              columns={[
                {
                  title: "WareHouse Name",
                  field: "attribute.warehouse.wareHouseName",
                },
                // { title: 'Category Name', field: 'sub-category.category.categoryName' },
                // { title: 'Sub Category Name', field: 'sub-category.subCategoryName' },
                { title: "Attribute Name", field: "attribute.attributeName" },
                { title: "Attribute Property Name", field: "typeName" },
              ]}
              data={wareHouseData}
              //data={CategoryData}
              // data={state.data}
              //   actions={[
              //     {
              //     icon: VisibilityIcon,
              //       tooltip: 'View Ware House',
              //       onClick: (event, rowData) => {
              //         // Do save operation
              //       //  setcallEdit(true);

              //       // props.selectedUser(rowData)
              //       }
              //     }
              //   ]}
              options={{
                actionsColumnIndex: -1,
                filtering: true,
                exportButton: true,
                maxBodyHeight: "600px",
                headerStyle: {
                  position: "sticky",
                  top: 0,
                  color: "#00BBBB",
                  fontWeight: "550",
                  onRowAdd: "none",
                },
              }}
              editable={{
                // onRowUpdate: (oldData, newData) =>
                //     new Promise((resolve) => {
                //         setTimeout(() => {
                //             resolve();
                //             if (oldData) {
                //                 props.updateAttribute(oldData, newData)
                //             }
                //         }, 600);
                //     }),
                onRowDelete: (oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      props.deleteAttribute(oldData.attribute.attributeId);
                    }, 600);
                  }),
              }}
            />
            <>
              <Modal show={show} onHide={handleClose}></Modal>
            </>
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    //getSectorsList: () => dispatch(getSectorsList()),
    // getSectorsSummaryData: (data) => dispatch(getSectorsSummaryData(data)),
    // getSectorsSummaryGetData: (data) => dispatch(getSectorsSummaryGetData(data)),
    // resetFunction: () => dispatch(resetFunction()),
    //searchBlock : () => dispatch(searchBlock()),
    warehouseDataGetCall: () => dispatch(getWarehouseData()),
    // getCategoryData: () => dispatch(getCategoryData()),
    // getsubCategoryData: () => dispatch(getsubCategoryData()),
    getAttributeData: () => dispatch(getAttributeData()),
    //  addAttributeName: (data) => dispatch(addAttributeName(data)),
    deleteAttribute: (attributeId) => dispatch(deleteAttribute(attributeId)),
    resetState: () => dispatch(resetReducer()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  //sectorsList: state.uploadDataReducer.sectorsList,
  //isFetchingSectors: state.uploadDataReducer.isFetchingSectors,
  //summaryList: state.SummaryReducer.summaryList,
  //isFetchingSectorsSummary: state.SummaryReducer.isFetchingSectorsSummary,
  //blockList: state.searchReducer.blockList,
  //isState: state.SummaryReducer.isState,
  warehouseList: state.AddDataReducer.warehouseList,
  // categoryList: state.AddDataReducer.categoryList,
  // subCategoryList: state.AddDataReducer.subCategoryList,
  attributeList: state.AddDataReducer.attributeList,
  dataAddedAttribute: state.AddDataReducer.dataAddedAttribute,
});

export default connect(mapStateToProps, mapDispatchToProps)(Attribute);
