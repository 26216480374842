import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import InternalIssuance from "./InternalIssuance";
import ExternalIssuance from "./ExternalIssuance";
import {isEmpty} from "lodash";

const AddIssuance = (props) => {
    useEffect(() => {
    }, []);
    return (
        <>
            <Tabs defaultActiveKey="internalIssuace" id="AddArticle" className="mb-3">
                <Tab eventKey="internalIssuace" title="Internal Issuace">
                <InternalIssuance />
                </Tab>
                <Tab eventKey="externalIssuace" title="External Issuace">
                <ExternalIssuance />
                </Tab>
            </Tabs>
        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
    };
};
const mapStateToProps = (state, ownProps) => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddIssuance);