import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Container,
  Button,
  FormGroup,
  Alert,
  Tabs,
  Tab,
} from "react-bootstrap";
import { ModalBody, Modal } from "reactstrap";
import "./add-details.css";

import { connect } from "react-redux";
import { isEmpty, find, isUndefined } from "lodash";
import moment from "moment";
import Spinner from "react-bootstrap/Spinner";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { IconButton } from "@material-ui/core";
import Select from "react-select";
import Swal from "sweetalert";
import {
  getLevel4Data,
  getLevel5Data,
} from "../accountsLevels/accountsLevel.action";
import {
  addAccountMasterControl,
  addLevel3Account,
  deleteAccountMasterControl,
  getAccountMasterControl,
  getLevel3Account,
  updateAccountMasterControl,
} from "./AddDetails.actions";
import MaterialTable from "material-table";

const SelectLevel3Account = (props) => {
  const [accountTypeId, setAccountTypeId] = useState("");
  const [accountTypeLabel, setAccountTypeLabel] = useState("");
  const [level4Id, setLevel4Id] = useState("");
  const [level4Label, setLevel4Label] = useState("");
  const [recivableId, setRecivableId] = useState("");
  const [recivableLabel, setRecivableLabel] = useState("");
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  const handleVisible = () => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: "Account Added Successfully",
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = () => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: "Something went Wrong!",
      icon: "error",
      button: "Ok",
    });
  };

  function uploadDemand() {
    props.addAccount(
      {
        levelFourId: level4Id,
      },
      handleVisible,
      handleDangerVisible
    );
  }
  const level4 =
    !isEmpty(props.level4List) &&
    props.level4List.map((x) => {
      let data = {
        value: x.levelFourData.levelFourId,
        label:
          x.levelFourData.allLevlKey + " / " + x.levelFourData.levelFourName,
      };
      return data;
    });
  const level4Account = (selectedOption, type) => {
    setLevel4Id(selectedOption.value);
    setLevel4Label(selectedOption.label);
  };
  useEffect(() => {
    props.getLevel3Data();
    // props.getAccountList();
    props.getLevel4Data();
    console.log(props.level3AccountList, "testttttttttttttttttt");
  }, []);
  return (
    <>
      {props.isFetchinglevel4List ||
      props.isSavingLevel3Account ||
      props.isFetchingLevel3Account ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {props.isSavingLevel3Account ? "Saving" : "Loading"}
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Row style={{ zIndex: 1 }}>
              <Col lg="6" sm="12">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>
                    <b>Select Level 3 Account</b>
                  </Form.Label>
                  <Select
                    // style={{zIndex}}
                    value={{ label: level4Label }}
                    placeholder="Select Level 3 Account..."
                    onChange={level4Account}
                    options={level4}
                  />
                </Form.Group>
              </Col>
            </Row>
            <div className="sendDiv">
              {level4Id == "" ? (
                <Button
                  disabled
                  style={{
                    marginLeft: "2%",
                    color: "white",
                    width: "20%",
                    backgroundColor: "black",
                    border: "1px solid black",
                  }}
                >
                  Save
                </Button>
              ) : (
                <Button className="sendButton" onClick={uploadDemand}>
                  Save
                </Button>
              )}
            </div>
            <div className="table">
              <MaterialTable
                style={{ zIndex: 0 }}
                title={"Level 3 Accounts"}
                columns={[
                  { title: "level Four Id", field: "levelFourId" },
                  { title: "Account Name", field: "level_four.levelFourName" },
                ]}
                data={props.level3AccountList.result}
                options={{
                  actionsColumnIndex: -1,
                  paging: true,
                  pageSize: 200, // make initial page size
                  emptyRowsWhenPaging: false, // To avoid of having empty rows
                  pageSizeOptions: [50, 100, 150, 200],
                  maxBodyHeight: "600px",
                  headerStyle: {
                    position: "sticky",
                    top: 0,
                    color: "#00BBBB",
                    fontWeight: "550",
                    onRowAdd: "none",
                  },
                }}

                // editable={{
                //   // onRowUpdate: (newData, oldData) =>
                //   // new Promise((resolve) => {
                //   //     setTimeout(() => {
                //   //         resolve();
                //   //         {
                //   //             // console.log('newData', newData),
                //   //             props.updateAccount(newData, oldData)
                //   //         }
                //   //     }, 600);
                //   // }),
                //   onRowDelete: (oldData) =>
                //     new Promise((resolve) => {
                //       setTimeout(() => {
                //         resolve();
                //         props.deleteAccount(oldData.levelFiveId);
                //       }, 600);
                //     }),
                // }}
              />
            </div>
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getLevel3Data: () => dispatch(getLevel3Account()),
    getLevel4Data: () => dispatch(getLevel4Data()),
    addAccount: (data, handleVisible, handleDangerVisible) =>
      dispatch(addLevel3Account(data, handleVisible, handleDangerVisible)),
    // getAccountList:()=>dispatch(getAccountMasterControl()),
    // deleteAccount:(id)=>dispatch(deleteAccountMasterControl(id)),
    // updateAccount:(data,oldData)=>dispatch(updateAccountMasterControl(data,oldData)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  level4List: state.accountsLevelReducer.level4List,
  isFetchinglevel4List: state.accountsLevelReducer.isFetchinglevel4List,
  isFetchingLevel3Account: state.AddDataReducer.isFetchingLevel3Account,
  isSavingLevel3Account: state.AddDataReducer.isSavingLevel3Account,
  level3AccountList: state.AddDataReducer.level3AccountList,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectLevel3Account);
