import React, { useEffect, useState } from "react";
import { Row, Col, Form, Table, Container, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import moment from 'moment';
import Spinner from 'react-bootstrap/Spinner';
import "./style.css";
import Select from 'react-select';
import MaterialTable from 'material-table';
import { GetVoucher, SearchVoucher } from "./Voucher.action";
import { Link } from "react-router-dom";
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import {previewVoucher} from '../../../../global/globalFunctions'
const VoucherSearchDetail = (props) => {

    useEffect(() => {
       
    }, []);

    const [state, setState] = React.useState({
        columns: [
            { title: 'Date', field: 'createdAt', render: rowData => moment(rowData.createdAt).format("L") },
            { title: 'Voucher #', field: 'voucherManualId' },

        ],
        columnsData: [
            { title: 'Account Title', field: 'accountTittle' },
            { title: 'Bill No', field: 'billNo' },
            { title: 'Voucher Amount', field: 'voucherAmount', render: rowData => rowData.voucherAmount.toLocaleString() },
            { title: "Voucher Date", field: "voucherDate", render: rowData => moment(rowData.voucherDate).format("L") },
            { title: "Tracking No", field: "trackingNo" },
            { title: "Destination", field: "destination" },
            { title: "Packages", field: "packages" },
            { title: "Weight", field: "weight" },
            { title: "Weight Charges", field: "weightCharges" },
            { title: "Rate First Kg", field: "rateFirstKg" },
            { title: "Rate Add Kg", field: "rateAddKg" },
            { title: "Rate Flat", field: "rateFlat" },
            { title: "Service", field: "service" },
        ],
    });
    const name = isEmpty(props.searchVoucherList)?"":props.searchVoucherList.name;
    const start = isEmpty(props.searchVoucherList)
      ? ""
      : props.searchVoucherList.startedDate;
    const end = isEmpty(props.searchVoucherList)
      ? ""
      : props.searchVoucherList.endDate;

    return (
        <>
            {props.isFetchingSearchVoucherList ? (
                <div className="loader-div">
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />
                        Loading...
                    </Button>
                </div>
            ) : (
                <Container fluid>
                    <div className="main">

                    <div className="sendDiv">
                            <Link to="/user/voucher-search">
                                <Button className="sendButton"> Back to Search</Button>
                            </Link>
                        </div>

                        <div className="table">
                            <MaterialTable
                                title={  <div style={{ marginTop: "5px" }}>
                                <h5>{name == "" ? "Voucher" : `${"Voucher: " + name}`}</h5>
                                <h5>
                                  Date Range: {start} - {end}
                                </h5>
                                </div>}
                                columns={[
                                    { title: 'Date', field: 'createdAt', render: rowData => moment(rowData.createdAt).format("L") },
                                    { title: 'Voucher #', field: 'voucherManualId' },
                        
                                ]}
                                data={props.searchVoucherList.data}
                                detailPanel={rowData => {
                                    return (
                                        <MaterialTable
                                            title="Vocuher Details"
                                            columns={[
                                                { title: 'Account Title', field: 'accountTittle' },
                                                { title: "Voucher Date", field: "voucherDate", render: rowData => moment(rowData.voucherDate).format("L") },
                                                { title: 'Bill No', field: 'billNo' },
                                                { title: 'Description', field: 'voucherDescription' },
                                                { title: 'Voucher Amount', field: 'voucherAmount', render: rowData => rowData.voucherAmount.toLocaleString() },
                                                { title: "DR/CR", field: "accountType", render: rowData => rowData.accountType == 1 ? "Credit" : rowData.accountType == 2 ? "Debit" : "" },
                                                { title: "Tracking No", field: "trackingNo" },
                                                { title: "Destination", field: "destination" },
                                                { title: "Packages", field: "packages" },
                                                { title: "Weight", field: "weight" },
                                                { title: "Weight Charges", field: "weightCharges" },
                                                { title: "Rate First Kg", field: "rateFirstKg" },
                                                { title: "Rate Add Kg", field: "rateAddKg" },
                                                { title: "Rate Flat", field: "rateFlat" },
                                                { title: "Service", field: "service" },
                                            ]}
                                            data={rowData.voucher_data}
                                            options={{
                                                actionsColumnIndex: -1,
                                                toolbar: false,
                                                exportButton: true,
                                                sorting: true,
                                                paging: true,
                                                pageSize: 200,       // make initial page size
                                                emptyRowsWhenPaging: false,   // To avoid of having empty rows
                                                pageSizeOptions: [50, 100, 150, 200],
                                                headerStyle: {
                                                    position: 'sticky', top: 0,
                                                    color: '#00BBBB',
                                                    fontWeight: '550',
                                                    onRowAdd: 'none',
                                                }
                                            }}

                                        />
                                    )
                                }}
                                actions={[
                                    {
                                        icon: LocalPrintshopIcon,
                                        disabled: false,
                                        onClick: (event, rowData) => {
                                    previewVoucher(rowData, "Completed");
                                        console.log(rowData, "rdata")
                                        },
                                      }
                                ]}
                                options={{
                                    actionsColumnIndex: -1,
                                    filtering: true,
                                    exportButton: true,
                                    paging: true,
                                    pageSize: 200,       // make initial page size
                                    emptyRowsWhenPaging: false,   // To avoid of having empty rows
                                    pageSizeOptions: [50, 100, 150, 200],
                                    headerStyle: {
                                        position: 'sticky', top: 0,
                                        color: '#00BBBB',
                                        fontWeight: '550',
                                        onRowAdd: 'none',
                                    }
                                }}
                         
                                onRowClick={(event, rowData, togglePanel) => togglePanel()}
                            />
                        </div>
                    </div>

                </Container>

            )}
        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
        SearchVoucher: (data) => dispatch(SearchVoucher(data)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    searchVoucherList: state.VoucherReducer.searchVoucherList,
    isFetchingSearchVoucherList: state.VoucherReducer.isFetchingSearchVoucherList
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(VoucherSearchDetail);