import React, { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { Row, Col, Form, Container, Button } from "react-bootstrap";
import { connect } from "react-redux";
import Select from "react-select";
import MaterialTable from "material-table";
import "jspdf-autotable";
import PrintIcon from "@mui/icons-material/Print";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";

import {
  getOrderCall,
  getArticleByOrder,
  getDataByOrderArticle,
} from "./ProductionDetail.action";
const ProductionDetail = (props) => {
  //get order
  console.log(props.orderInfo, "pppppppppppppppppppppppppppppppp");

  const getOrder = props.orderInfo.map((x) => {
    let data = { label: x.orderId, value: x.orderId };
    return data;
  });

  const getArticleByOrderIdList =
    !isEmpty(props.articleByOrderId) &&
    props.articleByOrderId[0].order_articles.map((x) => {
      let data = { value: x.articleId, label: x.article.itemName };
      return data;
    });
  const receivejob = [
    // { value: "Local", label: "Local" },
    { value: "select1", label: "select1" },
    { value: "select2", label: "select2" },
  ];
  const setreceivingjobInfo = () => {};

  /*const [state, setState] = useState({
    
    columns: [
      { title: 'Labor Job Name', field: 'laborJObName'},
      { title: 'Worker', field: 'worker'},
      { title: 'Aloc', field: 'aloc'},
      { title: 'Qty', field: 'qty'},
      { title: 'DueDate', field: 'duedate'},
      { title: 'Receiving Job', field: 'receivingJob'},
    //   { title: 'Receiving', field: 'customerContry'},
    ],
  });*/

  const [orderNum, setOrderNum] = useState("");
  const [articleId, setAricleId] = useState();
  //change order
  const handleChangeOrder = (selectedOption) => {
    setOrderNum(selectedOption.value);

    props.getArticleByOrder(selectedOption.value);
  };
  //change Article
  const handleChangeArticle = (selectedOption) => {
    setAricleId(selectedOption.value);

    props.getDataByOrderArticle(selectedOption.value);
  };
  const sendData = () => {};

  useEffect(() => {
    props.getOrderCall();
  }, []);

  return (
    <Container fluid>
      <div className="main">
        <div>
          <Form>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>
                    <b> Select Order </b>
                  </Form.Label>

                  <Select
                    placeholder="Select Order..."
                    onChange={handleChangeOrder}
                    options={getOrder}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>
                    <b> Select Article </b>
                  </Form.Label>

                  <Select
                    placeholder="Select Article..."
                    onChange={handleChangeArticle}
                    options={getArticleByOrderIdList}
                  />
                </Form.Group>
              </Col>
            </Row>
            <div className="sendDiv">
              <Button className="sendButton" onClick={() => sendData()}>
                Save
              </Button>
            </div>

            <MaterialTable
              title="Production Details"
              columns={[
                { title: "Labor Job Name", field: "laborJObName" },
                { title: "Worker", field: "worker" },
                { title: "Aloc", field: "aloc" },
                { title: "Qty", field: "qty" },
                { title: "DueDate", field: "duedate" },
                { title: "Receiving Job", field: "receivingJob" },
                //   { title: 'Receiving', field: 'customerContry'},
              ]}
              //data={props.customerInfo}
              data={[
                {
                  laborJObName: "qasim",
                  worker: "entry",
                  aloc: "ll",
                  qty: "2",
                  duedate: "02-3-2020",
                  receivingJob: (
                    <Select
                      placeholder="Select Receiving Job"
                      onChange={setreceivingjobInfo}
                      options={receivejob}
                    />
                  ),
                },
              ]}
              actions={[
                (rowData) => {
                  return {
                    icon: EditIcon,
                    disabled: false,
                    onClick: (event, rowData) => {},
                  };
                },
                (rowData) => {
                  return {
                    icon: DeleteIcon,
                    disabled: false,
                    onClick: (event, rowData) => {},
                  };
                },
                (rowData) => {
                  return {
                    icon: SaveIcon,
                    disabled: false,
                    onClick: (event, rowData) => {},
                  };
                },
                (rowData) => {
                  return {
                    icon: PrintIcon,
                    disabled: false,
                    onClick: (event, rowData) => {},
                  };
                },
              ]}
              options={{
                actionsColumnIndex: -1,
                filtering: true,
                exportButton: true,
                exportAllData: true,
                maxBodyHeight: "600px",
                headerStyle: {
                  position: "sticky",
                  top: 0,
                  color: "#00BBBB",
                  fontWeight: "550",
                  onRowAdd: "none",
                },
              }}
            />
          </Form>
        </div>
      </div>
    </Container>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getArticleByOrder: (data) => dispatch(getArticleByOrder(data)),
    getOrderCall: () => dispatch(getOrderCall()),
    getDataByOrderArticle: (data) => dispatch(getDataByOrderArticle(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  orderInfo: state.OrderCreationReducer.orderInfo,
  articleByOrderId: state.productionDetailReducer.articleByOrderId,
  materialByArticleId: state.OrderCreationReducer.materialByArticleId,
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductionDetail);
