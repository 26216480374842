import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, Alert } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import "jspdf-autotable";
import { addBrandCall, getBrandCall } from "./production.actions";

const AddBrand = (props) => {
  const [brand, setBrand] = useState("");
  const [description, setDescription] = useState("");

  /* const [state, setState] = useState({
        columns: [
            { title: 'Brand', field: 'brandName' },
            { title: 'Brand Description', field: 'brandDescription' },
        ],
    });*/

  function sendData() {
    props.addBrandCall({
      brandName: brand,
      brandDescription: description,
    });
  }

  useEffect(() => {
    props.getBrandCall();
  }, []);

  return (
    <Container fluid>
      <div className="main">
        <div>
          <Form>
            <Row>
              <Col lg="12" md="12" xs="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Enter Brand:</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="brandDetail"
                    placeholder="Brand...."
                    onBlur={(e) => setBrand(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col lg="12" md="12" xs="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Description:</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="brandDescription"
                    placeholder="Description...."
                    onBlur={(e) => setDescription(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>

            <div className="sendDiv">
              <Button className="sendButton" onClick={() => sendData()}>
                Save
              </Button>
            </div>

            <MaterialTable
              title="Brand Details"
              columns={[
                { title: "Brand", field: "brandName" },
                { title: "Brand Description", field: "brandDescription" },
              ]}
              data={props.brandInfo}
              options={{
                actionsColumnIndex: -1,
                filtering: true,
                exportButton: true,
                exportAllData: true,
                maxBodyHeight: "600px",
                headerStyle: {
                  position: "sticky",
                  top: 0,
                  color: "#00BBBB",
                  fontWeight: "550",
                  onRowAdd: "none",
                },
              }}
              editable={{
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      // props.updateWareHouse(newData, oldData)
                    }, 600);
                  }),

                onRowDelete: (oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      //   props.deleteWareHouse(oldData.wareHouseId);
                    }, 600);
                  }),
              }}
            />
          </Form>
        </div>
      </div>
    </Container>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addBrandCall: (data) => dispatch(addBrandCall(data)),
    getBrandCall: () => dispatch(getBrandCall()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  brandInfo: state.productionReducer.brandInfo,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddBrand);
