import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import {isNull } from "lodash";
import AddExpense from "./AddExpense";
import ExpenseList from "./ExpenseList";
import ReportMarquee from "./ReportMarquee";

const TotalExpenseData = (props) => {
    const permissions = JSON.parse(localStorage.getItem("permissions"));

    return (
        <>
            <Tabs defaultActiveKey={!isNull(permissions) && permissions.some(p => p['sub_id'] === 96) ?
                    "AddExpense" : !isNull(permissions) && permissions.some(p => p['sub_id'] === 97) ?
                        "ExpenseList" :!isNull(permissions) && permissions.some(p => p['sub_id'] === 98) ?
                        "ExpenseReport" :  ""} id="AddExpense" className="mb-3">
                <Tab eventKey="AddExpense" title="Add Expense"
                disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 96) ? false : true}>  
                   <AddExpense />
                </Tab>
                <Tab eventKey="ExpenseList" title="Expense List"
               disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 97) ? false : true}> 
                    <ExpenseList/>
                </Tab>
                <Tab eventKey="ExpenseReport" title="Expense Report"
               disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 98) ? false : true}> 
                    <ReportMarquee/>
                </Tab>
            </Tabs>
        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
    };
};
const mapStateToProps = (state, ownProps) => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TotalExpenseData);