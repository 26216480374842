import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import {isNull } from "lodash";
import AddMasterControls from "./AddMasterControls";
import MasterControlList from "./MasterControlList"
const TotalMasterControlData = (props) => {
    const permissions = JSON.parse(localStorage.getItem("permissions"));

    return (
        <>
            <Tabs  defaultActiveKey={!isNull(permissions) && permissions.some(p => p['sub_id'] === 86) ?
                    "AddMasterControl" : !isNull(permissions) && permissions.some(p => p['sub_id'] === 87) ?
                        "MasterControlList" :  ""} id="AddMasterControl" className="mb-3">
                <Tab eventKey="AddMasterControl" title="Add MasterControl"
                disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 86) ? false : true}>  
                    <AddMasterControls />
                </Tab>
                <Tab eventKey="MasterControlList" title="MasterControl List"
                disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 87) ? false : true}> 
                    <MasterControlList/>
                </Tab>
            </Tabs>
        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
    };
};
const mapStateToProps = (state, ownProps) => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TotalMasterControlData);