import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import {isNull } from "lodash";
import AddSubEvent from "./AddSubEvent";
import SubEventList from"./SubEventList";
const TotalSubEventData = (props) => {
    const permissions = JSON.parse(localStorage.getItem("permissions"));

    return (
        <>
            <Tabs defaultActiveKey={!isNull(permissions) && permissions.some(p => p['sub_id'] === 94) ?
                    "AddSubEvent" : !isNull(permissions) && permissions.some(p => p['sub_id'] === 95) ?
                        "SubEventList" :  ""} id="AddSubEvent" className="mb-3">
                <Tab eventKey="AddSubEvent" title="Add SubEvent"
               disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 94) ? false : true}>  
                    <AddSubEvent />
                </Tab>
                <Tab eventKey="SubEventList" title="SubEvent List"
               disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 95) ? false : true}> 
                   <SubEventList/>
                </Tab>
            </Tabs>
        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
    };
};
const mapStateToProps = (state, ownProps) => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TotalSubEventData);