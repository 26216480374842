import React, { useEffect, useState, forwardRef } from "react";
import {
  InputGroup,
  Row,
  Col,
  Form,
  Table,
  Container,
  Button,
  FormGroup,
  Modal,
  Alert,
  Tabs,
  Tab,
} from "react-bootstrap";
import Select from "react-select";
//import { getSectorsList } from "../uploadData/uploadData.actions";
import { connect } from "react-redux";
import { isEmpty, get, map, isNull, find, isUndefined } from "lodash";
import moment from "moment";
import Spinner from "react-bootstrap/Spinner";
import {
  getLevel1Data,
  getLevelOneName,
  getLevelTwoName,
  getLevelFourName,
} from "./ProfitLoss.action";
import { TableCell } from "@material-ui/core";
import { TableRow } from "@material-ui/core";
import { TableHead } from "@material-ui/core";
import { Link } from "react-router-dom";
import MaterialTable from "material-table";

const ProfitLossDetail = (props) => {
  const [openingBalance, setOpeningBalance] = useState();
  const [closingBalance, setClosingBalance] = useState();

  let name1 = props.location.state.name1;
  let name2 = props.location.state.name2;
  let level1Balance = props.location.state.level1Balance;
  let level2Balance = props.location.state.level2Balance;
  let level3Balance = props.location.state.level3Balance;
  let level4Balance = props.location.state.level4Balance;
  let level5Balance = props.location.state.level5Balance;
  let level6Balance = props.location.state.level6Balance;
  let level7Balance = props.location.state.level7Balance;
  let level8Balance = props.location.state.level8Balance;
  let level9Balance = props.location.state.level9Balance;
  let level10Balance = props.location.state.level10Balance;
  console.log(name1, "nameeeeeeeeeeeeeeeeeeeeeee");
  console.log(name2, "nameeeeeeeeeeeeeeeeeeeeeee");
  console.log(level10Balance, "nameeeeeeeeeeeeeeeeeeeeeee");

  useEffect(() => {}, []);

  return (
    <>
      {props.isFetchinglevel1List ||
      props.isFetchingLevelTow ||
      props.isFetchingLevelThree ||
      props.isFetchingLevelFour ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div>
            <Row>
              <Col lg="6" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b>{isUndefined(name1) ? "" : name1}</b>
                  </Form.Label>
                  <br />
                  <input
                    type="text"
                    class="form-control"
                    id="name1"
                    value={!isUndefined(name1) ? name1 : ""}
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col lg="6" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b>PKR: </b>
                  </Form.Label>
                  <br />
                  <input
                    type="text"
                    class="form-control"
                    id="PKR1"
                    value={!isUndefined(level1Balance) ? level1Balance : ""}
                    readOnly
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <h3>Cost of Sales</h3>
            </Row>
            <br />
            <Row>
              <Col lg="4" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b>Opening Balance: </b>
                  </Form.Label>
                  <br />
                  <input
                    type="text"
                    class="form-control"
                    id="opening"
                    value={openingBalance}
                    onChange={(e) => setOpeningBalance(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col lg="4" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b> {isUndefined(name2) ? "" : name2} </b>
                  </Form.Label>
                  <br />
                  <input
                    type="text"
                    class="form-control"
                    id="level2Balance"
                    value={isUndefined(level2Balance) ? "" : level2Balance}
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col lg="4" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b>Less Closing Balance: </b>
                  </Form.Label>
                  <br />
                  <input
                    type="text"
                    class="form-control"
                    id="closing Balance"
                    value={closingBalance}
                    onChange={(e) => setClosingBalance(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg="9"></Col>
              <Col lg="3" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b>Total:</b>
                  </Form.Label>
                  <br />
                  <input
                    type="text"
                    class="form-control"
                    id="total"
                    value={
                      isUndefined(level2Balance) ||
                      isUndefined(openingBalance) ||
                      isUndefined(closingBalance)
                        ? 0
                        : Number(level2Balance) +
                          Number(openingBalance) -
                          Number(closingBalance)
                    }
                    readOnly
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg="12" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b>Gross Profit (Loss): </b>
                  </Form.Label>
                  <br />
                  <input
                    type="text"
                    class="form-control"
                    id="grossProfit"
                    value={
                      isUndefined(level2Balance) ||
                      isUndefined(level1Balance) ||
                      isUndefined(openingBalance) ||
                      isUndefined(closingBalance)
                        ? 0
                        : Math.abs(Number(level1Balance)) -
                          (Number(level2Balance) +
                            Number(openingBalance) -
                            Number(closingBalance))
                    }
                    readOnly
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg="12" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b>Total Expense: </b>
                  </Form.Label>
                  <br />
                  <input
                    type="text"
                    class="form-control"
                    id="expense"
                    value={
                      Number(level3Balance) +
                      Number(level4Balance) +
                      Number(level5Balance) +
                      Number(level6Balance) +
                      Number(level7Balance) +
                      Number(level8Balance) +
                      Number(level9Balance) +
                      Number(level10Balance)
                    }
                    readOnly
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg="12" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b>Net Profit: </b>
                  </Form.Label>
                  <br />
                  <input
                    type="text"
                    class="form-control"
                    id="net profit"
                    value={
                      isUndefined(level2Balance) ||
                      isUndefined(level1Balance) ||
                      isUndefined(openingBalance) ||
                      isUndefined(closingBalance)
                        ? 0
                        : Number(level2Balance) +
                          Number(openingBalance) -
                          Number(closingBalance) -
                          Number(level1Balance) -
                          Number(level3Balance) +
                          Number(level4Balance) +
                          Number(level5Balance) +
                          Number(level6Balance) +
                          Number(level7Balance) +
                          Number(level8Balance) +
                          Number(level9Balance) +
                          Number(level10Balance)
                    }
                    readOnly
                  />
                </Form.Group>
              </Col>
            </Row>
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getLevel1Data: () => dispatch(getLevel1Data()),
    getLevelOneName: (data) => dispatch(getLevelOneName(data)),
    getLevelTwoName: (data) => dispatch(getLevelTwoName(data)),
    getLevelFourName: (data) => dispatch(getLevelFourName(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  level1List: state.accountsLevelReducer.level1List,
  isFetchinglevel1List: state.accountsLevelReducer.isFetchinglevel1List,
  levelTow: state.accountsLevelReducer.levelTow,
  isFetchingLevelTow: state.accountsLevelReducer.isFetchingLevelTow,
  levelThree: state.accountsLevelReducer.levelThree,
  isFetchingLevelThree: state.accountsLevelReducer.isFetchingLevelThree,
  levelFour: state.accountsLevelReducer.levelFour,
  isFetchingLevelFour: state.accountsLevelReducer.isFetchingLevelFour,
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfitLossDetail);
