import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, FormGroup, Alert } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty, isNull, filter, upperCase } from "lodash";
import Spinner from 'react-bootstrap/Spinner';
import MaterialTable from 'material-table';
import 'jspdf-autotable';
import { getHRDivision, GetHRDesignation, addHRDesignation, GetHRDepartment, deleteDesignation, GetHRDepartmentByDivisionId } from "./HRDetails.actions";
import Select from 'react-select';
import Swal from 'sweetalert';

const AddDesignation = (props) => {
    const [divisionId, setDivisionId] = useState(0);

    const [designationName, setDesignationName] = useState("");
    const [designationDescription, setDesignationDescription] = useState();
    const [department, setDepartment] = useState(0);

    const [visibleAlert, setAlertVisible] = useState(false);
    const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);


    function uploadDesignation() {
        props.addHRDesignation({
            designationName: upperCase(designationName),
            designationDescription: upperCase(designationDescription),
            department_id: department,
            division_id:divisionId,
        }, handleVisible, handleDangerVisible);
    }
    const getDivisionList = !isEmpty(props.divisionList) && (props.divisionList.map((x) => { let data = { value: x.division_id, label: x.division_name }; return data; }));
    const getDepartmentNameList = (!isEmpty(props.departmentList)) ? (props.departmentList.map((x) => { let data = { value: x.departmentId, label: x.departmentName }; return data })) : [];
    // .filter((department) => { return department.division_id === divisionId})
    console.log('getDepartmentNameList',getDepartmentNameList);
    const designationData = props.designationList.reverse();

    // const selectedGetDepartmentNameList = getDepartmentNameList.filter((department) => { return department.value === divisionId});
    // console.log('selectedGetDepartmentNameList',selectedGetDepartmentNameList);


    const handleChangeDepartment = (selectedOption) => {
        setDepartment(selectedOption.value);
    };
    /*const [state, setState] = React.useState({
        columns: [
            { title: 'Designation Name', field: 'designationName' },
            { title: 'Designation Description', field: 'designationDescription' },
            { title: 'Department Name', field: 'department.departmentName', editable: 'never'  },
        ],
    });*/


    const handleChangeDivision = (selectedOption) => {
        setDivisionId(selectedOption.value);
        props.GetHRDepartmentByDivisionId(selectedOption.value);

    }
    const clearStates = () => {
        setDivisionId(0);
        setDesignationName();
        setDesignationDescription();
        setDepartment(0);

    }

    const handleVisible = () => {
        setAlertVisible(true);
        clearStates();
        setTimeout(() => {
            setAlertVisible(false)
        }, 9000);
        Swal({
            title: "Success",
            text: "Added successfully...",
            icon: "success",
            button: "Ok",
        });
    }

    const handleDangerVisible = () => {
        setDangerVisibleAlert(true);
        setTimeout(() => {
            setDangerVisibleAlert(false)
        }, 9000);
        Swal({
            title: "Error",
            text: "Something went wrong...",
            icon: "error",
            button: "Ok",
        });
    }

    useEffect(() => {
        props.getHRDivision();
    }, []);
    return (
        <>
            {props.isFetchingwareHouseList || props.isAddingDesignationData ? (
                <div className="loader-div">
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />
                        {/* {props.isFetchingwareHouseList ? 'Loading...' : 'Loading...'} */}

                    </Button>
                </div>
            ) : (
                <Container fluid>

                    <div className="main">

                        <div>
                            <Form>
                                <Row>
                                    <Col lg="12" md="12" xs="12">
                                        <Form.Group className="mb-3" controlId="garana" >
                                            <Form.Label><b>Select Division:</b></Form.Label>
                                            <Select
                                                placeholder="Select Division..."
                                                onChange={handleChangeDivision}
                                                options={getDivisionList}


                                            />

                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="12" md="12" xs="12">
                                        <Form.Group className="mb-3" controlId="garana" >
                                            <Form.Label><b>Select Department:</b></Form.Label>
                                            <Select
                                                isDisabled={props.isFetchingDepartmentData || divisionId === 0}
                                                placeholder="Select Department..."
                                                onChange={handleChangeDepartment}
                                                options={getDepartmentNameList}
                                            // options={getDepartmentNameList.filter((department) => { return department.division_id === divisionId})}

                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="12" md="12" xs="12">
                                        <Form.Group className="mb-3" controlId="garana" >
                                            <Form.Label><b>Enter Designation Name :</b></Form.Label>
                                            <Form.Control type="text" placeholder="Designation Name...." onBlur={(e) => setDesignationName(e.target.value)}
                                                disabled={(department === 0)}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>


                                <Row>
                                    <Col xl="12" lg="12" md="12" sm="12" xs="12">
                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                <b>Enter Designation Description:</b>
                                            </Form.Label>
                                            <Form.Control as="textarea" rows={3}
                                                placeholder="Designation Description....." onMouseLeave={(e) => setDesignationDescription(e.target.value)}
                                                disabled={(department === 0)}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <div className="sendDiv">
                                {(department === 0)||designationName==""?
                                    <Button className="sendButton" style={{backgroundColor:"black"}} onClick={uploadDesignation}
                                        disabled
                                    >Save</Button>:
                                     <Button className="sendButton" onClick={uploadDesignation}
                                       
                                    >Save</Button>}
                                </div>
                            </Form>

                        </div>

                    </div>

                </Container>

            )}
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {

        getHRDivision: () => dispatch(getHRDivision()),

        addHRDesignation: (data, handleVisible, handleDangerVisible) => dispatch(addHRDesignation(data, handleVisible, handleDangerVisible)),
        GetHRDepartmentByDivisionId: (divisionId) => dispatch(GetHRDepartmentByDivisionId(divisionId)),
        getHRDivision: () => dispatch(getHRDivision()),

    };
};
const mapStateToProps = (state, ownProps) => ({
    divisionList: state.HrReducer.divisionList,

    designationList: state.HrReducer.designationList,
    departmentList: state.HrReducer.departmentList,
    isFetchingDepartmentData: state.HrReducer.isFetchingDepartmentData,
    isFetchingDesignationList: state.HrReducer.isFetchingDesignationList,
    isAddingDesignationData: state.HrReducer.isAddingDesignationData


});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddDesignation);