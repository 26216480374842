import API, { baseURL } from "../../../../../global/api";
import { get, forEach,filter,isEmpty, lowerCase } from "lodash";
import jsPDF from "jspdf";
import logo from '../../../../../Images/logo.png'

export const requestPostSalaryData = () => {
    return {
        type: "REQUEST_POST_SALARY_DATA",
    };
};
export const successPostSalaryData = (data) => {
    return {
        type: "SUCCESS_POST_SALARY_DATA",
        payload: data,
    };
};
export const errorPostSalaryData = () => {
    return {
        type: "ERROR_POST_SALARY_DATA",
    };
};

export const postSalaryData = (data,type,department,handleVisible,handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestPostSalaryData());
        API.post(`/salary/genrate_salary`, data).then((res) => {
            let getData = res.data;
            console.log('get Company Salary Data', getData);
            const unit = "pt";
            const size = "A4"; // Use A1, A2, A3 or A4
            const orientation = "landscape"; // portrait or landscape

            const marginLeft = 100;
            const marginLeft1 = 500;
            const marginLeft2 = 530;
            const doc = new jsPDF(orientation, unit, size);
            console.log(process.env, 'env')
            doc.setFontSize(8);
            const title = "Salary Report";
            // const department=department
            const month = res.data.salary_month;
            const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
            const address = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
            const columns = [['Sr No.',
                'Code',
                'Employees Name',
                'Department\n________\n\nDesignation\n________\n\nD-O-J / D-O-B',
                'Salary & Allowance Detail',
                'Attendance Detail\n_________\n\nAllow Days\n_________\n\nEarned Salary',
                'O/T Hours\n_________\n\nOverTime Amount\n_________\n\nOverTime adj',
                'Incentive\n_________\n\nFlexy Amt\n_________\n\nAllowance',
                'Gross Salary',
                'Late Hours\n_________\n\nLateTime Amount\n_________\n\nLateTime adj',
                'LTA Deduction\n_________\n\nSTA Deduction\n_________\n\nFine',
                'Net Payable Salary',
                
                'Signature/Thumb',
                'Photo',
            ]];
        

            const data = getData.salary_details.map((x,index) => [
                index+1,
                // 'Sr No.\n_________\n\nCode'
                x.employeeId,
                // 'Employees Name'
                `${x.employee_name} ${x.gender=="Male"?`S/O ${x.father_name}`:`D-H/O ${x.father_name}`}`,
                // 'Designation\n________\n\nD-O-J / D-O-B'
                `${x.department}\n_________\n\n${x.designation}\n_________\n\n${x.date_of_join} / ${x.date_of_birth}`,
                // 'Salary & Allowance Detail'
                `B/P : ${x.basic_salary}\n${x.incrementType=="increment"?`Inc :${x.increment}`:x.incrementType=="decrement"?`Dec :${x.increment}`:""}\n,M/A : ${x.medicial_allowance}\nO/A : ${x.other_allowance}\nP/A : ${x.petrol_allowance}\nA/A : ${x.accomodation_allowance}\nS/A : ${x.special_allowance}`,
                // 'Attendance Detail'& 'Earned Salary'
                `${x.total_days_in_month}-------${x.present_days}\n__________\n\n${x.relaxation_leaves}\n__________\n\n${x.earned_salary}`,
                // 'O/T Hours\n_________\n\nOverTime Amount'
                `${x.overtime_hours}\n__________\n\n${x.overtime_amount}\n__________\n\n${x.over_time_adj}`,
                // 'Incentive\n_________\n\nFlexy Amt\n_________\n\nAllowance'
                x.total_allowance,
                // 'Gross Salary'
                x.gross_salary,
                //late time
                `${x.late_hours}\n_______\n\n${x.late_hours_amount}\n_______\n\n${x.late_time_adj}`,
                // 'Deductions Detail\n_________\n\nLTA Deduction\n\nSTA Deduction'
                `${x.long_term_advance}\n_______\n\n${x.short_term_advance}\n_______\n\n${x.fine_and_plenties}`,
                // 'Net Payable Salary'
                x.net_payable_salary,
                //image
                //  ""
                //   x.photo,
           
                
            ])

            // if (cell.raw === 'DESCRIPTION') {//paint.Name header red
            //     cell.styles.fontSize= 105;
            //    cell.styles.textColor = [255,0,0];
            // } else {
            //     cell.styles.textColor = 255;
            //     cell.styles.fontSize = 10;

            // }
            let content = {
                startY: 125,
                startX: 5,
                margin: { left: 5, right: 5 },
                theme: 'grid',
                cellWidth: 'wrap',
                head: columns,
                body: data,
                headStyles: { halign: 'center', valign: 'middle', lineWidth: 0.5, /*lineColor: [255, 255, 255], fontSize: 88*/ },
                bodyStyles: { valign: 'middle', halign: 'center', fontSize: 8, rowPageBreak: 'avoid' },
              //  columnStyles: { 12: { halign: 'center', fillColor: [0, 255, 0] } },
            //   willDrawCell: (data) => {
            //     console.log(data,"aaaaaaaaaaaaaaaa112233")
            //     if (data.section === 'body' && data.column.dataKey == 12) {
            //         // data.row.raw[12]
            //         // var base64Img = `${baseURL}get_image_data_by_path/${data.row.raw[12]}`
            //         const photo=  filter(getData.salary_details,{employeeId:(data.row.raw[0])})
            //         var base64Img = `${baseURL}get_image_data_by_path/${photo[0].photo}`
            //         //   console.log(photo,"testthrl")
            //         doc.addImage(base64Img, "jpg", 520, data.cell.y + 2, 30, 50)
            //        // console.log(cell, "Eeeee")
            //     }
            //         // console.log(data,"aaaaaaaaaaaaaaaa112233")
            //         // if (data.section === 'body' && data.column.dataKey == 12) {
            //         //   const photo=  filter(getData.salary_details,{employeeId:(data.row.raw[0])})
            //         //   console.log(photo,"testthrl")
            //         //   var dim = data.cell.height - data.cell.padding('vertical');
            //         //   var textPos = data.cell.textPos;
            //         //  doc.addImage(logo, "PNG",  textPos.x,  textPos.y, dim, dim));
            //         //    if (!isEmpty(photo)){
            //         //     var base64Img = `${baseURL}get_image_data_by_path/${photo[0].photo}`
            //         //     console.log(base64Img, "eeeeeeee")
                    
            //           //  doc.addImage(base64Img.src, `${lowerCase(photo[0].photo.split('.').pop())}`,data.cell.x + 2, data.cell.y + 2, 30, 50)
            //            }
                       

                       // console.log(cell, "Eeeee")
                    
                    // if (data.column.index === 12 && data.cell.section === 'body') {
                    //     var td = data.cell.raw;
                    //     var img = `${baseURL}get_image_data_by_path/${data.row.raw[12]}`
                    //     var dim = data.cell.height - data.cell.padding('vertical');
                    //     var textPos = data.cell.textPos;
                    //     doc.addImage(img.src, textPos.x,  textPos.y, dim, dim);
                    //  }
                    
            };
            //doc.addImage(logo, 'PNG', 40, 15, 100, 100);
            // doc.text(companyName, 200, 60, 0, 20);
            // doc.text(Dates, 200, 100, 0, 20);
            // doc.text(accountNo, marginLeft, 140, 0, 20);
            // doc.text(accountTitle, marginLeft, 160, 0, 20);
            doc.addImage(logo, 'png', 30, 23, 45, 45)
            doc.setFont(undefined, 'bold');
            doc.setFontSize(20);
            doc.text(title, marginLeft1, 40);
            doc.setFont(undefined, 'normal');
            doc.setFontSize(17);
            doc.text(month, marginLeft2, 70);
            doc.setFont(undefined, 'bold');
            doc.setFontSize(15);
            doc.text(companyName, marginLeft, 40)
            doc.setLineWidth(1);
            doc.line(100, 45, 328, 45);
            doc.setFont(undefined, 'normal');
            doc.setFontSize(10);
            doc.text(address, marginLeft, 60)
            // doc.setFont(undefined, 'bold');
            doc.setFontSize(16);
            doc.text(`Department Name: ${department}`,280,100)
            // doc.setFont(undefined, 'normal');
            doc.setFontSize(15);
            // doc.text(TotalCredit, marginLeft, 190);
            // doc.text(TotalDebit, marginLeft, 208);
            // doc.text(TotalBalance, marginLeft, 225);
            doc.autoTable(content);
            
            const headersTotal = [["Basic Salary", "Allowances", "Gross Salary","Earned Salary",]];
            const headersAdd = [["Over Time","Adjustment"]];
            const headersLess = [["Late Time","Long Term","Short Term","Adjustment","Fine & penalties" ]];
            const headersPayable = [["Net-Payable Salary"]];

            const tableData =[[getData.total_basic_salary, getData.total_allowances,getData.total_gross_salary,getData.total_earn_salary,getData.total_overtime,getData.total_latetime_adj,getData.total_LT,getData.total_ST,getData.total_latetime_adj,getData.total_net_payable_salary]]

            // startY: 125,
            // startX: 5,
            // margin: { left: 5, right: 5 },
            // theme: 'grid',
            // cellWidth: 'wrap',
            // head: columns,
            // body: data,
            // headStyles: { halign: 'center', valign: 'middle', lineWidth: 0.5, /*lineColor: [255, 255, 255], fontSize: 88*/ },
            // bodyStyles: { valign: 'middle', halign: 'center', fontSize: 8, rowPageBreak: 'avoid' },
            // doc.text("Total",)
            let contentTotal = {
                startY: doc.lastAutoTable.finalY + 20,
                startX: 5,
                head: headersTotal,
                0: { cellWidth: 130 },
                1: { cellWidth: 130 },
                2: { cellWidth: 130 },
                3: { cellWidth: 130 },
               
                body: [[
                { content: `${getData.total_basic_salary}`, rowSpan: 5 },
                 { content: `${getData.total_allowances}`, rowSpan: 5},
                 { content: `${getData.total_gross_salary}`,rowSpan: 5}, 
                 { content: `${getData.total_earn_salary}`, rowSpan: 5 },
                ]],
                theme: 'grid',
                headStyles: {
                    // fillColor: '#2980ba'
                },
        
                bodyStyles: {
                    minCellHeight: 200
                }
            };
             doc.text("Total",40,doc.lastAutoTable.finalY+15)
            doc.autoTable(contentTotal);
                        let contentAdd = {
                startY: doc.lastAutoTable.finalY + 20,
                startX: 5,
                head: headersAdd,
             
                0: { cellWidth: 130 },
                1: { cellWidth: 130 },
               
                body: [[
               
                 { content: `${getData.total_overtime}`, rowSpan: 5 },
                { content: `${getData.total_overtime_adj}`, rowSpan: 5 },
              ]],
                theme: 'grid',
                headStyles: {
                    // fillColor: '#2980ba'
                },
        
                bodyStyles: {
                    minCellHeight: 200
                }
            };
            doc.text("Add",40,doc.lastAutoTable.finalY+15)
            doc.autoTable(contentAdd);
                        let contentLess = {
                startY: doc.lastAutoTable.finalY + 20,
                startX: 5,
                head: headersLess,
              
                6: { cellWidth: 130 },
                7: { cellWidth: 130 },
                8: { cellWidth: 130 },
                9: { cellWidth: 130 },
                10: { cellWidth: 130 },
              
                body: [[
              
                { content: `${getData.total_latetime}`, rowSpan: 5 }, 
                { content: `${getData.total_LT}`, rowSpan: 5 }, 
                { content: `${getData.total_ST}`, rowSpan: 5 }, 
                { content: `${getData.total_latetime_adj}`, rowSpan: 5 },
                { content: `${getData.total_fine}`, rowSpan: 5 }, 

                 ]],
                theme: 'grid',
                headStyles: {
                    // fillColor: '#2980ba'
                },
        
                bodyStyles: {
                    minCellHeight: 200
                }
            };
            doc.text("Less",40,doc.lastAutoTable.finalY+15)
            doc.autoTable(contentLess);
                        let contentPayable = {
                startY: doc.lastAutoTable.finalY + 20,
                startX: 5,
                head: headersPayable,
          
                11: { cellWidth: 130 },
                body: [[
             

                 { content: `${getData.total_net_salary}`, rowSpan: 5 }]],
                theme: 'grid',
                headStyles: {
                    // fillColor: '#2980ba'
                },
        
                bodyStyles: {
                    minCellHeight: 200
                }
            };
            doc.text("Net-payable",40,doc.lastAutoTable.finalY+15)
            doc.autoTable(contentPayable);
        //         var string = doc.output('datauristring','dddd'); 
        // var embed = "<embed width='100%' height='100%' src='" + string + "'/>"
        // var x = window.open('Demand Report');
        // x.document.open('Demand Report');
        // x.document.write(embed);
        // x.document.close();
             doc.save(`${type} Company Salary of ${month}.pdf`);
             console.log(res,"resssssssss")
            dispatch(successPostSalaryData(getData));
            //  handleVisible()
        }).catch((error) => {
            console.log(error,error.response,"ttttttttttttttttttttt")
            
            dispatch(errorPostSalaryData());
            handleDangerVisible(error.response.data.message)
        })
    };
};
export const requestSalaryHistoryData = () => {
    return {
        type: "REQUEST_SALARY_HISTORY_DATA",
    };
};
export const successSalaryHistoryData = (data) => {
    return {
        type: "SUCCESS_SALARY_HISTORY_DATA",
        payload: data,
    };
};
export const errorSalaryHistoryData = () => {
    return {
        type: "ERROR_SALARY_HISTORY_DATA",
    };
};

export const salaryHistoryData = (data,handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestSalaryHistoryData());
        API.post(`/salary/salary_history`, data).then((res) => {
            let getData = res.data;
            console.log('get Company Salary Data', getData);
            const unit = "pt";
            const size = "A4"; // Use A1, A2, A3 or A4
            const orientation = "landscape"; // portrait or landscape

            const marginLeft = 100;
            const marginLeft1 = 500;
            const marginLeft2 = 530;
            const doc = new jsPDF(orientation, unit, size);
            console.log(process.env, 'env')
            doc.setFontSize(8);
            const title = "Salary Report";
            // const department=department
            const month = res.data.salary_month;
            const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
            const address = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
            const columns = [['Sr No.',
                'Code',
                'Employees Name',
                'Department\n________\n\nDesignation\n________\n\nD-O-J / D-O-B',
                'Salary & Allowance Detail',
                'Attendance Detail\n_________\n\nAllow Days\n_________\n\nEarned Salary',
                'O/T Hours\n_________\n\nOverTime Amount\n_________\n\nOverTime adj',
                'Incentive\n_________\n\nFlexy Amt\n_________\n\nAllowance',
                'Gross Salary',
                'Late Hours\n_________\n\nLateTime Amount\n_________\n\nLateTime adj',
                'LTA Deduction\n_________\n\nSTA Deduction\n_________\n\nFine',
                'Net Payable Salary',
                
                'Signature/Thumb',
                'Photo',
            ]];
        

            const data = getData.data.map((x,index) => [
                index+1,
                // 'Sr No.\n_________\n\nCode'
                x.employeeId,
                // 'Employees Name'
                `${x.employee_name} ${x.gender=="Male"?`S/O ${x.father_name}`:`D-H/O ${x.father_name}`}`,
                // 'Designation\n________\n\nD-O-J / D-O-B'
                `${x.department}\n_________\n\n${x.designation}\n_________\n\n${x.date_of_join} / ${x.date_of_birth}`,
                // 'Salary & Allowance Detail'
                `B/P : ${x.basic_salary}\n${x.incrementType=="increment"?`Inc :${x.increment}`:x.incrementType=="decrement"?`Dec :${x.increment}`:""}\n,M/A : ${x.medicial_allowance}\nO/A : ${x.other_allowance}\nP/A : ${x.petrol_allowance}\nA/A : ${x.accomodation_allowance}\nS/A : ${x.special_allowance}`,
                // 'Attendance Detail'& 'Earned Salary'
                `${x.total_days_in_month}-------${x.present_days}\n__________\n\n${x.relaxation_leaves}\n__________\n\n${x.earned_salary}`,
                // 'O/T Hours\n_________\n\nOverTime Amount'
                `${x.overtime_hours}\n__________\n\n${x.overtime_amount}\n__________\n\n${x.over_time_adj}`,
                // 'Incentive\n_________\n\nFlexy Amt\n_________\n\nAllowance'
                x.total_allowance,
                // 'Gross Salary'
                x.gross_salary,
                //late time
                `${x.late_hours}\n_______\n\n${x.late_hours_amount}\n_______\n\n${x.late_time_adj}`,
                // 'Deductions Detail\n_________\n\nLTA Deduction\n\nSTA Deduction'
                `${x.long_term_advance}\n_______\n\n${x.short_term_advance}\n_______\n\n${x.fine_and_plenties}`,
                // 'Net Payable Salary'
                x.net_payable_salary,
                //image
                //  ""
                //   x.photo,
           
                
            ])

            // if (cell.raw === 'DESCRIPTION') {//paint.Name header red
            //     cell.styles.fontSize= 105;
            //    cell.styles.textColor = [255,0,0];
            // } else {
            //     cell.styles.textColor = 255;
            //     cell.styles.fontSize = 10;

            // }
            let content = {
                startY: 125,
                startX: 5,
                margin: { left: 5, right: 5 },
                theme: 'grid',
                cellWidth: 'wrap',
                head: columns,
                body: data,
                headStyles: { halign: 'center', valign: 'middle', lineWidth: 0.5, /*lineColor: [255, 255, 255], fontSize: 88*/ },
                bodyStyles: { valign: 'middle', halign: 'center', fontSize: 8, rowPageBreak: 'avoid' },
              //  columnStyles: { 12: { halign: 'center', fillColor: [0, 255, 0] } },
            //   willDrawCell: (data) => {
            //     console.log(data,"aaaaaaaaaaaaaaaa112233")
            //     if (data.section === 'body' && data.column.dataKey == 12) {
            //         // data.row.raw[12]
            //         // var base64Img = `${baseURL}get_image_data_by_path/${data.row.raw[12]}`
            //         const photo=  filter(getData.salary_details,{employeeId:(data.row.raw[0])})
            //         var base64Img = `${baseURL}get_image_data_by_path/${photo[0].photo}`
            //         //   console.log(photo,"testthrl")
            //         doc.addImage(base64Img, "jpg", 520, data.cell.y + 2, 30, 50)
            //        // console.log(cell, "Eeeee")
            //     }
            //         // console.log(data,"aaaaaaaaaaaaaaaa112233")
            //         // if (data.section === 'body' && data.column.dataKey == 12) {
            //         //   const photo=  filter(getData.salary_details,{employeeId:(data.row.raw[0])})
            //         //   console.log(photo,"testthrl")
            //         //   var dim = data.cell.height - data.cell.padding('vertical');
            //         //   var textPos = data.cell.textPos;
            //         //  doc.addImage(logo, "PNG",  textPos.x,  textPos.y, dim, dim));
            //         //    if (!isEmpty(photo)){
            //         //     var base64Img = `${baseURL}get_image_data_by_path/${photo[0].photo}`
            //         //     console.log(base64Img, "eeeeeeee")
                    
            //           //  doc.addImage(base64Img.src, `${lowerCase(photo[0].photo.split('.').pop())}`,data.cell.x + 2, data.cell.y + 2, 30, 50)
            //            }
                       

                       // console.log(cell, "Eeeee")
                    
                    // if (data.column.index === 12 && data.cell.section === 'body') {
                    //     var td = data.cell.raw;
                    //     var img = `${baseURL}get_image_data_by_path/${data.row.raw[12]}`
                    //     var dim = data.cell.height - data.cell.padding('vertical');
                    //     var textPos = data.cell.textPos;
                    //     doc.addImage(img.src, textPos.x,  textPos.y, dim, dim);
                    //  }
                    
            };
            //doc.addImage(logo, 'PNG', 40, 15, 100, 100);
            // doc.text(companyName, 200, 60, 0, 20);
            // doc.text(Dates, 200, 100, 0, 20);
            // doc.text(accountNo, marginLeft, 140, 0, 20);
            // doc.text(accountTitle, marginLeft, 160, 0, 20);
            doc.addImage(logo, 'png', 30, 23, 45, 45)
            doc.setFont(undefined, 'bold');
            doc.setFontSize(20);
            doc.text(title, marginLeft1, 40);
            doc.setFont(undefined, 'normal');
            doc.setFontSize(17);
            doc.text(month, marginLeft2, 70);
            doc.setFont(undefined, 'bold');
            doc.setFontSize(15);
            doc.text(companyName, marginLeft, 40)
            doc.setLineWidth(1);
            doc.line(100, 45, 328, 45);
            doc.setFont(undefined, 'normal');
            doc.setFontSize(10);
            doc.text(address, marginLeft, 60)
            // doc.setFont(undefined, 'bold');
            // doc.setFontSize(16);
            // doc.text(`Department Name: ${department}`,280,100)
            // doc.setFont(undefined, 'normal');
            doc.setFontSize(15);
            // doc.text(TotalCredit, marginLeft, 190);
            // doc.text(TotalDebit, marginLeft, 208);
            // doc.text(TotalBalance, marginLeft, 225);
            doc.autoTable(content);
            
            const headersTotal = [["Basic Salary", "Allowances", "Gross Salary","Earned Salary",]];
            const headersAdd = [["Over Time","Adjustment"]];
            const headersLess = [["Late Time","Long Term","Short Term","Adjustment","Fine & penalties" ]];
            const headersPayable = [["Net-Payable Salary"]];

            const tableData =[[getData.total_basic_salary, getData.total_allowances,getData.total_gross_salary,getData.total_earn_salary,getData.total_overtime,getData.total_latetime_adj,getData.total_LT,getData.total_ST,getData.total_latetime_adj,getData.total_net_payable_salary]]

            // startY: 125,
            // startX: 5,
            // margin: { left: 5, right: 5 },
            // theme: 'grid',
            // cellWidth: 'wrap',
            // head: columns,
            // body: data,
            // headStyles: { halign: 'center', valign: 'middle', lineWidth: 0.5, /*lineColor: [255, 255, 255], fontSize: 88*/ },
            // bodyStyles: { valign: 'middle', halign: 'center', fontSize: 8, rowPageBreak: 'avoid' },
            // doc.text("Total",)
            let contentTotal = {
                startY: doc.lastAutoTable.finalY + 20,
                startX: 5,
                head: headersTotal,
                0: { cellWidth: 130 },
                1: { cellWidth: 130 },
                2: { cellWidth: 130 },
                3: { cellWidth: 130 },
               
                body: [[
                { content: `${getData.total_basic_salary}`, rowSpan: 5 },
                 { content: `${getData.total_allowances}`, rowSpan: 5},
                 { content: `${getData.total_gross_salary}`,rowSpan: 5}, 
                 { content: `${getData.total_earn_salary}`, rowSpan: 5 },
                ]],
                theme: 'grid',
                headStyles: {
                    // fillColor: '#2980ba'
                },
        
                bodyStyles: {
                    minCellHeight: 200
                }
            };
             doc.text("Total",40,doc.lastAutoTable.finalY+15)
            doc.autoTable(contentTotal);
                        let contentAdd = {
                startY: doc.lastAutoTable.finalY + 20,
                startX: 5,
                head: headersAdd,
             
                0: { cellWidth: 130 },
                1: { cellWidth: 130 },
               
                body: [[
               
                 { content: `${getData.total_overtime}`, rowSpan: 5 },
                { content: `${getData.total_overtime_adj}`, rowSpan: 5 },
              ]],
                theme: 'grid',
                headStyles: {
                    // fillColor: '#2980ba'
                },
        
                bodyStyles: {
                    minCellHeight: 200
                }
            };
            doc.text("Add",40,doc.lastAutoTable.finalY+15)
            doc.autoTable(contentAdd);
                        let contentLess = {
                startY: doc.lastAutoTable.finalY + 20,
                startX: 5,
                head: headersLess,
              
                6: { cellWidth: 130 },
                7: { cellWidth: 130 },
                8: { cellWidth: 130 },
                9: { cellWidth: 130 },
                10: { cellWidth: 130 },
              
                body: [[
              
                { content: `${getData.total_latetime}`, rowSpan: 5 }, 
                { content: `${getData.total_LT}`, rowSpan: 5 }, 
                { content: `${getData.total_ST}`, rowSpan: 5 }, 
                { content: `${getData.total_latetime_adj}`, rowSpan: 5 },
                { content: `${getData.total_fine}`, rowSpan: 5 }, 

                 ]],
                theme: 'grid',
                headStyles: {
                    // fillColor: '#2980ba'
                },
        
                bodyStyles: {
                    minCellHeight: 200
                }
            };
            doc.text("Less",40,doc.lastAutoTable.finalY+15)
            doc.autoTable(contentLess);
                        let contentPayable = {
                startY: doc.lastAutoTable.finalY + 20,
                startX: 5,
                head: headersPayable,
          
                11: { cellWidth: 130 },
                body: [[
             

                 { content: `${getData.total_net_salary}`, rowSpan: 5 }]],
                theme: 'grid',
                headStyles: {
                    // fillColor: '#2980ba'
                },
        
                bodyStyles: {
                    minCellHeight: 200
                }
            };
            doc.text("Net-payable",40,doc.lastAutoTable.finalY+15)
            doc.autoTable(contentPayable);
        //         var string = doc.output('datauristring','dddd'); 
        // var embed = "<embed width='100%' height='100%' src='" + string + "'/>"
        // var x = window.open('Demand Report');
        // x.document.open('Demand Report');
        // x.document.write(embed);
        // x.document.close();
             doc.save(`Company Salary.pdf`);
             console.log(res,"resssssssss")
            
            dispatch(successSalaryHistoryData(getData));
           console.log(res,"rrrrrrrrrrrrrrrrrrrr")
            //  handleVisible(res.data)
        }).catch((error) => {
            console.log(error,error.response,"ttttttttttttttttttttt")
            dispatch(errorSalaryHistoryData());
            handleDangerVisible(error.response.data.message)
        })
    };
};