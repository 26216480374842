import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import {isNull } from "lodash";
import AddDesignation from "./AddDesignation";
import DesignationList from "./DesignationList";
const TotalDesignationData = (props) => {
    const permissions = JSON.parse(localStorage.getItem("permissions"));

    return (
        <>
            <Tabs defaultActiveKey={!isNull(permissions) && permissions.some(p => p['sub_id'] === 50) ?
                    "AddDesignation" : !isNull(permissions) && permissions.some(p => p['sub_id'] === 51) ?
                        "DesignationList" : ""}  id="AddDesignation" className="mb-3">
                <Tab eventKey="AddDesignation" title="Add Designation"
                disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 50) ? false : true}>  
                    <AddDesignation />
                </Tab>
                <Tab eventKey="DesignationList" title="Designation List"
                disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 51) ? false : true}> 
                    <DesignationList />
                </Tab>
            </Tabs>
        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
    };
};
const mapStateToProps = (state, ownProps) => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TotalDesignationData);