import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
//import App from './App';
import { ConnectedRouter } from 'connected-react-router';
import { Redirect } from 'react-router-dom';
import { Route } from 'react-router-dom';
import configureStore, { history } from './configureStore';
import "bootstrap/dist/css/bootstrap.min.css";
import "./views/user_views/assets/css/animate.min.css";
import "./views/user_views/assets/scss/light-bootstrap-dashboard-react.scss?v=2.0.0";
import "./views/user_views/assets/css/demo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import UserResponsiveDrawer from "../src/views/user_views/drawer/ResponsiveDrawer";
import AdminResponsiveDrawer from "../src/views/admin_views/drawer/ResponsiveDrawer";
import ProtectedRoute from './ProtectedRoute.js';
import Login from "../src/views/user_views/accounts/Login";
import LoginAdmin from "../src/views/admin_views/accounts/Login"
import UserMainPage from './views/user_views/accounts/UserMainPage';
import AdminMainPage from './views/admin_views/accounts/AdminMainPage';

const store = configureStore()
const renderApp = () =>
  render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Route path="/login" component={Login} />
        <Route path="/admin-login" component={LoginAdmin} />
        <ProtectedRoute path="/home" component={UserMainPage} />
        <ProtectedRoute path='/user' component={UserResponsiveDrawer} />
        <ProtectedRoute path="/admin-home" component={AdminMainPage} />
        <ProtectedRoute path='/admin' component={AdminResponsiveDrawer} />
        <Route exact path="/" >
          <Redirect to="/login" />
        </Route>
      </ConnectedRouter>
    </Provider>,
    document.getElementById('root')
  )

// if (process.env.NODE_ENV !== 'production' && module.hot) {
//   module.hot.accept('../src/views/user_views/drawer/ResponsiveDrawer', renderApp)
// }
if (module.hot) {
  module.hot.accept();
}

renderApp()