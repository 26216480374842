import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Container,
  Button,
  Alert,
  Tabs,
  Tab,
} from "react-bootstrap";
import "./add-levels.css";
import {
  AddLevel4Name,
  getLevel4Data,
  getLevel1Data,
  getLevel2Data,
  getLevel3Data,
  getLevelOneName,
  getLevelTwoName,
} from "./accountsLevel.action";
import { connect } from "react-redux";
import { isEmpty, map, find, toUpper } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import ReactExport from "react-export-excel";
import Select from "react-select";
import Swal from "sweetalert";
import PDFLevel4 from "./PDFLevel4";
import Level4Upload from "./Level4Upload";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Level4Tap from "./Level4Tap";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const AddLevel4 = (props) => {
  const [level1Id, setLevel1Id] = useState();
  const [level1data, setLevel1data] = useState();
  const [level2Id, setLevel2Id] = useState();
  const [level2Data, setLevel2data] = useState();
  const [level3Id, setLevel3Id] = useState();
  const [level3label, setLevel3label] = useState();
  const [level4, setLevel4Name] = useState();
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  const handleVisible = () => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: "Added successfully...",
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = () => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: "Something went wrong...",
      icon: "error",
      button: "Ok",
    });
  };
  // add level
  const getLevel1List = props.level1List.map((x) => {
    let data = { value: x.levelOneId, label: x.name };
    return data;
  });
  const handleChangeLevel1 = (selectedOption) => {
    handleChangeLevel2(selectedOption);
    props.getLevelOneName(selectedOption.value);
    setLevel1Id(selectedOption.value);
    setLevel1data(selectedOption.label);
  };
  const getLevelTwoList =
    !isEmpty(props.levelTow) &&
    props.levelTow.map((x) => {
      let data = {
        value: x.levelTwoData.levelTwoId,
        label: x.levelTwoData.levelTwoName,
      };
      return data;
    });
  const handleChangeLevel2 = (selectedOption) => {
    setLevel2Id(selectedOption.value);
    setLevel2data(selectedOption.label);
    props.getLevelTwoName(selectedOption.value);
    // setLevel1Id(find(props.level2List, x => x.levelTwoData.levelTwoId == selectedOption.value).levelTwoData.levelOneId)
  };
  const getlevel3val =
    !isEmpty(props.levelThree) &&
    props.levelThree.map((x) => {
      let data = {
        value: x.levelTwoData.levelThreeId,
        label: x.levelTwoData.levelThreeName,
      };
      return data;
    });
  // end level
  function uploadLevel4() {
    props.AddLevel4Name(
      {
        levelOneId: String(level1Id),
        levelTwoId: String(level1Id),
        levelThreeId: String(level3Id),
        levelFourName: toUpper(level4),
      },
      handleVisible,
      handleDangerVisible
    );
    setLevel1data("");
    setLevel2data("");
    setLevel3label("");
    document.getElementById("level4id").value = "";
  }

  // const getLevel3List = !isEmpty(props.level3List) && props.level3List.map((x) => { let data = { value: x.levelThreeData.levelThreeId, label: x.levelThreeData.levelThreeName }; return data });
  const handleChangeLevel3 = (selectedOption) => {
    setLevel3label(selectedOption.label);
    setLevel3Id(selectedOption.value);
    // setLevel1Id(find(props.level3List, x => x.levelThreeData.levelThreeId == selectedOption.value).levelThreeData.levelOneId);
    // setLevel2Id(find(props.level3List, x => x.levelThreeData.levelThreeId == selectedOption.value).levelThreeData.levelTwoId)
  };

  const level4Data = props.level4List;

  useEffect(() => {
    props.getLevel1Data();
    // props.getLevel2Data();
    // props.getLevel3Data();
    // props.getLevel4Data();
  }, []);
  return (
    <>
      {props.isFetchinglevel1List ||
      props.isFetchingLevelTow ||
      props.isFetchingLevelThree ||
      props.isSavingLevel4 ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {props.isSavingLevel4 ? "Saving" : "Loading"}
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <div>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>
                      <b> Select Level 1 * </b>
                    </Form.Label>
                    <Select
                      value={{ label: level1data }}
                      placeholder="Select Level 1..."
                      onChange={handleChangeLevel1}
                      options={getLevel1List}
                    />
                  </Form.Group>
                </Col>
              </Row>
              {/* <Row>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>
                                                    <b> Select Level 2 </b>
                                                </Form.Label>
                                                <Select
                                                value = {{label: level2Data}}
                                                 isDisabled={isEmpty(getLevelTwoList) || isEmpty(level1data)}
                                                    placeholder="Select Level 2..."
                                                     onChange={handleChangeLevel2}
                                                      options={getLevelTwoList}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row> */}
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>
                      <b> Select Level 2 *</b>
                    </Form.Label>
                    <Select
                      isDisabled={isEmpty(getlevel3val) || isEmpty(level1data)}
                      value={{ label: level3label }}
                      placeholder="Select Level 3..."
                      onChange={handleChangeLevel3}
                      options={getlevel3val}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg="12" md="12" xs="12">
                  <Form.Group className="mb-3" controlId="garana">
                    <Form.Label>
                      <b>Enter Level 3 Name *</b>
                    </Form.Label>
                    {!level3Id ? (
                      <Form.Control
                        type="text"
                        placeholder="Level Name...."
                        readOnly
                      />
                    ) : (
                      <Form.Control
                        type="text"
                        id="level4id"
                        placeholder="Level Name...."
                        onMouseLeave={(e) => setLevel4Name(e.target.value)}
                      />
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <div className="sendDiv">
                {isEmpty(level4) && isEmpty(level4) ? (
                  <Button
                    disabled
                    style={{
                      marginLeft: "2%",
                      color: "white",
                      width: "20%",
                      backgroundColor: "black",
                      border: "1px solid black",
                    }}
                  >
                    Save
                  </Button>
                ) : (
                  <Button className="sendButton" onClick={uploadLevel4}>
                    Save
                  </Button>
                )}
              </div>
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLevel1Data: () => dispatch(getLevel1Data()),
    getLevel2Data: () => dispatch(getLevel2Data()),
    getLevel3Data: () => dispatch(getLevel3Data()),
    getLevel4Data: () => dispatch(getLevel4Data()),
    AddLevel4Name: (data, handleVisible, handleDangerVisible) =>
      dispatch(AddLevel4Name(data, handleVisible, handleDangerVisible)),
    getLevelOneName: (data) => dispatch(getLevelOneName(data)),
    getLevelTwoName: (data) => dispatch(getLevelTwoName(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  levelTow: state.accountsLevelReducer.levelTow,
  levelThree: state.accountsLevelReducer.levelThree,
  level1List: state.accountsLevelReducer.level1List,
  level2List: state.accountsLevelReducer.level2List,
  level3List: state.accountsLevelReducer.level3List,
  level4List: state.accountsLevelReducer.level4List,
  isFetchinglevel4List: state.accountsLevelReducer.isFetchinglevel4List,
  isAddingLevel4: state.accountsLevelReducer.isAddingLevel4,
  isNotAddLevel4: state.accountsLevelReducer.isNotAddLevel4,
  isFetchinglevel1List: state.accountsLevelReducer.isFetchinglevel1List,
  isFetchingLevelTow: state.accountsLevelReducer.isFetchingLevelTow,
  isFetchingLevelThree: state.accountsLevelReducer.isFetchingLevelThree,
  isSavingLevel4: state.accountsLevelReducer.isSavingLevel4,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddLevel4);
