import React from "react";
import { Card, Tabs, Tab, Button } from "react-bootstrap";
import { connect } from "react-redux";
import Level4 from "./Level4";
import Level4Tap from "./Level4Tap";
import Level4Upload from "./Level4Upload";
import Level4Report from "./Level4Report";
import { Link } from "react-router-dom";
import { isNull } from "lodash";

const Level4Tabs = (props) => {
  const permissions = JSON.parse(localStorage.getItem("permissions"));

  return (
    <>
      <Tabs
        defaultActiveKey={
          !isNull(permissions) && permissions.some((p) => p["sub_id"] === 37)
            ? "LevelData"
            : !isNull(permissions) &&
              permissions.some((p) => p["sub_id"] === 38)
            ? "Level 3 Details"
            : !isNull(permissions) &&
              permissions.some((p) => p["sub_id"] === 39)
            ? "Level 3 Upload"
            : !isNull(permissions) &&
              permissions.some((p) => p["sub_id"] === 40)
            ? "Level 3 Report"
            : ""
        }
        id="level4Data"
        className="mb-3"
        unmountOnExit={true}
      >
        <Tab
          eventKey="LevelData"
          title="Add Level 3"
          unmountOnExit={true}
          disabled={
            !isNull(permissions) && permissions.some((p) => p["sub_id"] === 37)
              ? false
              : true
          }
        >
          <Level4 />
        </Tab>

        <Tab
          eventKey="Level 3 Details"
          title="Level 3 Details"
          unmountOnExit={true}
          disabled={
            !isNull(permissions) && permissions.some((p) => p["sub_id"] === 38)
              ? false
              : true
          }
        >
          <div className="sendDiv">
            <Link to="/user/level4-details">
              {" "}
              <Button className="sendButton">View Details</Button>
            </Link>
          </div>
        </Tab>

        <Tab
          eventKey="Level 3 Upload"
          title="Level 3 Upload"
          unmountOnExit={true}
          disabled={
            !isNull(permissions) && permissions.some((p) => p["sub_id"] === 39)
              ? false
              : true
          }
        >
          <Level4Upload />
        </Tab>

        <Tab
          eventKey="Level 3 Report"
          title="Level 3 Report"
          unmountOnExit={true}
          disabled={
            !isNull(permissions) && permissions.some((p) => p["sub_id"] === 40)
              ? false
              : true
          }
        >
          <Level4Report />
        </Tab>
      </Tabs>
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
const mapStateToProps = (state, ownProps) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Level4Tabs);
