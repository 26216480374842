import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import AddCategory from "./AddCategory";
import CategoryTableList from "./CategoryTableList";
import AddWarehouse from "./AddWarehouse";
import WareHouseTableList from "./WareHouseTableList";
import {isNull } from "lodash";
import AddInventory from "./AddInventory";
import AddInventoryDetails from "./AddInventoryDetails";
import StockList from "./StockList";
import InventoryReport from "./InventoryReport";
const TotalInventoryData = (props) => {
    const permissions = JSON.parse(localStorage.getItem("permissions"));

    return (
        <>
            {/* <Tabs
                defaultActiveKey={!isNull(permissions) && permissions.some(p => p['sub_id'] === 1) ?
                    "CategoryList" : !isNull(permissions) && permissions.some(p => p['sub_id'] === 2) ?
                        "AddCategory" : ""}
                id="AddCategory"
                className="mb-3"
                unmountOnExit={true}>
                <Tab
                    eventKey="CategoryList"
                    title="Category List"
                    unmountOnExit={true}
                    disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 1) ? false : true}>
                    <CategoryTableList />
                </Tab>
                <Tab
                    eventKey="AddCategory"
                    title="Add Category"
                    unmountOnExit={true}
                    disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 2) ? false : true}>
                    <AddCategory />
                </Tab>
            </Tabs> */}

            <Tabs defaultActiveKey={!isNull(permissions) && permissions.some(p => p['sub_id'] === 111) ?
                    "AddInventory" : !isNull(permissions) && permissions.some(p => p['sub_id'] === 112) ?
                        "InventoryUpload" :
                        !isNull(permissions) && permissions.some(p => p['sub_id'] === 113) ?
                        "InventoryStockList" :
                        !isNull(permissions) && permissions.some(p => p['sub_id'] === 114) ?
                        "InventoryReport" : ""} id="AddInventory" className="mb-3">
                <Tab eventKey="AddInventory" title="Add Inventory"
                disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 111) ? false : true}>  
                    <AddInventory />
                </Tab>
                <Tab eventKey="InventoryUpload" title="Inventory Upload"
                disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 112) ? false : true}> 
                    <AddInventoryDetails/>
                </Tab>
                <Tab eventKey="InventoryStockList" title="Inventory Stock List"
                disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 113) ? false : true}> 
                    <StockList/>
                </Tab>
                <Tab eventKey="InventoryReport" title="Inventory Report"
                disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 114) ? false : true}> 
                    <InventoryReport/>
                </Tab>
            </Tabs>
        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
    };
};
const mapStateToProps = (state, ownProps) => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TotalInventoryData);