import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Container,
  Button,
  FormGroup,
  Alert,
} from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty, isNull, upperCase } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import DeleteIcon from "@mui/icons-material/Delete";
import "jspdf-autotable";
import { filter } from "lodash";
import Modal from "react-bootstrap/Modal";
import SendIcon from "@mui/icons-material/Send";
import Select from "react-select";
import Swal from "sweetalert";
import {
  PostInvoice,
  deleteInvoice,
  getInvoice,
  getInvoicePdf,
} from "./InvoiceDetails.actions";
import { getLevel5Data } from "../../accountsLevels/accountsLevel.action";
import { getAllInventory } from "../AddDetails.actions";
import { baseURL, headers } from "../../../../../global/api";

const InvoiceList = (props) => {
  const [show, setShow] = useState(false);
  const [typeAccount, setType] = useState("");
  const [cashaccountNo, setCashaccountNo] = useState("");
  const [id, setId] = useState();
  const handleShow = (rowData) => {
    setId(rowData);
    setShow(true);
  };
  const handleClose = () => setShow(false);
  function handlePost() {
    props.PostInvoice({
      levelFiveId: cashaccountNo,
      type: typeAccount,
      invoice_id: id,
    });
    handleClose();
  }
  const cashAccountNoTop =
    !isEmpty(props.level5List) &&
    props.level5List
      // .filter((x) => x.levelfiveData.levelFourId == level4Id)
      .map((y) => {
        let data = {
          value: y.levelfiveData.levelFiveId,
          label:
            y.levelfiveData.allLevelKey + " / " + y.levelfiveData.levelFiveName,
          values: y.levelFour.levelFourId,
        };
        return data;
      });
  const handleChangeAccountNo = (selectedOption) => {
    setCashaccountNo(selectedOption.value);
  };
  useEffect(() => {
    // props.getAllInventory({
    //   startedDate: "",
    //   endDate: "",
    //   wareHouseId: "",
    //   subCategoryId: "",
    //   categoryId: "",
    // });
    // // props.GetInvoice();
    // props.getLevel5Data();
  }, []);
  return (
    <>
      {props.isFetchingInvoice ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {/* {props.isFetchingwareHouseList ? 'Loading...' : 'Loading...'} */}
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <div>
              <Form>
                <MaterialTable
                  title="Invoice Detail"
                  columns={[
                    { title: "Invoice ID", field: "invoice_id" },
                    { title: "Invoice Date", field: "invoice_date" },
                    {
                      title: "Customer Name",
                      field: "level_five.levelFiveName",
                    },
                    { title: "Invoice Description", field: "invoice_desc" },
                    { title: "Grand Total", field: "invoice_total_amount" },
                    { title: "Invoice Status", field: "invoice_status" },
                  ]}
                  // data={props.invoiceData}
                  data={(query) =>
                    new Promise((resolve, reject) => {
                      console.log(query, "qqqqqss");
                      let url = `${baseURL}/invoice/get_invoice?`;
                      url += "size=" + query.pageSize;
                      url += "&page=" + (query.page + 1);
                      //  url += "&search=" + query.search;
                      fetch(url, {
                        method: "GET",
                        headers: headers,
                      })
                        .then((response) => response.json())
                        .then((result) => {
                          console.log(result, "rrrrr");
                          resolve({
                            data: result.result,
                            page: result.page - 1,
                            totalCount: result.total_results,
                          });
                        });
                    })
                  }
                  detailPanel={(rowData) => {
                    return (
                      <MaterialTable
                        title="Invoice Detail"
                        columns={[
                          {
                            title: "Inventory ID",
                            field: "inventoryId",
                          },
                          {
                            title: "Product Name",
                            field: "inventory.productName",
                          },
                          {
                            title: "Inventory Price",
                            field: "invoice_data_price",
                          },
                          {
                            title: "Invoice Quantity",
                            field: "invoice_data_quantity",
                          },
                          {
                            title: "Invoice Total Price",
                            field: "total_price_inv",
                          },
                        ]}
                        data={rowData.invoice_data}
                        options={{
                          actionsColumnIndex: -1,
                          toolbar: false,
                          //   exportButton: true,
                          sorting: true,
                          maxBodyHeight:'600px',
                          paging: true,
                          pageSize: 200, // make initial page size
                          emptyRowsWhenPaging: false, // To avoid of having empty rows
                          pageSizeOptions: [50, 100, 150, 200],
                          headerStyle: {
                            position: "sticky",
                            top: 0,
                            color: "#00BBBB",
                            fontWeight: "550",
                            onRowAdd: "none",
                          },
                        }}
                      />
                    );
                  }}
                  options={{
                    actionsColumnIndex: -1,
                    filtering: true,
                    // exportButton: true,
                    // exportAllData: true,
                    sorting: true,
                    paging: true,
                    maxBodyHeight:'600px',
                    pageSize: 20, // make initial page size
                    emptyRowsWhenPaging: false, // To avoid of having empty rows
                    pageSizeOptions: [50, 100, 150, 200],
                    headerStyle: {
                      position: "sticky",
                      top: 0,
                      color: "#00BBBB",
                      fontWeight: "550",
                      onRowAdd: "none",
                    },
                  }}
                  actions={[
                    (rowData) => {
                      return rowData.invoice_status !== "Pending"
                        ? {
                            icon: SendIcon,
                            disabled: true,
                          }
                        : {
                            icon: SendIcon,
                            disabled: false,
                            onClick: (event, rowData) => {
                              // handleShow(rowData.invoice_id);
                              props.PostInvoice({
                                invoice_id: rowData.invoice_id,
                              });
                            },
                          };
                    },
                    (rowData) => {
                      return isEmpty(rowData)
                        ? {
                            icon: LocalPrintshopIcon,
                            disabled: true,
                          }
                        : {
                            icon: LocalPrintshopIcon,
                            disabled: false,
                            onClick: (event, rowData) => {
                              console.log(rowData, "rrrrr");
                              props.generateinvoicePdf(rowData);
                            },
                          };
                    },
                    (rowData) => {
                      return rowData.invoice_status !== "Pending"
                        ? {
                            icon: DeleteIcon,
                            disabled: true,
                          }
                        : {
                            icon: DeleteIcon,
                            disabled: false,
                            onClick: (event, rowData) => {
                              console.log(rowData, "rrrrr");
                              props.deleteInvoice(rowData.invoice_id);
                            },
                          };
                    },
                  ]}
                />
              </Form>
            </div>
            <Modal show={show} onHide={handleClose} animation={false}>
              <Modal.Header closeButton>
                <Modal.Title>Select Transation Method</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col xl="3" lg="3" md="3" xs="3"></Col>
                  <Col xl="2" lg="2" md="2" xs="2">
                    {" "}
                    <Button onClick={() => setType("Bank")}>Bank</Button>
                  </Col>
                  <Col xl="2" lg="2" md="2" xs="2"></Col>
                  <Col xl="2" lg="2" md="2" xs="2">
                    <Button onClick={() => setType("Cash")}>Cash</Button>
                  </Col>
                  <Col xl="3" lg="3" md="3" xs="3"></Col>
                </Row>
                <Row>
                  <Col xl="3" lg="3" md="3" xs="3"></Col>
                  {typeAccount == "" ? (
                    ""
                  ) : typeAccount == "Cash" ? (
                    <Col xl="6" lg="6" md="6" xs="12">
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b>Select Cash Account:</b>
                        </Form.Label>
                        <Select
                          placeholder="Select Cash Account..."
                          onChange={handleChangeAccountNo}
                          isDisabled={typeAccount == "" ? true : false}
                          // options={cashAccountNoTop}
                          options={filter(
                            cashAccountNoTop,
                            (x) => x.values == 1
                          )}
                        />
                      </Form.Group>
                    </Col>
                  ) : (
                    <Col xl="6" lg="6" md="6" xs="12">
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b>Select Bank Account:</b>
                        </Form.Label>
                        <Select
                          placeholder="Select Bank Account..."
                          onChange={handleChangeAccountNo}
                          isDisabled={typeAccount == "" ? true : false}
                          // options={cashAccountNoTop}
                          options={filter(
                            cashAccountNoTop,
                            (x) => x.values == 2
                          )}
                        />
                      </Form.Group>
                    </Col>
                  )}
                  <Col xl="3" lg="3" md="3" xs="3"></Col>
                </Row>
              </Modal.Body>

              <Modal.Footer>
                <div className="sendDiv">
                  {cashaccountNo == "" ? (
                    <Button
                      disabled
                      style={{ marginLeft: "40%", backgroundColor: "black" }}
                    >
                      Save
                    </Button>
                  ) : (
                    <Button className="sendButton" onClick={handlePost}>
                      Save
                    </Button>
                  )}
                </div>
              </Modal.Footer>
            </Modal>
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    generateinvoicePdf: (data) => dispatch(getInvoicePdf(data)),
    GetInvoice: () => dispatch(getInvoice()),
    PostInvoice: (data) => dispatch(PostInvoice(data)),
    getLevel5Data: () => dispatch(getLevel5Data()),
    deleteInvoice: (id) => dispatch(deleteInvoice(id)),
    getAllInventory: (data) => dispatch(getAllInventory(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  isFetchingInvoice: state.InvoiceReducer.isFetchingInvoice,
  invoiceData: state.InvoiceReducer.invoiceData,
  level5List: state.accountsLevelReducer.level5List,
  allInventory: state.AddDataReducer.allInventory,
  // isFetchingInventoryAll: state.AddDataReducer.isFetchingInventoryAll,
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceList);
