import React, { useState } from "react";
import { Container, Form, Row, Col, Button, FormGroup } from "react-bootstrap";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import { useEffect } from "react";
import Swal from "sweetalert";
import { isEmpty, find, toLower } from "lodash";
import Select from "react-select";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { IconButton } from "@material-ui/core";
import jsPDF from "jspdf";
import { addUser } from "./users.actions";
//import logo from "../../../../../Images/logo.png";
// import logo from "../../../../Images/logo.png";
// import {
//   addUserRes,
//   deleteUserRes,
//   getUserRes,
//   UpdateUserRes,
// } from "./user.actions";
// import { getRestaurantData } from "../AddDetails/AddResturant/Resturant.action";
// import { setHeading } from "../../accounts/account.actions";
const AddUser = (props) => {
  const [customerPhone, setCustomerPhone] = useState("");
  const [userName, setUserName] = useState("");
  const [passwordUser, setPasswordUser] = useState("");
  const [address, setAddress] = useState("");
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
  const [submit, setSubmit] = useState(false);
  const admin_id=localStorage.getItem("adminId")
  function uploadCustomer() {
    props.addUser(
      {
        phone_number: customerPhone,
        address: address,
        username: userName,
        password: passwordUser,
      },
      handleVisible,
      handleDangerVisible
    );
  }
  const handleVisible = (msg) => {
    setAlertVisible(true);
    clearStates();
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
  };
  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  };
  
  const clearStates = () => {
    setAddress("");
    setPasswordUser("");
    setUserName("");
    setCustomerPhone("");
    setSubmit(false);
  };

  useEffect(() => {
  }, []);
  return (
    <>
     
      <Container fluid>
        <Form>
     
          
          <Row>
            <Col lg="6" md="12" xs="12">
              <Form.Group className="mb-3" controlId="garana">
                <Form.Label>
                  <b>Enter User Name :</b>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="User Name...."
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col lg="6" md="12" xs="12">
              <Form.Group className="mb-3" controlId="garana">
                <Form.Label>
                  <b>Enter User Password :</b>
                </Form.Label>
                <Form.Control
                  type="password"
                  minLength={8}
                  // maxLength={13}
                  placeholder="Enter User Password...."
                  value={passwordUser}
                  onChange={(e) => setPasswordUser(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg="6" md="12" xs="12">
              <Form.Group className="mb-3" controlId="garana">
                <Form.Label>
                  <b>Enter Phone Number :</b>
                </Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Phone Number...."
                  value={customerPhone}
                  onChange={(e) => setCustomerPhone(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col lg="6" md="12" xs="12">
              <Form.Group className="mb-3" controlId="garana">
                <Form.Label>
                  <b>Enter Address/City :</b>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Address/City...."
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <div className="sendDiv">
            <Button
            
              disabled={isEmpty(userName)||isEmpty(passwordUser) }
              onClick={() => {
                uploadCustomer();
                // setSubmit(true);
              }}
            >
              Save
            </Button>
          </div>
        </Form>
       
      </Container>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addUser: (data, handleVisible, handleDangerVisible) =>
      dispatch(addUser(data, handleVisible, handleDangerVisible)),
  };
};
const mapStateToProps = (state, ownProps) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(AddUser);
