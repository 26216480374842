import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, FormGroup, Alert, Image } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty, isNull, upperCase } from "lodash";
import Spinner from 'react-bootstrap/Spinner';
import "./hr-styles.css";
import profileImage from "../../../../../Images/profileImage.jpg";
import Select from 'react-select';
import ImageUploader from 'react-images-upload';
import { addEmployee, GetHRDepartment, GetHRDesignation, GetHRDesignationByDepartment, getHREmployeeType } from "./HRDetails.actions";
import Swal from 'sweetalert';
import moment from "moment";

const AddEmployee = (props) => {
    const [uploadProfileImage, setUploadProfileImage] = useState("");
    console.log(uploadProfileImage, "uuuuuuuuuuu");
    const [image, setImage] = useState(profileImage);
    const [employeId, setEmployeId] = useState("");

    const [employeeName, setemployeeName] = useState("");

    // console.log(employeeName, "eeeeeeeeeee");
    const [appointmentType, setAppointmentType] = useState("");
    const [employeFatherName, setEmployeFatherName] = useState("");
    // const [employeMotherName, setEmployeMotherName] = useState();
    const [dob, setDob] = useState("");
    const [gender, setGender] = useState("");
    const [mobile, setMobile] = useState("");
    const [address, setAddress] = useState("");
    console.log('address', address);

    const [emergencyContact, setEmergencyContact] = useState("");

    const [maritalStatus, setMaritalStatus] = useState("");
    const [cnic, setCnic] = useState("");
    const [joiningDate, setJoiningDate] = useState("");
    const [religion, setReligion] = useState("");

    // const [machineID, setMachineID] = useState();

    const [allocation, setAllocation] = useState("");

    const [employeeType, setEmployeeType] = useState("");
    const [ntn, setNtn] = useState("");
    const [eobi, setEobi] = useState("");
    const [socialSecurity, setSocialSecurity] = useState("");
    const [bloodGroup, setBloodGroup] = useState("");
    const [probationPeriod, setProbationPeriod] = useState("");
    const [probationEndDate, setProbationEndDate] = useState("");
    // const [temporaryBlock, setTemporaryBlock] = useState();
    // const [reasonForBlock, setReasonForBlock] = useState();



    const [reportTo, setReportTo] = useState("");
    const [uploadCnicImage, setUploadCnicImage] = useState("");
    const [uploadDocument, setUploadDocument] = useState("");

    const [activeStatus, setActiveStatus] = useState(true);
    const [visibleAlert, setAlertVisible] = useState(false);
    const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);


    const [department, setDepartment] = useState(0);
    const [designation, setDesignation] = useState(0);
    console.log(image, "ppppppppppp");
    const imageHandler = (e) => {
        setUploadProfileImage(e.target.files[0]);
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState == 2) {
                setImage(reader.result)
                // setUploadProfileImage(reader.result);

            }
        }
        reader.readAsDataURL(e.target.files[0]);
    };
    let min_date = moment();
    console.log('min_date', min_date)
    min_date = min_date.subtract(1, "month");
    console.log('min_date', min_date)
    min_date = min_date.format("YYYY-MM-DD");
    console.log('min_date', min_date)

    console.log(
        'address', address,

        'allocation', allocation,
        'appointmentType', appointmentType,
        'department', department, (department === 0),
        'designation', designation, (designation === 0),
        'employeeType', employeeType, (employeeType === 0),
        'employeeName', employeeName, isEmpty(employeeName),
        'joiningDate', joiningDate, isEmpty(joiningDate),
        'cnic', cnic, isEmpty(cnic),
        'mobile', mobile, isEmpty(mobile),
        'maritalStatus', maritalStatus, isEmpty(maritalStatus),
        'religion', religion, isEmpty(religion),
        'gender', gender, isEmpty(gender),
        'probationPeriod', probationPeriod, isEmpty(probationPeriod),
        'probationEndDate', probationEndDate, isEmpty(probationEndDate),

    )
    const getAppointmentList = [

        { value: "new", label: "New" },
        { value: "replacement", label: "Replacement" },
    ];
    const getReligionList = [

        { value: "Islam", label: "Islam" },
        { value: "Christianity", label: "Christianity" },
        { value: "Judaism", label: "Judaism" },
        { value: "Hinduism", label: "Hinduism" },
        { value: "Buddhism", label: "Buddhism" },
        { value: "Sikhism", label: "Sikhism" },
        { value: "Other", label: "Other" },
    ];
    const getMaritalStatusList = [

        { value: "Single", label: "Single" },
        { value: "Married", label: "Married" },
        // { value: "Divorced", label: "Divorced" },
        // { value: "Separated", label: "Separated" },

    ];
    const getStatusList = [

        { value: true, label: "Active" },
        { value: false, label: "Non Active" },
    ];

    const getGenderList = [

        { value: "Male", label: "Male" },
        { value: "Female", label: "Female" },
        { value: "Other", label: "Other" },
    ];
    const handleChangeAppointment = (selectedOption) => {
        setAppointmentType(selectedOption.value);
    };
    const handleChangeGender = (selectedOption) => {
        setGender(selectedOption.value);
    };
    const handleChangeReligion = (selectedOption) => {
        setReligion(selectedOption.value);
    };
    const handleChangeMaritalStatus = (selectedOption) => {
        setMaritalStatus(selectedOption.value);
    };
    const handleChangeStatus = (selectedOption) => {
        setActiveStatus(selectedOption.value);
    };
    const onDrop = (pictureFiles, pictureDataURLs) => {
        setUploadCnicImage(pictureFiles);
    }
    const onDropDocs = (pictureFiles, pictureDataURLs) => {
        setUploadDocument(pictureFiles);
    }
    const SaveEmployee = () => {
        props.addEmployee({
            address: upperCase(address),

            appointment_type: appointmentType,
            name: upperCase(employeeName),
            father_name: upperCase(employeFatherName),
            // mother_name: employeMotherName,
            dob: dob,
            gender: gender,
            phone: mobile,
            emergency_contact: emergencyContact,
            marital_status: maritalStatus,
            cnic: cnic,
            uploadImage: uploadProfileImage,
            joining_date: joiningDate,
            religion: religion,
            // machine_id: machineID,
            report_to: upperCase(reportTo),
            uploadCnicImage: uploadCnicImage,
            uploadDocument: uploadDocument,
            // active_status: activeStatus,



            allocation: upperCase(allocation),
            employee_type: employeeType,
            ntn: ntn,
            eobi: eobi,
            social_security: socialSecurity,
            blood_group: bloodGroup,
            probation_period: upperCase(probationPeriod),
            probation_period_end_date: probationEndDate,
            // temporary_block: temporaryBlock,
            // reasonForBlock: reasonForBlock,

            department_id: department,
            designation_id: designation,
        }, handleVisible, handleDangerVisible);
    };

    const getDepartmentNameList = !isEmpty(props.departmentList) && props.departmentList.map((x) => { let data = { value: x.departmentId, label: x.departmentName }; return data });
    const handleChangeDepartment = (selectedOption) => {
        setDepartment(selectedOption.value);
        props.GetHRDesignationByDepartment(selectedOption.value);
    };

    const getDesignationNameList = !isEmpty(props.designationListByDepartmentId) && props.designationListByDepartmentId.map((x) => { let data = { value: x.designation_id, label: x.designationName }; return data });
    const handleChangeDesignation = (selectedOption) => {
        setDesignation(selectedOption.value);
    };
    console.log('props.employeeTypeList', (props.employeeTypeList));
    console.log('props.employeeTypeList', isEmpty(props.employeeTypeList));
    const getEmployeeTypeList = isEmpty(props.employeeTypeList) ? [] : props.employeeTypeList.map((x) => { let data = { value: x.employee_type_id, label: x.employee_type_name }; return data });
    console.log('getEmployeeTypeList', getEmployeeTypeList);
    const handleChangeEmployeeType = (selectedOption) => {
        setEmployeeType(selectedOption.value);
    };


    const clearStates = () => {
        setUploadProfileImage();
        setImage(profileImage);
        setEmployeId();
        setemployeeName();
        setAppointmentType();
        setEmployeFatherName();
        setDob();
        setGender();
        setMobile();
        setAddress();
        setEmergencyContact();
        setMaritalStatus();
        setCnic();
        setJoiningDate();
        setReligion();
        setAllocation();
        setEmployeeType(0);
        setNtn();
        setEobi();
        setSocialSecurity();
        setBloodGroup();
        setProbationPeriod();
        setProbationEndDate();
        setReportTo();
        setUploadCnicImage();
        setUploadDocument();
        setActiveStatus(true);
        setAlertVisible();
        setDangerVisibleAlert();
        setDepartment(0);
        setDesignation(0);

    }

    const handleVisible = () => {
        setAlertVisible(true);
        clearStates();
        setTimeout(() => {
            setAlertVisible(false)
        }, 9000);
        Swal({
            title: "Success",
            text: "Added successfully...",
            icon: "success",
            button: "Ok",
        });
    }

    const handleDangerVisible = (message="Something went wrong") => {
        setDangerVisibleAlert(true);
        setTimeout(() => {
            setDangerVisibleAlert(false)
        }, 9000);
        Swal({
            title: "Error",
            text: `${message}...`,
            icon: "error",
            button: "Ok",
        });
    }

    useEffect(() => {
        props.GetHRDepartment();
        props.GetHRDesignation();
        props.getHREmployeeType();
    }, []);
    return (
        <>
            <Container fluid>
                {props.isAddingEmployee ? <div className="loader-div">
                    {(image !== profileImage) && setImage(profileImage)}
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />
                        {props.isFetchingSectorsSummary ? 'Adding Employee...' : 'Adding Employee...'}

                    </Button>
                </div> :
                    <div className="mainHr">
                        <Row>
                            <Col lg="4" md="4" xs="12"></Col>
                            <Col lg="3" md="3" xs="12">
                                <Image src={image} rounded={false}  width="100%" style={{ textAlign: "center" }} />
                                <input className="form-control" type="file" onChange={imageHandler} />
                            </Col>
                            <Col lg="4" md="4" xs="12"></Col>
                        </Row>
                        <br /><br />
                        <Form>
                            <Row>
                                <Col lg="4" md="4" xs="12">
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>Appointment Type <span style={{fontSize:'25px',fontWeight:'bold',color:'red'}}>*</span></b></Form.Label>
                                        <Select
                                            placeholder="Appointment Type..."
                                            onChange={handleChangeAppointment}
                                            options={getAppointmentList}

                                        />
                                    </Form.Group>
                                </Col>
                                {/* <Col lg="4" md="4" xs="12">
                            <Form.Group className="mb-3" controlId="garana" >
                                <Form.Label><b>Enter Employee ID :</b></Form.Label>
                                <Form.Control type="text" id="warehouseName" placeholder="Employee ID...." onBlur={(e) => setEmployeId(e.target.value)} />
                            </Form.Group>
                        </Col> */}
                                {/* <Col lg="4" md="4" xs="12">
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>Enter Machine ID:</b></Form.Label>
                                        <Form.Control type="text" id="warehouseName" placeholder="Machine ID...." onBlur={(e) => setMachineID(e.target.value)} />
                                    </Form.Group>
                                </Col> */}
                                <Col lg="4" md="4" xs="12">
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>Employee Name <span style={{fontSize:'25px',fontWeight:'bold',color:'red'}}>*</span></b></Form.Label>
                                        <Form.Control type="text" id="warehouseName" placeholder="Employee Name...." onBlur={(e) => setemployeeName(e.target.value)} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>

                                <Col lg="4" md="4" xs="12">
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>Father/Husband Name <span style={{fontSize:'25px',fontWeight:'bold',color:'red'}}>*</span></b></Form.Label>
                                        <Form.Control type="text" id="warehouseName" placeholder="Employee Father/Husband Name...." onBlur={(e) => setEmployeFatherName(e.target.value)} />
                                    </Form.Group>
                                </Col>
                                {/* <Col lg="4" md="4" xs="12">
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>Enter Mother Name :</b></Form.Label>
                                        <Form.Control type="text" id="warehouseName" placeholder="Employee Mother Name...." onBlur={(e) => setEmployeMotherName(e.target.value)} />
                                    </Form.Group>
                                </Col> */}
                                <Col lg="4" md="4" xs="12">
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>Gender <span style={{fontSize:'25px',fontWeight:'bold',color:'red'}}>*</span></b></Form.Label>
                                        <Select
                                            placeholder="Gender..."
                                            onChange={handleChangeGender}
                                            options={getGenderList}

                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg="4" md="4" xs="12">
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>Date of Birth :</b></Form.Label>
                                        <Form.Control type="date" id="warehouseName" placeholder="Employee DOB...."  max= {moment().format("YYYY-MM-DD")} onBlur={(e) => setDob(e.target.value)} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>

                                <Col lg="4" md="4" xs="12">
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>Marital status:</b></Form.Label>
                                        <Select
                                            placeholder="Marital status...."
                                            onChange={handleChangeMaritalStatus}
                                            options={getMaritalStatusList}

                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg="4" md="4" xs="12">
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>Religion:</b></Form.Label>
                                        <Select
                                            placeholder="Religion...."
                                            onChange={handleChangeReligion}
                                            options={getReligionList}

                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg="4" md="4" xs="12">
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>Joining Date:</b></Form.Label>
                                        <Form.Control type="date" id="warehouseName" placeholder="Joining Date...."
                                        max= {moment().format("YYYY-MM-DD")}
                                            //  min={min_date}
                                            // defaultValue ={joiningDate}
                                            // min={moment(new Date()).format("YYYY-MM")}
                                            onBlur={(e) => setJoiningDate(e.target.value)} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="4" md="4" xs="12">
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>Mobile#:</b></Form.Label>
                                        <Form.Control type="test" id="warehouseName" placeholder="Mobile Number...." onBlur={(e) => setMobile(e.target.value)} />
                                    </Form.Group>
                                </Col>

                                <Col lg="4" md="4" xs="12">
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>Emergency Contact#:</b></Form.Label>
                                        <Form.Control type="test" id="warehouseName" placeholder="Emergency Contact Number...." onBlur={(e) => setEmergencyContact(e.target.value)} />
                                    </Form.Group>
                                </Col>
                                <Col lg="4" md="4" xs="12">
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>Address:</b></Form.Label>
                                        <Form.Control type="text" id="warehouseName" placeholder="Address...." onBlur={(e) => setAddress(e.target.value)} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>




                                {/* <Col lg="4" md="4" xs="12">
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>Status:</b></Form.Label>
                                        <Form.Control type="text" id="warehouseName" value={activeStatus} placeholder="Status...." onChange={(e) => setActiveStatus(e.target.value)} disabled={true} />
                                    </Form.Group>
                                </Col> */}
                                {/* <Col lg="4" md="4" xs="12">          
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>Select Status:</b></Form.Label>
                                        <Select
                                            placeholder="Select Status..."
                                            onChange={handleChangeStatus}
                                            options={getStatusList}

                                        />
                                    </Form.Group>
                                </Col>          */}
                                <Col lg="4" md="4" xs="12">
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>Report to:</b></Form.Label>
                                        <Form.Control type="text" id="warehouseName" placeholder="Report to...." onBlur={(e) => setReportTo(e.target.value)} />
                                    </Form.Group>
                                </Col>


                            </Row>
                            <Row>
                                <Col lg="4" md="4" xs="12">
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>Employee Type:</b></Form.Label>
                                        {/* <Form.Control type="text" id="warehouseName" placeholder="Employee Type...." onBlur={(e) => setEmployeeType(e.target.value)} /> */}
                                        <Select
                                            placeholder="Employee Type..."
                                            onChange={handleChangeEmployeeType}
                                            options={getEmployeeTypeList}
                                            isDisabled={isEmpty(getEmployeeTypeList) || isEmpty(props.employeeTypeList)}
                                        />

                                    </Form.Group>
                                </Col>
                                <Col lg="4" md="4" xs="12">
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>NTN:</b></Form.Label>
                                        <Form.Control type="number" id="warehouseName" placeholder="NTN...." onBlur={(e) => setNtn(e.target.value)} />
                                    </Form.Group>
                                </Col>
                                <Col lg="4" md="4" xs="12">
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>EOBI#:</b></Form.Label>
                                        <Form.Control type="number" id="warehouseName" placeholder="EOBI#...." onBlur={(e) => setEobi(e.target.value)} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="4" md="4" xs="12">
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>SOCIAL SECURITY:</b></Form.Label>
                                        <Form.Control type="number" id="warehouseName" placeholder="SOCIAL SECURITY...." onBlur={(e) => setSocialSecurity(e.target.value)} />
                                    </Form.Group>
                                </Col>
                                <Col lg="4" md="4" xs="12">
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>Blood Group:</b></Form.Label>
                                        <Form.Control type="text" id="warehouseName" placeholder="Blood Group...." onBlur={(e) => setBloodGroup(e.target.value)} />
                                    </Form.Group>
                                </Col>
                                <Col lg="4" md="4" xs="12">
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>CNIC <span style={{fontSize:'25px',fontWeight:'bold',color:'red'}}>*</span></b></Form.Label>
                                        <Form.Control type="number" id="warehouseName" placeholder="Employee CNIC...." onBlur={(e) => setCnic(e.target.value)} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>


                                {/* <Col lg="4" md="4" xs="12">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Enter Temporary Block:</b></Form.Label>
                                    <Form.Control type="text" id="warehouseName" placeholder="Temporary Block...." onBlur={(e) => setTemporaryBlock(e.target.value)} />
                                </Form.Group>
                            </Col>                           
                             <Col lg="4" md="4" xs="12">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Enter Reason for Block:</b></Form.Label>
                                    <Form.Control type="text" id="warehouseName" placeholder="Reason for Block...." onBlur={(e) => setReasonForBlock(e.target.value)} />
                                </Form.Group>
                            </Col> */}
                            </Row>
                            <Row>
                                <Col lg="12" md="12" xs="12">
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>Department <span style={{fontSize:'25px',fontWeight:'bold',color:'red'}}>*</span></b></Form.Label>
                                        <Select
                                            isDisabled={isEmpty(getDepartmentNameList)}
                                            placeholder="Department..."
                                            onChange={handleChangeDepartment}
                                            options={getDepartmentNameList}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="12" md="12" xs="12">
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>Designation <span style={{fontSize:'25px',fontWeight:'bold',color:'red'}}>*</span></b></Form.Label>
                                        <Select
                                            isDisabled={isEmpty(getDesignationNameList)}
                                            placeholder="Designation..."
                                            onChange={handleChangeDesignation}
                                            options={getDesignationNameList}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12">
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>Asset Allocation:</b></Form.Label>
                                        <Form.Control as="textarea" rows={3} id="warehouseName" placeholder="Allocation...." onBlur={(e) => setAllocation(e.target.value)} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                {/* <Col lg="4" md="4" xs="12">
                            <Form.Group className="mb-3" controlId="garana" >
                                <Form.Label><b>Select Status:</b></Form.Label>
                                <Select
                                    placeholder="Select Status..."
                                    onChange={handleChangeStatus}
                                    options={getStatusList}

                                />
                            </Form.Group>
                        </Col> */}
                                <Col lg="4" md="4" xs="12">
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>Probation Period:</b></Form.Label>
                                        <Form.Control type="text" id="warehouseName" placeholder="Probation Period...." onBlur={(e) => setProbationPeriod(e.target.value)} />
                                    </Form.Group>
                                </Col>
                                <Col lg="4" md="4" xs="12">
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>Probation End Date:</b></Form.Label>
                                        <Form.Control type="date" id="warehouseName" placeholder="Probation End Date...." onBlur={(e) => setProbationEndDate(e.target.value)} />
                                    </Form.Group>
                                </Col>
                                <Col lg="4" md="4" xs="12">
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>Upload Document:</b></Form.Label>
                                        <input className="form-control" type="file" onBlur={(e) => setUploadDocument(e.target.files[0])} />
                                    </Form.Group>
                                </Col>

                            </Row>

                            <Row>
                                <Col xl="12" lg="12" md="12" sm="12" xs="12">
                                    <Form.Group className="mb-3">
                                        {/* <Form.Label>
                                            <b style={{ fontSize: "large" }}>Upload CNIC Image</b>
                                        </Form.Label> */}
                                        <ImageUploader
                                            withIcon={false}
                                            buttonText='Upload CNIC Image'
                                            onChange={onDrop}
                                            // onChange = {(e) => setUploadCnicImage(e.target.files[0])}
                                            imgExtension={['.jpg', '.gif', '.png', '.gif']}
                                            maxFileSize={999999999999999999999}
                                            singleImage={true}
                                            withPreview={true}
                                        />
                                    </Form.Group>
                                </Col>
                                {/* <Col xl="6" lg="6" md="6" sm="6" xs="6">
                                    <Form.Group className="mb-3">
                                        <Form.Label>
                                            <b style={{ fontSize: "large" }}>Upload your Educational Documents</b>
                                        </Form.Label>
                                        <ImageUploader
                                            withIcon={false}
                                            buttonText='Upload Documents'
                                            onChange={onDropDocs}
                                            // onChange = {(e) => setUploadDocument(e.target.files[0])}
                                            imgExtension={['.jpg', '.gif', '.png', '.gif']}
                                            maxFileSize={999999999999999999999}
                                            singleImage={false}
                                            withPreview={true}
                                        />
                                    </Form.Group>
                                </Col> */}
                            </Row>
                            <div className="sendDiv">
                            {
                                   isEmpty(appointmentType)||
                                    department === 0
                                    || designation === 0
                                    // || (employeeType === 0)
                                    || isEmpty(employeeName)
                                    || isEmpty(employeFatherName)
                                    //|| isEmpty(joiningDate)
                                    || isEmpty(cnic)
                                    // || isEmpty(mobile)
                                    //  || isEmpty(maritalStatus)
                                    //  || isEmpty(religion)
                                    || isEmpty(gender)
                                   // || isEmpty(probationPeriod)
                                   /* || isEmpty(probationEndDate)*/?
                                <Button className="sendButton" style={{backgroundColor:"black"}} onClick={() => { SaveEmployee(); }} disabled>Save</Button>:
                                        <Button className="sendButton" onClick={() => { SaveEmployee(); }} >Save</Button>}
                            </div>
                        </Form>
                    </div>
                }
            </Container>
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        addEmployee: (data, handleVisible, handleDangerVisible) => dispatch(addEmployee(data, handleVisible, handleDangerVisible)),
        GetHRDepartment: () => dispatch(GetHRDepartment()),
        GetHRDesignation: () => dispatch(GetHRDesignation()),
        getHREmployeeType: () => dispatch(getHREmployeeType()),
        GetHRDesignationByDepartment:(id)=>dispatch(GetHRDesignationByDepartment(id)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    departmentList: state.HrReducer.departmentList,
    designationListByDepartmentId: state.HrReducer.designationListByDepartmentId,
    isAddingEmployee: state.HrReducer.isAddingEmployee,
    employeeTypeList: state.HrReducer.employeeTypeList,
    isFetchingEmployeeTypeData: state.HrReducer.isFetchingEmployeeTypeData,

});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddEmployee);