import React, { Component } from "react";
import { useLocation,useHistory } from "react-router-dom";
import { Navbar,NavDropdown, Container, Nav, Dropdown, Button } from "react-bootstrap";

import {dashboardInventoryRoutes, dashboardAccountRoutes, dashboardProductionRoutes} from "../../routes";

function Header() {

console.log(dashboardInventoryRoutes,dashboardAccountRoutes,dashboardProductionRoutes,"tttttttt121212")
  const location = useLocation();
  const history = useHistory();
  const mobileSidebarToggle = (e) => {
    e.preventDefault();
    document.documentElement.classList.toggle("nav-open");
    var node = document.createElement("div");
    node.id = "bodyClick";
    node.onclick = function () {
      this.parentElement.removeChild(this);
      document.documentElement.classList.toggle("nav-open");
    };
    document.body.appendChild(node);
  };

  const getBrandText = () => {
    
    for (let i = 0; i < dashboardInventoryRoutes.length; i++) {
      if (location.pathname.indexOf(dashboardInventoryRoutes[i].layout + dashboardInventoryRoutes[i].path) !== -1) {
        return dashboardInventoryRoutes[i].name;
      }
    }
    return `${process.env.REACT_APP_COMPANY_NAME}`;
  };

  function Logout() {
    window.localStorage.clear();
    window.location.replace('/login');
  }
  return (
    <Navbar bg="light" expand="lg">
      <Container fluid>
        <div className="d-flex justify-content-center align-items-center ml-2 ml-lg-0">
          <Button
            variant="dark"
            className="d-lg-none btn-fill d-flex justify-content-center align-items-center rounded-circle p-2"
            onClick={mobileSidebarToggle}
          >
            <i className="fas fa-ellipsis-v"></i>
          </Button>
          <Navbar.Brand
            href="#home"
            onClick={(e) => e.preventDefault()}
            className="mr-2"
          >
            {getBrandText()}
          </Navbar.Brand>
        </div>
        <Navbar.Toggle aria-controls="basic-navbar-nav" className="mr-2">
          <span className="navbar-toggler-bar burger-lines"></span>
          <span className="navbar-toggler-bar burger-lines"></span>
          <span className="navbar-toggler-bar burger-lines"></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto" navbar>
          <NavDropdown >
            <Nav.Item>
            
              <Nav.Link
                className="m-0"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                <span className="no-icon" onClick={Logout}>Log out</span>
              </Nav.Link>
              
            </Nav.Item>
            <Nav.Item>
            <Nav.Link
              className="m-0"
              href="/home"
              onClick={(e) => e.preventDefault()}
            >
              <span className="no-icon" onClick={'/home'}>Home</span>
              </Nav.Link>
              </Nav.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
  };
};
const mapStateToProps = (state, ownProps) => ({
});

export default Header;
