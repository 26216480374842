import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import { isNull } from "lodash";
import Increment from "./Increment";
import SalaryIncrementList from "./SalaryIncrementList";
import { getSalaryIncrement } from "./salary.actions";

const TotalSalaryIncrementData = (props) => {
  const permissions = JSON.parse(localStorage.getItem("permissions"));
  // const handleKey = (key) => {
  //   if (key == "AddSalaryIncrement") {
  //   } else {
  //     props.getSalaryIncrement();
  //   }
  // };

  return (
    <>
      <Tabs
        defaultActiveKey={
          !isNull(permissions) && permissions.some((p) => p["sub_id"] === 62)
            ? "AddSalaryIncrement"
            : !isNull(permissions) &&
              permissions.some((p) => p["sub_id"] === 63)
            ? "SalaryIncrementList"
            : ""
        }
        id="AddSalaryIncrement"
        className="mb-3"
        // onSelect={handleKey("SalaryIncrementList")}
      >
        <Tab
          eventKey="AddSalaryIncrement"
          title="Add Salary Increment"
          disabled={
            !isNull(permissions) && permissions.some((p) => p["sub_id"] === 62)
              ? false
              : true
          }
        >
          <Increment />
        </Tab>
        <Tab
          eventKey="SalaryIncrementList"
          title="Salary Increment List"
          disabled={
            !isNull(permissions) && permissions.some((p) => p["sub_id"] === 63)
              ? false
              : true
          }
          // onEntering={props.getSalaryIncrement()}
        >
          <SalaryIncrementList />
        </Tab>
      </Tabs>
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getSalaryIncrement: () => dispatch(getSalaryIncrement()),
  };
};
const mapStateToProps = (state, ownProps) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TotalSalaryIncrementData);
