import React, { useEffect, useState } from "react";
import {
  InputGroup,
  Row,
  Col,
  Form,
  Table,
  Container,
  Button,
  FormGroup,
  Modal,
  Alert,
} from "react-bootstrap";
import "./admin-details.css";
import {
  getCategoryData,
  deleteCategory,
  getWarehouseData,
  getsubCategoryData,
  addInventory,
  getInventory,
  deleteInventory,
  updateInventory,
  getAttributeById,
  resetReducer,
} from "../../user_views/forms/AddDetails/AddDetails.actions";
import { connect } from "react-redux";
import { isEmpty, get, map, find, groupBy, value, chain } from "lodash";
import moment from "moment";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";

const AdminInventory = (props) => {
  //let warehouseid = props.location.state.id;

  const [category, setCategoryName] = useState();
  const [subCategoryId, setSubCategoryId] = useState();
  const [wareHouseId, setwareHouseId] = useState(
    isEmpty(subCategoryId)
      ? 0
      : find(
          props.categoryList,
          (x) =>
            x.categoryId ==
            find(props.subCategoryList, (x) => x.subCategoryId == subCategoryId)
              .categoryId
        ).warehouse.wareHouseId
  );
  const [categoryId, setcategoryId] = useState(
    isEmpty(subCategoryId)
      ? 0
      : find(
          props.categoryList,
          (x) =>
            x.categoryId ==
            find(props.subCategoryList, (x) => x.subCategoryId == subCategoryId)
              .categoryId
        ).categoryId
  );
  const [companyName, setCompanyName] = useState();
  const [inventoryName, setInventoryName] = useState();
  const [price, setPrice] = useState();
  const [arrivalDate, setArrivalDate] = useState();
  const [vendor, setVendor] = useState();
  const [description, setDescription] = useState();
  const [currentBalance, setCurrentBalance] = useState();
  const [miniBalance, setMiniBalance] = useState();
  const [maxBalance, setMaxBalance] = useState();
  const [show, setShow] = useState(false);
  const [attributeType, setAttributeType] = useState();
  //const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function handleClose() {
    // props.getSectorsSummaryGetData({
    //     summarySectorId: sector
    //     });
    setShow(false);
  }

  function uploadInventory() {
    // props.getSectorsSummaryData({
    //     warehouse_name: warehouse,
    // });
    //    {props.isState && handleShow()};
    // let wareHouseName = find(props.warehouseList, x => x.wareHouseId == wareHouseId).wareHouseName;
    // let categoryNameData = find(props.categoryList, x => x.categoryId == category).categoryName;
    // let subCategoryNameData = find(props.subCategoryList, x => x.subCategoryId == subCategoryId).subCategoryName;
    // console.log(wareHouseName, "wareHouseName");
    // console.log(categoryNameData, "wareHouseName");
    // console.log(subCategoryNameData, "wareHouseName");
    props.addInventory({
      companyName: companyName,
      productName: inventoryName,
      productPrice: price,
      arrival_date: arrivalDate,
      VendorName: vendor,
      invertoryDesc: description,
      currentBlnc: currentBalance,
      minBlnc: miniBalance,
      maxBlnc: maxBalance,
      wareHouseId: wareHouseId,
      categoryId: category,
      subCategoryId: subCategoryId,
      typeName: attributeType,
    });
  }

  function getWareHouseDataChange(wareHouseValue) {
    //props.getCategoryData(wareHouseValue);
    setwareHouseId(wareHouseValue);
  }

  function getCategoryDataChange(CategoryValue) {
    // props.getsubCategoryData(CategoryValue);
    setCategoryName(CategoryValue);
  }

  const wareHouseData = props.inventoryList;
  const [open, setOpen] = React.useState(props.dataAddedInventory);
  const [state, setState] = React.useState({
    columns: [
      { title: "Company Name", field: "companyName" },
      { title: "Product Name", field: "productName" },
      { title: "Product Price", field: "productPrice" },
      { title: "Quantity", field: "quantity" },
      { title: "Arrival Date", field: "arrival_date" },
      { title: "Vendor Name", field: "VendorName" },
      { title: "Invertory Description", field: "invertoryDesc" },
      { title: "Current Balance", field: "currentBlnc" },
      { title: "Minimum Balance", field: "minBlnc" },
      { title: "Maximum Balance", field: "maxBlnc" },
    ],
  });
  useEffect(() => {
    props.getWarehouseData();
    props.getCategoryData();
    props.getsubCategoryData();
    props.getInventory();
    //props.getAttributeById(warehouseid);
    props.resetState();

    // !isEmpty(z) && z.map((attributeValue) => {
    //    console.log(attributeValue, "yyyyyyyyyyyyyyyyyyyyy")
    //     let obj = {};
    //     obj = { title: attributeValue.attribute, field: `attribute-values`, render : rowData => find(get(rowData, 'attribute-values'), x => x.attribute.attributeName === attributeValue.attribute).value };
    //     state.columns.push(obj);
    //     console.log(obj, "objjjjjjjjjjj");

    // })
  }, []);
  let z = chain(props.attributeById)
    .groupBy("attribute.attributeName")
    .map((value, key) => ({ attribute: key, value: value }))
    .value();
  console.log(z, "uuuuuuuuu");

  !isEmpty(z) &&
    z.map((attributeValue) => {
      console.log(attributeValue, "yyyyyyyyyyyyyyyyyyyyy");
      let obj = {};
      obj = {
        title: attributeValue.attribute,
        field: `attribute-values`,
        render: (rowData) =>
          find(
            get(rowData, "attribute-values"),
            (x) => x.attribute.attributeName === attributeValue.attribute
          ).value,
      };
      [
        { title: "Company Name", field: "companyName" },
        { title: "Product Name", field: "productName" },
        { title: "Product Price", field: "productPrice" },
        { title: "Quantity", field: "quantity" },
        { title: "Arrival Date", field: "arrival_date" },
        { title: "Vendor Name", field: "VendorName" },
        { title: "Invertory Description", field: "invertoryDesc" },
        { title: "Current Balance", field: "currentBlnc" },
        { title: "Minimum Balance", field: "minBlnc" },
        { title: "Maximum Balance", field: "maxBlnc" },
      ].push(obj);
      console.log(obj, "objjjjjjjjjjj");
    });

  return (
    <>
      {props.isFetchingSectorsSummary ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {props.isFetchingSectorsSummary ? "Loading..." : "Loading..."}
          </Button>
        </div>
      ) : (
        <Container fluid>
          {props.dataAddedInventory && (
            <Alert
              variant={"success"}
              onClose={() => props.resetState()}
              dismissible
            >
              Added Data...
            </Alert>
          )}
          <div className="main">
            <Row>
              <Col xl="4" lg="4" md="12" sm="12" xs="12">
                <Form.Group>
                  <Form.Label>
                    <b> Select Warehouse </b>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    value={wareHouseId}
                    onChange={(e) => getWareHouseDataChange(e.target.value)}
                  >
                    <option selected disabled>
                      Select an Option
                    </option>
                    {!isEmpty(props.warehouseList) &&
                      props.warehouseList.map((warehouse) => (
                        <option
                          id={warehouse.wareHouseId}
                          value={warehouse.wareHouseId}
                        >
                          {warehouse.wareHouseName}
                        </option>
                      ))}
                  </Form.Control>
                </Form.Group>
              </Col>

              <Col xl="4" lg="4" md="12" sm="12" xs="12">
                <Form.Group>
                  <Form.Label>
                    <b> Select Category </b>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    value={categoryId}
                    onChange={(e) => getCategoryDataChange(e.target.value)}
                  >
                    <option selected disabled>
                      Select an Option
                    </option>
                    {!isEmpty(props.categoryList) &&
                      props.categoryList.map((category) => (
                        <option
                          id={category.categoryId}
                          value={category.categoryId}
                        >
                          {category.categoryName}
                        </option>
                      ))}
                  </Form.Control>
                </Form.Group>
              </Col>

              <Col xl="4" lg="4" md="12" sm="12" xs="12">
                <Form.Group>
                  <Form.Label>
                    <b> Select Sub-Category </b>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    //   selected={sector}
                    onChange={(e) => {
                      setSubCategoryId(e.target.value);
                      setcategoryId(
                        find(
                          props.categoryList,
                          (x) =>
                            x.categoryId ==
                            find(
                              props.subCategoryList,
                              (x) => x.subCategoryId == e.target.value
                            ).categoryId
                        ).categoryId
                      );
                      setwareHouseId(
                        find(
                          props.warehouseList,
                          (x) =>
                            x.wareHouseId ==
                            find(
                              props.subCategoryList,
                              (x) => x.subCategoryId == e.target.value
                            ).warehouseId
                        ).wareHouseId
                      );
                    }}
                  >
                    <option selected disabled>
                      Select an Option
                    </option>
                    {!isEmpty(props.subCategoryList) &&
                      props.subCategoryList.map((subcategory) => (
                        <option
                          id={subcategory.subCategoryId}
                          value={subcategory.subCategoryId}
                        >
                          {subcategory.subCategoryName}
                        </option>
                      ))}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <br />
            <br />
            <Row>
              <Col xl="6" lg="6" md="12" sm="12" xs="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Add Company Name:</b>
                  </Form.Label>
                  {isEmpty(subCategoryId) ? (
                    <Form.Control
                      type="text"
                      placeholder="Company Name...."
                      readOnly
                    />
                  ) : (
                    <Form.Control
                      type="text"
                      placeholder="Company Name...."
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                    />
                  )}
                </Form.Group>
              </Col>
              <Col xl="6" lg="6" md="12" sm="12" xs="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Add Inventory Name:</b>
                  </Form.Label>
                  {isEmpty(subCategoryId) ? (
                    <Form.Control
                      type="text"
                      placeholder="Inventory Name...."
                      readOnly
                    />
                  ) : (
                    <Form.Control
                      type="text"
                      placeholder="Inventory Name...."
                      value={inventoryName}
                      onChange={(e) => setInventoryName(e.target.value)}
                    />
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xl="6" lg="6" md="12" sm="12" xs="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Add Price:</b>
                  </Form.Label>
                  {isEmpty(subCategoryId) ? (
                    <Form.Control
                      type="number"
                      placeholder="Add Price...."
                      readOnly
                    />
                  ) : (
                    <Form.Control
                      type="number"
                      placeholder="Add Price...."
                      value={price}
                      onChange={(e) => setPrice(e.target.value)}
                    />
                  )}
                </Form.Group>
              </Col>
              <Col xl="6" lg="6" md="12" sm="12" xs="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Add Cuurent Balance:</b>
                  </Form.Label>
                  {isEmpty(subCategoryId) ? (
                    <Form.Control
                      type="number"
                      placeholder="Current Balance...."
                      readOnly
                    />
                  ) : (
                    <Form.Control
                      type="number"
                      placeholder="Current Balance...."
                      value={currentBalance}
                      onChange={(e) => setCurrentBalance(e.target.value)}
                    />
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xl="6" lg="6" md="12" sm="12">
                <FormGroup>
                  <Form.Label>
                    <b>Select Product Arrival Date</b>
                  </Form.Label>
                  {isEmpty(subCategoryId) ? (
                    <Form.Control type="date" readOnly></Form.Control>
                  ) : (
                    <Form.Control
                      type="date"
                      onChange={(e) => setArrivalDate(e.target.value)}
                    ></Form.Control>
                  )}
                </FormGroup>
              </Col>
              <Col xl="6" lg="6" md="12" sm="12" xs="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Add Vendor:</b>
                  </Form.Label>
                  {isEmpty(subCategoryId) ? (
                    <Form.Control
                      type="text"
                      placeholder="Add Vendor...."
                      readOnly
                    />
                  ) : (
                    <Form.Control
                      type="text"
                      placeholder="Add Vendor...."
                      value={vendor}
                      onChange={(e) => setVendor(e.target.value)}
                    />
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xl="12" lg="12" md="12" sm="12" xs="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Add Additional Description</b>
                  </Form.Label>
                  {isEmpty(subCategoryId) ? (
                    <Form.Control
                      id="additionalDescription"
                      as="textarea"
                      rows={3}
                      placeholder="Additional Description....."
                      readOnly
                    />
                  ) : (
                    <Form.Control
                      id="additionalDescription"
                      as="textarea"
                      rows={3}
                      placeholder="Additional Description....."
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xl="6" lg="6" md="12" sm="12" xs="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Add Minimum Balance:</b>
                  </Form.Label>
                  {isEmpty(subCategoryId) ? (
                    <Form.Control
                      type="number"
                      placeholder="Minimum Balance...."
                      readOnly
                    />
                  ) : (
                    <Form.Control
                      type="number"
                      placeholder="Minimum Balance...."
                      value={miniBalance}
                      onChange={(e) => setMiniBalance(e.target.value)}
                    />
                  )}
                </Form.Group>
              </Col>
              <Col xl="6" lg="6" md="12" sm="12" xs="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Add Maximum Balance:</b>
                  </Form.Label>
                  {isEmpty(subCategoryId) ? (
                    <Form.Control
                      type="number"
                      placeholder="Maximum Balance...."
                      readOnly
                    />
                  ) : (
                    <Form.Control
                      type="number"
                      placeholder="Maximum Balance...."
                      value={maxBalance}
                      onChange={(e) => setMaxBalance(e.target.value)}
                    />
                  )}
                </Form.Group>
              </Col>
            </Row>
            {!isEmpty(z) &&
              z.map((attributename) => {
                console.log(attributename, "nameeeeeeeeee");
                return (
                  <Row>
                    <Col xl="12" lg="12" md="12" sm="12" xs="12">
                      <Form.Group>
                        <Form.Label>
                          <b>{attributename.attribute}</b>
                        </Form.Label>
                        <Form.Control
                          as="select"
                          //value={categoryId}
                          onChange={(e) => setAttributeType(e.target.value)}
                        >
                          <option selected disabled>
                            Select an Option
                          </option>
                          {!isEmpty(attributename.value) &&
                            attributename.value.map((a) => (
                              <option value={a.typeName}>{a.typeName}</option>
                            ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                );
              })}
            <div className="sendDiv">
              <Button className="sendButton" onClick={uploadInventory}>
                Save
              </Button>
            </div>

            {/* {open && <WareHouseModal open={open} />} */}
            <MaterialTable
              title="Inventory Details"
              columns={[
                { title: "Company Name", field: "companyName" },
                { title: "Product Name", field: "productName" },
                { title: "Product Price", field: "productPrice" },
                { title: "Quantity", field: "quantity" },
                { title: "Arrival Date", field: "arrival_date" },
                { title: "Vendor Name", field: "VendorName" },
                { title: "Invertory Description", field: "invertoryDesc" },
                { title: "Current Balance", field: "currentBlnc" },
                { title: "Minimum Balance", field: "minBlnc" },
                { title: "Maximum Balance", field: "maxBlnc" },
              ]}
              data={wareHouseData}
              //data={CategoryData}
              // data={state.data}
              //   actions={[
              //     {
              //     icon: VisibilityIcon,
              //       tooltip: 'View Ware House',
              //       onClick: (event, rowData) => {
              //         // Do save operation
              //       //  setcallEdit(true);

              //       // props.selectedUser(rowData)
              //       }
              //     }
              //   ]}
              options={{
                actionsColumnIndex: -1,
                paging: true,
                pageSize: 200, // make initial page size
                emptyRowsWhenPaging: false, // To avoid of having empty rows
                pageSizeOptions: [50, 100, 150, 200],
                maxBodyHeight: "600px",
                headerStyle: {
                  position: "sticky",
                  top: 0,
                  color: "#00BBBB",
                  fontWeight: "550",
                  onRowAdd: "none",
                },
              }}
              editable={{
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      if (oldData) {
                        props.updateInventory(newData, oldData);
                      }
                    }, 600);
                  }),
                onRowDelete: (oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      props.deleteInventory(oldData.inventoryId);
                    }, 600);
                  }),
              }}
            />
            <>
              <Modal show={show} onHide={handleClose}></Modal>
            </>
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    //getSectorsList: () => dispatch(getSectorsList()),
    // getSectorsSummaryData: (data) => dispatch(getSectorsSummaryData(data)),
    // getSectorsSummaryGetData: (data) => dispatch(getSectorsSummaryGetData(data)),
    // resetFunction: () => dispatch(resetFunction()),
    //searchBlock : () => dispatch(searchBlock()),
    // warehouseDataGetCall: () => dispatch(getWarehouseData()),
    // addCategoryName: (data) => dispatch(addCategoryName(data)),
    getCategoryData: () => dispatch(getCategoryData()),
    deleteCategory: (categoryId) => dispatch(deleteCategory(categoryId)),
    getWarehouseData: () => dispatch(getWarehouseData()),
    getsubCategoryData: () => dispatch(getsubCategoryData()),
    addInventory: (data) => dispatch(addInventory(data)),
    getInventory: () => dispatch(getInventory()),
    deleteInventory: (inventoryId) => dispatch(deleteInventory(inventoryId)),
    updateInventory: (newData, oldData) =>
      dispatch(updateInventory(newData, oldData)),
    getAttributeById: (warehouseValue) =>
      dispatch(getAttributeById(warehouseValue)),
    resetState: () => dispatch(resetReducer()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  //sectorsList: state.uploadDataReducer.sectorsList,
  //isFetchingSectors: state.uploadDataReducer.isFetchingSectors,
  //summaryList: state.SummaryReducer.summaryList,
  //isFetchingSectorsSummary: state.SummaryReducer.isFetchingSectorsSummary,
  //blockList: state.searchReducer.blockList,
  //isState: state.SummaryReducer.isState,
  warehouseList: state.AddDataReducer.warehouseList,
  categoryList: state.AddDataReducer.categoryList,
  subCategoryList: state.AddDataReducer.subCategoryList,
  inventoryList: state.AddDataReducer.inventoryList,
  attributeById: state.AddDataReducer.attributeById,
  dataAddedInventory: state.AddDataReducer.dataAddedInventory,
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminInventory);
