import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button } from "react-bootstrap";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { isEmpty, get } from "lodash";
import {
  getEmployeeData,
  getEmployeeDataWithStatus,
  getInfoLTLedger,
  getLtLedger,
} from "./HRDetails.actions";
import { appendNonSignificantZeros } from "../../../../../global/globalFunctions";
import { baseURL, headers } from "../../../../../global/api";

import Select from "react-select";
import { Redirect, Link } from "react-router-dom";
import MaterialTable from "material-table";

const HRLTLedger = (props) => {
  const [show, setShow] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [item, setItem] = useState();
  console.log(item, "item");
  const getEmployeeList =
    !isEmpty(props.activeEmployeeList) &&
    props.activeEmployeeList.map((x) => {
      let data = {
        value: x.employeeId,
        label: `${appendNonSignificantZeros(x.employeeId)}-${x.name}`,
      };
      return data;
    });
  !isEmpty(getEmployeeList) &&
    getEmployeeList.push({ value: "All", label: "All" });

  const LTLedger = props.ltLedger;
  console.log(LTLedger, "ledger");

  const exportPDF = () => {
    props.getLtLedger({
      startedDate: !isEmpty(startDate) ? startDate : "",
      endDate: !isEmpty(endDate) ? endDate : "",
    });
    setShow(true);
  };
  useEffect(() => {
    // props.getEmployeeData();
    // props.getEmployeeDataWithStatus(1);
  }, []);

  return (
    <>
      {props.isFetchingLtLedger ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {props.isFetchingLtLedger ? "Loading..." : "Loading..."}
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Row>
              <Col lg="4" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b> Start Date </b>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    onChange={(e) => setStartDate(e.target.value)}
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col lg="4" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b> End Date</b>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    onChange={(e) => setEndDate(e.target.value)}
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col lg="12" sm="12" className="text-center">
                {startDate == "" || endDate == "" ? (
                  <Button
                    disabled
                    style={{ backgroundColor: "black" }}
                    className="sendButton"
                  >
                    Search
                  </Button>
                ) : (
                  <Button className="sendButton" onClick={() => exportPDF()}>
                    Search
                  </Button>
                )}
              </Col>
            </Row>

            {/* <div className="sendDiv">
              {item == "" ?
                <Button disabled style={{ backgroundColor: "black" }} className="sendButton">Generate Ledger Report</Button> :
                <Button className="sendButton" onClick={() => searchLedger()}>Generate Ledger Report</Button>}
            </div> */}
            {show && (
              <MaterialTable
                title={`Long Term Advance Report ${startDate} - ${endDate}`}
                columns={[
                  { title: "Emp Id", field: "employee_id" },
                  { title: "Name", field: "employee_name" },
                  { title: "Department Name", field: "departmentName" },
                  { title: "Designation Name", field: "designationName" },
                  // { title: 'mode', field: 'acc_type' },
                  // { title: 'Montly Ded', field: 'long_term_deduction_value' },
                  // { title: 'Description', field: 'long_term_description' },
                  { title: "Opening Balance", field: "opening_balance" },
                  { title: "Advance Given", field: "credit_value" },
                  { title: "Advance Amount", field: "debit_value" },
                  { title: "Closing Balance", field: "closing_balance" },
                  // { title: 'Date', field: 'long_term_ledger_date' }
                ]}
                // data={
                //   // LTLedger
                //   (query) =>
                //     new Promise((resolve, reject) => {
                //       let url = `${baseURL}long_term_adv/get_lt_employee_ledger?`;
                //       url += "size=" + query.pageSize;
                //       url += "&page=" + (query.page + 1);
                //       url += "&search=" + query.search;
                //       fetch(url, {
                //         method: 'GET',
                //         headers: headers,
                //       })
                //         .then((response) => response.json())
                //         .then((result) => {
                //           resolve({
                //             data: result.data,
                //             page: result.page - 1,
                //             totalCount: result.total_results,
                //           });
                //         });
                //     })
                // }
                data={props.ltLedger}
                options={{
                  actionsColumnIndex: -1,
                  filtering: false,
                  exportButton: true,
                  exportAllData: true,
                  maxBodyHeight: "600px",
                  emptyRowsWhenPaging: false,
                  headerStyle: {
                    position: "sticky",
                    top: 0,
                    color: "#00BBBB",
                    fontWeight: "550",
                    onRowAdd: "none",
                  },
                }}
              />
            )}
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getEmployeeData: () => dispatch(getEmployeeData()),
    getInfoLTLedger: (data) => dispatch(getInfoLTLedger(data)),
    // searchAccountLedger: (data) => dispatch(searchAccountLedger(data)),
    getLtLedger: (data) => dispatch(getLtLedger(data)),
    getEmployeeDataWithStatus: (status) =>
      dispatch(getEmployeeDataWithStatus(status)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  isFetchingLtLedger: state.HrReducer.isFetchingLtLedger,
  ltLedger: state.HrReducer.ltLedger,
  employeeList: state.HrReducer.employeeList,
  isFetchingEmployeeData: state.HrReducer.isFetchingEmployeeData,
  ltLedger: state.HrReducer.ltLedger,
  isFetchingLTLedger: state.HrReducer.isFetchingLTLedger,
  level5LedgerData: state.AccountLegderReducer.level5LedgerData,
  searchAccountLedgerList: state.AccountLegderReducer.searchAccountLedgerList,
  isFetchingLevel5LedgerData:
    state.AccountLegderReducer.isFetchingLevel5LedgerData,
  isFetchingSearchAccountLedger:
    state.AccountLegderReducer.isFetchingSearchAccountLedger,

  activeEmployeeList: state.HrReducer.activeEmployeeList,
  isFetchingActiveEmployeeData: state.HrReducer.isFetchingActiveEmployeeData,
});

export default connect(mapStateToProps, mapDispatchToProps)(HRLTLedger);
