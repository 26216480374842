import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, FormGroup, Alert, Image } from "react-bootstrap";
import { connect } from "react-redux";

import Spinner from 'react-bootstrap/Spinner';
import "../AddDetails/hr-styles.css";
import Select from 'react-select';
import ImageUploader from 'react-images-upload';
import { getEmployeeData, getEmployeeDataWithStatus} from "../AddDetails/HRDetails.actions";
import { getAttendance, deleteAttendance, UpdateAttendance } from "./salary.actions";
import MaterialTable from 'material-table';
import { isEmpty, get, find, chain, uniqBy, isNull } from "lodash";
import EditIcon from '@mui/icons-material/Edit';
import { appendNonSignificantZeros } from "../../../../../global/globalFunctions";
import { baseURL, headers } from "../../../../../global/api";
import moment from "moment";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { salaryHistoryData } from "../SalaryManagment/generateSalary.action";
import swal from "sweetalert";



const ViewPastSalary = (props) => {
  
    const [startDate, setStartDate] = useState();
    const [visibleAlert, setAlertVisible] = useState(false);
    const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
  
    const handleVisible = (msg) => {
      setAlertVisible(true);
      setTimeout(() => {
        setAlertVisible(false);
      }, 9000);
      swal({
        title: "Success",
        text: msg,
        icon: "success",
        button: "Ok",
      });
    };
  
    const handleDangerVisible = (err) => {
      setDangerVisibleAlert(true);
      setTimeout(() => {
        setDangerVisibleAlert(false);
      }, 9000);
      swal({
        title: "Error",
        text: err,
        icon: "error",
        button: "Ok",
      });
    };
    // const [show,setShow]=useState(false);
    const handleGenerate = () => {
        props.getSalaryHistory({
            month: startDate,
        },handleDangerVisible);

    };

    useEffect(() => {
     
    }, []);
    return (
        <Container fluid>
             <div className="mainHr">
                <Row>
                     <Col lg="6" md="6" xs="12">
              <Form.Group className="mb-3" controlId="garana" > {/*Month*/}
                <Form.Label><b>Select Month:</b></Form.Label>
                <br/>
                <LocalizationProvider dateAdapter={AdapterMoment}>

<DatePicker

  openTo="month"
  inputFormat="YYYY-MM"

  views={['year', 'month']}
  value={startDate}
  onChange={(newVal) => (setStartDate(moment(newVal).format("YYYY-MM")))}
  renderInput={(params) => <TextField {...params} />}
/>
</LocalizationProvider>
              </Form.Group>
            </Col>
                </Row>

                <div className="sendDiv">
                    <Button className="sendButton" onClick={() => { handleGenerate(); }}>Generate Salary</Button>
                </div>
            </div>
    

        </Container>)
}

const mapDispatchToProps = (dispatch) => {
    return {
        getSalaryHistory: (data,handleDangerVisible) => dispatch(salaryHistoryData(data,handleDangerVisible)),
        // getEmployeeData: () => dispatch(getEmployeeData()),
        // UpdateAttendance: (newData, oldData, employeeData) => dispatch(UpdateAttendance(newData, oldData, employeeData)),
        // deleteAttendance: (id, employeeData) => dispatch(deleteAttendance(id, employeeData)),
        // getEmployeeDataWithStatus: (status) => dispatch(getEmployeeDataWithStatus(status)),

    };
};
const mapStateToProps = (state, ownProps) => ({
    // employeeList: state.HrReducer.employeeList,
    attendanceData: state.SalaryReducer.attendanceData,
    isFetchingAttendanceData:state.SalaryReducer.isFetchingAttendanceData,
    activeEmployeeList: state.HrReducer.activeEmployeeList,
    isFetchingActiveEmployeeData: state.HrReducer.isFetchingActiveEmployeeData,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ViewPastSalary);