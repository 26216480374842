import API from "../../../../../global/api";

//get order id in render the component
export const getOrderCall = () => {
    return (dispatch) => {
        dispatch(requestGetOrder())
        API.get(`createOrder/get-all-order-data`).then((res) => {
            dispatch(successGetOrder(res.data))
        }).catch((error) => {
            dispatch(errorGetOrder())
        })
    }
}

export const requestGetOrder = () => {
    return {
        type: "REQUEST_GET_ORDER"
    }
}

export const successGetOrder = (data) => {
    return {
        type: "SUCCESS_GET_ORDER",
        payload: data,
    }
}

export const errorGetOrder = () => {
    return {
        type: "ERROR_GET_ORDER"
    }
}



export const getArticleByOrder = (data) => {
    return (dispatch) => {
        dispatch(requestGetArticleByOrder())
        API.get(`/createOrder/get_order_article/${data}`).then((res) => {
            dispatch(successGetArticleByOrder(res.data))
        }).catch((error) => {
            dispatch(errorGetArticleByOrder())
        })
    }
}

export const requestGetArticleByOrder = () => {
    return {
        type: "REQUEST_GET_ARTICLE_BY_ORDER"
    }
}

export const successGetArticleByOrder = (data) => {
    return {
        type: "SUCCESS_GET_ARTICLE_BY_ORDER",
        payload: data,
    }
}

export const errorGetArticleByOrder = () => {
    return {
        type: "ERROR_GET_ARTICLE_BY_ORDER"
    }
}


export const getDataByOrderArticle = (data) => {
    return (dispatch) => {
        dispatch(requestGetDataByOrderArticle())
        API.get(`/createOrder/get_order_inventory/${data}`).then((res) => {
            dispatch(successGetDataByOrderArticle(res.data))
        }).catch((error) => {
            dispatch(errorGetDataByOrderArticle())
        })
    }
}

export const requestGetDataByOrderArticle = () => {
    return {
        type: "REQUEST_GET_DATA_BY_ORDER_ARTICLE"
    }
}

export const successGetDataByOrderArticle = (data) => {
    return {
        type: "SUCCESS_GET_DATA_BY_ORDER_ARTICLE",
        payload: data,
    }
}

export const errorGetDataByOrderArticle = () => {
    return {
        type: "ERROR_GET_DATA_BY_ORDER_ARTICLE"
    }
}