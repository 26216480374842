import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Container,
  Button,
  FormGroup,
  Alert,
} from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty, isNull, upperCase, sumBy, filter, uniqBy } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import "jspdf-autotable";
import logo from "../../../../Images/logo.png";
import Select from "react-select";
import Swal from "sweetalert";
import { appendNonSignificantZeros } from "../../../../global/globalFunctions";
import { baseURL, headers } from "../../../../global/api";
import { getAccountMasterControl } from "./AddDetails.actions";
import { FormControlLabel, Switch } from "@mui/material";
import jsPDF from "jspdf";

const UserSelectedAccounts = (props) => {
  const [s1, setS1] = useState(4);
  const [sLabel, setSLabel] = useState("4 Column");
  // const [sign, setSign] = useState("");
  const handleSwitch = () => {
    if (s1 == 4) {
      setS1(7);
      setSLabel("7 Column");
    } else {
      setS1(4);
      setSLabel("4 Column");
    }
  };
  let debit = filter(
    props.accountMasterControlList,
    (x) => x.debitCreditId == 2
  );
  let credit = filter(
    props.accountMasterControlList,
    (x) => x.debitCreditId == 1
  );
  const deValue = sumBy(debit, "levelBalance");
  const CrValue = sumBy(credit, "levelBalance");
  console.log(debit, credit, deValue, CrValue, "vvvvvvvvvvvvvvvvvvv");
  // if (Number(deValue) > Number(CrValue)) {
  //   setSign("Debit");
  // }
  // else if(CrValue>deValue){setSign("Credit")}
  const netValue = Number(deValue) - Number(CrValue);
  useEffect(() => {
    props.getAccountList();
  }, []);
  return (
    <>
      {props.isFetchingAccountMasterControlList ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {/* {props.isFetchingwareHouseList ? 'Loading...' : 'Loading...'} */}
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <div>
              <Form>
                <FormControlLabel
                  style={{ alignSelf: "" }}
                  control={<Switch onClick={handleSwitch} />}
                  label={sLabel}
                />
                {s1 === 4 ? (
                  <MaterialTable
                    title={
                      <div style={{ marginTop: "5px" }}>
                        <h5>Account</h5>
                        <h5>
                          Total Credit:{" "}
                          {props.accountMasterControlList.length <= 1
                            ? 0
                            : CrValue}
                        </h5>
                        <h5>
                          Total Debit:{" "}
                          {props.accountMasterControlList.length <= 1
                            ? 0
                            : deValue}
                        </h5>
                        <h5>
                          Net Balance:{" "}
                          {props.accountMasterControlList.length <= 1
                            ? 0
                            : Math.abs(netValue)}{" "}
                          {Number(deValue) > Number(CrValue)
                            ? "Debit"
                            : Number(deValue) < Number(CrValue)
                            ? "Credit"
                            : ""}
                        </h5>
                      </div>
                    }
                    columns={[
                      { title: "Account Code", field: "allLevelKey" },
                      { title: "Account Name", field: "levelFiveName" },
                      { title: "Account Balance", field: "levelBalance" },
                      {
                        title: "DB/CR",
                        field: "debitCreditId",
                        render: (rowData) =>
                          rowData.debitCreditId == 1
                            ? "Credit"
                            : rowData.debitCreditId == 2
                            ? "Debit"
                            : rowData.debitCreditId,
                      },
                    ]}
                    data={props.accountMasterControlList}
                    options={{
                      actionsColumnIndex: -1,
                      exportButton: true,
                      paging: true,
                      pageSize: 200, // make initial page size
                      emptyRowsWhenPaging: false, // To avoid of having empty rows
                      pageSizeOptions: [50, 100, 150, 200],
                      maxBodyHeight: "600px",
                      headerStyle: {
                        position: "sticky",
                        top: 0,
                        color: "#00BBBB",
                        fontWeight: "550",
                        onRowAdd: "none",
                      },
                      exportPdf: (columns, data) => {
                        console.log(data, "ttttttt");
                        const doc = new jsPDF();
                        const columnTitles = [
                          { title: "Account Code", field: "levelFiveId" },
                          { title: "Account Name", field: "levelFiveName" },
                          { title: "Account Balance", field: "levelBalance" },
                          {
                            title: "DB/CR",
                            field: "debitCreditId",
                            render: (rowData) =>
                              rowData.debitCreditId == 1
                                ? "Credit"
                                : rowData.debitCreditId == 2
                                ? "Debit"
                                : rowData.debitCreditId,
                          },
                        ];
                        //  const pdfData = data.map((rowData) => [rowData.sub_event_name, rowData.sub_event_desc,rowData.event.event_name]);
                        const pdfData = data.map((columnDef) => [
                          columnDef.levelFiveId,
                          columnDef.levelFiveName,
                          columnDef.levelBalance,
                          columnDef.debitCreditId == 1
                            ? "Credit"
                            : columnDef.debitCreditId == 2
                            ? "Debit"
                            : columnDef.debitCreditId,
                        ]);
                        let content = {
                          startY: 90,
                          startX: 5,
                          head: [columnTitles],
                          body: pdfData,
                        };
                        const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
                        const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
                        const companyNTN = `STRN#${process.env.REACT_APP_COMPANY_NTN}`;
                        const si =
                          Number(deValue) > Number(CrValue)
                            ? "Debit"
                            : Number(deValue) < Number(CrValue)
                            ? "Credit"
                            : "";
                        doc.addImage(logo, "PNG", 10, 11, 30, 30);
                        // doc.text(`Company Name: ${companyName}`, 75, 20, 0, 20);

                        doc.setFont("Times New Roman", "bolditalic");
                        // doc.setFontT("bolditalic");
                        doc.text(companyName, 75, 15, 0, 20);
                        doc.setFontSize(9);
                        doc.setFont("Times New Roman", "normal");
                        doc.text(companyAddress, 75, 20, 0, 20);
                        doc.text(companyNTN, 75, 25, 0, 20);
                        doc.setFontSize(14);
                        doc.setFont("Times New Roman", "normal");
                        doc.text("Account Report", 75, 46, 0, 20);
                        doc.text(`Total Debit: ${deValue}`, 5, 56, 0, 20);
                        doc.text(`Total Credit: ${CrValue}`, 5, 66, 0, 20);
                        doc.text(
                          `Net Balance:${Math.abs(netValue)} ${si}`,
                          5,
                          76,
                          0,
                          20
                        );
                        doc.autoTable(content);

                        doc.save(`Report.pdf`);
                      },
                    }}
                  />
                ) : (
                  <MaterialTable
                    title={
                      <div style={{ marginTop: "5px" }}>
                        <h5>Account</h5>
                        <h5>
                          Total Credit:{" "}
                          {props.accountMasterControlList.length <= 1
                            ? 0
                            : CrValue}
                        </h5>
                        <h5>
                          Total Debit:{" "}
                          {props.accountMasterControlList.length <= 1
                            ? 0
                            : deValue}
                        </h5>
                        <h5>
                          Net Balance:{" "}
                          {props.accountMasterControlList.length <= 1
                            ? 0
                            : Math.abs(netValue)}{" "}
                          {Number(deValue) > Number(CrValue)
                            ? "Debit"
                            : Number(deValue) < Number(CrValue)
                            ? "Credit"
                            : ""}
                        </h5>
                      </div>
                    }
                    columns={[
                      { title: "Account Code", field: "allLevelKey" },
                      { title: "Account Name", field: "levelFiveName" },
                      { title: "Account Balance", field: "levelBalance" },
                      {
                        title: "DB/CR",
                        field: "debitCreditId",
                        render: (rowData) =>
                          rowData.debitCreditId == 1
                            ? "Credit"
                            : rowData.debitCreditId == 2
                            ? "Debit"
                            : rowData.debitCreditId,
                      },
                      {
                        title: "Last Transcation Date",
                        field: "lastTransectionDate",
                      },
                      {
                        title: "Last Transcation Balance",
                        field: "lastTransectionAmount",
                      },
                      {
                        title: "Last Transcation DB/CR",
                        field: "lastTransectionType",
                        render: (rowData) =>
                          rowData.lastTransectionType == 1
                            ? "Credit"
                            : rowData.lastTransectionType == 2
                            ? "Debit"
                            : rowData.lastTransectionType,
                      },
                    ]}
                    data={props.accountMasterControlList}
                    options={{
                      actionsColumnIndex: -1,
                      exportButton: true,
                      paging: true,
                      pageSize: 200, // make initial page size
                      emptyRowsWhenPaging: false, // To avoid of having empty rows
                      pageSizeOptions: [50, 100, 150, 200],
                      maxBodyHeight: "600px",
                      headerStyle: {
                        position: "sticky",
                        top: 0,
                        color: "#00BBBB",
                        fontWeight: "550",
                        onRowAdd: "none",
                      },
                      exportPdf: (columns, data) => {
                        console.log(data, "ttttttt");
                        const doc = new jsPDF();
                        const columnTitles = [
                          { title: "Account Code", field: "allLevelKey" },
                          { title: "Account Name", field: "levelFiveName" },
                          { title: "Account Balance", field: "levelBalance" },
                          {
                            title: "DB/CR",
                            field: "debitCreditId",
                            render: (rowData) =>
                              rowData.debitCreditId == 1
                                ? "Credit"
                                : rowData.debitCreditId == 2
                                ? "Debit"
                                : rowData.debitCreditId,
                          },
                          {
                            title: "Last Transcation Date",
                            field: "lastTransectionDate",
                          },
                          {
                            title: "Last Transcation Balance",
                            field: "lastTransectionAmount",
                          },
                          {
                            title: "Last Transcation DB/CR",
                            field: "lastTransectionType",
                            render: (rowData) =>
                              rowData.lastTransectionType == 1
                                ? "Credit"
                                : rowData.lastTransectionType == 2
                                ? "Debit"
                                : rowData.lastTransectionType,
                          },
                        ];
                        //  const pdfData = data.map((rowData) => [rowData.sub_event_name, rowData.sub_event_desc,rowData.event.event_name]);
                        const pdfData = data.map((columnDef) => [
                          columnDef.levelFiveId,
                          columnDef.levelFiveName,
                          columnDef.levelBalance,
                          columnDef.debitCreditId == 1
                            ? "Credit"
                            : columnDef.debitCreditId == 2
                            ? "Debit"
                            : columnDef.debitCreditId,
                          columnDef.lastTransectionDate,
                          columnDef.lastTransectionAmount,
                          columnDef.lastTransectionType == 1
                            ? "Credit"
                            : columnDef.lastTransectionType == 2
                            ? "Debit"
                            : columnDef.lastTransectionType,
                        ]);
                        let content = {
                          startY: 90,
                          startX: 5,
                          head: [columnTitles],
                          body: pdfData,
                        };
                        const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
                        const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
                        const companyNTN = `STRN#${process.env.REACT_APP_COMPANY_NTN}`;
                        const si =
                          Number(deValue) > Number(CrValue)
                            ? "Debit"
                            : Number(deValue) < Number(CrValue)
                            ? "Credit"
                            : "";
                        doc.addImage(logo, "PNG", 10, 11, 30, 30);
                        // doc.text(`Company Name: ${companyName}`, 75, 20, 0, 20);

                        doc.setFont("Times New Roman", "bolditalic");
                        // doc.setFontT("bolditalic");
                        doc.text(companyName, 75, 15, 0, 20);
                        doc.setFontSize(9);
                        doc.setFont("Times New Roman", "normal");
                        doc.text(companyAddress, 75, 20, 0, 20);
                        doc.text(companyNTN, 75, 25, 0, 20);
                        doc.setFontSize(14);
                        doc.setFont("Times New Roman", "normal");
                        doc.text("Account Report", 80, 46, 0, 20);
                        doc.text(`Total Debit: ${deValue}`, 5, 56, 0, 20);
                        doc.text(`Total Credit: ${CrValue}`, 5, 66, 0, 20);
                        doc.text(
                          `Net Balance:${Math.abs(netValue)} ${si}`,
                          5,
                          76,
                          0,
                          20
                        );
                        doc.autoTable(content);

                        doc.save(`Report.pdf`);
                      },
                    }}
                  />
                )}
              </Form>
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAccountList: () => dispatch(getAccountMasterControl()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  accountMasterControlList: state.AddDataReducer.accountMasterControlList,
  isFetchingAccountMasterControlList:
    state.AddDataReducer.isFetchingAccountMasterControlList,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserSelectedAccounts);
