import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import {isNull } from "lodash";
import EmployeeType from "./EmployeeType";
import EmployeeTypeList from "./EmployeeTypeList";


const TotalEmployeeTypeData = (props) => {
    const permissions = JSON.parse(localStorage.getItem("permissions"));

    return (
        <>
            <Tabs defaultActiveKey={!isNull(permissions) && permissions.some(p => p['sub_id'] === 52) ?
                    "AddEmployeeType" : !isNull(permissions) && permissions.some(p => p['sub_id'] === 53) ?
                        "EmployeeTypeList" : ""} id="AddEmployeeType" className="mb-3">
                <Tab eventKey="AddEmployeeType" title="Add EmployeeType"
                disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 52) ? false : true}>  
                   
                   <EmployeeType />
                </Tab>
                <Tab eventKey="EmployeeTypeList" title="EmployeeType List"
                disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 53) ? false : true}> 
                  <EmployeeTypeList />
                </Tab>
            </Tabs>
        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
    };
};
const mapStateToProps = (state, ownProps) => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TotalEmployeeTypeData);