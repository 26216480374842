import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import {isNull } from "lodash";
import AddDepartment from "./AddDepartment";
import DepartmentList from "./DepartmentList";
const TotalDepartmentData = (props) => {
    const permissions = JSON.parse(localStorage.getItem("permissions"));

    return (
        <>
            <Tabs defaultActiveKey={!isNull(permissions) && permissions.some(p => p['sub_id'] === 56) ?
                    "AddDepartment" : !isNull(permissions) && permissions.some(p => p['sub_id'] === 57) ?
                        "DepartmentList" : ""} 
                                id="AddDepartment" className="mb-3">
                <Tab eventKey="AddDepartment" title="Add Department"
                disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 56) ? false : true}>  
                    <AddDepartment />
                </Tab>
                <Tab eventKey="DepartmentList" title="Department List"
                disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 57) ? false : true}> 
                    <DepartmentList />
                </Tab>
            </Tabs>
        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
    };
};
const mapStateToProps = (state, ownProps) => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TotalDepartmentData);