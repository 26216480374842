import React, { useEffect } from "react";
import { Container, Button } from "react-bootstrap";
import "./add-details.css";
import {
  getProductByDemand,
  getPurchase,
  getApproveDemand,
  getApprovePurchase,
  purchaseById,
  addIGP,
  getIGP,
  searchIGPReport,
  resetPurchaseReducer,
  purchaseFromDemandCall,
} from "./AddDetails.actions";
import { connect } from "react-redux";
import moment from "moment";
import Spinner from "react-bootstrap/Spinner";
import WareHouseModal from "./WareHouseModal";
import MaterialTable from "material-table";
import { isEmpty } from "lodash";

const IGPDetail = (props) => {
  const wareHouseData = props.IGPList;
  const [open, setOpen] = React.useState(false);
  // const [state, setState] = React.useState({
  //     viewColumns: [
  //         { title: 'IGP Key', field: 'igpId' },
  //         { title: 'GatePass Number', field: 'gatePass' },
  //         { title: 'Receiver Name', field: 'receivername' },
  //         { title: 'Order Type', field: 'orderType' },
  //         { title: 'Stock Update', field: 'updatedAt', render: rowData => moment(rowData.createdAt).format("YYYY-MM-DD") },
  //         // { title: 'IGP Tax', field: 'igpTax' },
  //         { title: 'Created At', field: 'createdAt', render: rowData => moment(rowData.createdAt).format("YYYY-MM-DD") },
  //     ],
  //     columnsData: [
  //         { title: 'Inventory Code', field: 'inventory', render: rowData => rowData.inventory.warehouse.whKey + "-" + rowData.inventory.category.cateKey + "-" + rowData.inventory.invKey },
  //         { title: 'Inventory Name', field: 'inventory.productName' },
  //         { title: 'Product Quantity', field: 'productQuantity' },
  //     ],
  // });
  useEffect(() => {
    props.getApproveDemand();
    props.getIGP();
    props.resetPurchaseReducer();
  }, []);
  return (
    <>
      {props.isFetchingIGP ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {/* {props.isFetchingSectorsSummary ? 'Loading...' : 'Loading...'} */}
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            {open && <WareHouseModal open={open} />}
            <MaterialTable
              title="Inward Gate Pass Details"
              //columns={state.viewColumns}
              columns={[
                { title: "IGP Key", field: "igpId" },
                { title: "GatePass Number", field: "gatePass" },
                { title: "Receiver Name", field: "receivername" },
                { title: "Order Type", field: "orderType" },
                {
                  title: "Stock Update",
                  field: "updatedAt",
                  render: (rowData) =>
                    moment(rowData.createdAt).format("YYYY-MM-DD"),
                },
                // { title: 'IGP Tax', field: 'igpTax' },
                {
                  title: "Created At",
                  field: "createdAt",
                  render: (rowData) =>
                    moment(rowData.createdAt).format("YYYY-MM-DD"),
                },
              ]}
              data={isEmpty(wareHouseData) ? [] : wareHouseData.reverse()}
              detailPanel={(rowData) => {
                return (
                  <MaterialTable
                    // columns={state.columnsData}
                    columns={[
                      {
                        title: "Inventory Code",
                        field: "inventory",
                        render: (rowData) =>
                          rowData.inventory.warehouse.whKey +
                          "-" +
                          rowData.inventory.category.cateKey +
                          "-" +
                          rowData.inventory.invKey,
                      },
                      {
                        title: "Inventory Name",
                        field: "inventory.productName",
                      },
                      { title: "Product Quantity", field: "productQuantity" },
                    ]}
                    data={rowData.igp_data}
                    options={{
                      actionsColumnIndex: -1,
                      toolbar: false,
                      exportButton: true,
                      sorting: true,
                      paging: true,
                      pageSize: 200, // make initial page size
                      emptyRowsWhenPaging: false, // To avoid of having empty rows
                      pageSizeOptions: [50, 100, 150, 200], // rows selection options
                      maxBodyHeight: "600px",
                      headerStyle: {
                        position: "sticky",
                        top: 0,
                        color: "#00BBBB",
                        fontWeight: "550",
                        onRowAdd: "none",
                      },
                    }}
                  />
                );
              }}
              options={{
                actionsColumnIndex: -1,
                filtering: true,
                exportButton: true,
                paging: true,
                pageSize: 200, // make initial page size
                emptyRowsWhenPaging: false, // To avoid of having empty rows
                pageSizeOptions: [50, 100, 150, 200],
                maxBodyHeight: "600px",
                headerStyle: {
                  position: "sticky",
                  top: 0,
                  color: "#00BBBB",
                  fontWeight: "550",
                  onRowAdd: "none",
                },
              }}
              onRowClick={(event, rowData, togglePanel) => togglePanel()}
            />
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getPurchase: () => dispatch(getPurchase()),
    getProductByDemand: (demandId) => dispatch(getProductByDemand(demandId)),
    getApproveDemand: () => dispatch(getApproveDemand()),
    getApprovePurchase: () => dispatch(getApprovePurchase()),
    purchaseById: (data) => dispatch(purchaseById(data)),
    addIGP: (data, handleVisible, handleDangerVisible) =>
      dispatch(addIGP(data, handleVisible, handleDangerVisible)),
    getIGP: () => dispatch(getIGP()),
    searchIGPReport: (data, igpName, startDate, endDate) =>
      dispatch(searchIGPReport(data, igpName, startDate, endDate)),
    resetPurchaseReducer: () => dispatch(resetPurchaseReducer()),
    purchaseFromDemandCall: (data) => dispatch(purchaseFromDemandCall(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  isFetchingDemand: state.AddDataReducer.isFetchingDemand,
  isFetchingInventoryAll: state.AddDataReducer.isFetchingInventoryAll,
  isAddingPurchase: state.AddDataReducer.isAddingPurchase,
  notAddPurchase: state.AddDataReducer.notAddPurchase,
  approvePurchaseList: state.AddDataReducer.approvePurchaseList,
  purchaseListById: state.AddDataReducer.purchaseListById,
  IGPList: state.AddDataReducer.IGPList,
  isFetchingSearchIGPList: state.AddDataReducer.isFetchingSearchIGPList,
  approveDemandList: state.AddDataReducer.approveDemandList,
  purchaseFromDemandData: state.AddDataReducer.purchaseFromDemandData,
  isFetchingIGP: state.AddDataReducer.isFetchingIGP,
  isFetchningApproveDemandList:
    state.AddDataReducer.isFetchningApproveDemandList,
  isFetchingPurchaseFromDemand:
    state.AddDataReducer.isFetchingPurchaseFromDemand,
  isFetchingPurchaseById: state.AddDataReducer.isFetchingPurchaseById,
});

export default connect(mapStateToProps, mapDispatchToProps)(IGPDetail);
