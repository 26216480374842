import React, { useEffect, useState, forwardRef } from "react";
import { InputGroup, Row, Col, Form, Table, Container, Button, FormGroup, Modal, Alert, Tabs, Tab } from "react-bootstrap";
import Select from 'react-select';
//import { getSectorsList } from "../uploadData/uploadData.actions";
import { connect } from "react-redux";
import { isEmpty, get, map, isNull, find, isUndefined } from "lodash";
import moment from 'moment';
import Spinner from 'react-bootstrap/Spinner';
import { getLevel1Data, getLevelOneName, getLevelTwoName, getLevelFourName } from "./balanceSheet.actions";
import {
  TableCell
} from '@material-ui/core';
import {
  TableRow
} from '@material-ui/core';
import {
  TableHead
} from '@material-ui/core';
import { Link } from "react-router-dom";
import MaterialTable from 'material-table';
import { useHistory } from "react-router-dom";

const BalanceSheetMain = (props) => {
  const [name1, setName1] = useState();
  const [level1Id1, setLevel1Id1] = useState();
  const [level1data1, setLevel1data1] = useState();
  const [level2Id1, setLevel2Id1] = useState();
  const [level2Data1, setLevel2data1] = useState();
  const [level3Id1, setLevel3Id1] = useState();
  const [level3Data1, setLevel3Data1] = useState();
  const [level1Balance1, setLevel1Balance1] = useState();
  const [level2Balance1, setLevel2Balance1] = useState();
  const [level3Balance1, setLevel3Balance1] = useState();
  console.log(level2Balance1, "bbbbbbbbbbbbbbbbbb");

  const [name2, setName2] = useState();
  const [level1Id2, setLevel1Id2] = useState();
  const [level1data2, setLevel1data2] = useState();
  const [level2Id2, setLevel2Id2] = useState();
  const [level2Data2, setLevel2data2] = useState();
  const [level3Id2, setLevel3Id2] = useState();
  const [level3Data2, setLevel3Data2] = useState();
  const [level1Balance2, setLevel1Balance2] = useState();
  const [level2Balance2, setLevel2Balance2] = useState();
  const [level3Balance2, setLevel3Balance2] = useState();

  const [name3, setName3] = useState();
  const [level1Id3, setLevel1Id3] = useState();
  const [level1data3, setLevel1data3] = useState();
  const [level2Id3, setLevel2Id3] = useState();
  const [level2Data3, setLevel2data3] = useState();
  const [level3Id3, setLevel3Id3] = useState();
  const [level3Data3, setLevel3Data3] = useState();
  const [level1Balance3, setLevel1Balance3] = useState();
  const [level2Balance3, setLevel2Balance3] = useState();
  const [level3Balance3, setLevel3Balance3] = useState();

  const [name4, setName4] = useState();
  const [level1Id4, setLevel1Id4] = useState();
  const [level1data4, setLevel1data4] = useState();
  const [level2Id4, setLevel2Id4] = useState();
  const [level2Data4, setLevel2data4] = useState();
  const [level3Id4, setLevel3Id4] = useState();
  const [level3Data4, setLevel3Data4] = useState();
  const [level1Balance4, setLevel1Balance4] = useState();
  const [level2Balance4, setLevel2Balance4] = useState();
  const [level3Balance4, setLevel3Balance4] = useState();

  const [name5, setName5] = useState();
  const [level1Id5, setLevel1Id5] = useState();
  const [level1data5, setLevel1data5] = useState();
  const [level2Id5, setLevel2Id5] = useState();
  const [level2Data5, setLevel2data5] = useState();
  const [level3Id5, setLevel3Id5] = useState();
  const [level3Data5, setLevel3Data5] = useState();
  const [level1Balance5, setLevel1Balance5] = useState();
  const [level2Balance5, setLevel2Balance5] = useState();
  const [level3Balance5, setLevel3Balance5] = useState();

  const [name6, setName6] = useState();
  const [level1Id6, setLevel1Id6] = useState();
  const [level1data6, setLevel1data6] = useState();
  const [level2Id6, setLevel2Id6] = useState();
  const [level2Data6, setLevel2data6] = useState();
  const [level3Id6, setLevel3Id6] = useState();
  const [level3Data6, setLevel3Data6] = useState();
  const [level1Balance6, setLevel1Balance6] = useState();
  const [level2Balance6, setLevel2Balance6] = useState();
  const [level3Balance6, setLevel3Balance6] = useState();

  const [name7, setName7] = useState();
  const [level1Id7, setLevel1Id7] = useState();
  const [level1data7, setLevel1data7] = useState();
  const [level2Id7, setLevel2Id7] = useState();
  const [level2Data7, setLevel2data7] = useState();
  const [level3Id7, setLevel3Id7] = useState();
  const [level3Data7, setLevel3Data7] = useState();
  const [level1Balance7, setLevel1Balance7] = useState();
  const [level2Balance7, setLevel2Balance7] = useState();
  const [level3Balance7, setLevel3Balance7] = useState();

  const [name8, setName8] = useState();
  const [level1Id8, setLevel1Id8] = useState();
  const [level1data8, setLevel1data8] = useState();
  const [level2Id8, setLevel2Id8] = useState();
  const [level2Data8, setLevel2data8] = useState();
  const [level3Id8, setLevel3Id8] = useState();
  const [level3Data8, setLevel3Data8] = useState();
  const [level1Balance8, setLevel1Balance8] = useState();
  const [level2Balance8, setLevel2Balance8] = useState();
  const [level3Balance8, setLevel3Balance8] = useState();

  const [name9, setName9] = useState();
  const [level1Id9, setLevel1Id9] = useState();
  const [level1data9, setLevel1data9] = useState();
  const [level2Id9, setLevel2Id9] = useState();
  const [level2Data9, setLevel2data9] = useState();
  const [level3Id9, setLevel3Id9] = useState();
  const [level3Data9, setLevel3Data9] = useState();
  const [level1Balance9, setLevel1Balance9] = useState();
  const [level2Balance9, setLevel2Balance9] = useState();
  const [level3Balance9, setLevel3Balance9] = useState();

  const [name10, setName10] = useState();
  const [level1Id10, setLevel1Id10] = useState();
  const [level1data10, setLevel1data10] = useState();
  const [level2Id10, setLevel2Id10] = useState();
  const [level2Data10, setLevel2data10] = useState();
  const [level3Id10, setLevel3Id10] = useState();
  const [level3Data10, setLevel3Data10] = useState();
  const [level1Balance10, setLevel1Balance10] = useState();
  const [level2Balance10, setLevel2Balance10] = useState();
  const [level3Balance10, setLevel3Balance10] = useState();

  const getLevel1List = props.level1List.map((x) => { let data = { value: x.levelOneId, label: x.name }; return data });
  const getLevelTwoList = !isEmpty(props.levelTow) && props.levelTow.map((x) => { let data = { value: x.levelTwoData.levelTwoId, label: x.levelTwoData.levelTwoName }; return data });
  const getlevel3val = !isEmpty(props.levelThree) && props.levelThree.map((x) => { let data = { value: x.levelTwoData.levelThreeId, label: x.levelTwoData.levelThreeName }; return data });


  const handleChangeLevel1One = (selectedOption) => {
    props.getLevelOneName(selectedOption.value);
    setLevel1Id1(selectedOption.value);
    setLevel1data1(selectedOption.label);
    setLevel1Balance1(!isEmpty(props.levelTow) && !isUndefined(props.levelTow[0].LevelOne) && props.levelTow[0].LevelOne.total_amount);
  };

  const handleChangeLevel2One = (selectedOption) => {
    setLevel2Id1(selectedOption.value);
    setLevel2data1(selectedOption.label)
    props.getLevelTwoName(selectedOption.value);
    setLevel2Balance1(!isEmpty(props.levelThree) && !isUndefined(props.levelThree[0].LevelOne)&& props.levelThree[0].LevelOne.total_amount);
  };

  const handleChangeLevel3One = (selectedOption) => {
    setLevel3Id1(selectedOption.value);
    setLevel3Data1(selectedOption.label);
    props.getLevelFourName(selectedOption.value);
    setLevel3Balance1(!isEmpty(props.levelFour) && !isUndefined(props.levelFour[0].LevelOne)&& props.levelFour[0].LevelOne.total_amount);
  };


  const handleChangeLevel1Two = (selectedOption) => {
    props.getLevelOneName(selectedOption.value);
    setLevel1Id2(selectedOption.value);
    setLevel1data2(selectedOption.label);
    setLevel1Balance2(!isEmpty(props.levelTow) && !isUndefined(props.levelTow[0].LevelOne)&& props.levelTow[0].LevelOne.total_amount);

  };

  const handleChangeLevel2Two = (selectedOption) => {
    setLevel2Id2(selectedOption.value);
    setLevel2data2(selectedOption.label)
    props.getLevelTwoName(selectedOption.value);
    setLevel2Balance2(!isEmpty(props.levelThree) && !isUndefined(props.levelThree[0].LevelOne)&& props.levelThree[0].LevelOne.total_amount);

  };

  const handleChangeLevel3Two = (selectedOption) => {
    setLevel3Id2(selectedOption.value);
    setLevel3Data2(selectedOption.label);
    props.getLevelFourName(selectedOption.value);
    setLevel3Balance2(!isEmpty(props.levelFour) && !isUndefined(props.levelFour[0].LevelOne)&& props.levelFour[0].LevelOne.total_amount);
  };

  const handleChangeLevel1Three = (selectedOption) => {
    props.getLevelOneName(selectedOption.value);
    setLevel1Id3(selectedOption.value);
    setLevel1data3(selectedOption.label);
    setLevel1Balance3(!isEmpty(props.levelTow) && !isUndefined(props.levelTow[0].LevelOne)&& props.levelTow[0].LevelOne.total_amount);

  };

  const handleChangeLevel2Three = (selectedOption) => {
    setLevel2Id3(selectedOption.value);
    setLevel2data3(selectedOption.label)
    props.getLevelTwoName(selectedOption.value);
    setLevel2Balance3(!isEmpty(props.levelThree) && !isUndefined(props.levelThree[0].LevelOne)&& props.levelThree[0].LevelOne.total_amount);

  };

  const handleChangeLevel3Three = (selectedOption) => {
    setLevel3Id3(selectedOption.value);
    setLevel3Data3(selectedOption.label);
    props.getLevelFourName(selectedOption.value);
    setLevel3Balance3(!isEmpty(props.levelFour) && !isUndefined(props.levelFour[0].LevelOne)&& props.levelFour[0].LevelOne.total_amount);
  };

  const handleChangeLevel1Four = (selectedOption) => {
    props.getLevelOneName(selectedOption.value);
    setLevel1Id4(selectedOption.value);
    setLevel1data4(selectedOption.label);
    setLevel1Balance4(!isEmpty(props.levelTow) && !isUndefined(props.levelTow[0].LevelOne) && props.levelTow[0].LevelOne.total_amount);

  };

  const handleChangeLevel2Four = (selectedOption) => {
    setLevel2Id4(selectedOption.value);
    setLevel2data4(selectedOption.label)
    props.getLevelTwoName(selectedOption.value);
    setLevel2Balance4(!isEmpty(props.levelThree) && !isUndefined(props.levelThree[0].LevelOne)&& props.levelThree[0].LevelOne.total_amount);

  };

  const handleChangeLevel3Four = (selectedOption) => {
    setLevel3Id4(selectedOption.value);
    setLevel3Data4(selectedOption.label);
    props.getLevelFourName(selectedOption.value);
    setLevel3Balance4(!isEmpty(props.levelFour) && !isUndefined(props.levelFour[0].LevelOne)&& props.levelFour[0].LevelOne.total_amount);
  };

  const handleChangeLevel1Five = (selectedOption) => {
    props.getLevelOneName(selectedOption.value);
    setLevel1Id5(selectedOption.value);
    setLevel1data5(selectedOption.label);
    setLevel1Balance5(!isEmpty(props.levelTow) && !isUndefined(props.levelTow[0].LevelOne)&& props.levelTow[0].LevelOne.total_amount);
  };

  const handleChangeLevel2Five = (selectedOption) => {
    setLevel2Id5(selectedOption.value);
    setLevel2data5(selectedOption.label)
    props.getLevelTwoName(selectedOption.value);
    setLevel2Balance5(!isEmpty(props.levelThree) && !isUndefined(props.levelThree[0].LevelOne)&& props.levelThree[0].LevelOne.total_amount);

  };

  const handleChangeLevel3Five = (selectedOption) => {
    setLevel3Id5(selectedOption.value);
    setLevel3Data5(selectedOption.label);
    props.getLevelFourName(selectedOption.value);
    setLevel3Balance5(!isEmpty(props.levelFour) && !isUndefined(props.levelFour[0].LevelOne)&& props.levelFour[0].LevelOne.total_amount);
  };

  const handleChangeLevel1Six = (selectedOption) => {
    props.getLevelOneName(selectedOption.value);
    setLevel1Id6(selectedOption.value);
    setLevel1data6(selectedOption.label);
    setLevel1Balance6(!isEmpty(props.levelTow) && !isUndefined(props.levelTow[0].LevelOne)&& props.levelTow[0].LevelOne.total_amount);

  };

  const handleChangeLevel2Six = (selectedOption) => {
    setLevel2Id6(selectedOption.value);
    setLevel2data6(selectedOption.label)
    props.getLevelTwoName(selectedOption.value);
    setLevel2Balance6(!isEmpty(props.levelThree) && !isUndefined(props.levelThree[0].LevelOne)&& props.levelThree[0].LevelOne.total_amount);

  };

  const handleChangeLevel3Six = (selectedOption) => {
    setLevel3Id6(selectedOption.value);
    setLevel3Data6(selectedOption.label);
    props.getLevelFourName(selectedOption.value);
    setLevel3Balance6(!isEmpty(props.levelFour) && !isUndefined(props.levelFour[0].LevelOne)&& props.levelFour[0].LevelOne.total_amount);
  };

  const handleChangeLevel1Seven = (selectedOption) => {
    props.getLevelOneName(selectedOption.value);
    setLevel1Id7(selectedOption.value);
    setLevel1data7(selectedOption.label);
    setLevel1Balance7(!isEmpty(props.levelTow) && !isUndefined(props.levelTow[0].LevelOne)&& props.levelTow[0].LevelOne.total_amount);


  };

  const handleChangeLevel2Seven = (selectedOption) => {
    setLevel2Id7(selectedOption.value);
    setLevel2data7(selectedOption.label)
    props.getLevelTwoName(selectedOption.value);
    setLevel2Balance7(!isEmpty(props.levelThree) && !isUndefined(props.levelThree[0].LevelOne)&& props.levelThree[0].LevelOne.total_amount);

  };

  const handleChangeLevel3Seven = (selectedOption) => {
    setLevel3Id7(selectedOption.value);
    setLevel3Data7(selectedOption.label);
    props.getLevelFourName(selectedOption.value);
    setLevel3Balance7(!isEmpty(props.levelFour) && !isUndefined(props.levelFour[0].LevelOne)&& props.levelFour[0].LevelOne.total_amount);
  };

  const handleChangeLevel1Eight = (selectedOption) => {
    props.getLevelOneName(selectedOption.value);
    setLevel1Id8(selectedOption.value);
    setLevel1data8(selectedOption.label);
    setLevel1Balance8(!isEmpty(props.levelTow) && !isUndefined(props.levelTow[0].LevelOne)&& props.levelTow[0].LevelOne.total_amount);


  };

  const handleChangeLevel2Eight = (selectedOption) => {
    setLevel2Id8(selectedOption.value);
    setLevel2data8(selectedOption.label)
    props.getLevelTwoName(selectedOption.value);
    setLevel2Balance8(!isEmpty(props.levelThree) && !isUndefined(props.levelThree[0].LevelOne)&& props.levelThree[0].LevelOne.total_amount);

  };

  const handleChangeLevel3Eight = (selectedOption) => {
    setLevel3Id8(selectedOption.value);
    setLevel3Data8(selectedOption.label);
    props.getLevelFourName(selectedOption.value);
    setLevel3Balance8(!isEmpty(props.levelFour) && !isUndefined(props.levelFour[0].LevelOne)&& props.levelFour[0].LevelOne.total_amount);
  };

  const handleChangeLevel1Nine = (selectedOption) => {
    props.getLevelOneName(selectedOption.value);
    setLevel1Id9(selectedOption.value);
    setLevel1data9(selectedOption.label);
    setLevel1Balance9(!isEmpty(props.levelTow) && !isUndefined(props.levelTow[0].LevelOne)&& props.levelTow[0].LevelOne.total_amount);
  };

  const handleChangeLevel2Nine = (selectedOption) => {
    setLevel2Id9(selectedOption.value);
    setLevel2data9(selectedOption.label)
    props.getLevelTwoName(selectedOption.value);
    setLevel2Balance9(!isEmpty(props.levelThree) && !isUndefined(props.levelThree[0].LevelOne)&& props.levelThree[0].LevelOne.total_amount);

  };

  const handleChangeLevel3Nine = (selectedOption) => {
    setLevel3Id9(selectedOption.value);
    setLevel3Data9(selectedOption.label);
    props.getLevelFourName(selectedOption.value);
    setLevel3Balance9(!isEmpty(props.levelFour) && !isUndefined(props.levelFour[0].LevelOne)&& props.levelFour[0].LevelOne.total_amount);
  };

  const handleChangeLevel1Ten = (selectedOption) => {
    props.getLevelOneName(selectedOption.value);
    setLevel1Id10(selectedOption.value);
    setLevel1data10(selectedOption.label);
    setLevel1Balance10(!isEmpty(props.levelTow) && !isUndefined(props.levelTow[0].LevelOne)&& props.levelTow[0].LevelOne.total_amount);
  };

  const handleChangeLevel2Ten = (selectedOption) => {
    setLevel2Id10(selectedOption.value);
    setLevel2data10(selectedOption.label)
    props.getLevelTwoName(selectedOption.value);
    setLevel2Balance10(!isEmpty(props.levelThree) && !isUndefined(props.levelThree[0].LevelOne)&& props.levelThree[0].LevelOne.total_amount);

  };

  const handleChangeLevel3Ten = (selectedOption) => {
    setLevel3Id10(selectedOption.value);
    setLevel3Data10(selectedOption.label);
    props.getLevelFourName(selectedOption.value);
    setLevel3Balance10(!isEmpty(props.levelFour) && !isUndefined(props.levelFour[0].LevelOne)&& props.levelFour[0].LevelOne.total_amount);
  };
  let history = useHistory();
  const viewProfitLoss = () => {
    console.log("i am in");
    history.push("/user/Profit-Loss-detail", {
      name1: name1,
      name2: name2,
      level1Balance: isUndefined(level2Balance1) && isUndefined(level3Balance1) && isUndefined(level1Balance1) ? 0 : isUndefined(level2Balance1) && isUndefined(level3Balance1) ? Number(level1Balance1) : isUndefined(level3Balance1) ? Number(level2Balance1) : Number(level3Balance1),
      level2Balance: isUndefined(level2Balance2) && isUndefined(level3Balance2) && isUndefined(level1Balance2) ? 0 : isUndefined(level2Balance2) && isUndefined(level3Balance2) ? Number(level1Balance2) : isUndefined(level3Balance2) ? Number(level2Balance2) : Number(level3Balance2),
      level3Balance: isUndefined(level2Balance3) && isUndefined(level3Balance3) && isUndefined(level1Balance3) ? 0 : isUndefined(level2Balance3) && isUndefined(level3Balance3) ? Number(level1Balance3) : isUndefined(level3Balance3) ? Number(level2Balance3) : Number(level3Balance3),
      level4Balance: isUndefined(level2Balance4) && isUndefined(level3Balance4) && isUndefined(level1Balance4) ? 0 : isUndefined(level2Balance4) && isUndefined(level3Balance4) ? Number(level1Balance4) : isUndefined(level3Balance4) ? Number(level2Balance4) : Number(level3Balance4),
      level5Balance: isUndefined(level2Balance5) && isUndefined(level3Balance5) && isUndefined(level1Balance5) ? 0 : isUndefined(level2Balance5) && isUndefined(level3Balance5) ? Number(level1Balance5) : isUndefined(level3Balance5) ? Number(level2Balance5) : Number(level3Balance5),
      level6Balance: isUndefined(level2Balance6) && isUndefined(level3Balance6) && isUndefined(level1Balance6) ? 0 : isUndefined(level2Balance6) && isUndefined(level3Balance6) ? Number(level1Balance6) : isUndefined(level3Balance6) ? Number(level2Balance6) : Number(level3Balance6),
      level7Balance: isUndefined(level2Balance7) && isUndefined(level3Balance7) && isUndefined(level1Balance7) ? 0 : isUndefined(level2Balance7) && isUndefined(level3Balance7) ? Number(level1Balance7) : isUndefined(level3Balance7) ? Number(level2Balance7) : Number(level3Balance7),
      level8Balance: isUndefined(level2Balance8) && isUndefined(level3Balance8) && isUndefined(level1Balance8) ? 0 : isUndefined(level2Balance8) && isUndefined(level3Balance8) ? Number(level1Balance8) : isUndefined(level3Balance8) ? Number(level2Balance8) : Number(level3Balance8),
      level9Balance: isUndefined(level2Balance9) && isUndefined(level3Balance9) && isUndefined(level1Balance9) ? 0 : isUndefined(level2Balance9) && isUndefined(level3Balance9) ? Number(level1Balance9) : isUndefined(level3Balance9) ? Number(level2Balance9) : Number(level3Balance9),
      level10Balance: isUndefined(level2Balance10) && isUndefined(level3Balance10) && isUndefined(level1Balance10) ? 0 : isUndefined(level2Balance10) && isUndefined(level3Balance10) ? Number(level1Balance10) : isUndefined(level3Balance10) ? Number(level2Balance10) : Number(level3Balance10),
      
    });

  };
  useEffect(() => {
    props.getLevel1Data();
  }, []);

  return (
    <>
      {props.isFetchinglevel1List || props.isFetchingLevelTow || props.isFetchingLevelThree || props.isFetchingLevelFour ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />

          </Button>
        </div>
      ) : (
        <Container fluid>
          <div>
            <Row>
              <Col lg="3" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b>Add Name : </b>
                  </Form.Label>
                  <br />
                  <input type="text" class="form-control" id="name1" value={name1} onChange={(e) => setName1(e.target.value)} />
                </Form.Group>
              </Col>
              <Col lg="2" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b> Level 1: </b>

                  </Form.Label>

                  <Select
                    value={{ label: level1data1 }}
                    placeholder="Select Order..."
                    onChange={(e) => handleChangeLevel1One(e)}
                    options={getLevel1List}
                  />

                </Form.Group>
              </Col>
              <Col lg="2" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b> Level 2: </b>

                  </Form.Label>

                  <Select
                    value={{ label: level2Data1 }}
                    isDisabled={isEmpty(getLevelTwoList) || isEmpty(level1data1)}
                    placeholder="Select Level 2..."
                    onChange={(e) => handleChangeLevel2One(e)}
                    options={getLevelTwoList}
                  />

                </Form.Group>
              </Col>
              <Col lg="2" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b>Level 3: </b>
                  </Form.Label>
                  <Select
                    isDisabled={isEmpty(getlevel3val) || isEmpty(level1data1) || isEmpty(level2Data1)}
                    value={{ label: level3Data1 }}
                    placeholder="Select Level 3..."
                    onChange={(e) => handleChangeLevel3One(e)}
                    options={getlevel3val}
                  />
                </Form.Group>
              </Col>
              <Col lg="3" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b>Balance: </b>
                  </Form.Label>
                  <br />
                  <input type="text" class="form-control" value={isUndefined(level2Balance1) && isUndefined(level3Balance1) && isUndefined(level1Balance1) ? "" : isUndefined(level2Balance1) && isUndefined(level3Balance1) ? Number(level1Balance1) : isUndefined(level3Balance1) ? Number(level2Balance1) : Number(level3Balance1)} readOnly />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col lg="3" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b>Add Name : </b>
                  </Form.Label>
                  <br />
                  <input type="text" class="form-control" id="name2" value={name2} onChange={(e) => setName2(e.target.value)} />
                </Form.Group>
              </Col>
              <Col lg="2" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b> Level 1: </b>

                  </Form.Label>

                  <Select
                    value={{ label: level1data2 }}
                    placeholder="Select Order..."
                    onChange={(e) => handleChangeLevel1Two(e)}
                    options={getLevel1List}
                  />

                </Form.Group>
              </Col>
              <Col lg="2" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b> Level 2: </b>

                  </Form.Label>

                  <Select
                    value={{ label: level2Data2 }}
                    isDisabled={isEmpty(getLevelTwoList) || isEmpty(level1data2)}
                    placeholder="Select Level 2..."
                    onChange={(e) => handleChangeLevel2Two(e)}
                    options={getLevelTwoList}
                  />

                </Form.Group>
              </Col>
              <Col lg="2" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b>Level 3: </b>
                  </Form.Label>
                  <Select
                    isDisabled={isEmpty(getlevel3val) || isEmpty(level1data2) || isEmpty(level2Data2)}
                    value={{ label: level3Data2 }}
                    placeholder="Select Level 3..."
                    onChange={(e) => handleChangeLevel3Two(e)}
                    options={getlevel3val}
                  />
                </Form.Group>
              </Col>
              <Col lg="3" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b>Balance: </b>
                  </Form.Label>
                  <br />
                  <input type="text" class="form-control" value={isUndefined(level2Balance2) && isUndefined(level3Balance2) && isUndefined(level1Balance2) ? "" : isUndefined(level2Balance2) && isUndefined(level3Balance2) ? Number(level1Balance2) : isUndefined(level3Balance2) ? Number(level2Balance2) : Number(level3Balance2)} readOnly />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col lg="3" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b>Add Name : </b>
                  </Form.Label>
                  <br />
                  <input type="text" class="form-control" id="name3" value={name3} onChange={(e) => setName3(e.target.value)} />
                </Form.Group>
              </Col>
              <Col lg="2" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b> Level 1: </b>

                  </Form.Label>

                  <Select
                    value={{ label: level1data3 }}
                    placeholder="Select Order..."
                    onChange={(e) => handleChangeLevel1Three(e)}
                    options={getLevel1List}
                  />

                </Form.Group>
              </Col>
              <Col lg="2" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b> Level 2: </b>

                  </Form.Label>

                  <Select
                    value={{ label: level2Data3 }}
                    isDisabled={isEmpty(getLevelTwoList) || isEmpty(level1data3)}
                    placeholder="Select Level 2..."
                    onChange={(e) => handleChangeLevel2Three(e)}
                    options={getLevelTwoList}
                  />

                </Form.Group>
              </Col>
              <Col lg="2" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b>Level 3: </b>
                  </Form.Label>
                  <Select
                    isDisabled={isEmpty(getlevel3val) || isEmpty(level1data3) || isEmpty(level2Data3)}
                    value={{ label: level3Data3 }}
                    placeholder="Select Level 3..."
                    onChange={(e) => handleChangeLevel3Three(e)}
                    options={getlevel3val}
                  />
                </Form.Group>
              </Col>
              <Col lg="3" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b>Balance: </b>
                  </Form.Label>
                  <br />
                  <input type="text" class="form-control" value={isUndefined(level2Balance3) && isUndefined(level3Balance3) && isUndefined(level1Balance3) ? "" : isUndefined(level2Balance3) && isUndefined(level3Balance3) ? Number(level1Balance3) : isUndefined(level3Balance3) ? Number(level2Balance3) : Number(level3Balance3)} readOnly />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col lg="3" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b>Add Name : </b>
                  </Form.Label>
                  <br />
                  <input type="text" class="form-control" id="name4" value={name4} onChange={(e) => setName4(e.target.value)} />
                </Form.Group>
              </Col>
              <Col lg="2" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b> Level 1: </b>

                  </Form.Label>

                  <Select
                    value={{ label: level1data4 }}
                    placeholder="Select Order..."
                    onChange={(e) => handleChangeLevel1Four(e)}
                    options={getLevel1List}
                  />

                </Form.Group>
              </Col>
              <Col lg="2" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b> Level 2: </b>

                  </Form.Label>

                  <Select
                    value={{ label: level2Data4 }}
                    isDisabled={isEmpty(getLevelTwoList) || isEmpty(level1data4)}
                    placeholder="Select Level 2..."
                    onChange={(e) => handleChangeLevel2Four(e)}
                    options={getLevelTwoList}
                  />

                </Form.Group>
              </Col>
              <Col lg="2" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b>Level 3: </b>
                  </Form.Label>
                  <Select
                    isDisabled={isEmpty(getlevel3val) || isEmpty(level1data4) || isEmpty(level2Data4)}
                    value={{ label: level3Data4 }}
                    placeholder="Select Level 3..."
                    onChange={(e) => handleChangeLevel3Four(e)}
                    options={getlevel3val}
                  />
                </Form.Group>
              </Col>
              <Col lg="3" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b>Balance: </b>
                  </Form.Label>
                  <br />
                  <input type="text" class="form-control" value={isUndefined(level2Balance4) && isUndefined(level3Balance4) && isUndefined(level1Balance4) ? "" : isUndefined(level2Balance4) && isUndefined(level3Balance4) ? Number(level1Balance4) : isUndefined(level3Balance4) ? Number(level2Balance4) : Number(level3Balance4)} readOnly />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col lg="3" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b>Add Name : </b>
                  </Form.Label>
                  <br />
                  <input type="text" class="form-control" id="name5" value={name5} onChange={(e) => setName5(e.target.value)} />
                </Form.Group>
              </Col>
              <Col lg="2" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b> Level 1: </b>

                  </Form.Label>

                  <Select
                    value={{ label: level1data5 }}
                    placeholder="Select Order..."
                    onChange={(e) => handleChangeLevel1Five(e)}
                    options={getLevel1List}
                  />

                </Form.Group>
              </Col>
              <Col lg="2" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b> Level 2: </b>

                  </Form.Label>

                  <Select
                    value={{ label: level2Data5 }}
                    isDisabled={isEmpty(getLevelTwoList) || isEmpty(level1data5)}
                    placeholder="Select Level 2..."
                    onChange={(e) => handleChangeLevel2Five(e)}
                    options={getLevelTwoList}
                  />

                </Form.Group>
              </Col>
              <Col lg="2" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b>Level 3: </b>
                  </Form.Label>
                  <Select
                    isDisabled={isEmpty(getlevel3val) || isEmpty(level1data5) || isEmpty(level2Data5)}
                    value={{ label: level3Data5 }}
                    placeholder="Select Level 3..."
                    onChange={(e) => handleChangeLevel3Five(e)}
                    options={getlevel3val}
                  />
                </Form.Group>
              </Col>
              <Col lg="3" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b>Balance: </b>
                  </Form.Label>
                  <br />
                  <input type="text" class="form-control" value={isUndefined(level2Balance5) && isUndefined(level3Balance5) && isUndefined(level1Balance5) ? "" : isUndefined(level2Balance5) && isUndefined(level3Balance5) ? Number(level1Balance5) : isUndefined(level3Balance5) ? Number(level2Balance5) : Number(level3Balance5)} readOnly />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col lg="3" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b>Add Name : </b>
                  </Form.Label>
                  <br />
                  <input type="text" class="form-control" id="name6" value={name6} onChange={(e) => setName6(e.target.value)} />
                </Form.Group>
              </Col>
              <Col lg="2" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b> Level 1: </b>

                  </Form.Label>

                  <Select
                    value={{ label: level1data6 }}
                    placeholder="Select Order..."
                    onChange={(e) => handleChangeLevel1Six(e)}
                    options={getLevel1List}
                  />

                </Form.Group>
              </Col>
              <Col lg="2" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b> Level 2: </b>

                  </Form.Label>

                  <Select
                    value={{ label: level2Data6 }}
                    isDisabled={isEmpty(getLevelTwoList) || isEmpty(level1data6)}
                    placeholder="Select Level 2..."
                    onChange={(e) => handleChangeLevel2Six(e)}
                    options={getLevelTwoList}
                  />

                </Form.Group>
              </Col>
              <Col lg="2" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b>Level 3: </b>
                  </Form.Label>
                  <Select
                    isDisabled={isEmpty(getlevel3val) || isEmpty(level1data6) || isEmpty(level2Data6)}
                    value={{ label: level3Data6 }}
                    placeholder="Select Level 3..."
                    onChange={(e) => handleChangeLevel3Six(e)}
                    options={getlevel3val}
                  />
                </Form.Group>
              </Col>
              <Col lg="3" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b>Balance: </b>
                  </Form.Label>
                  <br />
                  <input type="text" class="form-control" value={isUndefined(level2Balance6) && isUndefined(level3Balance6) && isUndefined(level1Balance6) ? "" : isUndefined(level2Balance6) && isUndefined(level3Balance6) ? Number(level1Balance6) : isUndefined(level3Balance6) ? Number(level2Balance6) : Number(level3Balance6)} readOnly />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col lg="3" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b>Add Name : </b>
                  </Form.Label>
                  <br />
                  <input type="text" class="form-control" id="name7" value={name7} onChange={(e) => setName7(e.target.value)} />
                </Form.Group>
              </Col>
              <Col lg="2" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b> Level 1: </b>

                  </Form.Label>

                  <Select
                    value={{ label: level1data7 }}
                    placeholder="Select Order..."
                    onChange={(e) => handleChangeLevel1Seven(e)}
                    options={getLevel1List}
                  />

                </Form.Group>
              </Col>
              <Col lg="2" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b> Level 2: </b>

                  </Form.Label>

                  <Select
                    value={{ label: level2Data7 }}
                    isDisabled={isEmpty(getLevelTwoList) || isEmpty(level1data7)}
                    placeholder="Select Level 2..."
                    onChange={(e) => handleChangeLevel2Seven(e)}
                    options={getLevelTwoList}
                  />

                </Form.Group>
              </Col>
              <Col lg="2" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b>Level 3: </b>
                  </Form.Label>
                  <Select
                    isDisabled={isEmpty(getlevel3val) || isEmpty(level1data7) || isEmpty(level2Data7)}
                    value={{ label: level3Data7 }}
                    placeholder="Select Level 3..."
                    onChange={(e) => handleChangeLevel3Seven(e)}
                    options={getlevel3val}
                  />
                </Form.Group>
              </Col>
              <Col lg="3" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b>Balance: </b>
                  </Form.Label>
                  <br />
                  <input type="text" class="form-control" value={isUndefined(level2Balance7) && isUndefined(level3Balance7) && isUndefined(level1Balance7) ? "" : isUndefined(level2Balance7) && isUndefined(level3Balance7) ? Number(level1Balance7) : isUndefined(level3Balance7) ? Number(level2Balance7) : Number(level3Balance7)} readOnly />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col lg="3" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b>Add Name : </b>
                  </Form.Label>
                  <br />
                  <input type="text" class="form-control" id="name8" value={name8} onChange={(e) => setName8(e.target.value)} />
                </Form.Group>
              </Col>
              <Col lg="2" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b> Level 1: </b>

                  </Form.Label>

                  <Select
                    value={{ label: level1data8 }}
                    placeholder="Select Order..."
                    onChange={(e) => handleChangeLevel1Eight(e)}
                    options={getLevel1List}
                  />

                </Form.Group>
              </Col>
              <Col lg="2" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b> Level 2: </b>

                  </Form.Label>

                  <Select
                    value={{ label: level2Data8 }}
                    isDisabled={isEmpty(getLevelTwoList) || isEmpty(level1data8)}
                    placeholder="Select Level 2..."
                    onChange={(e) => handleChangeLevel2Eight(e)}
                    options={getLevelTwoList}
                  />

                </Form.Group>
              </Col>
              <Col lg="2" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b>Level 3: </b>
                  </Form.Label>
                  <Select
                    isDisabled={isEmpty(getlevel3val) || isEmpty(level1data8) || isEmpty(level2Data8)}
                    value={{ label: level3Data8 }}
                    placeholder="Select Level 3..."
                    onChange={(e) => handleChangeLevel3Eight(e)}
                    options={getlevel3val}
                  />
                </Form.Group>
              </Col>
              <Col lg="3" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b>Balance: </b>
                  </Form.Label>
                  <br />
                  <input type="text" class="form-control" value={isUndefined(level2Balance8) && isUndefined(level3Balance8) && isUndefined(level1Balance8) ? "" : isUndefined(level2Balance8) && isUndefined(level3Balance8) ? Number(level1Balance8) : isUndefined(level3Balance8) ? Number(level2Balance8) : Number(level3Balance8)} readOnly />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col lg="3" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b>Add Name : </b>
                  </Form.Label>
                  <br />
                  <input type="text" class="form-control" id="name9" value={name9} onChange={(e) => setName9(e.target.value)} />
                </Form.Group>
              </Col>
              <Col lg="2" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b> Level 1: </b>

                  </Form.Label>

                  <Select
                    value={{ label: level1data9 }}
                    placeholder="Select Order..."
                    onChange={(e) => handleChangeLevel1Nine(e)}
                    options={getLevel1List}
                  />

                </Form.Group>
              </Col>
              <Col lg="2" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b> Level 2: </b>

                  </Form.Label>

                  <Select
                    value={{ label: level2Data9 }}
                    isDisabled={isEmpty(getLevelTwoList) || isEmpty(level1data9)}
                    placeholder="Select Level 2..."
                    onChange={(e) => handleChangeLevel2Nine(e)}
                    options={getLevelTwoList}
                  />

                </Form.Group>
              </Col>
              <Col lg="2" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b>Level 3: </b>
                  </Form.Label>
                  <Select
                    isDisabled={isEmpty(getlevel3val) || isEmpty(level1data9) || isEmpty(level2Data9)}
                    value={{ label: level3Data9 }}
                    placeholder="Select Level 3..."
                    onChange={(e) => handleChangeLevel3Nine(e)}
                    options={getlevel3val}
                  />
                </Form.Group>
              </Col>
              <Col lg="3" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b>Balance: </b>
                  </Form.Label>
                  <br />
                  <input type="text" class="form-control" value={isUndefined(level2Balance9) && isUndefined(level3Balance9) && isUndefined(level1Balance9) ? "" : isUndefined(level2Balance9) && isUndefined(level3Balance9) ? Number(level1Balance9) : isUndefined(level3Balance9) ? Number(level2Balance9) : Number(level3Balance9)} readOnly />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col lg="3" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b>Add Name : </b>
                  </Form.Label>
                  <br />
                  <input type="text" class="form-control" id="name10" value={name10} onChange={(e) => setName10(e.target.value)} />
                </Form.Group>
              </Col>
              <Col lg="2" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b> Level 1: </b>

                  </Form.Label>

                  <Select
                    value={{ label: level1data10 }}
                    placeholder="Select Order..."
                    onChange={(e) => handleChangeLevel1Ten(e)}
                    options={getLevel1List}
                  />

                </Form.Group>
              </Col>
              <Col lg="2" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b> Level 2: </b>

                  </Form.Label>

                  <Select
                    value={{ label: level2Data10 }}
                    isDisabled={isEmpty(getLevelTwoList) || isEmpty(level1data10)}
                    placeholder="Select Level 2..."
                    onChange={(e) => handleChangeLevel2Ten(e)}
                    options={getLevelTwoList}
                  />

                </Form.Group>
              </Col>
              <Col lg="2" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b>Level 3: </b>
                  </Form.Label>
                  <Select
                    isDisabled={isEmpty(getlevel3val) || isEmpty(level1data10) || isEmpty(level2Data10)}
                    value={{ label: level3Data10 }}
                    placeholder="Select Level 3..."
                    onChange={(e) => handleChangeLevel3Ten(e)}
                    options={getlevel3val}
                  />
                </Form.Group>
              </Col>
              <Col lg="3" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b>Balance: </b>
                  </Form.Label>
                  <br />
                  <input type="text" class="form-control" value={isUndefined(level2Balance10) && isUndefined(level3Balance10) && isUndefined(level1Balance10) ? "" : isUndefined(level2Balance10) && isUndefined(level3Balance10) ? Number(level1Balance10) : isUndefined(level3Balance10) ? Number(level2Balance10) : Number(level3Balance10)} readOnly />
                </Form.Group>
              </Col>
            </Row>


            <div className="sendDiv">
             {/* <Link to = {{pathname: "/user/Profit-Loss-detail", name1: name1}}> <Button className="sendButton">Search</Button></Link> */}
             <Button className="sendButton" onClick = {(e) => viewProfitLoss(e)}>Search</Button>
           
            </div>
          </div>
        </Container>
      )}

    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getLevel1Data: () => dispatch(getLevel1Data()),
    getLevelOneName: (data) => dispatch(getLevelOneName(data)),
    getLevelTwoName: (data) => dispatch(getLevelTwoName(data)),
    getLevelFourName: (data) => dispatch(getLevelFourName(data))
  };
};
const mapStateToProps = (state, ownProps) => ({
  level1List: state.accountsLevelReducer.level1List,
  isFetchinglevel1List: state.accountsLevelReducer.isFetchinglevel1List,
  levelTow: state.accountsLevelReducer.levelTow,
  isFetchingLevelTow: state.accountsLevelReducer.isFetchingLevelTow,
  levelThree: state.accountsLevelReducer.levelThree,
  isFetchingLevelThree: state.accountsLevelReducer.isFetchingLevelThree,
  levelFour: state.accountsLevelReducer.levelFour,
  isFetchingLevelFour: state.accountsLevelReducer.isFetchingLevelFour
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BalanceSheetMain);