import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import MaterialTable from 'material-table';
import moment from 'moment'
import { Redirect } from 'react-router-dom';
import Swal from 'sweetalert';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { isEmpty, filter, find } from 'lodash'
import { Row, Col, Form, Container, Button, Alert, Modal } from "react-bootstrap";
import Spinner from 'react-bootstrap/Spinner';
import Select from 'react-select';
import { changePassword, getUsers } from './users.actions';

const ChangePassword = (props) => {

    const userData = props.usersList;
    const [users, setUsers] = useState("");
    const [usersLabel, setUsersLabel] = useState("");
    const [password, setPassword] = useState("");
    const [visibleAlert, setAlertVisible] = useState(false);
    const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
    const handleChangeUsers = (selectedOption) => {
        setUsers(selectedOption.value);
        setUsersLabel(selectedOption.label)
    };
    const handleVisible = () => {
        setAlertVisible(true)
        setTimeout(() => {
            setAlertVisible(false)
        }, 9000);
        Swal({
            title: "Success",
            text: "Password Update successfully...",
            icon: "success",
            button: "Ok",
        });
    }
    const handleDangerVisible = () => {
        setDangerVisibleAlert(true)
        setTimeout(() => {
            setDangerVisibleAlert(false)
        }, 9000);
        Swal({
            title: "Error",
            text: "Something went wrong...",
            icon: "error",
            button: "Ok",
        });
    }
    useEffect(() => {
        props.getUsers();
    }, []);

    const sendData = () => {
        props.changePassword(users,{
            password: password
        }, handleVisible, handleDangerVisible);
        setPassword("")
        setUsersLabel("")
        setUsers("")
    };

    const getUsersData = props.usersList.map((x) => { let data = { value: x.id, label: x.username }; return data });

    return (

        <>
            {props.isFetchingUsersList  ? (
                <div className="loader-div">
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />
                        {props.isSavingLevel2 ? "Saving" : "Loading"}
                    </Button>
                </div>
            ) : (
                <Container fluid>
                    <div className="main">

                        <Row>
                            <Col xl="6" lg="6" md="6" sm="12">
                                <Form.Group>
                                    <Form.Label>
                                        <b> Select User</b>
                                    </Form.Label>
                                    <Select
                                        placeholder="Select User..."
                                        value={{label:usersLabel}}
                                        onChange={(e) => handleChangeUsers(e)}
                                        options={getUsersData}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xl="6" lg="6" md="6" sm="12">
                                <Form.Group>
                                    <Form.Label>
                                        <b>Enter New Password</b>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    ></Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <div className="sendDiv">
                            {password == ""||users=="" ? (
                                <Button
                                    disabled
                                    style={{ backgroundColor: "black" }}
                                    className="sendButton"
                                >
                                    Update Password
                                </Button>
                            ) : (
                                <Button className="sendButton" onClick={() => sendData()}>
                                    Update Password
                                </Button>
                            )}
                        </div>
                    </div>

                </Container>

            )}
        </>


    );
}

const mapDispatchToProps = dispatch => {
    return {
        getUsers: () => dispatch(getUsers()),
        changePassword: (id,data,handleVisible,handleDangerVisible) => dispatch(changePassword(id,data,handleVisible,handleDangerVisible)),
    }
}

const mapStateToProps = (state, ownProps) => ({
    usersList: state.usersReducer.usersList,
    isFetchingUsersList:state.usersReducer.isFetchingUsersList,
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);