import React, { useState } from "react";
import { Container, Form, Row, Col, Button, FormGroup } from "react-bootstrap";
import {
  addVisitorMarquee,
  deleteVisitor,
  getCustomerMarquee,
  getVisitorMarquee,
  UpdateVisitor,
} from "./MarqueeDetails.actions";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import { useEffect } from "react";
import Swal from "sweetalert";
import { isEmpty, find } from "lodash";
import Select from "react-select";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { IconButton } from "@material-ui/core";
import jsPDF from "jspdf";
import logo from "../../../../../Images/logo.png";
const VisitorMarqueeList = (props) => {
  const VisitorData = isEmpty(props.visitorMarqueeList)
    ? []
    : props.visitorMarqueeList.reverse();
  useEffect(() => {
    props.getVisitorMarquee(1);
    // props.getMasterControl(1)
  }, []);
  return (
    <>
      <Container fluid>
        <MaterialTable
          style={{ zIndex: 0 }}
          title="Visitor"
          columns={[
            { title: "Visitor Name", field: "customer_name" },
            { title: "Visitor Phone", field: "customer_phone" },
            { title: "Visitor Purpose", field: "visitors_purpose" },
          ]}
          data={VisitorData}
          options={{
            actionsColumnIndex: -1,
            filtering: true,
            exportButton: true,
            exportAllData: true,
            exportPdf: (columns, data) => {
              const doc = new jsPDF();
              //render:rowData=>rowData.hall_name
              const columnTitles = [
                {
                  title: "Visitor Name",
                  field: "customer_name",
                  render: (rowData) => rowData.customer_name,
                },
                {
                  title: "Visitor Phone",
                  field: "customer_phone",
                  render: (rowData) => rowData.customer_phone,
                },
                {
                  title: "Visitor Purpose",
                  field: "visitors_purpose",
                  render: (rowData) => rowData.visitors_purpose,
                },
              ].map((columnDef) => columnDef.title);
              const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
              const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
              const companyNtn = `${process.env.REACT_APP_COMPANY_NTN}`;
              const pdfData = data.map((rowData) => [
                rowData.customer_name,
                rowData.customer_phone,
                rowData.visitors_purpose,
              ]);
              let content = {
                startY: 50,
                startX: 5,
                head: [columnTitles],
                body: pdfData,
              };
              doc.addImage(logo, "PNG", 10, 1, 50, 50);
              doc.setFontSize(20);
              doc.setFont(undefined, "bold");
              doc.text(companyName, 50, 22, 0, 0);
              doc.setFontSize(13);
              doc.setFont("TimesNewRoman", "Normal");
              doc.text(companyAddress, 50, 29, 0, 0);
              doc.text(companyNtn, 50, 36, 0, 0);
              doc.autoTable(content);
              doc.save(`Visitor Table.pdf`);
            },
            maxBodyHeight: "600px",
            headerStyle: {
              position: "sticky",
              top: 0,
              color: "#00BBBB",
              fontWeight: "550",
              onRowAdd: "none",
            },
          }}
          editable={{
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve) => {
                setTimeout(() => {
                  resolve();
                  {
                    // console.log('newData', newData),
                    props.UpdateVisitor(newData, oldData);
                  }
                }, 600);
              }),
            onRowDelete: (oldData) =>
              new Promise((resolve) => {
                setTimeout(() => {
                  resolve();
                  props.deleteVisitor(oldData.visitor_customer_id);
                  console.log(oldData, "old");
                }, 600);
              }),
          }}
          /* detailPanel={(rowData) => {
                                return (
                                    <MaterialTable
                                    title="Menu Dishes"
                                    columns={stateDishes.columns}
                                    data={rowData.menu_data}
                                    options={{
                                        actionsColumnIndex: -1,
                                        filtering: true,
                                        exportButton: true,
                                        exportAllData: true,
                                        headerStyle: {
                                            position: 'sticky', top: 0,
                                            color: '#00BBBB',
                                            fontWeight: '550',
                                            onRowAdd: 'none',
                                        },
        
                                    }}
                                    editable={{
                                        onRowUpdate: (newData, oldData) =>
                                            new Promise((resolve) => {
                                                setTimeout(() => {
                                                    resolve();
                                                    {
                                                        // console.log('newData', newData),
                                                        // props.UpdateHRMenu(newData, oldData)
                                                    }
                                                }, 600);
                                            }),
                                        onRowDelete: (oldData) =>
                                            new Promise((resolve) => {
                                                setTimeout(() => {
                                                    resolve();
                                                    props.deleteMenu(oldData.menu_id);
                                                    console.log(oldData, 'old')
                                                }, 600);
                                            }),
                                    }}
                                    

                                    />
                                )}}*/
        />
      </Container>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getVisitorMarquee: (id) => dispatch(getVisitorMarquee(id)),
    deleteVisitor: (menu_id) => dispatch(deleteVisitor(menu_id)),
    UpdateVisitor: (newData, oldData) =>
      dispatch(UpdateVisitor(newData, oldData)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  addCustomer: state.MarqueeReducer.addCustomer,
  isAddingCustomerData: state.MarqueeReducer.isAddingCustomerData,
  isFetchingCustomerDataMarquee:
    state.MarqueeReducer.isFetchingCustomerDataMarquee,
  customerMarqueeList: state.MarqueeReducer.customerMarqueeList,
  visitorMarqueeList: state.MarqueeReducer.visitorMarqueeList,
});

export default connect(mapStateToProps, mapDispatchToProps)(VisitorMarqueeList);
