import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, FormGroup, Alert, Image } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty, isNull } from "lodash";
import Spinner from 'react-bootstrap/Spinner';
import "./hr-styles.css";
import Select from 'react-select';
import ImageUploader from 'react-images-upload';
import { addSalary, getEmployeeData, getShiftControl, getEmployeeDataWithStatus } from "./HRDetails.actions";

import Swal from 'sweetalert';
import { appendNonSignificantZeros } from "../../../../../global/globalFunctions";

const AddSalary = (props) => {

    const [employeeId, setEmployeeId] = useState('');
    const [machineId, setMachineId] = useState('');
    const [basicSalary, setBasicSalary] = useState(0);
    const [medicalAllowance, setMedicalAllowance] = useState(0);
    const [petrolAllowance, setPetrolAllowance] = useState(0);
    const [specialAllowance, setSpecialAllowance] = useState(0);
    const [otherAllowance, setOtherAllowance] = useState(0);
    const [accomodationAllowance, setAccomodationAllowance] = useState(0);
    const [bonus, setBonus] = useState('');

    const [overTime, setOverTime] = useState('');
    const [overTimeRate, setOverTimeRate] = useState('');
    const [salaryTransfer, setSalaryTranfer] = useState('');
    const [bankAccount, setBankAccount] = useState();

    const [lateTimeDeduct_1, setLateTimeDeduct_1] = useState();
    const [lateTimeDeduct_2, setLateTimeDeduct_2] = useState();
    const [exemptionAttendance, setExemptionAttendance] = useState();
    const [bankName, setBankName] = useState();
    const [leaveAllowed, setLeaveAllowed] = useState();
    const [activeStatus, setActiveStatus] = useState();
    // const [temporaryBlockSalary, setTemporaryBlockSalary] = useState();
    // const [reasonForBlockSalary, setReasonForBlockSalary] = useState();
    const [salaryCreationBasis, setSalaryCreationBasis] = useState();

    const [salaryCreation, setSalaryCreation] = useState();
    const [shiftSelection, setShiftSelection] = useState(null);



    const [visibleAlert, setAlertVisible] = useState(false);
    const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);


    console.log(('basicSalary===0', (basicSalary === 0)))
    console.log(('basicSalary', (basicSalary)))

    console.log('activeStatus !== true', (activeStatus !== true));
    console.log('activeStatus !== false', activeStatus !== false);

    const getOverTimeList = [
        { value: 1, label: "Allowed" },
        { value: 0, label: "Not Allowed" },
    ];

    const getActiveStatusList = [
        { value: true, label: "Active" },
        { value: false, label: "Non Active" },
    ];
    const getLateTimeList = [
        { value: 1, label: "Deduct" },
        { value: 0, label: "Not Deduct" },
    ];

    const getSalaryTransferList = [
        { value: "Cash", label: "Cash" },
        { value: "Bank", label: "Bank" },
    ];
    const getBonusList = [
        { value: 1, label: "Yes" },
        { value: 0, label: "No" },
    ];
    const getExemptionAttendanceList = [
        { value: 1, label: "Allowed" },
        { value: 0, label: "Not Allowed" },
    ];
    const getSalaryCreationList = [
        { value: 1, label: "Allowed" },
        { value: 0, label: "Not Allowed" },
    ];
    const getDeductionList = [
        { value: 1, label: "Deduct" },
        { value: 0, label: "Do not Deduct" }
    ];
    const getLeaveAllowedList = [
        { value: 1, label: "Yes" },
        { value: 0, label: "No" },
    ];
    const getSalaryCreationBasisList = [
        { value: "Shift", label: "Shift" },
        { value: "Master", label: "Master" },
    ];
    const handleShiftSelection = (selectedOption) => {
        setShiftSelection(selectedOption.value);
    };
    const handleSalaryCreationBasis = (selectedOption) => {
        setSalaryCreationBasis(selectedOption.value);
    };
    const handleLeaveAllowed = (selectedOption) => {
        setLeaveAllowed(selectedOption.value);
    };
    const handleLateDeduction = (selectedOption) => {
        setLateTimeDeduct_1(selectedOption.value);
        selectedOption.value === 0 && setLateTimeDeduct_2(0);

    };
    const handleSalaryCreation = (selectedOption) => {
        setSalaryCreation(selectedOption.value);
    };
    const handleOverTime = (selectedOption) => {
        setOverTime(selectedOption.value);
        selectedOption.value === 0 && setOverTimeRate(0);
    };
    const handleChangeActiveStatus = (selectedOption) => {
        setActiveStatus(selectedOption.value);
    };
    // const handleChangeLateTime = (selectedOption) => {
    //     setLateTime(selectedOption.value);
    // };

    const handleChangeSalaryTransfer = (selectedOption) => {
        setSalaryTranfer(selectedOption.value);
        selectedOption.value === 'Cash' && setBankName();
        selectedOption.value === 'Cash' && setBankAccount();


    };
    const handleChangeBonus = (selectedOption) => {
        setBonus(selectedOption.value);
    };
    const handleChangeexemptionAttendance = (selectedOption) => {
        setExemptionAttendance(selectedOption.value);
    };
    const handleChangeEmployee = (selectedOption) => {
        setEmployeeId(selectedOption.value);
    };

    // const getEmployeeList = !isEmpty(props.employeeList) && props.employeeList.map((x) => { let data = { value: x.employeeId, label: `${appendNonSignificantZeros(x.employeeId)}-${x.name}` }; return data });

    const getEmployeeList = !isEmpty(props.activeEmployeeList) && props.activeEmployeeList.map((x) => { let data = { value: x.employeeId, label: `${appendNonSignificantZeros(x.employeeId)}-${x.name}` }; return data })

    const getSelectableShifts = !isEmpty(props.shiftControl) && props.shiftControl.map((x) => { let data = { value: x.shift_control_id, label: x.shift_name }; return data; });
    console.log('props.shiftControl', props.shiftControl)
    console.log('getSelectableShifts', getSelectableShifts)

    const clearStates = () => {

        setEmployeeId('');
        setMachineId('');
        setBasicSalary(0);
        setMedicalAllowance(0);
        setPetrolAllowance(0);
        setSpecialAllowance(0);
        setOtherAllowance(0);
        setAccomodationAllowance(0);
        setBonus('');
        setOverTime('');
        setOverTimeRate('');
        setSalaryTranfer('');
        setBankAccount();
        setLateTimeDeduct_1();
        setLateTimeDeduct_2();
        setExemptionAttendance();
        setBankName();
        setLeaveAllowed();
        setActiveStatus();
        setSalaryCreationBasis();
        setSalaryCreation();
        setShiftSelection(null);

    }

    const handleVisible = () => {
        setAlertVisible(true)
        clearStates();
        setTimeout(() => {
            setAlertVisible(false)
        }, 9000);
        Swal({
            title: "Success",
            text: "Added successfully...",
            icon: "success",
            button: "Ok",
        });
    }

    const handleDangerVisible = () => {
        setDangerVisibleAlert(true)
        setTimeout(() => {
            setDangerVisibleAlert(false)
        }, 9000);
        Swal({
            title: "Error",
            text: "Something went wrong...",
            icon: "error",
            button: "Ok",
        });
    }

    const saveSalary = () => {
        props.addSalary({
            employee_id: Number(employeeId),
            machine_id: Number(machineId),
            basic_salary: Number(basicSalary),
            medicial_allowance: Number(medicalAllowance),
            accomodation_allowance: Number(accomodationAllowance),
            petrol_allowance: Number(petrolAllowance),
            // special_allowance: Number(specialAllowance),     //ToBeRemoved
            other_allowance: Number(otherAllowance),
            special_allowance: Number(specialAllowance),
            total_allowances: (Number(medicalAllowance) + Number(petrolAllowance) + Number(specialAllowance) + Number(accomodationAllowance) + Number(otherAllowance)),
            bonus: bonus,
            total_salary: (Number(medicalAllowance) + Number(petrolAllowance) + Number(specialAllowance) + Number(accomodationAllowance) + Number(otherAllowance) + Number(basicSalary)),
            over_time: overTime,
            over_time_rate: overTimeRate,

            late_time_deduct_1: lateTimeDeduct_1,       //TobeAdded
            late_time_deduct_percentage: lateTimeDeduct_2,
            exemption_attendance: exemptionAttendance,
            bank_name: bankName,
            leave_allowed: leaveAllowed,
            // active_status: activeStatus,

            salary_transfer: salaryTransfer,
            bank_account: bankAccount,

            salary_creation_based: salaryCreationBasis,
            salary_creation: salaryCreation,
            shift_id: shiftSelection,

        }, handleVisible, handleDangerVisible);
    };

    useEffect(() => {
        props.getEmployeeData();
        props.getEmployeeDataWithStatus(1);
        props.getShiftControl();

    }, []);
    return (
        <>
            <Container fluid>
                {((props.isFetchingEmployeeData)) ?
                    <div className="loader-div">
                        <Button variant="info" disabled>
                            <Spinner
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                variant="info"
                            />
                            {props.isAddingSalary ? 'Adding Salary...' : 'Fetching Employee...'}

                        </Button>
                    </div> :
                    <Container fluid>
                        <div className="mainHr">
                            <Row>
                                <Col lg="12" md="12" xs="12">
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>Select Employee:</b></Form.Label>
                                        <Select
                                            // isDisabled={isEmpty(getDepartmentNameList)}
                                            placeholder="Select Employee..."
                                            onChange={handleChangeEmployee}
                                            options={getEmployeeList}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="4" md="4" xs="12">
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>Machine ID:</b></Form.Label>
                                        <Form.Control type="number" id="machineId" placeholder="Machine Id...." onBlur={(e) => setMachineId(e.target.value)} />
                                    </Form.Group>
                                </Col>
                                <Col lg="4" md="4" xs="12">
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>Basic Salary:</b></Form.Label>
                                        <Form.Control type="number" id="basicSalary" placeholder="Basic Salary...." onBlur={(e) => setBasicSalary(e.target.value)} />
                                    </Form.Group>
                                </Col>
                                <Col lg="4" md="4" xs="12">
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>Medical Allowance:</b></Form.Label>
                                        <Form.Control type="number" id="medicalAllowance" placeholder="Medical Allowance...." onBlur={(e) => setMedicalAllowance(e.target.value)} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="4" md="4" xs="12">
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>Petrol Allowance:</b></Form.Label>
                                        <Form.Control type="number" id="petrolAllowance" placeholder="Petrol Allowance...." onBlur={(e) => setPetrolAllowance(e.target.value)} />
                                    </Form.Group>
                                </Col>
                                <Col lg="4" md="4" xs="12">
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>Other Allowance:</b></Form.Label>
                                        <Form.Control type="number" id="Other Allowance" placeholder="Other Allowance...." onBlur={(e) => setOtherAllowance(e.target.value)} />
                                    </Form.Group>
                                </Col>

                                <Col lg="4" md="4" xs="12">
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>Accomodation Allowance:</b></Form.Label>
                                        <Form.Control type="number" id="accomodationAllowance" placeholder="Accomodation Allowance...." onBlur={(e) => setAccomodationAllowance(e.target.value)} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="4" md="4" xs="12">
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>Special Allowance:</b></Form.Label>
                                        <Form.Control type="number" id="special Allowance" placeholder="Special Allowance...." onBlur={(e) => setSpecialAllowance(e.target.value)} />
                                    </Form.Group>
                                </Col>
                                <Col lg="4" md="4" xs="12">
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>Total Allowance:</b></Form.Label>
                                        <Form.Control id="totalAllowance" placeholder="Total Allowance...." value={Number(medicalAllowance) + Number(petrolAllowance) + Number(specialAllowance) + Number(accomodationAllowance) + Number(otherAllowance)} disabled />
                                    </Form.Group>
                                </Col>

                                <Col lg="4" md="4" xs="12">
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>Bonus:</b></Form.Label>
                                        {/* <Form.Control id="bonus" placeholder="Bonus..." defaultValue={bonus} onBlur={(e) => setBonus(e.target.value)} /> */}
                                        <Select
                                            // isDisabled={isEmpty(getDepartmentNameList)}
                                            placeholder="Select Bonus..."
                                            onChange={handleChangeBonus}
                                            options={getBonusList}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="4" md="4" xs="12">
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>Total Salary:</b></Form.Label>
                                        <Form.Control id="totalSalary" placeholder="Total Salary..." value={Number(medicalAllowance) + Number(petrolAllowance) + Number(specialAllowance) + Number(accomodationAllowance) + Number(otherAllowance) + Number(basicSalary)} disabled />
                                    </Form.Group>
                                </Col>

                                <Col lg="4" md="4" xs="12">
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>Over Time:</b></Form.Label>
                                        <Select
                                            // isDisabled={isEmpty(getDepartmentNameList)}
                                            placeholder="Select Over Time..."
                                            onChange={handleOverTime}
                                            options={getOverTimeList}
                                        />
                                    </Form.Group>
                                </Col>

                                <Col lg="4" md="4" xs="12">
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>Over Time Rate:</b></Form.Label>
                                        <Form.Control type="number" id="overTimeRate" placeholder="Over Time Rate (%)...." onBlur={(e) => setOverTimeRate(e.target.value)} disabled={overTime === 0} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="4" md="4" xs="12">
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>Late Time Deduction:</b></Form.Label>
                                        {/* <Form.Control type="text" id="lateTimeDeduct_1" placeholder="Late Time Deduct 1...." onBlur={(e) => setLateTimeDeduct_1(e.target.value)} /> */}
                                        <Select
                                            defaultValue={lateTimeDeduct_1}

                                            placeholder='Late Time Deduction'
                                            options={getDeductionList}
                                            onChange={handleLateDeduction}
                                        />
                                    </Form.Group>
                                </Col>

                                <Col lg="4" md="4" xs="12">
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>Late Time Deduction Rate:</b></Form.Label>
                                        <Form.Control type="text" id="lateTimeDeduct_2" placeholder="Late Time Deduction Rate...." onBlur={(e) => setLateTimeDeduct_2(e.target.value)} disabled={lateTimeDeduct_1 === 0} />
                                    </Form.Group>
                                </Col>
                                {/* <Col lg="4" md="4" xs="12">
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>Late Time:</b></Form.Label>
                                        <Select
                                            //isDisabled={isEmpty(getDepartmentNameList)}
                                            placeholder="Select Late Time..."
                                            onChange={handleChangeLateTime}
                                            options={getLateTimeList}
                                        />
                                    </Form.Group>
                                </Col> 
                                <Col lg="4" md="4" xs="12">
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>Arrival Time:</b></Form.Label>
                                        <Form.Control type="time" id="arrivalTime" placeholder="Arrival Time...." onBlur={(e) => setArrivalTime(e.target.value)} />
                                    </Form.Group>
                                </Col> */}

                                <Col lg="4" md="4" xs="12">
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>Exemption Attendance:</b></Form.Label>
                                        {/* <Form.Control type="text" id="exemptionAttendance" placeholder="Exemption Attendance...." onBlur={(e) => setExemptionAttendance(e.target.value)} /> */}
                                        <Select

                                            placeholder="Exemption Attendance..."
                                            onChange={handleChangeexemptionAttendance}
                                            options={getExemptionAttendanceList}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="4" md="4" xs="12">
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>Leave Allowed:</b></Form.Label>
                                        {/* <Form.Control type="number" id="leaveAllowed" placeholder="Number of Days...." onBlur={(e) => setLeaveAllowed(e.target.value)} /> */}
                                        <Select

                                            placeholder="Select Leave Allowed..."
                                            defaultValue={leaveAllowed}
                                            onChange={handleLeaveAllowed}
                                            options={getLeaveAllowedList}
                                        />
                                    </Form.Group>
                                </Col>


                                <Col lg="4" md="4" xs="12">
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>Salary Transfer:</b></Form.Label>
                                        <Select
                                            // isDisabled={isEmpty(getDepartmentNameList)}
                                            placeholder="Salary Transfer..."
                                            onChange={handleChangeSalaryTransfer}
                                            options={getSalaryTransferList}
                                        />
                                    </Form.Group>
                                </Col>

                                <Col lg="4" md="4" xs="12">
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>Bank Name:</b></Form.Label>
                                        <Form.Control type="text" id="bankName" placeholder="Bank Name...." onBlur={(e) => setBankName((e.target.value).toUpperCase())} disabled={(salaryTransfer === 'Cash')} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="4" md="4" xs="12">
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>Bank Account:</b></Form.Label>
                                        <Form.Control type="text" id="bankAccount" placeholder="Banking Account...." onBlur={(e) => setBankAccount(e.target.value)} disabled={(salaryTransfer === 'Cash')} />
                                    </Form.Group>
                                </Col>
                                <Col lg="4" md="4" xs="12">
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>Salary Creation:</b></Form.Label>
                                        <Select
                                            // isDisabled={isEmpty(getDepartmentNameList)}
                                            placeholder="Salary Creation..."
                                            onChange={handleSalaryCreation}
                                            options={getSalaryCreationList}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg="4" md="4" xs="12">
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>Salary Creation Basis:</b></Form.Label>
                                        <Select
                                            // isDisabled={isEmpty(getDepartmentNameList)}
                                            placeholder="Salary Creation Basis..."
                                            onChange={handleSalaryCreationBasis}
                                            options={getSalaryCreationBasisList}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>

                                {(salaryCreationBasis === "Shift") &&
                                    <Col lg="4" md="4" xs="12">
                                        <Form.Group className="mb-3" controlId="garana" >
                                            <Form.Label><b>Select Shift:</b></Form.Label>
                                            <Select
                                                // isDisabled={isEmpty(getSelectableShifts)}
                                                placeholder="Select Shift..."
                                                onChange={handleShiftSelection}
                                                options={getSelectableShifts}
                                                isDisabled={(salaryCreationBasis !== "Shift") || isEmpty(getSelectableShifts)}

                                            />
                                        </Form.Group>
                                    </Col>}
                            </Row>


                            <div className="sendDiv">
                            {((salaryCreationBasis === "Shift") && ((shiftSelection === null)) ||
                                        (
                                            employeeId === '' ||
                                            machineId === '' ||
                                            basicSalary === 0 ||
                                            medicalAllowance === 0 ||
                                            petrolAllowance === 0 ||
                                            otherAllowance === 0 ||
                                            accomodationAllowance === 0 ||
                                            bonus === '' ||
                                            overTime === '' ||
                                            overTimeRate === '' ||
                                            salaryTransfer === '' || ((salaryTransfer === 'Bank') && (bankAccount === undefined) && (bankName === undefined) ) ||
                                            lateTimeDeduct_1 === undefined || ((lateTimeDeduct_1 === 1) && (lateTimeDeduct_2 === undefined)) ||
                                            exemptionAttendance === undefined ||
                                            leaveAllowed === undefined ||
                                            salaryCreationBasis === undefined ||
                                            salaryCreation === undefined 
                                    )
                                    )?
                                <Button className="sendButton" style={{backgroundColor:"black"}} onClick={() => saveSalary()}
                                    // disabled={(activeStatus !== true) || (activeStatus !== false) || (basicSalary === 0)}
                                    disabled
                                >Save</Button>:
                                  <Button className="sendButton" onClick={() => saveSalary()}
                               
                                >Save</Button>}
                            </div>
                        </div>
                    </Container>
                }
            </Container>
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        addSalary: (data, handleVisible, handleDangerVisible) => dispatch(addSalary(data, handleVisible, handleDangerVisible)),
        getEmployeeData: () => dispatch(getEmployeeData()),
        getShiftControl: () => dispatch(getShiftControl()),
        getEmployeeDataWithStatus: (status) => dispatch(getEmployeeDataWithStatus(status)),

    };
};
const mapStateToProps = (state, ownProps) => ({
    employeeList: state.HrReducer.employeeList,
    isAddingSalary: state.HrReducer.isAddingSalary,
    errorAddingSalary: state.HrReducer.errorAddingSalary,
    successAddingSalary: state.HrReducer.successAddingSalary,


    isFetchingEmployeeData: state.HrReducer.isFetchingEmployeeData,
    shiftControl: state.HrReducer.shiftControl,

    activeEmployeeList: state.HrReducer.activeEmployeeList,
    isFetchingActiveEmployeeData: state.HrReducer.isFetchingActiveEmployeeData,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddSalary);