import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Container,
  Button,
  FormGroup,
  Alert,
} from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty, isNull, upperCase } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import "jspdf-autotable";
import { GetHRDesignation, deleteDesignation } from "./HRDetails.actions";
import Select from "react-select";
import Swal from "sweetalert";

const DesignationList = (props) => {
  const designationData = props.designationList.reverse();

  useEffect(() => {
    props.GetHRDesignation();
  }, []);
  return (
    <>
      {props.isFetchingDepartmentData ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {/* {props.isFetchingwareHouseList ? 'Loading...' : 'Loading...'} */}
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <div>
              <Form>
                <MaterialTable
                  title="Designation"
                  columns={[
                    {
                      title: "Designation Id",
                      field: "designation_id",
                      render: (rowData) =>
                        rowData.division_id +
                        "-" +
                        rowData.department_id +
                        "-" +
                        rowData.designation_id,
                    },
                    { title: "Designation Name", field: "designationName" },
                    {
                      title: "Designation Description",
                      field: "designationDescription",
                    },
                    {
                      title: "Department Name",
                      field: "department.departmentName",
                      editable: "never",
                    },
                  ]}
                  data={designationData}
                  options={{
                    actionsColumnIndex: -1,
                    filtering: true,
                    exportButton: true,
                    exportAllData: true,
                    maxBodyHeight: "600px",
                    headerStyle: {
                      position: "sticky",
                      top: 0,
                      color: "#00BBBB",
                      fontWeight: "550",
                      onRowAdd: "none",
                    },
                  }}
                  editable={{
                    // onRowUpdate: (newData, oldData) =>
                    //     new Promise((resolve) => {
                    //         setTimeout(() => {
                    //             resolve();
                    //             {
                    //                 props.updateWareHouse(newData, oldData)
                    //             }
                    //         }, 600);
                    //     }),
                    onRowDelete: (oldData) =>
                      new Promise((resolve) => {
                        setTimeout(() => {
                          resolve();
                          props.deleteDesignation(oldData.designation_id);
                          console.log(oldData, "old");
                        }, 600);
                      }),
                  }}
                />
              </Form>
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetHRDesignation: () => dispatch(GetHRDesignation()),
    deleteDesignation: (designation_id) =>
      dispatch(deleteDesignation(designation_id)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  designationList: state.HrReducer.designationList,
  departmentList: state.HrReducer.departmentList,
  isFetchingDepartmentData: state.HrReducer.isFetchingDepartmentData,
  isFetchingDesignationList: state.HrReducer.isFetchingDesignationList,
});

export default connect(mapStateToProps, mapDispatchToProps)(DesignationList);
