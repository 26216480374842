import API from "../../../../../global/api";

export const requestGetArticle = () => {
    return {
        type: "REQUEST_GET_ARTICLE",
    };
};
export const successGetArticle = (data) => {
    return {
        type: "SUCCESS_GET_ARTICLE",
        payload: data,
    };
};
export const errorGetArticle = () => {
    return {
        type: "ERROR_GET_ARTICLE",
    };
};

export const getArticle = () => {
    return (dispatch) => {
        dispatch(requestGetArticle());
        API.get(`/atricle/get-article`).then((res) => {
            let getData = res.data;
            dispatch(successGetArticle(getData));
        }).catch((error) => {
            dispatch(errorGetArticle());
        })
    };
};



export const requestGetArticleData = () => {
    return {
        type: "REQUEST_GET_ARTICLE_DATA",
    };
};
export const successGetArticleData = (data) => {
    return {
        type: "SUCCESS_GET_ARTICLE_DATA",
        payload: data,
    };
};
export const errorGetArticleData = () => {
    return {
        type: "ERROR_GET_ARTICLE_DATA",
    };
};

export const getArticleData = (id) => {
    return (dispatch) => {
        dispatch(requestGetArticleData());
        API.get(`/article/get-article-data/${id}`).then((res) => {
            let getData = res.data;
            dispatch(successGetArticleData(getData));
        }).catch((error) => {
            dispatch(errorGetArticleData());
        })
    };
};



export const requestAddConversionCost = () => {
    return {
        type: "REQUEST_ADD_CONVERSION_COST",
    };
};
export const successAddConversionCost = (data) => {
    return {
        type: "SUCCESS_ADD_CONVERSION_COST",
        payload: data,
    };
};
export const errorAddConversionCost = () => {
    return {
        type: "ERROR_ADD_CONVERSION_COST",
    };
};

export const addConversionCost = (data) => {
    return (dispatch) => {
        dispatch(requestAddConversionCost());
        API.post(`/article/conversioncost-add-data`, data).then((res) => {
            let getData = res.data;
            dispatch(successAddConversionCost(getData));
        }).catch((error) => {
            dispatch(errorAddConversionCost());
        })
    };
};