import React, { useState } from "react";
import {
  Container,
  Form,
  Row,
  Col,
  Button,
  FormGroup,
  FormControl,
} from "react-bootstrap";
import {
  addMenu,
  getMenu,
  deleteMenu,
  getMasterControl /*UpdateHRMenu */,
  SearchMarqueeMenuReport,
  UpdateMenu,
} from "./MarqueeDetails.actions";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import { useEffect } from "react";
import Swal from "sweetalert";
import { isEmpty, find, sumBy } from "lodash";
import jsPDF from "jspdf";
import logo from "../../../../../Images/logo.png";
import Select from "react-select";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { IconButton } from "@material-ui/core";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { Link } from "react-router-dom";
const InstallmentReportTable = (props) => {
  const subEventsData = isEmpty(props.customerByIdList)
    ? []
    : props.customerByIdList;

  useEffect(() => {
    console.log(
      props.customerByIdList,
      "ttttttttttttttttttttttttttttttttttttttttttt"
    );
    //     props.getMenu(2);
  }, []);
  return (
    <>
      <Container fluid>
        {props.isFetchingCustomerByIdData ? (
          <div className="loader-div">
            <Button variant="info" disabled>
              <Spinner
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
                variant="info"
              />
              loading
            </Button>
          </div>
        ) : (
          <>
            <div className="sendDiv">
              <Link to="/user/add-installment">
                <Button className="sendButton"> Back to Search</Button>
              </Link>
            </div>
            <MaterialTable
              title="Installment History"
              columns={[
                { title: "Booking Id", field: "booking_id", editable: "never" },
                {
                  title: "Booking Date",
                  field: "booking.booking_date",
                  editable: "never",
                },
                {
                  title: "Booking Name",
                  field: "booking.booking_name",
                  editable: "never",
                },

                // { title: 'Booking Status', field: 'booking_installment_status', editable: 'never' },
                {
                  title: "Installment Date",
                  field: "booking_installment_date",
                  editable: "never",
                },
                {
                  title: "Total Price",
                  field: "booking_total_amount",
                  editable: "never",
                },
                {
                  title: "Installment Price",
                  field: "installment_price",
                  editable: "never",
                },
                {
                  title: "Remaining Price",
                  field: "closing_balance",
                  editable: "never",
                },
              ]}
              data={subEventsData}
              options={{
                actionsColumnIndex: -1,
                filtering: true,
                exportButton: true,
                exportAllData: true,
                maxBodyHeight: "600px",
                headerStyle: {
                  position: "sticky",
                  top: 0,
                  color: "#00BBBB",
                  fontWeight: "550",
                  onRowAdd: "none",
                },
              }}
            />
          </>
        )}
      </Container>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMenu: (id) => dispatch(getMenu(id)),
    UpdateMenu: (newData, oldData) => dispatch(UpdateMenu(newData, oldData)),
    deleteMenu: (menu_id) => dispatch(deleteMenu(menu_id)),
    SearchMarqueeMenuReport: (data) => dispatch(SearchMarqueeMenuReport(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  customerByIdList: state.MarqueeReducer.customerByIdList,
  isFetchingCustomerByIdData: state.MarqueeReducer.isFetchingCustomerByIdData,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InstallmentReportTable);
