import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, FormGroup, Alert } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty, isNull, upperCase } from "lodash";
import Spinner from 'react-bootstrap/Spinner';
import MaterialTable from 'material-table';
import 'jspdf-autotable';
import { addHRDepartment, GetHRDepartment, getHRDivision, updateHRDepartment, deleteHRDepartment } from "./HRDetails.actions";
import Select from 'react-select';
import Swal from 'sweetalert';


const AddDepartment = (props) => {

    const [divisionId, setDivisionId] = useState(0);
    const [divisionName, setDivisionName] = useState();

    const [departmentName, setDepartmentName] = useState();
    const [departmentDescription, setDepartmentDescription] = useState();

    const [visibleAlert, setAlertVisible] = useState(false);
    const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);


    function uploadDepartment() {
        props.addHRDepartment({
            division_id: divisionId,         //name to be varified
            departmentName: upperCase(departmentName),
            departmentDescription: upperCase(departmentDescription),
        },handleVisible, handleDangerVisible );
    }
    // [(!isEmpty(props.divisionList)) & (console.log('props.divisionList', props.divisionList))]
    const getDivisionList = !isEmpty(props.divisionList) && (props.divisionList.map((x) => { let data = { value: x.division_id, label: x.division_name }; return data;   })) ;
    console.log('getDivisionList', getDivisionList);
    const departmentData = isEmpty(props.departmentList) ? [] : props.departmentList.reverse();
    // const divisionData = isEmpty(props.departmentList) ? [] : props.departmentList.reverse();

    const clearStates = () => {
        setDivisionId(0);
        setDivisionName();
        setDepartmentName();
        setDepartmentDescription();

    }
    
    /*const [state, setState] = React.useState({
        columns: [
            { title: 'Department Name', field: 'departmentName' },
            { title: 'Department Description', field: 'departmentDescription' },
            { title: 'Division Name', field: 'division_hr.division_name', editable: 'never' },
        ],
    });*/

    const handleVisible = () => {
        setAlertVisible(true)
        clearStates()
        setTimeout(() => {
            setAlertVisible(false)
        }, 9000);
        Swal({
            title: "Success",
            text: "Added successfully...",
            icon: "success",
            button: "Ok",
        });
    }

    const handleDangerVisible = () => {
        setDangerVisibleAlert(true)
        setTimeout(() => {
            setDangerVisibleAlert(false)
        }, 9000);
        Swal({
            title: "Error",
            text: "Something went wrong...",
            icon: "error",
            button: "Ok",
        });
    }

    const handleChangeDivision = (selectedOption) =>
    {
        setDivisionId(selectedOption.value);
        setDivisionName(selectedOption.label);

    }
    useEffect(() => {
        props.GetHRDepartment();
        props.getHRDivision();
    }, []);
    return (
        <>
            {(props.isFetchingDepartmentData || props.isFetchingDivisionData || props.isAddingDepartmentData || props.isDeletingDepartmentData) ? (
                <div className="loader-div">
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />
                        {/* {props.isFetchingwareHouseList ? 'Loading...' : 'Loading...'} */}

                    </Button>
                </div>
            ) : (
                <Container fluid>

                    <div className="main">

                        <div>
                            <Form>
                                <Row>
                                    <Col lg="12" md="12" xs="12">
                                        <Form.Group className="mb-3" controlId="garana" >
                                            <Form.Label><b>Select Division:</b></Form.Label>
                                            <Select
                                                placeholder="Select Division..."
                                                onChange={handleChangeDivision}
                                                options={getDivisionList}

                                            />

                                        </Form.Group>
                                    </Col>
                                </Row>
                                                                <Row>
                                    <Col lg="12" md="12" xs="12">
                                        <Form.Group className="mb-3" controlId="garana" >
                                            <Form.Label><b>Enter Department Name :</b></Form.Label>
                                            <Form.Control type="text" placeholder="Department Name...." onChange={(e) =>
                                                setDepartmentName(e.target.value)} 
                                                disabled={isEmpty(divisionName)}
                                                    />
                                        </Form.Group>
                                    </Col>
                                </Row>


                                <Row>
                                    <Col xl="12" lg="12" md="12" sm="12" xs="12">
                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                <b>Enter Department Description:</b>
                                            </Form.Label>
                                            <Form.Control as="textarea" rows={3}
                                                placeholder="Department Description....." onMouseLeave={(e) => setDepartmentDescription(e.target.value)} disabled={isEmpty(divisionName)}/>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <div className="sendDiv">
                                    {isEmpty(divisionName) || isEmpty(departmentName)?
                                    <Button className="sendButton" onClick={uploadDepartment} style={{backgroundColor:"black"}} disabled>Save</Button>:
                                    <Button className="sendButton" onClick={uploadDepartment} >Save</Button>}
                                </div>
                            </Form>

                        </div>

                    </div>

                </Container>

            )}
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        addHRDepartment: (data,handleVisible, handleDangerVisible) => dispatch(addHRDepartment(data,handleVisible, handleDangerVisible)),
        GetHRDepartment: () => dispatch(GetHRDepartment()),
        getHRDivision: () => dispatch(getHRDivision()),
        updateHRDepartment: (newData, oldData) => dispatch(updateHRDepartment(newData, oldData)),
        deleteHRDepartment: (departmentId) => dispatch(deleteHRDepartment(departmentId)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    departmentList: state.HrReducer.departmentList,
    divisionList: state.HrReducer.divisionList,
    isFetchingDepartmentData: state.HrReducer.isFetchingDepartmentData,
    isFetchingDivisionData: state.HrReducer.isFetchingDivisionData,
    isAddingDepartmentData: state.HrReducer.isAddingDepartmentData,
    isDeletingDepartmentData: state.HrReducer.isDeletingDepartmentData,
    
    

});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddDepartment);