import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, Alert } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import "jspdf-autotable";
import { addUOMCall, getUOMCall } from "./production.actions";

const AddUOM = (props) => {
  console.log(props.uomInfo, "kkqq");
  const [UOM, setUOM] = useState("");
  const [description, setDescription] = useState("");

  /*const [state, setState] = useState({
    columns: [
      { title: 'UOM', field: 'uomName'},
      { title: 'UOM Description', field: 'uomDescription' },
    ],
  });*/

  const sendData = () => {
    props.addUOMCall({
      uomName: UOM,
      uomDescription: description,
    });
  };

  useEffect(() => {
    props.getUOMCall();
  }, []);

  return (
    <Container fluid>
      <div className="main">
        <div>
          <Form>
            <Row>
              <Col lg="12" md="12" xs="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Enter UOM:</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="UOMDetail"
                    placeholder="UOM...."
                    onBlur={(e) => setUOM(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col lg="12" md="12" xs="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Description:</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="uomDescription"
                    placeholder="Description...."
                    onBlur={(e) => setDescription(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>

            <div className="sendDiv">
              <Button className="sendButton" onClick={() => sendData()}>
                Save
              </Button>
            </div>

            <MaterialTable
              title="UOM Details"
              columns={[
                { title: "UOM", field: "uomName" },
                { title: "UOM Description", field: "uomDescription" },
              ]}
              data={props.uomInfo}
              options={{
                actionsColumnIndex: -1,
                filtering: true,
                exportButton: true,
                exportAllData: true,
                maxBodyHeight: "600px",
                headerStyle: {
                  position: "sticky",
                  top: 0,
                  color: "#00BBBB",
                  fontWeight: "550",
                  onRowAdd: "none",
                },
              }}
              editable={{
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      // props.updateWareHouse(newData, oldData)
                    }, 600);
                  }),

                onRowDelete: (oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      //   props.deleteWareHouse(oldData.wareHouseId);
                    }, 600);
                  }),
              }}
            />
          </Form>
        </div>
      </div>
    </Container>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addUOMCall: (data) => dispatch(addUOMCall(data)),
    getUOMCall: () => dispatch(getUOMCall()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  uomInfo: state.productionReducer.uomInfo,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUOM);
