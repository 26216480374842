import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Table,
  Container,
  Button,
  FormGroup,
  Alert,
  Tab,
  Tabs,
} from "react-bootstrap";
import "./add-details.css";
import { ModalBody, Modal } from "reactstrap";
import {
  getPurchase,
  getApproveDemand,
  addIssuance,
  getAllInventory,
  getIssuance,
  searchIssuanceReport,
  searchIssuanceReportByDate,
} from "./AddDetails.actions";
import { connect } from "react-redux";
import { isEmpty, find, isUndefined } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import WareHouseModal from "./WareHouseModal";
import MaterialTable from "material-table";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { IconButton } from "@material-ui/core";
import Select from "react-select";
import Swal from "sweetalert";

const IssuanceReportByDate = (props) => {
  const [inputField, setInputField] = useState([
    {
      inventoryId: "",
      productQuantityCount: "",
      productQuantity: 0,
    },
  ]);

  const [maximumQuantity, setMaximumQuantity] = useState(0);
  const [requistion, setRequistion] = useState();
  const [department, setdepartment] = useState();
  const [productionOrder, setProductionOrder] = useState();
  const [supervisor, setSupervisor] = useState();
  const [issuedBy, setIssuedBy] = useState();
  const [receivedBy, setReceivedBy] = useState();
  const [requistionType, setRequistionType] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [purchaseNameValue, setPurchaseNameValue] = useState();
  const [purchaseNameLabel, setPurchaseNameLabel] = useState();
  const [issuanceNameValue, setIssuanceNameValue] = useState();
  const [issuanceNameLabel, setIssuanceNameLabel] = useState();
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  const handleVisible = () => {
    setAlertVisible(true)
    setTimeout(() => {
        setAlertVisible(false)
    }, 9000);
    Swal({
        title: "Data Not Found",
        text: "Data Not Found",
        icon: "info",
        button: "Ok",
    });
}

  const handleDangerVisible = () => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: "Something went Wrong!",
      icon: "error",
      button: "Ok",
    });
  };

//   const handleChangeGetIssuance = (selectedOption) => {
//     setIssuanceNameValue(selectedOption.value);
//     console.log(issuanceNameValue, "oooooooooooppppppppppppppp");
//     setIssuanceNameLabel(selectedOption.label);
//   };

//   const getIssuanceList = props.IssuanceList.map((x) => {
//     let data = { value: x.issuanceId, label: x.issuanceId };
//     return data;
//   });

  const exportPDF = () => {
    props.searchIssuanceReport(
      {
         startedDate : !isEmpty(startDate) ? startDate : "",
          endDate: !isEmpty(endDate) ? endDate : "",
      //  issuanceId: issuanceNameValue,
      },
      startDate,
      endDate,
      handleVisible
    );
  };

  useEffect(() => {
   // props.getIssuance();
  }, []);
  return (
    <>
      {props.isFetchingIssuance ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Row>
              <Col xl="6" lg="6" md="6" sm="6">
                <FormGroup>
                  <Form.Label>
                    <b>Start Date :</b>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  ></Form.Control>
                </FormGroup>
              </Col>
              <Col xl="6" lg="6" md="6" sm="6">
                <FormGroup>
                  <Form.Label>
                    <b>End Date</b>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  ></Form.Control>
                </FormGroup>
              </Col>
            </Row>
            {/* <Row>
                                    <Col xl="12" lg="12" md="12" sm="12">
                                        <Form.Group>
                                            <Form.Label>
                                                <b> Select Issuance</b>
                                            </Form.Label>
                                            <Select
                                                placeholder="Select Issuance..."
                                                onChange={(e) => handleChangeGetIssuance(e)}
                                                options={getIssuanceList}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row> */}
            <div className="sendDiv">
              {/* {!issuanceNameValue ? <Button disabled style={{ marginLeft: "2%", color: "white", width: "20%", backgroundColor: "black", border: "1px solid black" }}>Generate Issuance Report</Button> :  <Button className="sendButton" onClick={() => exportPDF()}>Generate Issuance Report</Button>} */}

              {isUndefined(startDate) || isUndefined(endDate) ? (
                <Button
                  disabled
                  style={{
                    marginLeft: "2%",
                    color: "white",
                    width: "20%",
                    backgroundColor: "black",
                    border: "1px solid black",
                  }}
                >
                  Generate Issuance Report
                </Button>
              ) : props.isFetchingSearchIssuanceList ? (
                <Button className="sendButton">Downloading PDF</Button>
              ) : (
                <Button className="sendButton" onClick={() => exportPDF()}>
                  Generate Issuance Report
                </Button>
              )}
            </div>
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    searchIssuanceReport: (data, startDate, endDate,handleVisible) =>
      dispatch(
        searchIssuanceReportByDate(data, startDate, endDate,handleVisible)
      ),
  };
};
const mapStateToProps = (state, ownProps) => ({

});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IssuanceReportByDate);
