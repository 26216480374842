import React, { useEffect, useState } from "react";
import { Card, Tabs, Tab } from "react-bootstrap";
import { connect } from "react-redux";
import "./style.css";
import { getAllVoucherType, resetVouchersState } from "./Voucher.action";
import {
  getLevel5Data,
  getLevel4Data,
} from "../accountsLevels/accountsLevel.action";
import BankReceipt from "./BankReceipt";
import BankPayment from "./BankPayment";
import CashPayment from "./CashPayment";
import CashReceipt from "./CashReceipt";
import JournalVoucher from "./JournalVoucher";
import { isNull } from "lodash";
const Voucher = (props) => {
  const permissions = JSON.parse(localStorage.getItem("permissions"));
  useEffect(() => {
    props.getLevel5Data();
    props.getLevel4Data();
  }, []);
  const [key, setKey] = useState("home");
  const [state, setState] = React.useState({
    columns: [
      { title: "Unit", field: "warehouse.wareHouseName", editable: "never" },
      { title: "Account No", field: "categoryName" },
      { title: "Account Title", field: "categoryName" },
      { title: "Bill No", field: "categoryName" },
      { title: "Description", field: "categoryName" },
      { title: "Debit", field: "categoryName" },
      { title: "Credit", field: "categoryName" },
    ],
  });
  const handleTabChange = () => {
    resetVouchersState();
  };
  return (
    <>
      {/* <Card style={{ padding: "3%" }}>

                <Tabs
                    defaultActiveKey={!isNull(permissions) && permissions.some(p => p['sub_id'] === 33) ?
                        "CPV" : !isNull(permissions) && permissions.some(p => p['sub_id'] === 34) ?
                            "CRV" : !isNull(permissions) && permissions.some(p => p['sub_id'] === 35) ?
                                "BPV" : !isNull(permissions) && permissions.some(p => p['sub_id'] === 36) ?
                                    "BRV" : !isNull(permissions) && permissions.some(p => p['sub_id'] === 37) ?
                                        "JV" : ""}
                    className="mb-5"
                    style={{ fontSize: "large" }}
                    unmountOnExit={true}>

                    <Tab
                        eventKey="CPV"
                        title="Cash Payment Voucher"
                        unmountOnExit={true}
                        disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 33) ? false : true}>
                        <CashPayment />
                    </Tab>

                    <Tab
                        eventKey="CRV"
                        title="Cash Receipt Voucher"
                        unmountOnExit={true}
                        disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 34) ? false : true}>
                        <CashReceipt />
                    </Tab>

                    <Tab
                        eventKey="BPV"
                        title="Bank Payment Voucher"
                        unmountOnExit={true}
                        disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 35) ? false : true}>
                        <BankPayment />
                    </Tab>

                    <Tab
                        eventKey="BRV"
                        title="Bank Receipt Voucher"
                        unmountOnExit={true}
                        disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 36) ? false : true}>
                        <BankReceipt />
                    </Tab>

                    <Tab
                        eventKey="JV"
                        title="Journal Voucher"
                        unmountOnExit={true}
                        disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 37) ? false : true}>
                        <JournalVoucher />
                    </Tab>
                </Tabs>
            </Card> */}

      <Card style={{ padding: "3%" }}>
        <Tabs
          defaultActiveKey={
            !isNull(permissions) && permissions.some((p) => p["sub_id"] === 21)
              ? "CPV"
              : !isNull(permissions) &&
                permissions.some((p) => p["sub_id"] === 22)
              ? "CRV"
              : !isNull(permissions) &&
                permissions.some((p) => p["sub_id"] === 23)
              ? "BPV"
              : !isNull(permissions) &&
                permissions.some((p) => p["sub_id"] === 24)
              ? "BRV"
              : !isNull(permissions) &&
                permissions.some((p) => p["sub_id"] === 25)
              ? "JV"
              : ""
          }
          id="uncontrolled-tab-example"
          className="mb-5"
          style={{ fontSize: "large" }} /*onSelect={()=> handleTabChange}*/
        >
          <Tab
            eventKey="CPV"
            title="Cash Payment Voucher"
            disabled={
              !isNull(permissions) &&
              permissions.some((p) => p["sub_id"] === 21)
                ? false
                : true
            }
          >
            <CashPayment />
          </Tab>
          <Tab
            eventKey="CRV"
            title="Cash Receipt Voucher"
            disabled={
              !isNull(permissions) &&
              permissions.some((p) => p["sub_id"] === 22)
                ? false
                : true
            }
          >
            <CashReceipt />
          </Tab>
          <Tab
            eventKey="BPV"
            title="Bank Payment Voucher"
            disabled={
              !isNull(permissions) &&
              permissions.some((p) => p["sub_id"] === 23)
                ? false
                : true
            }
          >
            <BankPayment />
          </Tab>
          <Tab
            eventKey="BRV"
            title="Bank Receipt Voucher"
            disabled={
              !isNull(permissions) &&
              permissions.some((p) => p["sub_id"] === 24)
                ? false
                : true
            }
          >
            <BankReceipt />
          </Tab>
          <Tab
            eventKey="JV"
            title="Journal Voucher"
            disabled={
              !isNull(permissions) &&
              permissions.some((p) => p["sub_id"] === 25)
                ? false
                : true
            }
          >
            <JournalVoucher />
          </Tab>
        </Tabs>
      </Card>
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAllVoucherType: () => dispatch(getAllVoucherType()),
    getLevel5Data: () => dispatch(getLevel5Data()),
    getLevel4Data: () => dispatch(getLevel4Data()),
    resetVouchersState: () => dispatch(resetVouchersState()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  getAllVoucher: state.VoucherReducer.getAllVoucher,
});

export default connect(mapStateToProps, mapDispatchToProps)(Voucher);
