import React,{useState} from 'react'
import { getBooking, getBookingById, SearchMarqueeEventReport } from './MarqueeDetails.actions';
import { connect } from "react-redux";
import { Container, Form, Row, Col, Button, FormGroup } from "react-bootstrap";
import { isEmpty, find, sumBy, pick } from "lodash";
import Select from "react-select";
import { useEffect } from "react";
const BookingReport = (props) => {
  function uploadExpense(id) {
    props.SearchMarqueeEventReport(id)
 }
  const [bookingId, setBookingId] = useState("");
  const getBookingList =!isEmpty(props.bookingList) &&
  props.bookingList.map((x) => {
    let data = { value: x.booking_id, label: `${x.booking_id} / ${x.booking_name} / ${x.booking_date}` };
    return data;
  });
  const handleChangeBookingById = (selectedOption) =>
  {
     console.log(selectedOption, "sop")
      setBookingId(selectedOption.value);
     // setBookingName(selectedOption.label);
      props.getBookingById(selectedOption.value)
      
  }
  useEffect(() => {
    props.getBooking();
  }, []);
  return (
    <>
      <Container fluid>
          <Form>
          <Row>
                                    <Col lg="12" md="12" xs="12">
                                        <Form.Group className="mb-3" controlId="garana" >
                                            <Form.Label><b>Select booking:</b></Form.Label>
                                            <Select
                                            isDisabled={isEmpty(props.bookingList)}
                                                placeholder="Select booking..."
                                                onChange={handleChangeBookingById}
                                                options={getBookingList}
                                            />

                                        </Form.Group>
                                    </Col>
                                </Row>
                                <div className="sendDiv">
                                  {bookingId==""?
                            <Button className="sendButton" disabled style={{backgroundColor:'black'}} onClick={() => { uploadExpense(props.bookingByIdList); }} >Generate Report</Button>:
                            <Button className="sendButton" onClick={() => { uploadExpense(props.bookingByIdList); }} >Generate Report</Button>}
                        </div>
                                </Form>
        </Container>
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    getBooking: () => dispatch(getBooking()),
    getBookingById:(id) => dispatch(getBookingById(id)),
    SearchMarqueeEventReport:(bookingByIdList)=> dispatch(SearchMarqueeEventReport(bookingByIdList)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  bookingList: state.MarqueeReducer.bookingList,
  bookingByIdList: state.MarqueeReducer.bookingByIdList,
});

export default connect(mapStateToProps, mapDispatchToProps)(BookingReport);

