import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Container,
  Button,
  FormGroup,
  Tabs,
  Tab,
  Alert,
} from "react-bootstrap";
import "./add-details.css";
import {
  getCategoryData,
  addCategoryName,
  getWarehouseData,
  getsubCategoryData,
  getInventory,
  deleteInventory,
  updateInventory,
  resetReducer,
  getAllInventory,
  categoryByWareHouse,
  subCategoryByCategory,
} from "./AddDetails.actions";
import { connect } from "react-redux";
import { isEmpty, get, find, chain, uniqBy, isNull } from "lodash";
import moment from "moment";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import Select from "react-select";
import { Link } from "react-router-dom";

const InventoryMaterialTable = (props) => {
  // const getWareHouseList = props.warehouseList.map((x) => { let data = { value: x.wareHouseId, label: x.wareHouseName }; return data });
  // const getcategoryList = !isEmpty(props.categoryByWareHouseValue) && props.categoryByWareHouseValue.map((x) => { let data = { value: x.categoryId, label: x.categoryName }; return data });
  // const getSubCategoryList = !isEmpty(props.subCategoryByCategoryValue) && props.subCategoryByCategoryValue.map((x) => { let data = { value: x.subCategoryId, label: x.subCategoryName }; return data });

  // console.log(getcategoryList, "llqq")

  // const handleChangeWareHouse = (selectedOption) => {
  //     setcategoryId1();
  //     setcategoryId();
  //     setSubCategoryId();
  //     setSubCategoryId1();
  //     setwareHouseId1(selectedOption.value);
  //     setwareHouseId(selectedOption.label);
  //     props.categoryByWareHouse(selectedOption.value);
  // };

  // const handleChangeCategory = (selectedOption) => {
  //     setSubCategoryId();
  //     setSubCategoryId1();
  //     setcategoryId1(selectedOption.value);
  //     setcategoryId(selectedOption.label);
  //     props.subCategoryByCategory(selectedOption.value);
  // };

  // const handleChangeSubCategory = (selectedOption) => {
  //     setSubCategoryId(selectedOption.label);
  //     setSubCategoryId1(selectedOption.value);
  // };

  function searchInventory() {
    // props.getAllInventory({
    //     startedDate: !isEmpty(startDate) ? startDate : "",
    //     endDate: !isEmpty(endDate) ? endDate : "",
    //     wareHouseId: wareHouseId1,
    //     subCategoryId: !isEmpty(subCategoryId1) ? subCategoryId1 : "",
    //     categoryId: categoryId1,
    // })
    // setSubCategoryId("");
    // setwareHouseId("");
    // setcategoryId("");
    // setStartDate("");
    // setEndDate("");
  }

  const wareHouseData = props.allInventory.map(function (el) {
    var o = Object.assign({}, el);
    o.key =
      el.warehouse.whKey +
      " " +
      "-" +
      " " +
      el.category.cateKey +
      " " +
      "-" +
      " " +
      el.sub_category.subcateKey +
      " " +
      "-" +
      " " +
      el.invKey;
    return o;
  });
  const [open, setOpen] = React.useState(props.dataAddedInventory);
  /*const [state, setState] = React.useState({
        columns: [
            { title: 'Inventory Code', field: 'key', editable: 'never' },
            { title: 'WareHouse', field: 'warehouse.wareHouseName', editable: 'never' },
            { title: 'Category', field: 'category.categoryName', editable: 'never' },
            { title: 'SubCategory', field: 'sub_category.subCategoryName', editable: 'never' },
            { title: 'Company Name', field: 'companyName' },
            { title: 'Inventory Field', field: 'inventoryField' },
            { title: 'Product Type', field: 'productType' },
            {
                title: 'Inventory Name', field: 'productName', render: rowData => <div style={{
                    backgroundColor: Number(rowData.currentBlnc) <= Number(rowData.minBlnc) ? '#ff8d8d' : Number(rowData.currentBlnc) >= Number(rowData.maxBlnc) ? '#addfac' : 'transparent'
                }}> {rowData.productName}</div>
            },
            { title: 'Product Price', field: 'productPrice' },
            { title: 'Currency', field: 'currency' },
            { title: 'Arrival Date', field: 'arrival_date', render: rowData => moment(rowData.arrival_date).format("YYYY-MM-DD") },
            { title: 'Vendor Name', field: 'VendorName' },
            { title: 'Current Balance', field: 'currentBlnc' },
            { title: 'Unit of Measure', field: 'uom' },
            { title: 'Minimum Balance', field: 'minBlnc' },
            { title: 'Maximum Balance', field: 'maxBlnc' },
            { title: 'Size', field: 'invSize' },
            { title: 'Color', field: 'invColor' },
            { title: 'Lenght', field: 'length' },
            { title: 'Height', field: 'height' },
            { title: 'Width', field: 'width' },
            { title: 'Thickness', field: 'thickness' },
            { title: 'Rack Position', field: 'rackPosition' },
            { title: 'Box No', field: 'box' },
            { title: 'HS Code', field: 'hsCode' },
            { title: 'Invertory Description 1', field: 'invertoryDesc1' },
            { title: 'Invertory Description 2', field: 'invertoryDesc2' },
            { title: 'Invertory Description 3', field: 'invertoryDesc3' },
            { title: 'Invertory Description 4', field: 'invertoryDesc4' },
        ],
    });*/
  useEffect(() => {
    // props.getWarehouseData();
    //   props.getCategoryData();
    //   props.getsubCategoryData();
    //   props.resetState();
    // props.getAllInventory({
    //     startedDate: "",
    //     endDate: "",
    //     wareHouseId: "",
    //     subCategoryId: "",
    //     categoryId: "",
    // })
  }, []);

  return (
    <div>
      {props.isFetchingwareHouseList ||
      props.isFetchingcategoryList ||
      props.isFetchingsubCategoryList ||
      props.isFetchingInventoryGetdata ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
          </Button>
        </div>
      ) : (
        <div>
          <div className="main">
            {/* {stateValue == true ?  */}

            <div className="sendDiv">
              <Link to="/user/add-inventory">
                <Button className="sendButton"> Back to Search</Button>
              </Link>
            </div>

            <MaterialTable
              title="Inventory Details"
              columns={uniqBy(
                [
                  { title: "Inventory Code", field: "key", editable: "never" },
                  {
                    title: "WareHouse",
                    field: "warehouse.wareHouseName",
                    editable: "never",
                  },
                  {
                    title: "Category",
                    field: "category.categoryName",
                    editable: "never",
                  },
                  {
                    title: "SubCategory",
                    field: "sub_category.subCategoryName",
                    editable: "never",
                  },
                  { title: "Company Name", field: "companyName" },
                  { title: "Inventory Field", field: "inventoryField" },
                  { title: "Product Type", field: "productType" },
                  {
                    title: "Inventory Name",
                    field: "productName",
                    render: (rowData) => (
                      <div
                        style={{
                          backgroundColor:
                            Number(rowData.currentBlnc) <=
                            Number(rowData.minBlnc)
                              ? "#ff8d8d"
                              : Number(rowData.currentBlnc) >=
                                Number(rowData.maxBlnc)
                              ? "#addfac"
                              : "transparent",
                        }}
                      >
                        {" "}
                        {rowData.productName}
                      </div>
                    ),
                  },
                  { title: "Product Price", field: "productPrice" },
                  { title: "Currency", field: "currency" },
                  {
                    title: "Arrival Date",
                    field: "arrival_date",
                    render: (rowData) =>
                      moment(rowData.arrival_date).format("YYYY-MM-DD"),
                  },
                  { title: "Vendor Name", field: "VendorName" },
                  { title: "Current Balance", field: "currentBlnc" },
                  { title: "Unit of Measure", field: "uom" },
                  { title: "Minimum Balance", field: "minBlnc" },
                  { title: "Maximum Balance", field: "maxBlnc" },
                  { title: "Size", field: "invSize" },
                  { title: "Color", field: "invColor" },
                  { title: "Lenght", field: "length" },
                  { title: "Height", field: "height" },
                  { title: "Width", field: "width" },
                  { title: "Thickness", field: "thickness" },
                  { title: "Rack Position", field: "rackPosition" },
                  { title: "Box No", field: "box" },
                  { title: "HS Code", field: "hsCode" },
                  { title: "Invertory Description 1", field: "invertoryDesc1" },
                  { title: "Invertory Description 2", field: "invertoryDesc2" },
                  { title: "Invertory Description 3", field: "invertoryDesc3" },
                  { title: "Invertory Description 4", field: "invertoryDesc4" },
                ],
                "title"
              )}
              // data={(!isUndefined(startDate) && !isUndefined(endDate)) ? wareHouseData.filter(item => item.arrival_date >= startDate && item.arrival_date <= endDate) : wareHouseData}
              data={wareHouseData}
              options={{
                actionsColumnIndex: -1,
                filtering: true,
                exportButton: true,
                paging: true,
                pageSize: 200, // make initial page size
                emptyRowsWhenPaging: false, // To avoid of having empty rows
                pageSizeOptions: [50, 100, 150, 200], // rows selection options
                maxBodyHeight: "600px",
                headerStyle: {
                  position: "sticky",
                  top: 0,
                  color: "#00BBBB",
                  fontWeight: "550",
                  onRowAdd: "none",
                },
              }}
              editable={{
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      if (oldData) {
                        props.updateInventory(newData, oldData);
                      }
                    }, 600);
                  }),
                onRowDelete: (oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      props.deleteInventory(oldData.inventoryId);
                    }, 600);
                  }),
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    addCategoryName: (data) => dispatch(addCategoryName(data)),
    getCategoryData: () => dispatch(getCategoryData()),
    getWarehouseData: () => dispatch(getWarehouseData()),
    getsubCategoryData: () => dispatch(getsubCategoryData()),
    getInventory: (id) => dispatch(getInventory(id)),
    deleteInventory: (inventoryId) => dispatch(deleteInventory(inventoryId)),
    updateInventory: (newData, oldData) =>
      dispatch(updateInventory(newData, oldData)),
    resetState: () => dispatch(resetReducer()),
    getAllInventory: (data) => dispatch(getAllInventory(data)),
    categoryByWareHouse: (data) => dispatch(categoryByWareHouse(data)),
    subCategoryByCategory: (data) => dispatch(subCategoryByCategory(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  warehouseList: state.AddDataReducer.warehouseList,
  categoryList: state.AddDataReducer.categoryList,
  subCategoryList: state.AddDataReducer.subCategoryList,
  inventoryList: state.AddDataReducer.inventoryList,
  isFetchingwareHouseList: state.AddDataReducer.isFetchingwareHouseList,
  isFetchingcategoryList: state.AddDataReducer.isFetchingcategoryList,
  isFetchingsubCategoryList: state.AddDataReducer.isFetchingsubCategoryList,
  isFetchingInventoryGetdata: state.AddDataReducer.isFetchingInventoryAll,
  allInventory: state.AddDataReducer.allInventory,
  categoryByWareHouseValue: state.AddDataReducer.categoryByWareHouseValue,
  subCategoryByCategoryValue: state.AddDataReducer.subCategoryByCategoryValue,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InventoryMaterialTable);
