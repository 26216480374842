import React, { useState } from "react";
import { Container, Form, Row, Col, Button } from "react-bootstrap";
import {
  addHall,
  getHall,
  deleteHall,
  UpdateHall,
} from "./MarqueeDetails.actions";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import { useEffect } from "react";
import Swal from "sweetalert";
import jsPDF from "jspdf";
import { isEmpty } from "lodash";
import logo from "../../../../../Images/logo.png";

const AddHall = (props) => {
  const [hallName, setHallName] = useState("");
  const [hallDescription, setHallDescription] = useState("");
  const [hallFunctionCharges, setHallFunctionCharges] = useState(0);
  const [hallPerPersonCharges, setHallPerPersonCharges] = useState(0);
  const [hallCapacity, setHallCapacity] = useState(0);

  const [submit, setSubmit] = useState(false);

  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  function uploadHall() {
    props.addHall(
      {
        hall_name: hallName,
        hall_desc: hallDescription,
        hall_sitting_capacity: hallCapacity,
        hall_per_function_charges: hallFunctionCharges,
        hall_per_person_charges: hallPerPersonCharges,
      },
      handleVisible,
      handleDangerVisible
    );
  }
  const hallData = isEmpty(props.hallList) ? [] : props.hallList.reverse();
  console.log(hallData);

  /* const [state, setState] = React.useState({
        columns: [
            { title: 'Hall Name', field: 'hall_name' },
            { title: 'Hall Sitting Capacity', field: 'hall_sitting_capacity' },
            { title: 'Hall Per Person Charges', field: 'hall_per_person_charges' },
            { title: 'Hall Per Function Charges', field: 'hall_per_function_charges' },
            { title: 'Hall Description', field: 'hall_desc' },
        ],
    });*/
  const clearStates = () => {
    setHallName();
    setHallDescription();
    setSubmit(false);
  };
  const handleVisible = () => {
    setAlertVisible(true);
    clearStates();
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: "Added successfully...",
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = () => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: "Something went wrong...",
      icon: "error",
      button: "Ok",
    });
  };

  return (
    <>
      {props.isFetchingHallData || props.isAddingHallData ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
          </Button>
        </div>
      ) : (
        <Container fluid>
          <Form>
            <Row>
              <Col lg="3" md="12" xs="12">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Hall Name *</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Hall Name...."
                    onChange={(e) => setHallName(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col lg="3" md="12" xs="12">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Sitting Capacity *</b>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Sitting Capacity:...."
                    onChange={(e) => setHallCapacity(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col lg="3" md="12" xs="12">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Function Charges *</b>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Function Charges:...."
                    onChange={(e) => setHallFunctionCharges(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col lg="3" md="12" xs="12">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Person Charges *</b>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Person Charges:...."
                    onChange={(e) => setHallPerPersonCharges(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col xl="12" lg="12" md="12" sm="12" xs="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Enter Hall Description:</b>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Hall Description....."
                    onBlur={(e) => setHallDescription(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>

            <div className="sendDiv">
              {isEmpty(hallName) ||
              hallCapacity == 0 ||
              hallFunctionCharges == 0 ||
              hallPerPersonCharges == 0 ? (
                <Button
                  className="sendButton"
                  style={{ backgroundColor: "black" }}
                  onClick={() => {
                    uploadHall();
                    setSubmit(true);
                  }}
                  disabled
                >
                  Save
                </Button>
              ) : (
                <Button
                  className="sendButton"
                  onClick={() => {
                    uploadHall();
                    setSubmit(true);
                  }}
                >
                  Save
                </Button>
              )}
            </div>
          </Form>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addHall: (data, handleVisible, handleDangerVisible) =>
      dispatch(addHall(data, handleVisible, handleDangerVisible)),
    getHall: () => dispatch(getHall()),
    UpdateHall: (newData, oldData) => dispatch(UpdateHall(newData, oldData)),
    deleteHall: (hall_id) => dispatch(deleteHall(hall_id)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  isFetchingHallData: state.MarqueeReducer.isFetchingHallData,
  isAddingHallData: state.MarqueeReducer.isAddingHallData,
  hallList: state.MarqueeReducer.hallList,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddHall);
