import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import "./add-details.css";
import { connect } from "react-redux";
import PurchaseDetail from "./PurchaseDetail";
import AddPurchase from "./AddPurchase";
import PurchaseReport from "./PurchaseReport";
import { isNull } from "lodash";


const PurchaseOrder = (props) => {
    const permissions = JSON.parse(localStorage.getItem("permissions"));

    return (
        <>
            <Tabs
                defaultActiveKey={!isNull(permissions) && permissions.some(p => p['sub_id'] === 11) ?
                    "PurchaseList" : !isNull(permissions) && permissions.some(p => p['sub_id'] === 12) ?
                        "AddPurchase" : !isNull(permissions) && permissions.some(p => p['sub_id'] === 13) ?
                            "PurchaseReport" : ""}
                id="AddPurchase"
                className="mb-3"
                unmountOnExit={true}>
                <Tab
                    eventKey="PurchaseList"
                    title="Purchase Order Details"
                    unmountOnExit={true}
                    disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] ===11) ? false : true}>
                    <PurchaseDetail />
                </Tab>
                <Tab
                    eventKey="AddPurchase"
                    title="Add Purchase Order"
                    unmountOnExit={true}
                    disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 12) ? false : true}>
                    <AddPurchase />
                </Tab>

                <Tab
                    eventKey="PurchaseReport"
                    title="Purchase Order Report"
                    unmountOnExit={true}
                    disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 13) ? false : true}>
                    <PurchaseReport />
                </Tab>
            </Tabs>
        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
    };
};
const mapStateToProps = (state, ownProps) => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PurchaseOrder);
