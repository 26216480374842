import React, { useEffect, useRef, useState } from "react";
import {
  Container,
  Button,
} from "react-bootstrap";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import Modal from 'react-bootstrap/Modal';
import {isNull} from 'lodash';
import {useHistory} from 'react-router-dom';
import {
  updateVoucherStatus,
  updateVoucherStatus1,
  getAdminVoucher,
  updateVoucher,
  getVoucherLogs,
  GetVoucherByID,
} from "./Voucher.action";
import { baseURL, headers } from "../../../../global/api";
import moment from "moment";
const ViewVoucher = (props) => {
  const history = useHistory();
  const permissions = JSON.parse(localStorage.getItem("permissions"));
  const [open, setOpen] = useState(false)
  useEffect(() => {
    //  props.getAdminVoucher();
  }, []);
  // const VoucherData = props.voucherListUser;
  //console.log(reverse(VoucherData), "eeeee")
  const handleClose = () => {
    setOpen(false)
  }
  const tableRef = useRef();
  const tableRefInner = useRef();
  
  function updateTableQuery() {
    tableRef.current.onQueryChange()
  };
  function updateTableQueryInner() {
    tableRefInner.current.onQueryChange()
  };

  return (
    <>
      {props.isFetchingadminVoucherList ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            Loading...
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <MaterialTable
              tableRef={tableRef}
              title="Voucher View"
              columns={[
                { title: "Voucher Number", field: "voucherManualId" },
                { title: "Account Title", field: "cashAccTittle", },
                { title: "Date", field: "voc_date", render: rowData => moment(rowData.voc_date).format("DD-MM-YYYY") },
                { title: "Status", field: "status" },
                { title: "CR/DR", field: "credit_or_debit", render: rowData => rowData.credit_or_debit == 1 ? "Credit" : rowData.credit_or_debit == 2 ? "Debit" : "" },

              ]}
              data={(query) =>
                new Promise((resolve, reject) => {
                  console.log(query, "qr")
                  let url = `${baseURL}/voucher/get_all_voucher_user?`;
                  url += "size=" + query.pageSize;
                  url += "&page=" + (query.page + 1);
                  url += "&search=" + query.search;
                  fetch(url, {
                    method: 'GET',
                    headers: headers,
                  })
                    .then((response) => response.json())
                    .then((result) => {
                      resolve({
                        data: result.data,
                        page: result.page - 1,
                        totalCount: result.total_results,
                      });
                    });
                })
              }
              detailPanel={(rowData) => {
                console.log(rowData, "rowwwwwwwwww")
                return (
                  <MaterialTable
                  tableRefInner={tableRefInner}
                    title="Voucher Details"
                    columns={[
                      { title: "Account Title", field: "accountTittle", editable: 'never' },
                      // { title: "Voucher Date", field: "voucherDate", render: rowData => moment(rowData.voucherDate).format("L") },
                      { title: "Voucher Amount", field: "voucherAmount", render: rowData => rowData.voucherAmount.toLocaleString() },
                      { title: "DR/CR", field: "accountType", render: rowData => rowData.accountType == 1 ? "Credit" : rowData.accountType == 2 ? "Debit" : "", editable: 'never' },
                      { title: "Bill No", field: "billNo" },
                      { title: "Voucher Description", field: "voucherDescription" },
                      { title: "Tracking No", field: "trackingNo" },
                      { title: "Destination", field: "destination" },
                      { title: "Packages", field: "packages" },
                      { title: "Weight", field: "weight" },
                      { title: "Weight Charges", field: "weightCharges" },
                      { title: "Rate First Kg", field: "rateFirstKg" },
                      { title: "Rate Add Kg", field: "rateAddKg" },
                      { title: "Rate Flat", field: "rateFlat" },
                      { title: "Service", field: "service" },

                    ]}
                    data={rowData.voucher_data}
                    options={{
                      actionsColumnIndex: -1,
                      toolbar: false,
                      exportButton: true,
                      sorting: true,
                      // filtering:true,
                      paging: true,
                      maxBodyHeight: '600px',
                      pageSize: 200, // make initial page size
                      emptyRowsWhenPaging: false, // To avoid of having empty rows
                      pageSizeOptions: [50, 100, 150, 200],
                      headerStyle: {
                        position: "sticky",
                        top: 0,
                        color: "#00BBBB",
                        fontWeight: "550",
                        onRowAdd: "none",
                      },
                    }}
                    // editable={{
                    //   // isEditable: rowData => false,
                    //   isEditHidden: (rowData) =>!isNull (permissions) && permissions.some((p) => p["cat_id"] === 68)?false:true,

                    //   onRowUpdate: (rowData) =>
                    //     new Promise((resolve) => {
                    //       setTimeout(() => {
                    //         resolve();
                    //         {
                    //           props.getVoucherById(newData, oldData,updateTableQuery)
                    //         }
                    //       }, 600);
                    //     }),
                    // }}
                  />
                );
              }}
              actions={[
                (rowData) => {
                  return {
                    icon: PlaylistAddCheckIcon,
                    disabled: rowData.is_edit==true?false:true,
                    hidden:!isNull (permissions) && permissions.some((p) => p["cat_id"] === 68)?false:true,
                    onClick: (event, rowData) => {
                      console.log(rowData,"rowdddddddd")
                      setOpen(true)
                      props.getVoucherLogs(rowData.voucherId,updateTableQuery);
                    },
                  };
                },
                (rowData) => {
                  return {
                    icon: 'edit',
                    // disabled: rowData.is_edit==true?false:true,
                    hidden:!isNull (permissions) && permissions.some((p) => p["cat_id"] === 68)?false:true,
                    onClick: (event, rowData) => {
                      console.log(rowData,"rowdddddddd")
                      // setOpen(true)
                      props.getVoucherById(rowData.voucherId);
                      history.push('/user/edit-voucher')
                    },
                  };
                },
              ]}
              options={{
                actionsColumnIndex: -1,
                // filtering: true,
                exportButton: true,
                paging: true,
                maxBodyHeight: '600px',
                pageSize: 200, // make initial page size
                emptyRowsWhenPaging: false, // To avoid of having empty rows
                pageSizeOptions: [50, 100, 150, 200],
                headerStyle: {
                  position: "sticky",
                  top: 0,
                  color: "#00BBBB",
                  fontWeight: "550",
                  onRowAdd: "none",
                },
              }}
              onRowClick={(event, rowData, togglePanel) => togglePanel()}
            />
          </div>
          <Modal
            show={open} onHide={handleClose}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            // centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Voucher Logs
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            {props.isFetchingGetVoucherLogs ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            Loading...
          </Button>
        </div>
      ) : (
            <MaterialTable
                    title="Voucher Details"
                    columns={[
                      { title: "User Name", field: "user.username", editable: 'never' },
                      // { title: "Voucher Date", field: "voucherDate", render: rowData => moment(rowData.voucherDate).format("L") },
                      { title: "Voucher Amount", field: "voucherAmount"},
                      { title: "Logs Type", field: "new_or_old"},
                      // { title: "DR/CR", field: "accountType", render: rowData => rowData.accountType == 1 ? "Credit" : rowData.accountType == 2 ? "Debit" : "", editable: 'never' },
                      { title: "Bill No", field: "billNo" },
                      { title: "Voucher Description", field: "voucherDescription" },
                      { title: "Tracking No", field: "trackingNo" },
                      { title: "Destination", field: "destination" },
                      { title: "Packages", field: "packages" },
                      { title: "Weight", field: "weight" },
                      { title: "Weight Charges", field: "weightCharges" },
                      { title: "Rate First Kg", field: "rateFirstKg" },
                      { title: "Rate Add Kg", field: "rateAddKg" },
                      { title: "Rate Flat", field: "rateFlat" },
                      { title: "Service", field: "service" },

                    ]}
                    data={props.voucherLogs}
                    options={{
                      actionsColumnIndex: -1,
                      toolbar: false,
                      exportButton: true,
                      sorting: true,
                      // filtering:true,
                      paging: true,
                      maxBodyHeight: '600px',
                      pageSize: 200, // make initial page size
                      emptyRowsWhenPaging: false, // To avoid of having empty rows
                      pageSizeOptions: [50, 100, 150, 200],
                      headerStyle: {
                        position: "sticky",
                        top: 0,
                        color: "#00BBBB",
                        fontWeight: "550",
                        onRowAdd: "none",
                      },
                    }}

                  />)}
            </Modal.Body>
            {/* <Modal.Footer>
              <Button onClick={handleClose}>Close</Button>
            </Modal.Footer> */}
          </Modal>
        </Container>

      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAdminVoucher: () => dispatch(getAdminVoucher()),
    updateVoucherStatus: (data, updateTableQuery) => dispatch(updateVoucherStatus(data, updateTableQuery)),
    updateVoucherStatus1: (data, updateTableQuery) => dispatch(updateVoucherStatus1(data, updateTableQuery)),
    updateVoucher: (newData, oldData,updateTableQuery) => dispatch(updateVoucher(newData, oldData,updateTableQuery)),
    getVoucherById: (id) => dispatch(GetVoucherByID(id)),
    getVoucherLogs: (id) => dispatch(getVoucherLogs(id))
  };
};
const mapStateToProps = (state, ownProps) => ({
  voucherListUser: state.VoucherReducer.voucherListUser,
  isFetchingadminVoucherList: state.VoucherReducer.isFetchingadminVoucherList,
  isFetchingGetVoucherLogs:state.VoucherReducer.isFetchingGetVoucherLogs,
  voucherLogs:state.VoucherReducer.voucherLogs,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewVoucher);
