const initialState = {
    isFetchingArticle: false,
    getArticleList: [],
    isFetchingArticleData: false,
    getArticleListData: [],
    addConversionCost: [],
    isAddingConversionCost: false
}


const COnversionCostReducer = (state = initialState, action) => {
    switch (action.type) {

            case "REQUEST_GET_ARTICLE":
                return { ...state, isFetchingArticle: true };
    
            case "SUCCESS_GET_ARTICLE":
                return {
                    ...state, isFetchingArticle: false,
                    getArticleList: action.payload
                };
    
            case "ERROR_GET_ARTICLE":
                return { ...state, isFetchingArticle: false };


                case "REQUEST_GET_ARTICLE_DATA":
                    return { ...state, isFetchingArticleData: true };
        
                case "SUCCESS_GET_ARTICLE_DATA":
                    return {
                        ...state, isFetchingArticleData: false,
                        getArticleListData: action.payload
                    };
        
                case "ERROR_GET_ARTICLE_DATA":
                    return { ...state, isFetchingArticleData: false };



                    case "REQUEST_ADD_CONVERSION_COST":
                        return { ...state, isAddingConversionCost: false };
            
                    case "SUCCESS_ADD_CONVERSION_COST":
                        return {
                            ...state, isAddingConversionCost: true,
                            addConversionCost: action.payload
                        };
            
                    case "ERROR_ADD_CONVERSION_COST":
                        return { ...state, isAddingConversionCost: false };


        default:
            return state;
    }
}

export default COnversionCostReducer;
