import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import MaterialTable from 'material-table';
import moment from 'moment'
import UserModal from './UserModal';
import { Redirect } from 'react-router-dom';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { getUsers, postUsersDetails, deleteUser, updateUser, selectedUser, updateUsersStatus } from './users.actions';
import { isEmpty } from 'lodash';
import BlockIcon from "@mui/icons-material/Block";
import TaskAltIcon from "@mui/icons-material/TaskAlt";

const UserDetailView = (props) => {
  const usersData = props.usersList;
  console.log(usersData, "1111111111111111111111data");
  const [open, setOpen] = React.useState(false);
  /* const [state, setState] = React.useState({
        columns: [
            { title: 'UserName', field: 'username' },
            { title: 'First Name', field: 'name' },
            //  { title: 'Last Name', field: 'lastName' },
            { title: 'Password', field: 'password' },
        ],

        // data: [
        //     { firstName: 'Zahra', lastName: 'Nabeel', email: 'zahra@gmail.com', phone: '028393263772', city: 'sialkot' }
        //   ]
    });*/

  useEffect(() => {
    props.getUsers();
  }, []);

  const [callEdit, setcallEdit] = React.useState(false);
  if (callEdit) {
    return <Redirect push to="/admin/view-user" />;
  }

    return (
        <>
            {open && <UserModal open={open} />}
            <MaterialTable
                title="User Detail View"
                columns={[
                    { title: 'User Id', field: 'id' },
                    { title: 'User Name', field: 'username' },
                    { title: 'Last login', field: 'last_login' },
                    { title: 'Created At', field: 'createdAt',  render: rowData =>  moment(rowData.createdAt).format("YYYY-MM-DD"),editable: 'never' }, 
                    // { title: 'First Name', field: 'name' },
                    // //  { title: 'Last Name', field: 'lastName' },
                    // { title: 'Password', field: 'password' },
                ]}
               
                data={usersData}
                //data={state.data}
                actions={[
                    // (rowData) => ({
                    //   disabled:
                    //     Number(rowData.userStatusId) == 2 ||
                    //     Number(rowData.userStatusId) == 4
                    //       ? true
                    //       : false,
                    //   icon: () => <CheckCircleIcon />,
                    //   hidden: Checkpermissions(
                    //     "permissions_cat_name",
                    //     "User Edit Status"
                    //   ),
                    //   tooltip: "Approve User",
                    //   onClick: (event, rowData) => {
                    //     props.updateUserStatus(rowData.id, { userStatusId: 2 });
                    //   },
                    // }),
                    (rowData) =>
                      Number(rowData.active) == false
                        ? {
                            // disabled:
                            //   Number(rowData.active) == 1 ? true : false,
                            icon: () => <TaskAltIcon />,
                            // hidden: Checkpermissions(
                            //   "permissions_cat_name",
                            //   "User Edit Status"
                            // ),
                            tooltip: "UnBlock User",
                            onClick: (event, rowData) => {
                              props.updateUsersStatus(rowData.id, {
                                active: 1,
                              });
                            },
                          }
                        : {
                            // disabled:
                            //   Number(rowData.active) == 1 ? true : false,
                            icon: () => <BlockIcon />,
                            // hidden: Checkpermissions(
                            //   "permissions_cat_name",
                            //   "User Edit Status"
                            // ),
                            tooltip: "Block User",
                            onClick: (event, rowData) => {
                              props.updateUsersStatus(rowData.id, {
                                active: 0,
                              });
                            },
                          },
                  ]}
                options={{
                    actionsColumnIndex: -1,
                    paging:true,
                    pageSize:200,       // make initial page size
                    emptyRowsWhenPaging: false,   // To avoid of having empty rows
                    pageSizeOptions:[50,100,150,200], 
                    headerStyle: {
                        position: 'sticky', top: 0,
                        color: '#00BBBB',
                        fontWeight: '550',
                        onRowAdd: 'none',
                    }
                }}
                //  editable={{
                //     onRowDelete: (oldData) =>
                //         new Promise((resolve) => {
                //             console.log(oldData,"olddddddddddddddd")
                //             setTimeout(() => {
                //                 resolve();
                //                 props.deleteUser(oldData.id);
                //             }, 600);
                //         }),
                // }}
            />
        </>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        // postUsersDetails: (data) => dispatch(postUsersDetails(Object.assign(data, {admin_id: "123"}))),
        // getUsers: () => dispatch(getUsers()),
        // updateUser: (data, oldData) => dispatch(updateUser(data, oldData)),
        // deleteUser: (userId) => dispatch(deleteUser(userId)),
        selectedUser: (data) => dispatch(selectedUser(data)),
        getUsers: () => dispatch(getUsers()),
        postUsersDetails: (data) => dispatch(postUsersDetails(data)),
        updateUsersStatus: (userId,data) => dispatch(updateUsersStatus(userId,data)),
        updateUser: (newData, oldData) => dispatch(updateUser(newData, oldData))
    }
}

const mapStateToProps = (state, ownProps) => ({
  usersList: state.usersReducer.usersList,
});

export default connect(mapStateToProps, mapDispatchToProps)(UserDetailView);
