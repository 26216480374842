import API from "../../../../../global/api";

export const requestGetAllInventoryData = () => {
    return {
        type: "REQUEST_GET_ALL_INVENTORY_DATA",
    };
};
export const successGetAllInventoryData = (data) => {
    return {
        type: "SUCCESS_GET_ALL_INVENTORY_DATA",
        payload: data,
    };
};
export const errorGetAllInventoryData = () => {
    return {
        type: "ERROR_GET_ALL_INVENTORY_DATA",
    };
};

export const getAllInventoryData = () => {
    return (dispatch) => {
        dispatch(requestGetAllInventoryData());
        API.get(`/inventory/all-inventory-data`).then((res) => {
            let getData = res.data;
            dispatch(successGetAllInventoryData(getData));
        }).catch((error) => {
            dispatch(errorGetAllInventoryData());
        })
    };
};



export const requestGetArticle = () => {
    return {
        type: "REQUEST_GET_ARTICLE",
    };
};
export const successGetArticle = (data) => {
    return {
        type: "SUCCESS_GET_ARTICLE",
        payload: data,
    };
};
export const errorGetArticle = () => {
    return {
        type: "ERROR_GET_ARTICLE",
    };
};

export const getArticle = () => {
    return (dispatch) => {
        dispatch(requestGetArticle());
        API.get(`/article/get-article`).then((res) => {
            let getData = res.data;
            dispatch(successGetArticle(getData));
        }).catch((error) => {
            dispatch(errorGetArticle());
        })
    };
};



export const requestAddArticleMaterial = () => {
    return {
        type: "REQUEST_ADD_ARTICLE_MATERIAL",
    };
};
export const successAddArticleMaterial = (data) => {
    return {
        type: "SUCCESS_ADD_ARTICLE_MATERIAL",
        payload: data,
    };
};
export const errorAddArticleMaterial = () => {
    return {
        type: "ERROR_ADD_ARTICLE_MATERIAL",
    };
};

export const addArticleMaterial = (data) => {
    return (dispatch) => {
        dispatch(requestAddArticleMaterial());
        API.post(`/article/add-material-cost`, data).then((res) => {
            let getData = res.data;
            dispatch(successAddArticleMaterial(getData));
        }).catch((error) => {
            dispatch(errorAddArticleMaterial());
        })
    };
};