const initialState = {
  isLogin: false,
  loginAdminId: "",
  unableLogin: true,
  isWrongCredentials: false,
  moduleKey: ''
};

const userReducerAdmin = (state = initialState, action) => {
  switch (action.type) {

    case "LOGIN_ADMIN_REQUEST":
      return {
        ...state,
        isLogin: true,
        isWrongCredentials: false
      };

    case "LOGIN_ADMIN_SUCCESS":
      console.log(action.payload.adminId, "ggttyy")
      return {
        ...state,
        isLogin: false,
        isWrongCredentials: false,
        loginAdminId: action.payload.adminId
      };

    case "LOGIN_ADMIN_ERROR":
      return {
        ...state,
        isLogin: false,
        isWrongCredentials: true
      };


      case "SET_MODULE_KEY":
        return { ...state, moduleKey: action.payload };
    default:
      return state;
    }
};
export default userReducerAdmin;
