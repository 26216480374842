
import React, { Component } from "react";
import { useLocation, Route, Switch } from "react-router-dom";
import { connect } from 'react-redux';
import AdminNavbar from "../components/Navbars/AdminNavbar";
//import Footer from "../components/Footer/Footer";
import Sidebar from "../components/Sidebar/Sidebar";
import FixedPlugin from "../components/FixedPlugin/FixedPlugin.js";
import {isEmpty, isUndefined} from 'lodash'
// import {dashboardInventoryRoutes, dashboardAccountRoutes} from "../routes";

import {dashboardInventoryRoutes, dashboardAccountRoutes, dashboardProductionRoutes, HRRoutes, BackUp, MarqueeRoutes} from "../routes";


import sidebarImage from "../../../Images/sidebar-3.jpg";

function ResponsiveDrawer(props) {
  const [image, setImage] = React.useState(sidebarImage);
  const [color, setColor] = React.useState("black");
  const [hasImage, setHasImage] = React.useState(true);
  const location = useLocation();
  const mainPanel = React.useRef(null);
  const clickUser = sessionStorage.getItem("clickUser");
  const getSelectedRoutes = () => {
    if(props.moduleKey == 'UserInventory' || window.localStorage.getItem('module_key') == 'user_inventory') return dashboardInventoryRoutes;
    else if (props.moduleKey == 'UserAccounts' || window.localStorage.getItem('module_key') == 'user_accounts') return dashboardAccountRoutes;
    else if (props.moduleKey == "Production" || window.localStorage.getItem('module_key') == 'user_production') return dashboardProductionRoutes;
    else if (props.moduleKey == 'UserHRModules' || window.localStorage.getItem('module_key') == 'user_hr') return HRRoutes;
    else if (props.moduleKey == 'BackUpModule' || window.localStorage.getItem('module_key') == 'user_backUpModule') return BackUp;
    else if (props.moduleKey == 'MarqueeModule' || window.localStorage.getItem('module_key') == 'user_marqueeModule') return MarqueeRoutes;
    // else if (props.moduleKey == "") return (
    //   // window.localStorage.clear(),
    //   // window.location.replace('/login')
    // );
  }
  const getRoutes = (z) => {
    return z.map((prop, key) => {
     if(isEmpty(prop.children) ) {
      if (prop.layout =="/user") {
        return (
          <Route
            path={prop.layout + prop.path}
            render={(props) => <prop.component {...props} />}
            key={key}
          />
        );
      }
       else {
        return null;
      }
    }
    else {
      let xy= prop.children;
      xy.map((prop1, key1) => {
  
        if (prop1.layout == "/user") {
          return (
            <Route
              path={prop1.layout + prop1.path}
              render={(props) => <prop1.component {...props} />}
              key={key1}
            />
          );
        }
         else {
          return null;
        }
    })
  }
    })
  };

  const getAccountRoutes = (AccountRoutes) => {
    return AccountRoutes.map((prop, key) => {
      if (prop.layout === "/acc") {
        return (
          <Route
            path={prop.layout + prop.path}
            render={(props) => <prop.component {...props} />}
            key={key}
          />
        );
      }
       else {
        return null;
      }
    });
  };
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainPanel.current.scrollTop = 0;
    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      var element = document.getElementById("bodyClick");
      element.parentNode.removeChild(element);
    }
  }, [location]);
  return (
    <>
      <div className="wrapper">
        <Sidebar color={color} image={hasImage ? image : ""} routes={getSelectedRoutes()} />
        <div className="main-panel" ref={mainPanel}>
          <AdminNavbar />
          <div className="content">
           { <Switch>{getRoutes(getSelectedRoutes())}</Switch>}
           {/* { <Switch>{getAccountRoutes(AccountRoutes)}</Switch>} */}
           {/* <Switch>
             <Route exact path="/user/dashboard" render={() => <CommunityProperty/>}/>
             <Route exact path="/user/test" render={() => <AcknowledgementForm/>}/>
           </Switch> */}
          </div>
          {/* <Footer /> */}
        </div>
      </div>
      <FixedPlugin
        hasImage={hasImage}
        setHasImage={() => setHasImage(!hasImage)}
        color={color}
        setColor={(color) => setColor(color)}
        image={image}
        setImage={(image) => setImage(image)}
      />
    </>
  );
}

const mapDispatchToProps = dispatch => {
  return {
  }
}

const mapStateToProps = (state, ownProps, number) => ({
 moduleKey: state.accountReducer.moduleKey,
})

export default connect(mapStateToProps, mapDispatchToProps)(ResponsiveDrawer);
//export default ResponsiveDrawer;