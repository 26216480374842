import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, FormGroup, Alert, Tab, Tabs } from "react-bootstrap";
import { ModalBody, Modal } from "reactstrap";
import "./add-details.css";
import {
    getProductByDemand, getPurchase, addPurchase, getApproveDemand, searchPurchaseReport, getApprovePurchase, purchaseById, purchaseFromDemandCall
} from "./AddDetails.actions";
import { connect } from "react-redux";
import { isEmpty, find } from "lodash";
import moment from 'moment';
import Spinner from 'react-bootstrap/Spinner';
import WareHouseModal from "./WareHouseModal";
import MaterialTable from 'material-table';
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { IconButton } from "@material-ui/core";
import Select from 'react-select';
import Swal from 'sweetalert';


const PurchaseReport = (props) => {

    const [inputField, setInputField] = useState([{
        inventoryId: "-1",
        productQuantity: 0,
        productQuantityCount: '',
        productPrice: 0,
        marketPrice: 0,
        inventoryLabel: ""
    }]);

    const [maximumQuantity, setMaximumQuantity] = useState(0);
    const [demandName, setDemandName] = useState();
    const [purchaseName, setPurchaseName] = useState();
    const [purchaseDescription, setPurchaseDescription] = useState();
    const [supplier, setSupplier] = useState();
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [demandNameValue, setDemandNameValue] = useState();
    const [demandNameLabel, setDemandNameLabel] = useState();
    const [purchaseNameValue, setPurchaseNameValue] = useState();
    const [purchaseNameLabel, setPurchaseNameLabel] = useState();
    const [purchaseTax, setPurchaseTax] = useState();
    const [ManualPurchaseNumber, setManualPurchaseNumber] = useState();
    const [saveId, setSaveId] = useState([]);
    const [visibleAlert, setAlertVisible] = useState(false);

    const handleVisible = () => {
        setAlertVisible(true)
        setTimeout(() => {
            setAlertVisible(false)
        }, 9000);
        Swal({
            title: "Data Not Found",
            text: "Data Not Found",
            icon: "info",
            button: "Ok",
        });
    }

    const handleChangeGetDemand = (selectedOption) => {
        setDemandNameValue(selectedOption.value);
        setDemandNameLabel(selectedOption.label);

        props.purchaseFromDemandCall(selectedOption.value);
    };
    // const getPurchaseList = props.approvePurchaseList.map((x) => {let data = {value: x.purchaseOrderId, label: x.purchaseName}; return data});
    // const getDemandList1 = [isEmpty(props.purchaseListById) ? [] : { value: props.purchaseListById[0].purchase_data[0].create_demand.demandId, label: props.purchaseListById[0].purchase_data[0].create_demand.demandName }];/*
    const getDemandList1 = props.demandList.map((x) => { let data = { value: x.demandId, label: x.demandName }; return data });

    const getPurchaseList = !isEmpty(props.purchaseFromDemandData) && props.purchaseFromDemandData.map((x) => { let data = { value: x.purchaseOrderId, label: x.purchaseOrderId + " / " + x.purchaseName }; return data });

    const handleChangeGetPurchase = (selectedOption) => {
        setPurchaseNameValue(selectedOption.value);
        setPurchaseNameLabel(selectedOption.label);
        props.purchaseById({ purchaseOrderId: selectedOption.value });
    };

    const exportPDF = () => {
        props.searchPurchaseReport({
            demandId: demandNameValue,
            startedDate: !isEmpty(startDate) ? startDate : "",
            endDate: !isEmpty(endDate) ? endDate : '',
            purchaseOrderId: purchaseNameValue
        }, demandNameLabel, purchaseNameLabel, startDate, endDate, handleVisible);
        setStartDate("");
        setEndDate("");
        setDemandNameLabel("");
        setPurchaseNameLabel("");
    }

    const getDemandList = props.approveDemandList.map((x) => { let data = { value: x.demandId, label: x.demandId + " / " + x.demandName }; return data });

    const inventory = props.porductListDemand.map((x) => { let data = { value: x.inventoryId, label: x.inventory.warehouse.whKey + "-" + x.inventory.category.cateKey + "-" + x.inventory.invKey + " " + x.productName }; return data })

    const getDemand = (selectedOption) => {
        props.getProductByDemand(selectedOption.value);
        setDemandName(selectedOption.value);
        setDemandNameLabel(selectedOption.label);
    };

    const handleChangeDemand = (e, index, maxVal, type) => {
        if (Number(e.target.value) <= maxVal) {
            let z = find(props.porductListDemand, x => x.inventoryId == e.value);
            const { name, value } = e;
            const list = [...inputField];
            if (type == 'intname') list[index]['inventoryId'] = e.value;
            if (type == 'intname') list[index]['productQuantity'] = Number(z.productQuantity);
            if (type == 'intquantity') list[index]['productQuantity'] = e.target.value;
            // if (type == 'intprice') list[index]['marketPrice'] = e.target.value;

            setInputField(list);
        }
        else {
            alert("value out of range");
        }
    };
    const handleChange = (e, index, type) => {
        let z = find(props.porductListDemand, x => x.inventoryId == e.value);
        const { name, value } = e;
        const list = [...inputField];
        if (type == 'intname') list[index]['inventoryId'] = e.value;
        if (type == 'intname') list[index]['inventoryLabel'] = e.label;
        if (type == 'intname') list[index]['productQuantityCount'] = Number(z.productQuantity);
        if (type == 'intname') list[index]['productPrice'] = Number(z.inventory.productPrice);
        if (type == 'intname') setMaximumQuantity(Number(z.productQuantity));
        if (type == 'intquantity') list[index]['productQuantity'] = e.target.value;
        if (type == 'intprice') list[index]['marketPrice'] = e.target.value;
        if (type == 'intname') setSaveId(e.value);
        setInputField(list);
        //list[index]['productQuantity'] = "";
    };

    useEffect(() => {
        props.getPurchase();
        props.getApprovePurchase();
        props.getApproveDemand();
    }, []);
    return (
        <>
                    {props.isFetchningApproveDemandList || props.isFetchingPurchaseFromDemand ? (
                        <div className="loader-div">
                            <Button variant="info" disabled>
                                <Spinner
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    variant="info"
                                />
                            </Button>
                        </div>
                    ) : (
                        <Container fluid>
                            <div className="main">
                                <Row>
                                    <Col xl="6" lg="6" md="6" sm="6">
                                        <FormGroup>
                                            <Form.Label>
                                                <b>Start Date :</b>
                                            </Form.Label>
                                            <Form.Control
                                                type="date"
                                                value={startDate}
                                                onChange={(e) => setStartDate(e.target.value)}
                                            >
                                            </Form.Control>
                                        </FormGroup>
                                    </Col>
                                    <Col xl="6" lg="6" md="6" sm="6">
                                        <FormGroup>
                                            <Form.Label>
                                                <b>End Date</b>
                                            </Form.Label>
                                            <Form.Control
                                                type="date"
                                                value={endDate}
                                                onChange={(e) => setEndDate(e.target.value)}
                                            >
                                            </Form.Control>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl="6" lg="6" md="12" sm="12">
                                        <Form.Group>
                                            <Form.Label>
                                                <b>Demand A/M</b>
                                            </Form.Label>
                                            {/* {isEmpty(purchaseNameValue) ? <Select
                                                placeholder="Select Demand..."
                                            /> :  */}
                                            <Select
                                                value={{ label: demandNameLabel }}
                                                placeholder="Select Demand..."
                                                onChange={(e) => handleChangeGetDemand(e)}
                                                options={!isEmpty(getDemandList) ? getDemandList.reverse() : []}
                                            />
                                            {/* }  */}
                                        </Form.Group>
                                    </Col>

                                    <Col xl="6" lg="6" md="12" sm="12">
                                        <Form.Group>
                                            <Form.Label>
                                                <b>Purchase Order</b>
                                            </Form.Label>
                                            <Select
                                            value={{label: purchaseNameLabel}}
                                                isDisabled={isEmpty(getPurchaseList)}
                                                placeholder="Select Purchase..."
                                                onChange={(e) => handleChangeGetPurchase(e)}
                                                options={!isEmpty(getPurchaseList) ? getPurchaseList.reverse() : []}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <div className="sendDiv">
                                    {isEmpty(demandNameLabel) || isEmpty(purchaseNameLabel) ? <Button disabled style={{ marginLeft: "2%", color: "white", width: "20%", backgroundColor: "black", border: "1px solid black" }}>Generate Purchase Report</Button> : props.isFetchingSearchPurchaseList ? <Button className="sendButton">Downloading PDF</Button> : <Button className="sendButton" onClick={() => exportPDF()}>Generate Purchase Report</Button>}
                                </div>
                            </div>
                        </Container>
                    )}
        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
        getPurchase: () => dispatch(getPurchase()),
        getProductByDemand: (demandId) => dispatch(getProductByDemand(demandId)),
        getApproveDemand: () => dispatch(getApproveDemand()),
        searchPurchaseReport: (data, purchaseName, startDate, endDate, demandName, handleVisible) => dispatch(searchPurchaseReport(data, purchaseName, startDate, endDate, demandName, handleVisible)),
        getApprovePurchase: () => dispatch(getApprovePurchase()),
        purchaseById: (data) => dispatch(purchaseById(data)),
        purchaseFromDemandCall: (data) => dispatch(purchaseFromDemandCall(data)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    warehouseList: state.AddDataReducer.warehouseList,
    categoryList: state.AddDataReducer.categoryList,
    subCategoryList: state.AddDataReducer.subCategoryList,
    inventoryList: state.AddDataReducer.inventoryList,
    demandList: state.AddDataReducer.demandList,
    allInventory: state.AddDataReducer.allInventory,
    isFetchingDemand: state.AddDataReducer.isFetchingDemand,
    isFetchingInventoryAll: state.AddDataReducer.isFetchingInventoryAll,
    porductListDemand: state.AddDataReducer.porductListDemand,
    purchaseList1: state.AddDataReducer.purchaseList1,
    approveDemandList: state.AddDataReducer.approveDemandList,
    isAddingPurchase: state.AddDataReducer.isAddingPurchase,
    notAddPurchase: state.AddDataReducer.notAddPurchase,
    approvePurchaseList: state.AddDataReducer.approvePurchaseList,
    purchaseListById: state.AddDataReducer.purchaseListById,
    isFetchingSearchPurchaseList: state.AddDataReducer.isFetchingSearchPurchaseList,
    isFetchingPurchaseList: state.AddDataReducer.isFetchingPurchaseList,
    purchaseFromDemandData: state.AddDataReducer.purchaseFromDemandData,
    isFetchingProductByDemand: state.AddDataReducer.isFetchingProductByDemand,
    isFetchningApproveDemandList: state.AddDataReducer.isFetchningApproveDemandList,
    isFetchingPurchaseFromDemand: state.AddDataReducer.isFetchingPurchaseFromDemand

});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PurchaseReport);
