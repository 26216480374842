import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Container,
  Button,
  FormGroup,
  Alert,
} from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty, isNull } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import "jspdf-autotable";
import {
  addSubEvent,
  getSubEvent,
  getEvent,
  updateSubEvent,
  deleteSubEvent,
  getDashboardMarquee,
} from "./MarqueeDetails.actions";
import Select from "react-select";
import Swal from "sweetalert";
import jsPDF from "jspdf";
import logo from "../../../../../Images/logo.png";

const AddSubEvent = (props) => {
  const [eventId, setEventId] = useState("");
  const [eventName, setEventName] = useState("");

  const [subEventName, setSubEventName] = useState("");
  const [subEventDescription, setSubEventDescription] = useState();

  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  function uploadSubEvent() {
    props.addSubEvent(
      {
        event_id: eventId,
        sub_event_name: subEventName,
        sub_event_desc: subEventDescription,
      },
      handleVisible,
      handleDangerVisible
    );
  }
  // [(!isEmpty(props.EventList)) & (console.log('props.EventList', props.EventList))]
  const getEventList =
    !isEmpty(props.eventList) &&
    props.eventList.map((x) => {
      let data = { value: x.event_id, label: x.event_name };
      return data;
    });
  console.log("getEventList", getEventList);
  const subEventsData = isEmpty(props.subEventsList)
    ? []
    : props.subEventsList.reverse();
  console.log("getSubEventList", subEventsData);

  const clearStates = () => {
    setEventId(0);
    setEventName("");
    setSubEventName("");
    setSubEventDescription("");
  };

  /*const [state, setState] = React.useState({
        columns: [
            { title: 'SubEvent Name', field: 'sub_event_name' },
            { title: 'SubEvent Description', field: 'sub_event_desc' },
            { title: 'Event Name', field: 'event.event_name', editable: 'never' },
        ],
    });*/

  const handleVisible = () => {
    setAlertVisible(true);
    clearStates();
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: "Added successfully...",
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = () => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: "Something went wrong...",
      icon: "error",
      button: "Ok",
    });
  };

  const handleChangeEvent = (selectedOption) => {
    console.log(selectedOption, "sop");
    setEventId(selectedOption.value);
    setEventName(selectedOption.label);
  };
  useEffect(() => {
    props.getSubEvent(2);
    props.getEvent(1);
  }, []);
  return (
    <>
      {props.isFetchingSubEventData ||
      props.isFetchingEventData ||
      props.isAddingSubEventData ||
      props.isDeletingSubEventData ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {/* {props.isFetchingwareHouseList ? 'Loading...' : 'Loading...'} */}
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <div>
              <Form>
                <Row>
                  <Col lg="12" md="12" xs="12">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Select Event *</b>
                      </Form.Label>
                      <Select
                        isDisabled={isEmpty(props.eventList)}
                        placeholder="Select Event..."
                        onChange={handleChangeEvent}
                        options={getEventList}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg="12" md="12" xs="12">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Enter Subevent Name *</b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="SubEvent Name...."
                        value={subEventName}
                        onChange={(e) => setSubEventName(e.target.value)}
                        disabled={isEmpty(eventName)}
                        //  disabled={isEmpty(eventId)}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col xl="12" lg="12" md="12" sm="12" xs="12">
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <b>Enter SubEvent Description:</b>
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="SubEvent Description....."
                        value={subEventDescription}
                        onChange={(e) => setSubEventDescription(e.target.value)}
                        disabled={isEmpty(eventName)}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <div className="sendDiv">
                  {isEmpty(eventName) || isEmpty(subEventName) ? (
                    <Button
                      className="sendButton"
                      onClick={uploadSubEvent}
                      style={{ backgroundColor: "black" }}
                      disabled
                    >
                      Save
                    </Button>
                  ) : (
                    <Button className="sendButton" onClick={uploadSubEvent}>
                      Save
                    </Button>
                  )}
                </div>
              </Form>
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addSubEvent: (data, handleVisible, handleDangerVisible) =>
      dispatch(addSubEvent(data, handleVisible, handleDangerVisible)),
    getSubEvent: (id) => dispatch(getSubEvent(id)),
    getEvent: (id) => dispatch(getEvent(id)),
    updateSubEvent: (newData, oldData) =>
      dispatch(updateSubEvent(newData, oldData)),
    deleteSubEvent: (SubEventId) => dispatch(deleteSubEvent(SubEventId)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  subEventsList: state.MarqueeReducer.subEventsList,
  eventList: state.MarqueeReducer.eventList,
  isFetchingSubEventData: state.HrReducer.isFetchingSubEventData,
  isFetchingEventData: state.HrReducer.isFetchingEventData,
  isAddingSubEventData: state.HrReducer.isAddingSubEventData,
  isDeletingSubEventData: state.HrReducer.isDeletingSubEventData,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddSubEvent);
