const initialState = {
    isFetchingAddOrder: false,
    isFetchingGetOrder: false,

    isFetchingAddChildOrder: false,

    isFetchingGetOrderArticle: false,

    orderInfo: [],

    orderArticleInfo: [],
    isFetchingGetArticleByOrder: false,
    articleByOrderId: [],
    isFetchingGetMaterialByOrder: false,
    materialByArticleId: [],
}


const OrderCreationReducer = (state = initialState, action) => {
    switch (action.type) {

            //Order
        case "REQUEST_ADD_ORDER":
            return { ...state, isFetchingAddOrder: true };

        case "SUCCESS_ADD_ORDER":
            return {
                ...state, isFetchingAddOrder: false,
            };

        case "ERROR_ADD_ORDER":
            return { ...state, isFetchingAddOrder: false };


            //Get Order
        case "REQUEST_GET_ORDER":
            return { ...state, isFetchingGetOrder: true };

        case "SUCCESS_GET_ORDER":
            return {
                ...state, isFetchingGetOrder: false,
                orderInfo: action.payload.data,
            };

        case "ERROR_GET_ORDER":
            return { ...state, isFetchingGetOrder: false };


            //Child Order
            case "REQUEST_ADD_CHILD_ORDER":
            return { ...state, isFetchingAddChildOrder: true };

        case "SUCCESS_ADD_CHILD_ORDER":
            return {
                ...state, isFetchingAddChildOrder: false,
            };

        case "ERROR_ADD_CHILD_ORDER":
            return { ...state, isFetchingAddChildOrder: false };


            //Get Order Article
        case "REQUEST_GET_ORDER_ARTCLE":
            return { ...state, isFetchingGetOrderArticle: true };

        case "SUCCESS_GET_ORDER_ARTCLE":
            return {
                ...state, isFetchingGetOrderArticle: false,
                orderArticleInfo: action.payload.data,
            };

        case "ERROR_GET_ORDER_ARTCLE":
            return { ...state, isFetchingGetOrderArticle: false };




            case "REQUEST_GET_ARTICLE_BY_ORDER":
            return { ...state, isFetchingGetArticleByOrder: true };

        case "SUCCESS_GET_ARTICLE_BY_ORDER":
            return {
                ...state, isFetchingGetArticleByOrder: false,
                articleByOrderId: action.payload.data,
            };

        case "ERROR_GET_ARTICLE_BY_ORDER":
            return { ...state, isFetchingGetArticleByOrder: false };



            case "REQUEST_GET_DATA_BY_ORDER_ARTICLE":
                return { ...state, isFetchingGetMaterialByOrder: true };
    
            case "SUCCESS_GET_DATA_BY_ORDER_ARTICLE":
                return {
                    ...state, isFetchingGetMaterialByOrder: false,
                    materialByArticleId: action.payload.data,
                };
    
            case "ERROR_GET_DATA_BY_ORDER_ARTICLE":
                return { ...state, isFetchingGetMaterialByOrder: false };


        default:
            return state;
    }
}

export default OrderCreationReducer;
