const initialState = {


    isFetchingGetOrder: false,
    orderInfo: [],


    isFetchingGetArticleByOrder: false,
    articleByOrderId: [],

    isFetchingGetMaterialByOrder: false,
    materialByArticleId: [],

}

const productionDetailReducer = (state = initialState, action) => {
    switch (action.type) {
        //Get Order
        case "REQUEST_GET_ORDER":
            return { ...state, isFetchingGetOrder: true };

        case "SUCCESS_GET_ORDER":
            return {
                ...state, isFetchingGetOrder: false,
                orderInfo: action.payload.data,
              
            };

        case "ERROR_GET_ORDER":
            return { ...state, isFetchingGetOrder: false };

        case "ERROR_GET_ORDER_ARTCLE":
            return { ...state, isFetchingGetOrderArticle: false };




        case "REQUEST_GET_ARTICLE_BY_ORDER":
            return { ...state, isFetchingGetArticleByOrder: true };

        case "SUCCESS_GET_ARTICLE_BY_ORDER":
            return {
                ...state, isFetchingGetArticleByOrder: false,
                articleByOrderId: action.payload.data,
            };

        case "ERROR_GET_ARTICLE_BY_ORDER":
            return { ...state, isFetchingGetArticleByOrder: false };



        case "REQUEST_GET_DATA_BY_ORDER_ARTICLE":
            return { ...state, isFetchingGetMaterialByOrder: true };

        case "SUCCESS_GET_DATA_BY_ORDER_ARTICLE":
            return {
                ...state, isFetchingGetMaterialByOrder: false,
                materialByArticleId: action.payload.data,
            };

        case "ERROR_GET_DATA_BY_ORDER_ARTICLE":
            return { ...state, isFetchingGetMaterialByOrder: false };

        default:
            return state;


    }
}
export default productionDetailReducer;