import React, { useState } from "react";
import { Container, Form, Row, Col, Button } from "react-bootstrap";
import {
  addMasterControl,
  getMasterControl,
  deleteMasterControl,
  UpdateMasterControl /*UpdateHREvent */,
} from "./MarqueeDetails.actions";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import { useEffect } from "react";
import Swal from "sweetalert";
import jsPDF from "jspdf";
import { isEmpty } from "lodash";
import logo from "../../../../../Images/logo.png";

const MasterControlList = (props) => {
  const eventData = isEmpty(props.masterControlsList)
    ? []
    : props.masterControlsList.reverse();
  console.log(eventData);

  useEffect(() => {
    props.getMasterControl(2);
  }, []);
  return (
    <>
      <Container fluid>
        {props.isFetchingEventData ? (
          <div className="loader-div">
            <Button variant="info" disabled>
              <Spinner
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
                variant="info"
              />
              Fetching Master Controlls Data
            </Button>
          </div>
        ) : (
          <MaterialTable
            title="Master Controls "
            columns={[
              {
                title: "Master Control Name",
                field: "master_control_item_name",
              },
              {
                title: "Master Control Description",
                field: "master_control_item_desc",
              },
              {
                title: "Master Control Type",
                field: "master_control_item_type",
                editable: "never",
              },
            ]}
            data={eventData}
            options={{
              actionsColumnIndex: -1,
              filtering: true,
              exportButton: true,
              paging: true,
              pageSize: 50,
              emptyRowsWhenPaging: false, // To avoid of having empty rows
              pageSizeOptions: [20, 50, 100, 150, 200],
              exportAllData: true,
              exportPdf: (columns, data) => {
                const doc = new jsPDF();

                const columnTitles = [
                  {
                    title: "Master Control Event Name",
                    field: "master_control_item_name",
                    render: (rowData) => rowData.master_control_item_name,
                  },
                  {
                    title: "Master Control Description",
                    field: "master_control_item_desc",
                    render: (rowData) => rowData.master_control_item_desc,
                  },
                ].map((columnDef) => columnDef.title);
                const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
                const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
                const companyNtn = `${process.env.REACT_APP_COMPANY_NTN}`;
                const pdfData = data.map((rowData) => [
                  rowData.master_control_item_name,
                  rowData.master_control_item_desc,
                ]);
                let content = {
                  startY: 50,
                  startX: 5,
                  head: [columnTitles],
                  body: pdfData,
                };
                doc.addImage(logo, "PNG", 10, 1, 50, 50);
                doc.setFontSize(20);
                doc.setFont(undefined, "bold");
                doc.text(companyName, 50, 22, 0, 0);
                doc.setFontSize(13);
                doc.setFont("TimesNewRoman", "Normal");
                doc.text(companyAddress, 50, 29, 0, 0);
                doc.text(companyNtn, 50, 36, 0, 0);
                doc.autoTable(content);
                doc.save(`Master control Table.pdf`);
              },
              maxBodyHeight: "600px",
              headerStyle: {
                position: "sticky",
                top: 0,
                color: "#00BBBB",
                fontWeight: "550",
                onRowAdd: "none",
              },
            }}
            editable={{
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    {
                      // console.log('newData', newData),
                      props.UpdateMasterControl(newData, oldData);
                    }
                  }, 600);
                }),
              onRowDelete: (oldData) =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    props.deleteMasterControl(oldData.master_control_item_id);
                    console.log(oldData, "old");
                  }, 600);
                }),
            }}
          />
        )}
      </Container>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMasterControl: (id) => dispatch(getMasterControl(id)),
    UpdateMasterControl: (newData, oldData) =>
      dispatch(UpdateMasterControl(newData, oldData)),
    deleteMasterControl: (event_id) => dispatch(deleteMasterControl(event_id)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  isFetchingMasterControlData: state.MarqueeReducer.isFetchingMasterControlData,
  masterControlsList: state.MarqueeReducer.masterControlsList,
});

export default connect(mapStateToProps, mapDispatchToProps)(MasterControlList);
