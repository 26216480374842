import React, { useEffect, useState } from "react";
import { Button, Col, Form, Spinner, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { getBooking, getProfitByBooking } from "./MarqueeDetails.actions";
import Select from "react-select";
import { isEmpty } from "lodash";
import Swal from "sweetalert";
import MaterialTable from "material-table";
import jsPDF from "jspdf";
import moment from "moment";
import logo from "../../../../../Images/logo.png";
import { Link } from "react-router-dom/cjs/react-router-dom";

const ProfitByBooking = (props) => {
  const [bookingId, setBookingId] = useState("");
  console.log(bookingId, bookingId);
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  const handleVisible = () => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: "Added successfully...",
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = () => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: "Something went wrong...",
      icon: "error",
      button: "Ok",
    });
  };

  const handleChangeBookingById = (selectedOption) => {
    console.log(selectedOption, "sop");
    setBookingId(selectedOption.value);
    // setBookingName(selectedOption.label);
    // props.getBookingById(selectedOption.value)
  };

  const getBookingList =
    !isEmpty(props.bookingList) &&
    props.bookingList.map((x) => {
      let data = { value: x.booking_id, label: `${x.booking_id} / ${x.booking_name} / ${x.booking_date}` };
      return data;
    });
  const exportPDF = () => {
    props.getProfitByBooking(bookingId);
  };

  useEffect(() => {
    props.getBooking();
  }, []);

  return (
    <>
      {props.isFetchingBookingData ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Form>
              <Row>
                <Col lg="12" md="12" xs="12">
                  <Form.Group className="mb-3" controlId="garana">
                    <Form.Label>
                      <b>Select booking *</b>
                    </Form.Label>
                    <Select
                      isDisabled={isEmpty(props.bookingList)}
                      placeholder="Select booking..."
                      onChange={handleChangeBookingById}
                      options={getBookingList}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Form>
            <div className="sendDiv" style={{ marginTop: "15px" }}>
              <Link to="/user/Profit-report-by-booking">
              {bookingId == "" ? (
                <Button
                  className="sendButton"
                  disabled
                  style={{ backgroundColor: "black" }}
                  onClick={() => exportPDF()}
                >
                  Generate
                </Button>
              ) : (
                <Button className="sendButton" onClick={() => exportPDF()}>
                  Generate
                </Button>
              )}
              </Link>
            </div>
        
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    //     addExpense: (data, handleVisible, handleDangerVisible) => dispatch(addExpense(data, handleVisible, handleDangerVisible)),
    //     getExpense: (id) => dispatch(getExpense(id)),
    //     getMasterControl: (id) => dispatch(getMasterControl(id)),
    //    // UpdateHRExpense: (newData, oldData) => dispatch(UpdateHRExpense(newData, oldData)),
    //     deleteExpense: (menu_id) => dispatch(deleteExpense(menu_id)),
    //     SearchMarqueeExpenseReport:(id)=>dispatch(SearchMarqueeExpenseReport(id)),
    //     getBookingById:(id) => dispatch(getBookingById(id)),
    getBooking: () => dispatch(getBooking()),
    getProfitByBooking: (bookingId) => dispatch(getProfitByBooking(bookingId)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  // departmentList: state.MarqueeReducer.departmentList,
  // isFetchingExpenseData: state.MarqueeReducer.isFetchingExpenseData,
  // isAddingExpenseData: state.MarqueeReducer.isAddingExpenseData,
  // expenseList: state.MarqueeReducer.expenseList,
  // masterControlsList: state.MarqueeReducer.masterControlsList,
  bookingList: state.MarqueeReducer.bookingList,
  isFetchingprofitByID: state.MarqueeReducer.isFetchingprofitByID,
  profitByID: state.MarqueeReducer.profitByID,
  isFetchingBookingData: state.MarqueeReducer.isFetchingBookingData,
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfitByBooking);
