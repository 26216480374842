import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import {isNull } from "lodash";
import AddCustomerMarquee from "./AddCustomerMarquee";
import CustomerMarqueeList from "./CustomerMarqueeList";



const TotalCustomerData = (props) => {
    const permissions = JSON.parse(localStorage.getItem("permissions"));

    return (
        <>
            <Tabs defaultActiveKey={!isNull(permissions) && permissions.some(p => p['sub_id'] === 103) ?
                    "AddCustomer" : !isNull(permissions) && permissions.some(p => p['sub_id'] === 104) ?
                        "CustomerList" :  ""} id="AddCustomer" className="mb-3">
                <Tab eventKey="AddCustomer" title="Add Customer"
                disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 103) ? false : true}>  
                    <AddCustomerMarquee />
                </Tab>
                <Tab eventKey="CustomerList" title="Customer List"
              disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 104) ? false : true}> 
                    <CustomerMarqueeList/>
                </Tab>
            </Tabs>
        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
    };
};
const mapStateToProps = (state, ownProps) => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TotalCustomerData);