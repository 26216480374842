import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import AddSubCategory from "./AddSubCategory";
import SubCategoryTableList from "./SubCategoryTableList";
import CategoryTableList from "./CategoryTableList";
import {isNull } from "lodash";
const TotalSubCategoryData = (props) => {
    const permissions = JSON.parse(localStorage.getItem("permissions"));
    return (
        <>
         {/* <Tabs
                defaultActiveKey={!isNull(permissions) && permissions.some(p => p['sub_id'] === 3) ?
                    "SubCategoryList" : !isNull(permissions) && permissions.some(p => p['sub_id'] === 4) ?
                        "AddSubCategory" : ""}
                id="AddCategory"
                className="mb-3"
                unmountOnExit={true}>
                <Tab
                    eventKey="SubCategoryList"
                    title="Sub-Category List"
                    unmountOnExit={true}
                    disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 3) ? false : true}>
                     <SubCategoryTableList />
                </Tab>
                <Tab
                    eventKey="AddSubCategory"
                    title="Add Sub-Category"
                    unmountOnExit={true}
                    disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 4) ? false : true}>
                    <AddSubCategory />
                </Tab>
            </Tabs> */}
        
            <Tabs defaultActiveKey={!isNull(permissions) && permissions.some(p => p['sub_id'] === 5) ?
                    "AddSubCategory" : !isNull(permissions) && permissions.some(p => p['sub_id'] === 6) ?
                        "SubCategoryList" : ""} id="AddSubCategory" className="mb-3">
                <Tab eventKey="AddSubCategory" title="Add SubCategory"
                disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 5) ? false : true}
                >
                <AddSubCategory />
                </Tab>
                <Tab eventKey="SubCategoryList" title="SubCategory List"
                disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 6) ? false : true}
                >
                <SubCategoryTableList />
                </Tab>
            </Tabs>
        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
    };
};
const mapStateToProps = (state, ownProps) => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TotalSubCategoryData);