import React, { useEffect, useState, forwardRef } from "react";
import {
  Row,
  Col,
  Form,
  Container,
  Button,
  Modal,
  Alert,
} from "react-bootstrap";
import "./add-details.css";
import {
  getCategoryData,
  deleteCategory,
  updateCategory,
  resetReducer,
} from "./AddDetails.actions";
import { connect } from "react-redux";
import { isEmpty, isNull, isUndefined } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";

const CategoryTableList = (props) => {
  const categoryData = isEmpty(props.categoryList)
    ? []
    : props.categoryList.map(function (el) {
        var o = Object.assign({}, el);
        o.key = el.warehouse.whKey + " " + "-" + " " + el.cateKey;
        return o;
      });

  /*const [state, setState] = React.useState({
        columns: [
            { title: 'WareHouse Name', field: 'warehouse.wareHouseName', editable: 'never' },
            { title: 'Category Code', field: 'key', editable: 'never' },
            { title: 'Category Name', field: 'categoryName' },
        ],
    });*/
  useEffect(() => {
    props.getCategoryData();
  }, []);

  return (
    <>
      {props.isFetchingcategoryList ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <MaterialTable
              title="Category Details"
              columns={[
                {
                  title: "WareHouse Name",
                  field: "warehouse.wareHouseName",
                  editable: "never",
                },
                { title: "Category Code", field: "key", editable: "never" },
                { title: "Category Name", field: "categoryName" },
              ]}
              data={isEmpty(categoryData) ? [] : categoryData}
              options={{
                actionsColumnIndex: -1,
                filtering: true,
                exportButton: true,
                paging: true,
                pageSize: 200, // make initial page size
                emptyRowsWhenPaging: false, // To avoid of having empty rows
                pageSizeOptions: [50, 100, 150, 200], // rows selection options
                maxBodyHeight: "600px",
                headerStyle: {
                  position: "sticky",
                  top: 0,
                  color: "#00BBBB",
                  fontWeight: "550",
                  onRowAdd: "none",
                },
              }}
              editable={{
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      {
                        props.updateCategory(newData, oldData);
                      }
                    }, 600);
                  }),
                onRowDelete: (oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      props.deleteCategory(oldData.categoryId);
                    }, 600);
                  }),
              }}
            />
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getCategoryData: () => dispatch(getCategoryData()),
    deleteCategory: (categoryId) => dispatch(deleteCategory(categoryId)),
    updateCategory: (newData, oldData) =>
      dispatch(updateCategory(newData, oldData)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  categoryList: state.AddDataReducer.categoryList,
  isFetchingcategoryList: state.AddDataReducer.isFetchingcategoryList,
});

export default connect(mapStateToProps, mapDispatchToProps)(CategoryTableList);
