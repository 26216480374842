import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { LoginStyles } from "./LoginStyles";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "white",
    border: "none",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function LoginModal(props) {
  const classes = useStyles();
  var t = true;
  const handleClose = () => {
    t = false;
  };
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open && t}>
          <div className={classes.paper}>
            <object data="./LoginModal.svg" style={{ marginLeft: "20%" }}>
              {" "}
            </object>
            <h3 style={LoginStyles.headingColor}>
              Please contact the admin to get your password.
            </h3>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
