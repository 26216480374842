import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Container,
  Button,
  FormGroup,
  Alert,
} from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty, isNull, upperCase } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import "jspdf-autotable";
import Select from "react-select";
import Swal from "sweetalert";
import {
  UpdateHREmployeeType,
  deleteHREmployeeType,
  getHREmployeeType,
} from "./HRDetails.actions";

const EmployeeTypeList = (props) => {
  const employeeTypeData = isEmpty(props.employeeTypeList)
    ? []
    : props.employeeTypeList.reverse();
  console.log("employeeTypeData", employeeTypeData);

  useEffect(() => {
    props.getHREmployeeType();
  }, []);
  return (
    <>
      {props.isFetchingEmployeeTypeData ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {/* {props.isFetchingwareHouseList ? 'Loading...' : 'Loading...'} */}
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <div>
              <Form>
                <MaterialTable
                  title="EmployeeType"
                  columns={[
                    { title: "EmployeeType Name", field: "employee_type_name" },
                    {
                      title: "EmployeeType Description",
                      field: "employee_type_desc",
                    },
                  ]}
                  data={employeeTypeData}
                  options={{
                    actionsColumnIndex: -1,
                    filtering: true,
                    exportButton: true,
                    exportAllData: true,
                    maxBodyHeight: "600px",
                    headerStyle: {
                      position: "sticky",
                      top: 0,
                      color: "#00BBBB",
                      fontWeight: "550",
                      onRowAdd: "none",
                    },
                  }}
                  editable={{
                    onRowUpdate: (newData, oldData) =>
                      new Promise((resolve) => {
                        setTimeout(() => {
                          resolve();
                          {
                            // console.log('newData', newData),
                            props.UpdateHREmployeeType(newData, oldData);
                          }
                        }, 600);
                      }),
                    onRowDelete: (oldData) =>
                      new Promise((resolve) => {
                        setTimeout(() => {
                          resolve();
                          props.deleteHREmployeeType(oldData.employee_type_id);
                          console.log(oldData, "old");
                        }, 600);
                      }),
                  }}
                />
              </Form>
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getHREmployeeType: () => dispatch(getHREmployeeType()),
    UpdateHREmployeeType: (newData, oldData) =>
      dispatch(UpdateHREmployeeType(newData, oldData)),
    deleteHREmployeeType: (employee_type_id) =>
      dispatch(deleteHREmployeeType(employee_type_id)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  employeeTypeList: state.HrReducer.employeeTypeList,
  isFetchingEmployeeTypeData: state.HrReducer.isFetchingEmployeeTypeData,
});

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeTypeList);
