const initialState = {
    isFetchingAddSize: false,
    isFetchingGetSize: false,

    isFetchingAddUOM: false,
    isFetchingGetUOM: false,

    isFetchingAddColor: false,
    isFetchingGetColor: false,

    isFetchingAddCustomer: false,
    isFetchingGetCustomer: false,
    isFetchingUpdateCustomer: false,

    isFetchingGetDivisions: false,

    isFetchingAddBrand: false,
    isFetchingGetBrand: false,

    isFetchingAddProduct: false,
    isFetchingGetProduct: false,

    isFetchingAddSubProduct: false,
    isFetchingGetSubProduct: false,

    isFetchingAddCatalog: false,
    isFetchingGetCatalog: false,

    isFetchingAddProcess: false,
    isFetchingGetProcess: false,

    isFetchingAddVendors: false,
    isFetchingGetVendors: false,

    isFetchingArticleIds: false,


    AticleIds: [],

    sizeDetail: {
        size: "",
        description: "",
    },
    sizeInfo: [],

    colorDetail: {
        color: "",
        description: "",
    },
    colorInfo: [],

    uomDetail: {
        uom: "",
        description: "",
    },
    uomInfo: [],

    divisionInfo: [],

    customerDetail: {
        name: "",
        company: "",
        email: "",
        web: "",
        phone: "",
        city: "",
        country: "",
    },
    customerInfo: [],

    brandDetail: {
        brand: "",
        description: "",
    },
    brandInfo: [],

    productDetail: {
        product: "",
    },
    productInfo: [],

    subProductDetail: {
        product: "",
        subProduct: "",
    },
    subProductInfo: [],

    catalogDetail: {
        catalog: "",
        Frontimage: [],
        Backimage: [],
    },
    catalogInfo: [],

    processDetail: {
        category: "",
        processes: [],
    },
    processInfo: [],

    vendorsInfo: [],


}


const productionReducer = (state = initialState, action) => {
    switch (action.type) {
        //Size
        case "REQUEST_ADD_SIZE":
            return { ...state, isFetchingAddSize: true };

        case "SUCCESS_ADD_SIZE":
            return {
                ...state, isFetchingAddSize: false,
                sizeDetail: {
                    size: action.payload.sizeName,
                    description: action.payload.sizeDescription,
                }
            };

        case "ERROR_ADD_SIZE":
            return { ...state, isFetchingAddSize: false };

        //Get Size
        case "REQUEST_GET_SIZE":
            return { ...state, isFetchingGetSize: true };

        case "SUCCESS_GET_SIZE":
            return {
                ...state, isFetchingGetSize: false,
                sizeInfo: action.payload.data,
            };

        case "ERROR_GET_SIZE":
            return { ...state, isFetchingGetSize: false };

        //UOM
        case "REQUEST_ADD_UOM":
            return { ...state, isFetchingAddUOM: true };

        case "SUCCESS_ADD_UOM":
            return {
                ...state, isFetchingAddUOM: false,
                uomDetail: {
                    uom: action.payload.uomName,
                    description: action.payload.uomDescription,
                },
            };

        case "ERROR_ADD_UOM":
            return { ...state, isFetchingAddUOM: false };

        //Get UOM
        case "REQUEST_GET_UOM":
            return { ...state, isFetchingGetUOM: true };

        case "SUCCESS_GET_UOM":
            return {
                ...state, isFetchingGetUOM: false,
                uomInfo: action.payload.data,
            };

        case "ERROR_GET_UOM":
            return { ...state, isFetchingGetUOM: false };

        //Color
        case "REQUEST_ADD_COLOR":
            return { ...state, isFetchingAddColor: true };

        case "SUCCESS_ADD_COLOR":
            return {
                ...state, isFetchingAddColor: false,
                colorDetail: {
                    color: action.payload.colorName,
                    description: action.payload.colorDescription,
                }
            };

        case "ERROR_ADD_COLOR":
            return { ...state, isFetchingAddColor: false };

        //Get Color
        case "REQUEST_GET_COLOR":
            return { ...state, isFetchingGetColor: true };

        case "SUCCESS_GET_COLOR":
            return {
                ...state, isFetchingGetColor: false,
                colorInfo: action.payload.data,
            };

        case "ERROR_GET_COLOR":
            return { ...state, isFetchingGetColor: false };


        //Get Division
        case "REQUEST_GET_DIVISION":
            return { ...state, isFetchingGetDivisions: true };

        case "SUCCESS_GET_DIVISION":
            return {
                ...state, isFetchingGetDivisions: false,
                divisionInfo: action.payload
            };

        case "ERROR_GET_DIVISION":
            return { ...state, isFetchingGetDivisions: false };

        //Customer
        case "REQUEST_ADD_CUSTOMER":
            return { ...state, isFetchingAddCustomer: true };

        case "SUCCESS_ADD_CUSTOMER":
            return {
                ...state, isFetchingAddCustomer: false,
                customerDetail: {
                    name: action.payload.customerPerson,
                    company: action.payload.customerCompany,
                    email: action.payload.customerEmail,
                    web: action.payload.customerWeb,
                    phone: action.payload.customerPhone,
                    city: action.payload.customerCity,
                    country: action.payload.customerContry,
                },
            };

        case "ERROR_ADD_CUSTOMER":
            return { ...state, isFetchingAddCustomer: false };

        //Get Customer
        case "REQUEST_GET_CUSTOMER":
            return { ...state, isFetchingGetCustomer: true };

        case "SUCCESS_GET_CUSTOMER":
            return {
                ...state, isFetchingGetCustomer: false,
                customerInfo: action.payload.data
            };

        case "ERROR_GET_CUSTOMER":
            return { ...state, isFetchingGetCustomer: false };


        //Update Customer
        case "REQUEST_UPDATE_CUSTOMER":
            return { ...state, isFetchingUpdateCustomer: true };

        case "SUCCESS_UPDATE_CUSTOMER":
            return {
                ...state, isFetchingUpdateCustomer: false,
                customerInfo: action.payload.data
            };

        case "ERROR_UPDATE_CUSTOMER":
            return { ...state, isFetchingUpdateCustomer: false };


        //Brand
        case "REQUEST_ADD_BRAND":
            return { ...state, isFetchingAddBrand: true };

        case "SUCCESS_ADD_BRAND":
            return {
                ...state, isFetchingAddBrand: false,
                brandDetail: {
                    brand: action.payload.name,
                    description: action.payload.description,
                },
            };

        case "ERROR_ADD_BRAND":
            return { ...state, isFetchingAddBrand: false };

        //Get Brand
        case "REQUEST_GET_BRAND":
            return { ...state, isFetchingGetBrand: true };

        case "SUCCESS_GET_BRAND":
            return {
                ...state, isFetchingGetBrand: false,
                brandInfo: action.payload.data,
            };

        case "ERROR_GET_BRAND":
            return { ...state, isFetchingGetBrand: false };


        //Product
        case "REQUEST_ADD_PRODUCT":
            return { ...state, isFetchingAddProduct: true };

        case "SUCCESS_ADD_PRODUCT":
            return {
                ...state, isFetchingAddProduct: false,
                productDetail: {
                    product: action.payload.name,
                },
            };

        case "ERROR_ADD_PRODUCT":
            return { ...state, isFetchingAddProduct: false };

        //Get Product
        case "REQUEST_GET_PRODUCT":
            return { ...state, isFetchingGetProduct: true };

        case "SUCCESS_GET_PRODUCT":
            return {
                ...state, isFetchingGetProduct: false,
                productInfo: action.payload.data,
            };

        case "ERROR_GET_PRODUCT":
            return { ...state, isFetchingGetProduct: false };


        //Sub Product
        case "REQUEST_ADD_SUB_PRODUCT":
            return { ...state, isFetchingAddSubProduct: true };

        case "SUCCESS_ADD_SUB_PRODUCT":
            return {
                ...state, isFetchingAddSubProduct: false,
                subProductDetail: {
                    product: action.payload.name,
                    subProduct: action.payload.description,
                },
            };

        case "ERROR_ADD_SUB_PRODUCT":
            return { ...state, isFetchingAddSubProduct: false };

        //Get Sub Product
        case "REQUEST_GET_SUB_PRODUCT":
            return { ...state, isFetchingGetSubProduct: true };

        case "SUCCESS_GET_SUB_PRODUCT":
            return {
                ...state, isFetchingGetSubProduct: false,
                subProductInfo: action.payload.data,
            };

        case "ERROR_GET_SUB_PRODUCT":
            return { ...state, isFetchingGetSubProduct: false };


        //Catalog
        case "REQUEST_ADD_CATALOG":
            return { ...state, isFetchingAddCatalog: true };

        case "SUCCESS_ADD_CATALOG":
            return {
                ...state, isFetchingAddCatalog: false,
                catalogDetail: {
                    catalog: action.payload.name,
                    Frontimage: action.payload.imagefront,
                    Backimage: action.payload.imageback,
                },
            };

        case "ERROR_ADD_CATALOG":
            return { ...state, isFetchingAddCatalog: false };

        //Get Catalog
        case "REQUEST_GET_CATALOG":
            return { ...state, isFetchingGetCatalog: true };

        case "SUCCESS_GET_CATALOG":
            return {
                ...state, isFetchingGetCatalog: false,
                catalogInfo: action.payload.data,
            };

        case "ERROR_GET_CATALOG":
            return { ...state, isFetchingGetCatalog: false };

        //Process
        case "REQUEST_ADD_PROCESS":
            return { ...state, isFetchingAddProcess: true };

        case "SUCCESS_ADD_PROCESS":
            return {
                ...state, isFetchingAddProcess: false,
                processDetail: {
                    category: action.payload.categoryProcessName,
                    processes: action.payload.data,
                }
            };

        case "ERROR_ADD_PROCESS":
            return { ...state, isFetchingAddProcess: false };

        //Get Process
        case "REQUEST_GET_PROCESS":
            return { ...state, isFetchingGetProcess: true };

        case "SUCCESS_GET_PROCESS":
            return {
                ...state, isFetchingGetProcess: false,
                processInfo: action.payload.data,
            };

        case "ERROR_GET_PROCESS":
            return { ...state, isFetchingGetProcess: false };


            //Vendors
        case "REQUEST_ADD_VENDORS":
            return { ...state, isFetchingAddVendors: true };

        case "SUCCESS_ADD_VENDORS":
            return {
                ...state, isFetchingAddVendors: false,
            };

        case "ERROR_ADD_VENDORS":
            return { ...state, isFetchingAddVendors: false };

        //Get Vendors
        case "REQUEST_GET_VENDORS":
            return { ...state, isFetchingGetVendors: true };

        case "SUCCESS_GET_VENDORS":
            return {
                ...state, isFetchingGetVendors: false,
                vendorsInfo: action.payload.data,
            };

        case "ERROR_GET_VENDORS":
            return { ...state, isFetchingGetVendors: false };



            
            // case "REQUEST_GET_ARTICLE_IDS":
            //     return { ...state, isFetchingArticleIds: true };
    
            case "SUCCESS_GET_ARTICLE_IDS":
                return {
                    ...state, isFetchingArticleIds: false,
                    ArticleIds: [1, 2, 3],
                };
    
            // case "ERROR_GET_ARTICLE_IDS":
            //     return { ...state, isFetchingArticleIds: false };


        default:
            return state;
    }
}

export default productionReducer;
