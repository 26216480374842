import API from "../../../../../global/api";

export const requestGetArticle = () => {
    return {
        type: "REQUEST_GET_ARTICLE",
    };
};
export const successGetArticle = (data) => {
    return {
        type: "SUCCESS_GET_ARTICLE",
        payload: data,
    };
};
export const errorGetArticle = () => {
    return {
        type: "ERROR_GET_ARTICLE",
    };
};

export const getArticle = () => {
    return (dispatch) => {
        dispatch(requestGetArticle());
        API.get(`/article/get-article`).then((res) => {
            let getData = res.data;
            dispatch(successGetArticle(getData));
        }).catch((error) => {
            dispatch(errorGetArticle());
        })
    };
};


export const requestGetArticleData = () => {
    return {
        type: "REQUEST_GET_ARTICLE_DATA",
    };
};
export const successGetArticleData = (data) => {
    return {
        type: "SUCCESS_GET_ARTICLE_DATA",
        payload: data,
    };
};
export const errorGetArticleData = () => {
    return {
        type: "ERROR_GET_ARTICLE_DATA",
    };
};

export const getArticleData = (id) => {
    return (dispatch) => {
        dispatch(requestGetArticleData());
        API.get(`/article/get-article-data/${id}`).then((res) => {
            let getData = res.data;
            dispatch(successGetArticleData(getData));
        }).catch((error) => {
            dispatch(errorGetArticleData());
        })
    };
};




export const requestAddAdminCost = () => {
    return {
        type: "REQUEST_ADD_ADMIN_COST",
    };
};
export const successAddAdminCost = (data) => {
    return {
        type: "SUCCESS_ADD_ADMIN_COST",
        payload: data,
    };
};
export const errorAddAdminCost = () => {
    return {
        type: "ERROR_ADD_ADMIN_COST",
    };
};

export const addAdminTotalCost = (data) => {
    return (dispatch) => {
        dispatch(requestAddAdminCost());
        API.post(`/article/admincost-add-data`, data).then((res) => {
            let getData = res.data;
            dispatch(successAddAdminCost(getData));
        }).catch((error) => {
            dispatch(errorAddAdminCost());
        })
    };
};