import API from '../../../../../global/api';
import { forEach } from "lodash";

//Get Sub Product Detail Call

export const getSubProductDetailCall = (data) => {
    console.log(data, "ddff")
    return (dispatch) => {
        dispatch(requestGetSubProductDetail())
        API.get(`product/get_all/${data}`).then((res) => {
            dispatch(successGetSubProductDetail(res.data))
        }).catch((error) => {
            dispatch(errorGetSubProductDetail())
        })
    }
}

export const requestGetSubProductDetail = () => {
    return {
        type: "REQUEST_GET_SUB_PRODUCT_DETAIL"
    }
}

export const successGetSubProductDetail = (data) => {
    return {
        type: "SUCCESS_GET_SUB_PRODUCT_DETAIL",
        payload: data,
    }
}

export const errorGetSubProductDetail = () => {
    return {
        type: "ERROR_GET_SUB_PRODUCT_DETAIL"
    }
}



//Add Article Call
export const addArticleCall = (data) => {

    console.log(data, "iipp")

    const formData = new FormData();
    formData.append("itemName", `${data.itemName}`);
    formData.append("itemCode", `${data.itemCode}`);
    formData.append("catelogNo", `${data.catelogNo}`);
    formData.append("customerCode", `${data.customerCode}`);
    formData.append("manualCode", `${data.manualCode}`);
    formData.append("articleWeight", `${data.articleWeight}`);
    formData.append("exportCost", `${data.exportCost}`);
    formData.append("dyed", `${data.dyed}`);
    formData.append("composition", `${data.composition}`);
    formData.append("articleDescription", `${data.articleDescription}`);
    formData.append("specialInstruction", `${data.specialInstruction}`);
    formData.append("divisionId", `${data.divisionId}`);
    formData.append("customerId", `${data.customerId}`);
    formData.append("brandId", `${data.brandId}`);
    formData.append("uomId", `${data.uomId}`);
    formData.append("size", `${JSON.stringify(data.size)}`);
    formData.append("mainProId", `${data.mainProId}`);
    formData.append("subProId", `${data.subProId}`);
    formData.append("packingQty", `${data.packingQty}`);

    forEach(data.image, (x, index) => {
        formData.append("image", data.image[index]);
    })

    return (dispatch) => {
        dispatch(requestAddArticle())
        API.post('article/add-article-data', formData).then((res) => {
           let ArrayId = res.data;
            console.log(ArrayId, "aarrrr");
            dispatch(successAddArticle(ArrayId))
        }).catch((error) => {
            dispatch(errorAddArticle())
        })
    }
}

export const requestAddArticle = () => {
    return {
        type: "REQUEST_ADD_ARTICLE"
    }
}

export const successAddArticle = (data) => {
    return {
        type: "SUCCESS_ADD_ARTICLE",
        payload: data,
    }
}

export const errorAddArticle = () => {
    return {
        type: "ERROR_ADD_ARTICLE"
    }
}



//Get Article Call

export const getArticleCall = () => {
    return (dispatch) => {
        dispatch(requestGetArticle())
        API.get(`article/get-article`).then((res) => {
            dispatch(successGetArticle(res.data))
        }).catch((error) => {
            dispatch(errorGetArticle())
        })
    }
}

export const requestGetArticle = () => {
    return {
        type: "REQUEST_GET_ARTICLE"
    }
}

export const successGetArticle = (data) => {
    return {
        type: "SUCCESS_GET_ARTICLE",
        payload: data,
    }
}

export const errorGetArticle = () => {
    return {
        type: "ERROR_GET_ARTICLE"
    }
}