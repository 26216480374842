import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import {isNull } from "lodash";
import AddInstallmentMarquee from "./AddInstallmentMarquee";
import InstallmentHistory from "./InstallmentHistory";



const TotalInstallmentData = (props) => {
    const permissions = JSON.parse(localStorage.getItem("permissions"));

    return (
        <>
            <Tabs defaultActiveKey={!isNull(permissions) && permissions.some(p => p['sub_id'] === 107) ?
                    "AddInstallment" : !isNull(permissions) && permissions.some(p => p['sub_id'] === 108) ?
                        "InstallmentReport" :  ""} id="AddInstallment" className="mb-3">
                <Tab eventKey="AddInstallment" title="Add Installment"
                disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 107) ? false : true}>  
                    <AddInstallmentMarquee />
                </Tab>
                <Tab eventKey="InstallmentReport" title="Installment Report"
              disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 108) ? false : true}> 
                    <InstallmentHistory/>
                </Tab>
            </Tabs>
        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
    };
};
const mapStateToProps = (state, ownProps) => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TotalInstallmentData);