import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Container,
  Button,
  FormGroup,
  Alert,
  Tabs,
  Tab,
} from "react-bootstrap";
import { ModalBody, Modal } from "reactstrap";
import "./add-details.css";

import { connect } from "react-redux";
import { isEmpty, find, isUndefined } from "lodash";
import moment from "moment";
import Spinner from "react-bootstrap/Spinner";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { IconButton } from "@material-ui/core";
import Select from "react-select";
import Swal from "sweetalert";
import {
  getLevel4Data,
  getLevel5Data,
} from "../accountsLevels/accountsLevel.action";
import {
  addAccountMasterControl,
  addRecivableAccount,
  deleteAccountMasterControl,
  getAccountMasterControl,
  getRecivableAccount,
  updateAccountMasterControl,
} from "./AddDetails.actions";
import MaterialTable from "material-table";

const RecivableAccounts = (props) => {
  const [accountTypeId, setAccountTypeId] = useState("");
  const [accountTypeLabel, setAccountTypeLabel] = useState("");
  const [level4Id, setLevel4Id] = useState("");
  const [level4Label, setLevel4Label] = useState("");
  const [recivableId, setRecivableId] = useState("");
  const [recivableLabel, setRecivableLabel] = useState("");
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  const handleVisible = () => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: "Account Added Successfully",
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = () => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: "Something went Wrong!",
      icon: "error",
      button: "Ok",
    });
  };

  function uploadDemand() {
    props.addAccount(
      {
        levelFiveId: recivableId,
      },
      handleVisible,
      handleDangerVisible
    );
  }
  const cashAccountNo =
    !isEmpty(props.level5List) &&
    props.level5List.map((x) => {
      let data = {
        value: x.levelfiveData.levelFiveId,
        label:
          x.levelfiveData.allLevelKey + " / " + x.levelfiveData.levelFiveName,
      };
      return data;
    });
  const recivableAccount = (selectedOption, type) => {
    setRecivableId(selectedOption.value);
    setRecivableLabel(selectedOption.label);
  };
  useEffect(() => {
    props.getLevel5Data();
    props.getRecivableAccount();
  }, []);
  return (
    <>
      {props.isFetchinglevel5List ||
      props.isSavingRecivableAccount ||
      props.isFetchingRecivableAccount ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {props.isSavingRecivableAccount ? "Saving" : "Loading"}
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Row style={{ zIndex: 1 }}>
              <Col lg="6" sm="12">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>
                    <b>Select Recivable Account</b>
                  </Form.Label>
                  <Select
                    // style={{zIndex}}
                    value={{ label: recivableLabel }}
                    placeholder="Select Level 3 Account..."
                    onChange={recivableAccount}
                    options={cashAccountNo}
                  />
                </Form.Group>
              </Col>
            </Row>
            <div className="sendDiv">
              {recivableId == "" ? (
                <Button
                  disabled
                  style={{
                    marginLeft: "2%",
                    color: "white",
                    width: "20%",
                    backgroundColor: "black",
                    border: "1px solid black",
                  }}
                >
                  Save
                </Button>
              ) : (
                <Button className="sendButton" onClick={uploadDemand}>
                  Save
                </Button>
              )}
            </div>
            <div className="table">
              <MaterialTable
                style={{ zIndex: 0 }}
                title={"Recivable Accounts"}
                columns={[
                  { title: "level Five Id", field: "levelFiveId" },
                  { title: "Account Name", field: "level_five.levelFiveName" },
                ]}
                data={props.recivableAccountList}
                options={{
                  actionsColumnIndex: -1,
                  paging: true,
                  pageSize: 200, // make initial page size
                  emptyRowsWhenPaging: false, // To avoid of having empty rows
                  pageSizeOptions: [50, 100, 150, 200],
                  maxBodyHeight: "600px",
                  headerStyle: {
                    position: "sticky",
                    top: 0,
                    color: "#00BBBB",
                    fontWeight: "550",
                    onRowAdd: "none",
                  },
                }}
                // editable={{
                //   // onRowUpdate: (newData, oldData) =>
                //   // new Promise((resolve) => {
                //   //     setTimeout(() => {
                //   //         resolve();
                //   //         {
                //   //             // console.log('newData', newData),
                //   //             props.updateAccount(newData, oldData)
                //   //         }
                //   //     }, 600);
                //   // }),
                //   onRowDelete: (oldData) =>
                //     new Promise((resolve) => {
                //       setTimeout(() => {
                //         resolve();
                //         props.deleteAccount(oldData.levelFiveId);
                //       }, 600);
                //     }),
                // }}
              />
            </div>
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getLevel5Data: () => dispatch(getLevel5Data()),
    getRecivableAccount: () => dispatch(getRecivableAccount()),
    addAccount: (data, handleVisible, handleDangerVisible) =>
      dispatch(addRecivableAccount(data, handleVisible, handleDangerVisible)),
    // getAccountList:()=>dispatch(getAccountMasterControl()),
    // deleteAccount:(id)=>dispatch(deleteAccountMasterControl(id)),
    // updateAccount:(data,oldData)=>dispatch(updateAccountMasterControl(data,oldData)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  level5List: state.accountsLevelReducer.level5List,
  isFetchinglevel5List: state.accountsLevelReducer.isFetchinglevel5List,
  isFetchingRecivableAccount: state.AddDataReducer.isFetchingRecivableAccount,
  isSavingRecivableAccount: state.AddDataReducer.isSavingRecivableAccount,
  recivableAccountList: state.AddDataReducer.recivableAccountList,
});

export default connect(mapStateToProps, mapDispatchToProps)(RecivableAccounts);
