import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Container,
  Button,
  FormGroup,
  Alert,
} from "react-bootstrap";
import "./add-details.css";
import {
  addWareHouseName,
  getWarehouseData,
  deleteWareHouse,
  updateWareHouse,
  resetReducer,
  addMasterControl,
} from "./AddDetails.actions";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import "jspdf-autotable";
import Swal from "sweetalert";

const AddMasterControl = (props) => {
  const [UOM, setUOM] = useState();
  const [UOMDes, setUOMDes] = useState();

  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  const handleVisible = () => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: "Master Control Added Successfully",
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = () => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: "Something went Wrong!",
      icon: "error",
      button: "Ok",
    });
  };

  function uploadWareHouse() {
    props.addWareHouseName(
      {
        unit: UOM,
        desc: UOMDes,
      },
      handleVisible,
      handleDangerVisible
    );
    setUOM("");
    setUOMDes("");
  }
  return (
    <>
      {props.isFetchingwareHouseList || props.isAddingWareHouse ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {props.isAddingWareHouse ? "Saving" : "Loading"}
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <div>
              <Form>
                <Row>
                  <Col lg="6" md="6" xs="6">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Enter UOM :</b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        id="UOMName"
                        placeholder="Enter UOM...."
                        onMouseLeave={(e) => setUOM(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="6" md="6" xs="6">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Enter UOM Description :</b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        id="UOMDes"
                        placeholder="Enter UOM Description...."
                        onMouseLeave={(e) => setUOMDes(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <div className="sendDiv">
                  {isEmpty(UOM) ? (
                    <Button
                      disabled
                      style={{
                        marginLeft: "2%",
                        color: "white",
                        width: "20%",
                        backgroundColor: "black",
                        border: "1px solid black",
                      }}
                    >
                      Save
                    </Button>
                  ) : (
                    <Button className="sendButton" onClick={uploadWareHouse}>
                      Save
                    </Button>
                  )}
                </div>
              </Form>
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addWareHouseName: (data, handleVisible, handleDangerVisible) =>
      dispatch(addMasterControl(data, handleVisible, handleDangerVisible)),
    //  resetState: () => dispatch(resetReducer())
  };
};
const mapStateToProps = (state, ownProps) => ({
  isAddingWareHouse: state.AddDataReducer.isAddingWareHouse,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddMasterControl);
