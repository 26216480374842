import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import {isNull } from "lodash";
import AddBooking from "./AddBooking";
import BookingList from "./BookingList";
import EditBookingMarquee from "./EditBookingMarquee";
import BookingReport from "./BookingReport";
import { hideForm } from "./MarqueeDetails.actions";


const TotalBookingData = (props) => {
    const permissions = JSON.parse(localStorage.getItem("permissions"));
    useEffect(() => {
        props.hideForm(false);
      }, []);
    return (
        <>
            <Tabs defaultActiveKey={!isNull(permissions) && permissions.some(p => p['sub_id'] === 99) ?
                    "AddBooking" : !isNull(permissions) && permissions.some(p => p['sub_id'] === 100) ?
                        "BookingList" : !isNull(permissions) && permissions.some(p => p['sub_id'] === 101) ?
                        "EditBooking" : !isNull(permissions) && permissions.some(p => p['sub_id'] === 102) ?
                        "BookingReport" : ""} id="AddBooking" className="mb-3">
                <Tab eventKey="AddBooking" title="Add Booking"
                disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 99) ? false : true}>  
                   <AddBooking />
                </Tab>
                <Tab eventKey="BookingList" title="Booking List"
              disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 100) ? false : true}> 
                    <BookingList/>
                </Tab>
                <Tab eventKey="EditBooking" title="Edit Booking"
               disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 101) ? false : true}> 
                    <EditBookingMarquee/>
                </Tab>
                <Tab eventKey="BookingReport" title="Booking Report"
               disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 102) ? false : true}> 
                    <BookingReport/>
                </Tab>
            </Tabs>
        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
        hideForm: (data) => dispatch(hideForm(data)),
    };
};
const mapStateToProps = (state, ownProps) => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TotalBookingData);