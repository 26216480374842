const initialState = {

    salaryData: [],
    isFetchingSalaryData: false,
    salaryHistoryData: [],
    isFetchingSalaryHistoryData: false,

}

const GenerateSalaryReducer = (state = initialState, action) => {
    switch (action.type) {

        case "REQUEST_POST_SALARY_DATA":
            return {
                ...state,
                isFetchingSalaryData: true
            };
        case "SUCCESS_POST_SALARY_DATA":
            return {
                ...state,
                salaryData: action.payload,
                isFetchingSalaryData: false,
            };
        case "ERROR_POST_SALARY_DATA":
            return { ...state };

            case "REQUEST_SALARY_HISTORY_DATA":
                return {
                    ...state,
                    isFetchingSalaryHistoryData: true
                };
            case "SUCCESS_SALARY_HISTORY_DATA":
                return {
                    ...state,
                    salaryHistoryData: action.payload,
                    isFetchingSalaryHistoryData: false,
                };
            case "ERROR_SALARY_HISTORY_DATA":
                return { ...state,isFetchingSalaryHistoryData:false };

        default:
            return state;
    }
};

export default GenerateSalaryReducer;