

const initialState = {
    isFetchingArticle: false,
    getArticleList: [],
    isFetchingEmployeeData: false,
    employeeList: [],
    isFetchingProcessData: false,
    processList: [],
    isAddingLabourData: false,
    addLabour: [],
    categoryProcessList: [],
    isFetchingCategoryProcessData: false,
    isFetchingProcessDataByCategory: false,
    processListByCategoty: []
}

const AddLabourReducer = (state = initialState, action) => {
    switch (action.type) {
            case "REQUEST_GET_ARTICLE":
                return { ...state, isFetchingArticle: true };
    
            case "SUCCESS_GET_ARTICLE":
                return {
                    ...state, isFetchingArticle: false,
                    getArticleList: action.payload
                };
    
            case "ERROR_GET_ARTICLE":
                return { ...state, isFetchingArticle: false };


                case "REQUEST_GET_EMPLOYEE":
            return { ...state, isFetchingEmployeeData: true };
        case "SUCCESS_GET_EMPLOYEE":
            return {
                ...state,
                employeeList: action.payload.data,
                isFetchingEmployeeData: false,
            };
        case "ERROR_GET_EMPLOYEE":
            return { ...state, isFetchingEmployeeData: false };


            case "REQUEST_GET_PROCESS":
                return { ...state, isFetchingProcessData: true };
            case "SUCCESS_GET_PROCESS":
                return {
                    ...state,
                    processList: action.payload.data,
                    isFetchingProcessData: false,
                };
            case "ERROR_GET_PROCESS":
                return { ...state, isFetchingProcessData: false };


                case "REQUEST_ADD_LABOUR":
                    return { ...state, isAddingLabourData: false };
                case "SUCCESS_ADD_LABOUR":
                    return {
                        ...state,
                        addLabour: action.payload,
                        isAddingLabourData: true,
                    };
                case "ERROR_ADD_LABOUR":
                    return { ...state, isAddingLabourData: false };

                    case "REQUEST_GET_PROCESS_CATEGORY":
                        return { ...state, isFetchingCategoryProcessData: true };
                    case "SUCCESS_GET_PROCESS_CATEGORY":
                        return {
                            ...state,
                            categoryProcessList: action.payload.data,
                            isFetchingCategoryProcessData: false,
                        };
                    case "ERROR_GET_PROCESS_CATEGORY":
                        return { ...state, isFetchingCategoryProcessData: false };



                        case "REQUEST_GET_PROCESS_DATA_BY_CATEGORY":
                            return { ...state, isFetchingProcessDataByCategory: true };
                        case "SUCCESS_GET_PROCESS_DATA_BY_CATEGORY":
                            return {
                                ...state,
                                processListByCategoty: action.payload.data,
                                isFetchingProcessDataByCategory: false,
                            };
                        case "ERROR_GET_PROCESS_DATA_BY_CATEGORY":
                            return { ...state, isFetchingProcessDataByCategory: false };


        default:
            return state;
    }
}

export default AddLabourReducer;
