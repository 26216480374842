import React from "react";
import { Card, Tabs, Tab, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { isNull } from "lodash";
import CompanySalaryReport from "./companySalaryReport";
import AppointmentLetter from "./AppointmentLetter";
import EmpCard from "./EmpCard";


const SalaryManagement = (props) => {
    const permissions = JSON.parse(localStorage.getItem("permissions"));

    return (
        <>
            <Tabs defaultActiveKey={!isNull(permissions) && permissions.some(p => p['sub_id'] === 67) ?
                    "AppointmentLetter" : !isNull(permissions) && permissions.some(p => p['sub_id'] === 68) ?
                        "EmpCard" : ""}>
                {/* <Tab
                    eventKey="SalaryData"
                    title="Company Salary Report"
                    unmountOnExit={true}
                    disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 66) ? false : true}>
                    <CompanySalaryReport />
                </Tab> */}
                <Tab
                    eventKey="AppointmentLetter"
                    title="Appointment Letter"
                    unmountOnExit={true}
                    disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 67) ? false : true}>
                    <AppointmentLetter />
                </Tab>
                <Tab
                    eventKey="EmpCard"
                    title="Employee Card"
                    unmountOnExit={true}
                    disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 68) ? false : true}>
                    <EmpCard />
                </Tab>
            </Tabs>

        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
    };
};
const mapStateToProps = (state, ownProps) => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SalaryManagement);