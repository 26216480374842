import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, Alert } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import "jspdf-autotable";
import Select from "react-select";
import {
  addCustomerCall,
  getCustomerCall,
  getDivisionCall,
  updateCustomerCall,
  deleteCustomerCall,
} from "./production.actions";
import { rotateAndSkewTextDegreesAndTranslate } from "pdf-lib";
import { findAllByAltText } from "@testing-library/dom";

const AddCustomer = (props) => {
  const getDivision = props.divisionInfo.map((x) => {
    let data = { label: x.divisionName, value: x.divisionId };
    return data;
  });

  const [customerCompany, setCustomerCompany] = useState("");
  const [customerPerson, setCustomerPerson] = useState("");
  const [customerPhone, setCustomerPhone] = useState();
  const [customerEmail, setCustomerEmail] = useState("");
  const [customerWeb, setCustomerWeb] = useState("");
  const [customerContry, setCustomerContry] = useState("");
  const [customerCity, setCustomerCity] = useState("");
  const [customerDivision, setCustomerDivision] = useState("");

  const setDevision = (selectedOption) => {
    setCustomerDivision(selectedOption.value);
  };

  /* const [state, setState] = useState({
    columns: [
      { title: 'Name', field: 'customerPerson'},
      { title: 'Company Name', field: 'customerCompany'},
      { title: 'Email', field: 'customerEmail'},
      { title: 'Web', field: 'customerWeb'},
      { title: 'Phone', field: 'customerPhone'},
      { title: 'City', field: 'customerCity'},
      { title: 'Country', field: 'customerContry'},
      { title: 'Division', field: 'divisionId', lookup: { 1: 'Americas', 2: 'Europe', 3: 'Asia', 4: 'Africa', 5: 'Central America', 6: 'Eastern Europe', 7: 'South America'}},
    ],
  });*/

  const sendData = () => {
    props.addCustomerCall({
      customerCompany: customerCompany,
      customerPerson: customerPerson,
      customerPhone: customerPhone,
      customerEmail: customerEmail,
      customerWeb: customerWeb,
      customerContry: customerContry,
      customerCity: customerCity,
      divisionId: customerDivision,
    });
  };

  useEffect(() => {
    props.getDivisionCall();
    props.getCustomerCall();
  }, []);

  return (
    <Container fluid>
      <div className="main">
        <div>
          <Form>
            <Row>
              <Col lg="6" md="6" xs="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Name:</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="personName"
                    placeholder="Name...."
                    onBlur={(e) => setCustomerPerson(e.target.value)}
                  />
                </Form.Group>
              </Col>

              <Col lg="6" md="6" xs="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Company Name:</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="companyName"
                    placeholder="Company Name...."
                    onBlur={(e) => setCustomerCompany(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col lg="6" md="6" xs="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Email:</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="email"
                    placeholder="Email...."
                    onBlur={(e) => setCustomerEmail(e.target.value)}
                  />
                </Form.Group>
              </Col>

              <Col lg="6" md="6" xs="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Web:</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="web"
                    placeholder="Web...."
                    onBlur={(e) => setCustomerWeb(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col lg="6" md="6" xs="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Phone #:</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="phone"
                    placeholder="Phone...."
                    onBlur={(e) => setCustomerPhone(e.target.value)}
                  />
                </Form.Group>
              </Col>

              <Col lg="6" md="6" xs="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>City:</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="city"
                    placeholder="City...."
                    onBlur={(e) => setCustomerCity(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col lg="6" md="6" xs="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Country:</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="country"
                    placeholder="Country...."
                    onBlur={(e) => setCustomerContry(e.target.value)}
                  />
                </Form.Group>
              </Col>

              <Col lg="6" md="6" xs="12" styles={{ zIndex: -1 }}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Division:</b>
                  </Form.Label>
                  <Select
                    placeholder="Select Division..."
                    onChange={setDevision}
                    options={getDivision}
                  />
                </Form.Group>
              </Col>
            </Row>

            <div className="sendDiv">
              <Button className="sendButton" onClick={() => sendData()}>
                Save
              </Button>
            </div>

            <MaterialTable
              title="Department Details"
              columns={[
                { title: "Name", field: "customerPerson" },
                { title: "Company Name", field: "customerCompany" },
                { title: "Email", field: "customerEmail" },
                { title: "Web", field: "customerWeb" },
                { title: "Phone", field: "customerPhone" },
                { title: "City", field: "customerCity" },
                { title: "Country", field: "customerContry" },
                {
                  title: "Division",
                  field: "divisionId",
                  lookup: {
                    1: "Americas",
                    2: "Europe",
                    3: "Asia",
                    4: "Africa",
                    5: "Central America",
                    6: "Eastern Europe",
                    7: "South America",
                  },
                },
              ]}
              data={props.customerInfo}
              options={{
                actionsColumnIndex: -1,
                filtering: true,
                exportButton: true,
                exportAllData: true,
                maxBodyHeight: "600px",
                headerStyle: {
                  position: "sticky",
                  top: 0,
                  color: "#00BBBB",
                  fontWeight: "550",
                  onRowAdd: "none",
                },
              }}
              editable={{
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      {
                        props.updateCustomerCall(newData, oldData);
                      }
                    }, 600);
                  }),

                onRowDelete: (oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      props.deleteCustomerCall(oldData.customerId);
                    }, 600);
                  }),
              }}
            />
          </Form>
        </div>
      </div>
    </Container>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addCustomerCall: (data) => dispatch(addCustomerCall(data)),
    getCustomerCall: () => dispatch(getCustomerCall()),
    getDivisionCall: () => dispatch(getDivisionCall()),
    updateCustomerCall: (newData, oldData) =>
      dispatch(updateCustomerCall(newData, oldData)),
    deleteCustomerCall: (id) => dispatch(deleteCustomerCall(id)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  customerInfo: state.productionReducer.customerInfo,
  divisionInfo: state.productionReducer.divisionInfo,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddCustomer);
