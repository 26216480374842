import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Container,
  Button,
  FormGroup,
  Tabs,
  Tab,
  Alert,
} from "react-bootstrap";
import "./add-details.css";
import {
  getCategoryData,
  getWarehouseData,
  getsubCategoryData,
  addInventory,
  getInventory,
  deleteInventory,
  updateInventory,
  resetReducer,
  uploadFile,
  getAllInventory,
  categoryByWareHouse,
  subCategoryByCategory,
} from "./AddDetails.actions";
import { connect } from "react-redux";
import { isEmpty, get, find, chain, uniqBy, isNull } from "lodash";
import moment from "moment";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import logo from "../../../../Images/logo.png";
import jsPDF from "jspdf";
import Select from "react-select";
import InventoryReport from "./InventoryReport";
import StockList from "./StockList";
import Swal from "sweetalert";

const AddInventoryDetails = (props) => {
  const [subCategoryId, setSubCategoryId] = useState();
  const [subCategoryId1, setSubCategoryId1] = useState();
  const [wareHouseId, setwareHouseId] = useState();
  const [categoryId, setcategoryId] = useState();
  const [wareHouseId1, setwareHouseId1] = useState();
  const [categoryId1, setcategoryId1] = useState();
  const [file, setFile] = useState();

  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  const handleVisible = () => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: "Inventory Uploaded Successfully",
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = () => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: "Something went Wrong!",
      icon: "error",
      button: "Ok",
    });
  };

  const getWareHouseList = props.warehouseList.map((x) => {
    let data = { value: x.wareHouseId, label: x.wareHouseName };
    return data;
  });
  const getcategoryList =
    !isEmpty(props.categoryByWareHouseValue) &&
    props.categoryByWareHouseValue.map((x) => {
      let data = { value: x.categoryId, label: x.categoryName };
      return data;
    });
  const getSubCategoryList =
    !isEmpty(props.subCategoryByCategoryValue) &&
    props.subCategoryByCategoryValue.map((x) => {
      let data = { value: x.subCategoryId, label: x.subCategoryName };
      return data;
    });

  const handleChangeWareHouse = (selectedOption) => {
    setwareHouseId1(selectedOption.value);
    setwareHouseId(selectedOption.label);
    props.categoryByWareHouse(selectedOption.value);
  };

  const handleChangeCategory = (selectedOption) => {
    setcategoryId1(selectedOption.value);
    setcategoryId(selectedOption.label);
    props.subCategoryByCategory(selectedOption.value);
  };

  const handleChangeSubCategory = (selectedOption) => {
    setSubCategoryId(selectedOption.value);
    setSubCategoryId1(selectedOption.label);
  };

  function uploadFileData() {
    props.uploadFile(
      {
        file: file,
        wareHouseId: wareHouseId1,
        categoryId: categoryId1,
        subCategoryId: subCategoryId,
      },
      handleVisible,
      handleDangerVisible
    );
  }

  useEffect(() => {
    props.getWarehouseData();
    setwareHouseId("");
    setcategoryId("");
    setSubCategoryId("");
    setSubCategoryId1("");
  }, []);

  return (
    <>
      {props.isFetchingwareHouseList ||
      props.isFetchingcategoryList ||
      props.isFetchingsubCategoryList ||
      props.isFetchingUploadFile ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {props.isFetchingUploadFile ? "Uploading" : "Loading"}
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Row>
              <Col xl="4" lg="4" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b> Select Warehouse </b>
                  </Form.Label>
                  <Select
                    value={{ label: wareHouseId }}
                    placeholder="Select WareHouse..."
                    onChange={(e) => handleChangeWareHouse(e)}
                    options={getWareHouseList}
                  />
                </Form.Group>
              </Col>

              <Col xl="4" lg="4" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b> Select Category </b>
                  </Form.Label>
                  <Select
                    isDisabled={
                      isEmpty(getcategoryList) || isEmpty(wareHouseId)
                    }
                    value={{ label: categoryId }}
                    placeholder="Select Category..."
                    onChange={(e) => handleChangeCategory(e)}
                    options={getcategoryList}
                  />
                </Form.Group>
              </Col>

              <Col xl="4" lg="4" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b> Select Sub-Category </b>
                  </Form.Label>
                  <Select
                    isDisabled={
                      isEmpty(getSubCategoryList) || isEmpty(categoryId)
                    }
                    value={{ label: subCategoryId1 }}
                    placeholder="Select Sub Category..."
                    onChange={(e) => handleChangeSubCategory(e)}
                    options={getSubCategoryList}
                  />
                </Form.Group>
              </Col>
            </Row>
            <div style={{ paddingTop: "2%" }}>
              <Row>
                <Col xl="4" lg="4" md="2" sm="2" xs="2"></Col>
                <Col xl="4" lg="4" md="4" sm="4" xs="4">
                  <Form.Group>
                    <Form.Label>
                      <b style={{ fontSize: "x-large" }}>
                        Upload Accounts File
                      </b>
                    </Form.Label>

                    <input
                      className="form-control"
                      type="file"
                      name="file"
                      class="Upload File"
                      id="upload file"
                      onChange={(e) => setFile(e.target.files[0])}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </div>
            <div className="sendDiv">
              {categoryId == "" ||
              wareHouseId == "" ||
              subCategoryId == "" ||
              file == "" ? (
                <Button
                  className="sendButton"
                  disabled
                  style={{ color: "black" }}
                >
                  Save
                </Button>
              ) : (
                <Button className="sendButton" onClick={() => uploadFileData()}>
                  Save
                </Button>
              )}
            </div>
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getCategoryData: () => dispatch(getCategoryData()),
    getWarehouseData: () => dispatch(getWarehouseData()),
    getsubCategoryData: () => dispatch(getsubCategoryData()),
    addInventory: (data, handleVisible, handleDangerVisible) =>
      dispatch(addInventory(data, handleVisible, handleDangerVisible)),
    getInventory: (id) => dispatch(getInventory(id)),
    deleteInventory: (inventoryId) => dispatch(deleteInventory(inventoryId)),
    updateInventory: (newData, oldData) =>
      dispatch(updateInventory(newData, oldData)),
    resetState: () => dispatch(resetReducer()),
    uploadFile: (data, handleVisible, handleDangerVisible) =>
      dispatch(uploadFile(data, handleVisible, handleDangerVisible)),
    categoryByWareHouse: (warehouseId) =>
      dispatch(categoryByWareHouse(warehouseId)),
    subCategoryByCategory: (categoryId) =>
      dispatch(subCategoryByCategory(categoryId)),
    getAllInventory: (data) => dispatch(getAllInventory(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  warehouseList: state.AddDataReducer.warehouseList,
  categoryList: state.AddDataReducer.categoryList,
  subCategoryList: state.AddDataReducer.subCategoryList,
  inventoryList: state.AddDataReducer.inventoryList,
  isFetchingwareHouseList: state.AddDataReducer.isFetchingwareHouseList,
  isFetchingcategoryList: state.AddDataReducer.isFetchingcategoryList,
  isFetchingsubCategoryList: state.AddDataReducer.isFetchingsubCategoryList,
  isFetchingInventoryGetdata: state.AddDataReducer.isFetchingInventoryGetdata,
  dataAddedInventory: state.AddDataReducer.dataAddedInventory,
  isFetchingUploadFile: state.AddDataReducer.isFetchingUploadFile,
  addUploadFile: state.AddDataReducer.addUploadFile,
  categoryByWareHouseValue: state.AddDataReducer.categoryByWareHouseValue,
  subCategoryByCategoryValue: state.AddDataReducer.subCategoryByCategoryValue,
  allInventory: state.AddDataReducer.allInventory,
  isFetchingUploadFile: state.AddDataReducer.isFetchingUploadFile,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddInventoryDetails);
