import API from "../../../global/api";
export const SET_MODULE_KEY = "SET_MODULE_KEY";

//Action types

export const loginAdminRequest = () => {
  return {
    type: "LOGIN_ADMIN_REQUEST",
  };
};
export const loginAdminSuccess = (data) => {
  return {
    type: "LOGIN_ADMIN_SUCCESS",
    payload: data,
  };
};
export const loginAdminError = () => {
  return {
    type: "LOGIN_ADMIN_ERROR",
  };
};


export const setModuleKey = (data) => {
  return {
    type: "SET_MODULE_KEY",
    payload: data,
  };
};

export const getLogin = (username) => {
  return (dispatch) => {
    dispatch(loginAdminRequest())
    API.post(`/admin/authenticate-admin`, username).then((res) => {
      let data1 = res.data;
      dispatch(loginAdminSuccess(data1));
      console.log(data1, "ggtt")

      window.localStorage.setItem("token", data1.token);
      window.localStorage.setItem("adminId", data1.adminId);
      window.localStorage.setItem("isAuthenticatedAdmin", "true");

      window.localStorage.setItem("isAdmin", "true");
      window.localStorage.setItem("isUser", "false");
      window.localStorage.setItem("isAuthenticated", "true");

      window.location.replace('/admin-home');
      
    }).catch((error) => {
      dispatch(loginAdminError());
    })
  };
};