import React, { useState } from 'react'
import { Container, Form, Row, Col, Button } from 'react-bootstrap';
// import { addHREmployeeType, getHRDivision, deleteHRDivision, UpdateHRDivision } from './HRDetails.actions';
import { connect } from "react-redux";
import Spinner from 'react-bootstrap/Spinner';
import MaterialTable from 'material-table';
import { useEffect } from 'react';
import Swal from 'sweetalert';
import { isEmpty, upperCase } from "lodash";

import { addHREmployeeType, getHREmployeeType, UpdateHREmployeeType, deleteHREmployeeType } from './HRDetails.actions'

const EmployeeType = (props) => {
    const [employeeTypeName, setEmployeeTypeName] = useState();
    const [employeeTypeDescription, setEmployeeTypeDescription] = useState();

    const [submit, setSubmit] = useState(false);

    const [visibleAlert, setAlertVisible] = useState(false);
    const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);


    function uploadEmployeeType() {
        props.addHREmployeeType({
            employee_type_name: upperCase(employeeTypeName),
            employee_type_desc: upperCase(employeeTypeDescription),
        }, handleVisible, handleDangerVisible);
    }
    const employeeTypeData = isEmpty(props.employeeTypeList) ? [] : props.employeeTypeList.reverse();
    console.log('employeeTypeData', employeeTypeData);

    /*const [state, setState] = React.useState({
        columns: [
            { title: 'EmployeeType Name', field: 'employee_type_name' },
            { title: 'EmployeeType Description', field: 'employee_type_desc' },
        ],
    });*/
    const clearStates = () => {
        setEmployeeTypeName();
        setEmployeeTypeDescription();
        setSubmit(false);
    }
    const handleVisible = (message) => {
        setAlertVisible(true)
        clearStates();
        setTimeout(() => {
            setAlertVisible(false)
        }, 9000);
        Swal({
            title: "Success",
            text: "Added Sccesfully...",
            // text: {message},
            icon: "success",
            button: "Ok",
        });
    }

    const handleDangerVisible = (message) => {
        setDangerVisibleAlert(true)
        setTimeout(() => {
            setDangerVisibleAlert(false)
        }, 9000);
        Swal({
            title: "Error",
            text: "Something went wrong...",
            // text: {message},
            icon: "error",
            button: "Ok",
        });
    }

    return (

        <Container fluid>
            {props.isAddingEmployeeTypeData ?
                <div className="loader-div">
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />
                        Adding Employee Type 
                    </Button>
                </div>
                :
                <div>
                    <Form>
                        <Row>
                            <Col lg="12" md="12" xs="12">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Enter Employee Type Name :</b></Form.Label>
                                    <Form.Control type="text" placeholder="EmployeeType Name...." onChange={(e) => setEmployeeTypeName(e.target.value)} />
                                </Form.Group>
                            </Col>
                        </Row>


                        <Row>
                            <Col xl="12" lg="12" md="12" sm="12" xs="12">
                                <Form.Group className="mb-3">
                                    <Form.Label>
                                        <b>Enter Employee Type Description:</b>
                                    </Form.Label>
                                    <Form.Control as="textarea" rows={3}
                                        placeholder="EmployeeType Description....." onBlur={(e) => setEmployeeTypeDescription(e.target.value)} />
                                </Form.Group>
                            </Col>
                        </Row>

                        <div className="sendDiv">
                        {isEmpty(employeeTypeName)?
                            <Button className="sendButton" style={{backgroundColor:"black"}} onClick={() => { uploadEmployeeType(); setSubmit(true); }} disabled>Save</Button>:
                            <Button className="sendButton" onClick={() => { uploadEmployeeType(); setSubmit(true); }} >Save</Button>}
                        </div>
                    </Form>
                  

                </div>
            }
        </Container>

    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        addHREmployeeType: (data, handleVisible, handleDangerVisible) => dispatch(addHREmployeeType(data, handleVisible, handleDangerVisible)),
        
    };
};
const mapStateToProps = (state, ownProps) => ({
    employeeTypeList: state.HrReducer.employeeTypeList,
    addEmployeeType: state.HrReducer.addEmployeeType,
    isAddingEmployeeTypeData: state.HrReducer.isAddingEmployeeTypeData,
    isFetchingEmployeeTypeData: state.HrReducer.isFetchingEmployeeTypeData,



});




export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EmployeeType);