import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import Select from 'react-select';
import { isEmpty, isNull, find } from "lodash";
import { getEmployeeData, getEmployeeDataWithStatus } from "../AddDetails/HRDetails.actions";
import { addAttendance } from './salary.actions';
import { connect } from "react-redux";
import HrReducer from "../AddDetails/HRDetails.reducers"
import Swal from 'sweetalert';
import Spinner from 'react-bootstrap/Spinner';
import { appendNonSignificantZeros } from '../../../../../global/globalFunctions';



const AddAttendanceManually = (props) => {


    const [employeeId, setEmployeeId] = useState('');
    const [employeeName, setEmployeeName] = useState('');
    const [attendanceDate, setAttendanceDate] = useState('');
    const [timeIn, setTimeIn] = useState(null);
    const [breakIn, setBreakIn] = useState(null);
    const [breakOut, setBreakOut] = useState(null);
    const [timeOut, setTimeOut] = useState(null);
    const [ts, setTs] = useState(1);
    //const [shiftId] = useState((props.employeeList[0].salaries[0].shift_id)==1)


    const [attendanceDay, setAttendanceDay] = useState('');
    console.log(attendanceDay, (attendanceDay === 'Sunday'));

    const [AlertVisible, setAlertVisible] = useState(false);
    const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

    const moment = require('moment');
    // const getEmployeeList = !isEmpty(props.employeeList) && props.employeeList.map((x) => { let data = { value: x.employeeId, label: `${appendNonSignificantZeros(x.employeeId)}-${x.name}` }; return data })

    const getEmployeeList = !isEmpty(props.activeEmployeeList) && props.activeEmployeeList.map((x) => { let data = { value: x.employeeId, label: `${appendNonSignificantZeros(x.employeeId)}-${x.name}` }; return data })
    !isEmpty(getEmployeeList) && getEmployeeList.push({ value: "All", label: "All" });
    console.log('props.employeeList', props.employeeList)

    //console.log(getEmployeeList,'data')
    console.log('props.activeEmployeeList', props.activeEmployeeList);

    const handleChangeEmployee = (selectedOption) => {
        if (selectedOption.value === 'All') {
            setTs(2);
        }
        else {
            const selectedEmployee = find(props.employeeList, x => x.employeeId == selectedOption.value);
            console.log('selectedEmployee', selectedEmployee);
            console.log('selectedEmployee.salaries[0].salary_creation_based==="Master"', selectedEmployee.salaries[0].salary_creation_based === "Master")
            let z = (isEmpty(selectedEmployee.salaries)) ? 2 : (selectedEmployee.salaries[0].salary_creation_based === "Master" || selectedEmployee.salaries[0].shift_control === null) ? 2 : Number(selectedEmployee.salaries[0].shift_control.shift_mangement);
            setTs(z);
            console.log(z, 'zzz')

            // (props.employeeList.salaries[0].salary_creation_based==="Master") && setTs(2);
        }
        setEmployeeId(selectedOption.value);
        setEmployeeName(selectedOption.label);

    };

    const saveAttendance = () => {
        props.addAttendance({
            employee_id: employeeId,
            attendance_date: attendanceDate,
            attendance_in_time: timeIn,
            break_in_time: timeOut,
            break_out_time: breakOut,
            attendance_out_time: breakIn,
            month_year: moment(attendanceDate).format("MM-YYYY")
        }, handleVisible, handleDangerVisible);


    }

    const clearStates = () => {

        setEmployeeId('');
        setEmployeeName('');
        setAttendanceDate('');
        setTimeIn(null);
        setBreakIn(null);
        setBreakOut(null);
        setTimeOut(null);
        setTs(1);
    }

    const handleVisible = () => {
        setAlertVisible(true);
        clearStates();
        setTimeout(() => {
            setAlertVisible(false)
        }, 9000);
        Swal({
            title: "Success",
            text: "Added successfully...",
            icon: "success",
            button: "Ok",
        });
    }

    const handleDangerVisible = () => {
        setDangerVisibleAlert(true)
        setTimeout(() => {
            setDangerVisibleAlert(false)
        }, 9000);
        Swal({
            title: "Error",
            text: "Please Add Data in Input fields...",
            icon: "error",
            button: "Ok",
        });
    }

    useEffect(() => {
        props.getEmployeeData();
        props.getEmployeeDataWithStatus(1);
    }, []);

    //  const getShift = !isEmpty(props.employeeList) && (props.employeeList[0].salaries) && (props.employeeList[0].salaries[0].shift_id);
    //  console.log(getShift,'shift')

    return (
        <div className="main" >
            {(props.isFetchingActiveEmployeeData) ? (
                <div className="loader-div">
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />
                    </Button>
                </div>
            ) : (
                <Container fluid>
                    <div>
                        <Form>
                            <Row>
                                <Col lg="12" md="12" xs="12">
                                    <Form.Group className="mb-3" controlid="garana">
                                        <Form.Label><b>Select Employee:</b></Form.Label>
                                        <Select
                                            isDisabled={isEmpty(getEmployeeList)}
                                            placeholder="Select Employee..."
                                            onChange={handleChangeEmployee}
                                            options={getEmployeeList}
                                        />
                                    </Form.Group>

                                </Col>
                            </Row>
                            <Row>
                                <Col lg="12" md="12" xs="12">
                                    <Form.Group className="mb-3" controlid="garana">
                                        <Form.Label><b>Date:</b></Form.Label>
                                        <Form.Control type="date" controlid="garana" placeholder="Attendance Date" onBlur={(e) => { setAttendanceDate(e.target.value); setAttendanceDay(moment(e.target.value).format('dddd')); console.log('attendanceDate', moment(attendanceDate).format('dddd'), attendanceDay) }} />

                                    </Form.Group>

                                </Col>
                            </Row>
                            {ts === 2 ?
                                <><div>
                                    <Row>
                                        <Col lg="6" md="6" xs="6">
                                            <Form.Group className="mb-3" controlid="garana"
                                            >
                                                <Form.Label>Time In</Form.Label>
                                                <Form.Control type="time" controlid="garana" placeholder="Time In" onBlur={(e) => setTimeIn(e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                        <Col lg="6" md="6" xs="6">
                                            <Form.Group className="mb-3" controlid="garana">
                                                <Form.Label>Break Out</Form.Label>
                                                <Form.Control type="time" controlid="garana" placeholder="Break Out " onBlur={(e) => setBreakOut(e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="6" md="6" xs="6">
                                            <Form.Group className="mb-3" controlid="garana">
                                                <Form.Label>Break In</Form.Label>
                                                <Form.Control type="time" controlid="garana" placeholder="Break In " onBlur={(e) => setBreakIn(e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                        <Col lg="6" md="6" xs="6">
                                            <Form.Group className="mb-3" controlid="garana">
                                                <Form.Label>Time Out</Form.Label>
                                                <Form.Control type="time" controlid="garana" placeholder="Time Out " onBlur={(e) => setTimeOut(e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </div><div className="sendDiv">
                                        <Button className="sendButton"
                                            onClick={() => { (saveAttendance()); }}
                                            disabled={isEmpty(employeeName) || isEmpty(attendanceDate) || isEmpty(timeIn) || isEmpty(breakIn) || isEmpty(breakOut) || isEmpty(timeOut) || (attendanceDay === 'Sunday')}
                                        >Save</Button>
                                    </div></> :
                                <div>
                                    <Row>
                                        <Col lg="6" md="6" xs="6">
                                            <Form.Group className="mb-3" controlid="garana"
                                            >
                                                <Form.Label>Time In</Form.Label>
                                                <Form.Control type="time" controlid="garana" placeholder="Time In" onBlur={(e) => setTimeIn(e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                        <Col lg="6" md="6" xs="6">
                                            <Form.Group className="mb-3" controlid="garana">
                                                <Form.Label>Time Out</Form.Label>
                                                <Form.Control type="time" controlid="garana" placeholder="Time Out " onBlur={(e) => setTimeOut(e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <div className="sendDiv">
                                        <Button className="sendButton"
                                            onClick={() => { (saveAttendance()); }}
                                            disabled={isEmpty(employeeName) || isEmpty(attendanceDate) || isEmpty(timeIn) || isEmpty(timeOut) || (attendanceDay === 'Sunday')}
                                        >Save</Button>
                                    </div>
                                </div>}

                        </Form>
                    </div>
                </Container>
            )}
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        addAttendance: (data, handleVisible, handleDangerVisible) => dispatch(addAttendance(data, handleVisible, handleDangerVisible)),
        getEmployeeData: () => dispatch(getEmployeeData()),
        getEmployeeDataWithStatus: (status) => dispatch(getEmployeeDataWithStatus(status)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    employeeList: state.HrReducer.employeeList,
    isFetchingEmployeeData: state.HrReducer.isFetchingEmployeeData,

    activeEmployeeList: state.HrReducer.activeEmployeeList,
    isFetchingActiveEmployeeData: state.HrReducer.isFetchingActiveEmployeeData,

});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddAttendanceManually);