const initialState = {
  isLogin: false,
  loginUserId: "",
  unableLogin: true,
  userDetails: {},
  moduleKey: "",
  isTrialBalance: false,
  trialList: [],
  isFetchingLevel5: false,
  level5DataList: [],
  isWrongCredentials: false,
  isFetchingTrialBalance: false,
  isFetchingProfitLoss: false,
  ProfitLoss: [],
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN_USER_REQUEST":
      return { ...state, isLogin: true, isWrongCredentials: false };
    case "LOGIN_USER_SUCCESS":
      return {
        ...state,
        isLogin: false,
        userDetails: action.payload,
        isWrongCredentials: false,
      };
    case "LOGIN_USER_ERROR":
      return { ...state, isLogin: false, isWrongCredentials: true };

    case "SET_MODULE_KEY":
      return { ...state, moduleKey: action.payload };
    case "REQUEST_TRIAL_DATA":
      return { ...state, isFetchingTrialBalance: true };
    case "SUCCESS_TRIAL_DATA":
      return {
        ...state,
        trialList: action.payload,
        isFetchingTrialBalance: false,
      };
    case "ERROR_TRIAL_DATA":
      return { ...state, isFetchingTrialBalance: false };
    case "REQUEST_GET_LEVEL_5":
      return { ...state, isFetchingLevel5: true };
    case "SUCCESS_GET_LEVEL_5":
      return {
        ...state,
        level5DataList: action.payload,
        isFetchingLevel5: false,
      };
    case "ERROR_GET_LEVEL_5":
      return { ...state, isFetchingLevel5: false };
    case "REQUEST_GENERATE_REPOR":
      return { ...state, isFetchingProfitLoss: true };
    case "SUCCESS_GENERATE_REPOR":
      return {
        ...state,
        ProfitLoss: action.payload,
        isFetchingProfitLoss: false,
      };
    case "ERROR_GENERATE_REPOR":
      return { ...state, isFetchingProfitLoss: false };
    default:
      return state;
  }
};

export default userReducer;
