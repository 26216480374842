import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Container,
  Button,
  FormGroup,
  Tabs,
  Tab,
  Alert,
} from "react-bootstrap";
import "./hr-styles.css";
import { connect } from "react-redux";
import { isEmpty, get, find, chain, uniqBy, isNull } from "lodash";
import moment from "moment";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import {
  getEmployeeData,
  HRUpdateEmployee,
  deleteHR,
} from "./HRDetails.actions";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Redirect, Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { appendNonSignificantZeros } from "../../../../../global/globalFunctions";

const ViewEmployee = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  console.log(anchorEl, "eeeeeeeeee");
  const [employeeDataList, setEmployeeDataList] = React.useState("");
  console.log(employeeDataList, "emidddd");

  const handleClick = (event, rowData) => {
    setAnchorEl(event.currentTarget);
    setEmployeeDataList(rowData);
    console.log(rowData, "rowwwwwwww");
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const employeeData = props.employeeList.reverse();

  useEffect(() => {
    props.getEmployeeData();
  }, []);

  const [callEdit, setcallEdit] = React.useState(false);
  if (callEdit) {
    return <Redirect push to="/user/view-user" />;
  }

  return (
    <>
      {props.isFetchingEmployeeData ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <MaterialTable
              title="Employees Details"
              columns={uniqBy(
                [
                  // { title: 'CNIC', field: 'cnic' },employeeId
                  { title: "Employee Id", field: "employeeId" },
                  {
                    title: "Machine Id",
                    render: (rowData) =>
                      !isEmpty(rowData.salaries)
                        ? `${rowData.salaries[0].machine_id} `
                        : "-",
                  },
                  { title: "Name", field: "name" },
                  { title: "Father Name", field: "father_name" },
                  { title: "Department", field: "department.departmentName" },
                  {
                    title: "Designation",
                    field: "designation.designationName",
                  },

                  {
                    title: "Total Allowances",
                    render: (rowData) =>
                      !isEmpty(rowData.salaries)
                        ? `${
                            rowData.salaries[0].accomodation_allowance +
                            rowData.salaries[0].other_allowance +
                            rowData.salaries[0].petrol_allowance +
                            rowData.salaries[0].medicial_allowance +
                            rowData.salaries[0].special_allowance
                          } `
                        : "-",
                  },
                  {
                    title: "Total Salary",
                    render: (rowData) =>
                      !isEmpty(rowData.salaries)
                        ? `${
                            rowData.salaries[0].accomodation_allowance +
                            rowData.salaries[0].other_allowance +
                            rowData.salaries[0].petrol_allowance +
                            rowData.salaries[0].medicial_allowance +
                            rowData.salaries[0].special_allowance +
                            rowData.salaries[0].basic_salary
                          } `
                        : "-",
                  },
                  { title: "Joining Date", field: "joining_date" },
                  { title: "Employee Type", field: "employee_type" },
                  {
                    title: "Active Status",
                    field: "active_status",
                    render: (rowData) =>
                      rowData.active_status === true ? "Active" : "InActive",
                  },

                  // { title: 'Gender'
                ],
                "title"
              )}
              data={employeeData}
              options={{
                actionsColumnIndex: -1,
                filtering: true,
                exportButton: true,
                exportAllData: true,
                pageSize: 20, // make initial page size
                emptyRowsWhenPaging: false, // To avoid of having empty rows
                pageSizeOptions: [50, 100, 150, 200], // rows selection options
                maxBodyHeight: "600px",
                headerStyle: {
                  position: "sticky",
                  top: 0,
                  color: "#00BBBB",
                  fontWeight: "550",
                  onRowAdd: "none",
                },
              }}
              // actions={[
              //     // (rowData) => {
              //     //     return { icon: EditIcon, disabled: false, onClick: (event, rowData) => { handleClick(event, rowData) } }
              //     // },
              // ]}
              // editable={{

              //     // onRowUpdate: (newData, oldData) =>
              //     //     new Promise((resolve) => {
              //     //         setTimeout(() => {
              //     //             resolve();
              //     //             if (oldData) {
              //     //                 props.HRUpdateEmployee(newData, oldData)

              //     //             }
              //     //         }, 600);
              //     //     }),
              //     onRowDelete: (oldData) =>
              //         new Promise((resolve) => {
              //             setTimeout(() => {
              //                 resolve();
              //                 props.deleteHR(oldData.employeeId);
              //             }, 600);
              //         }),
              // }}
            />
          </div>

          {/* <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <Link
                            to={{
                                pathname: "/user/edit-employee",
                                state: employeeDataList
                            }}>
                            <MenuItem>
                                Edit Employee
                            </MenuItem>
                        </Link>

                        <Link
                            to={{
                                pathname: "/user/edit-salary",
                                state: employeeDataList
                            }}>
                            {console.log('employeeDataList', employeeDataList)}
                            <MenuItem>
                                Edit Salary
                            </MenuItem>
                        </Link>
                    </Menu> */}
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getEmployeeData: () => dispatch(getEmployeeData()),
    updateHR: (data, oldData) => dispatch(HRUpdateEmployee(data, oldData)),
    deleteHR: (data) => dispatch(deleteHR(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  employeeList: state.HrReducer.employeeList,
  isFetchingEmployeeData: state.HrReducer.isFetchingEmployeeData,
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewEmployee);
