import React, { useEffect, useState } from "react";
import { Row, Col, Form, Table, Container, Button, Card } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty, filter, sumBy } from "lodash";
import moment from 'moment';
import Spinner from 'react-bootstrap/Spinner';
import "./style.css";
import Select from 'react-select';
import MaterialTable from 'material-table';
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { InputAdornment } from "@mui/material";
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import { setEditVoucherFields, updateVoucherNew } from "./Voucher.action";
import { useHistory } from 'react-router-dom';
import swal from "sweetalert";
const VoucherEdit = (props) => {
    const history = useHistory();
    const [open, setOpen] = useState(false)
    const [creditSum, setCreditSum] = useState()
    const [debitSum, setDebitSum] = useState()

    const [visibleAlert, setAlertVisible] = useState(false);
    const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
    const handleVisible = (data) => {
        setAlertVisible(true);
        setTimeout(() => {
            setAlertVisible(false);
        }, 9000);
        swal({
            title: "Success",
            text: "Voucher Update successfully...",
            icon: "success",
            buttons: "Ok",
        }).then((ok) => {
            console.log(ok, "okkkkkkkk")
            if (ok) {
                //subPage()
                history.goBack()
                // previewVoucher(data, "Pending");
            } else {
                // setShowDialog(false)
            }
        });
    };

    const handleDangerVisible = () => {
        setDangerVisibleAlert(true);
        setTimeout(() => {
            setDangerVisibleAlert(false);
        }, 9000);
        swal({
            title: "Error",
            text: "Something went wrong...",
            icon: "error",
            button: "Ok",
        });
    };
    useEffect(() => {

    }, []);

    const changeAmount = (e, key, i) => {
        const list = [...props.vDataObject];
        console.log(list, 'listttt')
        key == 'voucherAmount' ? list[i][key] = Number(e.target.value) : list[i][key] = e.target.value
        console.log(list, 'listtttttttttt')
        props.setEditVoucherFields(list)
        let debit = filter(props.vDataObject, { accountType: '2' })
        let credit = filter(props.vDataObject, { accountType: '1' })
        // setDebitSum(sumBy(debit, 'voucherAmount'))
        // setCreditSum(sumBy(credit, 'voucherAmount'))
        let debitSum=sumBy(debit, 'voucherAmount')
        let creditSum=sumBy(credit, 'voucherAmount')
        console.log(debitSum, creditSum, "aaaaaaqqq")
        if (debitSum == creditSum) {
            setOpen(true)
        }
    };
    const updateVoucher = () => {
        console.log(props.vDataObject, props.voucherDataById[0], "aaaaaaaa123")
        props.updateVoucherNew({ data: props.vDataObject }, props.voucherDataById[0].voucherId, handleVisible, handleDangerVisible)
        // history.push('/user/VoucherView')
    };



    return (
        <>
            {props.isFetchingVoucherById ? (
                <div className="loader-div">
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />
                        Loading...
                    </Button>
                </div>
            ) : (
                <Container fluid>
                    {console.log(props.vDataObject, "aaaavDataObjectaaaa")}
                    {!isEmpty(props.vDataObject) && props.vDataObject.map((item, i) => {
                        return (
                            <React.Fragment key={i}>
                                <>
                                    <Card
                                        style={{
                                            marginTop: "10px",
                                            padding: "20px",
                                            borderLeftWidth: "10px",
                                        }}
                                    >
                                        <Row>
                                            {/* xl="3" lg="3" md="3" sm="3" xs="6" */}
                                            <Col >
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label>Account Title</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Account Title"
                                                        // id="service"
                                                        // name="service"
                                                        value={item.accountTittle}
                                                        disabled
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col >
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label>Voucher Amount</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        placeholder="Voucher Amount"
                                                        // id="service"
                                                        // name="service"
                                                        value={item.voucherAmount}
                                                        onChange={(e) => { changeAmount(e, 'voucherAmount', i) }}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col >
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label>DR/CR</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="DR/CR"
                                                        // id="service"
                                                        // name="service"
                                                        value={item.accountType == "2" ? "Debit" : item.accountType == "1" ? "Credit" : ""}
                                                        disabled
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col >
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label>Bill No</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Bill No"
                                                        // id="service"
                                                        // name="service"
                                                        value={item.billNo}
                                                        onChange={(e) => { changeAmount(e, 'billNo', i) }}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col >
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label>Voucher Description</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Voucher Description"
                                                        // id="service"
                                                        // name="service"
                                                        value={item.voucherDescription}
                                                        onChange={(e) => { changeAmount(e, 'voucherDescription', i) }}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col >
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label>Tracking No</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Tracking No"
                                                        // id="service"
                                                        // name="service"
                                                        value={item.trackingNo}
                                                        onChange={(e) => { changeAmount(e, 'trackingNo', i) }}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col >
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label>Destination</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Destination"
                                                        // id="service"
                                                        // name="service"
                                                        value={item.destination}
                                                        onChange={(e) => { changeAmount(e, 'destination', i) }}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col >
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label>Packages</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Packages"
                                                        // id="service"
                                                        // name="service"
                                                        value={item.packages}
                                                        onChange={(e) => { changeAmount(e, 'packages', i) }}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col >
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label>Weight</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Weight"
                                                        // id="service"
                                                        // name="service"
                                                        value={item.weight}
                                                        onChange={(e) => { changeAmount(e, 'weight', i) }}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col >
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label>Weight Charges</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Weight Charges"
                                                        // id="service"
                                                        // name="service"
                                                        value={item.weightCharges}
                                                        onChange={(e) => { changeAmount(e, 'weightCharges', i) }}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col >
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label>Rate First Kg</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Rate First Kg"
                                                        // id="service"
                                                        // name="service"
                                                        value={item.rateFirstKg}
                                                        onChange={(e) => { changeAmount(e, 'rateFirstKg', i) }}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col >
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label>Rate Add Kg</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Rate Add Kg"
                                                        // id="service"
                                                        // name="service"
                                                        value={item.rateAddKg}
                                                        onChange={(e) => { changeAmount(e, 'rateAddKg', i) }}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col >
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label>Rate Flat</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Rate Flat"
                                                        // id="service"
                                                        // name="service"
                                                        value={item.rateFlat}
                                                        onChange={(e) => { changeAmount(e, 'rateFlat', i) }}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col >
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label>Service</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Service"
                                                        // id="service"
                                                        // name="service"
                                                        value={item.service}
                                                        onChange={(e) => { changeAmount(e, 'service', i) }}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Card>
                                </>
                            </React.Fragment>
                        );
                    })}
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Debit Sum</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Debit Sum"
                                    // id="service"
                                    // name="service"
                                    value={sumBy(
                                        filter(
                                            props.vDataObject,
                                          (x) => x.accountType == "2"
                                        ),
                                        (item) => Number(item.voucherAmount)
                                      )}
                                    disabled
                                    // onChange={(e) => { changeAmount(e, 'rateFlat', i) }}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Credit Sum</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Credit Sum"
                                    // id="service"
                                    // name="service"
                                    value={sumBy(
                                        filter(
                                            props.vDataObject,
                                          (x) => x.accountType == "1"
                                        ),
                                        (item) => Number(item.voucherAmount)
                                      )}
                                    disabled
                                    // onChange={(e) => { changeAmount(e, 'rateFlat', i) }}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Difference</Form.Label>
                      <Form.Control
                        type="text"
                        name="voucherDate"
                        value={Math.abs(
                          sumBy(
                            filter(
                                props.vDataObject,
                              (x) => x.accountType == "1"
                            ),
                            (item) => Number(item.voucherAmount)
                          ) -
                            sumBy(
                              filter(
                                props.vDataObject,
                                (x) => x.accountType == "2"
                              ),
                              (item) => Number(item.voucherAmount)
                            )
                        )}
                        isInvalid={
                          !Math.abs(
                            sumBy(
                              filter(
                                props.vDataObject,
                                (x) => x.accountType == "1"
                              ),
                              (item) => Number(item.voucherAmount)
                            ) ==
                              sumBy(
                                filter(
                                    props.vDataObject,
                                  (x) => x.accountType == "2"
                                ),
                                (item) => Number(item.voucherAmount)
                              )
                          )
                        }
                        disabled={true}
                      />
                    </Form.Group>
                        </Col>
                    </Row>
                    <Row>

                        <Col lg="12" sm="12" className="text-center">
                            {console.log(filter(props.vDataObject, { accountType: '2' }), "====", filter(props.vDataObject, { accountType: '2' }))}
                            {open == false ?

                                <Button disabled style={{ backgroundColor: "black" }} className="sendButton">Update Voucher</Button> :
                                <Button className="sendButton" onClick={(e) => updateVoucher()}>Update Voucher</Button>
                            }
                            {/* } onClick={(e) => updateVoucher()}  */}
                        </Col>
                    </Row>
                </Container>

            )}
        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
        setEditVoucherFields: (data) => dispatch(setEditVoucherFields(data)),
        updateVoucherNew: (data, id, handleVisible, handleDangerVisible) => dispatch(updateVoucherNew(data, id, handleVisible, handleDangerVisible)),

    };
};
const mapStateToProps = (state, ownProps) => ({
    isFetchingVoucherById: state.VoucherReducer.isFetchingVoucherById,
    voucherDataById: state.VoucherReducer.voucherDataById,
    vDataObject: state.VoucherReducer.vDataObject
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(VoucherEdit);