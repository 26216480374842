import React, { useEffect, useState } from "react";
import {
  InputGroup,
  Row,
  Col,
  Form,
  Container,
  Button,
  FormGroup,
  Modal,
} from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty, get, map, find, isUndefined, reverse } from "lodash";
import moment from "moment";
import Spinner from "react-bootstrap/Spinner";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import MaterialTable from "material-table";
import { getAdminVoucher } from "../../AdminDetails/adminDetails.action";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import {
  updateVoucherStatus,
  updateVoucherStatus1,
} from "../../AdminDetails/adminDetails.action";

const VoucherVerification = (props) => {
  useEffect(() => {
    // props.getAllInventory();
    // props.getsubCategoryData();
    // props.getAllDemand();
    props.getAdminVoucher();
  }, []);
  const VoucherData = props.voucherList;
  /*const [state, setState] = React.useState({
      columns: [
          { title: 'Cash Account Title', field: 'cashAccTittle' },
          { title: 'Cash Account', field: 'voukey' },
          { title: 'status', field: 'status' },
          { title: 'voucher', field: 'voucherId' },
      ],
      columnsData: [
        { title: 'Account Title', field: 'accountTittle' },
        { title: 'Bill No', field: 'billNo' },
        { title: 'Pay To', field: 'payTo' },
    ],
    
  });*/

  return (
    <>
      <MaterialTable
        title="Approve Voucher"
        columns={[
          { title: "Cash Account Title", field: "cashAccTittle" },
          { title: "Cash Account", field: "voukey" },
          { title: "status", field: "status" },
          { title: "voucher", field: "voucherId" },
        
        ]}
        data={reverse(VoucherData)}
        detailPanel={(rowData) => {
          return (
            <MaterialTable
              title="Vocuher Details"
              columns={[
                { title: "Account Title", field: "accountTittle" },
                { title: "Bill No", field: "billNo" },
                { title: "Pay To", field: "payTo" },
                { title: "Tracking No", field: "trackingNo" },
                { title: "Destination", field: "destination" },
                { title: "Packages", field: "packages" },
                { title: "Weight", field: "weight" },
                { title: "Weight Charges", field: "weightCharges" },
                { title: "Rate First Kg", field: "rateFirstKg" },
                { title: "Rate Add Kg", field: "rateAddKg" },
                { title: "Rate Flat", field: "rateFlat" },
                { title: "Service", field: "service" },
              ]}
              data={rowData.voucher_data}
              options={{
                actionsColumnIndex: -1,
                toolbar: false,
                exportButton: true,
                sorting: true,
                paging: true,
                maxBodyHeight:'600px',
                pageSize: 200, // make initial page size
                emptyRowsWhenPaging: false, // To avoid of having empty rows
                pageSizeOptions: [50, 100, 150, 200],
                headerStyle: {
                  position: "sticky",
                  top: 0,
                  color: "#00BBBB",
                  fontWeight: "550",
                  onRowAdd: "none",
                },
              }}
            />
          );
        }}
        actions={[
          (rowData) => {
            return rowData.status !== "Pending"
              ? {
                  icon: DoneIcon,
                  disabled: true,
                  onClick: (rowData) => {
                    /* anythink */
                  },
                }
              : {
                  icon: DoneIcon,
                  disabled: false,
                  onClick: (event, rowData) => {
                    props.updateVoucherStatus(rowData);
                  },
                };
          },
          (rowData) => {
            return rowData.status !== "Pending"
              ? {
                  icon: ClearIcon,
                  disabled: true,
                  onClick: (rowData) => {
                    /* anythink */
                  },
                }
              : {
                  icon: ClearIcon,
                  disabled: false,
                  onClick: (event, rowData) => {
                    props.updateVoucherStatus1(rowData);
                  },
                };
          },
        ]}
        options={{
          actionsColumnIndex: -1,
          filtering: true,
          exportButton: true,
          paging: true,
          maxBodyHeight:'600px',
          pageSize: 200, // make initial page size
          emptyRowsWhenPaging: false, // To avoid of having empty rows
          pageSizeOptions: [50, 100, 150, 200],
          headerStyle: {
            position: "sticky",
            top: 0,
            color: "#00BBBB",
            fontWeight: "550",
            onRowAdd: "none",
          },
        }}
        onRowClick={(event, rowData, togglePanel) => togglePanel()}
      />
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    // addCategoryName: (data) => dispatch(addCategoryName(data)),
    // getCategoryData: (wareHouseValue) => dispatch(getCategoryData(wareHouseValue)),
    // deleteCategory: (categoryId) => dispatch(deleteCategory(categoryId)),
    // getWarehouseData: () => dispatch(getWarehouseData()),
    // getsubCategoryData: (CategoryValue) => dispatch(getsubCategoryData(CategoryValue)),
    // addInventory: (data) => dispatch(addInventory(data)),
    // getInventory: () => dispatch(getInventory()),
    // deleteInventory: (inventoryId) => dispatch(deleteInventory(inventoryId)),
    // updateInventory: (newData, oldData) => dispatch(updateInventory(newData, oldData)),
    // addDemand: (data) => dispatch(addDemand(data)),
    // getAllDemand: () => dispatch(getAllDemand()),
    // getAllInventory: () => dispatch(getAllInventory())
    getAdminVoucher: () => dispatch(getAdminVoucher()),
    updateVoucherStatus: (data) => dispatch(updateVoucherStatus(data)),
    updateVoucherStatus1: (data) => dispatch(updateVoucherStatus1(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  // warehouseList: state.AddDataReducer.warehouseList,
  // categoryList: state.AddDataReducer.categoryList,
  // subCategoryList: state.AddDataReducer.subCategoryList,
  // inventoryList: state.AddDataReducer.inventoryList,
  // demandList: state.AddDataReducer.demandList,
  // allInventory: state.AddDataReducer.allInventory,
  // isFetchingDemand: state.AddDataReducer.isFetchingDemand,
  // isFetchingInventoryAll: state.AddDataReducer.isFetchingInventoryAll
  voucherList: state.adminDemandDetailReducer.voucherList,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VoucherVerification);
