import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button } from "react-bootstrap";
import {
  AddTransactionSummary,
  getVouchers,
} from "./TransactionSummary.actions";
import { connect } from "react-redux";
import moment from "moment";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import { isEmpty, find } from "lodash";
import Select from "react-select";
import logo from "../../../../Images/logo.png";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { GetVoucher } from "../Vouchers/Voucher.action";
import { Redirect, Link } from "react-router-dom";

const TransactionSummary = (props) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [voucher, setVoucher] = useState("");
  const [voucherId, setVoucherId] = useState("");

  const getSummary = props.summary;
  /* const [state, setState] = React.useState({
    columns: [
      { title: 'Voucher No.', field: 'voucherId' },
      { title: 'Voucher Key.', field: 'voukey' },
      { title: 'Posting By ', field: 'cashAccTittle' },
      { title: 'Date', field: 'createdAt', render: rowData => moment(rowData.createdAt).format("YYYY-MM-DD") },
    ],
  });

  const [stateArticle, setStateArticle] = useState({
    columns: [
      { title: "Account Title", field: "accountTittle", render: rowData => rowData.accountTittle + "(" + rowData.level_five.allLevelKey + ")" },
      { title: 'Description', field: 'voucherDescription' },
      { title: 'Bill No', field: 'billNo' },
      { title: "Amount", field: "voucherAmount" },
      { title: "DR/CR", field: "accountType", render: rowData => rowData.accountType == 2 ? "Debit" : rowData.accountType == 1 ? "Credit" : "" },
    ],
  });*/
  //const AccountNo = !isEmpty(props.voucherListData) && props.voucherListData.map((x) => { let data = { value: x.voucherId, label: x.level_five.allLevelKey + "/" + x.cashAccTittle }; return data });

  const voucherData =
    !isEmpty(props.voucherList) &&
    props.voucherList.map((x) => {
      let data = { value: x.abbrevation, label: x.voucherType };
      return data;
    });
  const handleChangeAccountNo = (selectedOption) => {
    setVoucher(selectedOption.value);
    setVoucherId(selectedOption.value);

    // setVoucherId(find(props.voucherListData, x => x.voucherId == selectedOption.value).voucherId);
  };
  useEffect(() => {
    props.AddTransactionSummary({
      startedDate: "",
      endDate: "",
      voucher: "",
    });
    props.getVouchers();
    props.GetVoucher();
  }, []);
  function searchData() {
    props.AddTransactionSummary({
      startedDate: startDate,
      endDate: endDate,
      voucher: voucher,
    });
  }
  return (
    <>
      {props.isFetchingVoucherList ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            Loading...
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Row>
              <Col lg="3" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b> Start Date </b>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    onChange={(e) => setStartDate(e.target.value)}
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col lg="3" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b> End Date</b>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    onChange={(e) => setEndDate(e.target.value)}
                  ></Form.Control>
                </Form.Group>
              </Col>

              <Col lg="5" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b> Voucher Type </b>
                  </Form.Label>

                  <Select
                    styles={{ overflow: "scroll" }}
                    placeholder="Select Voucher Type..."
                    onChange={handleChangeAccountNo}
                    options={voucherData}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col lg="12" sm="12" className="text-center" style={{marginTop:'15px'}}>
                {voucherId == "" ? (
                  <Button
                    disabled
                    style={{ backgroundColor: "black" }}
                    className="sendButton"
                  >
                    Search
                  </Button>
                ) : (
                  <Link
                    to={{
                      pathname: "/user/transaction-summary-details",
                      state: { start: startDate, end: endDate },
                    }}
                  >
                    <Button
                      className="sendButton"
                      onClick={(e) => searchData()}
                    >
                      Search
                    </Button>
                  </Link>
                )}
              </Col>
            </Row>

            {/* <MaterialTable
              title="Transaction Summary"
              columns={state.columns}
              data={getSummary}
              detailPanel={rowData => {
                return (
                  <MaterialTable
                    columns={stateArticle.columns}
                    data={rowData.voucher_data}
                    title="Voucher Data Preview"
                    options={{
                      paging: true,
                      pageSize: 200,       // make initial page size
                      emptyRowsWhenPaging: false,   // To avoid of having empty rows
                      pageSizeOptions: [50, 100, 150, 200],
                    }}
                  />
                )
              }}
              options={{
                actionsColumnIndex: -1,
                filtering: true,
                exportButton: true,
                paging: true,
                exportAllColumns: true,
                exportAllData: true,
                pageSize: 200,       // make initial page size
                emptyRowsWhenPaging: false,   // To avoid of having empty rows
                pageSizeOptions: [50, 100, 150, 200],
                exportPdf: (columns, data) => {
                  const doc = new jsPDF();

                  const columnTitles = state.columns
                    .map(columnDef => columnDef.title);

                  const pdfData = data.map(rowData =>
                    state.columns.map(columnDef => (console.log(columnDef.field, "eeeee"), columnDef.field == 'voucherDate' ? moment(rowData[columnDef.field]).format("YYYY-MM-DD") : rowData[columnDef.field])),
                  );
                  let content = {
                    startY: 50,
                    startX: 5,
                    head: [columnTitles],
                    body: pdfData
                  };
                  doc.addImage(logo, 'PNG', 10, 11, 30, 30);
                  doc.text(`Company Name: ${process.env.REACT_APP_COMPANY_NAME}`, 100, 20, 0, 20);
                  doc.text("Transaction Summary Report", 30, 45, 0, 20);
                  doc.autoTable(content);

                  doc.save(`data.pdf`);
                },
                headerStyle: {
                  position: 'sticky', top: 0,
                  color: '#00BBBB',
                  fontWeight: '550',
                  onRowAdd: 'none',
                }
              }}
            /> */}
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    AddTransactionSummary: (data) => dispatch(AddTransactionSummary(data)),
    getVouchers: () => dispatch(getVouchers()),
    GetVoucher: () => dispatch(GetVoucher()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  voucherListData: state.TransactitonSummaryReducer.voucherListData,
  summary: state.TransactitonSummaryReducer.summary,
  isFetchingGetVouchers: state.TransactitonSummaryReducer.isFetchingGetVouchers,
  isFetchingTransactionSummary:
    state.TransactitonSummaryReducer.isFetchingTransactionSummary,
  voucherList: state.VoucherReducer.voucherList,
  isFetchingVoucherList: state.VoucherReducer.isFetchingVoucherList,
});

export default connect(mapStateToProps, mapDispatchToProps)(TransactionSummary);
