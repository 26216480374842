import React, { useState } from "react";
import { Container, Form, Row, Col, Button, FormGroup } from "react-bootstrap";
import {
  addExpense,
  getExpense,
  deleteExpense,
  getBooking,
  getMasterControl /*UpdateHRExpense */,
  getEvent,
  getSubEvent,
  getHall,
  getMenu,
  addBooking,
  getCustomerMarquee,
  hideForm,
  deleteBooking,
  getBookingShifts,
  SearchMarqueeEventReport,
  getBookingById,
  getSubEventByEventId,
} from "./MarqueeDetails.actions";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import { useEffect } from "react";
import Swal from "sweetalert";
import { isEmpty, find, sumBy,filter } from "lodash";
import Select from "react-select";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { IconButton } from "@material-ui/core";
import jsPDF from "jspdf";
import logo from "../../../../../Images/logo.png";
import { number } from "yargs";
import moment from "moment";

const AddBooking = (props) => {
  const [expenseName, setExpenseName] = useState("");
  const [bookingId, setBookingId] = useState("");
  const [bookingName, setBookingName] = useState("");
  const [bookingPerPersonPrice, setBookingPerPersonPrice] = useState(0);
  const [hallPerfunctionPrice, setHallPerfunctionPrice] = useState(0);
  const [bookingTax, setBookingTax] = useState(0);
  const [afterTaxTotalPrice, setAfterTaxTotalPrice] = useState(0);
  
  const [bookingAdvance, setBookingAdvance] = useState(0);
  const [bookingRemainingPrice, setBookingRemainingPrice] = useState(0);
  const [bookingTotalPrice, setBookingTotalPrice] = useState(0);
  const [bookingTotalPriceDummy, setBookingTotalPriceDummy] = useState(0);
  const [bookingDiscount, setBookingDiscount] = useState(0);
  
  const [bookingPerson, SetBookingPerson] = useState(1);
  const [bookingDescription, setBookingDescription] = useState("");
  const [menuId, setMenuId] = useState("");
  const [menuName, setMenuName] = useState("");
  const [disMethodId, setDisMethodId] = useState("");
  const [menuPrice, setMenuPrice] = useState("");
  const [menuPerHeadPrice, setMenuPerHeadPrice] = useState("");
  const [chargingPrice, setChargingPrice] = useState("");
  const [bookingDate, setBookingDate] = useState("");
  const [expenseDescription, setExpenseDescription] = useState();
  const [submit, setSubmit] = useState(false);
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
  const [hallId, setHallId] = useState("");
  const [hallName, setHallName] = useState("");
  const [hallCapacity, setHallCapacity] = useState(0);
  const [hallTotolPrice, setHallTotalPrice] = useState(0);
  const [hallPerHeadPrice, setHallPerHeadPrice] = useState(0);
  const [eventId, setEventId] = useState("");
  const [eventName, setEventName] = useState("");
  const [subEventId, setSubEventId] = useState("");
  const [subEventName, setSubEventName] = useState("");
  const [eventShiftId, setEventShiftId] = useState("");
  const [eventShiftName, setEventShiftName] = useState("");
  const [bookingTypeId, setBookingTypeId] = useState("");
  const [bookingTypeName, setBookingTypeName] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [addonsPrice,setAddonsPrice]=useState(0);
  const [disType,setDisType]=useState("");
  /*const [state, setState] = React.useState({
    columns: [
      { title: "Bookig Name", field: "booking.booking_name" },
      { title: "Expense Description", field: "expense_desc" },
    ],
  });
  const [stateDishes, setStateDishes] = React.useState({
    columns: [
      {
        title: "Dish Name",
        field: "master_control_item.master_control_item_name",
      },
      { title: "Price", field: "booking_data_price" },
    ],
  });*/
  const [inputField, setInputField] = useState([
    {
      master_control_item_id: "",
      booking_data_price: 0,
    },
  ]);
  const [inputField1, setInputField1] = useState([
    {
      master_control_item_id: "",
      booking_data_price: 0,
    },
  ]);
  const handleAddInput = () => {
    setInputField([
      ...inputField,
      { master_control_item_id: "", booking_data_price: 0 },
    ]);
  };
  const handleRemoveInput = (index) => {
    const list = [...inputField];
    list.splice(index, 1);
    const addonsP=sumBy(list,"booking_data_price")
    console.log(addonsP,"tttttttttttttttt")
    setAddonsPrice(addonsP);
    setBookingTotalPrice((Number(bookingPerson)*Number(chargingPrice))+Number(addonsP)+Number(hallPerfunctionPrice)+sumBy(inputField1,"booking_data_price"))
    setInputField(list);
  };
  const handleChange = (e, index, type) => {
    console.log(e, index, type,"tttttttttttt")
    let z = find(
      props.allInventory,
      (x) => x.master_control_item_id == e.value
    );
    const { name, value } = e;
    const list = [...inputField];
    if (type == "intname") list[index]["master_control_item_id"] = e.value;
    if (type == "intname"){ list[index]["inventoryLabel"] = e.label;
    setInputField(list);}
    // if (type == 'intname') setMaximumQuantity(Number(z.currentBlnc));
    if (type == "intprice"){
      list[index]["booking_data_price"] = Number(e.target.value);
    setInputField(list);
    // list[index]['booking_data_price'] = "";
    const addonsP=Number(sumBy(inputField,"booking_data_price"))
    //setBookingTotalPrice(bookingTotalPrice +add)
    console.log(addonsP,inputField,"tttttttttttttttt")
    setAddonsPrice(addonsP);
    //setAddonsPrice(0);
    const fp=Number(bookingTotalPriceDummy)+Number(addonsP)+Number(sumBy(inputField1,"booking_data_price"));
    console.log(fp,"fpfpfpfp")
    setBookingTotalPrice(fp);
    // setBookingTotalPriceDummy(fp)
  }
  };
  const handleChange1 = (e, index, type) => {
    console.log(e, index, type,"tttttttttttt")
    let z = find(
      props.allInventory,
      (x) => x.master_control_item_id == e.value
    );
    const { name, value } = e;
    const list = [...inputField1];
    if (type == "intname") list[index]["master_control_item_id"] = e.value;
    if (type == "intname"){ list[index]["inventoryLabel"] = e.label;
    setInputField1(list);}
    // if (type == 'intname') setMaximumQuantity(Number(z.currentBlnc));
    if (type == "intprice"){
      list[index]["booking_data_price"] = Number(e.target.value);
    setInputField1(list);
    // list[index]['booking_data_price'] = "";
    const addonsP=Number(sumBy(inputField1,"booking_data_price"))
    //setBookingTotalPrice(bookingTotalPrice +add)
    console.log(addonsP,inputField1,"tttttttttttttttt")
    setAddonsPrice(addonsP);
    //setAddonsPrice(0);
    const fp=Number(bookingTotalPriceDummy)+Number(addonsP)+Number(sumBy(inputField,"booking_data_price"));
    console.log(fp,"fpfpfpfp")
    setBookingTotalPrice(fp);}
  };
  const handleAddInput1 = () => {
    setInputField1([
      ...inputField1,
      { master_control_item_id: "", booking_data_price: 0 },
    ]);
  };
  const handleRemoveInput1 = (index) => {
    const list = [...inputField1];
    list.splice(index, 1);
    const addonsP=sumBy(list,"booking_data_price")
    console.log(addonsP,"tttttttttttttttt")
    setAddonsPrice(addonsP);
    setBookingTotalPrice((Number(bookingPerson)*Number(chargingPrice))+Number(addonsP)+Number(hallPerfunctionPrice)+sumBy(inputField,"booking_data_price"))
    setInputField1(list);
  };
  const bookingAviliable = () => {
  setDangerVisibleAlert(true);
  setTimeout(() => {
    setDangerVisibleAlert(false);
  }, 9000);
  Swal({
    title: "Error",
    text: "Hall already Booked Please Change Hall or Shift.....",
    icon: "error",
    button: "Ok",
  });
  }
  function searchShift(){
    console.log(props.hideFormList,"click1")
    props.getShifts({
      hall_id:hallId,
      booking_date:bookingDate,
      event_shift_id:eventShiftId,
    },bookingAviliable)
    console.log(props.iserror,"click2")

    // if(props.iserror==false){
    //   setDangerVisibleAlert(true);
    // setTimeout(() => {
    //   setDangerVisibleAlert(false);
    // }, 9000);
    // Swal({
    //   title: "Error",
    //   text: "Hall already Booked Please Change Hall or Shift.....",
    //   icon: "error",
    //   button: "Ok",
    // });
    // }
    // props.hideForm(true);
    // console.log(props.hideFormList,"click2")
   
  };
  function uploadBooking() {
    
    props.addBooking(
      {
        booking_name: bookingName,
        booking_desc: bookingDescription,
        booking_status: "Pending",
        booking_person: bookingPerson,
        booking_price_per_person: bookingPerPersonPrice,
        booking_total_price: (Number(bookingPerson)*Number(chargingPrice))+Number(sumBy(inputField,"booking_data_price"))+Number(sumBy(inputField1,"booking_data_price"))+Number(hallPerfunctionPrice),
        booking_payable_price:afterTaxTotalPrice==0?bookingTotalPrice:afterTaxTotalPrice,
        booking_advance: bookingAdvance,
        booking_discount: bookingDiscount,
        booking_remaining_price: bookingRemainingPrice,
        booking_date: bookingDate,
        event_id: eventId,
        event_shift_id: eventShiftId,
        hall_id: hallId,
        sub_event_id: subEventId,
        tax:bookingTax,
        menu_id: menuId,
        customer_id: customerId,
        booking_type: bookingTypeName,
        charging_price: chargingPrice,
        booking_discount_type:disType,
        data: inputField[0].master_control_item_id == ""||inputField1[0].master_control_item_id == "" ? [] : inputField.concat(inputField1),
      },
      handleVisible,
      handleDangerVisible
    );
    props.hideForm(false)
  }
  const bookingData = isEmpty(props.bookingList)
    ? []
    : props.bookingList.reverse();
  const inventory = props.masterControlsList.map((x) => {
    let data = {
      value: x.master_control_item_id,
      label: x.master_control_item_name,
      type:x.master_control_item_type
    };
    return data;
  });
  const clearStates = () => {
    setHallName("");
    setEventName("");
    setSubEventName("");
    setChargingPrice(0);
    setBookingTotalPrice(0);
    setBookingTotalPriceDummy(0);
    setBookingPerPersonPrice(0);
    setBookingName("");
    setBookingDescription("");
    setBookingAdvance(0);
    setBookingDiscount(0);
    SetBookingPerson(0);
    setBookingRemainingPrice(0);
    setSubEventName("");
    setSubEventId(0);
    setSubmit(false);
  };
  const handleVisible = () => {
    setAlertVisible(true);
    clearStates();
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: "Added successfully...",
      icon: "success",
      //buttons: ["Ok", "Print"],
      buttons: {
        roll: {
            text: "Print ",
            value: "pr",
        },
        roll2: {
            text: "Ok",
            value: "ok"
        }
    }
}).then(function (x) {
    if (x == 'pr') {
      console.log("check")
    }
    });
  };
  const handleChangeHall1 = (selectedOption) => {
    // clearStates();
    console.log(selectedOption, "sop");
    setHallId(selectedOption.value);
    setHallName(selectedOption.label);
    let hallId = selectedOption.value;
    const getHall =
      !isEmpty(props.hallList) &&
      find(props.hallList, (x) => x.hall_id == hallId);
    console.log("hall capacity", getHall.hall_sitting_capacity);
    setHallCapacity(getHall.hall_sitting_capacity);
    console.log("hall total price", getHall.hall_per_function_charges);
    setHallTotalPrice(getHall.hall_per_function_charges);
    console.log("hall per head price", getHall.hall_per_person_charges);
    setHallPerHeadPrice(getHall.hall_per_person_charges);
    let p = Number(getHall.hall_per_function_charges) + Number(chargingPrice);
    console.log("price", p);
    setBookingTotalPrice(p);
    setBookingTotalPriceDummy(p);
   // props.hideForm(false);
  };
  const dismethod=(selectedOption)=>{
    console.log("testttttttttttttt",selectedOption)
    setDisMethodId(selectedOption.value)
    setDisType(selectedOption.label)
  }
  const handleChangeHall = (selectedOption) => {
    // clearStates();
    console.log(selectedOption, "sop");
    setHallId(selectedOption.value);
    setHallName(selectedOption.label);
    let hallId = selectedOption.value;
    const getHall =
      !isEmpty(props.hallList) &&
      find(props.hallList, (x) => x.hall_id == hallId);
    console.log("hall capacity", getHall.hall_sitting_capacity);
    setHallCapacity(getHall.hall_sitting_capacity);
    console.log("hall total price", getHall.hall_per_function_charges);
    setHallTotalPrice(getHall.hall_per_function_charges);
    console.log("hall per head price", getHall.hall_per_person_charges);
    setHallPerHeadPrice(getHall.hall_per_person_charges);
    setHallPerfunctionPrice(Number(getHall.hall_per_function_charges))
    let p = Number(getHall.hall_per_function_charges) + Number(chargingPrice);
    console.log("price", p);
    setBookingTotalPrice(p);
    setBookingTotalPriceDummy(p);
    props.hideForm(false);
  };
  const getHallList =
    !isEmpty(props.hallList) &&
    props.hallList.map((x) => {
      let data = { value: x.hall_id, label: x.hall_name };
      return data;
    });
    const getdisdata =
   [{id:1,
    title:'Cash'},
    {id:2,
    title:'Percentage'}]
  console.log("gethalllist", getHallList);
  const getdislist =
  getdisdata.map((x) => {
    let data = { value: x.id, label: x.title };
    return data;
  });
  const getBookingList =
    !isEmpty(props.bookingList) &&
    props.bookingList.map((x) => {
      let data = { value: x.booking_id, label: x.booking_name };
      return data;
    });
  console.log("getBookiList", getBookingList, props.bookingList);
  const handleDangerVisible = () => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: "Something went wrong...",
      icon: "error",
      button: "Ok",
    });
  };
  const handleChangeCustomer = (selectedOption) => {
    console.log(selectedOption, "sop");
    setCustomerId(selectedOption.value);
    setCustomerName(selectedOption.label);
  };
  const handleChangeEvent = (selectedOption) => {
    console.log(selectedOption, "sop");
    setEventId(selectedOption.value);
    setEventName(selectedOption.label);
props.getSubEventByEventId(selectedOption.value)
  };
  const getEventList =
    !isEmpty(props.eventList) &&
    props.eventList.map((x) => {
      let data = { value: x.event_id, label: x.event_name };
      return data;
    });
  console.log("getEventList", getEventList);
  const handleChangeSubEvent = (selectedOption) => {
    console.log(selectedOption, "sop");
    setSubEventId(selectedOption.value);
    setSubEventName(selectedOption.label);
  };
  const handleChargePrice = (e) => {
   // SetBookingPerson(1);
    setChargingPrice(e.target.value);
    //const getHall =!isEmpty(props.hallList) && find(props.hallList, x => x.hall_id ==hallId);
    // let bPrice=hallTotolPrice+menuPrice+chargingPrice+sumBy(data,booking_data_price)
    //console.log("data is",props.data)
    setChargingPrice(e.target.value);
    let p = Number(bookingPerson)* Number(e.target.value);
    let p1=Number(hallTotolPrice) +p
    console.log("price", p);
    setBookingTotalPrice(p1);
    setBookingTotalPriceDummy(p1);
  };
  const getSubEventsList =
    !isEmpty(props.subEventsListByEventID) &&
    props.subEventsListByEventID.map((x) => {
      let data = { value: x.sub_event_id, label: x.sub_event_name };
      return data;
    });
  console.log("getSubEventList", getSubEventsList);
  let shiftData = [
    {
      event_shift_id: 1,
      event_shift_name: "Morning",
      event_start_time: "08:00",
      event_end_time: "12:30",
    },
    {
      event_shift_id: 2,
      event_shift_name: "After Noon",
      event_start_time: "12:31",
      event_end_time: "05:00",
    },
    {
      event_shift_id: 3,
      event_shift_name: "Evening",
      event_start_time: "05:01",
      event_end_time: "10:00",
    },
  ];
  const handleChangeShift = (selectedOption) => {
    console.log(selectedOption, "sop");
    setEventShiftId(selectedOption.value);
    setEventShiftName(selectedOption.label);
  };
  const eventShiftList =
    !isEmpty(shiftData) &&
    shiftData.map((x) => {
      let data = { value: x.event_shift_id, label: x.event_shift_name };
      return data;
    });
  console.log("getEventShihftList", eventShiftList);
  let bookingTypeData = [
    {
      booking_type_id: 1,
      booking_type_name: "FULL",
    },
    {
      booking_type_id: 2,
      booking_type_name: "PARTIAL",
    },
  ];
  const handleChangeBookingType = (selectedOption) => {
    console.log(selectedOption, "sop");
    setBookingTypeId(selectedOption.value);
    setBookingTypeName(selectedOption.label);
    selectedOption.value==1?setBookingAdvance(bookingTotalPrice):setBookingAdvance(0)
  };
  const bookingTypeList =
    !isEmpty(bookingTypeData) &&
    bookingTypeData.map((x) => {
      let data = { value: x.booking_type_id, label: x.booking_type_name };
      return data;
    });
  console.log("getEventShihftList", bookingTypeList);
  const handleChangeMenu = (selectedOption) => {
    //clearStates();
    //SetBookingPerson(1);
    console.log(selectedOption, "sop");
    setMenuId(selectedOption.value);
    setMenuName(selectedOption.label);
    let menuId = selectedOption.value;
    const getPrice =
      !isEmpty(props.menuList) &&
      find(props.menuList, (x) => x.menu_id == menuId);
    console.log("price", getPrice.menu_price);
    setMenuPrice(getPrice.menu_price);
    setChargingPrice(getPrice.menu_price);
    let p = Number(bookingPerson)*Number(getPrice.menu_price) 
    let p1=Number(hallTotolPrice) +p
     // + Number(chargingPrice);
    console.log("price", p);
    setBookingTotalPrice(p1);
    setBookingTotalPriceDummy(p1);
  };
  const getMenuList =
    !isEmpty(props.menuList) &&
    props.menuList.map((x) => {
      let data = { value: x.menu_id, label: x.menu_name };
      return data;
    });
  console.log("getEventShihftList", getMenuList);
  const getCustomerMarqueeList =
    !isEmpty(props.customerMarqueeList) &&
    props.customerMarqueeList.map((x) => {
      let data = {
        value: x.customer_id,
        label: `${x.customer_name}/${x.customer_cnic}`,
      };
      return data;
    });
  console.log("getEventShihftList", getCustomerMarqueeList);
  useEffect(() => {
    //props.getExpense();
    props.getMasterControl(1);
    props.getBooking();
    props.getEvent(1);
    // props.getSubEvent(1);
    props.getHall(1);
    props.getMenu(1);
    props.getCustomerMarquee();
    props.hideForm(false);
    console.log(props.hideFormList,"click")
  }, []);
  return (
    <>
      {props.isFetchingExpenseData || props.isFetchingBookingShiftData||props.isFetchingSubEventDataByEventID ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
          </Button>
        </div>
      ) : (
        <Container fluid>
          <Form>
         { !props.hideFormList&&
            <Row>
              <Col lg="3" md="12" xs="12">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Date *</b>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    value={bookingDate}
                    min={moment(new Date()).format("YYYY-MM-DD")}
                    placeholder="Date:...."
                    onChange={(e) => {
                      setBookingDate(e.target.value);
                      props.hideForm(false);
                    }}
                  />
                </Form.Group>
              </Col>
              <Col lg="3" md="12" xs="12">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Select Hall *</b>
                  </Form.Label>
                  <Select
                    isDisabled={isEmpty(props.hallList)}
                    placeholder="Select Hall..."
                    onChange={handleChangeHall}
                    options={getHallList}
                  />
                </Form.Group>
              </Col>
              <Col lg="3" md="12" xs="12">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Select Event Shift *</b>
                      </Form.Label>
                      <Select
                        isDisabled={isEmpty(eventShiftList)}
                        placeholder="Select SubEvent..."
                        onChange={handleChangeShift}
                        options={eventShiftList}
                      />
                    </Form.Group>
                  </Col>
                  
              <Col lg="3" md="12" xs="12" >
               
               <div className="sendDiv">
               {bookingDate==""||hallId==""||eventShiftId==""?
                <Button
                  className="sendBuuton mt-4"
                  disabled
                  style={{backgroundColor:'black'}}
                  onClick={() => {
                    searchShift();
                  }}
                >
                  Search
                </Button>:
                <Button
                   className="mt-4 sendBuuton"
                    style={{backgroundColor:'black',color:'white'}}
                  onClick={() => {
                    searchShift();
                    //setSubmit(true);
                  }}
                >
                  Search
                </Button>}</div>
              </Col>
            </Row>}
            
            {props.hideFormList && (
              <div>
                <Row>
                  <Col lg="3" md="3" xs="3">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Select Customer *</b>
                      </Form.Label>
                      <Select
                        isDisabled={isEmpty(props.customerMarqueeList)}
                        placeholder="Select Customer..."
                        value={{label:customerName}}
                        onChange={handleChangeCustomer}
                        options={getCustomerMarqueeList}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="3" md="12" xs="12">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Date *</b>
                      </Form.Label>
                      <Form.Control
                        type="date"
                        value={bookingDate}
                        disabled
                        placeholder="Date:...."
                        onChange={(e) => {
                          setBookingDate(e.target.value);
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="3" md="12" xs="12">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Select Hall *</b>
                      </Form.Label>
                      <Select
                        isDisabled
                        value={{label:hallName}}
                        placeholder="Select Hall..."
                        onChange={handleChangeHall1}
                        options={getHallList}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="3" md="12" xs="12">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Select Event Shift *</b>
                      </Form.Label>
                      <Select
                        isDisabled
                        value={{label:eventShiftName}}
                        placeholder="Select SubEvent..."
                        onChange={handleChangeShift}
                        options={eventShiftList}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg="3" md="12" xs="12">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Sitting Person:</b>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        value={hallCapacity}
                        disabled={true}
                        placeholder="menu price:...."
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="3" md="12" xs="12">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Per function Price:</b>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        value={hallTotolPrice}
                        disabled={true}
                        placeholder="menu price:...."
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="3" md="12" xs="12">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Per Head Price:</b>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        value={hallPerHeadPrice}
                        disabled={true}
                        placeholder="menu price:...."
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="3" md="12" xs="12">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Select booking type *</b>
                      </Form.Label>
                      <Select
                        isDisabled={isEmpty(bookingTypeList)}
                        value={{label:bookingTypeName}}
                        placeholder="Select booking type..."
                        onChange={handleChangeBookingType}
                        options={bookingTypeList}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                <Col lg="3" md="12" xs="12">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Select Event *</b>
                      </Form.Label>
                      <Select
                        isDisabled={isEmpty(props.eventList)}
                        value={{label:eventName}}
                        placeholder="Select Event..."
                        onChange={handleChangeEvent}
                        options={getEventList}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="3" md="12" xs="12">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Select SubEvent *</b>
                      </Form.Label>
                      <Select
                        isDisabled={isEmpty(getSubEventsList)||eventName==""}
                        value={{label:subEventName}}
                        placeholder="Select SubEvent..."
                        onChange={handleChangeSubEvent}
                        options={getSubEventsList}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="3" md="12" xs="12">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Select Menu *</b>
                      </Form.Label>
                      <Select
                        isDisabled={isEmpty(props.menuList)}
                        placeholder="Select Manu..."
                        onChange={handleChangeMenu}
                        options={getMenuList}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="3" md="12" xs="12">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Menu Price:</b>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        value={menuPrice}
                        disabled={true}
                        placeholder="menu price:...."
                      />
                    </Form.Group>
                  </Col>
                 
                </Row>
                <Row>
                <Col lg="3" md="12" xs="12">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Per Head Price:</b>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        value={menuPerHeadPrice}
                        disabled={true}
                        placeholder="Per Head price:...."
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="3" md="12" xs="12">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Charging Price:</b>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        value={chargingPrice}
                        placeholder="Charging price:...."
                        onChange={handleChargePrice}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="3" md="12" xs="12">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Booking person:</b>
                      </Form.Label>
                      <Form.Control
                        value={bookingPerson}
                        type="number"
                        placeholder="Booking person:...."
                        onChange={(e) => {
                          Number(e.target.value) <= hallCapacity &&
                            SetBookingPerson(e.target.value);
                          let p =
                            Number(e.target.value) * Number(chargingPrice);
                          let p1 = p + hallTotolPrice ;
                          setBookingTotalPrice(p1);
                          setBookingTotalPriceDummy(p1)
                        }}
                        max={hallCapacity}
                        min={1}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="3" md="12" xs="12">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Booking per person price:</b>
                      </Form.Label>
                      <Form.Control
                        value={bookingPerPersonPrice}
                        type="number"
                        placeholder="Booking per person price:...."
                        onChange={(e) =>
                          setBookingPerPersonPrice(e.target.value)
                        }
                      />
                    </Form.Group>
                  </Col>
                 
                </Row>
                <Row>
                  <Col lg="12" md="12" xs="12">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Enter Booking Name *</b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Booking Name...."
                        value={bookingName}
                        onChange={(e) => setBookingName(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                {inputField.map((item, i) => {
                  return (
                    <React.Fragment key={i}>
                      <Row>
                        <Col xl="6" lg="6" md="12" sm="12">
                          <Form.Group>
                            <Form.Label>
                              <b> Select AddOns Dish</b>
                            </Form.Label>
                            <Select
                              style={{ zIndex: 1 }}
                              value={{ label: item.inventoryLabel }}
                              placeholder="Select Items..."
                              name="master_control_item_id"
                              onChange={(e) => handleChange(e, i, "intname")}
                              options={filter(inventory,{type:"Dish"})}
                              //  isOptionDisabled={(option) => !isEmpty(find(inputField, x => x.master_control_item_id == option.value))}
                            />
                          </Form.Group>
                        </Col>

                        <Col xl="3" lg="3" md="6" sm="6">
                          <Form.Group className="mb-3">
                            <Form.Label>
                              <b>Add Price</b>
                            </Form.Label>
                            <Form.Control
                              type="number"
                              placeholder="Add Price...."
                              name="booking_data_price"
                              value={item.booking_data_price}
                              onChange={(e) => handleChange(e, i, "intprice")}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <FormGroup>
                        <Row>
                          <Col lg="12">
                            {inputField.length - 1 == i && (
                              <IconButton
                                title="Click to Add Demand"
                                onClick={handleAddInput}
                                style={{
                                  float: "right",
                                  color: "black",
                                }}
                              >
                                <AddIcon />
                              </IconButton>
                            )}

                            {inputField.length !== 1 && (
                              <IconButton
                                title="Click to Remove Demand"
                                onClick={() => handleRemoveInput(i)}
                                style={{ float: "right", color: "black" }}
                              >
                                <RemoveIcon />
                              </IconButton>
                            )}
                          </Col>
                        </Row>
                      </FormGroup>
                    </React.Fragment>
                  );
                })}
                 {inputField1.map((item, i) => {
                  return (
                    <React.Fragment key={i}>
                      <Row>
                        <Col xl="6" lg="6" md="12" sm="12">
                          <Form.Group>
                            <Form.Label>
                              <b> Select AddOn Decor</b>
                            </Form.Label>
                            <Select
                              style={{ zIndex: 1 }}
                              value={{ label: item.inventoryLabel }}
                              placeholder="Select Items..."
                              name="master_control_item_id"
                              onChange={(e) => handleChange1(e, i, "intname")}
                              options={filter(inventory,{type:"Decor"})}
                              //  isOptionDisabled={(option) => !isEmpty(find(inputField, x => x.master_control_item_id == option.value))}
                            />
                          </Form.Group>
                        </Col>

                        <Col xl="3" lg="3" md="6" sm="6">
                          <Form.Group className="mb-3">
                            <Form.Label>
                              <b>Add Price</b>
                            </Form.Label>
                            <Form.Control
                              type="number"
                              placeholder="Add Price...."
                              name="booking_data_price"
                              value={item.booking_data_price}
                              onChange={(e) => handleChange1(e, i, "intprice")}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <FormGroup>
                        <Row>
                          <Col lg="12">
                            {inputField.length - 1 == i && (
                              <IconButton
                                title="Click to Add Demand"
                                onClick={handleAddInput1}
                                style={{
                                  float: "right",
                                  color: "black",
                                }}
                              >
                                <AddIcon />
                              </IconButton>
                            )}

                            {inputField.length !== 1 && (
                              <IconButton
                                title="Click to Remove Demand"
                                onClick={() => handleRemoveInput1(i)}
                                style={{ float: "right", color: "black" }}
                              >
                                <RemoveIcon />
                              </IconButton>
                            )}
                          </Col>
                        </Row>
                      </FormGroup>
                    </React.Fragment>
                  );
                })}
                <Row>
                  <Col xl="12" lg="12" md="12" sm="12" xs="12">
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <b>Enter Booking Description:</b>
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Booking Description....."
                        value={bookingDescription}
                        onChange={(e) => setBookingDescription(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                <Col lg="3" md="3" xs="3">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Booking Total price:</b>
                      </Form.Label>
                      <Form.Control
                        value={(Number(bookingPerson)*Number(chargingPrice))+Number(sumBy(inputField,"booking_data_price"))+Number(sumBy(inputField1,"booking_data_price"))+Number(hallPerfunctionPrice)}
                        type="number"
                        placeholder="Booking total price:...."
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="3" md="3" xs="3">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Select Discount Method:</b>
                  </Form.Label>
                  <Select
                    // isDisabled={isEmpty(props.hallList)}
                    // value={label:}
                    placeholder="Select Discount Method..."
                    onChange={dismethod}
                    options={getdislist}
                  />
                </Form.Group>
              </Col>
                  <Col lg="3" md="3" xs="3">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Booking Discount:</b>
                      </Form.Label>
                      <Form.Control
                        value={bookingDiscount}
                        type="number"
                        placeholder="Booking Discount:...."
                        disabled={disMethodId==""?true:false}
                        onChange={(e) => {
                          setBookingDiscount(e.target.value);
                          
                          if(disMethodId==2){
                         let dumy=(Number(bookingPerson)*Number(chargingPrice))+Number(sumBy(inputField,"booking_data_price"))+Number(sumBy(inputField1,"booking_data_price"))+Number(hallPerfunctionPrice)
                         console.log(dumy,bookingTotalPrice,"kkkkkkkkkkkkkkkkkkkkkkk")
                          let discountRate =
                            (Number(dumy) * e.target.value) / 100;
                            console.log(discountRate,"discountRate")
                          let finalPrice = Number(dumy) - Number(discountRate);
                          console.log(finalPrice,"final Price")
                          setBookingTotalPrice(finalPrice);
                          setBookingTotalPriceDummy(finalPrice)}
                          else{
                            let dumy=(Number(bookingPerson)*Number(chargingPrice))+Number(sumBy(inputField,"booking_data_price"))+Number(sumBy(inputField1,"booking_data_price"))+Number(hallPerfunctionPrice)
                            console.log(dumy,bookingTotalPrice,"kkkkkkkkkkkkkkkkkkkkkkk")
                            //  let discountRate =
                            //    (Number(dumy) * e.target.value) / 100;
                            //    console.log(discountRate,"discountRate")
                             let finalPrice = Number(dumy) - Number(e.target.value);
                             console.log(finalPrice,"final Price")
                             setBookingTotalPrice(finalPrice);
                             setBookingTotalPriceDummy(finalPrice)
                          }
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="3" md="12" xs="12">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Tax:</b>
                      </Form.Label>
                      <Form.Control
                        value={bookingTax}
                        min={0}
                        max={100}
                        type="number"
                        placeholder="Tax:...."
                        onChange={(e) =>{
                          setBookingTax(e.target.value)
                          let tax=(Number(e.target.value)*bookingTotalPrice)/100;
                          // setBookingTotalPrice(bookingTotalPrice+Number(tax))
                          setAfterTaxTotalPrice(bookingTotalPrice+Number(tax));
                        }
                        }
                      />
                    </Form.Group>
                  </Col>
                  </Row>
                  <Row>
                  <Col lg="4" md="4" xs="4">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Payable price:</b>
                      </Form.Label>
                      <Form.Control
                        value={bookingTax<=0?bookingTotalPrice:afterTaxTotalPrice}
                        type="number"
                        placeholder="Booking total price:...."
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="4" md="4" xs="4">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Booking Advance:</b>
                      </Form.Label>
                      <Form.Control
                        value={bookingTypeName=="FULL"?bookingTax==0?bookingTotalPrice:afterTaxTotalPrice:bookingAdvance}
                        disabled={bookingTypeName=="FULL"}
                        type="number"
                        placeholder="Booking Advance:...."
                        onChange={(e) => {
                      //  let p1=bookingTax<=0?bookingTotalPrice:afterTaxTotalPrice
                          let p = Number(bookingTax==0?bookingTotalPrice:afterTaxTotalPrice) - e.target.value;
                          setBookingRemainingPrice(p);
                          bookingTypeName=="FULL"?setBookingAdvance(afterTaxTotalPrice==0?bookingTotalPrice:afterTaxTotalPrice):
                          setBookingAdvance(e.target.value);
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="4" md="4" xs="4">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Booking Remaining price:</b>
                      </Form.Label>
                      <Form.Control
                        value={bookingRemainingPrice}
                        disabled
                        type="number"
                        placeholder="Booking Remaining price:...."
                        onChange={(e) =>
                          setBookingRemainingPrice(e.target.value)
                        }
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <div className="sendDiv">
                  {console.log(hallId,menuId,eventId,customerId,bookingTypeId,subEventId,"abcdefg")}
               {bookingName==""||hallId==""||menuId==""||eventId==""||subEventId==""||bookingTypeId==""||customerId==""?
                <Button
                    className="sendButton"
                    disabled
                    style={{backgroundColor:'black'}}
                    onClick={() => {
                      uploadBooking();
                      setSubmit(true);
                    }}
                  >
                    Save
                  </Button>:
                  <Button
                    className="sendButton"
                    onClick={() => {
                      uploadBooking();
                      setSubmit(true);
                    }}
                  >
                    Save
                  </Button>}
                </div>
              </div>
            )}
          </Form>

        
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addBooking: (data, handleVisible, handleDangerVisible) =>
      dispatch(addBooking(data, handleVisible, handleDangerVisible)),
    getExpense: (id) => dispatch(getExpense(id)),
    getMasterControl: (id) => dispatch(getMasterControl(id)),
    getBooking: () => dispatch(getBooking()),
    getEvent: (id) => dispatch(getEvent(id)),
    getSubEvent: (id) => dispatch(getSubEvent(id)),
    getSubEventByEventId:(id)=>dispatch(getSubEventByEventId(id)),
    getHall: (id) => dispatch(getHall(id)),
    getMenu: (id) => dispatch(getMenu(id)),
    hideForm: (data) => dispatch(hideForm(data)),
    getCustomerMarquee: () => dispatch(getCustomerMarquee()),
    // UpdateHRExpense: (newData, oldData) => dispatch(UpdateHRExpense(newData, oldData)),
    deleteBooking: (menu_id) => dispatch(deleteBooking(menu_id)),
    getShifts:(data,bookingAviliable)=>dispatch(getBookingShifts(data,bookingAviliable)),
    SearchMarqueeEventReport:(id)=> dispatch(SearchMarqueeEventReport(id)),
    getBookingById:(id) => dispatch(getBookingById(id)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  departmentList: state.MarqueeReducer.departmentList,
  subEventsListByEventID:state.MarqueeReducer.subEventsListByEventID,
  isFetchingSubEventDataByEventID:state.MarqueeReducer.isFetchingSubEventDataByEventID,
  isFetchingExpenseData: state.MarqueeReducer.isFetchingExpenseData,
  isAddingExpenseData: state.MarqueeReducer.isAddingExpenseData,
  expenseList: state.MarqueeReducer.expenseList,
  masterControlsList: state.MarqueeReducer.masterControlsList,
  bookingList: state.MarqueeReducer.bookingList,
  bookingShiftList: state.MarqueeReducer.bookingShiftList,
  eventList: state.MarqueeReducer.eventList,
  subEventsList: state.MarqueeReducer.subEventsList,
  hallList: state.MarqueeReducer.hallList,
  menuList: state.MarqueeReducer.menuList,
  iserror:state.MarqueeReducer.iserror,
  hideFormList: state.MarqueeReducer.hideFormList,
  customerMarqueeList: state.MarqueeReducer.customerMarqueeList,
  isFetchingBookingShiftData:state.MarqueeReducer.isFetchingBookingShiftData,
  bookingByIdList: state.MarqueeReducer.bookingByIdList,
  // data:state.MarqueeReducer.data,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddBooking);
