import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Container,
  Button,
  Alert,
  Tabs,
  Tab,
} from "react-bootstrap";
import "./add-levels.css";
import {
  getLevel1Data,
  getLevel2Data,
  getLevel3Data,
  getLevel4Data,
  getLevel5Data,
  AddLevel5Name,
  getcreditdebit,
  getLevelOneName,
  getLevelTwoName,
  deleteLevel5,
  getLevelFourName,
} from "./accountsLevel.action";
import { connect } from "react-redux";
import { isEmpty, isUndefined, find, map, toUpper } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import ReactExport from "react-export-excel";
import Select from "react-select";
import Swal from "sweetalert";
import Level5Upload from "./Level5Upload";
import PDFLevel5 from "./PDFLevel5";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Level5Tap from "./Level5Tap";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const AddLevel5 = (props) => {
  const [level1data, setLevel1data] = useState();

  const [level1Id, setLevel1Id] = useState();
  const [level2Id, setLevel2Id] = useState();
  const [level2Data, setLevel2data] = useState();
  const [level3Id, setLevel3Id] = useState();
  const [level3Data, setLevel3Data] = useState();
  const [level4Id, setLevel4Id] = useState();
  const [level4Data, setLevel4Data] = useState();
  const [level5, setLevel5Name] = useState();
  const [creditdebit, setCreditDebit] = useState("0");
  const [income, setIncome] = useState();
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  const handleVisible = () => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: "Added successfully...",
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = () => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: "Something went wrong...",
      icon: "error",
      button: "Ok",
    });
  };

  function uploadLevel5() {
    props.AddLevel5Name(
      {
        levelOneId: String(level1Id),
        levelTwoId: String(level1Id),
        levelThreeId: String(level3Id),
        levelFourId: String(level4Id),
        levelFiveName: toUpper(level5),
        debitCreditId: creditdebit,
        levelBalance: income,
      },
      handleVisible,
      handleDangerVisible
    );

    setLevel1data("");
    setLevel2data("");
    setLevel3Data("");
    setLevel4Data("");
    document.getElementById("level5id").value = "";
    document.getElementById("rsid").value = "";
  }

  // const getLevel4List = !isEmpty(props.level4List) && props.level4List.map((x) => { let data = { value: x.levelFourData.levelFourId, label: x.levelFourData.levelFourName }; return data });
  const getLevelFourList =
    !isEmpty(props.levelFour) &&
    props.levelFour.map((x) => {
      let data = {
        value: x.levelTwoData.levelFourId,
        label: x.levelTwoData.levelFourName,
      };
      return data;
    });
  const handleChangeLevel4 = (selectedOption) => {
    setLevel4Data(selectedOption.label);
    setLevel4Id(selectedOption.value);
    // setLevel1Id(find(props.level4List, x => x.levelFourData.levelFourId == selectedOption.value).levelFourData.levelOneId);
    // setLevel2Id(find(props.level4List, x => x.levelFourData.levelFourId == selectedOption.value).levelFourData.levelTwoId);
    // setLevel3Id(find(props.level4List, x => x.levelFourData.levelFourId == selectedOption.value).levelFourData.levelThreeId);
  };

  const creditDebit =
    !isEmpty(props.creditDebitValue) &&
    props.creditDebitValue.map((x) => {
      let data = { value: x.debitCreditId, label: x.accountName };
      return data;
    });
  const handleChangeDebitCredit = (selectedOption) => {
    setCreditDebit(selectedOption.value);
  };
  const level5Data = props.level5List;

  // add level
  const getLevel1List = props.level1List.map((x) => {
    let data = { value: x.levelOneId, label: x.name };
    return data;
  });
  const handleChangeLevel1 = (selectedOption) => {
    handleChangeLevel2(selectedOption);
    props.getLevelOneName(selectedOption.value);
    setLevel1Id(selectedOption.value);
    setLevel1data(selectedOption.label);
  };
  const getLevelTwoList =
    !isEmpty(props.levelTow) &&
    props.levelTow.map((x) => {
      let data = {
        value: x.levelTwoData.levelTwoId,
        label: x.levelTwoData.levelTwoName,
      };
      return data;
    });
  const handleChangeLevel2 = (selectedOption) => {
    setLevel2Id(selectedOption.value);
    setLevel2data(selectedOption.label);
    props.getLevelTwoName(selectedOption.value);
    // setLevel1Id(find(props.level2List, x => x.levelTwoData.levelTwoId == selectedOption.value).levelTwoData.levelOneId)
  };
  const getlevel3val =
    !isEmpty(props.levelThree) &&
    props.levelThree.map((x) => {
      let data = {
        value: x.levelTwoData.levelThreeId,
        label: x.levelTwoData.levelThreeName,
      };
      return data;
    });
  const handleChangeLevel3 = (selectedOption) => {
    props.getLevelFourName(selectedOption.value);
    setLevel3Id(selectedOption.value);
    setLevel3Data(selectedOption.label);
    // setLevel1Id(find(props.level3List, x => x.levelThreeData.levelThreeId == selectedOption.value).levelThreeData.levelOneId);
    // setLevel2Id(find(props.level3List, x => x.levelThreeData.levelThreeId == selectedOption.value).levelThreeData.levelTwoId)
  };
  // end level
  useEffect(() => {
    props.getcreditdebit();
    props.getLevel1Data();
    // props.getLevel2Data();
    // props.getLevel3Data();
    // props.getLevel4Data();
    // props.getLevel5Data();
  }, []);

  return (
    <>
      {/* {props.isFetchinglevel5List ? ( */}
      {props.isFetchinglevel1List ||
      props.isFetchingLevelTow ||
      props.isFetchingLevelThree ||
      props.isFetchingLevelFour ||
      props.isSavingLevel5 ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {props.isSavingLevel5 ? "Saving" : "Loading"}
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <div>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>
                      <b> Select Level 1 *</b>
                    </Form.Label>
                    <Select
                      value={{ label: level1data }}
                      placeholder="Select Level 1..."
                      onChange={handleChangeLevel1}
                      options={getLevel1List}
                    />
                  </Form.Group>
                </Col>
              </Row>
              {/* <Row>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>
                                                    <b> Select Level 2 </b>
                                                </Form.Label>
                                                <Select
                                                 isDisabled={isEmpty(getLevelTwoList) || isEmpty(level1data)}
                                                 value = {{label: level2Data}}
                                                    placeholder="Select Level 2..."
                                                     onChange={handleChangeLevel2}
                                                      options={getLevelTwoList}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row> */}
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>
                      <b> Select Level 2 *</b>
                    </Form.Label>
                    <Select
                      isDisabled={
                        isEmpty(getlevel3val) ||
                        isEmpty(level1data) ||
                        isEmpty(level2Data)
                      }
                      value={{ label: level3Data }}
                      placeholder="Select Level 2..."
                      onChange={handleChangeLevel3}
                      options={getlevel3val}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>
                      <b> Select Level 3 *</b>
                    </Form.Label>
                    <Select
                      isDisabled={
                        isEmpty(getLevelFourList) || isEmpty(level3Data)
                      }
                      value={{ label: level4Data }}
                      placeholder="Select Level 3..."
                      onChange={(e) => handleChangeLevel4(e)}
                      options={getLevelFourList}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg="12" md="12" xs="12">
                  <Form.Group className="mb-3" controlId="garana">
                    <Form.Label>
                      <b>Enter Level 4 Name *</b>
                    </Form.Label>
                    {!level4Id ? (
                      <Form.Control
                        type="text"
                        placeholder="Level Name...."
                        readOnly
                      />
                    ) : (
                      <Form.Control
                        type="text"
                        id="level5id"
                        placeholder="Level Name...."
                        onMouseLeave={(e) => setLevel5Name(e.target.value)}
                      />
                    )}
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col lg="6" sm="12">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>
                      <b>Amount Type *</b>
                    </Form.Label>
                    <Select
                      isDisabled={isEmpty(creditDebit) || isEmpty(level5)}
                      placeholder="Select Type..."
                      onChange={handleChangeDebitCredit}
                      options={creditDebit}
                    />
                  </Form.Group>
                </Col>
                <Col lg="6" sm="12">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>
                      <b>Amount *</b>
                    </Form.Label>
                    {creditdebit == 0 ? (
                      <Form.Control
                        type="number"
                        placeholder="Your Rs..."
                        readOnly
                      />
                    ) : (
                      <Form.Control
                        type="number"
                        id="rsid"
                        value={income}
                        min={1}
                        placeholder="Your Rs...."
                        onChange={(e) => setIncome(e.target.value)}
                      />
                    )}
                  </Form.Group>
                </Col>
              </Row>

              <div className="sendDiv">
                {/* {isEmpty(level4Id) && isEmpty(level5) && isEmpty(creditdebit) || isEmpty(income) ?  <Button disabled style={{ marginLeft: "2%", color: "white", width: "20%", backgroundColor: "black", border: "1px solid black" }}>Save</Button> : <Button className="sendButton" onClick={uploadLevel5}>Save</Button>} */}
                {!income ? (
                  <Button
                    disabled
                    style={{
                      marginLeft: "2%",
                      color: "white",
                      width: "20%",
                      backgroundColor: "black",
                      border: "1px solid black",
                    }}
                  >
                    Save
                  </Button>
                ) : (
                  <Button className="sendButton" onClick={uploadLevel5}>
                    Save
                  </Button>
                )}
              </div>
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLevel1Data: () => dispatch(getLevel1Data()),
    getLevel2Data: () => dispatch(getLevel2Data()),
    getLevel3Data: () => dispatch(getLevel3Data()),
    getLevel4Data: () => dispatch(getLevel4Data()),
    getLevel5Data: () => dispatch(getLevel5Data()),
    AddLevel5Name: (data, handleVisible, handleDangerVisible) =>
      dispatch(AddLevel5Name(data, handleVisible, handleDangerVisible)),
    getcreditdebit: () => dispatch(getcreditdebit()),
    deleteLevel5: (levelFiveId) => dispatch(deleteLevel5(levelFiveId)),
    getLevelOneName: (data) => dispatch(getLevelOneName(data)),
    getLevelTwoName: (data) => dispatch(getLevelTwoName(data)),
    getLevelFourName: (data) => dispatch(getLevelFourName(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  levelTow: state.accountsLevelReducer.levelTow,
  levelThree: state.accountsLevelReducer.levelThree,
  levelFour: state.accountsLevelReducer.levelFour,
  level1List: state.accountsLevelReducer.level1List,
  level2List: state.accountsLevelReducer.level2List,
  level3List: state.accountsLevelReducer.level3List,
  level4List: state.accountsLevelReducer.level4List,
  level5List: state.accountsLevelReducer.level5List,
  D_CList: state.accountsLevelReducer.D_CList,
  creditDebitValue: state.accountsLevelReducer.creditDebitValue,
  isFetchinglevel5List: state.accountsLevelReducer.isFetchinglevel5List,
  isAddingLevel5: state.accountsLevelReducer.isAddingLevel5,
  isNotAddLevel5: state.accountsLevelReducer.isNotAddLevel5,
  isFetchinglevel1List: state.accountsLevelReducer.isFetchinglevel1List,
  isFetchingLevelTow: state.accountsLevelReducer.isFetchingLevelTow,
  isFetchingLevelThree: state.accountsLevelReducer.isFetchingLevelThree,
  isFetchingLevelFour: state.accountsLevelReducer.isFetchingLevelFour,
  isSavingLevel5: state.accountsLevelReducer.isSavingLevel5,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddLevel5);
