import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty, isNull, find } from "lodash";
import Spinner from 'react-bootstrap/Spinner';
import Select from 'react-select';
import "../production-styles.css";
import {getProcessCategoryData, getEmployeeData, getOrderNo, getArticleByOrderId, getCategoryByArticleId, getProcessByCategoryId, addIssuanceData} from "./Issuance.actions";
import { getProcessData } from "../AddLabourFolder/AddLabour.actions";

const InternalIssuance = (props) => {
    const [checkedState, setCheckedState] = useState([]);
    const [jobName, setJobName] = useState();
    const [perUniPrice, setPerUniPrice] = useState();
    const [articleId, setArticleId] = useState();
    const [dueDate, setDueDate] = useState();
    const [worker, setWorker] = useState();
    const [articleMaterial, setArticleMaterial] = useState();
    const [allocateQuantity, setAllocateQuantity] = useState();
    const [comment, setComment] = useState();
    const [orderNo, setOrderNo] = useState();
    const [show, setShow] = useState(false);
    const [laborRate, setLaborRate] = useState();
    console.log(laborRate, "rrrrrrrrrrrrr");

  //  const getJobName = props.categoryProcessList.map((x) => { let data = { value: x.categoryProcessId, label: x.categoryProcessName }; return data });
  //  const getArticleList = !isEmpty(props.articleListByOrder) && props.articleListByOrder.map((x) => {let data = {value: x.order_articles.map((x) => {return x.article.articleId}), label: x.order_articles.map((x) => {return x.article.itemName})}; return data});
  const getArticleList = !isEmpty(props.articleListByOrder) && props.articleListByOrder[0].order_articles.map((x) => {let data = {value: x.article.articleId, label:  x.article.itemName}; return data});
   
  console.log(getArticleList, "gggggggggggg");
    const getWorkerList = props.employeeList.map((x) => { let data = { value: x.employeeId, label: x.Name }; return data });
  //  const getArticleMaterialList = !isEmpty(props.articleListByOrder) && props.articleListByOrder.map((x) => {let data = {value: x.processes.processId, label: x.processes.processName}; return data});
    const getOrderNo = props.orderNoList.map((x) => {let data = {value: x.orderId, label: x.manualOrderId}; return data});
const  getProcessListData = !isEmpty(props.processListByCategoryId) && props.processListByCategoryId.map((x) => {let data = {value: x.process.processId, label: x.process.processName, rate: x.labourRate}; return data});
const getJobName = !isEmpty(props.categoryListByArticleId) && props.categoryListByArticleId.map((x) => {let data = {value: x.category_process.categoryProcessId, label: x.category_process.categoryProcessName}; return data});
console.log(getJobName, "Nnnnnnnnnnnnn");
 const handleOnChangeRate = (position, e, type) => {
        console.log(e, "eeeeeeeeeeeeeee");
        let employeeIdValue;
        console.log(e.value, "ooooooooo")

        if (isEmpty(find(checkedState, x => x.id == position))) {
            // it dont exist    
            if (document.getElementById(`custom-checkbox-${position}`).checked) {
                let z = checkedState;

                // let obj = [];

                z.push({
                    id: position,
                    processId: type == 'checkBoxValue' && e.target.value,
                    rate: type == 'checkBoxValue' && find(getProcessListData, x => x.value == (type == 'checkBoxValue' && e.target.value)).rate,
                })
                setCheckedState(z);
                // setTotalCost(checkedState.reduce((a, b) => a = Number(a) + Number(b.rate), 0));

            }
            else {
                let z = checkedState;
                let filterarray = z.filter(function (ele) {
                    return ele.id != position;
                });
                console.log(filterarray, "aaaaaa");
                setCheckedState(filterarray);
                // setTotalCost(checkedState.reduce((a, b) => a = Number(b.rate) - Number(a), 0));

            }
        }
        else {
            //it already exist
            if (document.getElementById(`custom-checkbox-${position}`).checked) {
                let z = checkedState;
                console.log(e.value, "ooooooooo")
                if (type == 'employee') employeeIdValue = e.value;
                z.map((x) => {
                    if (x.id == position) {
                        if (type == 'employee') x['rate'] = x['rate']
                        else x['rate'] = e.target.value
                        if (type == 'employee') x['employeeId'] = employeeIdValue
                        else x['employeeId'] = x['employeeId']
                    }
                    else {
                        return x;
                    }
                })
                // setTotalCost(checkedState.reduce((a, b) => a = Number(a) + Number(b.rate), 0));

            }
            else {
                let z = checkedState;
                let filterarray = z.filter(function (ele) {
                    return ele.id != position;
                });

                setCheckedState(filterarray);
                // setTotalCost(checkedState.reduce((a, b) => a = Number(b.rate) - console.log(Number(a), "ooooooo"), 0));

            }
        }
        console.log(checkedState, "checkkkkkkkkkkkk");
        // console.log(totalCost, "cccccccc");
        // total = !isEmpty(checkedState) ? checkedState.reduce((a, b) => a = Number(a) + Number(b.rate), 0) : 0;
        // console.log(total, "tttt");
        // setTotalCost(total);
        // console.log(totalCost, "cccccccc")
    }
    const handleChangeOrder = (selectedOption) => {
        setOrderNo(selectedOption.value);
        props.getArticleByOrderId({orderId: selectedOption.value})
    };

    const handleChangeJobName = (selectedOption) => {
        setJobName(selectedOption.value);
        props.getProcessByCategoryId({categoryProcessId: selectedOption.value, articleId: articleId});
        //setLaborRate(!isEmpty(props.processListByCategoryId) && props.processListByCategoryId.map((x) => {return x.labourRate}));
        setShow(true);
    };

    const handleChangeArticle = (selectedOption) => {
        setArticleId(selectedOption.value);
        props.getCategoryByArticleId({articleId: selectedOption.value})
    };

    const handleChangeWorker = (selectedOption) => {
        setWorker(selectedOption.value);
    };

    const handleChangeArticleMaterial = (selectedOption) => {
        setArticleMaterial(selectedOption.value);
    };


    const saveIssuance = () => {
        props.addIssuanceData({
            orderId: orderNo,
            articleId: articleId,
            employeeId: worker,
            internaljobDate: dueDate,
            categoryProcessId: jobName,
            internaljobAllocate: allocateQuantity,
            processData: !isEmpty(checkedState) && checkedState.map((x) => {let data = {processId: x.processId, internaljobRate: x.rate}; return data}),
            internaljobComment: comment
        })
    };

    useEffect(() => {
        props.getProcessCategoryData();
        props.getEmployeeData();
        props.getOrderNo();
    }, []);
    return (
        <>

            {props.isFetchingcategoryList || props.isFetchingwareHouseList ? (
                <div className="loader-div">
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />
                        {/* {props.isFetchingSectorsSummary ? 'Loading...' : 'Loading...'} */}

                    </Button>
                </div>
            ) : (
                <Container fluid>
                    <div className="main">
                    <Row>
                            <Col xl="6" lg="6" md="6" sm="12">
                                <Form.Label>
                                    <b> Select Order # </b>
                                </Form.Label>
                                <Select
                                    placeholder="Select Order #..."
                                 onChange={handleChangeOrder}
                                 options={getOrderNo}
                                />
                            </Col>
                            <Col xl="6" lg="6" md="6" sm="12">
                                    <Form.Label>
                                        <b> Select Article</b>
                                    </Form.Label>
                                    <Select
                                        placeholder="Select Article..."
                                      onChange={handleChangeArticle}
                                      options={getArticleList}
                                    />
                                </Col>
                            </Row>

                            <Row>
                        <Col xl="6" lg="6" md="6" sm="12">
                                <Form.Label>
                                    <b> Select Worker </b>
                                </Form.Label>
                                <Select
                                    placeholder="Select Worker..."
                                 onChange={handleChangeWorker}
                                 options={getWorkerList}
                                />
                            </Col>

                            <Col xl="6" lg="6" md="6" sm="12">
                                    <Form.Group className="mb-3">
                                        <Form.Label><b>Due Date :</b></Form.Label>
                                        <Form.Control type="date" placeholder="Date...." onBlur = {(e) => setDueDate(e.target.value)} />
                                    </Form.Group>
                                </Col>
                        </Row>

                        <Row>
                            <Col xl="6" lg="6" md="6" sm="12">
                                <Form.Label>
                                    <b> Select Job Name </b>
                                </Form.Label>
                                <Select
                                    placeholder="Select Job Name..."
                                 onChange={handleChangeJobName}
                                 options={getJobName}
                                />
                            </Col>
                            {/* <Col xl="6" lg="6" md="6" sm="12">
                                    <Form.Group className="mb-3">
                                        <Form.Label><b>Rate Per Unit (Price) :</b></Form.Label>
                                        <Form.Control type="text" placeholder="Per unit price...." onBlur = {(e) => setPerUniPrice(e.target.value)} />
                                    </Form.Group>
                                </Col> */}

                                <Col xl="6" lg="6" md="12" md="12" sm="12">
                                <Form.Group className="mb-3">
                                    <Form.Label><b>Allocate Quantity out of 180 :</b></Form.Label>
                                    <Form.Control type="number" placeholder="Allocate Quantity...." onBlur = {(e) => setAllocateQuantity(e.target.value)} />
                                </Form.Group>
                            </Col>
                        </Row>
                        {show == true ?
                            !isEmpty(getProcessListData) && getProcessListData.map((x, index) => {
                                console.log(x, "xdataaaaa");
                                return (
                                    <React.Fragment key={index}>

                                        <Row>
                                            <Col xl="6" lg="6" md="6" sm="6">
                                                <input

                                                    type="checkbox"
                                                    id={`custom-checkbox-${index}`}
                                                    name="checkedStateValue"
                                                    value={x.value}
                                                    // checked={checkedState[index]}
                                                    onChange={(e) => handleOnChangeRate(index, e, 'checkBoxValue')}
                                                />
                                                <span style={{ paddingLeft: "2%" }}>{x.label}</span>
                                            </Col>

                                            <Col xl="6" lg="6" md="6" sm="12">
                                    <Form.Group className="mb-3">
                                        <Form.Label><b>Rate Per Unit (Price) :</b></Form.Label>
                                        <Form.Control type="text" placeholder="Per unit price...." value = {x.rate} onChange={(e) => handleOnChangeRate(index, e, 'intRate')} readOnly />
                                    </Form.Group>
                                </Col>
                                        </Row>
                                    </React.Fragment>
                                );
                            })
                            : ""}
                        {/* <Row>
                            <Col xl="6" lg="6" md="6" sm="12">
                                <Form.Label>
                                    <b> Select Order # </b>
                                </Form.Label>
                                <Select
                                    placeholder="Select Order #..."
                                 onChange={handleChangeOrder}
                                 options={getOrderNo}
                                />
                            </Col>
                            <Col xl="6" lg="6" md="6" sm="12">
                                    <Form.Label>
                                        <b> Select Article</b>
                                    </Form.Label>
                                    <Select
                                        placeholder="Select Article..."
                                    //  onChange={handleChangeArticle}
                                    //  options={getArticleList}
                                    />
                                </Col>
                        </Row> */}

                        {/* <Row>
                        <Col xl="6" lg="6" md="6" sm="12">
                                    <Form.Label>
                                        <b> Select Article Material Selection </b>
                                    </Form.Label>
                                    <Select
                                        placeholder="Select Article Material Selection..."
                                    //  onChange={handleChangeArticleMaterial}
                                    //  options={getArticleMaterialList}
                                    />
                                </Col>
                            <Col xl="6" lg="6" md="12" md="12" sm="12">
                                <Form.Group className="mb-3">
                                    <Form.Label><b>Allocate Quantity out of 180 :</b></Form.Label>
                                    <Form.Control type="number" placeholder="Allocate Quantity...." onBlur = {(e) => setAllocateQuantity(e.target.value)} />
                                </Form.Group>
                            </Col>
                            </Row> */}
                            <Row>
                            <Col xl="12" lg="12" md="12" sm="12">
                                <Form.Group className="mb-3">
                                    <Form.Label><b>Total Rate :</b></Form.Label>
                                    <Form.Control type="number" placeholder="Total Rate...." value={!isEmpty(checkedState) ? checkedState.reduce((a, b) => a = a + Number(b.rate), 0) : 0} readOnly />
                                </Form.Group>
                            </Col>
                            </Row>
                            <Row>
                            <Col xl="12" lg="12" md="12" md="12" sm="12">
                                    <Form.Group className="mb-3">
                                        <Form.Label><b>Comments :</b></Form.Label>
                                        <Form.Control id="Comments" as="textarea" rows={3}
                                            placeholder="Comments....." onMouseLeave = {(e) => setComment(e.target.value)} />
                                    </Form.Group>
                                </Col>
                        </Row>
                        <div className="sendDiv">
                            <Button className="sendButton" onClick={saveIssuance}>Save</Button>
                        </div>
                    </div>
                </Container>
            )}

        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
        getProcessCategoryData: () => dispatch(getProcessCategoryData()),
        getEmployeeData: () => dispatch(getEmployeeData()),
        getOrderNo: () => dispatch(getOrderNo()),
        getArticleByOrderId: (data) => dispatch(getArticleByOrderId(data)),
        getCategoryByArticleId: (data) => dispatch(getCategoryByArticleId(data)),
        getProcessByCategoryId: (data) => dispatch(getProcessByCategoryId(data)),
        addIssuanceData: (data) => dispatch(addIssuanceData(data))
    };
};
const mapStateToProps = (state, ownProps) => ({
    categoryProcessList: state.AddIssuanceReducer.categoryProcessList,
    employeeList: state.AddIssuanceReducer.employeeList,
    orderNoList: state.AddIssuanceReducer.orderNoList,
    articleListByOrder: state.AddIssuanceReducer.articleListByOrder,
    categoryListByArticleId: state.AddIssuanceReducer.categoryListByArticleId,
    processListByCategoryId: state.AddIssuanceReducer.processListByCategoryId
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InternalIssuance);