import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import logo from "../../../../Images/logo.png";
import { groupBy, isEmpty, get, isUndefined } from "lodash";

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#E4E4E4",
  },
  section: {
    margin: 5,
    padding: 5,
    flexGrow: 1,
  },
  textStyles: {
    marginLeft: 20,
  },
  textStyles1: {
    marginLeft: 30,
  },
  textStyles2: {
    marginLeft: 40,
  },
  image: {
    width: "30%",
  },
});

var groupedLevel3;
var keys;
const PDFLevel4 = (props) => (
  (groupedLevel3 = groupBy(!isEmpty(props.array) && props.array, function (x) {
    return x.levelFourData.level_one.name;
  })),
  (keys = !isEmpty(groupedLevel3) && Object.keys(groupedLevel3)),
  (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Image style={styles.image} src={logo} />
          <Text style={{ textAlign: "center", padding: "5%" }}>
            Company Name: {process.env.REACT_APP_COMPANY_NAME}
          </Text>
          {!isEmpty(keys)
            ? keys.map((y, index) => {
                let x = get(groupedLevel3, `${y}`);
                return (
                  <View key={index}>
                    <Text>
                      {x[0].levelFourData.level_one.key}-{" "}
                      {x[0].levelFourData.level_one.name}
                    </Text>
                    {Object.keys(
                      groupBy(x, (y) => y.levelFourData.level_two.levelTwoName)
                    )
                      ? Object.keys(
                          groupBy(
                            x,
                            (y) => y.levelFourData.level_two.levelTwoName
                          )
                        ).map((xyz, index1) => {
                          let x1 = get(
                            groupBy(
                              x,
                              (y) => y.levelFourData.level_two.levelTwoName
                            ),
                            `${xyz}`
                          );
                          return (
                            <View key={index1}>
                              <Text style={styles.textStyles}>
                                {x1[0].levelFourData.level_one.key +
                                  "-" +
                                  x1[0].levelFourData.level_two.levelTwoKey}
                                - {x1[0].levelFourData.level_two.levelTwoName}
                              </Text>
                              {Object.keys(
                                groupBy(
                                  x1,
                                  (z) =>
                                    z.levelFourData.level_three.levelThreeName
                                )
                              )
                                ? Object.keys(
                                    groupBy(
                                      x1,
                                      (z) =>
                                        z.levelFourData.level_three
                                          .levelThreeName
                                    )
                                  ).map((wxyz, index2) => {
                                    let x2 = get(
                                      groupBy(
                                        x1,
                                        (z) =>
                                          z.levelFourData.level_three
                                            .levelThreeName
                                      ),
                                      `${wxyz}`
                                    );
                                    return (
                                      <View key={index2}>
                                        <Text style={styles.textStyles1}>
                                          {x2[0].levelFourData.level_one.key +
                                            "-" +
                                            x2[0].levelFourData.level_two
                                              .levelTwoKey +
                                            "-" +
                                            x2[0].levelFourData.level_three
                                              .levelThreeKey}
                                          -{" "}
                                          {
                                            x2[0].levelFourData.level_three
                                              .levelThreeName
                                          }
                                        </Text>
                                        {!isEmpty(x2)
                                          ? x2.map((lvl4, index3) => {
                                              return (
                                                <View key={index3}>
                                                  <Text
                                                    style={styles.textStyles2}
                                                  >
                                                    {
                                                      lvl4.levelFourData
                                                        .allLevlKey
                                                    }
                                                    -{" "}
                                                    {
                                                      lvl4.levelFourData
                                                        .levelFourName
                                                    }{" "}
                                                    {!isUndefined(
                                                      lvl4.amount
                                                    ) &&
                                                      lvl4.amount.total_amount}
                                                  </Text>
                                                </View>
                                              );
                                            })
                                          : ""}
                                      </View>
                                    );
                                  })
                                : ""}
                            </View>
                          );
                        })
                      : ""}
                  </View>
                );
              })
            : ""}
        </View>
      </Page>
    </Document>
  )
);
export default PDFLevel4;
