import API from "../../../../../global/api";


//Add Order Call
export const addOrderCall = (data) => {
    return (dispatch) => {
        dispatch(requestAddOrder())
        API.post('createOrder/order_add_data', data).then((res) => {
            dispatch(successAddOrder(data))
        }).catch((error) => {
            dispatch(errorAddOrder())
        })
    }
}

export const requestAddOrder = () => {
    return {
        type: "REQUEST_ADD_ORDER"
    }
}

export const successAddOrder = (data) => {
    return {
        type: "SUCCESS_ADD_ORDER",
        payload: data,
    }
}

export const errorAddOrder = () => {
    return {
        type: "ERROR_ADD_ORDER"
    }
}



//Get Order Call

export const getOrderCall = () => {
    return (dispatch) => {
        dispatch(requestGetOrder())
        API.get(`createOrder/get-all-order-data`).then((res) => {
            dispatch(successGetOrder(res.data))
        }).catch((error) => {
            dispatch(errorGetOrder())
        })
    }
}

export const requestGetOrder = () => {
    return {
        type: "REQUEST_GET_ORDER"
    }
}

export const successGetOrder = (data) => {
    return {
        type: "SUCCESS_GET_ORDER",
        payload: data,
    }
}

export const errorGetOrder = () => {
    return {
        type: "ERROR_GET_ORDER"
    }
}



//Add Child Order Call
export const addChildOrderCall = (data) => {
    return (dispatch) => {
        dispatch(requestAddChildOrder())
        API.post('createOrder/order_child_data', data).then((res) => {
            dispatch(successAddChildOrder(data))
        }).catch((error) => {
            dispatch(errorAddChildOrder())
        })
    }
}

export const requestAddChildOrder = () => {
    return {
        type: "REQUEST_ADD_CHILD_ORDER"
    }
}

export const successAddChildOrder = (data) => {
    return {
        type: "SUCCESS_ADD_CHILD_ORDER",
        payload: data,
    }
}

export const errorAddChildOrder = () => {
    return {
        type: "ERROR_ADD_CHILD_ORDER"
    }
}



//Get Order Article Call
export const getOrderArticleCall = (data) => {
    return (dispatch) => {
        dispatch(requestGetOrderArticle())
        API.get(`/createOrder/get_order_marterial/${data}`).then((res) => {
            dispatch(successGetOrderArticle(res.data))
        }).catch((error) => {
            dispatch(errorGetOrderArticle())
        })
    }
}

export const requestGetOrderArticle = () => {
    return {
        type: "REQUEST_GET_ORDER_ARTCLE"
    }
}

export const successGetOrderArticle = (data) => {
    return {
        type: "SUCCESS_GET_ORDER_ARTCLE",
        payload: data,
    }
}

export const errorGetOrderArticle = () => {
    return {
        type: "ERROR_GET_ORDER_ARTCLE"
    }
};





export const getArticleByOrder = (data) => {
    return (dispatch) => {
        dispatch(requestGetArticleByOrder())
        API.get(`/createOrder/get_order_article/${data}`).then((res) => {
            dispatch(successGetArticleByOrder(res.data))
        }).catch((error) => {
            dispatch(errorGetArticleByOrder())
        })
    }
}

export const requestGetArticleByOrder = () => {
    return {
        type: "REQUEST_GET_ARTICLE_BY_ORDER"
    }
}

export const successGetArticleByOrder = (data) => {
    return {
        type: "SUCCESS_GET_ARTICLE_BY_ORDER",
        payload: data,
    }
}

export const errorGetArticleByOrder = () => {
    return {
        type: "ERROR_GET_ARTICLE_BY_ORDER"
    }
}



export const getDataByOrderArticle = (data) => {
    return (dispatch) => {
        dispatch(requestGetDataByOrderArticle())
        API.get(`/createOrder/get_order_inventory/${data}`).then((res) => {
            dispatch(successGetDataByOrderArticle(res.data))
        }).catch((error) => {
            dispatch(errorGetDataByOrderArticle())
        })
    }
}

export const requestGetDataByOrderArticle = () => {
    return {
        type: "REQUEST_GET_DATA_BY_ORDER_ARTICLE"
    }
}

export const successGetDataByOrderArticle = (data) => {
    return {
        type: "SUCCESS_GET_DATA_BY_ORDER_ARTICLE",
        payload: data,
    }
}

export const errorGetDataByOrderArticle = () => {
    return {
        type: "ERROR_GET_DATA_BY_ORDER_ARTICLE"
    }
}