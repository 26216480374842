import React from "react";
import { Redirect, Route } from "react-router-dom";

function ProtectedRoute({ component: Component, ...restOfProps }) {
  const isAuthenticatedAdmin = Boolean(localStorage.getItem("isAuthenticatedAdmin"));
  const isAuthenticatedUser = Boolean(localStorage.getItem("isAuthenticatedUser"));


  return (
    <Route
      {...restOfProps}
      render={(props) =>
        isAuthenticatedAdmin || isAuthenticatedUser  ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
}

export default ProtectedRoute;