import React, { useEffect } from "react";
import { connect } from "react-redux";
import MaterialTable from "material-table";
import moment from "moment";
// import UserModal from './UserModal';
import { Redirect } from "react-router-dom";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { isEmpty } from "lodash";
import {
  addVendorsCall,
  getVendorsCall,
  updateVendorsCall,
  deleteVendorsCall,
} from "./production.actions";

const AddVendors = (props) => {
  //   const [open, setOpen] = React.useState(false);

  /* const [state, setState] = React.useState({
        columns: [
            { title: 'First Name', field: 'firstName' },
            { title: 'Last Name', field: 'lastName' },
            // { title: 'Vendor Name', field: 'username' },
            { title: 'Email', field: 'email' },
            { title: 'Phone No', field: 'phone', type: 'numeric', },
            { title: 'Business Address', field: 'businessName' },
            { title: 'Company Name', field: 'companyName' },
        ],
    });*/

  useEffect(() => {
    // props.getUsers();
    props.getVendorsCall();
  }, []);

  //   const [callEdit, setcallEdit] = React.useState(false);
  //   if (callEdit) {
  //     return <Redirect push to="/admin/view-user" />;
  //   }

  return (
    <>
      <MaterialTable
        title="Vendors"
        columns={[
          { title: "First Name", field: "firstName" },
          { title: "Last Name", field: "lastName" },
          // { title: 'Vendor Name', field: 'username' },
          { title: "Email", field: "email" },
          { title: "Phone No", field: "phone", type: "numeric" },
          { title: "Business Address", field: "businessName" },
          { title: "Company Name", field: "companyName" },
        ]}
        data={props.vendorsInfo}
        actions={
          [
            // {
            // icon: VisibilityIcon,
            //   tooltip: 'View User',
            //   onClick: (event, rowData) => {
            //     // Do save operation
            //     setcallEdit(true);
            //    props.selectedUser(rowData)
            //   }
            // }
          ]
        }
        options={{
          actionsColumnIndex: -1,
          maxBodyHeight: "600px",
          headerStyle: {
            position: "sticky",
            top: 0,
            color: "#00BBBB",
            fontWeight: "550",
            onRowAdd: "none",
          },
        }}
        editable={{
          onRowAdd: (newData) =>
            new Promise((resolve) => {
              setTimeout(() => {
                resolve();
                props.addVendorsCall(newData);
              }, 600);
            }),

          onRowUpdate: (newData, oldData) =>
            new Promise((resolve) => {
              setTimeout(() => {
                resolve();
                if (oldData) {
                  props.updateVendorsCall(newData, oldData);
                }
              }, 600);
            }),

          onRowDelete: (oldData) =>
            new Promise((resolve) => {
              setTimeout(() => {
                resolve();
                props.deleteVendorsCall(oldData.productionVendorId);
              }, 600);
            }),
        }}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getVendorsCall: () => dispatch(getVendorsCall()),
    addVendorsCall: (data) => dispatch(addVendorsCall(data)),
    deleteVendorsCall: (vendorId) => dispatch(deleteVendorsCall(vendorId)),
    updateVendorsCall: (newData, oldData) =>
      dispatch(updateVendorsCall(newData, oldData)),
  };
};

const mapStateToProps = (state, ownProps) => ({
  vendorsInfo: state.productionReducer.vendorsInfo,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddVendors);
