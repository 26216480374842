import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import {isNull } from "lodash";
import AddDivision from "./AddDivision";
import DivisionList from "./DivisionList";
const TotalDivisionData = (props) => {
    const permissions = JSON.parse(localStorage.getItem("permissions"));

    return (
        <>
            <Tabs defaultActiveKey={!isNull(permissions) && permissions.some(p => p['sub_id'] === 48) ?
                    "AddDivision" : !isNull(permissions) && permissions.some(p => p['sub_id'] === 49) ?
                        "DivisionList" : ""}  id="AddDivision" className="mb-3">
                <Tab eventKey="AddDivision" title="Add Division"
                disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 48) ? false : true}>  
                    <AddDivision />
                </Tab>
                <Tab eventKey="DivisionList" title="Division List"
                disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 49) ? false : true}> 
                    <DivisionList />
                </Tab>
            </Tabs>
        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
    };
};
const mapStateToProps = (state, ownProps) => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TotalDivisionData);