import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import {isNull } from "lodash";
import AddMasterControl from "./AddMasterControl";
import MasterControlTableList from "./MasterControlTableList";
const TotalMasterControlDataInventory = (props) => {
    const permissions = JSON.parse(localStorage.getItem("permissions"));

    return (
        <>
            <Tabs defaultActiveKey={!isNull(permissions) && permissions.some(p => p['sub_id'] === 117) ?
                    "AddMasterControl" : !isNull(permissions) && permissions.some(p => p['sub_id'] === 118) ?
                        "MasterControlList" : ""} id="AddMasterControl" className="mb-3">
                <Tab eventKey="AddMasterControl" title="Add MasterControl"
                disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 115) ? false : true}>  
                    <AddMasterControl />
                </Tab>
                <Tab eventKey="MasterControlList" title="MasterControl List"
                disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 116) ? false : true}> 
                    <MasterControlTableList />
                </Tab>
            </Tabs>
        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
    };
};
const mapStateToProps = (state, ownProps) => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TotalMasterControlDataInventory);