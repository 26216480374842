import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, FormGroup } from "react-bootstrap";
import "./add-details.css";
import {
    getProductByDemand, getPurchase, getApproveDemand, getApprovePurchase, purchaseById,
    addIGP, getIGP, searchIGPReport, resetPurchaseReducer, purchaseFromDemandCall
} from "./AddDetails.actions";
import { connect } from "react-redux";
import { isEmpty, find } from "lodash";
import moment from 'moment';
import Spinner from 'react-bootstrap/Spinner';
import Select from 'react-select';
import Swal from 'sweetalert';

const IGPReport = (props) => {

    const [inputField, setInputField] = useState([{
        inventoryId: "",
        productQuantityCount: "",
        productQuantity: "",
        inventoryLabel: ""
    }]);

    const [demandName, setDemandName] = useState();
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [demandNameValue, setDemandNameValue] = useState();
    const [demandNameLabel, setDemandNameLabel] = useState();
    const [purchaseNameValue, setPurchaseNameValue] = useState();
    const [purchaseNameLabel, setPurchaseNameLabel] = useState();
    const [IGPNameValue, setIGPNameValue] = useState();
    const [IGPNameLabel, setIGPNameLabel] = useState();
    const [IGPManualNameValue, setIGPManualNameValue] = useState();
    const [IGPManualNameLabel, setIGPManualNameLabel] = useState();
    const [visibleAlert, setAlertVisible] = useState(false);

    const handleVisible = () => {
        setAlertVisible(true)
        setTimeout(() => {
            setAlertVisible(false)
        }, 9000);
        Swal({
            title: "Data Not Found",
            text: "Data Not Found",
            icon: "info",
            button: "Ok",
        });
    }


    const handleChangeGetPurchase = (selectedOption) => {
        setPurchaseNameValue(selectedOption.value);
        setPurchaseNameLabel(selectedOption.label);
        props.purchaseById({ purchaseOrderId: selectedOption.value });
    };

    const handleChangeGetIGP = (selectedOption) => {
        setIGPNameValue(selectedOption.value);
        setIGPNameLabel(selectedOption.label);
    };

    const handleChangeGetManualIGP = (selectedOption) => {
        setIGPManualNameValue(selectedOption.value);
        setIGPManualNameLabel(selectedOption.label);
    };
    console.log(props.IGPList,"tttttttttttttttttttttt")
    const getIGPList = props.IGPList.map((x) => { let data = { value: x.igpId, label: x.igpId }; return data })
    const getManualIGPList = props.IGPList.map((x) => { let data = { value: x.gatePass, label: x.gatePass }; return data })

    const exportPDF = () => {
        props.searchIGPReport({
            startedDate: !isEmpty(startDate) ? startDate : "",
            endDate: !isEmpty(endDate) ? endDate : "",
            igpId: IGPNameValue,
            purchaseOrderId: purchaseNameValue,
            demandId: demandNameValue,
            manualIGP: !isEmpty(IGPManualNameValue) ? IGPManualNameValue : "",
        }, IGPNameLabel, startDate, endDate, handleVisible);
        setStartDate("");
        setEndDate("");
        setDemandNameLabel("");
        setPurchaseNameLabel("");
        setIGPNameLabel("");
        setIGPManualNameLabel("");
    }
    const getPurchaseListFilter = !isEmpty(props.purchaseFromDemandData) && props.purchaseFromDemandData.map((x) => { let data = { value: x.purchaseOrderId, label: x.purchaseOrderId + " / " + x.purchaseName }; return data });
    // const getDemandList = [isEmpty(props.purchaseListById) ? [] : { value: props.purchaseListById[0].purchase_data[0].create_demand.demandId, label: props.purchaseListById[0].purchase_data[0].create_demand.demandName }];/*props.purchaseListById.map((x) => x.purchase_data.map((a) => {let data = {value: a.create_demand.demandId, label: a.create_demand.demandName}; return data}))*/;
    const getDemandList = props.approveDemandList.map((x) => { let data = { value: x.demandId, label: x.demandId + " / " + x.demandName }; return data });

    // const inventory = [isEmpty(props.purchaseListById) ? [] : { value: props.purchaseListById[0].purchase_data[0].inventory.inventoryId, label: props.purchaseListById[0].purchase_data[0].inventory.warehouse.whKey + "-" + props.purchaseListById[0].purchase_data[0].inventory.category.cateKey + "-" + props.purchaseListById[0].purchase_data[0].inventory.sub_category.subcateKey + "-" + props.purchaseListById[0].purchase_data[0].inventory.invKey + " " + props.purchaseListById[0].purchase_data[0].inventory.productName }];
    //  const inventory = isEmpty(props.purchaseListById) ? [] : props.purchaseListById[0].purchase_data.map((x) => { let data = { value: x.inventory.inventoryId, label: x.inventory.warehouse.whKey + "-" + x.inventory.category.cateKey + "-" + x.inventory.sub_category.subcateKey + "-" + x.inventory.invKey + " " + x.inventory.productName }; return data });

    const inventory = isEmpty(props.purchaseListById) ? [] : props.purchaseListById[0].purchase_data.map((x) => { let data = { value: x.inventory.inventoryId, label: x.inventory.warehouse.whKey + "-" + x.inventory.category.cateKey + "-" + "-" + x.inventory.invKey + " " + x.inventory.productName }; return data });

    const handleChangeGetDemand = (selectedOption) => {
        setDemandName(selectedOption.value);
        setDemandNameLabel(selectedOption.label);
        setDemandNameValue(selectedOption.value);
        props.purchaseFromDemandCall(selectedOption.value);
    };

    useEffect(() => {
        props.getApproveDemand();
        props.getIGP();
        // props.getApprovePurchase();
        props.resetPurchaseReducer();
    }, []);
    return (
        <>
            {props.isFetchningApproveDemandList || props.isFetchingPurchaseFromDemand ? (
                <div className="loader-div">
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />

                    </Button>
                </div>
            ) : (
                <Container fluid>
                    <div className="main">
                        <Row>
                            <Col xl="6" lg="6" md="6" sm="6">
                                <FormGroup>
                                    <Form.Label>
                                        <b>Start Date :</b>
                                    </Form.Label>
                                    <Form.Control
                                        type="date"
                                        value={startDate}
                                        onChange={(e) => setStartDate(e.target.value)}
                                    >
                                    </Form.Control>
                                </FormGroup>
                            </Col>
                            <Col xl="6" lg="6" md="6" sm="6">
                                <FormGroup>
                                    <Form.Label>
                                        <b>End Date</b>
                                    </Form.Label>
                                    <Form.Control
                                        type="date"
                                        value={endDate}
                                        onChange={(e) => setEndDate(e.target.value)}
                                    >
                                    </Form.Control>
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col xl="6" lg="6" md="12" sm="12">
                                <Form.Group>
                                    <Form.Label>
                                        <b> Select Demand</b>
                                    </Form.Label>
                                    <Select
                                        value={{ label: demandNameLabel }}
                                        placeholder="Select Demand..."
                                        onChange={(e) => handleChangeGetDemand(e)}
                                        options={!isEmpty(getDemandList) ? getDemandList.reverse() : []}
                                    />
                                </Form.Group>
                            </Col>

                            <Col xl="6" lg="6" md="12" sm="12">
                                <Form.Group>
                                    <Form.Label>
                                        <b> Select Purchase</b>
                                    </Form.Label>
                                    <Select
                                        value={{ label: purchaseNameLabel }}
                                        isDisabled={isEmpty(getPurchaseListFilter)}
                                        placeholder="Select Purchase..."
                                        onChange={(e) => handleChangeGetPurchase(e)}
                                        options={!isEmpty(getPurchaseListFilter) ? getPurchaseListFilter.reverse() : []}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        {/* <Row>
                                    <Col xl="6" lg="6" md="12" md="12" sm="12">
                                        <Form.Group>
                                            <Form.Label>
                                                <b> Select Purchase</b>
                                            </Form.Label>
                                            <Select
                                                placeholder="Select Purchase..."
                                                onChange={handleChangeGetPurchase}
                                                options={getPurchaseList}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xl="6" lg="6" md="12" md="12" sm="12">
                                        <Form.Group>
                                            <Form.Label>
                                                <b> Select Demand </b>
                                            </Form.Label>
                                            <Select
                                                placeholder="Select Demand..."
                                                onChange={handleChangeGetDemand1}
                                                options={getDemandList}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row> */}
                        <Row>
                            <Col xl="6" lg="6" md="12" sm="12">
                                <Form.Group>
                                    <Form.Label>
                                        <b> Select IGP</b>
                                    </Form.Label>
                                    <Select
                                    value={{label: IGPNameLabel}}
                                        placeholder="Select IGP..."
                                        onChange={(e) => handleChangeGetIGP(e)}
                                        options={!isEmpty(getIGPList) ? getIGPList.reverse() : []}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xl="6" lg="6" md="12" sm="12">
                                <Form.Group>
                                    <Form.Label>
                                        <b> Select Manual IGP</b>
                                    </Form.Label>
                                    <Select
                                    value={{label: IGPManualNameLabel}}
                                        placeholder="Select Manual IGP..."
                                        onChange={(e) => handleChangeGetManualIGP(e)}
                                        options={!isEmpty(getManualIGPList) ? getManualIGPList.reverse() : []}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <div className="sendDiv">
                            {isEmpty(demandNameLabel) || isEmpty(purchaseNameLabel) ? <Button disabled style={{ marginLeft: "2%", color: "white", width: "20%", backgroundColor: "black", border: "1px solid black" }}>Generate Inventory Report</Button> : props.isFetchingSearchIGPList ? <Button className="sendButton">Downloading PDF</Button> : <Button className="sendButton" onClick={() => exportPDF()}>Generate IGP Report</Button>}
                        </div>
                    </div>
                </Container>
            )}

        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
        getPurchase: () => dispatch(getPurchase()),
        getProductByDemand: (demandId) => dispatch(getProductByDemand(demandId)),
        getApproveDemand: () => dispatch(getApproveDemand()),
        getApprovePurchase: () => dispatch(getApprovePurchase()),
        purchaseById: (data) => dispatch(purchaseById(data)),
        addIGP: (data, handleVisible, handleDangerVisible) => dispatch(addIGP(data, handleVisible, handleDangerVisible)),
        getIGP: () => dispatch(getIGP()),
        searchIGPReport: (data, igpName, startDate, endDate, handleVisible) => dispatch(searchIGPReport(data, igpName, startDate, endDate, handleVisible)),
        resetPurchaseReducer: () => dispatch(resetPurchaseReducer()),
        purchaseFromDemandCall: (data) => dispatch(purchaseFromDemandCall(data)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    isFetchingDemand: state.AddDataReducer.isFetchingDemand,
    isFetchingInventoryAll: state.AddDataReducer.isFetchingInventoryAll,
    isAddingPurchase: state.AddDataReducer.isAddingPurchase,
    notAddPurchase: state.AddDataReducer.notAddPurchase,
    approvePurchaseList: state.AddDataReducer.approvePurchaseList,
    purchaseListById: state.AddDataReducer.purchaseListById,
    IGPList: state.AddDataReducer.IGPList,
    isFetchingSearchIGPList: state.AddDataReducer.isFetchingSearchIGPList,
    approveDemandList: state.AddDataReducer.approveDemandList,
    purchaseFromDemandData: state.AddDataReducer.purchaseFromDemandData,
    isFetchingIGP: state.AddDataReducer.isFetchingIGP,
    isFetchningApproveDemandList: state.AddDataReducer.isFetchningApproveDemandList,
    isFetchingPurchaseFromDemand: state.AddDataReducer.isFetchingPurchaseFromDemand,
    isFetchingPurchaseById: state.AddDataReducer.isFetchingPurchaseById
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(IGPReport);