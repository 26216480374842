import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import {isNull } from "lodash";
import Fine from "./Fine";
import FineList from "./FineList";

const TotalFineData = (props) => {
    const permissions = JSON.parse(localStorage.getItem("permissions"));

    return (
        <>
            <Tabs defaultActiveKey={!isNull(permissions) && permissions.some(p => p['sub_id'] === 64) ?
                    "AddFine" : !isNull(permissions) && permissions.some(p => p['sub_id'] === 65) ?
                        "FineList" : ""} id="AddFine" className="mb-3">
                <Tab eventKey="AddFine" title="Add Fine"
                disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 64) ? false : true}>  
                   <Fine />
                </Tab>
                <Tab eventKey="FineList" title="Fine List"
                disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 65) ? false : true}> 
                    <FineList />
                </Tab>
            </Tabs>
        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
    };
};
const mapStateToProps = (state, ownProps) => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TotalFineData);