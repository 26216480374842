import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Table,
  Container,
  Button,
  FormGroup,
  Alert,
  Tab,
  Tabs,
} from "react-bootstrap";
import "./add-details.css";
import { ModalBody, Modal } from "reactstrap";
import {
  getPurchase,
  getApproveDemand,
  addIssuance,
  getAllInventory,
  getIssuance,
  searchIssuanceReport,
  getWarehouseData,
} from "./AddDetails.actions";
import { connect } from "react-redux";
import { isEmpty, find, isUndefined, filter } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import WareHouseModal from "./WareHouseModal";
import MaterialTable from "material-table";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { IconButton } from "@material-ui/core";
import Select from "react-select";
import Swal from "sweetalert";
import moment from "moment";

const AddIssuance = (props) => {
  const [inputField, setInputField] = useState([
    {
      inventoryId: "",
      productQuantityCount: "",
      inventoryPrice:"",
      productQuantity: 0,
    },
  ]);
  const [date, setDate] = useState(moment(
    new Date()
  ).format("YYYY-MM-DD"));
  const [wareHouseId, setwareHouseId] = useState("");
  const [wareHouselabel, setwareHouseLabel] = useState("");

  const [maximumQuantity, setMaximumQuantity] = useState(0);
  const [requistion, setRequistion] = useState();
  const [department, setdepartment] = useState();
  const [productionOrder, setProductionOrder] = useState();
  const [supervisor, setSupervisor] = useState();
  const [issuedBy, setIssuedBy] = useState();
  const [receivedBy, setReceivedBy] = useState();
  const [requistionType, setRequistionType] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [purchaseNameValue, setPurchaseNameValue] = useState();
  const [purchaseNameLabel, setPurchaseNameLabel] = useState();
  const [issuanceNameValue, setIssuanceNameValue] = useState();
  const [issuanceNameLabel, setIssuanceNameLabel] = useState();
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  const handleVisible = () => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: "Issuance Added Successfully",
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = () => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: "Something went Wrong!",
      icon: "error",
      button: "Ok",
    });
  };

  const handleChangeGetIssuance = (selectedOption) => {
    setIssuanceNameValue(selectedOption.value);

    setIssuanceNameLabel(selectedOption.label);
  };
  const handleChangeWareHouse = (selectedOption) => {
    setwareHouseId(selectedOption.value);
    setwareHouseLabel(selectedOption.label);
  };
  const inventory = props.allInventory.map((x) => {
    let data = {
      value: x.inventoryId,
      label:
        x.warehouse.whKey +
        "-" +
        x.category.cateKey +
        "-" +
        x.sub_category.subcateKey +
        "-" +
        x.invKey +
        " " +
        x.productName,
      whId: x.wareHouseId,
    };
    return data;
  });

  const getIssuanceList = props.IssuanceList.map((x) => {
    let data = { value: x.issuanceId, label: x.issuanceId };
    return data;
  });
  const getWareHouseList = props.warehouseList.map((x) => {
    let data = { value: x.wareHouseId, label: x.wareHouseName };
    return data;
  });
  const filteredInventory =
    wareHouseId === "" ? [] : filter(inventory, { whId: wareHouseId });

  const exportPDF = () => {
    props.searchIssuanceReport(
      {
        startedDate: !isEmpty(startDate) ? startDate : "",
        endDate: !isEmpty(endDate) ? endDate : "",
        issuanceId: issuanceNameValue,
      },
      issuanceNameLabel,
      startDate,
      endDate
    );
  };

  function uploadPurchase() {
    props.addIssuance(
      {
        date: date,
        requsition: requistion,
        productionOrder: productionOrder,
        department: department,
        supervisor: supervisor,
        issuedBy: issuedBy,
        receivedBy: receivedBy,
        requsitionType: requistionType,
        data: inputField,
        wareHouseId: wareHouseId,
      },
      handleVisible,
      handleDangerVisible
    );
    setRequistion("-1");
    setdepartment("");
    setProductionOrder("");
    setSupervisor("");
    setIssuedBy("");
    setReceivedBy("");
    setRequistionType("");
    setInputField([
      {
        inventoryId: "",
        productQuantityCount: "",
        productQuantity: "",
        inventoryPrice:""
      },
    ]);
  }

  const handleChangeDemand = (e, index, type, item) => {
    let z = find(props.allInventory, (x) => x.inventoryId == item);
    if (Number(e.target.value) <= Number(z.currentBlnc)) {
      const { name, value } = e;
      const list = [...inputField];
      if (type == "intname") list[index]["inventoryId"] = e.value;
      if (type == "intname") list[index]["inventoryLabel"] = e.label;
      if (type == "intname")
        list[index]["productQuantity"] = Number(z.currentBlnc);
      if (type == "intname") setMaximumQuantity(Number(z.currentBlnc));
      if (type == "intquantity")
        list[index]["productQuantity"] =
          e.target.value < 0
            ? alert("cannot added less than 0")
            : e.target.value;
      // var holder = {};
      // list.forEach(function (d) {
      //     if (holder.hasOwnProperty(d.inventoryId)) {
      //         holder[d.inventoryId] = Number(d.productQuantity) + Number(d.productQuantity);
      //     } else {
      //         holder[d.inventoryId] = Number(d.productQuantity);
      //     }
      // });
      // var obj2 = [];
      // for (var prop in holder) {
      //     obj2.push({ inventoryId: prop, productQuantity: Number(holder[prop]) });
      // }
      setInputField(list);
    } else {
      alert("value out of range");
    }
  };

  const handleChange = (e, index, type) => {
    let z = find(props.allInventory, (x) => x.inventoryId == e.value);
    const { name, value } = e;
    const list = [...inputField];
    console.log(z,"testttttttttttttttttttt")
    if (type == "intname") list[index]["inventoryId"] = e.value;
    if (type == "intname") list[index]["inventoryLabel"] = e.label;
    if (type == "intname") list[index]["inventoryPrice"] = z.productPrice;
    if (type == "intname")
      list[index]["productQuantityCount"] = Number(z.currentBlnc);
    if (type == "intname") setMaximumQuantity(Number(z.currentBlnc));
    if (type == "intquantity") list[index]["productQuantity"] = e.target.value;
    setInputField(list);
    // list[index]['productQuantity'] = "";
  };

  const handleAddInput = () => {
    setInputField([
      ...inputField,
      { inventoryId: "", productQuantityCount: "",inventoryPrice:"", productQuantity: "" },
    ]);
  };

  const handleRemoveInput = (index) => {
    const list = [...inputField];
    list.splice(index, 1);
    setInputField(list);
  };

  const getRequistion = [
    { value: "Manual", label: "Manual" },
    { value: "After Production", label: "After Production" },
  ];

  const handleChangeRequistion = (selectedOption) => {
    setRequistion(selectedOption.value);
  };

  useEffect(() => {
    props.getAllInventory({
      startedDate: "",
      endDate: "",
      wareHouseId: "",
      subCategoryId: "",
      categoryId: "",
    });
    props.getWarehouseData();
  }, []);
  return (
    <>
      {props.isFetchingInventoryAll || props.isAddingIssuance ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {props.isAddingIssuance ? "Saving..." : "Loading..."}
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Row>
              <Col xl="12" lg="12" sm="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Date *</b>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    placeholder="Date...."
                    name="Date"
                    value={date}
                    onChange={(e) =>
                      setDate(moment(e.target.value).format("YYYY-MM-DD"))
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xl="12" lg="12" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b>Select Warehouse *</b>
                  </Form.Label>
                  <Select
                    value={{ label: wareHouselabel }}
                    placeholder="Select WareHouse..."
                    onChange={(e) => handleChangeWareHouse(e)}
                    options={getWareHouseList}
                    // onBlur={() => setStateValue(false)}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              {/* <Col xl="12" lg="12" md="12" sm="12">
                                <Form.Group>
                                    <Form.Label>
                                        <b>Requsition</b>
                                    </Form.Label>
                                    <Select
                                        placeholder="Select Requistion..."
                                        onChange={(e) => handleChangeRequistion(e)}
                                        options={getRequistion}
                                    />
                                </Form.Group>
                            </Col> */}
            </Row>
            <Row>
              <Col xl="12" lg="12" md="12" sm="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Department:</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Department...."
                    name="Department"
                    onBlur={(e) => setdepartment(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            {/* <Row>
                            <Col xl="12" lg="12" md="12" sm="12">
                                <Form.Group className="mb-3">
                                    <Form.Label><b>Production Order #:</b></Form.Label>
                                    <Form.Control type="text" placeholder="Production Order...." name="Production Order" onBlur={e => setProductionOrder(e.target.value)} />
                                </Form.Group>
                            </Col>
                        </Row> */}
            <Row>
              <Col xl="12" lg="12" md="12" sm="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Details/Supervisor:</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Details/Supervisor...."
                    name="Supervisor"
                    onBlur={(e) => setSupervisor(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xl="12" lg="12" md="12" sm="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Received By:</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Received By...."
                    name="receivedBy"
                    onBlur={(e) => setReceivedBy(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xl="12" lg="12" md="12" sm="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Issued By:</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Issued By...."
                    name="issuedBy"
                    onBlur={(e) => setIssuedBy(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            {/* <Row>
                            <Col xl="12" lg="12" md="12" sm="12">
                                <Form.Group className="mb-3">
                                    <Form.Label><b>Requsition Type:</b></Form.Label>
                                    <Form.Control type="text" placeholder="Requsition Type...." name="requistionType" value={requistionType} onBlur={e => setRequistionType(e.target.value)} />
                                </Form.Group>
                            </Col>
                        </Row> */}
            {inputField.map((item, i) => {
              return (
                <React.Fragment key={i}>
                  <Row>
                    <Col xl="3" lg="3" md="6" sm="12">
                      <Form.Group>
                        <Form.Label>
                          <b> Select Inventory *</b>
                        </Form.Label>
                        <Select
                          value={{ label: item.inventoryLabel }}
                          placeholder="Select Inventory..."
                          name="inventoryId"
                          onChange={(e) => handleChange(e, i, "intname")}
                          options={
                            isEmpty(inventory) || wareHouseId === ""
                              ? []
                              : filteredInventory
                          }
                          isDisabled={isEmpty(inventory) || wareHouseId === ""}
                          isOptionDisabled={(option) =>
                            !isEmpty(
                              find(
                                inputField,
                                (x) => x.inventoryId == option.value
                              )
                            )
                          }
                        />
                      </Form.Group>
                    </Col>

                    <Col xl="3" lg="3" md="6" sm="6">
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b>Inventories Quantity *</b>
                        </Form.Label>
                        <Form.Control
                          placeholder="Inventories Quantity...."
                          name="productQuantityCount"
                          disabled
                          value={item.productQuantityCount}
                        />
                      </Form.Group>
                    </Col>
                    <Col xl="3" lg="3" md="6" sm="6">
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b>Price *</b>
                        </Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Price...."
                          name="Price"
                          min={0}
                          value={item.inventoryPrice}
                          disabled
                          // onChange={(e) =>
                          //   handleChangeDemand(
                          //     e,
                          //     i,
                          //     "intquantity",
                          //     item.inventoryId
                          //   )
                          // }
                        />
                      </Form.Group>
                    </Col>

                    <Col xl="3" lg="3" md="6" sm="6">
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b>Add Quantity *</b>
                        </Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Add Quantity...."
                          name="productQuantity"
                          min={0}
                          value={item.productQuantity}
                          onChange={(e) =>
                            handleChangeDemand(
                              e,
                              i,
                              "intquantity",
                              item.inventoryId
                            )
                          }
                        />
                      </Form.Group>
                    </Col>

                  </Row>
                  <FormGroup>
                    <Row>
                      <Col lg="12">
                        {inputField.length - 1 == i && (
                          <IconButton
                            title="Click to Add Demand"
                            onClick={handleAddInput}
                            style={{
                              float: "right",
                              color: "black",
                            }}
                          >
                            <AddIcon />
                          </IconButton>
                        )}

                        {inputField.length !== 1 && (
                          <IconButton
                            title="Click to Remove Demand"
                            onClick={() => handleRemoveInput(i)}
                            style={{ float: "right", color: "black" }}
                          >
                            <RemoveIcon />
                          </IconButton>
                        )}
                      </Col>
                    </Row>
                  </FormGroup>
                </React.Fragment>
              );
            })}
            <div className="sendDiv">
              {wareHouseId==""||inputField[0].inventoryId== ""||
     inputField[0].productQuantityCount== ""||
     inputField[0].productQuantity== 0||
     date==""?
              <Button className="sendButton" disabled style={{backgroundColor:'black'}} onClick={uploadPurchase}>
                Save
              </Button>:
              <Button className="sendButton" onClick={uploadPurchase}>
                Save
              </Button>}
            </div>
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getPurchase: () => dispatch(getPurchase()),
    getApproveDemand: () => dispatch(getApproveDemand()),
    addIssuance: (data, handleVisible, handleDangerVisible) =>
      dispatch(addIssuance(data, handleVisible, handleDangerVisible)),
    getAllInventory: (data) => dispatch(getAllInventory(data)),
    getIssuance: () => dispatch(getIssuance()),
    searchIssuanceReport: (data, issuanceName, startDate, endDate) =>
      dispatch(searchIssuanceReport(data, issuanceName, startDate, endDate)),
    getWarehouseData: () => dispatch(getWarehouseData()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  allInventory: state.AddDataReducer.allInventory,
  isFetchingDemand: state.AddDataReducer.isFetchingDemand,
  isFetchingInventoryAll: state.AddDataReducer.isFetchingInventoryAll,
  purchaseList1: state.AddDataReducer.purchaseList1,
  approveDemandList: state.AddDataReducer.approveDemandList,
  isAddingPurchase: state.AddDataReducer.isAddingPurchase,
  notAddPurchase: state.AddDataReducer.notAddPurchase,
  IssuanceList: state.AddDataReducer.IssuanceList,
  isFetchingIssuance: state.AddDataReducer.isFetchingIssuance,
  isFetchingSearchIssuanceList:
    state.AddDataReducer.isFetchingSearchIssuanceList,
  warehouseList: state.AddDataReducer.warehouseList,
  isFetchingwareHouseList: state.AddDataReducer.isFetchingwareHouseList,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddIssuance);
