import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import {isNull } from "lodash";
import AddEvent from "./AddEvent";
import EventList from "./EventList"

const TotalEventData = (props) => {
    const permissions = JSON.parse(localStorage.getItem("permissions"));

    return (
        <>
            <Tabs defaultActiveKey={!isNull(permissions) && permissions.some(p => p['sub_id'] === 92) ?
                    "AddEvent" : !isNull(permissions) && permissions.some(p => p['sub_id'] === 93) ?
                        "EventList" :  ""} id="AddEvent" className="mb-3">
                <Tab eventKey="AddEvent" title="Add Event"
                disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 92) ? false : true}>  
                    <AddEvent />
                </Tab>
                <Tab eventKey="EventList" title="Event List"
               disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 93) ? false : true}> 
                    <EventList/>
                </Tab>
            </Tabs>
        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
    };
};
const mapStateToProps = (state, ownProps) => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TotalEventData);