import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Table,
  Container,
  Button,
  Card,
} from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty, get, find, isUndefined, filter } from "lodash";
import {
  getLTAccount,
  getEmployeeData,
  addLTAccount,
  getEmployeeDataWithStatus,
  getEmployeeDataById,
} from "./HRDetails.actions";
import Spinner from "react-bootstrap/Spinner";
import Select from "react-select";
import MaterialTable from "material-table";
import Swal from "sweetalert";
import { Redirect, Link } from "react-router-dom";
import { appendNonSignificantZeros } from "../../../../../global/globalFunctions";

const EmployeeEditId = (props) => {
  const [employeeId, setEmployeeId] = useState("");
  const [employeeName, setEmployeeName] = useState();
  const [employeeDataList, setEmployeeDataList] = React.useState("");

  const getEmployeeList =
    !isEmpty(props.employeeList) &&
    props.employeeList.map((x) => {
      let data = {
        value: x.employeeId,
        label: `${appendNonSignificantZeros(x.employeeId)}-${x.name}`,
      };
      return data;
    });
  const handleChangeEmployee = (selectedOption) => {
    setEmployeeId(selectedOption.value);
    setEmployeeName(selectedOption.label);
    props.getEmployeeDataById(selectedOption.value);
    console.log(selectedOption.value, selectedOption.label, "dddd");
  };
  useEffect(() => {
    //props.getLTAccount();
    props.getEmployeeData();
    //  props.getEmployeeDataWithStatus(1);
  }, []);

  return (
    <>
      {props.isFetchingEmployeeData ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Card style={{ padding: "3%" }}>
              <div style={{ padding: "1% 0" }}>
                <Row>
                  <Col lg="4" sm="12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Select Employee</Form.Label>
                      <Select
                        //value={name}
                        placeholder="Select Employee..."
                        onChange={handleChangeEmployee}
                        options={getEmployeeList}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>

              <Row>
                <Col lg="12" sm="12" className="text-center">
                  {employeeId === "" ? (
                    <Button
                      className="sendButtonVoucher"
                      disabled
                      style={{ backgroundColor: "black" }}
                      size="large"
                    >
                      Edit Employee
                    </Button>
                  ) : props.isFetchingEmployeeDataById ? (
                    <Button
                      className="sendButtonVoucher"
                      disabled
                      style={{ backgroundColor: "black" }}
                      size="large"
                    >
                      Fetching Employee Data
                    </Button>
                  ) : (
                    <Link
                      to={{
                        pathname: "/user/edit-employee",
                        state: props.employeeListById,
                      }}
                    >
                      {console.log(props.employeeListById, "empdatatest")}
                      <Button className="sendButtonVoucher">
                        Edit Employee
                      </Button>
                    </Link>
                  )}
                </Col>
              </Row>
            </Card>
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getEmployeeData: () => dispatch(getEmployeeData()),
    getEmployeeDataById: (id) => dispatch(getEmployeeDataById(id)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  ltAccount: state.HrReducer.ltAccount,
  isFetchingLTAccount: state.accountsLevelReducer.isFetchingLTAccount,
  employeeList: state.HrReducer.employeeList,
  isFetchingEmployeeData: state.HrReducer.isFetchingEmployeeData,
  level4List: state.accountsLevelReducer.level4List,
  isFetchinglevel4List: state.accountsLevelReducer.isFetchinglevel4List,
  employeeListById: state.HrReducer.employeeListById,
  isFetchingEmployeeDataById: state.HrReducer.isFetchingEmployeeDataById,
  activeEmployeeList: state.HrReducer.activeEmployeeList,
  isFetchingActiveEmployeeData: state.HrReducer.isFetchingActiveEmployeeData,
});

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeEditId);
