import React, { useEffect, useState } from "react";
import { Row, Col, Container, Button } from "react-bootstrap";
import { connect } from "react-redux";
import 'jspdf-autotable';

const AddDocuments = (props) => {

    async function downloadDoc1() {
    const existingPdfBytes = await fetch(`${""}/FL-110`,{
        headers: { 
         Authorization: 'BEARER '+window.localStorage.getItem("token") 
         },
      }).then((res) => res.arrayBuffer());
  
      var bytes = new Uint8Array(existingPdfBytes);
      saveByteArray("FL-110", bytes);
    }

    function saveByteArray(reportName, byte) {
        var blob = new Blob([byte], { type: "application/pdf" });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        var fileName = reportName;
        link.download = fileName;
        link.click();
      }
    useEffect(() => {
    }, []);
    return (
        <>
            <Container fluid>
                <div className="main">
                    <Row>
                        <Col lg = "6" md="6" sm="12">
                            <Button className="sendButton" onClick={() => downloadDoc1()}>Doc 1</Button>
                        </Col>
                        <Col lg = "6" md="6" sm="12">
                            <Button className="sendButton">Doc 2</Button>
                        </Col>
                    </Row>
                </div>

            </Container>
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {

    };
};
const mapStateToProps = (state, ownProps) => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddDocuments);