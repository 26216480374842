import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import {connect} from 'react-redux';
import {UserStyles} from './UserStyles';
import UserModal from './UserModal';
import moment from 'moment';
import {get} from 'lodash';
import {
  Badge,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Form,
  OverlayTrigger,
  Tooltip,
  Button,
} from "react-bootstrap";
import Spinner from 'react-bootstrap/Spinner';

const useStyles = makeStyles({
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});


function ViewUser(props) {
  const classes = useStyles();
  //const [expanded, setExpanded] = useState(false);
  const [open, setOpen] = React.useState(false);
  return (
    <div>
    {/* {props.isFetchingSelectedViewUser ? <Container fluid><div className="loader-div">
        <Button variant="info" disabled>
          <Spinner
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
            variant="info"
          />
        </Button>
      </div>
      </Container> : */}
    <Paper style={UserStyles.padding}>
      <h3 style={UserStyles.headingColor}>User Details</h3>
      {/* { open && <UserModal open = {open}/>} */}
      
    
        <Typography variant="h6" component="h2" style={UserStyles.headingColor} >
        First Name
        </Typography>
        <Typography className={classes.pos} color="textSecondary">
          {get(props.selectedViewData[0], 'first_name', '')}
        </Typography>
        <Typography variant="h6" component="h2" style={UserStyles.headingColor} >
        Last Name
        </Typography>
        <Typography className={classes.pos} color="textSecondary">
        {get(props.selectedViewData[0], 'last_name', '')}
        </Typography>
        <Typography variant="h6" component="h2" style={UserStyles.headingColor}>
         User Name
        </Typography>
        <Typography className={classes.pos} color="textSecondary">
        {get(props.selectedViewData[0], 'user_name', '')}
        </Typography>
        
        </Paper>
{}
</div>
)
}
const mapStateToProps =(state, ownProps, number)  =>({
  selectedViewData: state.usersReducer.selectedViewData,
  isFetchingSelectedViewUser: state.usersReducer.isFetchingSelectedViewUser
})

export default connect(mapStateToProps,null)(ViewUser);
