import React from "react";
import { Card, Tabs, Tab } from "react-bootstrap";
import { connect } from "react-redux";
import "../Vouchers/style.css";
import Level2 from "./Level2";
import Level3 from "./Level3";
import Level4 from "./Level4";
import Level5 from "./Level5";

const Levels = (props) => {
  return (
    <>
      <Card style={{ padding: "3%" }}>
        <Tabs
          defaultActiveKey="lvl2"
          id="uncontrolled-tab-example"
          className="mb-5"
          style={{ fontSize: "large" }}
        >
          <Tab eventKey="lvl2" title="Level 2">
            <Level2 />
          </Tab>
          <Tab eventKey="lvl3" title="Level 3">
            <Level3 />
          </Tab>
          <Tab eventKey="lvl4" title="Level 4">
            <Level4 />
          </Tab>
          <Tab eventKey="lvl5" title="Level 5">
            <Level5 />
          </Tab>
        </Tabs>
      </Card>
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
const mapStateToProps = (state, ownProps) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Levels);
