import React, { useEffect, useState } from "react";
import { Button, Container, Col, Row, Form } from "react-bootstrap";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import jsPDF from "jspdf";
import moment from "moment";
import { getProfitByDate } from "./MarqueeDetails.actions";
import logo from "../../../../../Images/logo.png";
import Swal from "sweetalert";
import { Link } from "react-router-dom";

const MonthlyProfit = (props) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  const handleVisible = () => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: "Added successfully...",
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = () => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: "Something went wrong...",
      icon: "error",
      button: "Ok",
    });
  };
  const exportPDF = () => {
    props.getProfitByDate({
      startedDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
    });
  };

  useEffect(() => {}, []);

  return (
    <>
      {props.isFetchingEmployeeData ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {props.isFetchingEmployeeData ? "Loading..." : "Loading..."}
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Row>
              <Col lg="4" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b> Start Date *</b>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    onChange={(e) => setStartDate(e.target.value)}
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col lg="4" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b> End Date *</b>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    onChange={(e) => setEndDate(e.target.value)}
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <div className="sendDiv" style={{ marginTop: "15px" }}>
              <Link to="/user/Profit-report-by-date">
              {endDate == "" || startDate == "" ? (
                <Button
                  className="sendButton"
                  disabled
                  style={{ backgroundColor: "black" }}
                  onClick={() => exportPDF()}
                >
                  Generate
                </Button>
              ) : (
                <Button className="sendButton" onClick={() => exportPDF()}>
                  Generate
                </Button>
              )}
              </Link>
            </div>
          
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    //     addExpense: (data, handleVisible, handleDangerVisible) => dispatch(addExpense(data, handleVisible, handleDangerVisible)),
    //     getExpense: (id) => dispatch(getExpense(id)),
    //     getMasterControl: (id) => dispatch(getMasterControl(id)),
    //     getBooking: () => dispatch(getBooking()),
    //    // UpdateHRExpense: (newData, oldData) => dispatch(UpdateHRExpense(newData, oldData)),
    //     deleteExpense: (menu_id) => dispatch(deleteExpense(menu_id)),
    //     SearchMarqueeExpenseReport:(id)=>dispatch(SearchMarqueeExpenseReport(id)),
    //     getBookingById:(id) => dispatch(getBookingById(id)),
    getProfitByDate: (data) => dispatch(getProfitByDate(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  // departmentList: state.MarqueeReducer.departmentList,
  // isFetchingExpenseData: state.MarqueeReducer.isFetchingExpenseData,
  // isAddingExpenseData: state.MarqueeReducer.isAddingExpenseData,
  // expenseList: state.MarqueeReducer.expenseList,
  // masterControlsList: state.MarqueeReducer.masterControlsList,
  // bookingList: state.MarqueeReducer.bookingList,
  isFetchingprofitByDate: state.MarqueeReducer.isFetchingprofitByDate,
  profitByDate: state.MarqueeReducer.profitByDate,
});

export default connect(mapStateToProps, mapDispatchToProps)(MonthlyProfit);
