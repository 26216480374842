const initialState = {

    isFetchingIssuance: false,
    issuanceByDate: [],
};


const DashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case "REQUEST_GET_ISSUANCE_DATE":
            return { ...state, isFetchingIssuance: true };
        case "SUCCESS_GET_ISSUANCE_DATE":
            return { ...state, isFetchingIssuance: false, issuanceByDate: action.payload };
        case "ERROR_GET_ISSUANCE_DATE":
            return { ...state, isFetchingIssuance: false };


        default:
            return state;

    }

};
export default DashboardReducer;
