import React, { useState } from 'react'
import { Container, Form, Col, Row, Button } from 'react-bootstrap'
import { addAttendanceFile } from './salary.actions'
import { connect } from "react-redux";
import Swal from 'sweetalert';
import moment from 'moment';
import { isEmpty } from "lodash";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';


const UploadAttendanceFile = (props) => {
  const [attendanceFile, setAttendanceFile] = useState("");
  const [startDate, setStartDate] = useState("");
  const [AlertVisible, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);




  const handleVisible = () => {
    setAlertVisible(true)
    setTimeout(() => {
      setAlertVisible(false)
    }, 9000);
    Swal({
      title: "Success",
      text: "Added successfully...",
      icon: "success",
      button: "Ok",
    });
  }

  const handleDangerVisible = () => {
    setDangerVisibleAlert(true)
    setTimeout(() => {
      setDangerVisibleAlert(false)
    }, 9000);
    Swal({
      title: "Error",
      text: "File Upload Failed...",
      icon: "error",
      button: "Ok",
    });
  }

  const handleGenerate = (a) => {
    console.log(attendanceFile, "bbbbbbb")
    console.log(a, "b1")
    props.addAttendanceFile(
      {
        attendance_file: attendanceFile,
        attendance_month_year: moment(startDate).format('YYYY-MM')
      },
      handleVisible, handleDangerVisible);
  };

  return (
    <>
      <Container fluid>
        <div className="mainHr">
          <Row>
            {// <Col lg="6" md="6" xs="12">

              //  <Form.Group controlId="garana" className="mb-3">
              //    <Form.Label>Upload Attendance File Here</Form.Label>
              // <Form.Control type="file" className="form-control"
              // onBlur={(e) => setAttendanceFile(e.target.files[0])}
              // onBlur={(e) => setUploadDocument(e.target.files[0])}
              // multiple
              ///>

              // <input className="form-control" type="file" onBlur={(e) => setAttendanceFile(e.target.files[0])} />
              // </Form.Group>
              //</div> </Col>
            }
            <Col lg="6" md="6" xs="12">
              <Form.Group className="mb-3" controlId="garana" >
                <Form.Label><b>Upload Attendance File Here</b></Form.Label>
                <input className="form-control" type="file" onBlur={(e) => setAttendanceFile(e.target.files[0])} />
              </Form.Group>
            </Col>
            <Col lg="6" md="6" xs="12">
              <Form.Group className="mb-3" controlId="garana" > {/*Month*/}
                <Form.Label><b>Select Month:</b></Form.Label>
                <br/>
                <LocalizationProvider dateAdapter={AdapterMoment}>

<DatePicker
  // disableFuture
  // label="Select Month"
  openTo="month"
  inputFormat="YYYY-MM"
  minDate={moment().subtract(2, 'months')}
  maxDate={moment().subtract(1, 'months').format('YYYY-MM-DD')}
  views={['year', 'month']}
  value={startDate}
  onChange={(newVal) => (console.dir(newVal),setStartDate(moment(newVal).format("YYYY-MM")))}
  renderInput={(params) => <TextField {...params} />}
/>
</LocalizationProvider>
                {/* <Form.Control type="date" id="startDate" min={moment().subtract(2, 'months').format('YYYY-MM')} max={moment().subtract(1, 'months').format('YYYY-MM-DD')} placeholder="Select Date...." onBlur={(e) => setStartDate(e.target.value)} /> */}
              </Form.Group>
            </Col>
            {/* <Col lg="4" md="4" xs="12">
              <Form.Group className="mb-3" controlId="garana" >
                <Form.Label><b>Upload Document:</b></Form.Label>
                <input className="form-control" type="file" name="UploadDocument" 
                onBlur={(e) => setAttendanceFile(e.target.files[0])}
                multiple
                 />
              </Form.Group>
            </Col> */}
          </Row>
          <div className="sendDiv">
            {startDate==""||attendanceFile==""?
            <Button className="sendButton" disabled style={{backgroundColor:"Black"}} onClick={() => { handleGenerate(attendanceFile); }}>Upload Attendance</Button>:
            <Button className="sendButton" onClick={() => { handleGenerate(attendanceFile); }}>Upload Attendance</Button>}
          </div>
        </div>
      </Container>
    </>
  )
}
const mapDispatchToProps = (dispatch) => {
  return {
    // getAttendance: (data) => dispatch(getAttendance(data)),
    addAttendanceFile: (data, handleVisible, handleDangerVisible) => dispatch(addAttendanceFile(data, handleVisible, handleDangerVisible)),
    // getEmployeeData: () => dispatch(getEmployeeData())
  };
};
const mapStateToProps = (state, ownProps) => ({
  // employeeList: state.HrReducer.employeeList,
  // attendanceData:state.SalaryReducer.attendanceData
  isAddingAttendanceFile: state.SalaryReducer.isAddingAttendanceFile,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadAttendanceFile);
