import API from "../../../../../global/api";

export const requestGetProcessCategory = () => {
    return {
        type: "REQUEST_GET_PROCESS_CATEGORY",
    };
};
export const successGetProcessCategory = (data) => {
    return {
        type: "SUCCESS_GET_PROCESS_CATEGORY",
        payload: data,
    };
};
export const errorGetProcessCategory = () => {
    return {
        type: "ERROR_GET_PROCESS_CATEGORY",
    };
};

export const getProcessCategoryData = () => {
    return (dispatch) => {
        dispatch(requestGetProcessCategory());
        API.get(`/categoryProcess/get_all_catProcess_data`).then((res) => {
            let getData = res.data;
            dispatch(successGetProcessCategory(getData));
        }).catch((error) => {
            dispatch(errorGetProcessCategory());
        })
    };
};


export const requestGetEmployee = () => {
    return {
        type: "REQUEST_GET_EMPLOYEE",
    };
};
export const successGetEmployee = (data) => {
    return {
        type: "SUCCESS_GET_EMPLOYEE",
        payload: data,
    };
};
export const errorGetEmployee = () => {
    return {
        type: "ERROR_GET_EMPLOYEE",
    };
};

export const getEmployeeData = () => {
    return (dispatch) => {
        dispatch(requestGetEmployee());
        API.get(`/employee/get_all_data`).then((res) => {
            let getData = res.data;
            dispatch(successGetEmployee(getData));
        }).catch((error) => {
            dispatch(errorGetEmployee());
        })
    };
};



export const requestGetOrderNo = () => {
    return {
        type: "REQUEST_GET_ORDER_NO",
    };
};
export const successGetOrderNo = (data) => {
    return {
        type: "SUCCESS_GET_ORDER_NO",
        payload: data,
    };
};
export const errorGetOrderNo = () => {
    return {
        type: "ERROR_GET_ORDER_NO",
    };
};

export const getOrderNo = () => {
    return (dispatch) => {
        dispatch(requestGetOrderNo());
        API.get(`/createOrder/get-order-data`).then((res) => {
            let getData = res.data;
            dispatch(successGetOrderNo(getData));
        }).catch((error) => {
            dispatch(errorGetOrderNo());
        })
    };
};




export const requestGetArticleByOrderId = () => {
    return {
        type: "REQUEST_GET_ARTICLE_ORDER_BY_ID",
    };
};
export const successGetArticleByOrderId = (data) => {
    return {
        type: "SUCCESS_GET_ARTICLE_ORDER_BY_ID",
        payload: data,
    };
};
export const errorGetArticleByOrderId = () => {
    return {
        type: "ERROR_GET_ARTICLE_ORDER_BY_ID",
    };
};

export const getArticleByOrderId = (data) => {
    return (dispatch) => {
        dispatch(requestGetArticleByOrderId());
        API.post(`/internal-job/order-data`, data).then((res) => {
            let getData = res.data;
            dispatch(successGetArticleByOrderId(getData));
        }).catch((error) => {
            dispatch(errorGetArticleByOrderId());
        })
    };
};


export const requestGetCategoryByArticleId = () => {
    return {
        type: "REQUEST_GET_CATEGORY_BY_ARTICLE_ID",
    };
};
export const successGetCategoryByArticleId = (data) => {
    return {
        type: "SUCCESS_GET_CATEGORY_BY_ARTICLE_ID",
        payload: data,
    };
};
export const errorGetCategoryByArticleId = () => {
    return {
        type: "ERROR_GET_CATEGORY_BY_ARTICLE_ID",
    };
};

export const getCategoryByArticleId = (data) => {
    return (dispatch) => {
        dispatch(requestGetCategoryByArticleId());
        API.post(`/internal-job/labCate-data`, data).then((res) => {
            let getData = res.data;
            dispatch(successGetCategoryByArticleId(getData));
        }).catch((error) => {
            dispatch(errorGetCategoryByArticleId());
        })
    };
};




export const requestGetProcessByCategoryId = () => {
    return {
        type: "REQUEST_GET_PROCESS_BY_CATEGORY_ID",
    };
};
export const successGetProcessByCategoryId = (data) => {
    return {
        type: "SUCCESS_GET_PROCESS_BY_CATEGORY_ID",
        payload: data,
    };
};
export const errorGetProcessByCategoryId = () => {
    return {
        type: "ERROR_GET_PROCESS_BY_CATEGORY_ID",
    };
};

export const getProcessByCategoryId = (data) => {
    return (dispatch) => {
        dispatch(requestGetProcessByCategoryId());
        API.post(`/internal-job/labProcess-data`, data).then((res) => {
            let getData = res.data;
            dispatch(successGetProcessByCategoryId(getData));
        }).catch((error) => {
            dispatch(errorGetProcessByCategoryId());
        })
    };
};




export const requestAddIssuance = () => {
    return {
        type: "REQUEST_ADD_ISSUANCE",
    };
};
export const successAddIssuance = (data) => {
    return {
        type: "SUCCESS_ADD_ISSUANCE",
        payload: data,
    };
};
export const errorAddIssuance = () => {
    return {
        type: "ERROR_ADD_ISSUANCE",
    };
};

export const addIssuanceData = (data) => {
    return (dispatch) => {
        dispatch(requestAddIssuance());
        API.post(`/internal-job/add-issuance-data`, data).then((res) => {
            let getData = res.data;
            dispatch(successGetProcessByCategoryId(getData));
        }).catch((error) => {
            dispatch(errorGetProcessByCategoryId());
        })
    };
};




export const requestGetVendors = () => {
    return {
        type: "REQUEST_GET_VENDORS",
    };
};
export const successGetVendors = (data) => {
    return {
        type: "SUCCESS_GET_VENDORS",
        payload: data,
    };
};
export const errorGetVendors = () => {
    return {
        type: "ERROR_GET_VENDORS",
    };
};

export const getVendorsData = () => {
    return (dispatch) => {
        dispatch(requestGetVendors());
        API.get(`/productionVendor/get_production_vendor`).then((res) => {
            let getData = res.data;
            dispatch(successGetVendors(getData));
        }).catch((error) => {
            dispatch(errorGetVendors());
        })
    };
};





export const requestAddIssuanceExternal = () => {
    return {
        type: "REQUEST_ADD_ISSUANCE_EXTERNAL",
    };
};
export const successAddIssuanceExternal = (data) => {
    return {
        type: "SUCCESS_ADD_ISSUANCE_EXTERNAL",
        payload: data,
    };
};
export const errorAddIssuanceExternal = () => {
    return {
        type: "ERROR_ADD_ISSUANCE_EXTERNAL",
    };
};

export const addIssuanceExternalData = (data) => {
    return (dispatch) => {
        dispatch(requestAddIssuanceExternal());
        API.post(`/external-job/add_external_job`, data).then((res) => {
            let getData = res.data;
            dispatch(successAddIssuanceExternal(getData));
        }).catch((error) => {
            dispatch(errorAddIssuanceExternal());
        })
    };
};