import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, Alert } from "react-bootstrap";
import "./add-levels.css";
import {
  getLevel1Data,
  getLevel2Data,
  getLevel3Data,
  uploadLevel4File,
  getLevelOneName,
  getLevelTwoName,
} from "./accountsLevel.action";
import { connect } from "react-redux";
import { isEmpty, find } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import Select from "react-select";
import Swal from "sweetalert";

const Level4Upload = (props) => {
  const [level1Id, setLevel1Id] = useState();
  const [level2Id, setLevel2Id] = useState();
  const [level3Id, setLevel3Id] = useState();
  const [level4File, setLevel4File] = useState();
  const [level1data, setLevel1data] = useState();
  const [level2Data, setLevel2data] = useState();
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  const handleVisible = () => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: "Added successfully...",
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = () => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: "Something went wrong...",
      icon: "error",
      button: "Ok",
    });
  };

  function uploadFileLevel4() {
    props.uploadLevel4File(
      {
        levelOneId: level1Id,
        levelTwoId: level2Id,
        levelThreeId: level3Id,
        file: level4File,
      },
      handleVisible,
      handleDangerVisible
    );
    setLevel1data("");
    setLevel2data("");
  }

  // add level
  const getLevel1List = props.level1List.map((x) => {
    let data = { value: x.levelOneId, label: x.name };
    return data;
  });
  const handleChangeLevel1 = (selectedOption) => {
    props.getLevelOneName(selectedOption.value);
    setLevel1Id(selectedOption.value);
    setLevel1data(selectedOption.label);
  };
  const getLevelTwoList =
    !isEmpty(props.levelTow) &&
    props.levelTow.map((x) => {
      let data = {
        value: x.levelTwoData.levelTwoId,
        label: x.levelTwoData.levelTwoName,
      };
      return data;
    });
  const handleChangeLevel2 = (selectedOption) => {
    setLevel2Id(selectedOption.value);
    setLevel2data(selectedOption.label);
    props.getLevelTwoName(selectedOption.value);
    // setLevel1Id(find(props.level2List, x => x.levelTwoData.levelTwoId == selectedOption.value).levelTwoData.levelOneId)
  };
  const getlevel3val =
    !isEmpty(props.levelThree) &&
    props.levelThree.map((x) => {
      let data = {
        value: x.levelTwoData.levelThreeId,
        label: x.levelTwoData.levelThreeName,
      };
      return data;
    });
  // end level

  const getLevel3List =
    !isEmpty(props.level3List) &&
    props.level3List.map((x) => {
      let data = {
        value: x.levelThreeData.levelThreeId,
        label: x.levelThreeData.levelThreeName,
      };
      return data;
    });
  const handleChangeLevel3 = (selectedOption) => {
    setLevel3Id(selectedOption.value);
    setLevel1Id(
      find(
        props.level3List,
        (x) => x.levelThreeData.levelThreeId == selectedOption.value
      ).levelThreeData.levelOneId
    );
    setLevel2Id(
      find(
        props.level3List,
        (x) => x.levelThreeData.levelThreeId == selectedOption.value
      ).levelThreeData.levelTwoId
    );
  };

  useEffect(() => {}, []);
  return (
    <>
      {props.isFetchinglevel1List ||
      props.isFetchingLevelTow ||
      props.isFetchingLevelThree ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>
                    <b> Select Level 1 </b>
                  </Form.Label>
                  <Select
                    value={{ label: level1data }}
                    placeholder="Select Level 1..."
                    onChange={handleChangeLevel1}
                    options={getLevel1List}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>
                    <b> Select Level 2 </b>
                  </Form.Label>
                  <Select
                    value={{ label: level2Data }}
                    isDisabled={isEmpty(getLevelTwoList) || isEmpty(level1data)}
                    placeholder="Select Level 2..."
                    onChange={handleChangeLevel2}
                    options={getLevelTwoList}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>
                    <b> Select Level 3 </b>
                  </Form.Label>
                  <Select
                    isDisabled={
                      isEmpty(getlevel3val) ||
                      isEmpty(level1data) ||
                      isEmpty(level2Data)
                    }
                    placeholder="Select Level 3..."
                    onChange={handleChangeLevel3}
                    options={getlevel3val}
                  />
                </Form.Group>
              </Col>
            </Row>

            <div style={{ paddingTop: "2%" }}>
              <Row>
                <Col xl="4" lg="4" md="2" sm="2" xs="2"></Col>
                <Col xl="4" lg="4" md="4" sm="4" xs="4">
                  <Form.Group>
                    <Form.Label>
                      <b style={{ fontSize: "x-large" }}>Upload Level4 File</b>
                    </Form.Label>
                    <input
                      className="form-control"
                      type="file"
                      name="file"
                      class="Upload File"
                      id="upload file"
                      onChange={(e) => setLevel4File(e.target.files[0])}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </div>
            <div className="sendDiv" style={{marginTop:'15px'}}>
              {!level3Id ? (
                <Button
                  disabled
                  style={{
                    marginLeft: "2%",
                    color: "white",
                    width: "20%",
                    backgroundColor: "black",
                    border: "1px solid black",
                  }}
                >
                  Save
                </Button>
              ) : (
                <Button className="sendButton" onClick={uploadFileLevel4}>
                  Save
                </Button>
              )}
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLevel1Data: () => dispatch(getLevel1Data()),
    getLevel2Data: () => dispatch(getLevel2Data()),
    getLevel3Data: () => dispatch(getLevel3Data()),
    uploadLevel4File: (data, handleVisible, handleDangerVisible) =>
      dispatch(uploadLevel4File(data, handleVisible, handleDangerVisible)),
    getLevelOneName: (data) => dispatch(getLevelOneName(data)),
    getLevelTwoName: (data) => dispatch(getLevelTwoName(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  level1List: state.accountsLevelReducer.level1List,
  level2List: state.accountsLevelReducer.level2List,
  level3List: state.accountsLevelReducer.level3List,
  isAddingLevel4File: state.accountsLevelReducer.isAddingLevel4File,
  isnotAddingLevel4File: state.accountsLevelReducer.isnotAddingLevel4File,
  isFetchinglevel1List: state.accountsLevelReducer.isFetchinglevel1List,
  isFetchingLevelTow: state.accountsLevelReducer.isFetchingLevelTow,
  isFetchingLevelThree: state.accountsLevelReducer.isFetchingLevelThree,
  levelTow: state.accountsLevelReducer.levelTow,
  levelThree: state.accountsLevelReducer.levelThree,
});

export default connect(mapStateToProps, mapDispatchToProps)(Level4Upload);
