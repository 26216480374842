import React from "react";
import { Card, Tabs, Tab } from "react-bootstrap";
import { connect } from "react-redux";
import Level3 from "./Level3";
import Level3Tap from "./Level3Tap";
import Level3Upload from "./Level3Upload";
import Level3Report from "./Level3Report";
import Level3RenderingPage from "./Level3RenderingPage";
import { isNull } from "lodash";

const Level3Tabs = (props) => {
  const permissions = JSON.parse(localStorage.getItem("permissions"));

  return (
    <>
      <Tabs
        defaultActiveKey={
          !isNull(permissions) && permissions.some((p) => p["sub_id"] === 21)
            ? "Level 3 Data"
            : !isNull(permissions) &&
              permissions.some((p) => p["sub_id"] === 22)
            ? "Level 3 Details"
            : !isNull(permissions) &&
              permissions.some((p) => p["sub_id"] === 23)
            ? "Level 3 Upload"
            : !isNull(permissions) &&
              permissions.some((p) => p["sub_id"] === 24)
            ? "Level 3 Report"
            : ""
        }
        id="level3Data"
        className="mb-3"
        unmountOnExit={true}
      >
        <Tab
          eventKey="Level 3 Data"
          title="Add Level 3"
          unmountOnExit={true}
          disabled={
            !isNull(permissions) && permissions.some((p) => p["sub_id"] === 21)
              ? false
              : true
          }
        >
          <Level3 />
        </Tab>

        <Tab
          eventKey="Level 3 Details"
          title="Level 3 Details"
          unmountOnExit={true}
          disabled={
            !isNull(permissions) && permissions.some((p) => p["sub_id"] === 22)
              ? false
              : true
          }
        >
          <Level3RenderingPage />
        </Tab>

        <Tab
          eventKey="Level 3 Upload"
          title="Level 3 Upload"
          unmountOnExit={true}
          disabled={
            !isNull(permissions) && permissions.some((p) => p["sub_id"] === 23)
              ? false
              : true
          }
        >
          <Level3Upload />
        </Tab>

        <Tab
          eventKey="Level 3 Report"
          title="Level 3 Report"
          unmountOnExit={true}
          disabled={
            !isNull(permissions) && permissions.some((p) => p["sub_id"] === 24)
              ? false
              : true
          }
        >
          <Level3Report />
        </Tab>
      </Tabs>
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
const mapStateToProps = (state, ownProps) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Level3Tabs);
