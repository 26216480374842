

const initialState = {
    categoryProcessList: [],
    isFetchingCategoryProcessData: false,
    employeeList: [],
    isFetchingEmployeeData: false,
    isFetchingGetOrderNo: false,
    orderNoList: [],
    isFetchingGetArticleByOrder: false,
    articleListByOrder: [],
    isFetchingGetCategoryByArticleId: false,
    categoryListByArticleId: [],
    isFetchingGetProcessByCategoryId: false,
    processListByCategoryId: [],
    isFetchingGetVendors: false,
    vendorList: []


}

const AddIssuanceReducer = (state = initialState, action) => {
    switch (action.type) {

        case "REQUEST_GET_PROCESS_CATEGORY":
            return { ...state, isFetchingCategoryProcessData: true };
        case "SUCCESS_GET_PROCESS_CATEGORY":
            return {
                ...state,
                categoryProcessList: action.payload.data,
                isFetchingCategoryProcessData: false,
            };
        case "ERROR_GET_PROCESS_CATEGORY":
            return { ...state, isFetchingCategoryProcessData: false };


            case "REQUEST_GET_EMPLOYEE":
                return { ...state, isFetchingEmployeeData: true };
            case "SUCCESS_GET_EMPLOYEE":
                return {
                    ...state,
                    employeeList: action.payload.data,
                    isFetchingEmployeeData: false,
                };
            case "ERROR_GET_EMPLOYEE":
                return { ...state, isFetchingEmployeeData: false };



                case "REQUEST_GET_ORDER_NO":
                return { ...state, isFetchingGetOrderNo: true };
            case "SUCCESS_GET_ORDER_NO":
                return {
                    ...state,
                    orderNoList: action.payload.data,
                    isFetchingGetOrderNo: false,
                };
            case "ERROR_GET_ORDER_NO":
                return { ...state, isFetchingGetOrderNo: false };



                case "REQUEST_GET_ARTICLE_ORDER_BY_ID":
                    return { ...state, isFetchingGetArticleByOrder: true };
                case "SUCCESS_GET_ARTICLE_ORDER_BY_ID":
                    return {
                        ...state,
                        articleListByOrder: action.payload.data,
                        isFetchingGetArticleByOrder: false,
                    };
                case "ERROR_GET_ARTICLE_ORDER_BY_ID":
                    return { ...state, isFetchingGetArticleByOrder: false };


                    case "REQUEST_GET_CATEGORY_BY_ARTICLE_ID":
                        return { ...state, isFetchingGetCategoryByArticleId: true };
                    case "SUCCESS_GET_CATEGORY_BY_ARTICLE_ID":
                        return {
                            ...state,
                            categoryListByArticleId: action.payload.data,
                            isFetchingGetCategoryByArticleId: false,
                        };
                    case "ERROR_GET_CATEGORY_BY_ARTICLE_ID":
                        return { ...state, isFetchingGetCategoryByArticleId: false };



                        case "REQUEST_GET_PROCESS_BY_CATEGORY_ID":
                            return { ...state, isFetchingGetProcessByCategoryId: true };
                        case "SUCCESS_GET_PROCESS_BY_CATEGORY_ID":
                            return {
                                ...state,
                                processListByCategoryId: action.payload.data,
                                isFetchingGetProcessByCategoryId: false,
                            };
                        case "ERROR_GET_PROCESS_BY_CATEGORY_ID":
                            return { ...state, isFetchingGetProcessByCategoryId: false };



                            case "REQUEST_GET_VENDORS":
                            return { ...state, isFetchingGetVendors: true };
                        case "SUCCESS_GET_VENDORS":
                            return {
                                ...state,
                                vendorList: action.payload.data,
                                isFetchingGetVendors: false,
                            };
                        case "ERROR_GET_VENDORS":
                            return { ...state, isFetchingGetVendors: false };


        default:
            return state;
    }
}

export default AddIssuanceReducer;
