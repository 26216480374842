import React from "react";
import { Card, Tabs, Tab, Button } from "react-bootstrap";
import { connect } from "react-redux";
import Level2 from "./Level2";
import Level2Report from "./Level2Report";
import Level2Upload from "./Level2Upload";
import { Link } from "react-router-dom";
import { isNull } from "lodash";

const Level2Tabs = (props) => {
  const permissions = JSON.parse(localStorage.getItem("permissions"));

  return (
    <>
      <Tabs
        defaultActiveKey={
          !isNull(permissions) && permissions.some((p) => p["sub_id"] === 17)
            ? "Level 2 Data"
            : !isNull(permissions) &&
              permissions.some((p) => p["sub_id"] === 18)
            ? "Level 2 Details"
            : !isNull(permissions) &&
              permissions.some((p) => p["sub_id"] === 19)
            ? "Level 2 Upload"
            : !isNull(permissions) &&
              permissions.some((p) => p["sub_id"] === 20)
            ? "Level 2 Report"
            : ""
        }
        id="level2Data"
        className="mb-3"
        unmountOnExit={true}
      >
        <Tab
          eventKey="Level 2 Data"
          title="Add Level 2"
          unmountOnExit={true}
          disabled={
            !isNull(permissions) && permissions.some((p) => p["sub_id"] === 17)
              ? false
              : true
          }
        >
          <Level2 />
        </Tab>
        <Tab
          eventKey="Level 2 Details"
          title="Level 2 Details"
          disabled={
            !isNull(permissions) && permissions.some((p) => p["sub_id"] === 18)
              ? false
              : true
          }
        >
          <div className="sendDiv">
            <Link to="/user/level2-details">
              {" "}
              <Button className="sendButton">View Details</Button>
            </Link>
          </div>
        </Tab>
        <Tab
          eventKey="Level 2 Upload"
          title="Level 2 Upload"
          unmountOnExit={true}
          disabled={
            !isNull(permissions) && permissions.some((p) => p["sub_id"] === 19)
              ? false
              : true
          }
        >
          <Level2Upload />
        </Tab>
        <Tab
          eventKey="Level 2 Report"
          title="Level 2 Report"
          unmountOnExit={true}
          disabled={
            !isNull(permissions) && permissions.some((p) => p["sub_id"] === 20)
              ? false
              : true
          }
        >
          <Level2Report />
        </Tab>
      </Tabs>
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
const mapStateToProps = (state, ownProps) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Level2Tabs);
