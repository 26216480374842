import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Container,
  Button,
  FormGroup,
  Alert,
  Image,
} from "react-bootstrap";
import { connect } from "react-redux";

import Spinner from "react-bootstrap/Spinner";
import "../AddDetails/hr-styles.css";
import Select from "react-select";
import ImageUploader from "react-images-upload";
import {
  getEmployeeData,
  getEmployeeDataWithStatus,
} from "../AddDetails/HRDetails.actions";
import {
  getAttendance,
  deleteAttendance,
  UpdateAttendance,
} from "./salary.actions";
import MaterialTable from "material-table";
import { isEmpty, get, find, chain, uniqBy, isNull } from "lodash";
import EditIcon from "@mui/icons-material/Edit";
import { appendNonSignificantZeros } from "../../../../../global/globalFunctions";
import { baseURL, headers } from "../../../../../global/api";
import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const ViewAttendance = (props) => {
  const [startDate, setStartDate] = useState();
  const [show, setShow] = useState(false);
  const employeeData = props.employeeList;
  // console.log('employeeData',employeeData)
  const attendanceData = props.attendanceData;
  console.log("attendanceData", props.attendanceData);

  /* const [state, setState] = React.useState({
        columns: [
            { title: 'Employee Id', field: 'employee_id', render: rowData => appendNonSignificantZeros(rowData.employee_id)   },
            { title: 'Attendance Date', field: 'attendance_date' },
            { title: 'Attendance in Time', field: 'attendance_in_time' },
            { title: 'Attendance out Time', field: 'attendance_out_time' },
            { title: 'Break in Time', field: 'break_in_time' },
            { title: 'Break out Time', field: 'break_out_time' },
            // { title: 'Religion', field: 'religion' },
            // { title: 'Phone #', field: 'phone' },
            // { title: 'Landline', field: 'land_line' },
            // { title: 'Emergency Contact', field: 'emergency_contact' },
            // { title: 'Date of birth', field: 'dob' },
            // // { title: 'Address', field: 'Address' },
            // // { title: 'Salary', field: 'Salary' },

            // { title: 'Active Status', field: 'active_status' },
            // { title: 'Appointment Type', field: 'appointment_type' },
            // // { title: 'createdAt', field: 'createdAt' },
            // { title: 'Department', field: 'department.departmentName' },
            // { title: 'Department ID', field: 'department_id' },
            // { title: 'Employee ID', field: 'employeeId' },
            // { title: 'Joining Date', field: 'joining_date' },
            // { title: 'Machine ID', field: 'machine_id' },
            // { title: 'Marital Status', field: 'marital_status' },
            // { title: 'Report To', field: 'report_to' },
            // // { title: 'updatedAt', field: 'updatedAt' },

        ],
    });*/

  // const handleAllEmployee = (id) => {
  //     var All = (!isEmpty(props.employeeList)) && props.employeeList.map((x) => { All.push({ id: x.employeeId }) });
  //     console.log("(!isEmpty(props.employeeList)) && props.employeeList.map((x) => { All.push({ id: x.employeeId }) })", (!isEmpty(props.employeeList)) && props.employeeList.map((x) => { All.push({ id: x.employeeId }) }))
  //     console.log("All", All)
  //     setAllEmployee(All);
  //     setEmployeeId(id);
  // };

  // const handleChangeEmployee = (selectedOption) => {
  //     setEmployeeId(selectedOption.value);
  // };

  // const getEmployeeList = !isEmpty(props.employeeList) ? props.employeeList.map((x) => { let data = { value: x.employeeId, label: `${appendNonSignificantZeros(x.employeeId)}-${x.name}`  }; return data }) : [];

  const getEmployeeList =
    !isEmpty(props.activeEmployeeList) &&
    props.activeEmployeeList.map((x) => {
      let data = {
        value: x.employeeId,
        label: `${appendNonSignificantZeros(x.employeeId)}-${x.name}`,
      };
      return data;
    });

  var getEmployeeUpdatedList = getEmployeeList;
  !isEmpty(getEmployeeList) &&
    getEmployeeUpdatedList.push({ value: "All", label: "All" });

  const handleGenerate = () => {
    props.getAttendance({
      month: startDate,
    });
    setShow(true);
  };

  useEffect(() => {
    setShow(false);
  }, []);
  return (
    <Container fluid>
      <div className="mainHr">
        <Row>
          <Col lg="6" md="6" xs="12">
            <Form.Group className="mb-3" controlId="garana">
              {" "}
              {/*Month*/}
              <Form.Label>
                <b>Select Month:</b>
              </Form.Label>
              <br />
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  // disableFuture
                  // label="Select Month"
                  openTo="month"
                  inputFormat="YYYY-MM"
                  //   minDate={moment().subtract(2, 'months')}
                  //   maxDate={moment().subtract(1, 'months').format('YYYY-MM-DD')}
                  views={["year", "month"]}
                  value={startDate}
                  onChange={(newVal) => (
                    setShow(false),
                    setStartDate(moment(newVal).format("YYYY-MM"))
                  )}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              {/* <Form.Control type="date" id="startDate" min={moment().subtract(2, 'months').format('YYYY-MM')} max={moment().subtract(1, 'months').format('YYYY-MM-DD')} placeholder="Select Date...." onBlur={(e) => setStartDate(e.target.value)} /> */}
            </Form.Group>
          </Col>
        </Row>

        <div className="sendDiv">
          <Button
            className="sendButton"
            onClick={() => {
              handleGenerate();
            }}
          >
            View Attendance
          </Button>
        </div>
      </div>
      {props.isFetchingAttendanceData ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {props.isFetchingAttendanceData ? "Loading..." : "Loading..."}
          </Button>
        </div>
      ) : (
        show && (
          <div className="main">
            <h5></h5>
            <MaterialTable
              title={`Attendance For ${moment(startDate).format("LL")}`}
              columns={uniqBy(
                [
                  { title: "Employee Id", field: "employeeId" },
                  { title: "Machine Id", field: "employee_code" },
                  { title: "Employee Name", field: "employee.name" },

                  { title: "Present Days", field: "present_days" },
                  { title: "Absent Days", field: "absent_days" },
                  { title: "Total Work Hours", field: "total_work_hours" },
                  { title: "Over Time Hours", field: "over_time_hours" },
                  { title: "Late Coming Hours", field: "late_coming_hours" },
                  { title: "Early Going Hours", field: "earlier_going_hours" },
                  { title: "Casual Leaves", field: "casual_leave" },
                  { title: "Sick Leaves", field: "sick_leave" },
                  // { title: 'Attendance Date', field: 'attendance_date' },
                  // { title: 'Attendance in Time', field: 'attendance_in_time' },
                  // { title: 'Attendance out Time', field: 'attendance_out_time' },
                  // { title: 'Break in Time', field: 'break_in_time' },
                  // { title: 'Break out Time', field: 'break_out_time' },
                ],
                "title"
              )}
              data={props.attendanceData}
              // data={(query) =>

              //     // new Promise((resolve, reject) => {
              //     //     console.log(query,"qqqqqqqqqqqq")
              //     //     let url = `${baseURL}attendance/get_attendance?`;
              //     //     url += "limit=" + query.pageSize;
              //     //     url += "&page=" + (query.page==0?1:query.page);
              //     //      url += "&search=" + query.search;
              //     new Promise((resolve, reject) => {
              //         let url = `${baseURL}attendance/get_attendance?`;
              //         url += "size=" + query.pageSize;
              //         url += "&page=" + (query.page + 1);
              //         url += "&search=" + query.search;
              //         fetch(url, {
              //             method: 'GET',
              //             headers: headers,
              //         })
              //             .then((response) => response.json())
              //             .then((result) => {
              //                 resolve({

              //                     data: result.data,
              //                     page: result.page,
              //                     totalCount: result.total_results,
              //                 });
              //             });

              //     })
              // }
              options={{
                actionsColumnIndex: -1,
                filtering: true,
                exportButton: true,
                exportAllData: true,
                paging: true,
                // page:1,
                pageSize: 50, // make initial page size
                emptyRowsWhenPaging: false, // To avoid of having empty rows
                pageSizeOptions: [20, 50, 100, 150, 200], // rows selection options
                maxBodyHeight: "600px",
                headerStyle: {
                  position: "sticky",
                  top: 0,
                  color: "#00BBBB",
                  fontWeight: "550",
                  onRowAdd: "none",
                  zIndex: 0,
                },
              }}

              // editable={{
              //     isEditable : rowData => false,
              //     onRowUpdate: (newData, oldData) =>
              //         new Promise((resolve) => {
              //             setTimeout(() => {
              //                 resolve();
              //                 {
              //                     { console.log('newData', newData) };

              //                     { console.log('oldData', oldData) };
              //                     props.UpdateAttendance(newData, oldData, { startedDate: startDate, endDate: endDate, employee_id: employeeId });

              //                 }
              //             }, 600);
              //         }),
              //         isDeletable: rowData => false,
              //     onRowDelete: (oldData) =>
              //         new Promise((resolve) => {
              //             setTimeout(() => {
              //                 resolve();
              //                 props.deleteAttendance(oldData.id, { startedDate: startDate, endDate: endDate, employee_id: employeeId });
              //                 console.log(oldData, 'old')
              //             }, 600);
              //         }),
              // }}
            />
          </div>
        )
      )}
    </Container>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAttendance: (data) => dispatch(getAttendance(data)),
    getEmployeeData: () => dispatch(getEmployeeData()),
    UpdateAttendance: (newData, oldData, employeeData) =>
      dispatch(UpdateAttendance(newData, oldData, employeeData)),
    deleteAttendance: (id, employeeData) =>
      dispatch(deleteAttendance(id, employeeData)),
    getEmployeeDataWithStatus: (status) =>
      dispatch(getEmployeeDataWithStatus(status)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  // employeeList: state.HrReducer.employeeList,
  attendanceData: state.SalaryReducer.attendanceData,
  isFetchingAttendanceData: state.SalaryReducer.isFetchingAttendanceData,
  activeEmployeeList: state.HrReducer.activeEmployeeList,
  isFetchingActiveEmployeeData: state.HrReducer.isFetchingActiveEmployeeData,
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewAttendance);
