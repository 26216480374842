import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, Alert } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import "jspdf-autotable";
import Select from "react-select";
import {
  addSubProductCall,
  getProductCall,
  getSubProductCall,
} from "./production.actions";

const SubProduct = (props) => {
  const getProduct = props.productInfo.map((x) => {
    let data = { label: x.name, value: x.mainProId };
    return data;
  });

  const [product, setProduct] = useState("");
  const [subProduct, setSubProduct] = useState("");

  const setProductInfo = (selectedOption) => {
    setProduct(selectedOption.value);
  };

  /*const [state, setState] = useState({
        columns: [
            { title: 'Product', field: 'mainProId', render: rowData => rowData.main_product.name },
            { title: 'Sub Product', field: 'name' },
        ],
    });*/

  function sendData() {
    props.addSubProductCall({
      mainProId: product,
      name: subProduct,
    });
  }

  useEffect(() => {
    props.getProductCall();
    props.getSubProductCall();
  }, []);

  return (
    <Container fluid>
      <div className="main">
        <div>
          <Form>
            <Row>
              <Col lg="12" md="12" xs="12" styles={{ zIndex: -1 }}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Select Product:</b>
                  </Form.Label>
                  <Select
                    placeholder="Select Product..."
                    onChange={setProductInfo}
                    options={getProduct}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col lg="12" md="12" xs="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Enter Sub Product:</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="subProductDetail"
                    placeholder="Sub Product...."
                    onBlur={(e) => setSubProduct(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>

            <div className="sendDiv">
              <Button className="sendButton" onClick={() => sendData()}>
                Save
              </Button>
            </div>

            <MaterialTable
              title="Sub Product Details"
              columns={[
                {
                  title: "Product",
                  field: "mainProId",
                  render: (rowData) => rowData.main_product.name,
                },
                { title: "Sub Product", field: "name" },
              ]}
              data={props.subProductInfo}
              options={{
                actionsColumnIndex: -1,
                filtering: true,
                exportButton: true,
                exportAllData: true,
                maxBodyHeight: "600px",
                headerStyle: {
                  position: "sticky",
                  top: 0,
                  color: "#00BBBB",
                  fontWeight: "550",
                  onRowAdd: "none",
                },
              }}
              editable={{
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      // props.updateWareHouse(newData, oldData)
                    }, 600);
                  }),

                onRowDelete: (oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      //   props.deleteWareHouse(oldData.wareHouseId);
                    }, 600);
                  }),
              }}
            />
          </Form>
        </div>
      </div>
    </Container>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addSubProductCall: (data) => dispatch(addSubProductCall(data)),
    getProductCall: () => dispatch(getProductCall()),
    getSubProductCall: () => dispatch(getSubProductCall()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  productInfo: state.productionReducer.productInfo,
  subProductInfo: state.productionReducer.subProductInfo,
});

export default connect(mapStateToProps, mapDispatchToProps)(SubProduct);
