import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, FormGroup, Alert } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty, find, isUndefined, filter } from "lodash";
import Spinner from 'react-bootstrap/Spinner';
import MaterialTable from 'material-table';
import 'jspdf-autotable';
import { getMasterAccounts, addAccounts, resetMasterData, getSelectedMasterAccounts } from "./HRDetails.actions";
import Select from 'react-select';
import Swal from 'sweetalert';



const MasterControl = (props) => {
    // var _ = require('lodash');
    const [LTAccountId, setLTAccountId] = useState('');
    const [LTAccountName, setLTAccountName] = useState('');
    const [STAccountId, setSTAccountId] = useState('');
    const [STAccountName, setSTAccountName] = useState('');
    const [STAccounts, setsTAccounts] = useState(
        {
            bankId: '',
            bankName: '',
            cashId: '',
            cashName: '',
            adjustId: '',
            adjustName: '',
        }
    );
    const [LTAccounts, setlTAccounts] = useState(
        {
            bankId: '',
            bankName: '',
            cashId: '',
            cashName: '',
            adjustId: '',
            adjustName: '',
        }
    );
    const [miscAccounts, setMiscAccounts] = useState(
        {

            sExpenseId: '',
            sExpenseName: '',
            ovetimeId: '',
            ovetimeName: '',
            allowancesId: '',
            allowancesName: '',
            deductionsId: '',
            deductionsName: '',
            salaryId: '',
            salaryName: '',

        }
    );


    const [visibleAlert, setAlertVisible] = useState(false);
    const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);



    useEffect(() => {
        props.getMasterAccounts();
        // props.getEmployeeData();
        props.getSelectedMasterAccounts();
    }, []);
    // const getMasterAccounts = props.
    function saveAccounts() {
        props.addAccounts(
            {
                data: [
                    {
                        name: "long_term_adv",
                        levelFiveId: LTAccountId
                    },
                    {
                        name: "long_term_bank",
                        levelFiveId: LTAccounts.bankId
                    }, {
                        name: "long_term_cash",
                        levelFiveId: LTAccounts.cashId
                    }, {
                        name: "long_term_adjustment",
                        levelFiveId: LTAccounts.adjustId
                    },
                    {
                        name: "short_term_adv",
                        levelFiveId: STAccountId
                    },
                    {
                        name: "short_term_bank",
                        levelFiveId: STAccounts.bankId
                    }, {
                        name: "short_term_cash",
                        levelFiveId: STAccounts.cashId
                    }, {
                        name: "short_term_adjustment",
                        levelFiveId: STAccounts.adjustId
                    }, {
                        name: "salary_expense",
                        levelFiveId: miscAccounts.sExpenseId
                    }, {
                        name: "overtime_expense",
                        levelFiveId: miscAccounts.ovetimeId
                    }, {
                        name: "allowances",
                        levelFiveId: miscAccounts.allowancesId
                    }, {
                        name: "deductions",
                        levelFiveId: miscAccounts.deductionsId
                    }, {
                        name: "salary_payable",
                        levelFiveId: miscAccounts.salaryId
                    },
                ]
            }, handleVisible, handleDangerVisible,);
        // !isEmpty(props.selectedMasterData) && setLTAccountId(props.selectedMasterData[0].levelFiveId);
        // !isEmpty(props.selectedMasterData) && setLTAccountName(props.selectedMasterData[0].levelFiveName);
        // !isEmpty(props.selectedMasterData) && setSTAccountId(props.selectedMasterData[1].levelFiveId);
        // !isEmpty(props.selectedMasterData) && setSTAccountName(props.selectedMasterData[1].levelFiveName);

        props.resetMasterData();
    }
    // console.log(props.selectedMasterData.filter((acc) => { return acc.name==="long_term_adjustment"}), "Master Adj");

    // specialLeaves [casualLeave, setCasualLeaves] = useState(isUndefined(props.casualLeaves) ? 0 :props.leaveList[0].casualLeaves );
    //const [casualMaternityLeave, setCasualMaternityLeave] = useState(isUndefined(props.leaveList[0].maternityLeaves) ? 0 :props.leaveList[0].maternityLeaves);

    // const getEmployeeList = (!isEmpty(props.employeeList) && (props.employeeList.map((x) => { let data = { value: x.employeeId, label: x.name }; return data })));
    const getAccountsList = !isEmpty(props.masterData) && props.masterData.map((x) => { let data = { value: x.levelFiveId, label: x.levelFiveName }; return data })
    const handleVisible = () => {
        setAlertVisible(true)
        setTimeout(() => {
            setAlertVisible(false)
        }, 9000);
        Swal({
            title: "Success",
            text: "Saved successfully...",
            icon: "success",
            button: "Ok",
        });
    }

    const handleDangerVisible = () => {
        setDangerVisibleAlert(true)
        setTimeout(() => {
            setDangerVisibleAlert(false)
        }, 9000);
        Swal({
            title: "Error",
            text: "Something went wrong...",
            icon: "error",
            button: "Ok",
        });
    }
    const handleChangeLTAccount = (selectedOption) => {
        setLTAccountId(selectedOption.value);
        setLTAccountName(selectedOption.label);
    };
    const handleChangeSTAccount = (selectedOption) => {
        setSTAccountId(selectedOption.value);
        setSTAccountName(selectedOption.label);
    };
    // const oldAccounts = () => {
    //     if (!isEmpty(props.selectedMasterData)) {
    //         console.log(props.selectedMasterData, "props.selectedMasterData");
    //         setLTAccountId(props.selectedMasterData[0].levelFiveId);
    //         setLTAccountName(props.selectedMasterData[0].levelFiveName);
    //         setSTAccountId(props.selectedMasterData[1].levelFiveId);
    //         setSTAccountName(props.selectedMasterData[1].levelFiveName);

    //     }
    // }
    return (
        <>
            {(props.isFetchingMasterAccounts) ? (
                <div className="loader-div">
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />
                    </Button>
                </div>
            ) : (
                <div>
                    <Form>
                        <Row>
                            <Col lg="6" md="6" xs="6">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Select LT Account:</b></Form.Label>
                                    <Select
                                        // isDisabled={isEmpty(getDepartmentNameList)}
                                        placeholder="Select LT Account..."
                                        onChange={handleChangeLTAccount}
                                        options={getAccountsList}
                                        value={{
                                            value: (LTAccountId === "" && !isEmpty(props.selectedMasterData)) ? filter(props.selectedMasterData, { name: 'long_term_adv' })[0].levelFiveId : LTAccountId, /*Sometimes the indexing is opposite, then both values get swapped*/
                                            label: (LTAccountId === "" && !isEmpty(props.selectedMasterData)) ? filter(props.selectedMasterData, { name: 'long_term_adv' })[0].level_five.levelFiveName : LTAccountName
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg="6" md="6" xs="6">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Select ST Account:</b></Form.Label>
                                    <Select
                                        // isDisabled={isEmpty(getDepartmentNameList)}
                                        placeholder="Select ST Account..."
                                        onChange={handleChangeSTAccount}
                                        options={getAccountsList}
                                        value={{
                                            value: (STAccountId === "" && !isEmpty(props.selectedMasterData)) ? filter(props.selectedMasterData, { name: 'short_term_adv' })[0].levelFiveId : STAccountId, /*Sometimes the indexing is opposite, then both values get swapped*/
                                            label: (STAccountId === "" && !isEmpty(props.selectedMasterData)) ? filter(props.selectedMasterData, { name: 'short_term_adv' })[0].level_five.levelFiveName : STAccountName
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="6" md="6" xs="6">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Select LT Bank Account:</b></Form.Label>
                                    <Select
                                        // isDisabled={isEmpty(getDepartmentNameList)}
                                        placeholder="Select LT Bank Account..."
                                        onChange={(selectedOption) => { setlTAccounts((prev) => ({ ...prev, bankId: selectedOption.value, bankName: selectedOption.label })); }}
                                        options={getAccountsList}
                                        value={{
                                            value: (LTAccounts.bankId === "" && !isEmpty(props.selectedMasterData)) ? filter(props.selectedMasterData, { name: 'long_term_bank' })[0].levelFiveId : LTAccounts.bankId, /*Sometimes the indexing is opposite, then both values get swapped*/
                                            label: (LTAccounts.bankId === "" && !isEmpty(props.selectedMasterData)) ? filter(props.selectedMasterData, { name: 'long_term_bank' })[0].level_five.levelFiveName : LTAccounts.bankName
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg="6" md="6" xs="6">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Select ST Bank Account:</b></Form.Label>
                                    <Select
                                        // isDisabled={isEmpty(getDepartmentNameList)}
                                        placeholder="Select ST Bank Account..."
                                        onChange={(selectedOption) => { setsTAccounts((prev) => ({ ...prev, bankId: selectedOption.value, bankName: selectedOption.label })); }}
                                        options={getAccountsList}
                                        value={{
                                            value: (STAccounts.bankId === "" && !isEmpty(props.selectedMasterData)) ? filter(props.selectedMasterData, { name: 'short_term_bank' })[0].levelFiveId : STAccounts.bankId, /*Sometimes the indexing is opposite, then both values get swapped*/
                                            label: (STAccounts.bankId === "" && !isEmpty(props.selectedMasterData)) ? filter(props.selectedMasterData, { name: 'short_term_bank' })[0].level_five.levelFiveName : STAccounts.bankName
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="6" md="6" xs="6">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Select LT Cash Account:</b></Form.Label>
                                    <Select
                                        // isDisabled={isEmpty(getDepartmentNameList)}
                                        placeholder="Select LT Cash Account..."
                                        onChange={(selectedOption) => { setlTAccounts((prev) => ({ ...prev, cashId: selectedOption.value, cashName: selectedOption.label })); }}
                                        options={getAccountsList}
                                        value={{
                                            value: (LTAccounts.cashId === "" && !isEmpty(props.selectedMasterData)) ? filter(props.selectedMasterData, { name: 'long_term_cash' })[0].levelFiveId : LTAccounts.cashId, /*Sometimes the indexing is opposite, then both values get swapped*/
                                            label: (LTAccounts.cashId === "" && !isEmpty(props.selectedMasterData)) ? filter(props.selectedMasterData, { name: 'long_term_cash' })[0].level_five.levelFiveName : LTAccounts.cashName
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg="6" md="6" xs="6">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Select ST Cash Account:</b></Form.Label>
                                    <Select
                                        // isDisabled={isEmpty(getDepartmentNameList)}
                                        placeholder="Select ST Cash Account..."
                                        onChange={(selectedOption) => { setsTAccounts((prev) => ({ ...prev, cashId: selectedOption.value, cashName: selectedOption.label })); }}
                                        options={getAccountsList}
                                        value={{
                                            value: (STAccounts.cashId === "" && !isEmpty(props.selectedMasterData)) ? filter(props.selectedMasterData, { name: 'short_term_cash' })[0].levelFiveId : STAccounts.cashId, /*Sometimes the indexing is opposite, then both values get swapped*/
                                            label: (STAccounts.cashId === "" && !isEmpty(props.selectedMasterData)) ? filter(props.selectedMasterData, { name: 'short_term_cash' })[0].level_five.levelFiveName : STAccounts.cashName
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="6" md="6" xs="6">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Select LT Adjustment Account:</b></Form.Label>
                                    <Select
                                        // isDisabled={isEmpty(getDepartmentNameList)}
                                        placeholder="Select LT Adjustment Account..."
                                        onChange={(selectedOption) => { setlTAccounts((prev) => ({ ...prev, adjustId: selectedOption.value, adjustName: selectedOption.label })); }}
                                        options={getAccountsList}
                                        value={{
                                            value: (LTAccounts.adjustId === "" && !isEmpty(props.selectedMasterData)) ? filter(props.selectedMasterData, { name: 'long_term_adjustment' })[0].levelFiveId : LTAccounts.adjustId, /*Sometimes the indexing is opposite, then both values get swapped*/
                                            label: (LTAccounts.adjustId === "" && !isEmpty(props.selectedMasterData)) ? filter(props.selectedMasterData, { name: 'long_term_adjustment' })[0].level_five.levelFiveName : LTAccounts.adjustName
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg="6" md="6" xs="6">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Select ST Adjustment Account:</b></Form.Label>
                                    <Select
                                        // isDisabled={isEmpty(getDepartmentNameList)}
                                        placeholder="Select ST Adjustment Account..."
                                        onChange={(selectedOption) => { setsTAccounts((prev) => ({ ...prev, adjustId: selectedOption.value, adjustName: selectedOption.label })); }}
                                        options={getAccountsList}
                                        value={{
                                            value: (STAccounts.adjustId === "" && !isEmpty(props.selectedMasterData)) ? filter(props.selectedMasterData, { name: 'short_term_adjustment' })[0].levelFiveId : STAccounts.adjustId, /*Sometimes the indexing is opposite, then both values get swapped*/
                                            label: (STAccounts.adjustId === "" && !isEmpty(props.selectedMasterData)) ? filter(props.selectedMasterData, { name: 'short_term_adjustment' })[0].level_five.levelFiveName : STAccounts.adjustName
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        {/* ------------------------------------------------------ */}
                        <Row>
                            <Col lg="6" md="6" xs="6">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Select Expense Account:</b></Form.Label>
                                    <Select
                                        // isDisabled={isEmpty(getDepartmentNameList)}
                                        placeholder="Select Expense Account..."
                                        onChange={(selectedOption) => { setMiscAccounts((prev) => ({ ...prev, sExpenseId: selectedOption.value, sExpenseName: selectedOption.label })); }}
                                        options={getAccountsList}
                                        value={{
                                            value: (miscAccounts.sExpenseId === "" && !isEmpty(props.selectedMasterData)) ? filter(props.selectedMasterData, { name: 'salary_expense' })[0].levelFiveId : miscAccounts.sExpenseId, /*Sometimes the indexing is opposite, then both values get swapped*/
                                            label: (miscAccounts.sExpenseId === "" && !isEmpty(props.selectedMasterData)) ? filter(props.selectedMasterData, { name: 'salary_expense' })[0].level_five.levelFiveName : miscAccounts.sExpenseName
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg="6" md="6" xs="6">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Select Overtime Account:</b></Form.Label>
                                    <Select
                                        // isDisabled={isEmpty(getDepartmentNameList)}
                                        placeholder="Select Overtime Account..."
                                        onChange={(selectedOption) => { setMiscAccounts((prev) => ({ ...prev, ovetimeId: selectedOption.value, ovetimeName: selectedOption.label })); }}
                                        options={getAccountsList}
                                        value={{
                                            value: (miscAccounts.ovetimeId === "" && !isEmpty(props.selectedMasterData)) ? filter(props.selectedMasterData, { name: 'overtime_expense' })[0].levelFiveId : miscAccounts.ovetimeId, /*Sometimes the indexing is opposite, then both values get swapped*/
                                            label: (miscAccounts.ovetimeId === "" && !isEmpty(props.selectedMasterData)) ? filter(props.selectedMasterData, { name: 'overtime_expense' })[0].level_five.levelFiveName : miscAccounts.ovetimeName
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="6" md="6" xs="6">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Select Allowances Account:</b></Form.Label>
                                    <Select
                                        // isDisabled={isEmpty(getDepartmentNameList)}
                                        placeholder="Select Allowances Account..."
                                        onChange={(selectedOption) => { setMiscAccounts((prev) => ({ ...prev, allowancesId: selectedOption.value, allowancesName: selectedOption.label })); }}
                                        options={getAccountsList}
                                        value={{
                                            value: (miscAccounts.allowancesId === "" && !isEmpty(props.selectedMasterData)) ? filter(props.selectedMasterData, { name: 'allowances' })[0].levelFiveId : miscAccounts.allowancesId, /*Sometimes the indexing is opposite, then both values get swapped*/
                                            label: (miscAccounts.allowancesId === "" && !isEmpty(props.selectedMasterData)) ? filter(props.selectedMasterData, { name: 'allowances' })[0].level_five.levelFiveName : miscAccounts.allowancesName
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg="6" md="6" xs="6">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Select Deductions Account:</b></Form.Label>
                                    <Select
                                        // isDisabled={isEmpty(getDepartmentNameList)}
                                        placeholder="Select Deductions Account..."
                                        onChange={(selectedOption) => { setMiscAccounts((prev) => ({ ...prev, deductionsId: selectedOption.value, deductionsName: selectedOption.label })); }}
                                        options={getAccountsList}
                                        value={{
                                            value: (miscAccounts.deductionsId === "" && !isEmpty(props.selectedMasterData)) ? filter(props.selectedMasterData, { name: 'deductions' })[0].levelFiveId : miscAccounts.deductionsId, /*Sometimes the indexing is opposite, then both values get swapped*/
                                            label: (miscAccounts.deductionsId === "" && !isEmpty(props.selectedMasterData)) ? filter(props.selectedMasterData, { name: 'deductions' })[0].level_five.levelFiveName : miscAccounts.deductionsName
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="6" md="6" xs="6">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Select Salary Payable Account:</b></Form.Label>
                                    <Select
                                        // isDisabled={isEmpty(getDepartmentNameList)}
                                        placeholder="Select Salary Payable Account..."
                                        onChange={(selectedOption) => { setMiscAccounts((prev) => ({ ...prev, salaryId: selectedOption.value, salaryName: selectedOption.label })); }}
                                        options={getAccountsList}
                                        value={{
                                            value: (miscAccounts.salaryId === "" && !isEmpty(props.selectedMasterData)) ? filter(props.selectedMasterData, { name: 'salary_payable' })[0].levelFiveId : miscAccounts.salaryId, /*Sometimes the indexing is opposite, then both values get swapped*/
                                            label: (miscAccounts.salaryId === "" && !isEmpty(props.selectedMasterData)) ? filter(props.selectedMasterData, { name: 'salary_payable' })[0].level_five.levelFiveName : miscAccounts.salaryName
                                        }}
                                    />
                                </Form.Group>
                            </Col>

                        </Row>
                        <div className="sendDiv">
                            <Button className="sendButton" onClick={saveAccounts} 
                            // disabled={(LTAccountId === '') || (STAccountId === '')}
                        >Save</Button>
                        </div>
                    </Form>

                </div>
            )}
        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
        getMasterAccounts: () => dispatch(getMasterAccounts()),
        addAccounts: (data, handleVisible, handleDangerVisible) => dispatch(addAccounts(data, handleVisible, handleDangerVisible)),
        resetMasterData: () => dispatch(resetMasterData()),
        getSelectedMasterAccounts: () => dispatch(getSelectedMasterAccounts()),


    };
};
const mapStateToProps = (state, ownProps) => ({
    isFetchingMasterAccounts: state.HrReducer.isFetchingMasterAccounts,
    masterData: state.HrReducer.masterData,
    selectedMasterData: state.HrReducer.selectedMasterData,
    isFetchingSelectedMasterAccounts: state.HrReducer.isFetchingSelectedMasterAccounts,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MasterControl);