import React, { useState } from "react";
import { Container, Form, Row, Col, Button } from "react-bootstrap";
import {
  addHall,
  getHall,
  deleteHall,
  UpdateHall,
} from "./MarqueeDetails.actions";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import { useEffect } from "react";
import Swal from "sweetalert";
import jsPDF from "jspdf";
import { isEmpty } from "lodash";
import logo from "../../../../../Images/logo.png";

const HallList = (props) => {
  const hallData = isEmpty(props.hallList) ? [] : props.hallList.reverse();
  console.log(hallData);

  useEffect(() => {
    props.getHall();
  }, []);
  return (
    <>
      <Container fluid>
        {props.isFetchingHallData ? (
          <div className="loader-div">
            <Button variant="info" disabled>
              <Spinner
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
                variant="info"
              />
              Fetching Hall Data
            </Button>
          </div>
        ) : (
          <MaterialTable
            title="Hall"
            columns={[
              { title: "Hall Name", field: "hall_name" },
              {
                title: "Hall Sitting Capacity",
                field: "hall_sitting_capacity",
              },
              {
                title: "Hall Per Person Charges",
                field: "hall_per_person_charges",
              },
              {
                title: "Hall Per Function Charges",
                field: "hall_per_function_charges",
              },
              { title: "Hall Description", field: "hall_desc" },
            ]}
            data={hallData}
            options={{
              actionsColumnIndex: -1,
              filtering: true,
              exportButton: true,
              paging: true,
              pageSize: 50,
              emptyRowsWhenPaging: false, // To avoid of having empty rows
              pageSizeOptions: [20, 50, 100, 150, 200],
              exportAllData: true,
              exportPdf: (columns, data) => {
                const doc = new jsPDF();

                const columnTitles = [
                  {
                    title: "Hall Name",
                    field: "hall_name",
                    render: (rowData) => rowData.hall_name,
                  },
                  {
                    title: "Hall Sitting Capacity",
                    field: "hall_sitting_capacity",
                    render: (rowData) => rowData.hall_sitting_capacity,
                  },
                  {
                    title: "Hall Per Person Charges",
                    field: "hall_per_person_charges",
                    render: (rowData) => rowData.hall_per_person_charges,
                  },
                  {
                    title: "Hall Per Function Charges",
                    field: "hall_per_function_charges",
                    render: (rowData) => rowData.hall_per_function_charges,
                  },
                  {
                    title: "Hall Description",
                    field: "hall_desc",
                    render: (rowData) => rowData.hall_desc,
                  },
                ].map((columnDef) => columnDef.title);
                const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
                const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
                const companyNtn = `${process.env.REACT_APP_COMPANY_NTN}`;
                const pdfData = data.map((rowData) => [
                  rowData.hall_name,
                  rowData.hall_sitting_capacity,
                  rowData.hall_per_person_charges,
                  rowData.hall_per_function_charges,
                  rowData.hall_desc,
                ]);
                let content = {
                  startY: 50,
                  startX: 5,
                  head: [columnTitles],
                  body: pdfData,
                };
                doc.addImage(logo, "PNG", 10, 1, 50, 50);
                doc.setFontSize(20);
                doc.setFont(undefined, "bold");
                doc.text(companyName, 50, 22, 0, 0);
                doc.setFontSize(13);
                doc.setFont("TimesNewRoman", "Normal");
                doc.text(companyAddress, 50, 29, 0, 0);
                doc.text(companyNtn, 50, 36, 0, 0);
                doc.autoTable(content);
                doc.save(`Hall Table.pdf`);
              },
              maxBodyHeight: "600px",
              headerStyle: {
                position: "sticky",
                top: 0,
                color: "#00BBBB",
                fontWeight: "550",
                onRowAdd: "none",
              },
            }}
            editable={{
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    {
                      // console.log('newData', newData),
                      props.UpdateHall(newData, oldData);
                    }
                  }, 600);
                }),
              onRowDelete: (oldData) =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    props.deleteHall(oldData.hall_id);
                    console.log(oldData, "old");
                  }, 600);
                }),
            }}
          />
        )}
      </Container>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getHall: () => dispatch(getHall()),
    UpdateHall: (newData, oldData) => dispatch(UpdateHall(newData, oldData)),
    deleteHall: (hall_id) => dispatch(deleteHall(hall_id)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  isFetchingHallData: state.MarqueeReducer.isFetchingHallData,
  hallList: state.MarqueeReducer.hallList,
});

export default connect(mapStateToProps, mapDispatchToProps)(HallList);
