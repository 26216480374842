import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, Alert } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { IconButton } from "@material-ui/core";
import "jspdf-autotable";
import { addProcessCall, getProcessCall } from "./production.actions";

const AddProcess = (props) => {
  const [inputField, setInputField] = useState([
    { processName: "", processDescription: "" },
  ]);
  const [categoryName, setCategoryName] = useState("");

  /*const [state, setState] = useState({
        columns: [
            { title: 'Size', field: 'sizeName' },
            { title: 'Size Description', field: 'sizeDescription' },
        ],
    });*/

  const handleChangeInput = (index, event) => {
    const values = [...inputField];
    values[index][event.target.id] = event.target.value;
    setInputField(values);
  };

  const handleAddFields = () => {
    setInputField([...inputField, { processName: "", processDescription: "" }]);
  };

  const handleRemoveFields = (index) => {
    const values = [...inputField];
    if ((index = 1)) values.splice(index, 1);
    setInputField(values);
  };

  function sendData() {
    props.addProcessCall({
      categoryProcessName: categoryName,
      data:
        !isEmpty(inputField) &&
        inputField.map((v) => ({
          ...v,
          processName: v.processName,
          processDescription: v.processDescription,
        })),
    });
  }

  console.log(inputField, "qqjjss");

  useEffect(() => {
    props.getProcessCall();
  }, []);

  return (
    <Container fluid>
      <div className="main">
        <div>
          <Form>
            <Row>
              <Col lg="12" md="12" xs="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Category Name:</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="categoryName"
                    placeholder="Category Name...."
                    onBlur={(e) => setCategoryName(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>

            {inputField.map((inputField, index) => (
              <div key={index}>
                <Row>
                  <Col lg="6" md="6" xs="12">
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <b>Process:</b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        id="processName"
                        placeholder="Process...."
                        onBlur={(e) => handleChangeInput(index, e)}
                      />
                    </Form.Group>
                  </Col>

                  <Col lg="6" md="6" xs="12">
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <b>Description:</b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        id="processDescription"
                        placeholder="Description...."
                        onBlur={(e) => handleChangeInput(index, e)}
                      />
                    </Form.Group>
                  </Col>

                  <Col>
                    <IconButton
                      onClick={() => handleAddFields()}
                      style={{
                        float: "right",
                        color: "black",
                        marginTop: "15px",
                      }}
                    >
                      <AddIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => handleRemoveFields(index)}
                      style={{
                        float: "right",
                        color: "black",
                        marginTop: "15px",
                      }}
                    >
                      <RemoveIcon />
                    </IconButton>
                  </Col>
                </Row>
              </div>
            ))}

            <div className="sendDiv">
              <Button className="sendButton" onClick={() => sendData()}>
                Save
              </Button>
            </div>

            <MaterialTable
              title="Process Details"
              columns={[
                { title: "Size", field: "sizeName" },
                { title: "Size Description", field: "sizeDescription" },
              ]}
              //   data={props.sizeInfo}
              options={{
                actionsColumnIndex: -1,
                filtering: true,
                exportButton: true,
                exportAllData: true,
                maxBodyHeight: "600px",
                headerStyle: {
                  position: "sticky",
                  top: 0,
                  color: "#00BBBB",
                  fontWeight: "550",
                  onRowAdd: "none",
                },
              }}
              editable={{
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      // props.updateWareHouse(newData, oldData)
                    }, 600);
                  }),

                onRowDelete: (oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      //   props.deleteWareHouse(oldData.wareHouseId);
                    }, 600);
                  }),
              }}
            />
          </Form>
        </div>
      </div>
    </Container>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addProcessCall: (data) => dispatch(addProcessCall(data)),
    getProcessCall: () => dispatch(getProcessCall()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  processInfo: state.productionReducer.processInfo,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddProcess);
