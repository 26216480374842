import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import AddCategory from "./AddCategory";
import CategoryTableList from "./CategoryTableList";
import {isNull } from "lodash";
const TotalCategoryData = (props) => {
    const permissions = JSON.parse(localStorage.getItem("permissions"));

    return (
        <>
            {/* <Tabs
                defaultActiveKey={!isNull(permissions) && permissions.some(p => p['sub_id'] === 1) ?
                    "CategoryList" : !isNull(permissions) && permissions.some(p => p['sub_id'] === 2) ?
                        "AddCategory" : ""}
                id="AddCategory"
                className="mb-3"
                unmountOnExit={true}>
                <Tab
                    eventKey="CategoryList"
                    title="Category List"
                    unmountOnExit={true}
                    disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 1) ? false : true}>
                    <CategoryTableList />
                </Tab>
                <Tab
                    eventKey="AddCategory"
                    title="Add Category"
                    unmountOnExit={true}
                    disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 2) ? false : true}>
                    <AddCategory />
                </Tab>
            </Tabs> */}

            <Tabs defaultActiveKey={!isNull(permissions) && permissions.some(p => p['sub_id'] === 3) ?
                    "AddCategory" : !isNull(permissions) && permissions.some(p => p['sub_id'] === 4) ?
                        "CategoryList" : ""} id="AddCategory" className="mb-3">
                
                <Tab eventKey="AddCategory" title="Add Category"
                disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 3) ? false : true}
                >
                    <AddCategory />
                </Tab>
                <Tab eventKey="CategoryList" title="Category List"
                disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 4) ? false : true}
                >
                    <CategoryTableList />
                </Tab>
            </Tabs>
        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
    };
};
const mapStateToProps = (state, ownProps) => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TotalCategoryData);