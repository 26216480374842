import API from "../../../../../global/api";
import { get, forEach, isNull, isEmpty, sumBy } from "lodash";
import logo from "../../../../../Images/logo.png";
import jsPDF from "jspdf";
import moment from "moment";

export const requestGetCustomer = () => {
  return {
    type: "REQUEST_GET_CUSTOMER",
  };
};

export const successGetCustomer = (data) => {
  return {
    type: "SUCCESS_GET_CUSTOMER",
    payload: data,
  };
};

export const errorGetCustomer = () => {
  return {
    type: "ERROR_GET_CUSTOMER",
  };
};
export const getCustomer = () => {
  return (dispatch) => {
    dispatch(requestGetCustomer());
    API.get("/invoice/get_invoice_customer")
      .then((res) => {
        dispatch(successGetCustomer(res.data));
      })
      .catch((error) => {
        dispatch(errorGetCustomer());
      });
  };
};
//delete invoice
export const requestDeleteInvoice = () => {
  return {
    type: "REQUEST_DELETE_INVOICE",
  };
};

export const successDeleteInvoice = (data) => {
  return {
    type: "SUCCESS_DELETE_INVOICE",
    payload: data,
  };
};

export const errorDeleteInvoice = () => {
  return {
    type: "ERROR_DELETE_INVOICE",
  };
};
export const deleteInvoice = (id) => {
  return (dispatch) => {
    dispatch(requestDeleteInvoice());
    API.delete(`/invoice/${id}`)
      .then((res) => {
        dispatch(getInvoice());
        dispatch(successDeleteInvoice(res.data));
      })
      .catch((error) => {
        dispatch(errorDeleteInvoice());
      });
  };
};
//add Ivoice
export const requestAddInvoice = () => {
  return {
    type: "REQUEST_ADD_INVOICE",
  };
};
export const successAddInvoice = (data) => {
  return {
    type: "SUCCESS_ADD_INVOICE",
    payload: data,
  };
};
export const errorAddInvoice = () => {
  return {
    type: "ERROR_ADD_INVOICE",
  };
};
export const addInvoice = (data, handleVisible, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(requestAddInvoice());
    API.post(`/invoice/add_invoice`, data)
      .then((res) => {
        dispatch(getInvoice());
        dispatch(successAddInvoice(data));
        handleVisible();
      })
      .catch((error) => {
        dispatch(errorAddInvoice());
        handleDangerVisible();
      });
  };
};
///get Ivoice
export const requestGetInvoice = () => {
  return {
    type: "REQUEST_GET_INVOICE",
  };
};

export const successGetInvoice = (data) => {
  return {
    type: "SUCCESS_GET_INVOICE",
    payload: data,
  };
};

export const errorGetInvoice = () => {
  return {
    type: "ERROR_GET_INVOICE",
  };
};
export const getInvoice = () => {
  return (dispatch) => {
    dispatch(requestGetInvoice());
    API.get("/invoice/get_invoice")
      .then((res) => {
        dispatch(successGetInvoice(res.data));
      })
      .catch((error) => {
        dispatch(errorGetInvoice());
      });
  };
};
//pdf
export const getInvoicePdf = (data) => {
  return (dispatch) => {
    const unit = "pt";
    const size = "A4";
    const orientation = "potrait";

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(11);
    const title = "Invoice Report";
    const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
    const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
    const companyNtn = `${process.env.REACT_APP_COMPANY_NTN}`;
    const customerName = !isEmpty(data.level_five.levelFiveName)
      ? "Customer Name: " + data.level_five.levelFiveName
      : "Customer Name: - ";
    // const demandName = "Receiving Name: " + issuanceName;
    // const IGPNumber = "Receiving #: " + resData.map((x) => x.receiving_id);
    const invoiceDate = !isEmpty(data.invoice_date)
      ? "Invoice Date: " + data.invoice_date
      : "Invoice Date: - ";
    const grandTotal = "Grand Total: " + data.invoice_total_amount;

    const headers = [
      [
        "PRODUCT NAME",
        "INVENTORY PRICE",
        "INVOICE QUANTITY",
        "INVOICE TOTAL PRICE",
      ],
    ];
    const data1 = data.invoice_data.map((elt) => [
      elt.inventory.productName,
      // elt.inventory.productPrice,
      elt.invoice_data_price,
      elt.invoice_data_quantity,

      elt.total_price_inv,

      // Number(elt.inventory.productPrice) * Number(elt.invoice_data_quantity),
    ]);
    let content = {
      startY: 220,
      startX: 10,
      head: headers,
      tableWidth: "wrap",
      body: data1,
    };
    doc.addImage(logo, "PNG", 40, 15, 100, 100);
    doc.text(companyName, 150, 40, 0, 20);
    doc.setFontSize(13);
    doc.setFont("times", "normal");
    doc.text(companyAddress, 150, 60, 0, 20);
    doc.text(companyNtn, 150, 80, 0, 20);
    doc.setFontSize(14);
    doc.setFont("times", "bold");
    doc.text(title, 240, 120);
    doc.setFont("times", "normal");
    doc.text(customerName, 50, 150, 0, 20);
    doc.text(invoiceDate, 50, 170, 0, 20);
    doc.text(`Additional Description: ${data.invoice_desc}`, 50, 190, 0, 20);
    // doc.text(demandName, 200, 80, 0, 20);
    // doc.text(IGPNumber, 200, 120, 0, 20);

    doc.autoTable(content);
    let finalY = doc.lastAutoTable.finalY;
    doc.text(grandTotal, 420, finalY + 10);
    var blob = doc.output("blob");
    window.open(URL.createObjectURL(blob));
    // doc.save(`Invoice Report ${moment(new Date()).format("YYYY-MM-DD")}.pdf`);
  };
};
////post Invoice
export const requestPostInvoice = () => {
  return {
    type: "REQUEST_POST_INVOICE",
  };
};
export const successPostInvoice = (data) => {
  return {
    type: "SUCCESS_POST_INVOICE",
    payload: data,
  };
};
export const errorPostInvoice = () => {
  return {
    type: "ERROR_POST_INVOICE",
  };
};
export const PostInvoice = (data, handleVisible, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(requestPostInvoice());
    API.post(`/invoice/invoice_post`, data)
      .then((res) => {
        dispatch(getInvoice());
        dispatch(successPostInvoice(res.data));
        //   handleVisible();
      })
      .catch((error) => {
        dispatch(errorPostInvoice());
        //   handleDangerVisible();
      });
  };
};
