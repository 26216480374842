import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, FormGroup, Alert, Image } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty, isNull, upperCase, find, isUndefined } from "lodash";
import Spinner from 'react-bootstrap/Spinner';
import "./hr-styles.css";
import profileImage from "../../../../../Images/profileImage.jpg";
import Select from 'react-select';
import ImageUploader from 'react-images-upload';
import { HRUpdateEmployee, GetHRDepartment, GetHRDesignation, getEmployeeDataById, setEditEmployeeFields, getHREmployeeType, GetImageForAdress, GetHRDesignationByDepartment } from "./HRDetails.actions";
import Swal from 'sweetalert';
import axios from "axios";
import { baseURL } from "../../../../../global/api";
import { NavLink } from "react-router-dom";
import moment from'moment';


const EditEmployee = (props) => {

    const { state } = props.location;
    console.log('state', state);

    const ServerIP = `${baseURL}/employee/get_image_data_by_path`;
    const pfpPath = isNull(state.uploadImage) ? "" : state.uploadImage.replace(/\\/g, "/");
    console.log('pfpPath', pfpPath)
    console.log('isNull(pfpPath)', isNull(pfpPath))
    const [prevImg, setPrevImg] = useState(`${ServerIP}${pfpPath}`)
    console.log('prevImg', prevImg)


    const [uploadProfileImage, setUploadProfileImage] = useState('');
    console.log('uploadProfileImage', uploadProfileImage);
    const [image, setImage] = useState(profileImage);
    console.log('image', image);

    console.log('props.employeeListById.uploadImage', props.employeeListById.uploadImage);

    // const imgAdress = !isEmpty(props.employeeListById) & props.employeeListById.uploadImage;
    // !isEmpty(props.employeeListById) ? props.employeeListById.appointment_type : 

    // const [employeeId, setEmployeeId] = useState();
    const oldEmployeeData = props.employeeListById;
    console.log('oldEmployeeData', oldEmployeeData);
    console.log('props.employeeListById', props.employeeListById);
    console.log('props.profilePic', props.profilePic);

    const [employeName, setEmployeName] = useState('');

    const [appointmentType, setAppointmentType] = useState(!isEmpty(props.employeeListById) ? props.employeeListById.appointment_type : '');
    const [employeFatherName, setEmployeFatherName] = useState('');

    const [dob, setDob] = useState('');
    const [gender, setGender] = useState('');
    const [mobile, setMobile] = useState('');

    const [emergencyContact, setEmergencyContact] = useState('');

    const [maritalStatus, setMaritalStatus] = useState('');
    const [cnic, setCnic] = useState('');
    const [joiningDate, setJoiningDate] = useState('');
    const [religion, setReligion] = useState('');

    const [machineID, setMachineID] = useState('');

    const [employeeType, setEmployeeType] = useState();
    const [ntn, setNtn] = useState();
    const [eobi, setEobi] = useState();
    const [socialSecurity, setSocialSecurity] = useState();
    const [bloodGroup, setBloodGroup] = useState();
    const [probationPeriod, setProbationPeriod] = useState();
    const [probationEndDate, setProbationEndDate] = useState();

    const [reportTo, setReportTo] = useState('');
    const [uploadCnicImage, setUploadCnicImage] = useState('');
    const [uploadDocument, setUploadDocument] = useState('');

    const [activeStatus, setActiveStatus] = useState('');
    const [visibleAlert, setAlertVisible] = useState(false);
    const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);


    const [department, setDepartment] = useState('');
    const [designation, setDesignation] = useState('');
    // console.log(image, "ppppppppppp");
    const imageHandler = (e) => {
        setUploadProfileImage(e.target.files[0]);
        props.setEditEmployeeFields({ key: "uploadImage", value: e.target.files[0] });
        const reader = new FileReader();
        console.log('reader', reader)
        reader.onload = () => {
            if (reader.readyState == 2) {
                setImage(reader.result)
            }
        }
        reader.readAsDataURL(e.target.files[0]);
        console.log('reader', reader)

    };

    const getAppointmentList = [

        { value: "new", label: "New" },
        { value: "replacement", label: "Replacement" },
    ];
    const getStatusList = [

        { value: 1, label: "Active" },
        { value: 0, label: "Non Active" },
    ];

    const getGenderList = [

        { value: "Male", label: "Male" },
        { value: "Female", label: "Female" },
        { value: "Other", label: "Other" },
    ];

    const getReligionList = [

        { value: "Islam", label: "Islam" },
        { value: "Christianity", label: "Christianity" },
        { value: "Judaism", label: "Judaism" },
        { value: "Hinduism", label: "Hinduism" },
        { value: "Buddhism", label: "Buddhism" },
        { value: "Sikhism", label: "Sikhism" },
        { value: "Other", label: "Other" },
    ];
    const getMaritalStatusList = [

        { value: "Unmarried", label: "Unmarried" },
        { value: "Married", label: "Married" },
        // { value: "Divorced", label: "Divorced" },
        // { value: "Separated", label: "Separated" },

    ];

    const handleChangeAppointment = (selectedOption) => {
        setAppointmentType(selectedOption.value);
        props.setEditEmployeeFields({ key: "appointment_type", value: selectedOption.value })
    };
    const handleChangeGender = (selectedOption) => {
        setGender(selectedOption.value);
        props.setEditEmployeeFields({ key: "gender", value: selectedOption.value })
    };
    const handleChangeStatus = (selectedOption) => {
        // setActiveStatus(selectedOption.value);
        props.setEditEmployeeFields({ key: "active_status", value: selectedOption.value })
    };
    const onDrop = (pictureFiles, pictureDataURLs) => {
        setUploadCnicImage(pictureFiles);
    }
    const onDropDocs = (pictureFiles, pictureDataURLs) => {
        setUploadDocument(pictureFiles);
    }
    const handleChangeReligion = (selectedOption) => {
        setReligion(selectedOption.value);
        props.setEditEmployeeFields({ key: "religion", value: selectedOption.value })
    };
    const handleChangeMaritalStatus = (selectedOption) => {
        setMaritalStatus(selectedOption.value);
        props.setEditEmployeeFields({ key: "marital_status", value: selectedOption.value })
    };
    const SaveEmployee = () => {
        props.HRUpdateEmployee({
            appointment_type: !isEmpty(props.employeeListById.appointment_type) ? props.employeeListById.appointment_type : '',
            name: !isEmpty(props.employeeListById.name) ? upperCase(props.employeeListById.name) : '',
            father_name: !isEmpty(props.employeeListById.father_name) ? upperCase(props.employeeListById.father_name) : '',
            dob: !isEmpty(props.employeeListById.dob) ? props.employeeListById.dob : '',
            gender: !isEmpty(props.employeeListById.gender) ? props.employeeListById.gender : '',
            phone: !isEmpty(props.employeeListById.phone) ? props.employeeListById.phone : '',
            emergency_contact: !isEmpty(props.employeeListById.emergency_contact) ? props.employeeListById.emergency_contact : '',
            marital_status: !isEmpty(props.employeeListById.marital_status) ? props.employeeListById.marital_status : '',
            cnic: !isEmpty(props.employeeListById.cnic) ? props.employeeListById.cnic : '',
            uploadImage: uploadProfileImage,
            joining_date: !isEmpty(props.employeeListById.joining_date) ? props.employeeListById.joining_date : '',
            religion: !isEmpty(props.employeeListById.religion) ? props.employeeListById.religion : '',
            address: !isEmpty(props.employeeListById.address) ? upperCase(props.employeeListById.address) : '',
            employee_type: props.employeeListById.employee_type,
            ntn: !isEmpty(props.employeeListById.ntn) ? props.employeeListById.ntn : '',
            eobi: !isEmpty(props.employeeListById.eobi) ? props.employeeListById.eobi : '',
            social_security: !isEmpty(props.employeeListById.social_security) ? props.employeeListById.social_security : '',
            blood_group: !isEmpty(props.employeeListById.blood_group) ? props.employeeListById.blood_group : '',
            probation_period: !isEmpty(props.employeeListById.probation_period) ? upperCase(props.employeeListById.probation_period) : '',
            probation_period_end_date: !isEmpty(props.employeeListById.probation_period_end_date) ? props.employeeListById.probation_period_end_date : '',
            allocation: !isEmpty(props.employeeListById.allocation) ? upperCase(props.employeeListById.allocation) : '',

            report_to: !isEmpty(props.employeeListById.report_to) ? upperCase(props.employeeListById.report_to) : '',
            uploadCnicImage: !isEmpty(props.employeeListById.uploadCnicImage) ? props.employeeListById.uploadCnicImage : '',
            uploadDocument: !isEmpty(props.employeeListById.uploadDocument) ? props.employeeListById.uploadDocument : '',
            uploadDocument: uploadDocument,
            active_status: props.employeeListById.active_status,
            department_id: props.employeeListById.department_id,
            designation_id: props.employeeListById.designation_id,
        }, state, handleVisible, handleDangerVisible);
    };
    console.log(
        'appointment_type', props.employeeListById.appointment_type,
        'name', props.employeeListById.name,
        'father_name', props.employeeListById.father_name,
        'dob', props.employeeListById.dob,
        'gender', props.employeeListById.gender,
        'phone', props.employeeListById.phone,
        'emergency_contact', props.employeeListById.emergency_contact,
        'marital_status', props.employeeListById.marital_status,
        'cnic', props.employeeListById.cnic,
        'uploadImage', uploadProfileImage,
        'joining_date', props.employeeListById.joining_date,
        'religion', props.employeeListById.religion,

        'employee_type', props.employeeListById.employee_type,
        'ntn', props.employeeListById.ntn,
        'eobi', props.employeeListById.eobi,
        'social_security', props.employeeListById.social_security,
        'blood_group', props.employeeListById.blood_group,
        'probation_period', props.employeeListById.probation_period,
        'probation_period_end_date', props.employeeListById.probation_period_end_date,
        'allocation', props.employeeListById.allocation,

        'report_to', props.employeeListById.report_to,
        'uploadCnicImage', props.employeeListById.uploadCnicImage,
        'uploadDocument', props.employeeListById.uploadDocument,
        'active_status', props.employeeListById.active_status,
        'employee_type', props.employeeListById.employee_type,

        'department_id', props.employeeListById.department_id,
        'designation_id', props.employeeListById.designation_id,)
    console.log('employee_type', props.employeeListById.employee_type,

        'department_id', props.employeeListById.department_id,
        'designation_id', props.employeeListById.designation_id,)

    const getDepartmentNameList = !isEmpty(props.departmentList) && props.departmentList.map((x) => { let data = { value: x.departmentId, label: x.departmentName }; return data })
    const handleChangeDepartment = (selectedOption) => {
        setDepartment(selectedOption.value);
        props.GetHRDesignationByDepartment(selectedOption.value);
        props.setEditEmployeeFields({ key: "department_id", value: selectedOption.value });

    };
    const getDesignationNameList = !isEmpty(props.designationListByDepartmentId) && props.designationListByDepartmentId.map((x) => { let data = { value: x.designation_id, label: x.designationName }; return data })
    const handleChangeDesignation = (selectedOption) => {
        setDesignation(selectedOption.value);
        props.setEditEmployeeFields({ key: "designation_id", value: selectedOption.value });

    };
    console.log('getDesignationNameList', getDesignationNameList)

    const getEmployeeTypeList = !isEmpty(props.employeeTypeList) && props.employeeTypeList.map((x) => { let data = { value: x.employee_type_id, label: x.employee_type_name }; return data })
    const handleChangeEmployeeType = (selectedOption) => {
        setEmployeeType(selectedOption.value);
        props.setEditEmployeeFields({ key: "employee_type", value: selectedOption.value });

    };
    console.log('getEmployeeTypeList', getEmployeeTypeList)

    console.log('employeeListById', props.employeeListById)

    const fetchProfileImages = () => {
        console.log('fetchProfileImages')

        const imageName = 'garande.png'
        const url = `http://localhost:4000/${pfpPath}`;
        axios.get(url, { responseType: 'blob' })
            .then(res => {
                console.log('res.data', res.data)
                return (res.data);
            })
    }

    const handleVisible = () => {
        setAlertVisible(true)
        setTimeout(() => {
            setAlertVisible(false)
        }, 9000);
        Swal({
            title: "Success",
            text: "Added successfully...",
            icon: "success",
            button: "Ok",
        });
    }

    const handleDangerVisible = () => {
        setDangerVisibleAlert(true)
        setTimeout(() => {
            setDangerVisibleAlert(false)
        }, 9000);
        Swal({
            title: "Error",
            text: "Something went wrong...",
            icon: "error",
            button: "Ok",
        });
    }
    const setOldPfp = () => {
        setImage(`${baseURL}/get_image_data_by_path/${state.uploadImage}`);
        console.log(image, `${baseURL}/get_image_data_by_path/${state.uploadImage}`, "url");
        // setImage("https://picsum.photos/200");
    }
    useEffect(() => {
        props.GetHRDepartment();
        props.GetHRDesignation();
        props.getHREmployeeType();
        props.getEmployeeDataById(state.employeeId, setOldPfp);
        // const timer = setTimeout(() => {
        //     props.GetImageForAdress(state.uploadImage);
        //   }, 2000);
        //   return () => clearTimeout(timer);
        props.GetImageForAdress(state.uploadImage);


    }, []);
    return (
        <>
            <Container fluid>
                {(props.isFetchingEmployeeDataById
                    || isEmpty(props.employeeListById)
                    //|| isEmpty(props.employeeListById.uploadImage)
                ) ? <div className="loader-div">
                    {/* {[(!isEmpty(props.employeeListById)  && (image === profileImage) && (image !== props.uploadImage)) ? setImage( image ) : setImage(props.profileImage)]} */}
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />
                        {/* {props.isFetchingSectorsSummary ? 'Adding Employee...' : 'Adding Employee...'} */}

                    </Button>
                </div> :
                    <div className="mainHr">
                        <Row>
                            <Col lg="4" md="4" xs="12"></Col>
                            <Col lg="3" md="3" xs="12">
                                <Image
                                    src={image}
                                    // src = "https://picsum.photos/200"

                                    // src={isNull(pfpPath) ? image : fetchProfileImages()}
                                    roundedCircle width="100%" style={{ padding: "2%", textAlign: "center" }}
                                />
                                <input className="form-control" type="file" name="uploadProfileImage"
                                    onChange={imageHandler} />
                            </Col>
                            <Col lg="4" md="4" xs="12"></Col>
                        </Row>
                        <br /><br />
                        <Row>
                        <Col lg="4" md="4" xs="12">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Change Employment status:</b></Form.Label>
                                    <Select
                                        defaultValue={{ label: (props.employeeListById.active_status === true) ? "Active": "InActive" }}
                                        placeholder="Employment status...."
                                        onChange={handleChangeStatus}
                                        options={getStatusList}

                                    />
                                </Form.Group>
                            </Col>
                            <Col lg="4" md="4" xs="12">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Appointment Type <span style={{fontSize:'25px',fontWeight:'bold',color:'red'}}>*</span></b></Form.Label>
                                    <Select
                                        defaultValue={{ label: props.employeeListById.appointment_type }}
                                        placeholder="Appointment Type..."
                                        onChange={handleChangeAppointment}
                                        options={getAppointmentList}

                                    />
                                </Form.Group>
                            </Col>
                            {/* <Col lg="4" md="4" xs="12">
                            <Form.Group className="mb-3" controlId="garana" >
                                <Form.Label><b>Enter Employee ID :</b></Form.Label>
                                <Form.Control type="text" id="warehouseName" name="EmployeeId" placeholder="Employee ID...." defaultValue={props.employeeListById.employee_type} onChange={(e) => {props.setEditEmployeeFields({key:"", value: e.target.value}); setEmployeeId(e.target.value)}} />
                            </Form.Group>
                        </Col> */}
                            {/* <Col lg="4" md="4" xs="12">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Enter Machine ID:</b></Form.Label>
                                    <Form.Control type="text" id="warehouseName" name="MachineID" placeholder="Machine ID...." defaultValue={props.employeeListById.machine_id} onChange={(e) => { props.setEditEmployeeFields({ key: "machine_id", value: e.target.value }); setMachineID(e.target.value) }} />
                                </Form.Group>
                            </Col> */}
                            <Col lg="4" md="4" xs="12">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Employee Name <span style={{fontSize:'25px',fontWeight:'bold',color:'red'}}>*</span></b></Form.Label>
                                    <Form.Control type="text" id="warehouseName" name="EmployeName" placeholder="Employee Name...." defaultValue={props.employeeListById.name} onChange={(e) => { props.setEditEmployeeFields({ key: "name", value: e.target.value }); setEmployeName(e.target.value) }} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>

                            <Col lg="4" md="4" xs="12">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Father Name <span style={{fontSize:'25px',fontWeight:'bold',color:'red'}}>*</span></b></Form.Label>
                                    <Form.Control type="text" id="warehouseName" name="EmployeFatherName" placeholder="Employee Father Name...." defaultValue={props.employeeListById.father_name} onChange={(e) => { props.setEditEmployeeFields({ key: "father_name", value: e.target.value }); setEmployeFatherName(e.target.value) }} />
                                </Form.Group>
                            </Col>
                            {/* <Col lg="4" md="4" xs="12">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Enter Mother Name :</b></Form.Label>
                                    <Form.Control type="text" id="warehouseName" name="EmployeMotherName" placeholder="Employee Mother Name...." defaultValue={ props.employeeListById.mother_name} onChange={(e) => {props.setEditEmployeeFields({key:"", value: e.target.value}); setEmployeMotherName(e.target.value)}} />
                                </Form.Group>
                            </Col> */}
                            <Col lg="4" md="4" xs="12">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Gender <span style={{fontSize:'25px',fontWeight:'bold',color:'red'}}>*</span></b></Form.Label>
                                    <Select
                                        defaultValue={{ label: props.employeeListById.gender }}
                                        placeholder="Select Gender..."
                                        onChange={handleChangeGender}
                                        options={getGenderList}

                                    />
                                </Form.Group>
                            </Col>

                            <Col lg="4" md="4" xs="12">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Date of Birth :</b></Form.Label>
                                    <Form.Control type="date" id="warehouseName" name="Dob" placeholder="Employee DOB...." defaultValue={props.employeeListById.dob} max= {moment().format("YYYY-MM-DD")} onChange={(e) => { props.setEditEmployeeFields({ key: "dob", value: e.target.value }); setDob(e.target.value) }} />
                                </Form.Group>
                            </Col>

                        </Row>
                        <Row>
                            <Col lg="4" md="4" xs="12">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Marital status:</b></Form.Label>
                                    <Select
                                        defaultValue={{ label: props.employeeListById.marital_status }}
                                        placeholder="Marital status...."
                                        onChange={handleChangeMaritalStatus}
                                        options={getMaritalStatusList}

                                    />
                                </Form.Group>
                            </Col>
                            <Col lg="4" md="4" xs="12">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Religion:</b></Form.Label>
                                    <Select
                                        defaultValue={{ label: props.employeeListById.religion }}
                                        placeholder="Religion...."
                                        onChange={handleChangeReligion}
                                        options={getReligionList}

                                    />
                                </Form.Group>
                            </Col>
                            <Col lg="4" md="4" xs="12">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Joining Date:</b></Form.Label>
                                    <Form.Control type="date" id="warehouseName" name="JoiningDate" placeholder="Joining Date...." defaultValue={props.employeeListById.joining_date} onChange={(e) => { props.setEditEmployeeFields({ key: "joining_date", value: e.target.value }); setJoiningDate(e.target.value) }} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="4" md="4" xs="12">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Mobile#:</b></Form.Label>
                                    <Form.Control type="text" id="warehouseName" name="Mobile" placeholder="Mobile Number...." defaultValue={props.employeeListById.phone} onChange={(e) => { props.setEditEmployeeFields({ key: "phone", value: e.target.value }); setMobile(e.target.value) }} />
                                </Form.Group>
                            </Col>
                            <Col lg="4" md="4" xs="12">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Emergency Contact#:</b></Form.Label>
                                    <Form.Control type="text" id="warehouseName" name="EmergencyContact" placeholder="Emergency Contact Number...." defaultValue={props.employeeListById.emergency_contact} onChange={(e) => { props.setEditEmployeeFields({ key: "emergency_contact", value: e.target.value }); setEmergencyContact(e.target.value) }} />
                                </Form.Group>
                            </Col>

                            <Col lg="4" md="4" xs="12">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Address:</b></Form.Label>
                                    <Form.Control type="text" id="warehouseName" placeholder="Address...." defaultValue={props.employeeListById.address} onChange={(e) => { props.setEditEmployeeFields({ key: "address", value: e.target.value }); }} />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>




                            <Col lg="4" md="4" xs="12">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Report to:</b></Form.Label>
                                    <Form.Control type="text" id="warehouseName" name="ReportTo" placeholder="Report to...." defaultValue={props.employeeListById.report_to} onChange={(e) => { props.setEditEmployeeFields({ key: "report_to", value: e.target.value }); setReportTo(e.target.value) }} />
                                </Form.Group>
                            </Col>

                        </Row>
                        <Row>
                            <Col lg="4" md="4" xs="12">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Employee Type:</b></Form.Label>
                                    {/* <Form.Control type="text" id="warehouseName" placeholder="Employee Type...." defaultValue={props.employeeListById.employee_type} onChange={(e) => { props.setEditEmployeeFields({ key: "employee_type", value: e.target.value }); setEmployeeType(e.target.value) }} /> */}
                                    <Select
                                        placeholder="Select Employee Type..."
                                        defaultValue={{label: !isUndefined(find(getEmployeeTypeList, {value: Number(props.employeeListById.employee_type)})) ? find(getEmployeeTypeList, {value: Number(props.employeeListById.employee_type)}).label: ("")}}
                                        onChange={handleChangeEmployeeType}
                                        options={getEmployeeTypeList}

                                    />
                                </Form.Group>
                            </Col>
                            <Col lg="4" md="4" xs="12">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>NTN:</b></Form.Label>
                                    <Form.Control type="text" id="warehouseName" placeholder="NTN...." defaultValue={props.employeeListById.ntn} onChange={(e) => { props.setEditEmployeeFields({ key: "ntn", value: e.target.value }); setNtn(e.target.value) }} />
                                </Form.Group>
                            </Col>
                            <Col lg="4" md="4" xs="12">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>EOBI#:</b></Form.Label>
                                    <Form.Control type="text" id="warehouseName" placeholder="EOBI#...." defaultValue={props.employeeListById.eobi} onChange={(e) => { props.setEditEmployeeFields({ key: "eobi", value: e.target.value }); setEobi(e.target.value) }} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="4" md="4" xs="12">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>SOCIAL SECURITY:</b></Form.Label>
                                    <Form.Control type="text" id="warehouseName" placeholder="SOCIAL SECURITY...." defaultValue={props.employeeListById.social_security} onChange={(e) => { props.setEditEmployeeFields({ key: "social_security", value: e.target.value }); setSocialSecurity(e.target.value) }} />
                                </Form.Group>
                            </Col>
                            <Col lg="4" md="4" xs="12">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Blood Group:</b></Form.Label>
                                    <Form.Control type="text" id="warehouseName" placeholder="Blood Group...." defaultValue={props.employeeListById.blood_group} onChange={(e) => { props.setEditEmployeeFields({ key: "blood_group", value: e.target.value }); setBloodGroup(e.target.value) }} />
                                </Form.Group>
                            </Col>
                            <Col lg="4" md="4" xs="12">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>CNIC <span style={{fontSize:'25px',fontWeight:'bold',color:'red'}}>*</span></b></Form.Label>
                                    <Form.Control type="text" id="warehouseName" name="Cnic" placeholder="Employee CNIC...." defaultValue={props.employeeListById.cnic} onChange={(e) => { props.setEditEmployeeFields({ key: "cnic", value: e.target.value }); setCnic(e.target.value) }} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12" md="12" xs="12">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Department <span style={{fontSize:'25px',fontWeight:'bold',color:'red'}}>*</span></b></Form.Label>
                                    <Select
                                        defaultValue={{ label: props.employeeListById.department.departmentName }}
                                        isDisabled={isEmpty(getDepartmentNameList)}
                                        placeholder="Select Department..."
                                        onChange={handleChangeDepartment}
                                        options={getDepartmentNameList}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12" md="12" xs="12">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Designation <span style={{fontSize:'25px',fontWeight:'bold',color:'red'}}>*</span></b></Form.Label>
                                    <Select
                                        defaultValue={{ label: (!isNull(props.employeeListById.designation.designationName) || (props.employeeListById.designation === undefined)) ? props.employeeListById.designation.designationName : '' }}
                                        //designation not being sent from backend
                                        isDisabled={isEmpty(getDesignationNameList)}
                                        placeholder="Select Designation..."
                                        onChange={handleChangeDesignation}
                                        options={getDesignationNameList}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Allocation:</b></Form.Label>
                                    <Form.Control as="textarea" rows={3} id="warehouseName" placeholder="Allocation...." defaultValue={props.employeeListById.allocation} onChange={(e) => { props.setEditEmployeeFields({ key: "allocation", value: e.target.value }); }} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="4" md="4" xs="12">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Probation Period:</b></Form.Label>
                                    <Form.Control type="text" id="warehouseName" placeholder="Probation Period...." defaultValue={props.employeeListById.probation_period} onChange={(e) => { props.setEditEmployeeFields({ key: "probation_period", value: e.target.value }); setProbationPeriod(e.target.value) }} />
                                </Form.Group>
                            </Col>

                            <Col lg="4" md="4" xs="12">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Probation End Date:</b></Form.Label>
                                    <Form.Control type="date" id="warehouseName" placeholder="Probation End Date...." defaultValue={props.employeeListById.probation_period_end_date} onChange={(e) => { props.setEditEmployeeFields({ key: "probation_period_end_date", value: e.target.value }); setProbationEndDate(e.target.value) }} />
                                </Form.Group>
                            </Col>

                        </Row>
                        <Row>
                            <Col lg="4" md="4" xs="12">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Upload Document:</b></Form.Label>
                                    <input className="form-control" type="file" name="UploadDocument" onChange={(e) => { setUploadDocument(e.target.files[0]) }} />
                                </Form.Group>
                            </Col>
                            <Col lg="4" md="4" xs="12">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Old Document:</b></Form.Label>
                                    <Button style={{width:"100%"}} href={`${baseURL}/get_image_data_by_path/${state.uploadDocument}`} disabled={isEmpty(state.uploadDocument)?true:false} target="_blank">View</Button> 
                                </Form.Group>
                            </Col>

                        </Row>


                        <Row>
                            <Col xl="3" lg="3" md="6" sm="12" xs="12">
                                <Form.Group className="mb-3">
                                    <Form.Label>
                                        <b style={{ fontSize: "large" }}>CNIC</b>
                                    </Form.Label>
                                    <Image
                                        src={`${baseURL}/get_image_data_by_path/${state.uploadCnicImage}`}
                                        // src={isNull(pfpPath) ? image : fetchProfileImages()}
                                        rounded={true} width="100%" style={{ padding: "2%", textAlign: "center" }}
                                    />
                                </Form.Group>

                            </Col>
                            <Col xl="9" lg="9" md="6" sm="12" xs="12">
                                <Form.Group className="mb-3">
                                    {/* <Form.Label>
                                        <b style={{ fontSize: "large" }}>Upload new CNIC Image</b>
                                    </Form.Label> */}
                                    <ImageUploader
                                        withIcon={false}
                                        buttonText='Upload new CNIC Image'
                                        onChange={onDrop}
                                        // onChange = {(e) => setUploadCnicImage(e.target.files[0])}
                                        imgExtension={['.jpg', '.gif', '.png', '.gif']}
                                        maxFileSize={999999999999999999999}
                                        singleImage={true}
                                        withPreview={true}
                                    />
                                </Form.Group>
                            </Col>
                            {/* <Col xl="6" lg="6" md="6" sm="6" xs="6">
                                <Form.Group className="mb-3">
                                    <Form.Label>
                                        <b style={{ fontSize: "large" }}>Upload your Educational Documents</b>
                                    </Form.Label>
                                    <ImageUploader
                                        withIcon={false}
                                        buttonText='Upload Documents'
                                        onChange={onDropDocs}
                                        // onChange = {(e) => setUploadDocument(e.target.files[0])}
                                        imgExtension={['.jpg', '.gif', '.png', '.gif']}
                                        maxFileSize={999999999999999999999}
                                        singleImage={false}
                                        withPreview={true}
                                    />
                                </Form.Group>
                            </Col> */}
                        </Row>

                        <Row>
                            <Col xl="12" lg="12" md="12" sm="12" xs="12">

                                {/* <Document
                                    file={`https://cors-anywhere.herokuapp.com/${baseURL}get_image_data_by_path/${state.uploadDocument}`}
                                >
                                    <Page pageNumber="1" />
                                </Document> */}
                            </Col>
                        </Row>
                        <div className="sendDiv">
                            <Button className="sendButton" onClick={SaveEmployee}>Save</Button>
                        </div>
                    </div>}
            </Container>
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        HRUpdateEmployee: (data, oldData, handleVisible, handleDangerVisible) => dispatch(HRUpdateEmployee(data, oldData, handleVisible, handleDangerVisible)),
        GetHRDepartment: () => dispatch(GetHRDepartment()),
        GetHRDesignation: () => dispatch(GetHRDesignation()),
        getEmployeeDataById: (state, setOldPfp) => dispatch(getEmployeeDataById(state, setOldPfp)),
        setEditEmployeeFields: (data) => dispatch(setEditEmployeeFields(data)),
        getHREmployeeType: () => dispatch(getHREmployeeType()),
        GetImageForAdress: (address) => dispatch(GetImageForAdress(address)),
        GetHRDesignationByDepartment:(id)=>dispatch(GetHRDesignationByDepartment(id)),



    };
};
const mapStateToProps = (state, ownProps) => ({
    departmentList: state.HrReducer.departmentList,
    designationListByDepartmentId: state.HrReducer.designationListByDepartmentId,
    isFetchingEmployeeDataById: state.HrReducer.isFetchingEmployeeDataById,
    employeeListById: state.HrReducer.employeeListById,
    employeeTypeList: state.HrReducer.employeeTypeList,
    isFetchingEmployeeTypeData: state.HrReducer.isFetchingEmployeeTypeData,
    profilePic: state.HrReducer.profilePic,


});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditEmployee);