import React, { useState } from "react";
import { Container, Form, Row, Col, Button, FormGroup } from "react-bootstrap";
import {
  addExpense,
  getExpense,
  deleteExpense,
  getBooking,
  getMasterControl /*UpdateHRExpense */,
  getEvent,
  getSubEvent,
  getHall,
  getMenu,
  addBooking,
  addCustomer,
  addCustomermarquee,
  getCustomerMarquee,
  UpdateHall,
  deleteHall,
  deleteCustomer,
  UpdateCustomer,
} from "./MarqueeDetails.actions";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import { useEffect } from "react";
import Swal from "sweetalert";
import { isEmpty, find } from "lodash";
import Select from "react-select";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { IconButton } from "@material-ui/core";
import jsPDF from "jspdf";
import logo from "../../../../../Images/logo.png";
const CustomerMarqueeList = (props) => {
  const CustomerData = isEmpty(props.customerMarqueeList)
    ? []
    : props.customerMarqueeList.reverse();
  useEffect(() => {
    props.getCustomerMarquee();
    // props.getMasterControl(1)
  }, []);
  return (
    <>
      <Container fluid>
        {props.isFetchingHallData ? (
          <div className="loader-div">
            <Button variant="info" disabled>
              <Spinner
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
                variant="info"
              />
              Fetching Hall Data
            </Button>
          </div>
        ) : (
          <MaterialTable
            style={{ zIndex: 0 }}
            title="Customer"
            columns={[
              { title: "Customer Name", field: "customer_name" },
              { title: "Customer Phone", field: "customer_phone" },
              { title: "Customer Email", field: "customer_email" },
              { title: "Customer CNIC", field: "customer_cnic" },
              { title: "Customer Address", field: "customer_address" },
            ]}
            data={CustomerData}
            options={{
              actionsColumnIndex: -1,
              filtering: true,
              exportButton: true,
              exportAllData: true,
              exportPdf: (columns, data) => {
                const doc = new jsPDF();
                //render:rowData=>rowData.hall_name
                const columnTitles = [
                  {
                    title: "Customer Name",
                    field: "customer_name",
                    render: (rowData) => rowData.customer_name,
                  },
                  {
                    title: "Customer Phone",
                    field: "customer_phone",
                    render: (rowData) => rowData.customer_phone,
                  },
                  {
                    title: "Customer Email",
                    field: "customer_email",
                    render: (rowData) => rowData.customer_email,
                  },
                  {
                    title: "Customer CNIC",
                    field: "customer_cnic",
                    render: (rowData) => rowData.customer_cnic,
                  },
                  {
                    title: "Customer Address",
                    field: "customer_address",
                    render: (rowData) => rowData.customer_address,
                  },
                ].map((columnDef) => columnDef.title);
                const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
                const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
                const companyNtn = `${process.env.REACT_APP_COMPANY_NTN}`;
                const pdfData = data.map((rowData) => [
                  rowData.customer_name,
                  rowData.customer_phone,
                  rowData.customer_email,
                  rowData.customer_cnic,
                  rowData.customer_address,
                ]);
                let content = {
                  startY: 50,
                  startX: 5,
                  head: [columnTitles],
                  body: pdfData,
                };
                doc.addImage(logo, "PNG", 10, 1, 50, 50);
                doc.setFontSize(20);
                doc.setFont(undefined, "bold");
                doc.text(companyName, 70, 22, 0, 0);
                doc.setFontSize(13);
                doc.setFont("TimesNewRoman", "Normal");
                doc.text(companyAddress, 70, 29, 0, 0);
                doc.text(companyNtn, 70, 36, 0, 0);
                doc.autoTable(content);
                doc.save(`Customer Table.pdf`);
              },
              maxBodyHeight: "600px",
              headerStyle: {
                position: "sticky",
                top: 0,
                color: "#00BBBB",
                fontWeight: "550",
                onRowAdd: "none",
              },
            }}
            editable={{
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    {
                      // console.log('newData', newData),
                      props.UpdateCustomer(newData, oldData);
                    }
                  }, 600);
                }),
              /*   onRowDelete: (oldData) =>
                                    new Promise((resolve) => {
                                        setTimeout(() => {
                                            resolve();
                                            props.deleteCustomer(oldData.customer_id);
                                            console.log(oldData, 'old')
                                        }, 600);
                                    }),*/
            }}
          />
        )}
      </Container>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCustomerMarquee: () => dispatch(getCustomerMarquee()),
    deleteCustomer: (id) => dispatch(deleteCustomer(id)),
    UpdateCustomer: (newData, oldData) =>
      dispatch(UpdateCustomer(newData, oldData)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  isFetchingCustomerDataMarquee:
    state.MarqueeReducer.isFetchingCustomerDataMarquee,
  customerMarqueeList: state.MarqueeReducer.customerMarqueeList,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerMarqueeList);
