import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Svg,
  Line,
  Image,
} from "@react-pdf/renderer";
import logo from "../../../../Images/logo.png";
import { groupBy, isEmpty, get } from "lodash";

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#E4E4E4",
    fontSize: 13,
    lineHeight: '1.5px'
  },
  section: {
    margin: 5,
    padding: 5,
    flexGrow: 1,
  },
  textStyles: {
    marginLeft: 20,
  },
  textStyles1: {
    marginLeft: 30,
  },
  textStyles2: {
    marginLeft: 40,
  },
  textStyles3: {
    marginLeft: 50,
  },
  image: {
    width: "30%",
  },
});

var groupedLevel3;
var keys;
const PDFLevel5 = (props) => (
  // console.log(props.array,groupedLevel3,"testttttttttttt"),
  (groupedLevel3 = groupBy(!isEmpty(props.array) && props.array, function (x) {
    return x.levelfiveData.level_one.name;
  })),
  (keys = !isEmpty(groupedLevel3) && Object.keys(groupedLevel3)),
  console.log(props.array, groupedLevel3, "testttttttttttt"),
  (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Image style={styles.image} src={logo} />
          <Text style={{ textAlign: "center", padding: "5%" }}>
            Company Name: {process.env.REACT_APP_COMPANY_NAME}
          </Text>
          {!isEmpty(keys)
            ? keys.map((y, index) => {
              let x = get(groupedLevel3, `${y}`);
              console.log(x, "pdfData");
              return (
                <View key={index}>
                  {/*this is header for capital */}
                  <Text>
                    {x[0].levelfiveData.level_one.key}-{" "}
                    {x[0].levelfiveData.level_one.name}{" "}
                    {x[0].LevelOne.total_amount}
                    {" ("}
                    {x[0].LevelOne.debitCreditId == 1 ? "Cr" : "Dr"}
                    {")"}
                  </Text>
                  {Object.keys(
                    groupBy(x, (y) => y.levelfiveData.level_two.levelTwoName)
                  )
                    ? Object.keys(
                      groupBy(x, (y) => y.levelfiveData.level_two.levelTwoName)
                    ).map((xyz, index1) => {
                      let x1 = get(
                        groupBy(x, (y) => y.levelfiveData.level_two.levelTwoName), `${xyz}`
                      );
                      return (
                        <View key={index1}>
                          {/** Level 2 header  */}
                          <Text style={styles.textStyles}>
                            {x1[0].levelfiveData.level_one.key +
                              "-" +
                              x1[0].levelfiveData.level_two.levelTwoKey}
                            - {x1[0].levelfiveData.level_two.levelTwoName}{" "}
                            {x1[0].levelTwo.total_amount}
                            {" ("}
                            {x1[0].levelTwo.debitCreditId == 1 ? "Cr" : "Dr"}
                            {")"}
                          </Text>
                          {Object.keys(
                            groupBy(
                              x1,
                              (z) =>
                                z.levelfiveData.level_three.levelThreeName
                            )
                          )
                            ? Object.keys(
                              groupBy(
                                x1,
                                (z) =>
                                  z.levelfiveData.level_three
                                    .levelThreeName
                              )
                            ).map((wxyz, index2) => {
                              let x2 = get(
                                groupBy(
                                  x1,
                                  (z) =>
                                    z.levelfiveData.level_three
                                      .levelThreeName
                                ),
                                `${wxyz}`
                              );
                              return (
                                <View key={index2}>
                                  {/**Level 3 headers */}
                                  <Text style={styles.textStyles1}>
                                    {x2[0].levelfiveData.level_one.key +
                                      "-" +
                                      x2[0].levelfiveData.level_two
                                        .levelTwoKey +
                                      "-" +
                                      x2[0].levelfiveData.level_three
                                        .levelThreeKey}
                                    -{" "}
                                    {
                                      x2[0].levelfiveData.level_three
                                        .levelThreeName
                                    }{" "}
                                    {x2[0].levelThree.total_amount}
                                    {" ("}
                                    {x2[0].levelThree.debitCreditId == 1 ? "Cr" : "Dr"}
                                    {")"}
                                  </Text>
                                  {Object.keys(
                                    groupBy(
                                      x2,
                                      (w) =>
                                        w.levelfiveData.level_four
                                          .levelFourName
                                    )
                                  )
                                    ? Object.keys(
                                      groupBy(
                                        x2,
                                        (w) =>
                                          w.levelfiveData.level_four
                                            .levelFourName
                                      )
                                    ).map((vwxyz, index3) => {
                                      let x3 = get(
                                        groupBy(
                                          x2,
                                          (w) =>
                                            w.levelfiveData.level_four
                                              .levelFourName
                                        ),
                                        `${vwxyz}`
                                      );
                                      return (
                                        <View key={index3}>
                                          <Text
                                            style={styles.textStyles2}
                                          >
                                            {x3[0].levelfiveData
                                              .level_one.key +
                                              "-" +
                                              x3[0].levelfiveData
                                                .level_two.levelTwoKey +
                                              "-" +
                                              x3[0].levelfiveData
                                                .level_three
                                                .levelThreeKey +
                                              "-" +
                                              x3[0].levelfiveData
                                                .level_four
                                                .levelFourKey}
                                            -{" "}
                                            {
                                              x3[0].levelfiveData
                                                .level_four
                                                .levelFourName
                                            }{" "}
                                            {
                                              x3[0].levelFour
                                                .total_amount
                                            }
                                            {" ("}
                                            {x3[0].levelFour.debitCreditId == 1 ? "Cr" : "Dr"}
                                            {")"}
                                          </Text>
                                          {!isEmpty(x3)
                                            ? x3.map((lvl5, index4) => {
                                              return (
                                                <View key={index4}>
                                                  <Text
                                                    style={
                                                      styles.textStyles3
                                                    }
                                                  >
                                                    {
                                                      lvl5
                                                        .levelfiveData
                                                        .allLevelKey
                                                    }
                                                    -{" "}
                                                    {
                                                      lvl5
                                                        .levelfiveData
                                                        .levelFiveName
                                                    }{" "}
                                                    {
                                                      lvl5
                                                        .levelfiveData
                                                        .levelBalance
                                                    }
                                                    {" ("}
                                                    {
                                                      lvl5
                                                        .levelfiveData
                                                        .debitCreditId == 1 ? "Cr" : "Dr"
                                                    }
                                                    {")"}
                                                  </Text>
                                                </View>
                                              );
                                            })
                                            : ""}
                                        </View>
                                      );
                                    })
                                    : ""}
                                </View>
                              );
                            })
                            : ""}
                        </View>
                      );
                    })
                    : ""}
                </View>
              );
            })
            : ""}
        </View>
      </Page>
    </Document>
  )
);
export default PDFLevel5;
