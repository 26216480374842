import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import {isNull } from "lodash";
import AddHall from "./AddHall";
import HallList from "./HallList";
const TotalHallData = (props) => {
    const permissions = JSON.parse(localStorage.getItem("permissions"));

    return (
        <>
            <Tabs defaultActiveKey={!isNull(permissions) && permissions.some(p => p['sub_id'] === 88) ?
                    "AddHall" : !isNull(permissions) && permissions.some(p => p['sub_id'] === 89) ?
                        "HallList" :  ""}  id="AddHall" className="mb-3">
                <Tab eventKey="AddHall" title="Add Hall"
              disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 88) ? false : true}>  
                    <AddHall />
                </Tab>
                <Tab eventKey="HallList" title="Hall List"
               disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 89) ? false : true}> 
                    <HallList/>
                </Tab>
            </Tabs>
        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
    };
};
const mapStateToProps = (state, ownProps) => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TotalHallData);