import { isEmpty, isNull, uniqBy } from "lodash";

const initialState = {

    profilePic: '',
    salaryListByIdLocal: {
        machineId: 0,
        basicSalary: 0,
        medicalAllowance: 0,
        petrolAllowance: 0,
        specialAllowance: 0,
        accomodationAllowance: 0,
        bonus: 0,
        overTime: 0,
        overTimeRate: 0,
        salaryTransfer: '',
        bankAccount: 0,
        lateTimeDeduct_1: 0,
        lateTimeDeduct_2: 0,
        exemptionAttendance: '',
        bankName: '',
        leaveAllowed: '',
        activeStatus: Boolean,
        temporaryBlockSalary: '',
        reasonForBlockSalary: ''
    },

    setFieldsConsoleVar: '',

    isAddingEmployeeTypeData: false,
    addEmployeeType: [],
    employeeTypeList: [],
    isFetchingEmployeeTypeData: false,
    isUpdatingEmployeeTypeData: false,
    isDeletingEmployeeTypeData: false,

    isAddingDivisionData: false,
    addDivision: [],
    divisionList: [],
    isFetchingDivisionData: false,
    isUpdatingDivisionData: false,
    isDeletingDivisionData: false,

    employeeAdd: [],
    isAddingEmployee: false,
    employeeList: [],
    isFetchingEmployeeData: false,
    isAddingDepartmentData: false,

    activeEmployeeList: [],
    isFetchingActiveEmployeeData: false,

    addDepartment: [],
    departmentList: [],
    isFetchingDepartmentData: false,
    isDeletingDepartmentData: false,



    isAddingDesignationData: false,
    addDesignation: [],
    designationList: [],
    isFetchingDesignationData: false,

    designationListByDepartmentId: [],
    isFetchingDesignationDataByDepartmentId: false,

    isFetchingEmployeeDataById: false,
    employeeListById: [],
    isFetchingSalaryDataById: false,
    salaryListById: [],

    isFetchingLeaveData: false,
    casualLeaves: 0,
    maternityLeaves: 0,
    sickLeaves: 0,
    otherLeaves: 0,
    annualLeaves: 0,
    paternityLeaves: 0,
    shortLeaves: 0,
    specialLeaves: 0,


    showEmployeeAdd: false,
    isAddingSalary: false,
    errorAddingSalary: false,
    successAddingSalary: false,

    salaryAdd: [],

    isUpdatingSalary: false,
    salaryUpdate: [],

    shiftControl: [],
    addShiftControl: [],
    isFetchingShiftControlData: false,
    isAddingShiftControl: false,

    fine: [],
    addFine: [],
    isFetchingFineData: false,
    isAddingFine: false,

    masterData: [],
    isFetchingMasterAccounts: false,
    isAddingAccounts: false,
    addAccounts: [],

    ltAccount: [],
    isFetchingLTAccount: false,
    isAddingLTAccount: false,
    addLTAccount: [],
    //lt recivig
    isAddingLTReciving: false,
    addLTReciving: [],
    //st recivig
    isAddingSTReciving: false,
    addSTReciving: [],

    isAddingSTAccount: false,
    addSTAccount: [],

    isFetchingLTLedger: false,
    ltLedger: [],

    isFetchingSTLedger: false,
    stLedger: [],
    //get lt/st recivig
    isFetchingLTRecivingLedger: false,
    ltRecivingLedger: [],

    isFetchingSTRecivingLedger: false,
    stRecivingLedger: [],
    isFetchingIndividualSalary: false,
    indSalary: [],

    isFetchingSelectedMasterAccounts: false,
    selectedMasterData: [],
    //lt ledger
    isFetchingLtLedger:false,
    ltLedger:[],
    isFetchingSearchLtLedger:false,
    searchltLedger:[],
      //st ledger
      isFetchingStLedger:false,
      stLedger:[],
      isFetchingSearchStLedger:false,
      searchstLedger:[],

      isFetchingEmployeeLeavesData:false,
      employeeLeaveData:[],
};
console.log('initialState.employeeTypeList', initialState.employeeTypeList);


const HrReducer = (state = initialState, action) => {
    // console.log(action, "Awww")
    switch (action.type) {

        case "REQUEST_GET_IMAGE_HR":
            return {
                ...state,
                // isFetchingEmployeeTypeData: true 
            };
        case "SUCCESS_GET_IMAGE_HR":
            return {
                ...state,
                profilePic: action.payload,
                // isFetchingEmployeeTypeData: false,
            };
        case "ERROR_GET_IMAGE_HR":
            return { ...state };

        //EmployeeType-Add
        case "REQUEST_ADD_EMPLOYEE_TYPE":
            return { ...state, isAddingEmployeeTypeData: true };
        case "SUCCESS_ADD_EMPLOYEE_TYPE":
            return {
                ...state,
                addEmployeeType: action.payload,
                isAddingEmployeeTypeData: false,
            };
        case "ERROR_ADD_EMPLOYEE_TYPE":
            return { ...state, isAddingEmployeeTypeData: false };

        //EmployeeType-Get

        case "REQUEST_GET_EMPLOYEE_TYPE":
            return { ...state, isFetchingEmployeeTypeData: true };
        case "SUCCESS_GET_EMPLOYEE_TYPE":
            return {
                ...state,
                employeeTypeList: action.payload.employee_type,
                isFetchingEmployeeTypeData: false,
            };
        case "ERROR_GET_EMPLOYEE_TYPE":
            return { ...state, isFetchingEmployeeTypeData: false, };

        //EmployeeType-Update
        case "REQUEST_UPDATE_EMPLOYEE_TYPE":
            return { ...state, isUpdatingEmployeeTypeData: true };
        case "SUCCESS_UPDATE_EMPLOYEE_TYPE":
            return { ...state, isUpdatingEmployeeTypeData: false };
        case "ERROR_UPDATE_EMPLOYEE_TYPE":
            return { ...state, isUpdatingEmployeeTypeData: false };

        //EmployeeType-Delete
        case "REQUEST_DELETE_EMPLOYEE_TYPE":
            return { ...state, isDeletingEmployeeTypeData: true };
        case "SUCCESS_DELETE_EMPLOYEE_TYPE":
            return { ...state, isDeletingEmployeeTypeData: false };
        case "ERROR_DELETE_EMPLOYEE_TYPE":
            return { ...state, isDeletingEmployeeTypeData: false };
        // +++++++++++++++++++++++++++++++++++++++++++++++;        

        //Division-Add
        case "REQUEST_ADD_DIVISION":
            return { ...state, isAddingDivisionData: true };
        case "SUCCESS_ADD_DIVISION":
            return {
                ...state,
                addDivision: action.payload,
                isAddingDivisionData: false,
            };
        case "ERROR_ADD_DIVISION":
            return { ...state, isAddingDivisionData: false };

        //Division-Get

        case "REQUEST_GET_DIVISION":
            return { ...state, isFetchingDivisionData: true };
        case "SUCCESS_GET_DIVISION":
            return {
                ...state,
                divisionList: action.payload.result,
                isFetchingDivisionData: false,
            };
        case "ERROR_GET_DIVISION":
            return { ...state };

        //Division-Update
        case "REQUEST_UPDATE_DIVISION":
            return { ...state, isUpdatingDivisionData: true };
        case "SUCCESS_UPDATE_DIVISION":
            return { ...state, isUpdatingDivisionData: false };
        case "ERROR_UPDATE_DIVISION":
            return { ...state, isUpdatingDivisionData: false };

        //Division-Delete
        case "REQUEST_DELETE_DIVISION":
            return { ...state, isDeletingDivisionData: false };
        case "SUCCESS_DELETE_DIVISION":
            return { ...state, isDeletingDivisionData: false };
        case "ERROR_DELETE_DIVISION":
            return { ...state, isDeletingDivisionData: false };

        //Emlployee
        case "REQUEST_ADD_EMPLOYEE":
            return { ...state, isAddingEmployee: true, showEmployeeAdd: false };
        case "SUCCESS_ADD_EMPLOYEE":
            return {
                ...state,
                employeeAdd: action.payload,
                isAddingEmployee: false,
                showEmployeeAdd: true
            };
        case "ERROR_ADD_EMPLOYEE":
            return { ...state, isAddingEmployee: false };


        case "REQUEST_GET_EMPLOYEE":
            return { ...state, isFetchingEmployeeData: true };
        case "SUCCESS_GET_EMPLOYEE":
            return {
                ...state,
                employeeList: action.payload.data,
                employeeLeaveData:[],
                isFetchingEmployeeData: false,
            };
        case "ERROR_GET_EMPLOYEE":
            return { ...state, isFetchingEmployeeData: false };

        case "REQUEST_GET_ACTIVE_EMPLOYEE":
            return { ...state, isFetchingActiveEmployeeData: true };
        case "SUCCESS_GET_ACTIVE_EMPLOYEE":
            return {
                ...state,
                activeEmployeeList: action.payload.data,
                employeeLeaveData:[],
                isFetchingActiveEmployeeData: false,
            };
        case "ERROR_GET_ACTIVE_EMPLOYEE":
            return { ...state, isFetchingActiveEmployeeData: false };


        case "SET_EDIT_EMPLOYEE_FIELDS":
            let valueEmp = state.employeeListById;
            console.log('state.employeeListById ', state.employeeListById)
            console.log('valueEmp', valueEmp)

            let keyEmp = action.payload.key;
            console.log('keyEmp', keyEmp)

            valueEmp[keyEmp] = action.payload.value;
            console.log('valueEmp', valueEmp)
            let valueEmp2 = [valueEmp];
            console.log('valueEmp2', valueEmp2)

            console.log('EDIT_EMPLOYEE action.payload.data', action.payload)

            return { ...state, employeeListById: valueEmp };

        //Department

        case "REQUEST_ADD_DEPARTMENT":
            return { ...state, isAddingDepartmentData: true };
        case "SUCCESS_ADD_DEPARTMENT":
            return {
                ...state,
                addDepartment: action.payload,
                isAddingDepartmentData: false,
            };
        case "ERROR_ADD_DEPARTMENT":
            return { ...state, isAddingDepartmentData: false };

        //Department-get
        case "REQUEST_GET_DEPARTMENT":
            return { ...state, isFetchingDepartmentData: true };
        case "SUCCESS_GET_DEPARTMENT":
            return {
                ...state,
                departmentList: action.payload.data,
                isFetchingDepartmentData: false,
            };
        case "ERROR_GET_DEPARTMENT":
            return { ...state, isFetchingDepartmentData: false };
        //Department-getById

        case "REQUEST_GET_DEPARTMENT_BY_DIVISIONID":
            return { ...state, isFetchingDepartmentData: true };
        case "SUCCESS_GET_DEPARTMENT_BY_DIVISIONID":
            return {
                ...state,
                departmentList: action.payload.data,
                isFetchingDepartmentData: false,
            };
        case "ERROR_GET_DEPARTMENT_BY_DIVISIONID":
            return { ...state, isFetchingDepartmentData: false };
        //Department-Delete
        case "REQUEST_DELETE_DEPARTMENT":
            return { ...state, isDeletingDepartmentData: true };
        case "SUCCESS_DELETE_DEPARTMENT":
            return { ...state, isDeletingDepartmentData: false };
        case "ERROR_DELETE_DEPARTMENT":
            return { ...state, isDeletingDepartmentData: false };
        //Designation
        case "REQUEST_ADD_DESIGNATION":
            return { ...state, isAddingDesignationData: true };
        case "SUCCESS_ADD_DESIGNATION":
            return {
                ...state,
                addDesignation: action.payload,
                isAddingDesignationData: false,
            };
        case "ERROR_ADD_DESIGNATION":
            return { ...state, isAddingDesignationData: false };


        case "REQUEST_GET_DESIGNATION":
            return { ...state, isFetchingDesignationData: true };
        case "SUCCESS_GET_DESIGNATION":
            return {
                ...state,
                designationList: action.payload.designation,
                isFetchingDesignationData: false,
            };
        case "ERROR_GET_DESIGNATION":
            return { ...state, isFetchingDesignationData: false };

            case "REQUEST_GET_DESIGNATION_BY_DEPARTMENT":
                return { ...state, isFetchingDesignationDataByDepartmentId: true };
            case "SUCCESS_GET_DESIGNATION_BY_DEPARTMENT":
                return {
                    ...state,
                    designationListByDepartmentId: action.payload.data,
                    isFetchingDesignationDataByDepartmentId: false,
                };
            case "ERROR_GET_DESIGNATION_BY_DEPARTMENT":
                return { ...state, isFetchingDesignationDataByDepartmentId: false };

        case "REQUEST_DELETE_DESIGNATION":
            return { ...state };
        case "SUCCESS_DELETE_DESIGNATION":
            return { ...state, designationList: state.designationList.filter(item => item.designation_id !== action.designation_id) };
        case "ERROR_DELETE_DESIGNATION":
            return { ...state };

        case "UPDATE_REQUEST_HR":
            return { ...state };
        case "UPDATE_SUCCESS_HR":
            //return { ...state, employeeList: state.employeeList.map(x => action.payload.find(({ employeeId }) => employeeId == x.employeeId) || x) };
            return { ...state, employeeList: action.payload };

        case "UPDATE_ERROR_HR":
            return { ...state };

        case "REQUEST_DELETE_HR":
            return { ...state };
        case "SUCCESS_DELETE_HR":
            return { ...state, employeeList: state.employeeList.filter(item => item.employeeId !== action.employeeId) };
        case "ERROR_DELETE_HR":
            return { ...state };
        //HR Policy
        case "SET_CASUAL_LEAVE":

            console.log("casualLeaves", state.casualLeaves)
            return { ...state, casualLeaves: action.payload };
        case "SET_SICK_LEAVE":
            console.log("sickLeaves", state.sickLeaves)
            return { ...state, sickLeaves: action.payload };
        case "SET_OTHER_LEAVE":
            console.log("otherLeaves", state.otherLeaves)
            return { ...state, otherLeaves: action.payload };
        case "SET_ANNUAL_LEAVE":
            console.log("annualLeaves", state.annualLeaves)
            return { ...state, annualLeaves: action.payload };
        case "SET_PATERNITY_LEAVE":
            console.log("paternityLeaves", state.paternityLeaves)
            return { ...state, paternityLeaves: action.payload };
        case "SET_SHORT_LEAVE":
            console.log("shortLeaves", state.shortLeaves)
            return { ...state, shortLeaves: action.payload };
        case "SET_SPECIAL_LEAVE":
            console.log("specialLeaves", state.specialLeaves)
            return { ...state, specialLeaves: action.payload };
        case "SET_MATERNITY_LEAVE":
            console.log("maternityLeaves", state.maternityLeaves)
            return { ...state, maternityLeaves: action.payload };

        case "REQUEST_GET_HR_POLICY":
            return { ...state, isFetchingLeaveData: true };
        case "SUCCESS_GET_HR_POLICY":
            return {
                ...state,
                casualLeaves: action.payload.policy[0].casual_leaves,
                maternityLeaves: action.payload.policy[0].maternity_leaves,
                sickLeaves: action.payload.policy[0].sick_leaves,
                otherLeaves: action.payload.policy[0].other_leaves,

                annualLeaves: action.payload.policy[0].annual_leaves,
                paternityLeaves: action.payload.policy[0].paternity_leaves,
                shortLeaves: action.payload.policy[0].short_leaves,
                specialLeaves: action.payload.policy[0].special_leaves,

                isFetchingLeaveData: false,
            };
        case "ERROR_GET_HR_POLICY":
            return { ...state, isFetchingLeaveData: false };

        case "REQUEST_GET_EMPLOYEE_BY_ID":
            return { ...state, isFetchingEmployeeDataById: true };
        case "SUCCESS_GET_EMPLOYEE_BY_ID":
            return {
                ...state,
                employeeListById: action.payload.data[0],
                isFetchingEmployeeDataById: false,
            };
        case "ERROR_GET_EMPLOYEE_BY_ID":
            return { ...state, isFetchingEmployeeDataById: false };


        case "REQUEST_GET_SALARY_BY_ID":
            return { ...state, isFetchingSalaryDataById: true };
        case "SUCCESS_GET_SALARY_BY_ID":
            return {
                ...state,
                salaryListById: action.payload.salary,
                isFetchingSalaryDataById: false,
            };
        case "ERROR_GET_SALARY_BY_ID":
            return { ...state, isFetchingSalaryDataById: false };


        case "REQUEST_ADD_SALARY":
            return { ...state, isAddingSalary: true };
        case "SUCCESS_ADD_SALARY":
            return {
                ...state,
                salaryAdd: action.payload,
                isAddingSalary: false,
                successAddingSalary: true
            };
        case "ERROR_ADD_SALARY":
            return { ...state, isAddingSalary: false, errorAddingSalary: true };



        //Salary-Update
        case "REQUEST_UPDATE_SALARY":
            return { ...state, isUpdatingSalary: true };
        case "SUCCESS_UPDATE_SALARY":
            return {
                ...state,
                salaryUpdate: action.payload,
                isUpdatingSalary: false,
            };
        case "ERROR_UPDATE_SALARY":
            return { ...state, isUpdatingSalary: false };


        case "SET_SALARY_FIELDS":
            let valueSalary = state.salaryListById[0];
            console.log('SetSalary valueSalary', valueSalary);
            // let machineId=valueSalary.machineId
            console.log('action.payload', action.payload);

            let keySalary = action.payload.key;
            console.log('SetSalary keySalary', keySalary);
            console.log('SetSalary action.payload.data', action.payload);

            valueSalary[keySalary] = action.payload.value;
            console.log('SetSalary valueSalary[key]', valueSalary);

            let xo = [valueSalary]
            console.log('SetSalary xo', xo);

            console.log('SALARY action.payload.data', action.payload)

            return { ...state, salaryListById: [valueSalary] };



        //Shift Control
        case "REQUEST_GET_SHIFT_CONTROL":
            return { ...state, isFetchingShiftControlData: true };
        case "SUCCESS_GET_SHIFT_CONTROL":
            return {
                ...state,
                shiftControl: action.payload.result,
                isFetchingShiftControlData: false,
            };
        case "ERROR_GET_SHIFT_CONTROL":
            return { ...state, isFetchingShiftControlData: false };

        case "REQUEST_ADD_SHIFT_CONTROL":
            return { ...state, isAddingShiftControl: true };
        case "SUCCESS_ADD_SHIFT_CONTROL":
            return {
                ...state,
                addShiftControl: action.payload,
                isAddingShiftControl: false,
            };
        case "ERROR_ADD_SHIFT_CONTROL":
            return { ...state, isAddingShiftControl: false };


        case "REQUEST_DELETE_SHIFT_CONTROL":
            return { ...state };
        case "SUCCESS_DELETE_SHIFT_CONTROL":
            return { ...state, shiftControl: state.shiftControl.filter(item => item.shift_control_id !== action.shift_control_id) };
        case "ERROR_DELETE_SHIFT_CONTROL":
            return { ...state };

        case "REQUEST_UPDATE_SHIFT_CONTROL":
            return { ...state };
        case "SUCCESS_UPDATE_SHIFT_CONTROL":
            return { ...state, shiftControl: action.payload };

        case "ERROR_UPDATE_SHIFT_CONTROL":
            return { ...state };

        //Fine & Penalties
        case "REQUEST_GET_FINE":
            return { ...state, isFetchingFineData: true };
        case "SUCCESS_GET_FINE":
            return {
                ...state,
                fine: action.payload.result,
                isFetchingFineData: false,
            };
        case "ERROR_GET_FINE":
            return { ...state, isFetchingFineData: false };

        case "REQUEST_ADD_FINE":
            return { ...state, isAddingFine: true };
        case "SUCCESS_ADD_FINE":
            return {
                ...state,
                addFine: action.payload.result,
                isAddingFine: false,
            };
        case "ERROR_ADD_FINE":
            return { ...state, isAddingFine: false };


        case "REQUEST_DELETE_FINE":
            return { ...state };
        case "SUCCESS_DELETE_FINE":
            return { ...state, fine: state.fine.filter(item => item.fine_and_plenties_id !== action.fine_and_plenties_id) };
        case "ERROR_DELETE_FINE":
            return { ...state };

        case "REQUEST_UPDATE_FINE":
            return { ...state };
        case "SUCCESS_UPDATE_FINE":
            return { ...state, fine: action.payload };

        case "ERROR_UPDATE_FINE":
            return { ...state };
        //Master Control
        case "REQUEST_GET_MASTER_ACCOUNTS":
            return { ...state, isFetchingMasterAccounts: true };
        case "SUCCESS_GET_MASTER_ACCOUNTS":
            return {
                ...state,
                masterData: action.payload,
                isFetchingMasterAccounts: false,
            };
        case "ERROR_GET_MASTER_ACCOUNTS":
            return { ...state, isFetchingMasterAccounts: false };

        case "REQUEST_ADD_MASTER_ACCOUNTS":
            return { ...state, isAddingAccounts: true };
        case "SUCCESS_ADD_MASTER_ACCOUNTS":
            return {
                ...state,
                addAccounts: action.payload,
                isAddingAccounts: false,
            };
        case "ERROR_ADD_MASTER_ACCOUNTS":
            return { ...state, isAddingAccounts: false };
        case "RESET_MASTER_DATA":
            return { ...state, addAccounts: '' }

        //LT Advance
        case "REQUEST_GET_LT_ACCOUNT":
            return { ...state, isFetchingLTAccount: true };
        case "SUCCESS_GET_LT_ACCOUNT":
            return {
                ...state,
                ltAccount: action.payload.master_control,
                isFetchingLTAccount: false,
            };
        case "ERROR_GET_LT_ACCOUNT":
            return { ...state, isFetchingLTAccount: false };


        case "REQUEST_ADD_LT_ACCOUNT":
            return { ...state, isAddingLTAccount: true };
        case "SUCCESS_ADD_LT_ACCOUNT":
            return {
                ...state,
                addLTAccount: action.payload,
                isAddingLTAccount: false,
            };
        case "ERROR_ADD_LT_ACCOUNT":
            return { ...state, isAddingLTAccount: false };
        //ST Advance
        case "REQUEST_ADD_ST_ACCOUNT":
            return { ...state, isAddingSTAccount: true };
        case "SUCCESS_ADD_ST_ACCOUNT":
            return {
                ...state,
                addSTAccount: action.payload,
                isAddingSTAccount: false,
            };
        case "ERROR_ADD_ST_ACCOUNT":
            return { ...state, isAddingSTAccount: false };
            //lt recivig
            case "REQUEST_ADD_LT_RECIVING":
    return { ...state, isAddingLTReciving: true };
case "SUCCESS_ADD_LT_RECIVING":
    return {
        ...state,
        addLTReciving: action.payload,
        isAddingLTReciving: false,
    };
case "ERROR_ADD_LT_RECIVING":
    return { ...state, isAddingLTReciving: false };
    //st recivig
    case "REQUEST_ADD_ST_RECIVING":
    return { ...state, isAddingSTReciving: true };
case "SUCCESS_ADD_ST_RECIVING":
    return {
        ...state,
        addSTReciving: action.payload,
        isAddingSTReciving: false,
    };
case "ERROR_ADD_ST_RECIVING":
    return { ...state, isAddingSTReciving: false };
    //lt/st recivig ledger
    case "REQUEST_GET_INFO_LT_RECIVINGLEDGER":
    return { ...state, isFetchingLTRecivingLedger: true };
case "SUCCESS_GET_INFO_LT_RECIVINGLEDGER":
    return {
        ...state,
        ltRecivingLedger: action.payload,
        isFetchingLTRecivingLedger: false,
    };
case "ERROR_GET_INFO_LT_RECIVINGLEDGER":
    return { ...state, isFetchingLTRecivingLedger: false };
//ST RecivingLedger
case "REQUEST_GET_INFO_ST_RECIVINGLEDGER":
    return { ...state, isFetchingSTRecivingLedger: true };
case "SUCCESS_GET_INFO_ST_RECIVINGLEDGER":
    return {
        ...state,
        stRecivingLedger: action.payload,
        isFetchingSTRecivingLedger: false,
    };
case "ERROR_GET_INFO_ST_RECIVINGLEDGER":
    return { ...state, isFetchingSTRecivingLedger: false };
        //LT Ledger
        case "REQUEST_GET_INFO_LT_LEDGER":
            return { ...state, isFetchingLTLedger: true };
        case "SUCCESS_GET_INFO_LT_LEDGER":
            return {
                ...state,
                ltLedger: action.payload,
                isFetchingLTLedger: false,
            };
        case "ERROR_GET_INFO_LT_LEDGER":
            return { ...state, isFetchingLTLedger: false };
            //search lt
            case "REQUEST_SEARCH_INFO_LT_LEDGER":
                return { ...state, isFetchingSearchLTLedger: true };
            case "SUCCESS_SEARCH_INFO_LT_LEDGER":
                return {
                    ...state,
                    searchltLedger: action.payload,
                    isFetchingSearchLTLedger: false,
                };
            case "ERROR_SEARCH_INFO_LT_LEDGER":
                return { ...state, isFetchingSearchLTLedger: false };
        //ST Ledger
        case "REQUEST_GET_INFO_ST_LEDGER":
            return { ...state, isFetchingSTLedger: true };
        case "SUCCESS_GET_INFO_ST_LEDGER":
            return {
                ...state,
                stLedger: action.payload,
                isFetchingSTLedger: false,
            };
        case "ERROR_GET_INFO_ST_LEDGER":
            return { ...state, isFetchingSTLedger: false };
            //search
            case "REQUEST_SEARCH_INFO_ST_LEDGER":
                return { ...state, isFetchingSearchSTLedger: true };
            case "SUCCESS_SEARCH_INFO_ST_LEDGER":
                return {
                    ...state,
                    searchstLedger: action.payload,
                    isFetchingSearchSTLedger: false,
                };
            case "ERROR_SEARCH_INFO_ST_LEDGER":
                return { ...state, isFetchingSearchSTLedger: false };


        case "REQUEST_GENERATE_SALARY":
            return {
                ...state,
                isFetchingIndividualSalary: true
            };
        case "SUCCESS_GENERATE_SALARY":
            return {
                ...state,
                indSalary: action.payload,
                isFetchingIndividualSalary: false,
            };
        case "ERROR_GENERATE_SALARY":
            return { ...state };

        //Master Control
        case "REQUEST_GET_MASTER_SELECTED_ACCOUNTS":
            return { ...state, isFetchingSelectedMasterAccounts: true };
        case "SUCCESS_GET_MASTER_SELECTED_ACCOUNTS":
            console.log(action.payload, "selectedMasterData")
            return {
                ...state,
                selectedMasterData: action.payload,
                isFetchingSelectedMasterAccounts: false,
            };
        case "ERROR_GET_MASTER_SELECTED_ACCOUNTS":
            return { ...state, isFetchingSelectedMasterAccounts: false };
            //GET LT LEDGER
            case "REQUEST_GET_LT_LEDGER":
                return { ...state, isFetchingLtLedger: true };
            case "SUCCESS_GET_LT_LEDGER":
                console.log(action.payload, "selectedMasterData")
                return {
                    ...state,
                    ltLedger: action.payload,
                    isFetchingLtLedger: false,
                };
            case "ERROR_GET_LT_LEDGER":
                return { ...state, isFetchingLtLedger: false };
                     //GET ST LEDGER
            case "REQUEST_GET_ST_LEDGER":
                return { ...state, isFetchingStLedger: true };
            case "SUCCESS_GET_ST_LEDGER":
                console.log(action.payload, "selectedMasterData")
                return {
                    ...state,
                    stLedger: action.payload,
                    isFetchingStLedger: false,
                };
            case "ERROR_GET_ST_LEDGER":
                return { ...state, isFetchingStLedger: false };

                case "REQUEST_GET_EMPLOYEE_LEAVES_DATA_BY_ID":
                    return { ...state, isFetchingEmployeeLeavesData: true };
                case "SUCCESS_GET_EMPLOYEE_LEAVES_DATA_BY_ID":
                    console.log(action.payload, "selectedMasterData")
                    return {
                        ...state,
                        employeeLeaveData: action.payload,
                        isFetchingEmployeeLeavesData: false,
                    };
                case "ERROR_GET_EMPLOYEE_LEAVES_DATA_BY_ID":
                    return { ...state, isFetchingEmployeeLeavesData: false };
        default:
            return state;
    }
};
export default HrReducer;
