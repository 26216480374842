import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Container,
  Button,
  Alert,
  Tabs,
  Tab,
} from "react-bootstrap";
import "./add-levels.css";
import {
  AddLevel4Name,
  getLevel4Data,
  getLevel1Data,
  getLevel2Data,
  getLevel3Data,
  getLevelOneName,
  getLevelTwoName,
} from "./accountsLevel.action";
import { connect } from "react-redux";
import { isEmpty, map, find, sortBy } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import ReactExport from "react-export-excel";
import PDFLevel4 from "./PDFLevel4";
import { PDFDownloadLink } from "@react-pdf/renderer";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const Level4Report = (props) => {
  const level4Data = props.level4List;
  useEffect(() => {
    props.getLevel4Data();
  }, []);
  return (
    <>
      {props.isFetchinglevel4List ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <ExcelFile
              element={
                <button className="sendButton" style={{ marginRight: "5%" }}>
                  Generate Excel
                </button>
              }
            >
              <ExcelSheet
                data={map(level4Data, (lev) =>
                  Object.assign(
                    {},
                    ...(function flatten(o) {
                      return [].concat(
                        ...Object.keys(o).map((k) =>
                          typeof o[k] === "object"
                            ? flatten(o[k])
                            : { [k]: o[k] }
                        )
                      );
                    })(lev)
                  )
                )}
                name="Level3Report"
              >
                <ExcelColumn label="level 1 code" value="key" />
                <ExcelColumn label="level 1 name" value="name" />
                <ExcelColumn label="level 2 code" value="levelTwoKey" />
                <ExcelColumn label="level 2 name" value="levelTwoName" />
                <ExcelColumn label="level 3 code" value="levelThreeKey" />
                <ExcelColumn label="level 3 name" value="levelThreeName" />
                <ExcelColumn label="level 4 code" value="allLevlKey" />
                <ExcelColumn label="level 4 name" value="levelFourName" />
              </ExcelSheet>
            </ExcelFile>

            <button className="sendButton">
              <PDFDownloadLink
                document={
                  <PDFLevel4
                    array={sortBy(props.level4List, [
                      "props.level4List",
                      "levelFourData.allLevlKey",
                    ])}
                  />
                }
                fileName="Level4.pdf"
              >
                {({ blob, url, loading, error }) =>
                  loading ? "Loading document..." : "Download now!"
                }
              </PDFDownloadLink>
            </button>
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLevel4Data: () => dispatch(getLevel4Data()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  level4List: state.accountsLevelReducer.level4List,
  isFetchinglevel4List: state.accountsLevelReducer.isFetchinglevel4List,
});

export default connect(mapStateToProps, mapDispatchToProps)(Level4Report);
