import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button } from "react-bootstrap";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { isEmpty, get } from "lodash";
import {
  getEmployeeData,
  getEmployeeDataWithStatus,
  getInfoSTLedger,
  searchInfoSTLedger,
} from "./HRDetails.actions";
import Select from "react-select";
import { Redirect, Link } from "react-router-dom";
import MaterialTable from "material-table";
import { appendNonSignificantZeros } from "../../../../../global/globalFunctions";
import { baseURL, headers } from "../../../../../global/api";
import jsPDF from "jspdf";
import logo from "../../../../../Images/logo.png";
import swal from "sweetalert";

const HRSTLedgerReport = (props) => {
  const [show, setShow] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [employeeId, setEmployeeId] = useState("");
  console.log(employeeId, "employeeId");
  const [visibleAlert, setAlertVisible] = useState(false);

  const handleVisible = () => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    swal({
      title: "Warning",
      text: "No Data Found",
      icon: "info",
      button: "Ok",
    });
  };
  /* const [state, setState] = React.useState({
     columns: [
       { title: 'Account Name', field: 'acc_name' },
       { title: 'Account Type', field: 'acc_type' },
       { title: 'Opening Balance', field: 'opening_balance' },
       { title: 'Credit Value', field: 'credit_value' },
       { title: 'Debit Value', field: 'debit_value' },
       { title: 'Closing Balance', field: 'closing_balance' },
       { title: 'Date', field: 'short_term_ledger_date' }
     ],
   });*/

  // const getEmployeeList = !isEmpty(props.employeeList) ? props.employeeList.map((x) => { let data = { value: x.employeeId, label: `${x.employeeId} ${x.name}` }; return data }) : [];
  // !isEmpty(getEmployeeList) && getEmployeeList.push({ value: "All", label: "All" });

  const getEmployeeList = !isEmpty(props.activeEmployeeList)
    ? props.activeEmployeeList.map((x) => {
        let data = {
          value: x.employeeId,
          label: `${appendNonSignificantZeros(x.employeeId)}-${x.name}`,
        };
        return data;
      })
    : [];
  !isEmpty(getEmployeeList) &&
    getEmployeeList.push({ value: "All", label: "All" });

  const handleChangeEmployee = (selectedOption) => {
    setEmployeeId(selectedOption.value);
    setShow(false);
  };

  const STLedger = props.stLedger;
  console.log(STLedger, "stledger");
  // const exportPDF = () => {
  //   props.searchAccountLedgerReport({
  //     startedDate: !isEmpty(startDate) ? startDate : "",
  //     endDate: !isEmpty(endDate) ? endDate : "",
  //     employeeId: employeeId,
  //   }, startDate, endDate);
  // }
  useEffect(() => {
    props.getEmployeeData();
    props.getEmployeeDataWithStatus(1);
    setShow(false);
  }, []);
  const clearStates = () => {
    setEmployeeId("");
    // setEndDate('');
    // setStartDate('');
  };

  function getData() {
    props.getInfoSTLedger(
      {
        startedDate: !isEmpty(startDate) ? startDate : "",
        endDate: !isEmpty(endDate) ? endDate : "",
        employee_id: employeeId,
      },
      handleVisible
    );
    clearStates();
  }
  function searchData() {
    props.searchInfoSTLedger({
      startedDate: !isEmpty(startDate) ? startDate : "",
      endDate: !isEmpty(endDate) ? endDate : "",
      employee_id: employeeId,
    });
    clearStates();
    setShow(true);
  }

  return (
    <>
      {props.isFetchingEmployee || props.isFetchingSearchSTLedger ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {
              "Loading" //props.isFetchingEmployeeList ? 'Loading...' : 'Loading...'}
            }
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Row>
              <Col lg="4" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b> Start Date </b>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    onChange={(e) => setStartDate(e.target.value)}
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col lg="4" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b> End Date</b>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    onChange={(e) => setEndDate(e.target.value)}
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col lg="4" md="12" sm="12" style={{ zIndex: 1 }}>
                <Form.Group>
                  <Form.Label>
                    <b> Select Employee </b>
                  </Form.Label>
                  <Select
                    style={{ zIndex: 1 }}
                    placeholder="Select Employee..."
                    onChange={handleChangeEmployee}
                    options={getEmployeeList}
                    isDisabled={isEmpty(getEmployeeList)}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col lg="12" sm="12" className="text-center">
                {
                  employeeId == "" ? (
                    <Button
                      disabled
                      style={{ backgroundColor: "black" }}
                      className="sendButton"
                    >
                      Search
                    </Button>
                  ) : (
                    // <Link to= {{pathname : "/user/Ledger-List",
                    // state: {start: startDate, end: endDate}}}>
                    <Button className="sendButton" onClick={() => searchData()}>
                      Search
                    </Button>
                  )
                  // </Link>
                }
              </Col>
            </Row>

            <div className="sendDiv" style={{ marginTop: "15px" }}>
              {employeeId == "" ? (
                <Button
                  disabled
                  style={{ backgroundColor: "black" }}
                  className="sendButton"
                >
                  Generate Ledger Report
                </Button>
              ) : (
                <Button className="sendButton" onClick={() => getData()}>
                  Generate Ledger Report
                </Button>
              )}
            </div>
            {console.log(props.searchstLedger, "props.stLedger")}
            {show && (
              <MaterialTable
                title={`${
                  isEmpty(props.searchstLedger.data)
                    ? ""
                    : props.searchstLedger.data[0].employee_id
                }-${
                  isEmpty(props.searchstLedger.data)
                    ? ""
                    : props.searchstLedger.data[0].acc_name
                } (${
                  isEmpty(props.searchstLedger.data)
                    ? ""
                    : props.searchstLedger.data[0].department
                }/${
                  isEmpty(props.searchstLedger.data)
                    ? ""
                    : props.searchstLedger.data[0].designation
                }) ST Ledger`}
                columns={[
                  // { title: 'Emloyee Id', field: 'employee_id' },
                  // { title: 'Account Name', field: 'acc_name' },
                  // { title: 'Account Type', field: 'acc_type' },
                  { title: "Date", field: "short_term_ledger_date" },
                  { title: "Description", field: "short_term_description" },
                  { title: "Advance Debit", field: "debit_value" },
                  { title: "Advance credit ", field: "credit_value" },

                  { title: "Closing Balance", field: "closing_balance" },
                ]}
                data={props.searchstLedger.data}
                //   data={
                //     // STLedger
                //     (query) =>
                //     new Promise((resolve, reject) => {
                //         let url = `${baseURL}short_term_adv/get_st_employee_ledger?`;
                //         url += "size=" + query.pageSize;
                //         url += "&page=" + (query.page + 1);
                //         url += "&search=" + query.search;
                //         fetch(url, {
                //             method: 'GET',
                //             headers: headers,
                //         })
                //             .then((response) => response.json())
                //             .then((result) => {
                //                 resolve({
                //                     data: result.data,
                //                     page: result.page - 1,
                //                     totalCount: result.total_results,
                //                 });
                //             });
                //     })

                //   }
                options={{
                  actionsColumnIndex: -1,
                  filtering: false,
                  exportButton: true,
                  exportAllData: true,
                  emptyRowsWhenPaging: false,
                  exportPdf: (columns, data) => {
                    const doc = new jsPDF();
                    //render:rowData=>rowData.hall_name
                    const columnTitles = [
                      {
                        title: "Account Name",
                        field: "acc_name",
                        render: (rowData) => rowData.acc_name,
                      },
                      // { title: 'Account Type', field: 'acc_type' },
                      {
                        title: "Description",
                        field: "short_term_description",
                        render: (rowData) => rowData.short_term_description,
                      },
                      {
                        title: "Advance Given",
                        field: "credit_value",
                        render: (rowData) => rowData.credit_value,
                      },
                      {
                        title: "Advance Deduction",
                        field: "debit_value",
                        render: (rowData) => rowData.debit_value,
                      },
                      {
                        title: "Closing Balance",
                        field: "closing_balance",
                        render: (rowData) => rowData.closing_balance,
                      },
                      {
                        title: "Date",
                        field: "short_term_ledger_date",
                        render: (rowData) => rowData.short_term_ledger_date,
                      },
                    ].map((columnDef) => columnDef.title);
                    const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
                    const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
                    const companyNtn = `${process.env.REACT_APP_COMPANY_NTN}`;
                    const pdfData = data.map((rowData) => [
                      rowData.acc_name,
                      rowData.short_term_description,
                      rowData.credit_value,
                      rowData.debit_value,
                      rowData.closing_balance,
                      rowData.short_term_ledger_date,
                    ]);
                    let content = {
                      startY: 50,
                      startX: 5,
                      head: [columnTitles],
                      body: pdfData,
                    };
                    doc.addImage(logo, "PNG", 10, 1, 50, 50);
                    doc.setFontSize(20);
                    doc.setFont(undefined, "bold");
                    doc.text(companyName, 50, 22, 0, 0);
                    doc.setFontSize(13);
                    doc.setFont("TimesNewRoman", "Normal");
                    doc.text(companyAddress, 50, 29, 0, 0);
                    doc.text(companyNtn, 50, 36, 0, 0);
                    doc.autoTable(content);
                    doc.save(`HRST Ledger Table.pdf`);
                  },
                  maxBodyHeight: "600px",
                  headerStyle: {
                    position: "sticky",
                    top: 0,
                    color: "#00BBBB",
                    fontWeight: "550",
                    onRowAdd: "none",
                  },
                }}
              />
            )}
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getEmployeeData: () => dispatch(getEmployeeData()),
    getInfoSTLedger: (data, handleVisible) =>
      dispatch(getInfoSTLedger(data, handleVisible)),
    searchInfoSTLedger: (data) => dispatch(searchInfoSTLedger(data)),
    // searchAccountLedger: (data) => dispatch(searchAccountLedger(data)),
    // searchAccountLedgerReport: (data, startDate, endDate) => dispatch(searchAccountLedgerReport(data, startDate, endDate)),
    getEmployeeDataWithStatus: (status) =>
      dispatch(getEmployeeDataWithStatus(status)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  employeeList: state.HrReducer.employeeList,
  isFetchingEmployeeData: state.HrReducer.isFetchingEmployeeData,
  stLedger: state.HrReducer.stLedger,
  isFetchingSTLedger: state.HrReducer.isFetchingSTLedger,
  searchstLedger: state.HrReducer.searchstLedger,
  isFetchingSearchSTLedger: state.HrReducer.isFetchingSearchSTLedger,
  level5LedgerData: state.AccountLegderReducer.level5LedgerData,
  searchAccountLedgerList: state.AccountLegderReducer.searchAccountLedgerList,
  isFetchingLevel5LedgerData:
    state.AccountLegderReducer.isFetchingLevel5LedgerData,
  isFetchingSearchAccountLedger:
    state.AccountLegderReducer.isFetchingSearchAccountLedger,

  activeEmployeeList: state.HrReducer.activeEmployeeList,
  isFetchingActiveEmployeeData: state.HrReducer.isFetchingActiveEmployeeData,
});

export default connect(mapStateToProps, mapDispatchToProps)(HRSTLedgerReport);
