import React, { useEffect, useState } from "react";
import {
  InputGroup,
  Row,
  Col,
  Form,
  Container,
  Button,
  FormGroup,
  Modal,
} from "react-bootstrap";
import {
  getLevel5LedgerData,
  searchAccountLedger,
  searchAccountLedgerReport,
} from "../ledger/Ledger.action";
import { connect } from "react-redux";
import moment from "moment";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import VisibilityIcon from "@material-ui/icons/Visibility";
import "./style.css";
import { isUndefined, isEmpty, get } from "lodash";
import Select from "react-select";
import { startFinancialDate } from "../../../../global/globalFunctions";

const AccountLedger = (props) => {
  const [show, setShow] = useState(false);
  // const [warehouse, setWarehouse] = useState();
  // //const handleClose = () => setShow(false);
  // const [wareHouseId, setwareHouseId] = useState();
  // const [categoryValue, setcategoryValue] = useState();
  // const [inventoryData, setinventoryData] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [item, setItem] = useState("");
  const [itemCode, setItemCode] = useState();

  console.log(item, "item");

  const getLevel5LedgerData = props.level5LedgerData.map((x) => {
    let data = {
      value: x.levelFiveId,
      label: x.allLevelKey + "" + x.levelFiveName,
    };
    return data;
  });
  const handleChangeLevel5Ledger = (selectedOption) => {
    setItem(selectedOption.value);
  };

  const handleShow = () => setShow(true);

  function handleClose() {
    // props.getSectorsSummaryGetData({
    //     summarySectorId: sector
    //     });
    setShow(false);
  }
  const exportPDF = () => {
    props.searchAccountLedgerReport(
      {
        startedDate: !isEmpty(startDate) ? startDate : "",
        endDate: !isEmpty(endDate) ? endDate : "",
        levelFiveId: item,
      },
      startDate,
      endDate
    );
  };

  const getledger = props.searchAccountLedgerList;
  const name = get(getledger[0], "accName", "");
  //const getLedgerCode = !isUndefined(props.ledgerlist) && props.ledgerlist.find(x => x.metrialName == ledger).metrialCode;
  console.log(name, "code");

  const [open, setOpen] = React.useState(false);
  /* const [state, setState] = React.useState({
    columns: [
      { title: 'Date', field: 'createdAt', render: rowData => moment(rowData.createdAt).format("YYYY-MM-DD") },
      // {title: "Opening Balance", field: "opening_balance"},
      {title: "Credit Amount", field: "credit"},
      {title: "Debit Amount", field: "debit"},
      {title: "Closing Balance", field: "accBalance"},
      { title: 'DR/CR', field: 'accType', render: rowData => rowData.accType == 2 ? "Debit" : "Credit" },
      // {title: "Opening Balance", field: "levelFiveId"}
      // { title: 'Date', field: 'updatedAt', render: rowData => moment(rowData.updatedAt).format("YYYY-MM-DD") },
      // { title: 'Account', field: 'accBalance', render: rowData => rowData.allLevelKey + " /" + rowData.levelFiveName },
      // { title: 'Debit', field: 'Debit', render: rowData => !isUndefined || !isEmpty(rowData.trial_bals) && rowData.debit_credit.debitCreditId == 2 ? rowData.trial_bals.map((x) => x.transDebit).reduce((a, b) => a + b) : 0 },
      // { title: 'Credit', field: 'Credit', render: rowData =>  !isUndefined || !isEmpty(rowData.trial_bals) && rowData.debit_credit.debitCreditId == 1 ? rowData.trial_bals.map((x) => x.transCredit).reduce((a, b) => a + b) : 0 },
      // // { title: 'Debit', field: 'Debit', render: rowData => !isUndefined || !isEmpty(rowData.trial_bals) ? rowData.trial_bals.map((x) => x.transDebit).reduce((a, b) => a + b) : 0 },
      // // { title: 'Credit', field: 'Credit', render: rowData =>  !isUndefined || !isEmpty(rowData.trial_bals) ? rowData.trial_bals.map((x) => x.transCredit).reduce((a, b) => a + b) : 0 },
      // { title: 'Balance', field: 'levelBalance', render: rowData => Math.abs(rowData.levelBalance)},
      // { title: 'DR/CR', field: 'debit_credit.accountName' },
    ],
  });*/

  useEffect(() => {
    // props.searchAccountLedger({
    //   startedDate: "",
    //   endDate: "",
    //   item: "",
    // });

    props.getLevel5LedgerData();
  }, []);
  function searchData() {
    props.searchAccountLedger({
      startedDate: !isEmpty(startDate) ? startDate : "",
      endDate: !isEmpty(endDate) ? endDate : "",
      levelFiveId: item,
    });
  }
  console.log(props.level5LedgerData, "ooooooooooo");
  return (
    <>
      {props.isFetchingSectorsSummary ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {props.isFetchingSectorsSummary ? "Loading..." : "Loading..."}
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <div>
              <Form>
                <Row>
                  <Col lg="4" md="12" sm="12">
                    <Form.Group>
                      <Form.Label>
                        <b> Start Date </b>
                      </Form.Label>
                      <Form.Control
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col lg="4" md="12" sm="12">
                    <Form.Group>
                      <Form.Label>
                        <b> End Date</b>
                      </Form.Label>
                      <Form.Control
                        type="date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}

                        //   selected={sector}
                        // onChange={(e) => getcategory(e.target.value)}
                      >
                        {/* <option selected disabled>
                                            Select an Option
                                        </option>
                                        {!isEmpty(props.categoryList) &&
                                            props.categoryList.map((category) => (
                                                <option id={category.categoryName} value={category.categoryName}>
                                                    {category.categoryName}
                                                </option>
                                            ))} */}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col lg="4" md="12" sm="12">
                    <Form.Group>
                      <Form.Label>
                        <b> Select Account *</b>
                      </Form.Label>
                      <Select
                        placeholder="Select Account..."
                        onChange={handleChangeLevel5Ledger}
                        options={getLevel5LedgerData}
                      />
                    </Form.Group>
                  </Col>
                  {/* <Col lg="3" md="12" sm="12">
                    <Form.Group>
                      <Form.Label>
                        <b> Item Code</b>
                      </Form.Label>
                      <Form.Control
                        onChange={(e) => setItemCode(e.target.value)}
                        type="text"
                        readOnly
                      >
                      </Form.Control>
                    </Form.Group>
                  </Col> */}
                </Row>
                <Row>
                  <Col lg="12" sm="12" className="text-center">
                    {item == "" ? (
                      <Button
                        disabled
                        style={{ backgroundColor: "black" }}
                        className="sendButton"
                      >
                        Search
                      </Button>
                    ) : (
                      <Button
                        className="sendButton"
                        onClick={(e) => searchData()}
                      >
                        Search
                      </Button>
                    )}
                  </Col>
                </Row>

                <div className="sendDiv">
                  <Button className="sendButton" onClick={() => exportPDF()}>
                    Generate Ledger Report
                  </Button>
                </div>

                <MaterialTable
                  // title="Ledgers"
                  title={name == "" ? "Ledgers" : `${"Ledgers of " + name}`}
                  columns={[
                    {
                      title: "Date",
                      field: "createdAt",
                      render: (rowData) =>
                        moment(rowData.createdAt).format("YYYY-MM-DD"),
                    },
                    // {title: "Opening Balance", field: "opening_balance"},
                    { title: "Credit Amount", field: "credit" },
                    { title: "Debit Amount", field: "debit" },
                    { title: "Closing Balance", field: "accBalance" },
                    {
                      title: "DR/CR",
                      field: "accType",
                      render: (rowData) =>
                        rowData.accType == 2 ? "Debit" : "Credit",
                    },
                    // {title: "Opening Balance", field: "levelFiveId"}]
                  ]}
                  data={getledger}
                  // data={state.data}
                  //   actions={[
                  //     {
                  //     icon: VisibilityIcon,
                  //       tooltip: 'View Ware House',
                  //       onClick: (event, rowData) => {
                  //         // Do save operation
                  //       //  setcallEdit(true);

                  //       // props.selectedUser(rowData)
                  //       }
                  //     }
                  //   ]}
                  options={{
                    actionsColumnIndex: -1,
                    filtering: true,
                    exportButton: true,
                    maxBodyHeight: "600px",
                    headerStyle: {
                      position: "sticky",
                      top: 0,
                      color: "#00BBBB",
                      fontWeight: "550",
                      onRowAdd: "none",
                    },
                  }}
                  // editable={{
                  //   onRowDelete: (oldData) =>
                  //     new Promise((resolve) => {
                  //       setTimeout(() => {
                  //         resolve();
                  //         props.deleteWareHouse(oldData.wareHouseId);
                  //       }, 600);
                  //     }),
                  // }}
                />
                <>
                  <Modal show={show} onHide={handleClose}>
                    <Modal.Body>Entered Successfully..</Modal.Body>
                    <Modal.Footer>
                      <Button
                        className="sendButton"
                        style={{ float: "right" }}
                        variant="primary"
                        onClick={handleClose}
                      >
                        بند کریں
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </>
              </Form>
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLevel5LedgerData: () => dispatch(getLevel5LedgerData()),
    searchAccountLedger: (data) => dispatch(searchAccountLedger(data)),
    searchAccountLedgerReport: (data, startDate, endDate) =>
      dispatch(searchAccountLedgerReport(data, startDate, endDate)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  level5LedgerData: state.AccountLegderReducer.level5LedgerData,
  searchAccountLedgerList: state.AccountLegderReducer.searchAccountLedgerList,
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountLedger);
