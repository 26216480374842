const initialState = {

  // warehouseList : [],
  // activityList : [],
  // categoryList : [],
  // subCategory : [],
  // iventoryData: [],
  // isFetchingAllActivittList : false,
  // isFetchingwarehouseList : false,
  // isFetchingCategorydata: false,
  // isFetchingsubCategorydata: false,
  // isFetchinginventoryData: false,
  isFetchingLedgerData: false,
  ledgerlist: [],
  ledgerSearchlist: [],
  isFetchingLevel5LedgerData: false,
  level5LedgerData: [],
  isFetchingSearchAccountLedger: false,
  searchAccountLedgerList: [],
  searchAccountLedgerListReport: [],
  isFetchingSearchAccountLedgerReport: false,
};

const AccountLegderReducer = (state = initialState, action) => {
  switch (action.type) {

    //   case "REQUEST_ALL_ACTIVITY":
    //     return { ...state, isFetchingAllActivittList: true, isState: false };
    //   case "SUCCESS_ALL_ACTIVITY":

    //     return {
    //       ...state,
    //       activityList:action.payload,
    //       isFetchingAllActivittList: false, 
    //       isState: true
    //     };
    //   case "ERROR_ALL_ACTIVITY":
    //     return { ...state, isFetchingAllActivittList: false, isState: false };

    //     case "REQUEST_WARE_HOUSE_DATA":
    //     return { ...state, isFetchingwarehouseList: true, isState: false };
    //   case "SUCCESS_WARE_HOUSE_GET_DATA":

    //     return {
    //       ...state,
    //       warehouseList:action.payload,
    //       isFetchingwarehouseList: false, 
    //       isState: true
    //     };
    //   case "ERROR_WARE_HOUSE_GET_DATA":
    //     return { ...state, isFetchingwarehouseList: false, isState: false };

    //     case "REQUEST_CATEGORY_NAME":
    //   return { ...state, isFetchingCategorydata: true };
    // case "SUCCESS_CATEGORY_NAME":
    //   return {
    //     ...state,
    //     categoryList :action.payload,
    //     isFetchingCategorydata: false, 
    //   };
    // case "ERROR_CATEGORY_NAME":
    //   return { ...state, isFetchingCategorydata: false };

    //   //SUB CATEGORY
    //    case "REQUEST_SUB_CATEGORY_NAME":
    //     return { ...state, isFetchingsubCategorydata: true };
    //   case "SUCCESS_SUB_CATEGORY_NAME":
    //     return {
    //       ...state,
    //       subCategory :action.payload,
    //       isFetchingsubCategorydata: false, 
    //     };
    //   case "ERROR_SUB_CATEGORY_NAME":
    //     return { ...state, isFetchingsubCategorydata: false };

    //     case "REQUEST_IVENTORY_DATA":
    //       return { ...state, isFetchinginventoryData: true };
    //     case "SUCCESS_IVENTORY_DATA":
    //       return {
    //         ...state,
    //         iventoryData :action.payload,
    //         isFetchinginventoryData: false, 
    //       };
    //     case "ERROR_IVENTORY_DATA":
    //       return { ...state, isFetchinginventoryData: false };

    case "REQUEST_ADD_LEDGER":
      return { ...state, isFetchingLedgerData: true };
    case "SUCCESS_ADD_LEDGER":
      return {
        ...state,
        ledgerlist: action.payload.data,
        isFetchingLedgerData: false,
      };
    case "ERROR_ADD_LEDGER":
      return { ...state, isFetchingLedgerData: false };


    case "REQUEST_ADD_LEDGER_BY_DATE":
      return { ...state };
    case "SUCCESS_ADD_LEDGER_BY_DATE":
      return {
        ...state,
        ledgerSearchlist: action.payload.data,
      };
    case "ERROR_ADD_LEDGER_BY_DATE":
      return { ...state };


    case "REQUEST_LEVEL5_LEDGER_DATA":
      return { ...state, isFetchingLevel5LedgerData: true };
    case "SUCCESS_LEVEL5_LEDGER_DATA":
      return {
        ...state,
        level5LedgerData: action.payload,
        isFetchingLevel5LedgerData: false
      };
    case "ERROR_LEVEL5_LEDGER_DATA":
      return { ...state, isFetchingLevel5LedgerData: false };


      case "REQUEST_LEVEL5_LEDGER_DATA":
      return { ...state, isFetchingLevel5LedgerData: true };
    case "SUCCESS_LEVEL5_LEDGER_DATA":
      return {
        ...state,
        level5LedgerData: action.payload,
        isFetchingLevel5LedgerData: false
      };
    case "ERROR_LEVEL5_LEDGER_DATA":
      return { ...state, isFetchingLevel5LedgerData: false };

      case "ERROR_LEVEL5_LEDGER_DATA":
      return { ...state, isFetchingLevel5LedgerData: false };


      case "REQUEST_SEARCH_LEDGER":
      return { ...state, isFetchingSearchAccountLedger: true };
    case "SUCCESS_SEARCH_LEDGER":
      return {
        ...state,
        searchAccountLedgerList: action.payload,
        isFetchingSearchAccountLedger: false
      };
    case "ERROR_SEARCH_LEDGER":
      return { ...state, isFetchingSearchAccountLedger: false };


      case "REQUEST_SEARCH_LEDGER_REPORT":
        return { ...state, isFetchingSearchAccountLedgerReport: true };
      case "SUCCESS_SEARCH_LEDGER_REPORT":
        return {
          ...state,
          searchAccountLedgerListReport: action.payload,
          isFetchingSearchAccountLedgerReport: false
        };
      case "ERROR_SEARCH_LEDGER_REPORT":
        return { ...state, isFetchingSearchAccountLedgerReport: false };



      return { ...state };
    default:
      return state;
  }
};
export default AccountLegderReducer;
