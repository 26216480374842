import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, FormGroup, Tabs, Tab, Alert } from "react-bootstrap";
import "./add-details.css";
import {
    getCategoryData, addCategoryName, deleteCategory, getWarehouseData, getsubCategoryData, addInventory, getInventory
    , deleteInventory, updateInventory, getAttributeById, resetReducer, uploadFile, getAllInventory, searchInventoryReport
} from "./AddDetails.actions";
import { connect } from "react-redux";
import { isEmpty, get, find, chain, uniqBy, isNull } from "lodash";
import moment from 'moment';
import Spinner from 'react-bootstrap/Spinner';
import MaterialTable from 'material-table';
import logo from "../../../../Images/logo.png";
import jsPDF from 'jspdf';
import Select from 'react-select';
import Swal from 'sweetalert';

const InventoryReport = (props) => {
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [wareHouseId, setwareHouseId] = useState();
    const [wareHouseId1, setwareHouseId1] = useState();
    const [visibleAlert, setAlertVisible] = useState(false);

    const handleVisible = () => {
        setAlertVisible(true)
        setTimeout(() => {
            setAlertVisible(false)
        }, 9000);
        Swal({
            title: "Data Not Found",
            text: "Data Not Found",
            icon: "info",
            button: "Ok",
        });
    }
    const handleChangeWareHouse = (selectedOption) => {
        setwareHouseId(selectedOption.value);
        setwareHouseId1(selectedOption.label);
    };

    const exportPDF = () => {
        props.searchInventoryReport({
            startedDate: !isEmpty(startDate) ? startDate : "",
            endDate: !isEmpty(endDate) ? endDate : "",
            wareHouseId: wareHouseId
        }, wareHouseId1, startDate, endDate, handleVisible);

    }

    const getWareHouseList = props.warehouseList.map((x) => { let data = { value: x.wareHouseId, label: x.wareHouseName }; return data });

    return (
        <>
            {props.isFetchingwareHouseList || props.isFetchingSearchInventoryList ? (
                <div className="loader-div">
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />
                        {props.isFetchingSearchInventoryList ? 'Downloading PDF...' : 'Loading...'}

                    </Button>
                </div>
            ) : (
                <Container fluid>
                    <div className="main">
                        <Row>
                            <Col xl="4" lg="4" md="6" sm="6">
                                <FormGroup>
                                    <Form.Label>
                                        <b>Start Date :</b>
                                    </Form.Label>
                                    <Form.Control
                                        type="date"
                                        value={startDate}
                                        onChange={(e) => setStartDate(e.target.value)}
                                    >
                                    </Form.Control>
                                </FormGroup>
                            </Col>
                            <Col xl="4" lg="4" md="6" sm="6">
                                <FormGroup>
                                    <Form.Label>
                                        <b>End Date</b>
                                    </Form.Label>
                                    <Form.Control
                                        type="date"
                                        value={endDate}
                                        onChange={(e) => setEndDate(e.target.value)}
                                    >
                                    </Form.Control>
                                </FormGroup>
                            </Col>
                            <Col xl="4" lg="4" md="12" sm="12">
                                <Form.Group>
                                    <Form.Label>
                                        <b> Select Warehouse </b>
                                    </Form.Label>
                                    <Select
                                        placeholder="Select WareHouse..."
                                        onChange={(e) => handleChangeWareHouse(e)}
                                        options={getWareHouseList}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <div className="sendDiv">
                            {isEmpty(wareHouseId1) ? <Button disabled style={{ marginLeft: "2%", color: "white", width: "20%", backgroundColor: "black", border: "1px solid black" }}>Generate Inventory Report</Button> : props.isFetchingSearchInventoryList ? <Button className="sendButton">Downloading PDF</Button> : <Button className="sendButton" onClick={() => exportPDF()}>Generate Inventory Report</Button>}
                        </div>
                    </div>
                </Container>
            )}
        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
        getWarehouseData: () => dispatch(getWarehouseData()),
        resetState: () => dispatch(resetReducer()),
        searchInventoryReport: (data, name, startDate, endDate, handleVisible) => dispatch(searchInventoryReport(data, name, startDate, endDate, handleVisible))
    };
};
const mapStateToProps = (state, ownProps) => ({
    warehouseList: state.AddDataReducer.warehouseList,
    searchInventoryList: state.AddDataReducer.searchInventoryList,
    isFetchingSearchInventoryList: state.AddDataReducer.isFetchingSearchInventoryList
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InventoryReport);