import React, { useEffect, useState } from "react";
import {
  InputGroup,
  Row,
  Col,
  Form,
  Table,
  Container,
  Button,
  FormGroup,
  Modal,
  Card,
  Tabs,
  Tab,
} from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty, get, map, find, isUndefined, filter, sumBy } from "lodash";
import moment from "moment";
import Spinner from "react-bootstrap/Spinner";
import "../Vouchers/style.css";
import Logo from "../../../../Images/logo.png";
import MaterialTable from "material-table";
import Select from "react-select";
import Swal from "sweetalert";
import { previewVoucher, mergeNames } from "../../../../global/globalFunctions";
import {
  getAllVoucherType,
  addCashPayment,
  getAllVoucher,
  submitJournal,
  deleteJournal,
  resetFunction4,
} from "./Voucher.action";
import { getcreditdebit } from "../accountsLevels/accountsLevel.action";

const JournalVoucher = (props) => {
  const [voucher, setVoucher] = useState();
  const [datestate, setDatestate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
  const [trackingNo, setTrackingNo] = useState("");
  const [destination, setDestination] = useState("");
  const [packages, setPackages] = useState("");
  const [weight, setWeight] = useState(0);
  const [weightCharges, setWeightCharges] = useState(0);
  const [rateFirstKg, setRateFirstKg] = useState(0);
  const [rateAddKg, setRateAddKg] = useState(0);
  const [rateFlat, setRateFlat] = useState(0);
  const [service, setService] = useState("");
  const handleVisible = (data) => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: "Added successfully...",
      icon: "success",
      buttons: ["Cancel", "Print Voucher"],
    }).then((willPrint) => {
      if (willPrint) {
        //subPage()
        previewVoucher(data, "Pending");
      } else {
        // setShowDialog(false)
      }
    });
  };

  const handleDangerVisible = () => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: "Something went wrong...",
      icon: "error",
      button: "Ok",
    });
  };
  const [values, setValues] = useState({
    voucherDescription: "",
    voucherAmount: "",
    PONo: "",
    billNo: "",
    accountTittle: "",
    payTo: "",
    levelFiveId: "",
    voucherDate: "",
    accountType: "-1",
    credit: "",
    debit: "",
    trackingNo: "",
    destination: "",
    packages: "",
    weight: 0,
    weightCharges: 0,
    rateFirstKg: 0,
    rateAddKg: 0,
    rateFlat: 0,
    service: "",
  });

  // const inputEvent = (event) => {
  //   setValues({
  //     ...values,
  //     [event.target.name]: event.target.value,
  //   });
  // };

  const inputEvent = (event) => {
    event.target.name == "voucherDate" && setDatestate(event.target.value);
    if (event.target.name == "voucherAmount") {
      setValues({
        ...values,
        [event.target.name]: event.target.value,
        debit: Number(event.target.value),
        credit: Number(event.target.value),
      });
    } else {
      setValues({
        ...values,
        [event.target.name]: event.target.value,
      });
    }
  };

  const cashAccountNo =
    !isEmpty(props.level5List) &&
    props.level5List
      .filter(
        (x) => !(x.levelFour.levelFourId == 1 || x.levelFour.levelFourId == 2)
      )
      .map((x) => {
        let data = {
          value: x.levelfiveData.levelFiveId,
          label:
            x.levelfiveData.allLevelKey + " /" + x.levelfiveData.levelFiveName,
        };
        return data;
      });

  const inputEventAccount = (selectedOption, type) => {
    setValues({
      levelFiveId: selectedOption.value,
    });
  };

  const inputEvent1Account = (selectedOption) => {
    setValues({
      ...values,
      ["levelFiveId"]: selectedOption.value,
      ["accountTittle"]: get(
        find(
          props.level5List,
          (x) => x.levelfiveData.levelFiveId == selectedOption.value
        ),
        "levelfiveData.levelFiveName",
        0
      ),
    });
  };

  const addPayment = () => {
    let newVal = values;
    if (!isEmpty(datestate)) newVal.voucherDate = datestate;
    props.submitJournal(newVal);
    setValues({
      voucherDescription: newVal.voucherDescription,
      voucherAmount: newVal.voucherAmount,
      PONo: "",
      billNo: newVal.billNo,
      accountTittle: "",
      //  payTo: "",
      levelFiveId: "",
      voucherDate: "",
      accountType: "-1",
      credit: "",
      debit: "",
      trackingNo: values.trackingNo,
      destination: values.destination,
      packages: values.packages,
      weight: values.weight,
      weightCharges: values.weightCharges,
      rateFirstKg: values.rateFirstKg,
      rateAddKg: values.rateAddKg,
      rateFlat: values.rateFlat,
      service: values.service,
    });
  };

  const add = () => {
    props.addCashPayment(
      {
        voucherTypeId: "5",
        voucher: voucher,
        levelFiveId: cash.map((x) => {
          return x.datajournal.levelFiveId;
        }),
        data: cash.map((x) => {
          return x.datajournal;
        }),
        voucherType: "JV",
        // trackingNo:trackingNo,
        // destination:destination,
        // packages:packages,
        // weight:weight,
        // weightCharges:weightCharges,
        // rateFirstKg:rateFirstKg,
        // rateAddKg:rateAddKg,
        // rateFlat:rateFlat,
        // service:service
      },
      handleVisible,
      handleDangerVisible
    );
    props.resetFunction4();
  };

  useEffect(() => {
    props.getcreditdebit();
  }, []);

  const cash = props.journal;
  let creditSum = 0,
    debitSum = 0;

  cash.map((x) => {
    if (x.datajournal.accountType == 1) {
      creditSum = creditSum + Number(x.datajournal.voucherAmount);
    } else if (x.datajournal.accountType == 2) {
      debitSum = debitSum + Number(x.datajournal.voucherAmount);
    }
  });

  const [state, setState] = React.useState({
    columns: [
      { title: "Date", field: "datajournal.voucherDate" },
      { title: "Account Name", field: "datajournal.accountTittle" },
      { title: "Bill No", field: "datajournal.billNo" },
      { title: "Description", field: "datajournal.voucherDescription" },
      { title: "Amount", field: "datajournal.voucherAmount" },
      {
        title: "DR/CR",
        field: "datajournal.accountType",
        render: (rowData) =>
          rowData.datajournal.accountType == 1
            ? "Credit"
            : rowData.datajournal.accountType == 2
            ? "Debit"
            : "---",
      },
    ],
  });
  return (
    <>
      {props.isFetchinglevel5List ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Card style={{ padding: "3%" }}>
              <Row>
                <Row>
                  <Col lg="3" sm="12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Date *</Form.Label>
                      <Form.Control
                        type="date"
                        name="voucherDate"
                        value={datestate}
                        max={moment(new Date()).format("YYYY-MM-DD")}
                        //value={values.voucherDate}
                        onChange={inputEvent}
                        disabled={!isEmpty(props.journal)}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="3" sm="12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>DEBIT</Form.Label>
                      <Form.Control
                        type="text"
                        name="voucherDate"
                        value={sumBy(
                          filter(cash, (x) => x.datajournal.accountType == "2"),
                          (item) => Number(item.datajournal.voucherAmount)
                        )}
                        disabled={true}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="3" sm="12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>CREDIT</Form.Label>
                      <Form.Control
                        type="text"
                        name="voucherDate"
                        value={sumBy(
                          filter(cash, (x) => x.datajournal.accountType == "1"),
                          (item) => Number(item.datajournal.voucherAmount)
                        )}
                        disabled={true}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="3" sm="12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Difference</Form.Label>
                      <Form.Control
                        type="text"
                        name="voucherDate"
                        value={Math.abs(
                          sumBy(
                            filter(
                              cash,
                              (x) => x.datajournal.accountType == "1"
                            ),
                            (item) => Number(item.datajournal.voucherAmount)
                          ) -
                            sumBy(
                              filter(
                                cash,
                                (x) => x.datajournal.accountType == "2"
                              ),
                              (item) => Number(item.datajournal.voucherAmount)
                            )
                        )}
                        isInvalid={
                          !Math.abs(
                            sumBy(
                              filter(
                                cash,
                                (x) => x.datajournal.accountType == "1"
                              ),
                              (item) => Number(item.datajournal.voucherAmount)
                            ) ==
                              sumBy(
                                filter(
                                  cash,
                                  (x) => x.datajournal.accountType == "2"
                                ),
                                (item) => Number(item.datajournal.voucherAmount)
                              )
                          )
                        }
                        disabled={true}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Row>
              <div style={{ padding: "1% 0" }}>
                <Row>
                  <Col lg="6" sm="12">
                    <Form.Group className="mb-3" controlId="accountId">
                      <Form.Label>Account *</Form.Label>
                      <Select
                        value={{ label: values.accountTittle }}
                        placeholder="Select Cash Account No..."
                        onChange={(inputEventAccount, inputEvent1Account)}
                        options={cashAccountNo}
                        //  isOptionDisabled = {(option) => isUndefined(cash[0]) ? false : option.value== cash[0].datajournal.levelFiveId}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="6" sm="12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Account Type *</Form.Label>
                      <Form.Control
                        as="select"
                        name="accountType"
                        value={values.accountType}
                        onChange={inputEvent}
                      >
                        <option value="-1" selected>
                          Select an option
                        </option>
                        <option value="2">Debit</option>
                        <option value="1">Credit</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
              </div>
              <div style={{ padding: "1% 0" }}>
                <Row>
                  <Col lg="2" sm="12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Bill</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Bill No"
                        name="billNo"
                        value={values.billNo}
                        onChange={inputEvent}

                        //    isOptionDisabled={(option) => (option.levelFourData.levelFourId == 1 || option.levelFourData.levelFourId == 2 )}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="7" sm="12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter description"
                        name="voucherDescription"
                        value={values.voucherDescription}
                        onChange={inputEvent}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="3" sm="12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Amount *</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Enter Amount"
                        name="voucherAmount"
                        value={values.voucherAmount}
                        onChange={inputEvent}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xl="4" lg="4" md="4" sm="6">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Tracking No</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Tracking No"
                        id="trackingNo"
                        name="trackingNo"
                        value={values.trackingNo}
                        onChange={inputEvent}
                      />
                    </Form.Group>
                  </Col>
                  <Col xl="4" lg="4" md="4" sm="6">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Destination</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter destination"
                        id="destination"
                        name="destination"
                        value={values.destination}
                        onChange={inputEvent}
                      />
                    </Form.Group>
                  </Col>
                  <Col xl="4" lg="4" md="4" sm="6">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Packages</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Packages"
                        id="packages"
                        name="packages"
                        value={values.packages}
                        // min={0}
                        onChange={inputEvent}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xl="4" lg="4" md="4" sm="6">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Weight</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Enter Weight"
                        id="weight"
                        name="weight"
                        value={values.weight}
                        onChange={inputEvent}
                      />
                    </Form.Group>
                  </Col>
                  <Col xl="4" lg="4" md="4" sm="6">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Weight Charges</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Enter Weight Charges"
                        id="weightCharges"
                        name="weightCharges"
                        value={values.weightCharges}
                        onChange={inputEvent}
                      />
                    </Form.Group>
                  </Col>
                  <Col xl="4" lg="4" md="4" sm="6">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Rate First Kg</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Enter Rate First Kg"
                        id="rateFirstKg"
                        name="rateFirstKg"
                        value={values.rateFirstKg}
                        // min={0}
                        onChange={inputEvent}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xl="4" lg="4" md="4" sm="6">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Rate Add Kg</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Enter Rate Add Kg"
                        id="rateAddKg"
                        name="rateAddKg"
                        value={values.rateAddKg}
                        onChange={inputEvent}
                      />
                    </Form.Group>
                  </Col>
                  <Col xl="4" lg="4" md="4" sm="6">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Rate Flat</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Enter Rate Flat"
                        id="rateFlat"
                        name="rateFlat"
                        value={values.rateFlat}
                        onChange={inputEvent}
                      />
                    </Form.Group>
                  </Col>
                  <Col xl="4" lg="4" md="4" sm="6">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Service</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Service"
                        id="service"
                        name="service"
                        value={values.service}
                        // min={0}
                        onChange={inputEvent}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>

              <Row>
                <Col lg="6" sm="12" className="text-center">
                  {datestate == "" ||
                  values.voucherAmount == "" ||
                  values.accountType == "-1" ||
                  values.levelFiveId == "" ||
                  datestate > moment(new Date()).format("YYYY-MM-DD") ? (
                    <Button
                      className="sendButtonVoucher"
                      disabled
                      style={{ color: "black" }}
                    >
                      ADD
                    </Button>
                  ) : (
                    <Button className="sendButtonVoucher" onClick={addPayment}>
                      ADD
                    </Button>
                  )}
                </Col>
                <Col lg="6" sm="12">
                  {creditSum !== 0 &&
                  debitSum !== 0 &&
                  creditSum == debitSum ? (
                    <Button
                      className="sendButton"
                      style={{ marginLeft: "2%" }}
                      onClick={add}
                    >
                      POST
                    </Button>
                  ) : (
                    <Button
                      disabled
                      style={{
                        marginLeft: "2%",
                        color: "white",
                        backgroundColor: "black",
                        border: "1px solid black",
                      }}
                    >
                      POST
                    </Button>
                  )}
                </Col>
              </Row>

              <div className="table">
                <MaterialTable
                  title="Journal Voucher"
                  columns={[
                    { title: "Date", field: "datajournal.voucherDate" },
                    {
                      title: "Account Name",
                      field: "datajournal.accountTittle",
                    },
                    { title: "Bill No", field: "datajournal.billNo" },
                    {
                      title: "Description",
                      field: "datajournal.voucherDescription",
                    },
                    { title: "Amount", field: "datajournal.voucherAmount" },
                    {
                      title: "DR/CR",
                      field: "datajournal.accountType",
                      render: (rowData) =>
                        rowData.datajournal.accountType == 1
                          ? "Credit"
                          : rowData.datajournal.accountType == 2
                          ? "Debit"
                          : "---",
                    },
                    { title: "Tracking No", field: "datajournal.trackingNo" },
                    { title: "Destination", field: "datajournal.destination" },
                    { title: "Packages", field: "datajournal.packages" },
                    { title: "Weight", field: "datajournal.weight" },
                    {
                      title: "Weight Charges",
                      field: "datajournal.weightCharges",
                    },
                    {
                      title: "Rate First Kg",
                      field: "datajournal.rateFirstKg",
                    },
                    { title: "Rate Add Kg", field: "datajournal.rateAddKg" },
                    { title: "Rate Flat", field: "datajournal.rateFlat" },
                    { title: "Service", field: "datajournal.service" },
                  ]}
                  data={cash}
                  options={{
                    actionsColumnIndex: -1,
                    paging: true,
                    pageSize: 200, // make initial page size
                    emptyRowsWhenPaging: false, // To avoid of having empty rows
                    pageSizeOptions: [50, 100, 150, 200],
                    maxBodyHeight: "600px",
                    headerStyle: {
                      position: "sticky",
                      top: 0,
                      color: "#00BBBB",
                      fontWeight: "550",
                      onRowAdd: "none",
                    },
                  }}
                  editable={{
                    onRowDelete: (oldData) =>
                      new Promise((resolve) => {
                        setTimeout(() => {
                          resolve();
                          props.deleteJournal(oldData);
                        }, 600);
                      }),
                  }}
                />
              </div>
            </Card>
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    addCashPayment: (data, handleVisible, handleDangerVisible) =>
      dispatch(addCashPayment(data, handleVisible, handleDangerVisible)),
    submitJournal: (data) => dispatch(submitJournal(data)),
    deleteJournal: (id) => dispatch(deleteJournal(id)),
    getcreditdebit: () => dispatch(getcreditdebit()),
    resetFunction4: () => dispatch(resetFunction4()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  journal: state.VoucherReducer.journal,
  level5List: state.accountsLevelReducer.level5List,
  creditDebitValue: state.accountsLevelReducer.creditDebitValue,
  isFetchinglevel5List: state.accountsLevelReducer.isFetchinglevel5List,
});

export default connect(mapStateToProps, mapDispatchToProps)(JournalVoucher);
