import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Container,
  Button,
  Alert,
  Tabs,
  Tab,
} from "react-bootstrap";
import "./add-levels.css";
import {
  getLevel1Data,
  AddLevel2Name,
  getLevel2Data,
} from "./accountsLevel.action";
import { connect } from "react-redux";
import { isEmpty, get, map, isNull, groupBy, isUndefined } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import logo from "../../../../Images/logo.png";
import jsPDF from "jspdf";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PDF from "./PDF";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import ReactExport from "react-export-excel";
import Select from "react-select";
import Level2Upload from "./Level2Upload";
import Level2Tap from "./Level2Tap";
import Swal from "sweetalert";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const AddLevel2 = (props) => {
  const [level1Id, setLevel1Id] = useState();
  const [level1label, setlevel1label] = useState();
  const [level2, setLevel2Name] = useState();
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  const handleVisible = () => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: "Added successfully...",
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = () => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: "Something went wrong...",
      icon: "error",
      button: "Ok",
    });
  };

  const styles = StyleSheet.create({
    page: {
      flexDirection: "row",
      backgroundColor: "#E4E4E4",
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
  });

  function uploadLevel2() {
    props.AddLevel2Name(
      {
        levelOneId: level1Id,
        levelTwoName: level2,
      },
      handleVisible,
      handleDangerVisible
    );
    setLevel1Id("");
    setlevel1label("");
    document.getElementById("level2id").value = "";
  }

  const getLevel1List = props.level1List.map((x) => {
    let data = { value: x.levelOneId, label: x.name };
    return data;
  });
  const handleChangeLevel1 = (selectedOption) => {
    setLevel1Id(selectedOption.value);
    setlevel1label(selectedOption.label);
  };

  const level1Data = props.level2List;

  useEffect(() => {
    props.getLevel1Data();
  }, []);
  return (
    <>
      {props.isFetchinglevel1List || props.isSavingLevel2 ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {props.isSavingLevel2 ? "Saving" : "Loading"}
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <div>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>
                      <b> Select Level 1 </b>
                    </Form.Label>
                    <Select
                      placeholder="Select Level 1..."
                      value={{ label: level1label }}
                      onChange={(e) => handleChangeLevel1(e)}
                      options={getLevel1List}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg="12" md="12" xs="12">
                  <Form.Group className="mb-3" controlId="garana">
                    <Form.Label>
                      <b>Enter Level 2 Name :</b>
                    </Form.Label>
                    {isUndefined(level1Id) || isEmpty(getLevel1List) ? (
                      <Form.Control
                        type="text"
                        placeholder="Level Name....."
                        readOnly
                      />
                    ) : (
                      <Form.Control
                        type="text"
                        id="level2id"
                        placeholder="Level Name...."
                        onMouseLeave={(e) => setLevel2Name(e.target.value)}
                      />
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <div className="sendDiv">
                {isEmpty(level1Id) && isEmpty(level2) ? (
                  <Button
                    disabled
                    style={{
                      marginLeft: "2%",
                      color: "white",
                      width: "20%",
                      backgroundColor: "black",
                      border: "1px solid black",
                    }}
                  >
                    Save
                  </Button>
                ) : (
                  <Button className="sendButton" onClick={uploadLevel2}>
                    Save
                  </Button>
                )}
              </div>
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLevel1Data: () => dispatch(getLevel1Data()),
    AddLevel2Name: (data, handleVisible, handleDangerVisible) =>
      dispatch(AddLevel2Name(data, handleVisible, handleDangerVisible)),
    getLevel2Data: () => dispatch(getLevel2Data()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  level1List: state.accountsLevelReducer.level1List,
  level2List: state.accountsLevelReducer.level2List,
  isFetchinglevel1List: state.accountsLevelReducer.isFetchinglevel1List,
  isAddingLevel2: state.accountsLevelReducer.isAddingLevel2,
  isNotAddLevel2: state.accountsLevelReducer.isNotAddLevel2,
  isFetchinglevel2List: state.accountsLevelReducer.isFetchinglevel2List,
  isSavingLevel2: state.accountsLevelReducer.isSavingLevel2,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddLevel2);
