import React, { useState } from "react";
import { Container, Form, Row, Col, Button, FormGroup } from "react-bootstrap";
import {
  addExpense,
  getExpense,
  deleteExpense,
  getBooking,
  getMasterControl /*UpdateHRExpense */,
  UpdateExpence,
} from "./MarqueeDetails.actions";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import { useEffect } from "react";
import Swal from "sweetalert";
import { isEmpty, find,filter } from "lodash";
import Select from "react-select";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { IconButton } from "@material-ui/core";
import jsPDF from "jspdf";
import logo from "../../../../../Images/logo.png";
const AddExpense = (props) => {
  const [menuName, setExpenseName] = useState("");
  const [bookingId, setBookingId] = useState("");
  const [bookingName, setBookingName] = useState("");
  const handleAddInput = () => {
    setInputField([
      ...inputField,
      {
        master_control_item_id: "",
        expense_form_data_price: 0,
        expense_form_desc: "",
        expense_form_quantity: 0,
        expense_form_total_price: 0,
      },
    ]);
  };

  const handleRemoveInput = (index) => {
    const list = [...inputField];
    list.splice(index, 1);
    setInputField(list);
  };
  const handleChange = (e, index, type) => {
    let z = find(
      props.allInventory,
      (x) => x.master_control_item_id == e.value
    );
    const { name, value } = e;
    const list = [...inputField];
    if (type == "intname") list[index]["master_control_item_id"] = e.value;
    if (type == "intname") list[index]["inventoryLabel"] = e.label;
    // if (type == 'intname') setMaximumQuantity(Number(z.currentBlnc));
    if (type == "intprice") {
      list[index]["expense_form_data_price"] = Number(e.target.value);
    }
    if (type == "intquantity") {
      list[index]["expense_form_quantity"] = Number(e.target.value);
    }
    if (type == "intdesc") list[index]["expense_form_desc"] = e.target.value;
    list[index]["expense_form_total_price"] =
      Number(list[index]["expense_form_quantity"]) *
      Number(list[index]["expense_form_data_price"]);
    console.log(list[index]["expense_form_total_price"], "pppp");
    setExpenseTotalPrice(list[index]["expense_form_total_price"]);
    setInputField(list);
    // list[index]['expense_form_data_price'] = "";
  };

  const [inputField, setInputField] = useState([
    {
      master_control_item_id: "",
      expense_form_data_price: 0,
      expense_form_desc: "",
      expense_form_quantity: 0,
      expense_form_total_price: 0,
    },
  ]);

  const [expenseDescription, setExpenseDescription] = useState();
  const [expenseQuantity, setExpenseQuantity] = useState();
  const [expenseTotalPrice, setExpenseTotalPrice] = useState();
  const [expensePrice, setExpensePrice] = useState();

  const [submit, setSubmit] = useState(false);

  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  function uploadExpense() {
    props.addExpense(
      {
        booking_id: bookingId,
        expense_desc: expenseDescription,
        data: inputField,
      },
      handleVisible,
      handleDangerVisible
    );
    setInputField([
      {
        master_control_item_id: "",
        expense_form_data_price: 0,
        expense_form_desc: "",
        expense_form_quantity: 0,
        expense_form_total_price: 0,
      },
    ]);
  }
  const expenseData = isEmpty(props.expenseList)
    ? []
    : props.expenseList.reverse();
  const inventory = props.masterControlsList.map((x) => {
    let data = {
      value: x.master_control_item_id,
      label: x.master_control_item_name,
      type:x.master_control_item_type
    };
    return data;
  });
  const clearStates = () => {
    setExpenseName();
    setExpenseDescription();
    setSubmit(false);
  };
  const handleVisible = () => {
    setAlertVisible(true);
    clearStates();
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: "Added successfully...",
      icon: "success",
      button: "Ok",
    });
  };
  const handleChangeBooking = (selectedOption) => {
    console.log(selectedOption, "sop");
    setBookingId(selectedOption.value);
    setBookingName(selectedOption.label);
  };
  const getBookingList =
    !isEmpty(props.bookingList) &&
    props.bookingList.map((x) => {
      let data = { value: x.booking_id, label: `${x.booking_id} / ${x.booking_name} / ${x.booking_date}` };
      return data;
    });
  console.log("getBookiList", getBookingList, props.bookingList);
  const handleDangerVisible = () => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: "Something went wrong...",
      icon: "error",
      button: "Ok",
    });
  };

  useEffect(() => {
    props.getExpense();
    props.getMasterControl(1);
    props.getBooking();
    console.log(props.expenseList, "eeeeee");
  }, []);
  return (
    <>
      {props.isFetchingExpenseData || props.isAddingExpenseData ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
          </Button>
        </div>
      ) : (
        <Container fluid>
          <Form>
            <Row>
              <Col lg="12" md="12" xs="12">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Select booking *</b>
                  </Form.Label>
                  <Select
                    isDisabled={isEmpty(props.bookingList)}
                    placeholder="Select booking..."
                    onChange={handleChangeBooking}
                    options={getBookingList}
                  />
                </Form.Group>
              </Col>
            </Row>
            {inputField.map((item, i) => {
              return (
                <React.Fragment key={i}>
                  <Row>
                    <Col xl="3" lg="3" md="12" sm="12">
                      <Form.Group>
                        <Form.Label>
                          <b> Select Expence *</b>
                        </Form.Label>
                        <Select
                          style={{ zIndex: 1 }}
                          value={{ label: item.inventoryLabel }}
                          placeholder="Select Items..."
                          name="master_control_item_id"
                          onChange={(e) => handleChange(e, i, "intname")}
                          options={filter(inventory,{type:"Expense"})}
                          isOptionDisabled={(option) =>
                            !isEmpty(
                              find(
                                inputField,
                                (x) => x.master_control_item_id == option.value
                              )
                            )
                          }
                        />
                      </Form.Group>
                    </Col>
                    <Col xl="3" lg="3" md="12" sm="12">
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b>Enter Expense Description </b>
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={1}
                          placeholder="Expense Description....."
                          name="expense_form_desc"
                          onChange={(e) => handleChange(e, i, "intdesc")}
                        />
                      </Form.Group>
                    </Col>
                    <Col xl="2" lg="2" md="12" sm="12">
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b>Quantity *</b>
                        </Form.Label>
                        <Form.Control
                          type="number"
                          rows={1}
                          placeholder="Enter Quantity....."
                          name="expense_form_quantity"
                          onChange={(e) => handleChange(e, i, "intquantity")}
                        />
                      </Form.Group>
                    </Col>
                    <Col xl="2" lg="2" md="6" sm="6">
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b>Add Price *</b>
                        </Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Add Price...."
                          name="expense_form_data_price"
                          value={item.expense_form_data_price}
                          onChange={(e) => handleChange(e, i, "intprice")}
                        />
                      </Form.Group>
                    </Col>
                    <Col xl="2" lg="2" md="12" sm="12">
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b>Total price *</b>
                        </Form.Label>
                        <Form.Control
                          type="number"
                          rows={1}
                          placeholder="Total price....."
                          value={item.expense_form_total_price}
                          disabled={true}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <FormGroup>
                    <Row>
                      <Col lg="12">
                        {inputField.length - 1 == i && (
                          <IconButton
                            title="Click to Add Demand"
                            onClick={handleAddInput}
                            style={{
                              float: "right",
                              color: "black",
                            }}
                          >
                            <AddIcon />
                          </IconButton>
                        )}

                        {inputField.length !== 1 && (
                          <IconButton
                            title="Click to Remove Demand"
                            onClick={() => handleRemoveInput(i)}
                            style={{ float: "right", color: "black" }}
                          >
                            <RemoveIcon />
                          </IconButton>
                        )}
                      </Col>
                    </Row>
                  </FormGroup>
                </React.Fragment>
              );
            })}
            <div className="sendDiv">
              {bookingId == "" ||
              inputField[0].master_control_item_id == "" ||
              inputField[0].expense_form_data_price == 0 ||
              inputField[0].expense_form_quantity == 0 ? (
                <Button
                  className="sendButton"
                  disabled
                  style={{ backgroundColor: "black" }}
                  onClick={() => {
                    uploadExpense();
                    setSubmit(true);
                  }}
                >
                  Save
                </Button>
              ) : (
                <Button
                  className="sendButton"
                  onClick={() => {
                    uploadExpense();
                    setSubmit(true);
                  }}
                >
                  Save
                </Button>
              )}
            </div>
          </Form>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addExpense: (data, handleVisible, handleDangerVisible) =>
      dispatch(addExpense(data, handleVisible, handleDangerVisible)),
    getExpense: () => dispatch(getExpense()),
    getMasterControl: (id) => dispatch(getMasterControl(id)),
    getBooking: () => dispatch(getBooking()),
    UpdateExpense: (newData, oldData) =>
      dispatch(UpdateExpence(newData, oldData)),
    deleteExpense: (id) => dispatch(deleteExpense(id)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  departmentList: state.MarqueeReducer.departmentList,
  isFetchingExpenseData: state.MarqueeReducer.isFetchingExpenseData,
  isAddingExpenseData: state.MarqueeReducer.isAddingExpenseData,
  expenseList: state.MarqueeReducer.expenseList,
  masterControlsList: state.MarqueeReducer.masterControlsList,
  bookingList: state.MarqueeReducer.bookingList,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddExpense);
