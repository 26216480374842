import React, {useState} from 'react'
import { Row, Col, Form, Container, Button, FormGroup, Alert } from "react-bootstrap";
import {BackupDatabase} from './HRDetails.actions';
import { connect } from "react-redux";
import Swal from 'sweetalert';

const Backup = (props) => {
    const [visibleAlert, setAlertVisible] = useState(false);
    const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

    const handleVisible = () => {
        setAlertVisible(true)
        setTimeout(() => {
            setAlertVisible(false)
        }, 9000);
        Swal({
            title: "Success",
            text: "Backup Download Successfull",
            icon: "success",
            button: "Ok",
          });
    }

    const handleDangerVisible = () => {
        setDangerVisibleAlert(true)
        setTimeout(() => {
            setDangerVisibleAlert(false)
        }, 9000);
        Swal({
            title: "Error",
            text: "Something went Wrong!",
            icon: "error",
            button: "Ok",
          });
    }
    const handleBackup = () => {
        props.BackupDatabase(handleVisible, handleDangerVisible);
    };
  return (
    <>
            <Container fluid>
        <div className="main">
            <div>
            <h3>Click button to Download the Backup</h3>
                <div className="sendDiv">
                    <Button className="sendButton"  onClick={() => handleBackup()}>Download</Button>
                </div>
            </div>
        </div>
    </Container>  
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
    return{
        BackupDatabase:(handleVisible, handleDangerVisible)=> dispatch(BackupDatabase(handleVisible, handleDangerVisible))
    }
}
const mapStateToProps = (state, ownProps) => ({
    BackupDatabase: state.HrReducer.backupRestore,
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Backup);