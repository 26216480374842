const initialState = {
    isFetchingPostUsers: false,
    users: [],
    isFetchingUsersList:false,
    usersList: [],
    selectedViewData: [],
    isFetchingSelectedViewUser: false,
    isFetchingPostVendors: false,
    vendors: [],
    vendorsList: [],
    isFetchingGetVendors: false,
    inventoryList: [],
    isFetchingInventorydata: false,
  };

  const usersReducer = (state = initialState, action) => {
    console.log("test1111111111111",action)
    switch (action.type) {
      
      case "ADD_REQUEST_USERS":
        return { ...state };
      case "ADD_SUCCESS_USERS":
        const obj1 = state.users;
        obj1.push(action.payload)
        return { ...state, users: obj1 }
      case "ADD_ERROR_USERS":
        return { ...state};

        case "GET_REQUEST_USERS":
          return { ...state ,isFetchingUsersList:true};
        case "GET_SUCCESS_USERS":
          return { ...state, usersList: action.payload,isFetchingUsersList:false }
        case "GET_ERROR_USERS":
          return { ...state,isFetchingUsersList:false};

          case "REQUEST_DELETE_USERS":
            return { ...state };
          case "SUCCESS_DELETE_USERS":
            return { ...state, usersList: state.usersList.filter(item => item.userId !== action.userId) };
          case "ERROR_DELETE_USERS":
            return { ...state };


            case "UPDATE_REQUEST_USERS":
              return { ...state };
            case "UPDATE_SUCCESS_USERS":
              return { ...state, usersList: state.usersList.map(x => action.payload.find(({ userId }) => userId == x.userId) || x) };
            case "UPDATE_ERROR_USERS":
              return { ...state};
//Vendors
    case "ADD_REQUEST_VENDORS":
        return { ...state };
      case "ADD_SUCCESS_VENDORS":
        const obj2 = state.vendors;
        obj2.push(action.payload)
        return { ...state, vendors: obj2 }
      case "ADD_ERROR_VENDORS":
        return { ...state};

        case "GET_REQUEST_VENDORS":
          return { ...state };
        case "GET_SUCCESS_VENDORS":
          return { ...state, vendorsList: action.payload }
        case "GET_ERROR_VENDORS":
          return { ...state};

          case "REQUEST_DELETE_VENDORS":
            return { ...state };
          case "SUCCESS_DELETE_VENDORS":
            return { ...state, vendorsList: state.vendorsList.filter(item => item.vendorId !== action.vendorId) };
          case "ERROR_DELETE_VENDORS":
            return { ...state };


            case "UPDATE_REQUEST_VENDORS":
              return { ...state };
            case "UPDATE_SUCCESS_VENDORS":
              return { ...state, vendorsList: state.vendorsList.map(x => action.payload.find(({ vendorId }) => vendorId == x.vendorId) || x) };
            case "UPDATE_ERROR_VENDORS":
              return { ...state};
              //Inventory
    case "REQUEST_ADD_INVENTORY":
      return {...state, isAddingInventory: true, dataAddedInventory: false};
    case "SUCCESS_ADD_INVENTORY":
      return {
        ...state,
         inventory: action.payload,
         isAddingInventory:false,
         dataAddedInventory: true
        };
    case "ERROR_ADD_INVENTORY":
      return {...state, isFetchingInventorydata: false};

      case "REQUEST_GET_INVENTORY":
        return {...state, isFetchingInventoryGetdata : true};
      case "SUCCESS_GET_INVENTORY":
        return {
          ...state, 
          inventoryList: action.payload, 
          isFetchingInventoryGetdata: false,
          isState: true
        };
      case "ERROR_GET_INVENTORY":
        return {...state, isFetchingInventoryGetdata: false};

        case "REQUEST_DELETE_INVENTORY":
          return { ...state };
        case "SUCCESS_DELETE_INVENTORY":
          return { ...state, inventoryList: state.inventoryList.filter(item => item.inventoryId !== action.inventoryId) };
        case "ERROR_DELETE_INVENTORY":
          return { ...state };

          case "UPDATE_REQUEST_Inventory":
            return { ...state };
          case "UPDATE_SUCCESS_Inventory":
            return { ...state, inventoryList: state.inventoryList.map(x => action.payload.find(({ inventoryId }) => inventoryId == x.inventoryId) || x) };
          case "UPDATE_ERROR_Inventory":
            return { ...state};
          default:
        return state;
    }
  
  }
  
  
  export default usersReducer;
  
  