import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button } from "react-bootstrap";
import {
  AddTransactionSummary,
  getVouchers,
} from "./TransactionSummary.actions";
import { connect } from "react-redux";
import moment from "moment";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import { isEmpty, find } from "lodash";
import Select from "react-select";
import logo from "../../../../Images/logo.png";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { GetVoucher } from "../Vouchers/Voucher.action";
import { Redirect, Link } from "react-router-dom";

const TransactionSummaryTable = (props) => {
  const getSummary = props.summary.data;
  console.log(props.summary, "sum");
  const [state, setState] = React.useState({
    columns: [
      { title: "Voucher No.", field: "voucherManualId" },
      { title: "Voucher Key.", field: "voukey" },
      { title: "Posting By ", field: "cashAccTittle" },
      {
        title: "Date",
        field: "createdAt",
        render: (rowData) => moment(rowData.createdAt).format("YYYY-MM-DD"),
      },
    ],
  });

  const [stateArticle, setStateArticle] = useState({
    columns: [
      {
        title: "Account Title",
        field: "accountTittle",
        render: (rowData) =>
          rowData.accountTittle + "(" + rowData.level_five.allLevelKey + ")",
      },
      { title: "Description", field: "voucherDescription" },
      { title: "Bill No", field: "billNo" },
      { title: "Amount", field: "voucherAmount" },
      {
        title: "DR/CR",
        field: "accountType",
        render: (rowData) =>
          rowData.accountType == 2
            ? "Debit"
            : rowData.accountType == 1
            ? "Credit"
            : "",
      },
    ],
  });

  const name = isEmpty(props.summary) ? "" : props.summary.voucher;
  const start = isEmpty(props.summary) ? "" : props.summary.startedDate;
  const end = isEmpty(props.summary) ? "" : props.summary.endDate;
  useEffect(() => {}, []);

  return (
    <>
      {props.isFetchingTransactionSummary ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            Loading...
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <div className="sendDiv">
              <Link to="/user/transaction-summary">
                <Button className="sendButton"> Back to Search</Button>
              </Link>
            </div>

            <MaterialTable
              title={
                <div style={{ marginTop: "5px" }}>
                  <h5>{name == "" ? "Voucher" : `${"Voucher: " + name}`}</h5>
                  <h5>
                    Date Range: {start} - {end}
                  </h5>
                </div>
              }
              columns={[
                { title: "Date", field: "voucherDate" },
                { title: "Voucher#", field: "voc_manual_number" },
                { title: "Account", field: "accountTittle" },
                { title: "Description", field: "voucherDescription" },
                { title: "Debit", field: "debit" },
                { title: "Credit", field: "credit" },
              ]}
              data={getSummary}
              options={{
                actionsColumnIndex: -1,
                filtering: true,
                exportButton: true,
                paging: true,
                exportAllColumns: true,
                exportAllData: true,
                pageSize: 200, // make initial page size
                emptyRowsWhenPaging: false, // To avoid of having empty rows
                pageSizeOptions: [50, 100, 150, 200],
                exportPdf: (columns, data) => {
                  const doc = new jsPDF();

                  const columnTitles = [
                    { title: "Date", field: "voucherDate" },
                    { title: "Voucher#", field: "voc_manual_number" },
                    { title: "Account", field: "accountTittle" },
                    { title: "Description", field: "voucherDescription" },
                    { title: "Debit", field: "debit" },
                    { title: "Credit", field: "credit" },
                  ].map((columnDef) => columnDef.title);

                  const pdfData = data.map((rowData) =>
                    [
                      { title: "Date", field: "voucherDate" },
                      { title: "Voucher#", field: "voc_manual_number" },
                      { title: "Account", field: "accountTittle" },
                      { title: "Description", field: "voucherDescription" },
                      { title: "Debit", field: "debit" },
                      { title: "Credit", field: "credit" },
                    ].map(
                      (columnDef) => (
                        console.log(columnDef.field, "eeeee"),
                        columnDef.field == "voucherDate"
                          ? moment(rowData[columnDef.field]).format(
                              "YYYY-MM-DD"
                            )
                          : rowData[columnDef.field]
                      )
                    )
                  );
                  let content = {
                    startY: 50,
                    startX: 5,
                    head: [columnTitles],
                    body: pdfData,
                  };
                  doc.addImage(logo, "PNG", 10, 11, 30, 30);
                  doc.text(
                    `Company Name: ${process.env.REACT_APP_COMPANY_NAME}`,
                    100,
                    20,
                    0,
                    20
                  );
                  doc.text("Transaction Summary Report", 30, 45, 0, 20);
                  doc.autoTable(content);

                  doc.save(`data.pdf`);
                },
                maxBodyHeight: "600px",
                headerStyle: {
                  position: "sticky",
                  top: 0,
                  color: "#00BBBB",
                  fontWeight: "550",
                  onRowAdd: "none",
                },
              }}
            />
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    AddTransactionSummary: (data) => dispatch(AddTransactionSummary(data)),
    getVouchers: () => dispatch(getVouchers()),
    GetVoucher: () => dispatch(GetVoucher()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  voucherListData: state.TransactitonSummaryReducer.voucherListData,
  summary: state.TransactitonSummaryReducer.summary,
  isFetchingGetVouchers: state.TransactitonSummaryReducer.isFetchingGetVouchers,
  isFetchingTransactionSummary:
    state.TransactitonSummaryReducer.isFetchingTransactionSummary,
  voucherList: state.VoucherReducer.voucherList,
  isFetchingVoucherList: state.VoucherReducer.isFetchingVoucherList,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransactionSummaryTable);
