const initialState = {
    isFetchingGetSubProductDetail: false,
    isFetchingAddArticle: false,
    isFetchingGetArticle: false,
    isNotAddingArticle: false,
    subProductDetailInfo: [],
    articleInfo: [],
    ArticleIds: []
}


const AddArticleReducer = (state = initialState, action) => {
    switch (action.type) {
        

        //Get Catalog
        case "REQUEST_GET_SUB_PRODUCT_DETAIL":
            return { ...state, isFetchingGetSubProductDetail: true, isNotAddingArticle: false };

        case "SUCCESS_GET_SUB_PRODUCT_DETAIL":
            return {
                ...state, isFetchingGetSubProductDetail: false,
                subProductDetailInfo: action.payload.data,
            };

        case "ERROR_GET_SUB_PRODUCT_DETAIL":
            return { ...state, isFetchingGetSubProductDetail: false };


            //Article
        case "REQUEST_ADD_ARTICLE":
            return { ...state, isFetchingAddArticle: false, isNotAddingArticle: false };

        case "SUCCESS_ADD_ARTICLE":
            return {
                ...state, isFetchingAddArticle: true,
                ArticleIds: action.payload.IdArray,
                isNotAddingArticle: false,
                // uomDetail: {
                //     uom: action.payload.uomName,
                //     description: action.payload.uomDescription,
                // },
            };

        case "ERROR_ADD_ARTICLE":
            return { ...state, isFetchingAddArticle: false, isNotAddingArticle: true };


            //Get Catalog
        case "REQUEST_GET_ARTICLE":
            return { ...state, isFetchingGetArticle: true, isNotAddingArticle: false };

        case "SUCCESS_GET_ARTICLE":
            return {
                ...state, isFetchingGetArticle: false,
                articleInfo: action.payload,
                isNotAddingArticle: false
            };

        case "ERROR_GET_ARTICLE":
            return { ...state, isFetchingGetArticle: false };


        default:
            return state;
    }
}

export default AddArticleReducer;
