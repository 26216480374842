const initialState = {
    isFetchingArticle: false,
    getArticleList: [],
    isFetchingArticleData: false,
    getArticleListData: [],
    addAdminTotalCost: [],
    isAddingAddAdminTotalCost: false
}


const AddAdminCostReducer = (state = initialState, action) => {
    switch (action.type) {

            case "REQUEST_GET_ARTICLE":
                return { ...state, isFetchingArticle: true };
    
            case "SUCCESS_GET_ARTICLE":
                return {
                    ...state, isFetchingArticle: false,
                    getArticleList: action.payload
                };
    
            case "ERROR_GET_ARTICLE":
                return { ...state, isFetchingArticle: false };

                case "REQUEST_GET_ARTICLE_DATA":
                    return { ...state, isFetchingArticleData: true };
        
                case "SUCCESS_GET_ARTICLE_DATA":
                    return {
                        ...state, isFetchingArticleData: false,
                        getArticleListData: action.payload
                    };
        
                case "ERROR_GET_ARTICLE_DATA":
                    return { ...state, isFetchingArticleData: false };


                    case "REQUEST_ADD_ADMIN_COST":
                        return { ...state, isAddingAddAdminTotalCost: false };
            
                    case "SUCCESS_ADD_ADMIN_COST":
                        return {
                            ...state, isAddingAddAdminTotalCost: true,
                            addAdminTotalCost: action.payload
                        };
            
                    case "ERROR_ADD_ADMIN_COST":
                        return { ...state, isAddingAddAdminTotalCost: false };


        default:
            return state;
    }
}

export default AddAdminCostReducer;
