import { Add } from '@mui/icons-material';
import React from 'react';
import { Tabs, Tab, Row, Col, Form, Container } from 'react-bootstrap';
import AddAttendanceManually from './AddAttendanceManually';
import UploadAttendanceFile from './UploadAttendanceFile';
import ViewAttendance from './ViewAttendance';
import { isNull } from "lodash";
import ViewPastSalary from './ViewPastSalary';


const UploadAttendance = () => {
    const permissions = JSON.parse(localStorage.getItem("permissions"));

    return (
        <Tabs 
        defaultActiveKey={!isNull(permissions) && permissions.some(p => p['sub_id'] === 60) ?
            "UploadAttendance" : !isNull(permissions) && permissions.some(p => p['sub_id'] === 58) ?
                "AddAttendanceManually" : !isNull(permissions) && permissions.some(p => p['sub_id'] === 61) ?
                    "ViewAttendance" 
                    // :  !isNull(permissions) && permissions.some(p => p['sub_id'] === 61) ? "ViewSalary" 
                    : ""}
                    >
            <Tab eventKey="UploadAttendance" title="Upload Attendance" 
            disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 60) ? false : true}
            >
                <UploadAttendanceFile />
            </Tab>
            {/*<Tab eventKey="AddAttendanceManually" title="Add Attendance Manually"
             disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 58) ? false : true}
             >

                <AddAttendanceManually />
    </Tab>*/}
            <Tab eventKey="ViewAttendance" title="View Attendance" 
            disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 61) ? false : true}
            >

                <ViewAttendance />
            </Tab>
            <Tab eventKey="ViewSalary" title="Salaries History" 
            // disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 61) ? false : true}
            >

                <ViewPastSalary />
            </Tab>
        </Tabs>)
}

export default UploadAttendance