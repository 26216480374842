import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, Alert } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import Spinner from 'react-bootstrap/Spinner';
import MaterialTable from 'material-table';
import 'jspdf-autotable';
import Select from 'react-select';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { IconButton } from "@material-ui/core";
import { getCustomerCall } from "../production.actions";
import { getArticleCall } from "../AddArticleFolder/AddArticles.actions";
import { getOrderCall, addChildOrderCall } from "./OrderCreation.actions";

const Child = (props) => {

    const [inputField, setInputField] = useState([{ articleId: "", quantity: "" }]);
    const getOrder = props.orderInfo.map((x) => { let data = { label: x.orderId, value: x.orderId }; return data });
    const getArticle = props.articleInfo.map((x) => { let data = { label: x.itemName, value: x.articleId }; return data });

    const [orderNum, setOrderNum] = useState("");

    const [customerName, setCustomerName] = useState("");
    const [customerOrderNum, setCustomerOrderNum] = useState();
    const [invoiceNum, setInvoiceNum] = useState("");
    const [description, setDescription] = useState("");
    const [orderDate, setOrderDate] = useState("");

    const [articleName, setArticleName] = useState();

    console.log(inputField, "qqoo")
    console.log(articleName, "qqooll")

    const setOrder = (selectedOption) => {
        setOrderNum(selectedOption.value);
    };

    const setArticle = (selectedOption) => {
        setArticleName(selectedOption.value);
    };


    const handleChangeInput = (index, e, type) => {
        const values = [...inputField];
        if (type == 'article') values[index]['articleId'] = e.value;
        if (type == 'articleQuantity') values[index]['quantity'] = e.target.value
        //values[index][event.target.id] = value;
        setInputField(values);
        //console.log(event, "ppff")
    }

    const handleAddFields = () => {
        setInputField([...inputField, { articleId: "", quantity: "" }])
    }


    const handleRemoveFields = (index) => {
        const values = [...inputField];
        if (index = 1)
            values.splice(index, 1);
        setInputField(values);
    }


   /* const [state, setState] = useState({
        columns: [
            { title: 'Order ID', field: 'orderId' },
            { title: 'Invoice Number', field: 'orderInvoice' },
            { title: 'Description', field: 'orderDescription' },
            { title: 'Delivery Date', field: 'orderDeliveryDate' },
            { title: 'Order Status', field: 'orderStatus' },
        ],
    });

    const [stateArticle, setStateArticle] = useState({
        columns: [
            { title: 'Article ID', field: 'articleId' },
            { title: 'Quantity', field: 'quantity' },
        ],
    });*/



    const sendData = () => {
        props.addChildOrderCall({
            orderId : orderNum,
            // customerId: customerName,
            // customerOrder: customerOrderNum,
            // orderInvoice: invoiceNum,
            // orderDescription: description,
            // orderDeliveryDate: orderDate,
            // data: !isEmpty(inputField) && inputField.map((v) => ({
            //     ...v,
            //     articleId: v.articleId,
            //     quantity: v.quantity,
            // }))
            data: inputField,
        })
    }

    useEffect(() => {
        props.getArticleCall();
        // props.getCustomerCall();
        props.getOrderCall();
    }, []);

    return (
        <Container fluid>
            <div className="main">

                <div>
                    <Form>
                        <Row>
                            <Col lg="12" md="12" xs="12" styles={{ zIndex: -1 }}>
                                <Form.Group className="mb-3">
                                    <Form.Label><b>Order #:</b></Form.Label>
                                    <Select
                                        placeholder="Select Order #..."
                                        onChange={setOrder}
                                        options={getOrder}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Form.Label><b>Array of Articles:</b></Form.Label>
                        {inputField.map((inputField, index) => (
                            <div key={index}>
                                <Row>
                                    <Col lg="6" md="6" xs="12">
                                        <Form.Group className="mb-3">
                                            <Form.Label><b>Article Name:</b></Form.Label>
                                            <Select
                                                id="articleId"
                                                placeholder="Select Article..."
                                               // onChange={setArticle}
                                                options={getArticle}
                                                onChange={(e) => handleChangeInput(index, e, 'article')}
                                            />
                                        </Form.Group>
                                    </Col>

                                    <Col lg="6" md="6" xs="12">
                                        <Form.Group className="mb-3">
                                            <Form.Label><b>Quantity:</b></Form.Label>
                                            <Form.Control type="text" id="quantity" placeholder="Quantity...."
                                                onBlur={(e) => handleChangeInput(index, e, 'articleQuantity')}
                                            />
                                        </Form.Group>
                                    </Col>

                                    <Col>
                                        <IconButton
                                            onClick={() => handleAddFields()}
                                            style={{
                                                float: "right",
                                                color: "black",
                                                marginTop: "15px",
                                            }}
                                        >
                                            <AddIcon />
                                        </IconButton>
                                        <IconButton
                                            onClick={() => handleRemoveFields(index)}
                                            style={{ float: "right", color: "black", marginTop: "15px" }}
                                        >
                                            <RemoveIcon />
                                        </IconButton>
                                    </Col>
                                </Row>
                            </div>
                        ))}


                        <div className="sendDiv">
                            <Button className="sendButton" onClick={() => sendData()}>Save</Button>
                        </div>

                        <MaterialTable
                            title="Order Details"
                            columns={ [
                                { title: 'Order ID', field: 'orderId' },
                                { title: 'Invoice Number', field: 'orderInvoice' },
                                { title: 'Description', field: 'orderDescription' },
                                { title: 'Delivery Date', field: 'orderDeliveryDate' },
                                { title: 'Order Status', field: 'orderStatus' },
                            ]}
                            data={props.orderInfo}
                            detailPanel={rowData => {
                                console.log(rowData, "qqgg")
                                return (
                                    <MaterialTable
                                        columns={[
                                            { title: 'Article ID', field: 'articleId' },
                                            { title: 'Quantity', field: 'quantity' },
                                        ]}
                                        data={rowData.order_articles}
                                        title="Article Preview"
                                    />
                                )
                            }}
                        />
                    </Form>

                </div>

            </div>

        </Container>

    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        addChildOrderCall: (data) => dispatch(addChildOrderCall(data)),
        getOrderCall: () => dispatch(getOrderCall()),
        getArticleCall: () => dispatch(getArticleCall())
    };
};
const mapStateToProps = (state, ownProps) => ({
    articleInfo: state.AddArticleReducer.articleInfo,
    orderInfo: state.OrderCreationReducer.orderInfo,
});

export default connect(mapStateToProps, mapDispatchToProps)(Child);