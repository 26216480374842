const initialState = {
  level1List: [],
  isFetchinglevel1List: false,
  level2List: [],
  isAddingLevel2: false,
  isNotAddLevel2: false,
  isFetchinglevel2List: false,
  level2: [],
  level3List: [],
  level3: false,
  isAddingLevel3: false,
  isNotAddLevel3: false,
  isFetchinglevel3List: false,
  level4: false,
  level4List: [],
  isAddingLevel4: false,
  isNotAddLevel4: false,
  isFetchinglevel4List: false,
  level5: false,
  level5List: [],
  isAddingLevel5: false,
  isNotAddLevel5: false,
  isFetchinglevel5List: false,
  isFetchingCreditDebit: false,
  creditDebitValue: [],
  isAddingLevel2File: false,
  isAddingLevel3File: false,
  isAddingLevel4File: false,
  isAddingLevel5File: false,

  isnotAddingLevel2File: false,
  isnotAddingLevel3File: false,
  isnotAddingLevel4File: false,
  isnotAddingLevel5File: false,
  //level 1
  isFetchingLevelTow: false,
  levelTow: [],
  isFetchingLevelThree: false,
  levelThree: [],
  isFetchingLevelFour: false,
  levelFour: [],
  isSavingLevel2: false,
  isSavingLevel3Data: false,
  isSavingLevel4: false,
  isSavingLevel5: false,
};

const accountsLevelReducer = (state = initialState, action) => {
  switch (action.type) {
    //LEVEL 1

    case "REQUEST_LEVEL1_GET_DATA":
      return { ...state, isFetchinglevel1List: true, isState: false };
    case "SUCCESS_LEVEL1_GET_DATA":
      return {
        ...state,
        level1List: action.payload,
        isFetchinglevel1List: false,
        isState: true,
      };
    case "ERROR_LEVEL1_GET_DATA":
      return { ...state, isFetchinglevel1List: false, isState: false };
    // start change
    //  get level 1
    case "REQUEST_LEVEL1_DATA":
      return { ...state, isFetchingLevelTow: true, isState: false };
    case "SUCCESS_LEVEL1_DATA":
      return {
        ...state,
        levelTow: action.payload,
        isFetchingLevelTow: false,
      };
    case "ERROR_LEVEL1_DATA":
      return { ...state, isFetchingLevelTow: false, isState: false };
    // get level 2
    // get level 3
    case "REQUEST_LEVEL2_DATA":
      return { ...state, isFetchingLevelThree: true, isState: false };
    case "SUCCESS_LEVEL2_DATA":
      return {
        ...state,
        levelThree: action.payload,
        isFetchingLevelThree: false,
      };
    case "ERROR_LEVEL2_DATA":
      return { ...state, isFetchingLevelThree: false, isState: false };
    // get level 3
    // get level 4
    case "REQUEST_LEVEL4_DATA":
      return { ...state, isFetchingLevelFour: true, isState: false };
    case "SUCCESS_LEVEL4_DATA":
      return {
        ...state,
        levelFour: action.payload,
        isFetchingLevelFour: false,
      };
    case "ERROR_LEVEL4_DATA":
      return { ...state, isFetchingLevelFour: false, isState: false };
    // get level 4
    // LEVEL 1

    // end change
    case "REQUEST_ADD_LEVEL2":
      return {
        ...state,
        isAddingLevel2: false,
        isNotAddLevel2: false,
        isSavingLevel2: true,
      };
    case "SUCCESS_ADD_LEVEL2":
      return {
        ...state,
        level2: action.payload,
        isAddingLevel2: true,
        isNotAddLevel2: false,
        isSavingLevel2: false,
      };
    case "ERROR_ADD_LEVEL2":
      return {
        ...state,
        isAddingLevel2: false,
        isNotAddLevel2: true,
        isSavingLevel2: false,
      };

    case "REQUEST_LEVEL2_GET_DATA":
      return {
        ...state,
        isFetchinglevel2List: true,
        isState: false,
        isAddingLevel2: false,
        isNotAddLevel2: false,
      };
    case "SUCCESS_LEVEL2_GET_DATA":
      return {
        ...state,
        level2List: action.payload,
        isFetchinglevel2List: false,
        isState: true,
        isNotAddLevel2: false,
      };
    case "ERROR_LEVEL2_GET_DATA":
      return { ...state, isFetchinglevel2List: false, isNotAddLevel2: false };

    //LEVEL 3
    case "REQUEST_ADD_LEVEL3":
      return { ...state, isAddingLevel3: false, isNotAddLevel3: false };
    case "SUCCESS_ADD_LEVEL3":
      return {
        ...state,
        level3: action.payload,
        isAddingLevel3: true,
        isNotAddLevel3: false,
      };
    case "ERROR_ADD_LEVEL3":
      return { ...state, isAddingLevel3: false, isNotAddLevel3: true };

    case "REQUEST_LEVEL3_GET_DATA":
      return {
        ...state,
        isFetchinglevel3List: true,
        isState: false,
        isNotAddLevel3: false,
        isAddingLevel3: false,
      };
    case "SUCCESS_LEVEL3_GET_DATA":
      return {
        ...state,
        level3List: action.payload,
        isFetchinglevel3List: false,
        isState: true,
        isNotAddLevel3: false,
      };
    case "ERROR_LEVEL3_GET_DATA":
      return { ...state, isFetchinglevel3List: false, isState: false };
    //Level 4
    case "REQUEST_ADD_LEVEL4":
      return {
        ...state,
        isAddingLevel4: false,
        isNotAddLevel4: false,
        isSavingLevel4: true,
      };
    case "SUCCESS_ADD_LEVEL4":
      return {
        ...state,
        level4: action.payload,
        isAddingLevel4: true,
        isNotAddLevel4: false,
        isSavingLevel4: false,
      };
    case "ERROR_ADD_LEVEL4":
      return {
        ...state,
        isAddingLevel4: false,
        isNotAddLevel4: true,
        isSavingLevel4: false,
      };

    case "REQUEST_LEVEL4_DATA":
      return {
        ...state,
        isFetchinglevel4List: true,
        isState: false,
        isNotAddLevel4: false,
        isAddingLevel4: false,
      };
    case "SUCCESS_LEVEL4_GET_DATA":
      return {
        ...state,
        level4List: action.payload,
        isFetchinglevel4List: false,
        isState: true,
        isAddingLevel4: false,
        isNotAddLevel4: false,
      };
    case "ERROR_LEVEL4_GET_DATA":
      return { ...state, isFetchinglevel4List: false, isState: false };
    //LEVEL 5
    case "REQUEST_ADD_LEVEL5":
      return { ...state, isAddingLevel5: false, isNotAddLevel5: false };
    case "SUCCESS_ADD_LEVEL5":
      return {
        ...state,
        level5: action.payload,
        isAddingLevel5: true,
        isNotAddLevel5: false,
      };
    case "ERROR_ADD_LEVEL5":
      return { ...state, isAddingLevel5: false, isNotAddLevel5: true };

    case "REQUEST_LEVEL5_GET_DATA":
      return {
        ...state,
        isFetchinglevel5List: true,
        isState: false,
        isNotAddLevel5: false,
        isAddingLevel5: false,
      };
    case "SUCCESS_LEVEL5_GET_DATA":
      return {
        ...state,
        level5List: action.payload,
        isFetchinglevel5List: false,
        isState: true,
        isNotAddLevel5: false,
      };
    case "ERROR_LEVEL5_GET_DATA":
      return { ...state, isFetchinglevel5List: false, isState: false };

    //creditdebot
    // case "REQUEST_ADD_LEVEL5":
    //     return { ...state, isAddingLevel5: true };
    // case "SUCCESS_ADD_LEVEL5":
    //     return {
    //         ...state,
    //         level5: action.payload,
    //         isAddingLevel5: false,
    //     };
    // case "ERROR_ADD_LEVEL5":
    //     return { ...state, isAddingLevel5: false };

    case "REQUEST_CREDITDEBIT_GET_DATA":
      return { ...state, isFetchingCreditDebit: true, isState: false };
    case "SUCCESS_CREDITDEBIT_GET_DATA":
      return {
        ...state,
        creditDebitValue: action.payload,
        isFetchingCreditDebit: false,
        isState: true,
      };
    case "ERROR_CREDITDEBIT_GET_DATA":
      return { ...state, isFetchingCreditDebit: false, isState: false };

    case "DELETE_REQUEST_LEVEL5":
      return { ...state };
    case "DELETE_SUCCESS_LEVEL5":
      return {
        ...state,
        level5List: state.level5List.filter(
          (item) => item.levelFiveId !== action.levelFiveId
        ),
      };
    case "DELETE_ERROR_LEVEL5":
      return { ...state };

    case "REQUEST_UPLOAD_LEVEL2_FILE":
      return {
        ...state,
        isAddingLevel2File: false,
        isnotAddingLevel2File: false,
      };
    case "SUCCESS_UPLOAD_LEVEL2_FILE":
      return {
        ...state,
        isAddingLevel2File: true,
        isnotAddingLevel2File: false,
      };
    case "ERROR_UPLOAD_LEVEL2_FILE":
      return {
        ...state,
        isAddingLevel2File: false,
        isnotAddingLevel2File: true,
      };

    case "REQUEST_UPLOAD_LEVEL3_FILE":
      return {
        ...state,
        isAddingLevel3File: false,
        isnotAddingLevel3File: false,
      };
    case "SUCCESS_UPLOAD_LEVEL3_FILE":
      return {
        ...state,
        isAddingLevel3File: true,
        isnotAddingLevel3File: false,
      };
    case "ERROR_UPLOAD_LEVEL3_FILE":
      return {
        ...state,
        isAddingLevel3File: false,
        isnotAddingLevel3File: true,
      };

    case "REQUEST_UPLOAD_LEVEL4_FILE":
      return {
        ...state,
        isAddingLevel4File: false,
        isnotAddingLevel4File: false,
      };
    case "SUCCESS_UPLOAD_LEVEL4_FILE":
      return {
        ...state,
        isAddingLevel4File: true,
        isnotAddingLevel4File: false,
      };
    case "ERROR_UPLOAD_LEVEL4_FILE":
      return {
        ...state,
        isAddingLevel4File: false,
        isnotAddingLevel4File: true,
      };

    case "REQUEST_UPLOAD_LEVEL5_FILE":
      return {
        ...state,
        isAddingLevel5File: false,
        isnotAddingLevel5File: false,
      };
    case "SUCCESS_UPLOAD_LEVEL5_FILE":
      return {
        ...state,
        isAddingLevel5File: true,
        isnotAddingLevel5File: false,
      };
    case "ERROR_UPLOAD_LEVEL5_FILE":
      return {
        ...state,
        isAddingLevel5File: false,
        isnotAddingLevel5File: true,
      };

    default:
      return state;
  }
};
export default accountsLevelReducer;
