import React, { useEffect, useState } from "react";
import { Row, Col, Form, FormGroup, Container, Button, } from "react-bootstrap";
import { getIssuanceByDate } from "./dashboard.actions";
import { connect } from "react-redux";
import moment from 'moment';
import Spinner from 'react-bootstrap/Spinner';
import MaterialTable from 'material-table';
import { isEmpty, isDate,isUndefined, sumBy } from "lodash";
import Select from 'react-select';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import logo from "../../../../Images/logo.png";

//import DBCard from "../../../../components/DBCard";
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { getTrialDataDashboard, getTrialDataDashboardAccounts, getTrialDataPdf } from "../../accounts/account.actions";
import { startFinancialDate } from "../../../../global/globalFunctions";
import { searchAccountLedger, searchAccountLedgerReport } from "../ledger/Ledger.action";
import { useHistory } from "react-router-dom";




const DashboardAccount = (props) => {
  console.log(props.trialList,"testtt")
  // console.log(props.trialList[2].level_twos[0].level_threes[0].level_fours[2].level_fives,"testtt1122")
  const [startDate, setStartDate] = useState(startFinancialDate);
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  useEffect(() => {
    props.getIssuanceByDate({  startedDate: "",
    endDate: "",});
    props.getTrialDataPdf({
      startedDate: !isUndefined(startDate) ? startDate : "",
      endDate: !isUndefined(endDate) ? endDate : "",
      // levelFiveId: !isUndefined(accountid) ? accountid : "",
    });
    console.log(props.trialList,"tttttttt")
  }, []);
  const history = useHistory();
  return (
    <>
      {props.isFetchingTrialBalance||props.isFetchingTrialBalance ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {props.isFetchingTrialBalance ? 'Loading...' : 'Loading...'}

          </Button>
        </div>
      ) : (
      <Container fluid >
        {console.log(props.trialList,"tttttttt111111")}
        <Row className="mb-2">
          <Col xl="3" lg="3" md="6" sm="6">
            <Card sx={{ display: 'flex' }}>

              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <CardContent sx={{ flex: '1 0 auto' }}>
                  <Typography component="div" variant="body1" color={'#42a5f5'}>
                    <b>Liabilities</b>
                  </Typography>

                </CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', pl: 5, pb: 1 }}>
                  <Typography variant="subtitle1" color="text.primary" component="div">
                    {/* {props.issuanceByDate.payables} */}
                    {!isEmpty(props.trialList)&& props.trialList[1].levelBalance} {!isEmpty(props.trialList)&& props.trialList[1].levelBalance_four_sign}
                    {/* {isEmpty(props.issuanceByDate.today_sale) ? 0 : props.issuanceByDate.today_sale[0].value}  */}

                  </Typography>
                </Box>
              </Box>
              {/* <CardMedia
                  component="img"
                  //sx={{ width: 120 }}
                  sx={{ ml: 7, width: 150, objectFit: "contain" }}
                  // image={colorfulbargraph}
                  alt="Live from space album cover"
                /> */}
            </Card>

          </Col>
          <Col xl="3" lg="3" md="6" sm="6">
            <Card sx={{ display: 'flex' }}>

              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <CardContent sx={{ flex: '1 0 auto' }}>
                  <Typography component="div" variant="body1" color={'#42a5f5'}>
                    <b>Assets</b>
                  </Typography>

                </CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', pl: 5, pb: 1 }}>
                  <Typography variant="subtitle1" color="text.primary" component="div">
                  {/* {props.issuanceByDate.reciveable} */}
                  {/* {!isEmpty(props.trialList)&& props.trialList[2].level_twos[0].level_threes[0].level_fours[2].levelBalance}   {!isEmpty(props.trialList)&& props.trialList[2].level_twos[0].level_threes[0].level_fours[2].levelBalance_four_sign} */}
                  {!isEmpty(props.trialList)&& props.trialList[2].levelBalance}   {!isEmpty(props.trialList)&& props.trialList[2].levelBalance_four_sign}
                    {/* {isEmpty(props.issuanceByDate.today_sale) ? 0 : props.issuanceByDate.today_sale[0].value}  */}

                  </Typography>
                </Box>
              </Box>
              {/* <CardMedia
                  component="img"
                  //sx={{ width: 120 }}
                  sx={{ ml: 7, width: 150, objectFit: "contain" }}
                  // image={colorfulbargraph}
                  alt="Live from space album cover"
                /> */}
            </Card>

          </Col>
          <Col xl="3" lg="3" md="6" sm="6">
            <Card sx={{ display: 'flex' }}>

              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <CardContent sx={{ flex: '1 0 auto' }}>
                  <Typography component="div" variant="body1" color={'#42a5f5'}>
                    <b>Cash</b>
                  </Typography>

                </CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', pl: 5, pb: 1 }}>
                  <Typography variant="subtitle1" color="text.primary" component="div">
                  {/* {props.issuanceByDate.cash} */}
                  {!isEmpty(props.trialList)&& props.trialList[2].level_twos[0].level_threes[0].level_fours[0].levelBalance}  {!isEmpty(props.trialList)&& props.trialList[2].level_twos[0].level_threes[0].level_fours[0].levelBalance_four_sign}
                    {/* {isEmpty(props.issuanceByDate.today_sale) ? 0 : props.issuanceByDate.today_sale[0].value}  */}

                  </Typography>
                </Box>
              </Box>
              {/* <CardMedia
                  component="img"
                  //sx={{ width: 120 }}
                  sx={{ ml: 7, width: 150, objectFit: "contain" }}
                  // image={colorfulbargraph}
                  alt="Live from space album cover"
                /> */}
            </Card>

          </Col>
          <Col xl="3" lg="3" md="6" sm="6">
            <Card sx={{ display: 'flex' }}>

              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <CardContent sx={{ flex: '1 0 auto' }}>
                  <Typography component="div" variant="body1" color={'#42a5f5'}>
                    <b>Bank</b>
                  </Typography>

                </CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', pl: 5, pb: 1 }}>
                  <Typography variant="subtitle1" color="text.primary" component="div">
                  {/* {props.trialList[2].level_fours[1].levelBalance} */}
                  {!isEmpty(props.trialList)&& props.trialList[2].level_twos[0].level_threes[0].level_fours[1].levelBalance}  {!isEmpty(props.trialList)&& props.trialList[2].level_twos[0].level_threes[0].level_fours[1].levelBalance_four_sign}
                    {/* {isEmpty(props.issuanceByDate.today_sale) ? 0 : props.issuanceByDate.today_sale[0].value}  */}

                  </Typography>
                </Box>
              </Box>
              {/* <CardMedia
                  component="img"
                  //sx={{ width: 120 }}
                  sx={{ ml: 7, width: 150, objectFit: "contain" }}
                  // image={colorfulbargraph}
                  alt="Live from space album cover"
                /> */}
            </Card>

          </Col>
        </Row>
        <Row className="mb-2">
        <Col xl="3" lg="3" md="6" sm="6">
            <Card sx={{ display: 'flex' }}>

              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <CardContent sx={{ flex: '1 0 auto' }}>
                  <Typography component="div" variant="body1" color={'#42a5f5'}>
                    <b>Receivables</b>
                  </Typography>

                </CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', pl: 5, pb: 1 }}>
                  <Typography variant="subtitle1" color="text.primary" component="div">
                  {/* {props.trialList[2].level_fours[1].levelBalance} */}
                  {!isEmpty(props.trialList)&& props.trialList[2].level_twos[0].level_threes[0].level_fours[2].levelBalance}  {!isEmpty(props.trialList)&& props.trialList[2].level_twos[0].level_threes[0].level_fours[2].levelBalanceSign}
                    {/* {isEmpty(props.issuanceByDate.today_sale) ? 0 : props.issuanceByDate.today_sale[0].value}  */}

                  </Typography>
                </Box>
              </Box>
              {/* <CardMedia
                  component="img"
                  //sx={{ width: 120 }}
                  sx={{ ml: 7, width: 150, objectFit: "contain" }}
                  // image={colorfulbargraph}
                  alt="Live from space album cover"
                /> */}
            </Card>

          </Col>
          <Col xl="3" lg="3" md="6" sm="6">
            <Card sx={{ display: 'flex' }}>

              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <CardContent sx={{ flex: '1 0 auto' }}>
                  <Typography component="div" variant="body1" color={'#42a5f5'}>
                    <b>Payables</b>
                  </Typography>

                </CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', pl: 5, pb: 1 }}>
                  <Typography variant="subtitle1" color="text.primary" component="div">
                  {/* {props.issuanceByDate.cash} */}
                  {!isEmpty(props.trialList)&& props.trialList[1].level_twos[0].level_threes[0].level_fours[0].levelBalance}  {!isEmpty(props.trialList)&& props.trialList[1].level_twos[0].level_threes[0].level_fours[0].levelBalanceSign}
                    {/* {isEmpty(props.issuanceByDate.today_sale) ? 0 : props.issuanceByDate.today_sale[0].value}  */}

                  </Typography>
                </Box>
              </Box>
              {/* <CardMedia
                  component="img"
                  //sx={{ width: 120 }}
                  sx={{ ml: 7, width: 150, objectFit: "contain" }}
                  // image={colorfulbargraph}
                  alt="Live from space album cover"
                /> */}
            </Card>

          </Col>
          <Col xl="6" lg="6" md="12" sm="12">
            <Card sx={{ display: 'flex' }}>

              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <CardContent sx={{ flex: '1 0 auto' }}>
                  <Typography component="div" variant="body1" color={'#42a5f5'}>
                    <b>Status</b>
                  </Typography>

                </CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', pl: 5, pb: 1 }}>
                  <Typography variant="subtitle1" color="text.primary" component="div">
                  {/* {!isEmpty(props.trialList)&& Number(props.trialList[2].level_twos[0].level_threes[0].level_fours[2].levelBalance)+Number(props.trialList[2].level_twos[0].level_threes[0].level_fours[0].levelBalance)+Number(props.trialList[2].level_twos[0].level_threes[0].level_fours[1].levelBalance)-Number(props.trialList[1].levelBalance)} */}
                  {!isEmpty(props.trialList)&& Number(props.trialList[2].levelBalance)-Number(props.trialList[1].levelBalance)}
                    {/* {isEmpty(props.issuanceByDate.today_sale) ? 0 : props.issuanceByDate.today_sale[0].value}  */}

                  </Typography>
                </Box>
              </Box>
              {/* <CardMedia
                  component="img"
                  //sx={{ width: 120 }}
                  sx={{ ml: 7, width: 150, objectFit: "contain" }}
                  // image={colorfulbargraph}
                  alt="Live from space album cover"
                /> */}
            </Card>

          </Col>
        </Row>

        <Row>
        <Col xl="6" lg="6" md="6" sm="6">
        {/* {console.log(!isEmpty(props.trialList)&&props.trialList[2].level_twos[0].level_threes[0].level_fours[2].level_fives,"testtt1122")} */}
        <MaterialTable  
            
                title={`Debtors`}
                columns={[
                  { title: "Name", field: "levelFiveName", editable:'never'},
                  { title: "Closing Balance", field: "levelBalance", editable:'never',filtering:false},
                  { title: "DR/CR", field: "debitCreditId", editable:'never',filtering:false,
                  render: (rowData) =>
                  rowData.debitCreditId == 2
                    ? "Debit"
                    : rowData.debitCreditId == 1
                    ? "Credit"
                    : ""},
                ]}
                 data={!isEmpty(props.trialList)&&props.trialList[2].level_twos[0].level_threes[0].level_fours[2].level_fives}
                
               options={{
                   actionsColumnIndex: -1,
                   filtering: true,
                   exportButton: false,
                   exportAllData: true,
                   paging: true,
                   maxBodyHeight:'600px',
                   // page:1,
                   pageSize: 50, // make initial page size
                   emptyRowsWhenPaging: false, // To avoid of having empty rows
                   pageSizeOptions: [20,50, 100,150, 200], // rows selection options
                   headerStyle: {
                       position: 'sticky', top: 0,
                       color: '#00BBBB',
                       fontWeight: '550',
                       onRowAdd: 'none',
                       zIndex: 0
                   },
                   
                   
               }}
               onRowClick={(event, rowData) => {
                history.push('/user/Ledger-List',startDate,endDate)
                console.log(rowData,"rowwwwwww")
                props.searchAccountLedgerReport(
                  {
                    startedDate: !isEmpty(startDate) ? startDate : "",
                    endDate: !isEmpty(endDate) ? endDate : "",
                    levelFiveId: rowData.levelFiveId,
                    name: `${rowData.allLevelKey} / ${rowData.levelFiveName}`,
                  },
                  startDate,
                  endDate
                );
                // // Get your id from rowData and use with link.
                // window.open(`mysite.com/product/${rowData.id}`, "_blank")
                // event.stopPropagation();
              }}
           />
          </Col>
          <Col xl="6" lg="6" md="6" sm="6">
          <MaterialTable  
            
            title={`Creditors`}
            columns={[
              { title: "Name", field: "levelFiveName", editable:'never'},
              { title: "Closing Balance", field: "levelBalance", editable:'never',filtering:false},
              { title: "DR/CR", field: "debitCreditId", editable:'never',filtering:false,
              render: (rowData) =>
              rowData.debitCreditId == 2
                ? "Debit"
                : rowData.debitCreditId == 1
                ? "Credit"
                : ""},
            ]}
             data={!isEmpty(props.trialList)&&props.trialList[1].level_twos[0].level_threes[0].level_fours[0].level_fives}
            
           options={{
               actionsColumnIndex: -1,
               filtering: true,
               exportButton: false,
               exportAllData: true,
               paging: true,
               maxBodyHeight:'600px',
               // page:1,
               pageSize: 50, // make initial page size
               emptyRowsWhenPaging: false, // To avoid of having empty rows
               pageSizeOptions: [20,50, 100,150, 200], // rows selection options
               headerStyle: {
                   position: 'sticky', top: 0,
                   color: '#00BBBB',
                   fontWeight: '550',
                   onRowAdd: 'none',
                   zIndex: 0
               },
               
           }}
           onRowClick={(event, rowData) => {
            console.log(rowData,"rowwwwwww")
            history.push('/user/Ledger-List',startDate,endDate)
            props.searchAccountLedgerReport(
              {
                startedDate: !isEmpty(startDate) ? startDate : "",
                endDate: !isEmpty(endDate) ? endDate : "",
                levelFiveId: rowData.levelFiveId,
                name: `${rowData.allLevelKey} / ${rowData.levelFiveName}`,
              },
              startDate,
              endDate
            );
            // // Get your id from rowData and use with link.
            // window.open(`mysite.com/product/${rowData.id}`, "_blank")
            // event.stopPropagation();
          }}
       />
          </Col>
        </Row>

      </Container>

       )} 
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTrialDataPdf: (data) => dispatch(getTrialDataDashboardAccounts(data)),
    getIssuanceByDate: (data) => dispatch(getTrialDataDashboard(data)),
    searchAccountLedgerReport: (data, startDate, endDate) =>
    dispatch(searchAccountLedger(data, startDate, endDate)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  isFetchingIssuance:state.DashboardReducer.isFetchingIssuance,
  issuanceByDate:state.DashboardReducer.issuanceByDate,
  trialList: state.accountReducer.trialList,
  isFetchingTrialBalance: state.accountReducer.isFetchingTrialBalance,
  isFetchingSearchAccountLedgerReport:
  state.AccountLegderReducer.isFetchingSearchAccountLedgerReport,
// trialList:state.userReducer.trialList,
// isFetchingTrialBalance:state.userReducer.isFetchingTrialBalance,

});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardAccount);