import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import Parent from "../production/OrderCreationFolder/Parent";
import Child from "../production/OrderCreationFolder/Child";
import ArticleMaterialReq from "../production/OrderCreationFolder/ArticleMaterialReq";
import AddMaterialRequired from "../production/OrderCreationFolder/AddMaterialRequired";
const Order = (props) => {
    useEffect(() => {
    }, []);
    return (
        <>
            <Tabs defaultActiveKey="parent" id="parent" className="mb-3">

                <Tab eventKey="parent" title="Parent">
                    <Parent />
                </Tab>

                <Tab eventKey="child" title="Child">
                    <Child />
                </Tab>

                <Tab eventKey="AMR" title="Article Material Required">
                    <ArticleMaterialReq />
                </Tab>

                <Tab eventKey="AddMaterialRequired" title="Add Article Material Required">
                    <AddMaterialRequired />
                </Tab>
            </Tabs>
        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
    };
};
const mapStateToProps = (state, ownProps) => ({
});

export default connect(mapStateToProps,mapDispatchToProps)(Order);