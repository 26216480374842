import { isEmpty, isNull, uniqBy } from "lodash";

const initialState = {
  isFetchingGetCustomer: false,
  customerInfo: [],
  isSavingInvoice: false,
  addInvoice: [],
  isFetchingInvoice: false,
  invoiceData: [],
  allInvoice: [],
  isFetchingAllInvoice: false,
};
console.log("initialState.employeeTypeList", initialState.employeeTypeList);

const InvoiceReducer = (state = initialState, action) => {
  // console.log(action, "Awww")
  switch (action.type) {
    //get customer
    case "REQUEST_GET_CUSTOMER":
      return { ...state, isFetchingGetCustomer: true };

    case "SUCCESS_GET_CUSTOMER":
      return {
        ...state,
        isFetchingGetCustomer: false,
        customerInfo: action.payload.result,
      };

    case "ERROR_GET_CUSTOMER":
      return { ...state, isFetchingGetCustomer: false };
    //add Invoice
    case "REQUEST_ADD_INVOICE":
      return { ...state, isSavingInvoice: true };

    case "SUCCESS_ADD_INVOICE":
      return {
        ...state,
        isSavingInvoice: false,
        addInvoice: action.payload.data,
      };

    case "ERROR_ADD_INVOICE":
      return { ...state, isSavingInvoice: false };
    //get ivoice
    case "REQUEST_GET_INVOICE":
      return { ...state, isFetchingInvoice: true };

    case "SUCCESS_GET_INVOICE":
      return {
        ...state,
        isFetchingInvoice: false,
        invoiceData: action.payload.result,
      };

    case "ERROR_GET_INVOICE":
      return { ...state, isFetchingInvoice: false };

    default:
      return state;
  }
};
export default InvoiceReducer;
