import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Container,
  Button,
  Alert,
  Tabs,
  Tab,
} from "react-bootstrap";
import "./add-levels.css";
import {
  getLevel1Data,
  getLevel2Data,
  getLevel3Data,
  AddLevel3Name,
  getLevelOneName,
} from "./accountsLevel.action";
import { connect } from "react-redux";
import jsPDF from "jspdf";
import "jspdf-autotable";
import logo from "../../../../Images/logo.png";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import { Redirect, Link } from "react-router-dom";
import { sortBy } from "lodash";

const Level3Tap = (props) => {
  const level3Data = sortBy(props.level3List, [
    "props.level3List",
    "levelThreeData.allLvlKey",
  ]);

  useEffect(() => {
    props.getLevel3Data();
  }, []);
  return (
    <>
      {props.isFetchinglevel3List ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {/* {props.isFetchinglevel2List ? 'Loading...' : 'Loading...'} */}
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <div className="sendDiv">
              <Link to="/user/level3">
                {" "}
                <Button className="sendButton">Back</Button>
              </Link>
            </div>
            <MaterialTable
              title="Level 3 Details"
              //columns={state.columns}
              columns={[
                {
                  title: "Level 1 Name",
                  field: "levelThreeData.level_one.name",
                  render: (rowData) =>
                    rowData.levelThreeData.level_one.name +
                    " /" +
                    rowData.levelThreeData.level_one.key,
                },
                {
                  title: "Level 2 Name",
                  field: "levelThreeData.level_two.levelTwoName",
                  render: (rowData) =>
                    rowData.levelThreeData.level_two.levelTwoName +
                    " /" +
                    rowData.levelThreeData.level_one.key +
                    "-" +
                    rowData.levelThreeData.level_two.levelTwoKey,
                },
                {
                  title: "Level 3 Code",
                  field: "levelThreeData.allLvlKey",
                  editable: "never",
                },
                {
                  title: "Level 3 Name",
                  field: "levelThreeData.levelThreeName",
                },
              ]}
              data={level3Data}
              options={{
                actionsColumnIndex: -1,
                exportButton: true,
                pageSize: 200,
                pageSizeOptions: [30, 50, 100],
                emptyRowsWhenPaging: false,
                exportPdf: (columns, data) => {
                  const doc = new jsPDF();

                  const columnTitles = [
                    {
                      title: "Level 1 Name",
                      field: "levelThreeData.level_one.name",
                      render: (rowData) =>
                        rowData.levelThreeData.level_one.name +
                        " /" +
                        rowData.levelThreeData.level_one.key,
                    },
                    {
                      title: "Level 2 Name",
                      field: "levelThreeData.level_two.levelTwoName",
                      render: (rowData) =>
                        rowData.levelThreeData.level_two.levelTwoName +
                        " /" +
                        rowData.levelThreeData.level_one.key +
                        "-" +
                        rowData.levelThreeData.level_two.levelTwoKey,
                    },
                    {
                      title: "Level 3 Code",
                      field: "levelThreeData.allLvlKey",
                      editable: "never",
                    },
                    {
                      title: "Level 3 Name",
                      field: "levelThreeData.levelThreeName",
                    },
                  ].map((columnDef) => columnDef.title);

                  // const pdfData = data.map(rowData =>
                  //   state.columns.map(columnDef => (console.log(rowData.levelTwoData[columnDef.field], "eeeee"), columnDef.field == 'voucherDate' ? moment(rowData[columnDef.field]).format("YYYY-MM-DD") : rowData[columnDef.field])),
                  // );

                  const pdfData = data.map((elt) => [
                    elt.levelThreeData.level_one.name,
                    elt.levelThreeData.level_two.levelTwoName,
                    elt.levelThreeData.allLvlKey,
                    elt.levelThreeData.levelThreeName,
                  ]);
                  let content = {
                    startY: 50,
                    startX: 5,
                    head: [columnTitles],
                    body: pdfData,
                  };
                  doc.addImage(logo, "PNG", 10, 11, 30, 30);
                  doc.text(
                    `Company Name: ${process.env.REACT_APP_COMPANY_NAME}`,
                    100,
                    20,
                    0,
                    20
                  );
                  doc.text("Level3 Report", 30, 45, 0, 20);
                  doc.autoTable(content);

                  doc.save(`data.pdf`);
                },
                maxBodyHeight: "600px",
                headerStyle: {
                  position: "sticky",
                  top: 0,
                  color: "#00BBBB",
                  fontWeight: "550",
                  onRowAdd: "none",
                },
              }}
            />
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLevel1Data: () => dispatch(getLevel1Data()),
    getLevel2Data: () => dispatch(getLevel2Data()),
    getLevel3Data: () => dispatch(getLevel3Data()),
    AddLevel3Name: (data) => dispatch(AddLevel3Name(data)),
    getLevelOneName: (data) => dispatch(getLevelOneName(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  levelTow: state.accountsLevelReducer.levelTow,
  level1List: state.accountsLevelReducer.level1List,
  level2List: state.accountsLevelReducer.level2List,
  level3List: state.accountsLevelReducer.level3List,
  isFetchinglevel3List: state.accountsLevelReducer.isFetchinglevel3List,
  isAddingLevel3: state.accountsLevelReducer.isAddingLevel3,
  isNotAddLevel3: state.accountsLevelReducer.isNotAddLevel3,
});

export default connect(mapStateToProps, mapDispatchToProps)(Level3Tap);
