import React, { useState } from "react";
import { Container, Form, Row, Col, Button, FormGroup } from "react-bootstrap";
import {
  addExpense,
  getExpense,
  deleteExpense,
  getBooking,
  getMasterControl /*UpdateHRExpense */,
  getEvent,
  getSubEvent,
  getHall,
  getMenu,
  addBooking,
  getCustomerMarquee,
  hideForm,
  deleteBooking,
  getBookingShifts,
  SearchMarqueeEventReport,
  getBookingById,
} from "./MarqueeDetails.actions";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import { useEffect } from "react";
import Swal from "sweetalert";
import { isEmpty, find, sumBy } from "lodash";
import Select from "react-select";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { IconButton } from "@material-ui/core";
import jsPDF from "jspdf";
import logo from "../../../../../Images/logo.png";
import { number } from "yargs";

const BookingList = (props) => {
  const bookingData = isEmpty(props.bookingList)
    ? []
    : props.bookingList.reverse();
  useEffect(() => {
    props.getBooking();
    props.hideForm(false);
  }, []);
  return (
    <>
      <Container fluid>
        {props.isFetchingBookingListData ? (
          <div className="loader-div">
            <Button variant="info" disabled>
              <Spinner
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
                variant="info"
              />
              Fetching Booking Data
            </Button>
          </div>
        ) : (
          <MaterialTable
            style={{ zIndex: 0 }}
            title="Booking Data"
            columns={[
              { title: "Booking Name", field: "booking_name" },
              { title: "Booking Date ", field: "booking_date" },
              { title: "Booking Total Price ", field: "booking_total_price" },
              { title: "Booking Status", field: "booking_status" },
              { title: "Hall Name", field: "hall.hall_name" },
              { title: "Event Name", field: "event.event_name" },
              // { title: "Sub Event Name", field: "sub_event.sub_event_name"},
              { title: "Sub Event Name", field: "sub_event.sub_event_name" },
              // { title: "Booking Type", field: "booking_type"},
              { title: "Booking Type", field: "booking_type" },
              { title: "Booking Person", field: "booking_person" },
            ]}
            data={bookingData}
            options={{
              actionsColumnIndex: -1,
              filtering: true,
              exportButton: true,
              pageSize: 50,
              emptyRowsWhenPaging: false, // To avoid of having empty rows
              pageSizeOptions: [20, 50, 100, 150, 200],
              exportAllData: true,
              exportPdf: (columns, data) => {
                const doc = new jsPDF();
                //render:rowData=>rowData.hall_name
                const columnTitles = [
                  {
                    title: "Booking Name",
                    field: "booking_name",
                    render: (rowData) => rowData.booking_name,
                  },
                  {
                    title: "Booking Date ",
                    field: "booking_date",
                    render: (rowData) => rowData.booking_desc,
                  },
                  {
                    title: "Booking Total Price ",
                    field: "booking_total_price",
                    render: (rowData) => rowData.booking_total_price,
                  },
                  {
                    title: "Booking Status",
                    field: "booking_status",
                    render: (rowData) => rowData.booking_status,
                  },
                  {
                    title: "Hall Name",
                    field: "hall.hall_name",
                    render: (rowData) => rowData.hall.hall_name,
                  },
                  {
                    title: "Event Name",
                    field: "event.event_name",
                    render: (rowData) => rowData.event.event_name,
                  },
                  //  { title: "Sub Event Name", field: "sub_event.sub_event_name",render:rowData=>rowData.booking_total_price},
                  {
                    title: "Sub Event Name",
                    field: "sub_event.sub_event_name",
                    render: (rowData) => rowData.sub_event.sub_event_name,
                  },
                  //  { title: "Booking Type", field: "booking_type",render:rowData=>rowData.booking_total_price},
                  {
                    title: "Booking Type",
                    field: "booking_type",
                    render: (rowData) => rowData.booking_type,
                  },
                  {
                    title: "Booking Person",
                    field: "booking_person",
                    render: (rowData) => rowData.booking_person,
                  },
                ].map((columnDef) => columnDef.title);
                const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
                const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
                const companyNtn = `${process.env.REACT_APP_COMPANY_NTN}`;
                const pdfData = data.map((rowData) => [
                  rowData.booking_name,
                  rowData.booking_date,
                  rowData.booking_total_price,
                ]);
                let content = {
                  startY: 50,
                  startX: 5,
                  head: [columnTitles],
                  body: pdfData,
                };
                doc.addImage(logo, "PNG", 10, 1, 50, 50);
                doc.setFontSize(20);
                doc.setFont(undefined, "bold");
                doc.text(companyName, 50, 22, 0, 0);
                doc.setFontSize(13);
                doc.setFont("TimesNewRoman", "Normal");
                doc.text(companyAddress, 50, 29, 0, 0);
                doc.text(companyNtn, 50, 36, 0, 0);
                doc.autoTable(content);
                doc.save(`Booking Table.pdf`);
              },
              maxBodyHeight: "600px",
              headerStyle: {
                position: "sticky",
                top: 0,
                color: "#00BBBB",
                fontWeight: "550",
                onRowAdd: "none",
              },
            }}
            editable={{
              /* onRowUpdate: (newData, oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      {
                        // console.log('newData', newData),
                        // props.UpdateHRExpense(newData, oldData)
                      }
                    }, 600);
                  }),*/
              onRowDelete: (oldData) =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    props.deleteBooking(oldData.booking_id);
                    console.log(oldData, "old");
                  }, 600);
                }),
            }}
            /* detailPanel={(rowData) => {
                return (
                  <MaterialTable
                    title="Expense List"
                    columns={[
                      {
                        title: "Dish Name",
                        field: "master_control_item.master_control_item_name",
                      },
                      { title: "Price", field: "booking_data_price" },
                    ]}
                    data={rowData.expense_form_data}
                    options={{
                      actionsColumnIndex: -1,
                      filtering: true,
                      exportButton: true,
                      exportAllData: true,
                      headerStyle: {
                        position: "sticky",
                        top: 0,
                        color: "#00BBBB",
                        fontWeight: "550",
                        onRowAdd: "none",
                      },
                    }}
                    editable={{
                      onRowUpdate: (newData, oldData) =>
                        new Promise((resolve) => {
                          setTimeout(() => {
                            resolve();
                            {
                              // console.log('newData', newData),
                              // props.UpdateHRExpense(newData, oldData)
                            }
                          }, 600);
                        }),
                      onRowDelete: (oldData) =>
                        new Promise((resolve) => {
                          setTimeout(() => {
                            resolve();
                            props.deleteExpense(oldData.menu_id);
                            console.log(oldData, "old");
                          }, 600);
                        }),
                    }}
                  />
                );
              }}*/
          />
        )}
      </Container>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBooking: () => dispatch(getBooking()),
    deleteBooking: (menu_id) => dispatch(deleteBooking(menu_id)),
    hideForm: (data) => dispatch(hideForm(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  bookingList: state.MarqueeReducer.bookingList,
  isFetchingBookingListData: state.MarqueeReducer.isFetchingBookingListData,
  // data:state.MarqueeReducer.data,
});

export default connect(mapStateToProps, mapDispatchToProps)(BookingList);
