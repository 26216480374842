import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Container,
  Button,
  Alert,
  Tabs,
  Tab,
} from "react-bootstrap";
import "./add-levels.css";
import {
  getLevel1Data,
  getLevel2Data,
  getLevel3Data,
  AddLevel3Name,
  getLevelOneName,
} from "./accountsLevel.action";
import { connect } from "react-redux";
import { isEmpty, get, map, isNull, find, groupBy } from "lodash";
import Spinner from "react-bootstrap/Spinner";

import ReactExport from "react-export-excel";
import Select from "react-select";
import PDFLevel3 from "./PDFLevel3";
import Level3Upload from "./Level3Upload";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Level3Tap from "./Level3Tap";
import { Redirect, Link } from "react-router-dom";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const Level3RenderingPage = (props) => {
  useEffect(() => {}, []);
  return (
    <>
      <div className="sendDiv">
        <Link to="/user/level3-details">
          {" "}
          <Button className="sendButton">View Details</Button>
        </Link>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
const mapStateToProps = (state, ownProps) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Level3RenderingPage);
