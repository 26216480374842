import API from "../../../../global/api";
import { get, isEmpty, flatten } from 'lodash';
import logo from "../../../../Images/logo.png";
import jsPDF from 'jspdf';
import moment from 'moment';

// export const requestAllActivity = () => {
//     return {
//         type: "REQUEST_ALL_ACTIVITY",
//     };
// };
// export const successAllActivity = (data) => {
//     return {
//         type: "SUCCESS_ALL_ACTIVITY",
//         payload: data,
//     };
// };
// export const errorAllActivity = () => {
//     return {
//         type: "ERROR_ALL_ACTIVITY",
//     };
// };

// export const getALLActivity = () => {
//     return (dispatch) => {
//         dispatch(requestAllActivity());
//         API.get(`/inventory/all-activity`).then((res) => {
//             let getData = res.data;
//             dispatch(successAllActivity(getData));
//         }).catch((error) => {
//             dispatch(successAllActivity());
//         })
//     };
// };

// export const requestWarehouseData = () => {
//     return {
//         type: "REQUEST_WARE_HOUSE_GET_DATA",
//     };
// };
// export const successWarehouseGetData = (data) => {
//     return {
//         type: "SUCCESS_WARE_HOUSE_GET_DATA",
//         payload: data,
//     };
// };
// export const errorWarehouseGetData = () => {
//     return {
//         type: "ERROR_WARE_HOUSE_GET_DATA",
//     };
// };

// export const getWarehouseData = () => {
//     return (dispatch) => {
//         dispatch(requestWarehouseData());
//         API.get(`/warehouse/all-warehouse`).then((res) => {
//             let getData = res.data;
//             dispatch(successWarehouseGetData(getData));
//         }).catch((error) => {
//             dispatch(errorWarehouseGetData());
//         })
//     };
// };

// export const requestCategoryName = () => {
//     return {
//         type: "REQUEST_CATEGORY_NAME",
//     };
// };
// export const successCategoryName = (data) => {
//     return {
//         type: "SUCCESS_CATEGORY_NAME",
//         payload: data,
//     };
// };
// export const errorCategoryName = () => {
//     return {
//         type: "ERROR_CATEGORY_NAME",
//     };
// };
// export const getCategoryData = () => {
//     return (dispatch) => {
//         dispatch(requestCategoryName());
//         API.get(`/warehouse/all-category`).then((res) => {
//             let data1 = res.data;
//             dispatch(successCategoryName(data1));
//         }).catch((error) => {
//             dispatch(errorCategoryName());
//         })
//     };
// };

// //SUB CATEGORY

// export const requestsubCategoryName = () => {
//     return {
//         type: "REQUEST_SUB_CATEGORY_NAME",
//     };
// };
// export const successsubCategoryName = (data) => {
//     console.log(data, "yyyyy")
//     return {
//         type: "SUCCESS_SUB_CATEGORY_NAME",
//         payload: data,
//     };
// };
// export const errorsubCategoryName = () => {
//     return {
//         type: "ERROR_SUB_CATEGORY_NAME",
//     };
// };
// export const getsubCategoryData = () => {
//     return (dispatch) => {
//         dispatch(requestsubCategoryName());
//         API.get(`/warehouse/all-subcategory`).then((res) => {
//             let data1 = res.data;
//             dispatch(successsubCategoryName(data1));
//         }).catch((error) => {
//             dispatch(errorsubCategoryName());
//         })
//     };
// };

// export const requestinventoryData = () => {
//     return {
//         type: "REQUEST_IVENTORY_DATA",
//     };
// };
// export const successinventoryData = (data) => {
//     console.log(data, "yyyyy")
//     return {
//         type: "SUCCESS_IVENTORY_DATA",
//         payload: data,
//     };
// };
// export const errorinventoryData = () => {
//     return {
//         type: "ERROR_IVENTORY_DATA",
//     };
// };
// export const getinventoryData = () => {
//     return (dispatch) => {
//         dispatch(requestinventoryData());
//         API.get(`/inventory/all-inventory`).then((res) => {
//             let data1 = res.data;
//             dispatch(successinventoryData(data1));
//         }).catch((error) => {
//             dispatch(errorinventoryData());
//         })
//     };
// };

export const requestSearchLedger = () => {
    return {
        type: "REQUEST_ADD_LEDGER",
    };
};
export const successSearchLedger = (data) => {
    return {
        type: "SUCCESS_ADD_LEDGER",
        payload: data,
    };
};
export const errorSearchLedger = () => {
    return {
        type: "ERROR_ADD_LEDGER",
    };
};


export const SearchLedger = () => {
    return (dispatch) => {
        dispatch(requestSearchLedger());
        API.get(`/inventory/getInventoryHistory`).then((res) => {
            let data1 = res.data;
            // console.log()
            dispatch(successSearchLedger(data1));
            
        }).catch((error) => {
            dispatch(errorSearchLedger());
        })
    };
};


export const requestSearchLedgerByDate = () => {
    return {
        type: "REQUEST_ADD_LEDGER_BY_DATE",
    };
};
export const successSearchLedgerByDate = (data) => {
    return {
        type: "SUCCESS_ADD_LEDGER_BY_DATE",
        payload: data,
    };
};
export const errorSearchLedgerByDate = () => {
    return { 
        type: "ERROR_ADD_LEDGER_BY_DATE",
    };
};


export const SearchLedgerByDate = (data) => {
    return (dispatch) => {
        dispatch(requestSearchLedgerByDate());
        API.post(`/inventory/getinventoryhistoryByDate`, data).then((res) => {
            let data1 = res.data;
            // console.log()
            dispatch(successSearchLedgerByDate(data1));
            
        }).catch((error) => {
            dispatch(errorSearchLedgerByDate());
        })
    };
};



export const requestLevel5LedgerData = () => {
    return {
        type: "REQUEST_LEVEL5_LEDGER_DATA",
    };
};
export const successLevel5LedgerData = (data) => {
    return {
        type: "SUCCESS_LEVEL5_LEDGER_DATA",
        payload: data,
    };
};
export const errorLevel5LedgerData = () => {
    return {
        type: "ERROR_LEVEL5_LEDGER_DATA",
    };
};

export const getLevel5LedgerData = () => {
    return (dispatch) => {
        dispatch(requestLevel5LedgerData());
        API.get(`/levelFive/get-acc-ledger`).then((res) => {
            let getData = res.data;
            dispatch(successLevel5LedgerData(getData));
        }).catch((error) => {
            dispatch(errorLevel5LedgerData());
        })
    };
};


export const requestSearchAccountLedger = () => {
    return {
        type: "REQUEST_SEARCH_LEDGER",
    };
};
export const successSearchAccountLedger = (data) => {
    return {
        type: "SUCCESS_SEARCH_LEDGER",
        payload: data,
    };
};
export const errorSearchAccountLedger = () => {
    return {
        type: "ERROR_SEARCH_LEDGER",
    };
};

export const searchAccountLedger = (data, startDate, endDate) => {
    return (dispatch) => {
        dispatch(requestSearchAccountLedger());
        API.post(`/levelFive/get-acc-data`, data).then((res) => {
             let getData = res.data;
            dispatch(successSearchAccountLedger(getData));
        }).catch((error) => {
            dispatch(errorSearchAccountLedger());
        })
    };
};


export const requestSearchAccountLedgerReport = () => {
    return {
        type: "REQUEST_SEARCH_LEDGER_REPORT",
    };
};
export const successSearchAccountLedgerReport = (data) => {
    return {
        type: "SUCCESS_SEARCH_LEDGER_REPORT",
        payload: data,
    };
};
export const errorSearchAccountLedgerReport = () => {
    return {
        type: "ERROR_SEARCH_LEDGER_REPORT",
    };
};

export const searchAccountLedgerReport = (data, startDate, endDate) => {
    return (dispatch) => {
        dispatch(requestSearchAccountLedgerReport());
        API.post(`/levelFive/get-acc-data`, data).then((res) => {
             let getData = res.data;
            const unit = "pt";
            const size = "A4"; // Use A1, A2, A3 or A4
            const orientation = "landscape"; // portrait or landscape

            const marginLeft = 40;
            const doc = new jsPDF(orientation, unit, size);

            doc.setFontSize(11);
            const title = "Accounts Ledger";
            const companyName = `Company Name: ${process.env.REACT_APP_COMPANY_NAME}`;
            const Dates = !isEmpty(startDate) || !isEmpty(endDate) ? "Form: " + " " + startDate + "to: " + endDate : "Form: -  To: - ";
            const accountNo = "Account #:  " + getData.map((x) => x.allLevelKey);
            const accountTitle = "Account Title:  " + getData.map((x) => x.levelFiveName);
            // const headers = [[{
            //     "field": "BANKER",
            //     "title": "BANKER",
            //     "colspan": 1,
            //     "rowspan": 2,
            //     "valign": "middle",
            //     },
            //     {
            //     "title": "Franquicia GM Neta",
            //     "colspan": 5,
            //     "rowspan": 1,
                
            //       }, 
            //       {
            //         "title": "Franquicia GM Neta",
            //         "colspan": 5,
            //         "rowspan": 1,
                
            //       },
            //       {
            //         "title": "TAM",
            //         "colspan": 5,
            //         "rowspan": 1,
                
            //       }]
            //     [
            //     {
            //     field: 'RATES_ACT',
            //     title: 'RATES',
            //     align: 'center',
            //     valign: 'middle',
            //     sortable: false,
            //     visible: true,
            //     },
            //     {
            //     field: 'FOREX_ACT',
            //     title: 'FOREX',
            //     align: 'center',
            //     valign: 'middle',
            //     sortable: false,
            //     visible: true,
            //     },
            //     {
            //     field: 'CREDIT_ACT',
            //     title: 'CREDIT',
            //     align: 'center',
            //     valign: 'middle',
            //     sortable: false,
            //     },
            //     {
            //     field: 'EQUITY_ACT',
            //     title: 'EQUITY',
            //     align: 'center',
            //     valign: 'middle',
            //     sortable: false,
            //     visible: true,
            //     },
            //     {
            //     field: 'TOTAL_ACT',
            //     title: 'TOTAL',
            //     align: 'center',
            //     valign: 'middle',
            //     sortable: false,
            //     visible: true,
            //     },
            //     ///año anterior
            //     {
            //     field: 'RATES_ANT',
            //     title: 'RATES',
            //     align: 'center',
            //     valign: 'middle',
            //     sortable: false,
            //     visible: true,
            //     },
            //     {
            //     field: 'FOREX_ANT',
            //     title: 'FOREX',
            //     align: 'center',
            //     valign: 'middle',
            //     sortable: false,
            //     visible: true,
            //     },
            //     {
            //     field: 'CREDIT_ANT',
            //     title: 'CREDIT',
            //     align: 'center',
            //     valign: 'middle',
            //     sortable: false,
            //     },
            //     {
            //     field: 'EQUITY_ANT',
            //     title: 'EQUITY',
            //     align: 'center',
            //     valign: 'middle',
            //     sortable: false,
            //     visible: true,
            //     },
            //     {
            //     field: 'TOTAL_ANT',
            //     title: 'TOTAL',
            //     align: 'center',
            //     valign: 'middle',
            //     sortable: false,
            //     visible: true,
            //     },{
            //     field: 'RATES_TAM',
            //     title: 'RATES',
            //     align: 'center',
            //     valign: 'middle',
            //     sortable: false,
            //     visible: true,
            //     },
            //     {
            //     field: 'FOREX_TAM',
            //     title: 'FOREX',
            //     align: 'center',
            //     valign: 'middle',
            //     sortable: false,
            //     visible: true,
            //     },
            //     {
            //     field: 'CREDIT_TAM',
            //     title: 'CREDIT',
            //     align: 'center',
            //     valign: 'middle',
            //     sortable: false,
            //     },
            //     {
            //     field: 'EQUITY_TAM',
            //     title: 'EQUITY',
            //     align: 'center',
            //     valign: 'middle',
            //     sortable: false,
            //     visible: true,
            //     },
            //     {
            //     field: 'TOTAL_TAM',
            //     title: 'TOTAL',
            //     align: 'center',
            //     valign: 'middle',
            //     sortable: false,
            //     visible: true,
            //     }
            //     ]]
             
            const headers = [["DATE", "CREDIT", "DEBIT", "BALANCE", "DR/CR"]];

            const data = getData[0].trial_bals.map((elt) => [moment(getData[0].createdAt).format("YYYY-MM-DD"), getData[0].debitCreditId == 1 ? elt.transCredit : 0, getData[0].debitCreditId == 2 ? elt.transDebit : 0, getData[0].levelBalance, getData[0].debit_credit.accountName ])

            let content = {
                startY: 190,
                startX: 10,
                head: headers,
                body: data
            };
            doc.addImage(logo, 'PNG', 40, 15, 100, 100);
            doc.text(companyName, 200, 60, 0, 20);
            doc.text(Dates, 200, 100, 0, 20);
            doc.text(accountNo, marginLeft, 140, 0, 20);
            doc.text(accountTitle, marginLeft, 160, 0, 20);
            doc.text(title, marginLeft, 175);
            doc.autoTable(content);
            doc.setProperties({ title: `Account Ledger Report ${moment(new Date()).format("YYYY-MM-DD")}.pdf` })
           //ct doc.output('dataurlnewwindow')
            doc.save(`Account Ledger Report ${moment(new Date()).format("YYYY-MM-DD")}.pdf`)
            dispatch(successSearchAccountLedgerReport(getData));
        }).catch((error) => {
            dispatch(errorSearchAccountLedgerReport());
        })
    };
};
