import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, Alert } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty, isUndefined } from "lodash";
import Spinner from 'react-bootstrap/Spinner';
import MaterialTable from 'material-table';
import Select from 'react-select';
import { Input } from "reactstrap";
import 'jspdf-autotable';
import { getArticleCall } from "../../production/AddArticleFolder/AddArticles.actions";

const AddArticleMaterialTable = (props) => {

    const [state, setState] = useState({
        columns: [
            { title: 'Article ID', field: 'articleId' },
            { title: 'Item Name', field: 'itemName' },
        ],
    });

    useEffect(() => {
        // props.getArticleCall();
    }, []);

    console.log(props.articleInfo, "ppqq")

    return (
        <MaterialTable
      title="Article Details"
      columns={[
        { title: 'Article ID', field: 'articleId' },
        { title: 'Item Name', field: 'itemName' },
      ]}
      data={props.articleInfo}
      detailPanel={rowData => {
        return (
          <div>
              <h4>Article Detail Preview</h4>
              <Row>
                  <Col lg="1" md="1" sm="12"></Col>
                  <Col lg="5" md="5" sm="12">
                  <label>Article Id: {rowData.articleId}</label>
                  </Col>
                  <Col lg="6" md="6" sm="12">
                  <label>Article Description: {rowData.articleDescription}</label>
                  </Col>
              </Row>

              <Row>
                  <Col lg="1" md="1" sm="12"></Col>
                  <Col lg="5" md="5" sm="12">
                  <label>Weight: {rowData.articleWeight}</label>
                  </Col>
                  <Col lg="6" md="6" sm="12">
                  <label>Dyed: {rowData.dyed == true ? "Yes" : "No"}</label>
                  </Col>
              </Row>

              <Row>
                  <Col lg="1" md="1" sm="12"></Col>
                  <Col lg="5" md="5" sm="12">
                  <label>Item Name: {rowData.itemName}</label>
                  </Col>
                  <Col lg="6" md="6" sm="12">
                  <label>Packing Quantity: {rowData.packingQty}</label>
                  </Col>
              </Row>

              <Row>
                  <Col lg="1" md="1" sm="12"></Col>
                  <Col lg="5" md="5" sm="12">
                  <label>Export Cost: {rowData.exportCost}</label>
                  </Col>
                  <Col lg="6" md="6" sm="12">
                  <label>Labour Cost: {rowData.labourCost}</label>
                  </Col>
              </Row>

              <Row>
                  <Col lg="1" md="1" sm="12"></Col>
                  <Col lg="5" md="5" sm="12">
                  <label>Material Cost: {rowData.materialCost}</label>
                  </Col>
                  <Col lg="6" md="6" sm="12">
                  <label>Total Cost: {rowData.totalCost}</label>
                  </Col>
              </Row>
          </div>
        )
      }}
    />
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        getArticleCall: () => dispatch(getArticleCall()),
    };
};
const mapStateToProps = (state, ownProps) => ({
    articleInfo: state.AddArticleReducer.articleInfo,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddArticleMaterialTable);