import React, { useState } from "react";
import { Container, Form, Row, Col, Button } from "react-bootstrap";
import {
  addEvent,
  getEvent,
  deleteEvent,
  UpdateEvent /*UpdateHREvent */,
} from "./MarqueeDetails.actions";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import { useEffect } from "react";
import Swal from "sweetalert";
import jsPDF from "jspdf";
import { isEmpty } from "lodash";
import logo from "../../../../../Images/logo.png";
//import logo from "../../../../Images/logo.png";

const AddEvent = (props) => {
  const [eventName, setEventName] = useState("");
  const [eventDescription, setEventDescription] = useState("");

  const [submit, setSubmit] = useState(false);

  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  function uploadEvent() {
    props.addEvent(
      {
        event_name: eventName,
        event_desc: eventDescription,
      },
      handleVisible,
      handleDangerVisible
    );
  }
  const eventData = isEmpty(props.eventList) ? [] : props.eventList.reverse();
  console.log(eventData);
  const pdf = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "potrait"; // portrait or landscape

    const doc = new jsPDF(orientation, unit, size);
    doc.text("hmmmmm", 10, 10, 10, 10);
    console.log(eventData, "ho gya kk");
  };
  /* const [state, setState] = React.useState({
        columns: [
            { title: 'Event Name', field: 'event_name' },
            { title: 'Event Description', field: 'event_desc' },
        ],
    });*/
  const clearStates = () => {
    setEventName("");
    setEventDescription("");
    setSubmit(false);
  };
  const handleVisible = () => {
    setAlertVisible(true);
    clearStates();
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: "Added successfully...",
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = () => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: "Something went wrong...",
      icon: "error",
      button: "Ok",
    });
  };

  useEffect(() => {
    props.getEvent(2);
  }, []);
  return (
    <>
      {props.isFetchingEventData || props.isAddingEventData ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
          </Button>
        </div>
      ) : (
        <Container fluid>
          <Form>
            <Row>
              <Col lg="12" md="12" xs="12">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Enter Event Name *</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Event Name...."
                    value={eventName}
                    onChange={(e) => setEventName(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col xl="12" lg="12" md="12" sm="12" xs="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Enter Event Description:</b>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Event Description....."
                    value={eventDescription}
                    onChange={(e) => setEventDescription(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>

            <div className="sendDiv">
              {isEmpty(eventName) ? (
                <Button
                  className="sendButton"
                  style={{ backgroundColor: "black" }}
                  onClick={() => {
                    uploadEvent();
                    setSubmit(true);
                  }}
                  disabled
                >
                  Save
                </Button>
              ) : (
                <Button
                  className="sendButton"
                  onClick={() => {
                    uploadEvent();
                    setSubmit(true);
                  }}
                >
                  Save
                </Button>
              )}
            </div>
          </Form>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addEvent: (data, handleVisible, handleDangerVisible) =>
      dispatch(addEvent(data, handleVisible, handleDangerVisible)),
    getEvent: (id) => dispatch(getEvent(id)),
    UpdateEvent: (newData, oldData) => dispatch(UpdateEvent(newData, oldData)),
    deleteEvent: (event_id) => dispatch(deleteEvent(event_id)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  departmentList: state.MarqueeReducer.departmentList,
  isFetchingEventData: state.MarqueeReducer.isFetchingEventData,
  isAddingEventData: state.MarqueeReducer.isAddingEventData,
  eventList: state.MarqueeReducer.eventList,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddEvent);
