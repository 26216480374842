import API from "../../../../global/api";
import { get, isEmpty,isUndefined } from "lodash";
import logo from "../../../../Images/logo.png";
import jsPDF from "jspdf";
import moment from "moment";
import { Link } from "react-router-dom";
import { sumBy } from "lodash";

export const requestAddWarehouse = () => {
  return {
    type: "REQUEST_ADD_WAREHOUSE",
  };
};
export const successAddWarehouse = (data) => {
  return {
    type: "SUCCESS_ADD_WAREHOUSE",
    payload: data,
  };
};
export const errorAddWarehouse = () => {
  return {
    type: "ERROR_ADD_WAREHOUSE",
  };
};

export const resetPurchaseReducer = () => {
  return {
    type: "RESET_REDUCER",
  };
};

export const resetReducer = () => {
  return {
    type: "RESET_PURCHASE_REDUCER",
  };
};

export const addWareHouseName = (data, handleVisible, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(requestAddWarehouse());
    API.post(`/warehouse/add-warehouse`, data)
      .then((res) => {
        dispatch(getWarehouseData());
        dispatch(successAddWarehouse(data));
        handleVisible();
      })
      .catch((error) => {
        dispatch(errorAddWarehouse());
        handleDangerVisible();
      });
  };
};
//master cotrol
export const requestAddMasterControl = () => {
  return {
    type: "REQUEST_ADD_MASTER_CONTROL",
  };
};
export const successAddMasterControl = (data) => {
  return {
    type: "SUCCESS_ADD_MASTER_CONTROL",
    payload: data,
  };
};
export const errorAddMasterControl = () => {
  return {
    type: "ERROR_ADD_MASTER_CONTROL",
  };
};
export const addMasterControl = (data, handleVisible, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(requestAddMasterControl());
    API.post(`/master_control_inventory/add_master_control`, data)
      .then((res) => {
        dispatch(getMasterControl());
        dispatch(successAddMasterControl(data));
        handleVisible();
      })
      .catch((error) => {
        dispatch(errorAddMasterControl());
        handleDangerVisible();
      });
  };
};

export const requestCategoryByWareHouse = () => {
  return {
    type: "REQUEST_CATEGORY_BY_WAREHOUSE",
  };
};
export const successCategoryByWareHouse = (data) => {
  return {
    type: "SUCCESS_CATEGORY_BY_WAREHOUSE",
    payload: data,
  };
};
export const errorCategoryByWareHouse = () => {
  return {
    type: "ERROR_CATEGORY_BY_WAREHOUSE",
  };
};

export const categoryByWareHouse = (wareHouseId) => {
  return (dispatch) => {
    dispatch(requestCategoryByWareHouse());
    API.get(`/warehouse/cate-by-warehouse/${wareHouseId}`)
      .then((res) => {
        let resData = res.data;
        dispatch(successCategoryByWareHouse(resData));
      })
      .catch((error) => {
        dispatch(errorCategoryByWareHouse());
      });
  };
};

export const requestSubCategoryByCategory = () => {
  return {
    type: "REQUEST_SUB_CATEGORY_BY_cATEGORY",
  };
};
export const successSubCategoryByCategory = (data) => {
  return {
    type: "SUCCESS_SUB_CATEGORY_BY_cATEGORY",
    payload: data,
  };
};
export const errorSubCategoryByCategory = () => {
  return {
    type: "ERROR_SUB_CATEGORY_BY_cATEGORY",
  };
};

export const subCategoryByCategory = (categoryId) => {
  return (dispatch) => {
    dispatch(requestSubCategoryByCategory());
    API.get(`/warehouse/subcate-by-cate/${categoryId}`)
      .then((res) => {
        let resData = res.data;
        dispatch(successSubCategoryByCategory(resData));
      })
      .catch((error) => {
        dispatch(errorSubCategoryByCategory());
      });
  };
};

export const requestWarehouseData = () => {
  return {
    type: "REQUEST_WARE_HOUSE_GET_DATA",
  };
};
export const successWarehouseGetData = (data) => {
  return {
    type: "SUCCESS_WARE_HOUSE_GET_DATA",
    payload: data,
  };
};
export const errorWarehouseGetData = () => {
  return {
    type: "ERROR_WARE_HOUSE_GET_DATA",
  };
};

export const getWarehouseData = () => {
  return (dispatch) => {
    dispatch(requestWarehouseData());
    API.get(`/warehouse/all-warehouse`)
      .then((res) => {
        let getData = res.data;
        dispatch(successWarehouseGetData(getData));
      })
      .catch((error) => {
        dispatch(errorWarehouseGetData());
      });
  };
};
// get master
export const requestGetMasterControl = () => {
  return {
    type: "REQUEST_GET_MASTER_CONTROL",
  };
};
export const successGetMasterControl = (data) => {
  return {
    type: "SUCCESS_GET_MASTER_CONTROL",
    payload: data,
  };
};
export const errorGetMasterControl = () => {
  return {
    type: "ERROR_GET_MASTER_CONTROL",
  };
};

export const getMasterControl = () => {
  return (dispatch) => {
    dispatch(requestGetMasterControl());
    API.get(`/master_control_inventory/get_master_control`)
      .then((res) => {
        let getData = res.data;
        dispatch(successGetMasterControl(getData));
      })
      .catch((error) => {
        dispatch(errorGetMasterControl());
      });
  };
};

export const DeleteRequestWareHouse = () => {
  return {
    type: "DELETE_REQUEST_WARE_HOUSE",
  };
};
export const DeleteSuccessWareHouse = (user_id) => {
  return {
    type: "DELETE_SUCCESS_WARE_HOUSE",
    user_id: user_id,
  };
};
export const DeleteErrorWareHouse = () => {
  return {
    type: "DELETE_ERROR_WARE_HOUSE",
  };
};

export const deleteWareHouse = (wareHouseId) => {
  return (dispatch) => {
    dispatch(DeleteRequestWareHouse());
    API.delete(`/warehouse/delete-warehouse/${wareHouseId}`)
      .then((res) => {
        dispatch(getWarehouseData());
        dispatch(DeleteSuccessWareHouse(wareHouseId));
      })
      .catch((error) => dispatch(DeleteErrorWareHouse()));
  };
};
export const UpdateRequestWareHouse = () => {
  return {
    type: "UPDATE_REQUEST_WAREHOUSE",
  };
};
export const UpdateSuccessWareHouse = (data) => {
  return {
    type: "UPDATE_SUCCESS_WAREHOUSE",
    payload: data,
  };
};

export const UpdateErrorWareHouse = () => {
  return {
    type: "UPDATE_ERROR_WAREHOUSE",
  };
};

export const updateWareHouse = (data, oldData) => {
  return (dispatch) => {
    dispatch(UpdateRequestWareHouse());
    API.put(
      `/warehouse/update-warehouse/${oldData.wareHouseId}`,
      {
        wareHouseName: data.wareHouseName,
        whKey: data.warehouseKey,
        userId: window.localStorage.getItem("userId"),
      },
      {}
    )
      .then((res) => {
        dispatch(getWarehouseData());
        dispatch(UpdateSuccessWareHouse([data]));
      })
      .catch((error) => dispatch(UpdateErrorWareHouse()));
  };
};

export const requestAddCategory = () => {
  return {
    type: "REQUEST_ADD_CATEGORY",
  };
};
export const successAddCategory = (data) => {
  return {
    type: "SUCCESS_ADD_CATEGORY",
    payload: data,
  };
};
export const errorAddCategory = () => {
  return {
    type: "ERROR_ADD_CATEGORY",
  };
};
export const addCategoryName = (data, handleVisible, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(requestAddCategory());
    API.post(`/warehouse/add-category`, data)
      .then((res) => {
        dispatch(getCategoryData());
        dispatch(successAddCategory(data));
        handleVisible();
      })
      .catch((error) => {
        dispatch(errorAddCategory());
        handleDangerVisible();
      });
  };
};

export const DeleteRequestCategory = () => {
  return {
    type: "DELETE_REQUEST_CATEGORY",
  };
};
export const DeleteSuccessCategory = (user_id) => {
  return {
    type: "DELETE_SUCCESS_CATEGORY",
    user_id: user_id,
  };
};
export const DeleteErrorCategory = () => {
  return {
    type: "DELETE_ERROR_CATEGORY",
  };
};

export const deleteCategory = (categoryId) => {
  return (dispatch) => {
    dispatch(DeleteRequestCategory());
    API.delete(`/warehouse/delete-category/${categoryId}`)
      .then((res) => {
        dispatch(getCategoryData());
        dispatch(DeleteSuccessCategory(categoryId));
      })
      .catch((error) => dispatch(DeleteErrorCategory()));
  };
};
export const UpdateRequestCategory = () => {
  return {
    type: "UPDATE_REQUEST_CATEGORY",
  };
};
export const UpdateSuccessCategory = (data) => {
  return {
    type: "UPDATE_SUCCESS_CATEGORY",
    payload: data,
  };
};

export const UpdateErrorCategory = () => {
  return {
    type: "UPDATE_ERROR_CATEGORY",
  };
};

export const updateCategory = (data, oldData) => {
  return (dispatch) => {
    dispatch(UpdateRequestCategory());
    API.put(
      `/warehouse/update-category/${oldData.categoryId}`,
      {
        warehouseWareHouseId: data.warehouseWareHouseId,
        categoryName: data.categoryName,
        cateKey: data.categoryKey,
        userId: window.localStorage.getItem("userId"),
      },
      {}
    )
      .then((res) => {
        dispatch(getCategoryData());
        dispatch(UpdateSuccessCategory([data]));
      })
      .catch((error) => dispatch(UpdateErrorCategory()));
  };
};
//SUB CATEGORY

export const requestAddSubCategory = () => {
  return {
    type: "REQUEST_ADD_SUB_CATEGORY",
  };
};
export const successAddSubCategory = (data) => {
  return {
    type: "SUCCESS_ADD_SUB_CATEGORY",
    payload: data,
  };
};
export const errorAddSubCategory = () => {
  return {
    type: "ERROR_ADD_SUB_CATEGORY",
  };
};
export const addsubCategoryName = (
  data,
  handleVisible,
  handleDangerVisible
) => {
  return (dispatch) => {
    dispatch(requestAddSubCategory());
    API.post(`/warehouse/add-subcategory`, data)
      .then((res) => {
        dispatch(getsubCategoryData());
        dispatch(successAddSubCategory(data));
        handleVisible();
      })
      .catch((error) => {
        dispatch(errorAddSubCategory());
        handleDangerVisible();
      });
  };
};
export const DeleteRequestsubCategory = () => {
  return {
    type: "DELETE_REQUEST_SUB_CATEGORY",
  };
};
export const DeleteSuccesssubCategory = (user_id) => {
  return {
    type: "DELETE_SUCCESS_SUB_CATEGORY",
    user_id: user_id,
  };
};
export const DeleteErrorsubCategory = () => {
  return {
    type: "DELETE_ERROR_SUB_CATEGORY",
  };
};

export const deletesubCategory = (subCategoryId) => {
  return (dispatch) => {
    dispatch(DeleteRequestsubCategory());
    API.delete(`/warehouse/delete-subcategory/${subCategoryId}`)
      .then((res) => {
        dispatch(getsubCategoryData());
        dispatch(DeleteSuccesssubCategory(subCategoryId));
      })
      .catch((error) => dispatch(DeleteErrorsubCategory()));
  };
};
export const UpdateRequestsubCategory = () => {
  return {
    type: "UPDATE_REQUEST_SUB_CATEGORY",
  };
};
export const UpdateSuccesssubCategory = (data) => {
  return {
    type: "UPDATE_SUCCESS_SUB_CATEGORY",
    payload: data,
  };
};

export const UpdateErrorsubCategory = () => {
  return {
    type: "UPDATE_ERROR_SUB_CATEGORY",
  };
};

export const updatesubCategory = (data, oldData) => {
  return (dispatch) => {
    dispatch(UpdateRequestsubCategory());
    API.put(
      `/warehouse/update-subcategory/${oldData.subCategoryId}`,
      {
        warehouseId: data.warehouseId,
        categoryId: data.categoryId,
        subCategoryName: data.subCategoryName,
        subcateKey: data.subCategoryKey,
        userId: window.localStorage.getItem("userId"),
      },
      {}
    )
      .then((res) => {
        dispatch(getsubCategoryData());
        dispatch(UpdateSuccesssubCategory([data]));
      })
      .catch((error) => dispatch(UpdateErrorsubCategory()));
  };
};

export const requestAddInventory = () => {
  return {
    type: "REQUEST_ADD_INVENTORY",
  };
};
export const successAddInventory = (data) => {
  return {
    type: "SUCCESS_ADD_INVENTORY",
    payload: data,
  };
};
export const errorAddInventory = () => {
  return {
    type: "ERROR_ADD_INVENTORY",
  };
};

export const addInventory = (data, handleVisible, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(requestAddInventory());
    API.post(`/inventory/add-inventory`, data)
      .then((res) => {
        // dispatch(getAllInventory({
        //     startedDate: "",
        //     endDate: "",
        //     wareHouseId: "",
        //     subCategoryId: "",
        //     categoryId: "",
        // }));
        dispatch(successAddInventory(data));
        handleVisible();
      })
      .catch((error) => {
        dispatch(errorAddInventory());
        handleDangerVisible();
      });
  };
};
//Attribute
export const requestAddAttribute = () => {
  return {
    type: "REQUEST_ADD_ATTRIBUTE",
  };
};
export const successAddAttribute = (data) => {
  return {
    type: "SUCCESS_ADD_ATTRIBUTE",
    payload: data,
  };
};
export const errorAddAttribute = () => {
  return {
    type: "ERROR_ADD_ATTRIBUTE",
  };
};
export const requestAttributeById = () => {
  return {
    type: "REQUEST_ATTRIBUTE_BY_ID",
  };
};
export const successAttributeById = (data) => {
  return {
    type: "SUCCESS_ATTRIBUTE_BY_ID",
    payload: data,
  };
};
export const errorAttributeById = () => {
  return {
    type: "ERROR_ATTRIBUTE_BY_ID",
  };
};
export const getAttributeById = (warehouseValue) => {
  return (dispatch) => {
    dispatch(requestAttributeById());
    API.get(`/inventory/all-attribute-types-warehouse/${warehouseValue}`)
      .then((res) => {
        let data1 = res.data;
        dispatch(successAttributeById(data1));
      })
      .catch((error) => {
        dispatch(errorAttributeById());
      });
  };
};

export const requestDemandQuantity = () => {
  return {
    type: "REQUEST_DEMAND_QUANTITY",
  };
};
export const successDemandQuantity = (data) => {
  return {
    type: "SUCCESS_DEMAND_QUANTITY",
    payload: data,
  };
};
export const errorDemandQuantity = () => {
  return {
    type: "ERROR_DEMAND_QUANTITY",
  };
};
export const getAllDemand = () => {
  return (dispatch) => {
    dispatch(requestDemandQuantity());
    API.get(`/quantity/quantity_get_data`)
      .then((res) => {
        let data1 = res.data;
        dispatch(successDemandQuantity(data1));
      })
      .catch((error) => {
        dispatch(errorDemandQuantity());
      });
  };
};
export const addAttributeName = (data) => {
  return (dispatch) => {
    dispatch(requestAddAttribute());
    API.post(`/inventory/add-attribute`, data)
      .then((res) => {
        //let data1 = res.data;
        dispatch(getAttributeData());
        dispatch(successAddAttribute(data));
      })
      .catch((error) => {
        dispatch(errorAddAttribute());
      });
  };
};
export const requestGetAllCategoryData = () => {
  return {
    type: "REQUEST_GET_ALL_CATEGORY_DATA",
  };
};
export const successGetAllCategoryData = (data) => {
  return {
    type: "SUCCESS_GET_ALL_CATEGORY_DATA",
    payload: data,
  };
};
export const errorGetAllCategoryData = () => {
  return {
    type: "ERROR_GET_ALL_CATEGORY_DATA",
  };
};
export const getCategoryData = () => {
  return (dispatch) => {
    dispatch(requestGetAllCategoryData());
    API.get(`/warehouse/all-category`)
      .then((res) => {
        let data1 = res.data;
        dispatch(successGetAllCategoryData(data1));
      })
      .catch((error) => {
        dispatch(errorGetAllCategoryData());
      });
  };
};
export const requestGetAllSubCategoryData = () => {
  return {
    type: "REQUEST_GET_ALL_SUB_CATEGORY_DATA",
  };
};
export const successGetAllSubCategoryData = (data) => {
  return {
    type: "SUCCESS_GET_ALL_SUB_CATEGORY_DATA",
    payload: data,
  };
};
export const errorGetAllSubCategoryData = () => {
  return {
    type: "ERROR_GET_ALL_SUB_CATEGORY_DATA",
  };
};
export const getsubCategoryData = () => {
  return (dispatch) => {
    dispatch(requestGetAllSubCategoryData());
    API.get(`/warehouse/all-subcategory`)
      .then((res) => {
        let data1 = res.data;
        dispatch(successGetAllSubCategoryData(data1));
      })
      .catch((error) => {
        dispatch(errorGetAllSubCategoryData());
      });
  };
};
//Attribute
// export const requestAttributeName = () => {
//     return {
//         type: "REQUEST_ATTRIBUTE_NAME",
//     };
// };
// export const successAttributeName = (data) => {
//     return {
//         type: "SUCCESS_ATTRIBUTE_NAME",
//         payload: data,
//     };
// };
// export const errorAttributeName = () => {
//     return {
//         type: "ERROR_ATTRIBUTE_NAME",
//     };
// };

// export const addAttributeName = (data) => {
//     return (dispatch) => {
//         dispatch(requestAttributeName());
//         API.post(`/inventory/add-attribute`, data).then((res) => {
//             //let data1 = res.data;
//             dispatch(getAttributeData());
//             dispatch(successAttributeName(data));
//         }).catch((error) => {
//             dispatch(errorAttributeName());
//         })
//     };
// };

export const requestGetInventory = () => {
  return {
    type: "REQUEST_GET_INVENTORY",
  };
};
export const successGetInventory = (data) => {
  return {
    type: "SUCCESS_GET_INVENTORY",
    payload: data,
  };
};
export const errorGetInventory = () => {
  return {
    type: "ERROR_GET_INVENTORY",
  };
};

export const getInventory = (id) => {
  return (dispatch) => {
    dispatch(requestGetInventory());
    API.get(`/inventory/sepecific-inventory/${id}`)
      .then((res) => {
        let getData = res.data;
        dispatch(successGetInventory(getData));
      })
      .catch((error) => {
        dispatch(errorGetInventory());
      });
  };
};
export const requestAttributeData = () => {
  return {
    type: "REQUEST_ATTRIBUTE_GET_DATA",
  };
};
export const successAttributeGetData = (data) => {
  return {
    type: "SUCCESS_ATTRIBUTE_GET_DATA",
    payload: data,
  };
};
export const errorAttributeGetData = () => {
  return {
    type: "ERROR_ATTRIBUTE_GET_DATA",
  };
};

export const getAttributeData = () => {
  return (dispatch) => {
    dispatch(requestAttributeData());
    API.get(`/inventory/all-attribute`)
      .then((res) => {
        let getData = res.data;
        dispatch(successAttributeGetData(getData));
      })
      .catch((error) => {
        dispatch(errorAttributeGetData());
      });
  };
};

export const requestDeleteInventory = () => {
  return {
    type: "REQUEST_DELETE_INVENTORY",
  };
};
export const successDeleteInventory = (user_id) => {
  return {
    type: "SUCCESS_DELETE_INVENTORY",
    user_id: user_id,
  };
};
export const errorDeleteInventory = () => {
  return {
    type: "ERROR_DELETE_INVENTORY",
  };
};

export const deleteInventory = (inventoryId) => {
  return (dispatch) => {
    dispatch(requestDeleteInventory());
    API.delete(`/inventory/delete-inventory/${inventoryId}`)
      .then((res) => {
        dispatch(
          getAllInventory({
            startedDate: "",
            endDate: "",
            wareHouseId: "",
            subCategoryId: "",
            categoryId: "",
          })
        );
        dispatch(successDeleteInventory(inventoryId));
      })
      .catch((error) => dispatch(errorDeleteInventory()));
  };
};

export const UpdateRequestInventory = () => {
  return {
    type: "UPDATE_REQUEST_Inventory",
  };
};
export const UpdateSuccessInventory = (data) => {
  return {
    type: "UPDATE_SUCCESS_Inventory",
    payload: data,
  };
};

export const UpdateErrorInventory = () => {
  return {
    type: "UPDATE_ERROR_Inventory",
  };
};

export const updateInventory = (data, oldData) => {
  return (dispatch) => {
    dispatch(UpdateRequestInventory());
    API.put(
      `/inventory/update-inventory/${oldData.inventoryId}`,
      {
        companyName: data.companyName,
        productName: data.productName,
        productPrice: data.productPrice,
        arrival_date: data.arrival_date,
        VendorName: data.VendorName,
        invertoryDesc1: data.invertoryDesc,
        currentBlnc: data.currentBlnc,
        minBlnc: data.minBlnc,
        maxBlnc: data.maxBlnc,
        wareHouseId: data.wareHouseId,
        categoryId: data.categoryId,
        subCategoryId: data.subCategoryId,
        invSize: data.size,
        invColor: data.color,
        inventoryField: data.invField,
        productType: data.productType,
        currency: data.currency,
        uom: data.uom,
        rackPosition: data.rackPosition,
        box: data.boxNo,
        height: data.height,
        width: data.width,
        length: data.length,
        thickness: data.thickness,
        hsCode: data.hsCode,
        invertoryDesc2: data.description2,
        invertoryDesc3: data.description3,
        invertoryDesc4: data.description4,
      },
      {}
    )
      .then((res) => {
        dispatch(UpdateSuccessInventory([data]));
      })
      .catch((error) => dispatch(UpdateErrorInventory()));
  };
};

export const requestAddDemand = () => {
  return {
    type: "REQUEST_ADD_DEMAND",
  };
};
export const successAddDemand = (data) => {
  return {
    type: "SUCCESS_ADD_DEMAND",
    payload: data,
  };
};
export const errorAddDemand = () => {
  return {
    type: "ERROR_ADD_DEMAND",
  };
};

export const addDemand = (data, handleVisible, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(requestAddDemand());
    API.post(`quantity/quantity_add_data`, data)
      .then((res) => {
        dispatch(getAllDemand());
        dispatch(successAddDemand(data));
        handleVisible();
      })
      .catch((error) => {
        dispatch(errorAddDemand());
        handleDangerVisible();
      });
  };
};

export const DeleteRequestAttribute = () => {
  return {
    type: "DELETE_REQUEST_ATTRIBUTE",
  };
};
export const DeleteSuccessAttribute = (user_id) => {
  return {
    type: "DELETE_SUCCESS_ATTRIBUTE",
    user_id: user_id,
  };
};
export const DeleteErrorAttribute = () => {
  return {
    type: "DELETE_ERROR_ATTRIBUTE",
  };
};

export const deleteAttribute = (attributeId) => {
  return (dispatch) => {
    dispatch(DeleteRequestAttribute());
    API.delete(`/inventory/delete-attribute/${attributeId}`)
      .then((res) => {
        dispatch(getAttributeData());
        dispatch(DeleteSuccessAttribute(attributeId));
      })
      .catch((error) => dispatch(DeleteErrorAttribute()));
  };
};
export const resetFunction = () => {
  return {
    type: "RESET_DATA",
  };
};

export const requestCategoryName = () => {
  return {
    type: "REQUEST_CATEGORY_NAME",
  };
};
export const successCategoryName = (data) => {
  return {
    type: "SUCCESS_CATEGORY_NAME",
    payload: data,
  };
};
export const errorCategoryName = () => {
  return {
    type: "ERROR_CATEGORY_NAME",
  };
};

export const requestsubCategoryName = () => {
  return {
    type: "REQUEST_SUB_CATEGORY_NAME",
  };
};
export const successsubCategoryName = (data) => {
  return {
    type: "SUCCESS_SUB_CATEGORY_NAME",
    payload: data,
  };
};
export const errorsubCategoryName = () => {
  return {
    type: "ERROR_SUB_CATEGORY_NAME",
  };
};

export const requestGetAllInventory = () => {
  return {
    type: "REQUEST_GET_ALL_INVENTORY",
  };
};
export const successGetAllInventory = (data) => {
  return {
    type: "SUCCESS_GET_ALL_INVENTORY",
    payload: data,
  };
};
export const errorGetAllInventory = () => {
  return {
    type: "ERROR_GET_ALL_INVENTORY",
  };
};

export const getAllInventory = (data) => {
  // `/inventory/all-inventory?limit=${1}`
  return (dispatch) => {
    dispatch(requestGetAllInventory());
    API.post(`/inventory/all-inventory`, data)
      .then((res) => {
        let getData = res.data;
        // let history = useHistory();;

        dispatch(successGetAllInventory(getData));

        if (true) {
          return <Link to="/courses" replace />;
        }
      })
      .catch((error) => {
        dispatch(errorGetAllInventory());
      });
  };
};

export const requestUploadFile = () => {
  return {
    type: "REQUEST_UPLOAD_FILE",
  };
};
export const successUploadFile = (data) => {
  return {
    type: "SUCCESS_UPLOAD_FILE",
    payload: data,
  };
};
export const errorUploadFile = () => {
  return {
    type: "ERROR_UPLOAD_FILE",
  };
};

export const uploadFile = (data, handleVisible, handleDangerVisible) => {
  const formData = new FormData();
  formData.append("file", get(data, "file"));
  formData.append("wareHouseId", `${data.wareHouseId}`);
  formData.append("categoryId", `${data.categoryId}`);
  formData.append("subCategoryId", `${data.subCategoryId}`);
  return (dispatch) => {
    dispatch(requestUploadFile());
    API.post(`/upload_image/uploadFiles`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        let getData = res.data;
        dispatch(successUploadFile(getData));
        handleVisible();
      })
      .catch((error) => {
        dispatch(errorUploadFile());
        handleDangerVisible();
      });
  };
};

export const requestGetPurchase = () => {
  return {
    type: "REQUEST_GET_PURCHASE",
  };
};
export const successGetPurchase = (data) => {
  return {
    type: "SUCCESS_GET_PURCHASE",
    payload: data,
  };
};
export const errorGetPurchase = () => {
  return {
    type: "ERROR_GET_PURCHASE",
  };
};

export const getPurchase = () => {
  return (dispatch) => {
    dispatch(requestGetPurchase());
    API.get(`/purchase/get_demand_user`)
      .then((res) => {
        let getData = res.data;
        dispatch(successGetPurchase(getData));
      })
      .catch((error) => {
        dispatch(errorGetPurchase());
      });
  };
};

export const requestGetApproveDemand = () => {
  return {
    type: "REQUEST_GET_APPROVE_DEMAND",
  };
};
export const successGetApproveDemand = (data) => {
  return {
    type: "SUCCESS_GET_APPROVE_DEMAND",
    payload: data,
  };
};
export const errorGetApproveDemand = () => {
  return {
    type: "ERROR_GET_APPROVE_DEMAND",
  };
};

export const getApproveDemand = () => {
  return (dispatch) => {
    dispatch(requestGetApproveDemand());
    API.get(`/quantity/get_approved_data`)
      .then((res) => {
        let getData = res.data;
        dispatch(successGetApproveDemand(getData));
      })
      .catch((error) => {
        dispatch(errorGetApproveDemand());
      });
  };
};

export const requestGetProductByDemand = () => {
  return {
    type: "REQUEST_GET_PRODUCT_BY_DEMAND",
  };
};
export const successGetProductByDemand = (data) => {
  return {
    type: "SUCCESS_GET_PRODUCT_BY_DEMAND",
    payload: data,
  };
};
export const errorGetProductByDemand = () => {
  return {
    type: "ERROR_GET_PRODUCT_BY_DEMAND",
  };
};

export const getProductByDemand = (demandId) => {
  return (dispatch) => {
    dispatch(requestGetProductByDemand());
    API.get(`/quantity/get_demand_by_id/${demandId}`)
      .then((res) => {
        let getData = res.data;
        dispatch(successGetProductByDemand(getData));
      })
      .catch((error) => {
        dispatch(errorGetProductByDemand());
      });
  };
};

export const requestAddPurchase = () => {
  return {
    type: "REQUEST_ADD_PURCHASE",
  };
};
export const successAddPurchase = (data) => {
  return {
    type: "SUCCESS_ADD_PURCHASE",
    payload: data,
  };
};
export const errorAddPurchase = () => {
  return {
    type: "ERROR_ADD_PURCHASE",
  };
};

export const addPurchase = (data, handleVisible, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(requestAddPurchase());
    API.post(`/purchase/purchase_data_add`, data)
      .then((res) => {
        dispatch(getPurchase());
        dispatch(successAddPurchase(data));
        handleVisible();
      })
      .catch((error) => {
        dispatch(errorAddPurchase());
        handleDangerVisible();
      });
  };
};

export const requestAddIgp = () => {
  return {
    type: "REQUEST_ADD_IGP",
  };
};
export const successAddIgp = (data) => {
  return {
    type: "SUCCESS_ADD_IGP",
    payload: data,
  };
};
export const errorAddPIgp = () => {
  return {
    type: "ERROR_ADD_IGP",
  };
};

export const addIGP = (data, handleVisible, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(requestAddIgp());
    API.post(`igp/add-igp`, data)
      .then((res) => {
        dispatch(getIGP());
        dispatch(successAddIgp(data));
        handleVisible();
      })
      .catch((error) => {
        dispatch(errorAddPIgp());
        handleDangerVisible();
      });
  };
};

export const requestGetApprovePurchase = () => {
  return {
    type: "REQUEST_GET_APPROVE_PURCHASE",
  };
};
export const successGetApprovePurchase = (data) => {
  return {
    type: "SUCCESS_GET_APPROVE_PURCHASE",
    payload: data,
  };
};
export const errorGetApprovePurchase = () => {
  return {
    type: "ERROR_GET_APPROVE_PURCHASE",
  };
};

export const getApprovePurchase = () => {
  return (dispatch) => {
    dispatch(requestGetApprovePurchase());
    API.get(`purchase/get-purchase-approve`)
      .then((res) => {
        let getData = res.data;
        dispatch(successGetApprovePurchase(getData));
      })
      .catch((error) => {
        dispatch(errorGetApprovePurchase());
      });
  };
};

export const requestPurchaseById = () => {
  return {
    type: "REQUEST_PURCHASE_ID",
  };
};
export const successPurchaseById = (data) => {
  return {
    type: "SUCCESS_PURCHASE_ID",
    payload: data,
  };
};
export const errorPurchaseById = () => {
  return {
    type: "ERROR_PURCHASE_ID",
  };
};

export const purchaseById = (data) => {
  return (dispatch) => {
    dispatch(requestPurchaseById());
    API.post(`/purchase/get-purchase-id`, data)
      .then((res) => {
        let getData = res.data;
        dispatch(successPurchaseById(getData));
      })
      .catch((error) => {
        dispatch(errorPurchaseById());
      });
  };
};

export const requestGetIGP = () => {
  return {
    type: "REQUEST_GET_IGP",
  };
};
export const successGetIGP = (data) => {
  return {
    type: "SUCCESS_GET_IGP",
    payload: data,
  };
};
export const errorGetIGP = () => {
  return {
    type: "ERROR_GET_IGP",
  };
};

export const getIGP = () => {
  return (dispatch) => {
    dispatch(requestGetIGP());
    API.get(`/igp/get_all_igp`)
      .then((res) => {
        let getData = res.data;
        dispatch(successGetIGP(getData));
      })
      .catch((error) => {
        dispatch(errorGetIGP());
      });
  };
};

export const requestAddIssuance = () => {
  return {
    type: "REQUEST_ADD_ISSUANCE",
  };
};
export const successAddIssuance = (data) => {
  return {
    type: "SUCCESS_ADD_ISSUANCE",
    payload: data,
  };
};
export const errorAddIssuance = () => {
  return {
    type: "ERROR_ADD_ISSUANCE",
  };
};

export const addIssuance = (data, handleVisible, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(requestAddIssuance());
    API.post(`/issuance/add-issuance`, data)
      .then((res) => {
        dispatch(getIssuance());
        dispatch(successAddIssuance(data));
        handleVisible();
      })
      .catch((error) => {
        dispatch(errorAddIssuance());
        handleDangerVisible();
      });
  };
};

export const requestGetIssuance = () => {
  return {
    type: "REQUEST_GET_ISSUANCE",
  };
};
export const successGetIssuance = (data) => {
  return {
    type: "SUCCESS_GET_ISSUANCE",
    payload: data,
  };
};
export const errorGetIssuance = () => {
  return {
    type: "ERROR_GET_ISSUANCE",
  };
};

export const getIssuance = () => {
  return (dispatch) => {
    dispatch(requestGetIssuance());
    API.get(`/issuance/get-all-issuance`)
      .then((res) => {
        let getData = res.data;
        dispatch(successGetIssuance(getData));
      })
      .catch((error) => {
        dispatch(errorGetIssuance());
      });
  };
};

export const requestSearchInventory = () => {
  return {
    type: "REQUEST_SEARCH_INVENTORY",
  };
};
export const successSearchInventory = (data) => {
  return {
    type: "SUCCESS_SEARCH_INVENTORY",
    payload: data,
  };
};
export const errorSearchInventory = () => {
  return {
    type: "ERROR_SEARCH_INVENTORY",
  };
};

export const searchInventoryReport = (
  data,
  name,
  startDate,
  endDate,
  handleVisible
) => {
  return (dispatch) => {
    dispatch(requestSearchInventory());
    API.post(`/inventory/search-inventory`, data)
      .then((res) => {
        // dispatch(getIssuance());
        let resData = res.data;
        isEmpty(resData) && handleVisible();
        const unit = "pt";
        const size = "A4";
        const orientation = "potrait";

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(11);
        const title = "Inventory Details";
        const companyName = `Company Name: ${process.env.REACT_APP_COMPANY_NAME}`;
        const WareHouseName = !isEmpty(name)
          ? "WareHouse Name: " + name
          : "WareHouse Name: ";
        const Dates =
          !isEmpty(startDate) || !isEmpty(endDate)
            ? "Stock Report as on " + " " + startDate + " to " + endDate
            : "Stock Repair as on - ";
        const headers = [
          [
            "CODE",
            "INVENTORY NAME",
            "PRICE",
            "CURRENT BALANCE",
            "RACK POSITION",
            "UOM",
            "AMOUNT",
          ],
        ];

        const data = resData.map((elt) => [
          elt.warehouse.whKey + "-" + elt.category.cateKey + "-" + elt.invKey,
          elt.productName,
          elt.productPrice,
          elt.currentBlnc,
          elt.rackPosition,
          elt.uom,
          !isEmpty(elt.currentBlnc) && !isEmpty(elt.productPrice)
            ? (Number(elt.currentBlnc) * Number(elt.productPrice)).toFixed(2)
            : 0,
        ]);
        const TotalCurrentBalance =
          "Total Current Balance: " +
          sumBy(data, function (o) {
            return Number(o[3]);
          });
        const TotalAmount =
          "Total Amount: " +
          sumBy(data, function (o) {
            return Number(o[6]);
          });
        let content = {
          startY: 200,
          startX: 10,
          head: headers,
          body: data,
        };
        doc.addImage(logo, "PNG", 40, 15, 100, 100);
        doc.text(companyName, 200, 60, 0, 20);
        doc.text(WareHouseName, 200, 80, 0, 20);
        doc.text(Dates, 200, 100, 0, 20);
        doc.text(TotalCurrentBalance, marginLeft, 135);
        doc.text(TotalAmount, marginLeft, 150);
        doc.text(title, marginLeft, 170);
        doc.autoTable(content);
        doc.save(
          `${!isEmpty(name) && name} Inventory Report ${moment(
            new Date()
          ).format("YYYY-MM-DD")}.pdf`
        );
        dispatch(successSearchInventory(resData));
      })
      .catch((error) => {
        dispatch(errorSearchInventory());
      });
  };
};

export const requestSearchDemand = () => {
  return {
    type: "REQUEST_SEARCH_DEMAND",
  };
};
export const successSearchDemand = (data) => {
  return {
    type: "SUCCESS_SEARCH_DEMAND",
    payload: data,
  };
};
export const errorSearchDemand = () => {
  return {
    type: "ERROR_SEARCH_DEMAND",
  };
};

export const searchDemandReport = (
  data,
  name,
  startDate,
  endDate,
  handleVisible
) => {
  return (dispatch) => {
    dispatch(requestSearchDemand());
    API.post(`/quantity/get_demand_search`, data)
      .then((res) => {
        let resData = res.data;
        isEmpty(resData) && handleVisible();
        const unit = "pt";
        const size = "A4";
        const orientation = "potrait";
        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);
        doc.setFontSize(11);
        const title = "Demand Report";
        const companyName = `Company Name: ${process.env.REACT_APP_COMPANY_NAME}`;
        const demandName = "Demand Name: " + name;
        const DemandNumber = "Demand #: " + resData.map((x) => x.demandId);
        const Dates =
          !isEmpty(startDate) || !isEmpty(endDate)
            ? "Demand Date " + " " + startDate + " to " + endDate
            : "Demand Date - ";
        const DemandType = "Demand Type: " + resData.map((x) => x.demandType);
        const DemandAgainst = "Demand Against: Customer";
        const headers = [
          [
            "CODE",
            "MATERIAL NAME",
            "UOM",
            "DEMAND QUANTITY",
            "APPROVE QUANTITY",
          ],
        ];
        const data = resData[0].demand_quantities.map((elt) => [
          elt.inventory.warehouse.whKey +
            "-" +
            elt.inventory.category.cateKey +
            "-" +
            elt.inventory.invKey,
          elt.productName,
          elt.inventory.uom,
          elt.suggestedQuantity,
          elt.approvedQuantity,
        ]);
        const TotalDemandQuantity =
          "Total Demand Quantity: " +
          sumBy(data, function (o) {
            return Number(o[3]);
          });
        const TotalApproveQuantity =
          "Total Approve Quantity: " +
          sumBy(data, function (o) {
            return Number(o[4]);
          });
        let content = {
          startY: 200,
          startX: 10,
          head: headers,
          body: data,
        };
        doc.addImage(logo, "PNG", 40, 15, 100, 100);
        doc.text(companyName, 200, 60, 0, 20);
        doc.text(demandName, 200, 80, 0, 20);
        doc.text(DemandNumber, 200, 120, 0, 20);
        doc.text(Dates, 380, 120, 0, 20);
        doc.text(DemandType, 200, 140, 0, 20);
        doc.text(DemandAgainst, 380, 140, 0, 20);
        doc.text(TotalDemandQuantity, marginLeft, 157);
        doc.text(TotalApproveQuantity, marginLeft, 170);
        doc.text(title, marginLeft, 185);
        doc.autoTable(content);
        doc.save(
          `${name} DemandReport ${moment(new Date()).format("YYYY-MM-DD")}.pdf`
        );
        dispatch(successSearchDemand(resData));
      })
      .catch((error) => {
        dispatch(errorSearchDemand());
      });
  };
};

export const requestSearchPurchase = () => {
  return {
    type: "REQUEST_SEARCH_PURCHASE",
  };
};
export const successSearchPurchase = (data) => {
  return {
    type: "SUCCESS_SEARCH_PURCHASE",
    payload: data,
  };
};
export const errorSearchPurchase = () => {
  return {
    type: "ERROR_SEARCH_PURCHASE",
  };
};

export const searchPurchaseReport = (
  data,
  demandName,
  purchaseName,
  startDate,
  endDate
) => {
  return (dispatch) => {
    dispatch(requestSearchPurchase());
    API.post(`/purchase/get_PO_search`, data)
      .then((res) => {
        let resData = res.data;
        const unit = "pt";
        const size = "A4";
        const orientation = "potrait";
        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);
        doc.setFontSize(11);
        const title = "Purchase Report";
        const companyName = `Company Name: ${process.env.REACT_APP_COMPANY_NAME}`;
        const demandName = "Purchase Name: " + purchaseName;
        const DemandNumber =
          "Purchase #: " + resData.map((x) => x.purchaseOrderId);
        const Dates =
          !isEmpty(startDate) || !isEmpty(endDate)
            ? "Purchase Date " + " " + startDate + " to " + endDate
            : "Purchase Date - ";
        const DemandType = "Demand #: " + resData.map((x) => x.demandId);
        const DemandAgainst = "Vendor Name: " + resData.map((x) => x.supplier);
        const headers = [
          [
            "CODE",
            "MATERIAL NAME",
            "UOM",
            "PER UNIT PRICE",
            "QUANTITY",
            "VALUE EXCLUDED TAX",
            "TAX",
            "TOTAL VALUE INCLUDED TAX",
          ],
        ];
        const data = resData[0].purchase_data.map((elt) => [
          elt.inventory.warehouse.whKey +
            "-" +
            elt.inventory.category.cateKey +
            "-" +
            elt.inventory.invKey,
          elt.inventory.productName,
          elt.inventory.uom,
          elt.inventory.productPrice,
          elt.productQuantity,
          !isEmpty(elt.productQuantity) && !isEmpty(elt.inventory.productPrice)
            ? (
                Number(elt.productQuantity) * Number(elt.inventory.productPrice)
              ).toFixed(2)
            : 0,
          resData[0].purchaseTax,
          Number(
            (!isEmpty(elt.productQuantity) &&
            !isEmpty(elt.inventory.productPrice)
              ? Number(elt.productQuantity) * Number(elt.inventory.productPrice)
              : 0) + Number(resData[0].purchaseTax)
          ).toFixed(2),
        ]);
        let content = {
          startY: 200,
          startX: 10,
          head: headers,
          body: data,
        };
        doc.addImage(logo, "PNG", 40, 15, 100, 100);
        doc.text(companyName, 200, 60, 0, 20);
        doc.text(demandName, 200, 80, 0, 20);
        doc.text(DemandNumber, 200, 120, 0, 20);
        doc.text(Dates, 380, 120, 0, 20);
        doc.text(DemandType, 200, 140, 0, 20);
        doc.text(DemandAgainst, 380, 140, 0, 20);
        doc.text(title, marginLeft, 165);
        doc.autoTable(content);
        doc.save(
          `${purchaseName} PurchaseReport ${moment(new Date()).format(
            "YYYY-MM-DD"
          )}.pdf`
        );
        dispatch(successSearchPurchase(resData));
      })
      .catch((error) => {
        console.log(error,"ttttttttttttttttttttttttt")
        dispatch(errorSearchPurchase());
      });
  };
};

export const requestSearchIGP = () => {
  return {
    type: "REQUEST_SEARCH_IGP",
  };
};
export const successSearchIGP = (data) => {
  return {
    type: "SUCCESS_SEARCH_IGP",
    payload: data,
  };
};
export const errorSearchIGP = () => {
  return {
    type: "ERROR_SEARCH_IGP",
  };
};

export const searchIGPReport = (data, igpName, startDate, endDate) => {
  return (dispatch) => {
    dispatch(requestSearchIGP());
    API.post(`/igp/get_igp_search`, data)
      .then((res) => {
        let resData = res.data;
        const unit = "pt";
        const size = "A4";
        const orientation = "potrait";

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(11);
        const title = "IGP Report";
        const companyName = `Company Name: ${process.env.REACT_APP_COMPANY_NAME}`;
        const demandName = "IGP Name: " + igpName;
        const IGPNumber = "IGP #: " + resData.map((x) => x.igpId);
        const Dates =
          !isEmpty(startDate) || !isEmpty(endDate)
            ? "Purchase Date " + " " + startDate + " to " + endDate
            : "Purchase Date - ";
        const DemandNumber =
          "Purchase Order #: " + resData.map((x) => x.purchaseOrderId);
        const DemandType = "Demand #: " + resData.map((x) => x.demandId);
        const DemandAgainst = "Receiver Name:";
        const headers = [
          [
            "CODE",
            "MATERIAL NAME",
            "UOM",
            "VENDOR",
            "PER UNIT PRICE",
            "QUANTITY",
            "VALUE EXCLUDED TAX",
            "TAX",
            "TOTAL VALUE INCLUDED TAX",
          ],
        ];
        const data = !isEmpty(resData) ? (
          resData[0].igp_data.map((elt) => [
            elt.inventory.warehouse.whKey +
              "-" +
              elt.inventory.category.cateKey +
              "-" +
              elt.inventory.sub_category.subcateKey +
              "-" +
              elt.inventory.invKey,
            elt.inventory.productName,
            elt.inventory.uom,
            elt.inventory.VendorName,
            elt.inventory.productPrice,
            elt.productQuantity,
            !isEmpty(elt.productQuantity) &&
            !isEmpty(elt.inventory.productPrice)
              ? (
                  Number(elt.productQuantity) *
                  Number(elt.inventory.productPrice)
                ).toFixed(2)
              : 0,
            resData[0].igpTax,
            Number(
              (!isEmpty(elt.productQuantity) &&
              !isEmpty(elt.inventory.productPrice)
                ? Number(elt.productQuantity) *
                  Number(elt.inventory.productPrice)
                : 0) + Number(resData[0].igpTax)
            ).toFixed(2),
          ])
        ) : (
          <span>No Data Found</span>
        );
        let content = {
          startY: 200,
          startX: 10,
          head: headers,
          body: data,
        };
        doc.addImage(logo, "PNG", 40, 15, 100, 100);
        doc.text(companyName, 200, 60, 0, 20);
        doc.text(demandName, 200, 80, 0, 20);
        doc.text(IGPNumber, 200, 120, 0, 20);
        doc.text(Dates, 380, 120, 0, 20);
        doc.text(DemandNumber, 200, 130, 0, 20);
        doc.text(DemandType, 200, 140, 0, 20);
        doc.text(DemandAgainst, 380, 140, 0, 20);
        doc.text(title, marginLeft, 165);
        doc.autoTable(content);
        doc.save(
          `${igpName} IGPReport ${moment(new Date()).format("YYYY-MM-DD")}.pdf`
        );
        dispatch(successSearchIGP(resData));
      })
      .catch((error) => {
        dispatch(errorSearchIGP());
      });
  };
};
//issuance Report by id

export const requestSearchIssuance = () => {
  return {
    type: "REQUEST_SEARCH_ISSUANCE",
  };
};
export const successSearchIssuance = (data) => {
  return {
    type: "SUCCESS_SEARCH_ISSUANCE",
    payload: data,
  };
};
export const errorSearchIssuance = () => {
  return {
    type: "ERROR_SEARCH_ISSUANCE",
  };
};

export const searchIssuanceReport = (data, issuanceName) => {
  return (dispatch) => {
    dispatch(requestSearchIssuance());
    API.post(`/issuance/get-search-issuance`, data)
      .then((res) => {
        let resData = res.data;
        const unit = "pt";
        const size = "A4";
        const orientation = "potrait";

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(11);
        const title = "Issuance Report";
        const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
        const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
        const companyNtn = `${process.env.REACT_APP_COMPANY_NTN}`;
        const demandName = "Issuance #: " + issuanceName;
        const IssuanceDate = resData[0].issuance_date;
        const issuedBy = resData[0].issuedBy;
        const receivedBy = resData[0].receivedBy;
        const supervisor = resData[0].supervisor;
       let data1= doc.splitTextToSize(`Details/Supervisor :${supervisor}`, 50)
       console.log("testDataaaaaaaaa",data1)
        const department = resData[0].department;
        // const IGPNumber = "Issuance #: " + resData.map((x) => x.issuanceId);
        //  const Dates = !isEmpty(startDate) || !isEmpty(endDate) ? "Issuance Date " + " " + startDate + " to " + endDate : "Issuance Date - ";
        const headers = [
          ["CODE", "MATERIAL NAME", "UOM", "PRICE", "QUANTITY", "TOTAL"],
        ];
        const data2 = resData[0].issuance_data.map((elt) => [
          elt.inventory.warehouse.whKey +
            "-" +
            elt.inventory.category.cateKey +
            "-" +
            elt.inventory.sub_category.subcateKey +
            "-" +
            elt.inventory.invKey,
          elt.inventory.productName,
          elt.inventory.uom,
          elt.inventoryPrice,
          elt.productQuantity,
          elt.inventoryPrice * elt.productQuantity,
        ]);
        const Total = sumBy(data2, "5");
        // const shortTotal=Total.slice(12);

        doc.addImage(logo, "PNG", 40, 15, 100, 100);
        doc.setFontSize(20);
        doc.setFont(undefined, "bold");
        doc.text(companyName, 150, 40, 0, 20);
        doc.setFontSize(13);
        doc.setFont("TimesNewRoman", "Normal");
        doc.text(companyAddress, 150, 60, 0, 20);
        doc.text(companyNtn, 150, 80, 0, 20);
        doc.setFontSize(14);
        doc.setFont(undefined, "bold");
        doc.text(title, 240, 120);
        doc.setFontSize(12);
        doc.setFont(undefined, "normal");
        doc.text(demandName, 40, 140, 0, 20);
        doc.text(`Issuance Date: ${IssuanceDate}`, 420, 140, 0, 20);
        doc.text(`Issued By:${issuedBy}`, 40, 160, 0, 20);
        doc.text(`Received By:${receivedBy}`, 420, 160, 0, 20);
        doc.text(`Department :${department}`, 40, 180, 0, 20);
        doc.text(40, 200, `Details/Supervisor :${supervisor}`, { maxWidth: 500 });
        // console.log(220+doc.internal.getLineHeight()*96/72, "eee")
        let content = {
          startY:250,
          startX: 10,
          head: headers,
          body: data2,
        };
        doc.autoTable(content);
        doc.text(`Total :${Total}`,
          440,
          doc.lastAutoTable.finalY + 20,
          0,
          20
        );
        doc.save(
          `${issuanceName} IssuanceReport ${moment(new Date()).format(
            "YYYY-MM-DD"
          )}.pdf`
        );
        dispatch(successSearchIssuance(resData));
      })
      .catch((error) => {
        console.log(error,"ttttttttttttttt")
        dispatch(errorSearchIssuance());
      });
  };
};

// issuance report by date
export const requestSearchIssuanceByDate = () => {
  return {
    type: "REQUEST_SEARCH_ISSUANCE_BYDATE",
  };
};
export const successSearchIssuanceByDate = (data) => {
  return {
    type: "SUCCESS_SEARCH_ISSUANCE_BYDATE",
    payload: data,
  };
};
export const errorSearchIssuanceByDate = () => {
  return {
    type: "ERROR_SEARCH_ISSUANCE_BYDATE",
  };
};

export const searchIssuanceReportByDate = (
  data,
  startDate,
  endDate,
  handleVisible
) => {
  return (dispatch) => {
    dispatch(requestSearchIssuanceByDate());
    API.post(`/issuance/issuance_product_report`, data)
      .then((res) => {
        let resData = res.data;
        if (isEmpty(resData)) {
          handleVisible();
        } else {
          const unit = "pt";
          const size = "A4";
          const orientation = "potrait";

          const marginLeft = 40;
          const doc = new jsPDF(orientation, unit, size);

          doc.setFontSize(11);
          const title = "Issuance Report";
          const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
          const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
          const companyNtn = `${process.env.REACT_APP_COMPANY_NTN}`;
          // const demandName = "Issuance #: " + issuanceName;
          const IssuanceDate = resData[0].issuance_date;
          const issuedBy = resData[0].issuedBy;
          const receivedBy = resData[0].receivedBy;
          const supervisor = resData[0].supervisor;
          const department = resData[0].department;
          // const IGPNumber = "Issuance #: " + resData.map((x) => x.issuanceId);
          const Dates =
            !isEmpty(startDate) || !isEmpty(endDate)
              ? "Issuance Date " + " " + startDate + " to " + endDate
              : "Issuance Date - ";
          const headers = [["Product NAME", "QUANTITY"]];
          const data2 = resData.map((elt) => [
            elt.inventory.productName,
            elt.total_quantity,
            //    (elt.inventory.productPrice*elt.productQuantity)
          ]);
          const Total = sumBy(resData, "total_quantity");
          // const shortTotal=Total.slice(12);
          let content = {
            startY: 190,
            startX: 10,
            head: headers,
            body: data2,
          };
          doc.addImage(logo, "PNG", 40, 15, 100, 100);
          doc.setFontSize(20);
          doc.setFont(undefined, "bold");
          doc.text(companyName, 150, 40, 0, 20);
          doc.setFontSize(13);
          doc.setFont("TimesNewRoman", "Normal");
          doc.text(companyAddress, 150, 60, 0, 20);
          doc.text(companyNtn, 150, 80, 0, 20);
          doc.setFontSize(14);
          doc.setFont(undefined, "bold");
          doc.text(title, 240, 120);
          doc.setFontSize(12);
          doc.setFont(undefined, "normal");
          doc.text(`From ${startDate} to ${endDate}`, 220, 140);
          // doc.text(`Issuance Date: ${IssuanceDate}`,420, 140, 0, 20);
          // doc.text(`Issued By:${issuedBy}`,40,160,0,20)
          // doc.text(`Received By:${receivedBy}`,420,160,0,20)
          // doc.text(`Department :${department}`,40,180,0,20)
          // doc.text(`Supervisor :${supervisor}`,420,180,0,20)
          // doc.text(Dates, 380, 120, 0, 20);

          doc.autoTable(content);
          doc.text(
            `Total Quantity: ${Total.toFixed(2)}`,
            370,
            doc.lastAutoTable.finalY + 20,
            0,
            20
          );
          doc.save(
            ` IssuanceReport ${moment(new Date()).format("YYYY-MM-DD")}.pdf`
          );
        }
        dispatch(successSearchIssuanceByDate(resData));
      })
      .catch((error) => {
        dispatch(errorSearchIssuanceByDate());
      });
  };
};
//Select Purchase From Demand

export const requestPurchaseFromDemand = () => {
  return {
    type: "REQUEST_PURCHASE_FROM_DEMAND",
  };
};
export const successPurchaseFromDemand = (data) => {
  return {
    type: "SUCCESS_PURCHASE_FROM_DEMAND",
    payload: data,
  };
};
export const errorPurchaseFromDemand = () => {
  return {
    type: "ERROR_PURCHASE_FROM_DEMAND",
  };
};

export const purchaseFromDemandCall = (demandId) => {
  return (dispatch) => {
    dispatch(requestPurchaseFromDemand());
    API.get(`/purchase/get-approved-purchase-by-demandID/${demandId}`)
      .then((res) => {
        let resData = res.data;
        dispatch(successPurchaseFromDemand(resData));
      })
      .catch((error) => {
        dispatch(errorPurchaseFromDemand());
      });
  };
};

export const requestGetApproveDemandNew = () => {
  return {
    type: "REQUEST_GET_APPROVE_DEMAND_NEW",
  };
};
export const successGetApproveDemandNew = (data) => {
  return {
    type: "SUCCESS_GET_APPROVE_DEMAND_NEW",
    payload: data,
  };
};
export const errorGetApproveDemandNew = () => {
  return {
    type: "ERROR_GET_APPROVE_DEMAND_NEW",
  };
};

export const getApproveDemandNew = () => {
  return (dispatch) => {
    dispatch(requestGetApproveDemandNew());
    API.get(`/quantity/get_approved_data_demand`)
      .then((res) => {
        let getData = res.data;
        dispatch(successGetApproveDemandNew(getData));
      })
      .catch((error) => {
        dispatch(errorGetApproveDemandNew());
      });
  };
};

export const requestPurchaseFromDemandNew = () => {
  return {
    type: "REQUEST_PURCHASE_FROM_DEMAND_NEW",
  };
};
export const successPurchaseFromDemandNew = (data) => {
  return {
    type: "SUCCESS_PURCHASE_FROM_DEMAND_NEW",
    payload: data,
  };
};
export const errorPurchaseFromDemandNew = () => {
  return {
    type: "ERROR_PURCHASE_FROM_DEMAND_NEW",
  };
};

export const purchaseFromDemandCallNew = (demandId) => {
  return (dispatch) => {
    dispatch(requestPurchaseFromDemandNew());
    API.get(`/purchase/get-approved-purchase-by-demandID/${demandId}`)
      .then((res) => {
        let resData = res.data;
        dispatch(successPurchaseFromDemandNew(resData));
      })
      .catch((error) => {
        dispatch(errorPurchaseFromDemandNew());
      });
  };
};

export const requestAddReceiving = () => {
  return {
    type: "REQUEST_ADD_RECEIVING",
  };
};
export const successAddReceiving = (data) => {
  return {
    type: "SUCCESS_ADD_RECEIVING",
    payload: data,
  };
};
export const errorAddReceiving = () => {
  return {
    type: "ERROR_ADD_RECEIVING",
  };
};

export const addReceiving = (data, handleVisible, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(requestAddReceiving());
    API.post(`/receiving/add_receiving`, data)
      .then((res) => {
        dispatch(getReceiving());
        dispatch(successAddReceiving(data));
        handleVisible();
      })
      .catch((error) => {
        dispatch(errorAddReceiving());
        handleDangerVisible();
      });
  };
};

export const requestGetReceiving = () => {
  return {
    type: "REQUEST_GET_RECEIVING",
  };
};
export const successGetReceiving = (data) => {
  return {
    type: "SUCCESS_GET_RECEIVING",
    payload: data,
  };
};
export const errorGetReceiving = () => {
  return {
    type: "ERROR_GET_RECEIVING",
  };
};

export const getReceiving = () => {
  return (dispatch) => {
    dispatch(requestGetReceiving());
    API.get(`/receiving/get_receiving_menu`)
      .then((res) => {
        let getData = res.data;
        dispatch(successGetReceiving(getData));
      })
      .catch((error) => {
        dispatch(errorGetReceiving());
      });
  };
};

//recivig report by id

export const requestSearchReceiving = () => {
  return {
    type: "REQUEST_SEARCH_RECEIVING",
  };
};
export const successSearchReceiving = (data) => {
  return {
    type: "SUCCESS_SEARCH_RECEIVING",
    payload: data,
  };
};
export const errorSearchReceiving = () => {
  return {
    type: "ERROR_SEARCH_RECEIVING",
  };
};

export const searchReceivingReport = (
  data,
  issuanceName,
  startDate,
  endDate,
  handleVisible
) => {
  return (dispatch) => {
    dispatch(requestSearchReceiving());
    API.post(`/receiving/get_receiving_report`, data)
      .then((res) => {
        let resData = res.data;
        isEmpty(resData) && handleVisible();
        const unit = "pt";
        const size = "A4";
        const orientation = "potrait";

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(11);
        const title = "Receiving Report";
        const companyName = `Company Name: ${process.env.REACT_APP_COMPANY_NAME}`;
        const demandName = "Receiving Name: " + issuanceName;
        const IGPNumber = "Receiving #: " + resData.map((x) => x.receiving_id);
        const Dates =resData[0].receiving_date
        const headers = [
          ["CODE", "MATERIAL NAME", "UOM", "PRICE", "QUANTITY", "VALUE"],
        ];
        const data = resData[0].receiving_data.map((elt) => [
          elt.inventory.warehouse.whKey +
            "-" +
            elt.inventory.category.cateKey +
            "-" +
            elt.inventory.invKey,
          elt.inventory.productName,
          elt.inventory.uom,
          elt.inventoryPrice,
          elt.productQuantity,
          Number(elt.inventoryPrice) * Number(elt.productQuantity),
        ]);
        const TotalPrice =
          "Total Price: " +
          sumBy(data, function (o) {
            return Number(o[3]);
          });
        const TotalQuantity =
          "Total Quantity: " +
          sumBy(data, function (o) {
            return Number(o[4]);
          });
        const TotalValue =
          "Total Value: " +
          sumBy(data, function (o) {
            return Number(o[5]);
          });
        const headersSigned = [
          ["Prepared By:", "Approved By:", "Audited By:", "Recieved By:"],
        ];
        let content = {
          startY: 220,
          startX: 10,
          head: headers,
          body: data,
        };
        doc.addImage(logo, "PNG", 40, 15, 100, 100);
        doc.text(companyName, 200, 60, 0, 20);
        doc.text(demandName, 200, 80, 0, 20);
        doc.text(`Reciving Date ${Dates}`, 200, 100, 0, 20);
        doc.text(IGPNumber, 200, 120, 0, 20);
        
        doc.text(TotalPrice, marginLeft, 158);
        doc.text(TotalQuantity, marginLeft, 170);
        doc.text(TotalValue, marginLeft, 188);
        doc.text(title, marginLeft, 210);
        doc.autoTable(content);
        let contentSign = {
          startY: doc.lastAutoTable.finalY + 10,
          startX: 5,
          head: headersSigned,
          0: { cellWidth: 130 },
          1: { cellWidth: 130 },
          2: { cellWidth: 130 },
          3: { cellWidth: 130 },
          body: [
            [
              { content: "", rowSpan: 5 },
              { content: "", rowSpan: 5 },
              { content: "", rowSpan: 5 },
              { content: "", rowSpan: 5 },
            ],
          ],
          theme: "grid",
          headStyles: {
            fillColor: "#2980ba",
          },

          bodyStyles: {
            minCellHeight: 300,
          },
        };
        doc.autoTable(contentSign);
        doc.save(
          `${issuanceName} ReceivingReport ${moment(new Date()).format(
            "YYYY-MM-DD"
          )}.pdf`
        );
        dispatch(successSearchReceiving(resData));
      })
      .catch((error) => {
        dispatch(errorSearchReceiving());
      });
  };
};
//recivig rport by date
export const requestSearchReceivingByDate = () => {
  return {
    type: "REQUEST_SEARCH_RECEIVING_BYDATE",
  };
};
export const successSearchReceivingByDate = (data) => {
  return {
    type: "SUCCESS_SEARCH_RECEIVING_BYDATE",
    payload: data,
  };
};
export const errorSearchReceivingByDate = () => {
  return {
    type: "ERROR_SEARCH_RECEIVING_BYDATE",
  };
};

export const searchReceivingReportByDate = (
  data,
  startDate,
  endDate,
  handleVisible
) => {
  return (dispatch) => {
    dispatch(requestSearchReceivingByDate());
    API.post(`/receiving/receiving_product_report`, data)
      .then((res) => {
        let resData = res.data;
        if (isEmpty(resData)) {
          handleVisible();
        }
        // isEmpty(resData) && handleVisible()
        else {
          const unit = "pt";
          const size = "A4";
          const orientation = "potrait";

          const marginLeft = 40;
          const doc = new jsPDF(orientation, unit, size);

          doc.setFontSize(11);
          const title = "GRN Report";
          const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
          const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
          const companyNtn = `${process.env.REACT_APP_COMPANY_NTN}`;
          const headers = [["Product NAME", "QUANTITY"]];
          const data = resData.map((elt) => [
            elt.productName,
            Number(elt.total_quantity),
          ]);
          const Total = sumBy(resData, `total_quantity`);
          const headersSigned = [
            ["Prepared By:", "Approved By:", "Audited By:", "Recieved By:"],
          ];
          let content = {
            startY: 200,
            startX: 10,
            head: headers,
            body: data,
          };
          doc.addImage(logo, "PNG", 40, 15, 100, 100);
          doc.text(companyName, 150, 40, 0, 20);
          doc.setFontSize(13);
          doc.setFont("TimesNewRoman", "Normal");
          doc.text(companyAddress, 150, 60, 0, 20);
          doc.text(companyNtn, 150, 80, 0, 20);
          doc.setFontSize(14);
          doc.setFont(undefined, "bold");
          doc.text(title, 240, 120);
          doc.setFontSize(12);
          doc.setFont(undefined, "normal");
          doc.text(`From ${startDate} to ${isUndefined(endDate)?"":endDate}`, 220, 140);
          doc.autoTable(content);
          doc.text(
            `Total Quantity:${Total}`,
            370,
            doc.lastAutoTable.finalY + 10
          );
          let contentSign = {
            startY: doc.lastAutoTable.finalY + 30,
            startX: 5,
            head: headersSigned,
            0: { cellWidth: 130 },
            1: { cellWidth: 130 },
            2: { cellWidth: 130 },
            3: { cellWidth: 130 },
            body: [
              [
                { content: "", rowSpan: 5 },
                { content: "", rowSpan: 5 },
                { content: "", rowSpan: 5 },
                { content: "", rowSpan: 5 },
              ],
            ],
            theme: "grid",
            headStyles: {
              fillColor: "#2980ba",
            },

            bodyStyles: {
              minCellHeight: 300,
            },
          };
          doc.autoTable(contentSign);
          doc.save(
            `Receiving Report ${moment(new Date()).format("YYYY-MM-DD")}.pdf`
          );
        }
        dispatch(successSearchReceivingByDate(resData));
      })
      .catch((error) => {
        console.log(error,"jjjjjjjjjjjjjjjjj")
        dispatch(errorSearchReceivingByDate());
      });
  };
};
//get accout master
export const requestGetAccountMasterControl = () => {
  return {
    type: "REQUEST_GET_ACCOUNT_MASTER_CONTROL",
  };
};
export const successGetAccountMasterControl = (data) => {
  return {
    type: "SUCCESS_GET_ACCOUNT_MASTER_CONTROL",
    payload: data,
  };
};
export const errorGetAccountMasterControl = () => {
  return {
    type: "ERROR_GET_ACCOUNT_MASTER_CONTROL",
  };
};

export const getAccountMasterControl = () => {
  return (dispatch) => {
    dispatch(requestGetAccountMasterControl());
    API.get(`/acc_master_control`)
      .then((res) => {
        let getData = res.data;
        dispatch(successGetAccountMasterControl(getData));
      })
      .catch((error) => {
        dispatch(errorGetAccountMasterControl());
      });
  };
};
//add accout master
export const requestAddAccountMasterControl = () => {
  return {
    type: "REQUEST_ADD_ACCOUNT_MASTER_CONTROL",
  };
};
export const successAddAccountMasterControl = (data) => {
  return {
    type: "SUCCESS_ADD_ACCOUNT_MASTER_CONTROL",
    payload: data,
  };
};
export const errorAddAccountMasterControl = () => {
  return {
    type: "ERROR_ADD_ACCOUNT_MASTER_CONTROL",
  };
};
export const addAccountMasterControl = (data, handleVisible, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(requestAddAccountMasterControl());
    API.post(`/acc_master_control`, data)
      .then((res) => {
        dispatch(getAccountMasterControl());
        dispatch(successAddAccountMasterControl(data));
        handleVisible();
      })
      .catch((error) => {
        dispatch(errorAddAccountMasterControl());
        handleDangerVisible();
      });
  };
};
//delete accout master 
export const DeleteRequestAccountMasterControl = () => {
  return {
    type: "DELETE_REQUEST_ACCOUNT_MASTER_CONTROL",
  };
};
export const DeleteSuccessAccountMasterControl = (user_id) => {
  return {
    type: "DELETE_SUCCESS_ACCOUNT_MASTER_CONTROL",
    user_id: user_id,
  };
};
export const DeleteErrorAccountMasterControl = () => {
  return {
    type: "DELETE_ERROR_ACCOUNT_MASTER_CONTROL",
  };
};

export const deleteAccountMasterControl = (wareHouseId) => {
  return (dispatch) => {
    dispatch(DeleteRequestAccountMasterControl());
    API.delete(`/acc_master_control/${wareHouseId}`)
      .then((res) => {
        dispatch(getAccountMasterControl());
        dispatch(DeleteSuccessAccountMasterControl(wareHouseId));
      })
      .catch((error) => dispatch(DeleteErrorAccountMasterControl()));
  };
};
//add recivable accout
export const requestAddRecivableAccount = () => {
  return {
    type: "REQUEST_ADD_RECIVABLE_ACCOUNT",
  };
};
export const successAddRecivableAccount = (data) => {
  return {
    type: "SUCCESS_ADD_RECIVABLE_ACCOUNT",
    payload: data,
  };
};
export const errorAddRecivableAccount = () => {
  return {
    type: "ERROR_ADD_RECIVABLE_ACCOUNT",
  };
};
export const addRecivableAccount = (data, handleVisible, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(requestAddRecivableAccount());
    API.post(`/invoice/add_invoice_MS`, data)
      .then((res) => {
         dispatch(getRecivableAccount());
        dispatch(successAddRecivableAccount(data));
        handleVisible();
      })
      .catch((error) => {
        dispatch(errorAddRecivableAccount());
        handleDangerVisible();
      });
  };
};
//get RECIVABLE accout
export const requestGetRecivableAccount = () => {
  return {
    type: "REQUEST_GET_RECIVABLE_ACCOUNT",
  };
};
export const successGetRecivableAccount = (data) => {
  return {
    type: "SUCCESS_GET_RECIVABLE_ACCOUNT",
    payload: data,
  };
};
export const errorGetRecivableAccount = () => {
  return {
    type: "ERROR_GET_RECIVABLE_ACCOUNT",
  };
};
export const getRecivableAccount = () => {
  return (dispatch) => {
    dispatch(requestGetRecivableAccount());
    API.get(`/invoice/get_invoice_MS`)
      .then((res) => {
        let getData = res.data;
        dispatch(successGetRecivableAccount(getData));
      })
      .catch((error) => {
        dispatch(errorGetRecivableAccount());
      });
  };
};
//add level3 accout
export const requestAddLevel3Account = () => {
  return {
    type: "REQUEST_ADD_LEVEL3_ACCOUNT",
  };
};
export const successAddLevel3Account = (data) => {
  return {
    type: "SUCCESS_ADD_LEVEL3_ACCOUNT",
    payload: data,
  };
};
export const errorAddLevel3Account = () => {
  return {
    type: "ERROR_ADD_LEVEL3_ACCOUNT",
  };
};
export const addLevel3Account = (data, handleVisible, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(requestAddLevel3Account());
    API.post(`/invoice/add_invoice_MS`, data)
      .then((res) => {
         dispatch(getLevel3Account());
        dispatch(successAddLevel3Account(data));
        handleVisible();
      })
      .catch((error) => {
        dispatch(errorAddLevel3Account());
        handleDangerVisible();
      });
  };
};
//get selected level3 
export const requestGetLevel3Account = () => {
  return {
    type: "REQUEST_GET_LEVEL3_ACCOUNT",
  };
};
export const successGetLevel3Account = (data) => {
  return {
    type: "SUCCESS_GET_LEVEL3_ACCOUNT",
    payload: data,
  };
};
export const errorGetLevel3Account = () => {
  return {
    type: "ERROR_GET_LEVEL3_ACCOUNT",
  };
};
export const getLevel3Account = () => {
  return (dispatch) => {
    dispatch(requestGetLevel3Account());
    API.get(`/invoice/get_invoice_MS`)
      .then((res) => {
        let getData = res.data;
        dispatch(successGetLevel3Account(getData));
      })
      .catch((error) => {
        dispatch(errorGetLevel3Account());
      });
  };
};
//edit master accout
export const UpdateRequestAccountMasterControl = () => {
  return {
    type: "UPDATE_REQUEST_ACCOUNT_MASTER_CONTROL",
  };
};
export const UpdateSuccessAccountMasterControl = (data) => {
  return {
    type: "UPDATE_SUCCESS_ACCOUNT_MASTER_CONTROL",
    payload: data,
  };
};

export const UpdateErrorAccountMasterControl = () => {
  return {
    type: "UPDATE_ERROR_ACCOUNT_MASTER_CONTROL",
  };
};

export const updateAccountMasterControl = (data, oldData) => {
  return (dispatch) => {
    dispatch(UpdateRequestAccountMasterControl());
    API.put(
      `/acc_master_control/${oldData.wareHouseId}`,
      {
        levelFiveName: data.levelFiveName,
        // whKey: data.warehouseKey,
        userId: window.localStorage.getItem("userId"),
      },
      {}
    )
      .then((res) => {
        dispatch(getAccountMasterControl());
        dispatch(UpdateSuccessAccountMasterControl([data]));
      })
      .catch((error) => dispatch(UpdateErrorAccountMasterControl()));
  };
};