import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Container,
  Button,
  FormGroup,
  Alert,
} from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty, find, sumBy } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import "jspdf-autotable";
import Select from "react-select";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { IconButton } from "@material-ui/core";
import Swal from "sweetalert";
import { addInvoice, getCustomer } from "./InvoiceDetails.actions";
import moment from "moment";
import { getAllInventory } from "../AddDetails.actions";

const AddInvoice = (props) => {
  const [customer, setCustomer] = useState("");
  const [customerLabel, setCustomerLabel] = useState("");
  const [Date, setDate] = useState("");
  const [addDesc, setAddDesc] = useState("");
  const [grandTotal, setGrandTotal] = useState(0);
  const [ManualNo, setManualNo] = useState();
  const [saleTaxRegNo, setSaleTaxRegNo] = useState();
  const [NTN, setNTN] = useState();
  const [CNIC, setCNIC] = useState();
  // const [CNIC, setCNIC] = useState();
  const [inputField, setInputField] = useState([
    {
      inventoryId: 0,
      invoice_data_price: 0,
      invoice_data_quantity: 0,
      total_price_inv: 0,
    },
  ]);

  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
  const getCustomer = props.customerInfo.map((x) => {
    console.log(x, "tttttttttttt");
    let data = {
      label: `${x.levelFiveId} - ${x.levelFiveName}`,
      value: x.levelFiveId,
    };
    return data;
  });

  function uploadDepartment() {
    props.addInvoice(
      {
        levelFiveId: customer,
        invoice_desc: addDesc,
        invoice_date: Date,
        data: inputField,
        invoice_total_amount: grandTotal,
      },
      handleVisible,
      handleDangerVisible
    );
    setCustomer("");
    setCustomerLabel("");
    setDate("");
    setAddDesc("");
    setGrandTotal("");
    setInputField([
      {
        inventoryId: 0,
        invoice_data_price: 0,
        invoice_data_quantity: 0,
        total_price_inv: 0,
      },
    ]);
  }
  const inventory = props.allInventory.map((x) => {
    let data = {
      value: x.inventoryId,
      label:
        x.warehouse.whKey +
        "-" +
        x.category.cateKey +
        "-" +
        x.sub_category.subcateKey +
        "-" +
        x.invKey +
        " " +
        x.productName,
    };
    return data;
  });
  const clearStates = () => {
    // setDivisionId(0);
    // setDivisionName();
    // setDepartmentName();
    // setDepartmentDescription();
  };

  const handleVisible = () => {
    setAlertVisible(true);
    clearStates();
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: "Added successfully...",
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = () => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: "Something went wrong...",
      icon: "error",
      button: "Ok",
    });
  };
  const setCustomerInfo = (selectedOption) => {
    setCustomer(selectedOption.value);
    setCustomerLabel(selectedOption.label);
  };
  const handleChange = (e, index, type) => {
    console.log(e, index, type, "test");
    const list = [...inputField];
    if (type == "intquantity")
      list[index]["invoice_data_quantity"] = e.target.value;
    let z = find(props.allInventory, (x) => x.inventoryId == e.value);
    console.log(z, "zzzzzzzzzzzzzzzzz");
    if (type == "intname") {
      list[index]["inventoryId"] = e.value;
      list[index]["inventoryLabel"] = e.label;
    }

    if (type == "intname")
      list[index]["invoice_data_price"] = Number(z.productPrice);
    if (type == "invoice_data_price")
      list[index]["invoice_data_price"] = Number(e.target.value);

    list[index]["total_price_inv"] =
      list[index]["invoice_data_price"] * list[index]["invoice_data_quantity"];

    setGrandTotal(sumBy(list, "total_price_inv"));
    setInputField(list);
  };

  const handleAddInput = () => {
    setInputField([
      ...inputField,
      {
        inventoryId: 0,
        invoice_data_price: 0,
        invoice_data_quantity: 0,
        total_price_inv: 0,
      },
    ]);
  };

  const handleRemoveInput = (index) => {
    const list = [...inputField];
    list.splice(index, 1);
    setInputField(list);
    setGrandTotal(
      list.length === 1
        ? list[0].total_price_inv
        : sumBy(list, "total_price_inv")
    );
  };
  useEffect(() => {
    props.getAllInventory({
      startedDate: "",
      endDate: "",
      wareHouseId: "",
      subCategoryId: "",
      categoryId: "",
    });
    props.getCustomer();
  }, []);
  return (
    <>
      {props.isFetchingGetCustomer ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {/* {props.isFetchingwareHouseList ? 'Loading...' : 'Loading...'} */}
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Form>
              <Row>
                <Col xl="6" lg="6" md="6" xs="12">
                  <Form.Group className="mb-3">
                    <Form.Label>
                      <b>Date *</b>
                    </Form.Label>
                    <Form.Control
                      type="date"
                      value={Date}
                      onChange={(e) => setDate(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                </Col>
                <Col xl="6" lg="6" md="6" xs="12">
                  <Form.Group className="mb-3">
                    <Form.Label>
                      <b>Customer *</b>
                    </Form.Label>
                    <Select
                      placeholder="Select Customer..."
                      onChange={setCustomerInfo}
                      options={getCustomer}
                      value={{ label: customerLabel }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xl="6" lg="6" md="6" xs="6">
                  <Form.Group className="mb-3">
                    <Form.Label>
                      <b>Additional Description</b>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={addDesc}
                      onChange={(e) => setAddDesc(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                </Col>
                <Col xl="6" lg="6" md="6" xs="6">
                  <Form.Group className="mb-3">
                    <Form.Label>
                      <b>Grand Total</b>
                    </Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Grand Total"
                      name="GrandTotal"
                      disabled
                      value={grandTotal}
                    />
                  </Form.Group>
                </Col>
              </Row>
              {/* <Col xl="4" lg="4" md="4" xs="4">
                    <Form.Group className="mb-3">
                      <Form.Label><b>Manuall No</b></Form.Label>
                      <Form.Control
                        type="text"
                        onChange={(e) => setManualNo(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xl="4" lg="4" md="4" xs="4">
                    <Form.Group className="mb-3">
                      <Form.Label><b>Registeration No</b></Form.Label>
                      <Form.Control
                        type="text"
                        onChange={(e) => setSaleTaxRegNo(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xl="4" lg="4" md="4" xs="4">
                    <Form.Group className="mb-3">
                      <Form.Label><b>NTN</b></Form.Label>
                      <Form.Control
                        type="text"
                        onChange={(e) => setNTN(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xl="4" lg="4" md="4" xs="4">
                    <Form.Group className="mb-3">
                      <Form.Label><b>CNIC</b></Form.Label>
                      <Form.Control
                        type="Number"
                        onChange={(e) => setCNIC(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                 
                </Row> */}
              {inputField.map((item, i) => {
                console.log(item, "yyyyyyyyyyyyyyyyyyyyyy");
                return (
                  <React.Fragment key={i}>
                    <Row>
                      <Col xl="5" lg="5" md="7" sm="7">
                        <Form.Group>
                          <Form.Label>
                            <b> Select Inventory *</b>
                          </Form.Label>
                          <Select
                            value={{ label: item.inventoryLabel }}
                            id="inventoryId"
                            placeholder="Select Inventory..."
                            name="inventoryId"
                            onChange={(e) => handleChange(e, i, "intname")}
                            options={inventory}
                            isOptionDisabled={(option) =>
                              !isEmpty(
                                find(
                                  inputField,
                                  (x) => x.inventoryId == option.value
                                )
                              )
                            }
                          />
                        </Form.Group>
                      </Col>
                      <Col xl="3" lg="3" md="5" sm="5">
                        <Form.Group>
                          <Form.Label>
                            <b>Inventory Price *</b>
                          </Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="Inventory Price"
                            name="Inventory Price"
                            value={item.invoice_data_price}
                            min={0}
                            onChange={(e) =>
                              handleChange(e, i, "invoice_data_price")
                            }
                          />
                        </Form.Group>
                      </Col>

                      <Col xl="2" lg="2" md="6" sm="6">
                        <Form.Group className="mb-3">
                          <Form.Label>
                            <b>Quantity *</b>
                          </Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="Quantity...."
                            name="Quantity"
                            value={item.invoice_data_quantity}
                            min={0}
                            onChange={(e) => handleChange(e, i, "intquantity")}
                          />
                        </Form.Group>
                      </Col>

                      <Col xl="2" lg="2" md="6" sm="6">
                        <Form.Group className="mb-3">
                          <Form.Label>
                            <b>Total Price</b>
                          </Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="Total Price...."
                            name="TotalPrice"
                            disabled
                            value={item.total_price_inv}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <FormGroup>
                      <Row>
                        <Col lg="12">
                          {inputField.length - 1 == i && (
                            <IconButton
                              title="Click to Add Invoice"
                              onClick={handleAddInput}
                              style={{
                                float: "right",
                                color: "black",
                              }}
                            >
                              <AddIcon />
                            </IconButton>
                          )}

                          {inputField.length !== 1 && (
                            <IconButton
                              title="Click to Remove Invoice"
                              onClick={() => handleRemoveInput(i)}
                              style={{ float: "right", color: "black" }}
                            >
                              <RemoveIcon />
                            </IconButton>
                          )}
                        </Col>
                      </Row>
                    </FormGroup>
                  </React.Fragment>
                );
              })}

              <div className="sendDiv">
                {isEmpty(Date) ||
                customer == "" ||
                inputField[0].inventoryId == "" ||
                inputField[0].invoice_data_price == "" ||
                inputField[0].invoice_data_quantity == 0 ? (
                  <Button
                    className="sendButton"
                    onClick={uploadDepartment}
                    disabled
                    style={{ backgroundColor: "black" }}
                  >
                    Save
                  </Button>
                ) : (
                  <Button className="sendButton" onClick={uploadDepartment}>
                    Save
                  </Button>
                )}
              </div>
            </Form>
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addInvoice: (data, handleVisible, handleDangerVisible) =>
      dispatch(addInvoice(data, handleVisible, handleDangerVisible)),
    getAllInventory: (data) => dispatch(getAllInventory(data)),
    // GetHRDepartment: () => dispatch(GetHRDepartment()),
    getCustomer: () => dispatch(getCustomer()),
    // updateHRDepartment: (newData, oldData) => dispatch(updateHRDepartment(newData, oldData)),
    // deleteHRDepartment: (departmentId) => dispatch(deleteHRDepartment(departmentId)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  isFetchingGetCustomer: state.InvoiceReducer.isFetchingGetCustomer,
  customerInfo: state.InvoiceReducer.customerInfo,
  allInventory: state.AddDataReducer.allInventory,
  isFetchingInventoryAll: state.AddDataReducer.isFetchingInventoryAll,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddInvoice);
