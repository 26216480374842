import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, Alert } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty, isUndefined } from "lodash";
import Spinner from 'react-bootstrap/Spinner';
import MaterialTable from 'material-table';
import Select from 'react-select';
// import { Input } from "reactstrap";
import 'jspdf-autotable';
import ImageUploading from 'react-images-uploading';
import { getSizeCall, getUOMCall, getColorCall, getDivisionCall, getCustomerCall, getBrandCall, getProductCall, getSubProductCall, getCatalogCall } from "../../production/production.actions";
import { getSubProductDetailCall, addArticleCall, getArticleCall } from "./AddArticles.actions";
import ImageUploader from 'react-images-upload';
import { IsoRounded } from "@material-ui/icons";
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { IconButton } from "@material-ui/core";
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import { Select as MSelect } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';


const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 300,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

// function getStyles(name, value, theme) {
//     return {
//         fontWeight:
//             value.indexOf(name) === -1
//                 ? theme.typography.fontWeightRegular
//                 : theme.typography.fontWeightMedium,
//     };
// }

const AddAricles = (props) => {

    const classes = useStyles();
    const theme = useTheme();

    const getDivision = props.divisionInfo.map((x) => { let data = { label: x.divisionName, value: x.divisionId }; return data });
    const getCustomer = props.customerInfo.map((x) => { let data = { label: x.customerPerson, value: x.customerId }; return data });
    const getBrand = props.brandInfo.map((x) => { let data = { label: x.brandName, value: x.brandId }; return data });
    const getUom = props.uomInfo.map((x) => { let data = { label: x.uomName, value: x.uomId }; return data });
    const getSize = props.sizeInfo.map((x) => { let data = { label: x.sizeName, value: x.sizeId }; return data });
    const getColor = props.colorInfo.map((x) => { let data = { label: x.colorName, value: x.colorId }; return data });
    const getMainProduct = props.productInfo.map((x) => { let data = { label: x.name, value: x.mainProId }; return data });
    // const getSubProduct = !isUndefined(props.subProductDetailInfo.sub_products) && props.subProductDetailInfo.sub_products.map((x) => { let data = { label: x.name, value: x.subProId }; return data });
    const getCatalog = props.catalogInfo.map((x) => { let data = { label: x.name, value: x.catelogId }; return data });

    const [division, setDivision] = useState("");
    const [customer, setCustomer] = useState("");
    const [brand, setBrand] = useState("");
    const [UOM, setUOM] = useState("");
    const [size, setSize] = useState("");
    const [color, setColor] = useState([]);
    const [catalog, setCatalog] = useState("");
    const [mainProduct, setMainProduct] = useState("");
    const [subProduct, setSubProduct] = useState("");

    console.log(props.subProductDetailInfo, "qqww")
    console.log(color, "qqwwrr")

    const [weight, setWeight] = useState("");
    const [hsCode, setHSCode] = useState("");
    const [composition, setComposition] = useState("");
    const [packing, setPacking] = useState("");
    const [description, setDescription] = useState("");
    const [instruction, setInstruction] = useState("");
    const [product, setProduct] = useState("");
    const [manualCode, setManualCode] = useState();
    const [customerCode, setCustomerCode] = useState("");
    const [exportCost, setExportCost] = useState("");

    const [images, setImages] = React.useState([]);

    const [inputField, setInputField] = useState([{ size: "", color: [] }]);

    const onDrop = (pictureFiles, pictureDataURLs) => {
        setImages(pictureFiles);
    }

    const setDevisionInfo = (selectedOption) => {
        setDivision(selectedOption.value);
        setManualCode("")
    };

    const setCustomerInfo = (selectedOption) => {
        setCustomer(selectedOption.value);
    };

    const setBrandInfo = (selectedOption) => {
        setBrand(selectedOption.value);
    };

    const setUomInfo = (selectedOption) => {
        setUOM(selectedOption.value);
    };

    const setSizeInfo = (selectedOption) => {
        setSize(selectedOption.value);
    };

    const setColorInfo = (selectedOption) => {
        setColor(selectedOption.value);
    };

    const setMainProductInfo = (selectedOption) => {
        setMainProduct(selectedOption.value);
    };

    const setSubProductInfo = (selectedOption) => {
        setSubProduct(selectedOption.value);
    };

    const setCatalogInfo = (selectedOption) => {
        setCatalog(selectedOption.value);
    };

    const handleChangeSize = (event) => {
        setSize(event.target.value);
        console.log(event, "change")
    };

    const handleChangeColor = (event) => {
        setColor(event.target.value);
        
    };

    const handleChangeInput = (index, event, value) => {
        const values = [...inputField];
        values[index][event.target.name] = value;
        setInputField(values);
        console.log(index, "index")
        console.log(event, "ee")
        // event.target.name == "size" && setSize(event.target.value);
        // event.target.name == "color" && setColor(event.target.value);
    }

    console.log(inputField, "ppffgg")

    const handleAddFields = () => {
        setInputField([...inputField, { size: "", color: [] }])
    }


    const handleRemoveFields = (index) => {
        const values = [...inputField];
        if (index = 1)
            values.splice(index, 1);
        setInputField(values);
    }

    const sendData = () => {
        props.addArticleCall({
            itemName: product,
            itemCode: hsCode,
            catelogNo: catalog,
            customerCode: customerCode,
            manualCode: manualCode,
            articleWeight: weight,
            exportCost: exportCost,
            dyed: document.getElementById("dyedCB").checked ? "1" : "0",
            composition: composition,
            articleDescription: description,
            specialInstruction: instruction,
            divisionId: division,
            customerId: customer,
            brandId: brand,
            uomId: UOM,
            // colorId: color,
            size: !isEmpty(inputField) && inputField.map((v)=>({
                // ...v,
                sizeId: v.size,
                color: v.color
            })),
            mainProId: mainProduct,
            subProId: subProduct,
            image: images,
            packingQty: packing,
        })
    }

    useEffect(() => {
        props.getSizeCall();
        props.getUOMCall();
        props.getColorCall();
        props.getDivisionCall();
        props.getCustomerCall();
        props.getBrandCall();
        props.getProductCall();
        props.getSubProductCall();
        props.getCatalogCall();
        props.getArticleCall();
    }, []);

    return (
        <Container fluid>
            <div className="main">
            {props.isFetchingAddArticle && <Alert variant={"success"} >
                        Added Data...
                    </Alert>}

                <div>

                    <Row>
                        <Col lg="6" md="6" xs="12">
                            <Form.Group className="mb-3">
                                <Form.Label><b>Division:</b></Form.Label>
                                <Select
                                    placeholder="Select Division..."
                                    onChange={setDevisionInfo}
                                    options={getDivision}
                                />
                            </Form.Group>
                        </Col>

                        <Col lg="6" md="6" xs="12">
                            <Form.Group className="mb-3">
                                <Form.Label><b>Customer:</b></Form.Label>
                                <Select
                                    placeholder="Select Customer..."
                                    onChange={setCustomerInfo}
                                    options={getCustomer}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="6" md="6" xs="12">
                            <Form.Group className="mb-3">
                                <Form.Label><b>Brand:</b></Form.Label>
                                <Select
                                    placeholder="Select Brand..."
                                    onChange={setBrandInfo}
                                    options={getBrand}
                                />
                            </Form.Group>
                        </Col>

                        <Col lg="6" md="6" xs="12">
                            <Form.Group className="mb-3">
                                <Form.Label><b>Main Product:</b></Form.Label>
                                <Select
                                    placeholder="Select Main Product..."
                                    onChange={setMainProductInfo}
                                    options={getMainProduct}
                                    onBlur={() => props.getSubProductDetailCall(mainProduct)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    {/* <Row>
                        <Col lg="6" md="6" xs="12">
                            <Form.Group className="mb-3">
                                <Form.Label><b>Size:</b></Form.Label>
                                <Select
                                    placeholder="Select Size..."
                                    onChange={setSizeInfo}
                                    options={getSize}
                                />
                            </Form.Group>
                        </Col>

                        <Col lg="6" md="6" xs="12">
                            <Form.Group className="mb-3">
                                <Form.Label><b>Color:</b></Form.Label>
                                <Select
                                    placeholder="Select Color..."
                                    onChange={setColorInfo}
                                    options={getColor}
                                />
                            </Form.Group>
                        </Col>
                    </Row> */}

                    <Row>
                        <Col lg="6" md="6" xs="12">
                            <Form.Group className="mb-3">
                                <Form.Label><b>Catalog Reference #:</b></Form.Label>
                                <Select
                                    placeholder="Select Catalog Reference #..."
                                    onChange={setCatalogInfo}
                                    options={getCatalog}
                                />
                            </Form.Group>
                        </Col>

                        <Col lg="6" md="6" xs="12">
                            <Form.Group className="mb-3">
                                <Form.Label><b>Sub Product:</b></Form.Label>
                                <Select
                                    placeholder="Select Sub Product..."
                                    onChange={setSubProductInfo}
                                    options={!isUndefined(props.subProductDetailInfo[0]) && props.subProductDetailInfo[0].sub_products.map((x) => { let data = { label: x.name, value: x.subProId }; return data })}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="6" md="6" xs="12">
                            <Form.Group className="mb-3">
                                <Form.Label><b>UOM:</b></Form.Label>
                                <Select
                                    placeholder="Select UOM..."
                                    onChange={setUomInfo}
                                    options={getUom}
                                />
                            </Form.Group>
                        </Col>

                        <Col lg="6" md="6" xs="12">
                            <Form.Group className="mb-3">
                                <Form.Label><b>Weight:</b></Form.Label>
                                <Form.Control type="text" id="weight" placeholder="Weight...."
                                    onBlur={(e) => setWeight(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="4" md="4" xs="12">
                            <Form.Group className="mb-3">
                                <Form.Label><b>HS Code:</b></Form.Label>
                                <Form.Control type="text" id="hsCode" placeholder="HS Code...."
                                    onBlur={(e) => setHSCode(e.target.value)}
                                />
                            </Form.Group>
                        </Col>

                        <Col lg="4" md="4" xs="12">
                            <Form.Group className="mb-3">
                                <Form.Label><b>Composition:</b></Form.Label>
                                <Form.Control type="text" id="composition" placeholder="Composition...."
                                    onBlur={(e) => setComposition(e.target.value)}
                                />
                            </Form.Group>
                        </Col>

                        <Col lg="4" md="4" xs="12">
                            <Form.Group className="mb-3">
                                <Form.Label><b>Packing Quantity:</b></Form.Label>
                                <Form.Control type="text" id="packingQuantity" placeholder="Packing Quantity...."
                                    onBlur={(e) => setPacking(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>

                        <Col lg="4" md="4" xs="12">
                            <Form.Group className="mb-3">
                                <Form.Label><b>Description:</b></Form.Label>
                                <Form.Control type="text" id="description" placeholder="Description...."
                                    onBlur={(e) => setDescription(e.target.value)}
                                />
                            </Form.Group>
                        </Col>

                        <Col lg="4" md="4" xs="12">
                            <Form.Group className="mb-3">
                                <Form.Label><b>Special Instruction:</b></Form.Label>
                                <Form.Control type="text" id="specialInstruction" placeholder="Special Instruction...."
                                    onBlur={(e) => setInstruction(e.target.value)}
                                />
                            </Form.Group>
                        </Col>

                        <Col lg="4" md="4" xs="12">
                            <Form.Group className="mb-3">
                                <Form.Label><b>Product Name:</b></Form.Label>
                                <Form.Control type="text" id="productName" placeholder="Product Name...."
                                    onBlur={(e) => setProduct(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="3" md="3" xs="12">
                            <Form.Group className="mb-3">
                                <Form.Label><b>Manual Code:</b></Form.Label>
                                <Form.Control type="text" id="manualCode" placeholder="Manual Code...."
                                    onBlur={(e) => setManualCode(e.target.value)}
                                />
                            </Form.Group>
                        </Col>

                        <Col lg="3" md="3" xs="12">
                            <Form.Group className="mb-3">
                                <Form.Label><b>Customer Auto Code:</b></Form.Label>
                                <Form.Control type="text" id="customerAutoCode" placeholder="Customer Auto Code...."
                                    onBlur={(e) => setCustomerCode(e.target.value)}
                                />
                            </Form.Group>
                        </Col>

                        <Col lg="3" md="3" xs="12">
                            <Form.Group className="mb-3">
                                <Form.Label><b>Export Cost:</b></Form.Label>
                                <Form.Control type="text" id="customerAutoCode" placeholder="Export Cost...."
                                    onBlur={(e) => setExportCost(e.target.value)}
                                />
                            </Form.Group>
                        </Col>

                        <Col lg="3" md="3" xs="12">
                            <Form.Group className="mb-3">
                                <Form.Label><b>Dyed:</b></Form.Label>
                                <Input type="checkbox" style={{ marginTop: "40px", marginLeft: "30px", height: "20px", width: "20px" }} id="dyedCB" />
                            </Form.Group>
                        </Col>
                    </Row>

                    {inputField.map((inputField, index) => (
                        <div key={index}>
                            <Row>
                                <Col lg="6" md="6" xs="12">
                                    <FormControl className={classes.formControl}>
                                        <InputLabel id="demo-simple-select-label">Size:</InputLabel>
                                        <MSelect
                                            labelId="demo-simple-select-label"
                                            id="size"
                                            value={inputField.size}
                                            name="size"
                                            onChange={handleChangeSize}
                                            onBlur={(e) => handleChangeInput(index, e, size)}
                                        >
                                            {props.sizeInfo.map((x) => (
                                                <MenuItem key={x.sizeId} value={x.sizeId} name={x.sizeId} id="size">
                                                    {x.sizeName}
                                                </MenuItem>
                                            ))}
                                        </MSelect>
                                    </FormControl>
                                </Col>

                                <Col lg="6" md="6" xs="12">
                                    <FormControl className={classes.formControl}>
                                        <InputLabel id="demo-mutiple-chip-label">Color:</InputLabel>
                                        <MSelect
                                            labelId="demo-mutiple-chip-label"
                                            id="color"
                                            name="color"
                                            multiple
                                            value={inputField.color}
                                            onChange={handleChangeColor}
                                            onBlur={(e) => handleChangeInput(index, e, color)}
                                            input={<Input id="select-multiple-chip" />}
                                            renderValue={(selected) => 
                                                (
                                                <div className={classes.chips}>
                                                    {selected.map((value) => 
                                                    (
                                                        <Chip key={value} label={props.colorInfo.find((y)=> y.colorId == value).colorName} className={classes.chip} />
                                                    ))}
                                                </div>
                                            )
                                        }
                                            MenuProps={MenuProps}
                                        >
                                            {props.colorInfo.map((x) => (
                                                <MenuItem key={x.colorId} value={x.colorId} id="color">
                                                    {x.colorName}
                                                </MenuItem>
                                            ))}
                                        </MSelect>
                                    </FormControl>
                                </Col>

                                <Col>
                                    <IconButton
                                        onClick={() => handleAddFields()}
                                        style={{
                                            float: "right",
                                            color: "black",
                                            marginTop: "15px",
                                        }}
                                    >
                                        <AddIcon />
                                    </IconButton>
                                    <IconButton
                                        onClick={() => handleRemoveFields(index)}
                                        style={{ float: "right", color: "black", marginTop: "15px" }}
                                    >
                                        <RemoveIcon />
                                    </IconButton>
                                </Col>
                            </Row>
                        </div>
                    ))}

                    <Row>
                        <Col lg="12" md="12" xs="12">
                            <Form.Group className="mb-3">
                                <Form.Label><b>Choose Any 6 Pics:</b></Form.Label>

                                <ImageUploader
                                    withIcon={true}
                                    buttonText='Choose Front Pic'
                                    onChange={onDrop}
                                    imgExtension={['.jpg', '.gif', '.png', '.gif']}
                                    maxFileSize={999999999999999999999}
                                    singleImage={false}
                                    withPreview={true}
                                />

                            </Form.Group>
                        </Col>
                    </Row>


                    <div className="sendDiv">
                        <Button className="sendButton" onClick={() => sendData()}>Save</Button>
                    </div>
                </div>

            </div>

        </Container>

    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        getSizeCall: () => dispatch(getSizeCall()),
        getUOMCall: () => dispatch(getUOMCall()),
        getColorCall: () => dispatch(getColorCall()),
        getDivisionCall: () => dispatch(getDivisionCall()),
        getCustomerCall: () => dispatch(getCustomerCall()),
        getBrandCall: () => dispatch(getBrandCall()),
        getProductCall: () => dispatch(getProductCall()),
        getSubProductCall: () => dispatch(getSubProductCall()),
        getCatalogCall: () => dispatch(getCatalogCall()),

        getSubProductDetailCall: (data) => dispatch(getSubProductDetailCall(data)),
        addArticleCall: (data) => dispatch(addArticleCall(data)),
        getArticleCall: () => dispatch(getArticleCall()),
    };
};
const mapStateToProps = (state, ownProps) => ({
    sizeInfo: state.productionReducer.sizeInfo,
    colorInfo: state.productionReducer.colorInfo,
    uomInfo: state.productionReducer.uomInfo,
    divisionInfo: state.productionReducer.divisionInfo,
    customerInfo: state.productionReducer.customerInfo,
    brandInfo: state.productionReducer.brandInfo,
    productInfo: state.productionReducer.productInfo,
    catalogInfo: state.productionReducer.catalogInfo,

    subProductDetailInfo: state.AddArticleReducer.subProductDetailInfo,
    articleInfo: state.AddArticleReducer.articleInfo,
    isFetchingAddArticle: state.AddArticleReducer.isFetchingAddArticle,
    isNotAddingArticle: state.AddArticleReducer.isNotAddingArticle
});

export default connect(mapStateToProps, mapDispatchToProps)(AddAricles);