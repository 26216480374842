import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Container,
  Button,
  Alert,
  Tabs,
  Tab,
} from "react-bootstrap";
import "./add-levels.css";
import {
  getLevel1Data,
  getLevel2Data,
  getLevel3Data,
  AddLevel3Name,
  getLevelOneName,
} from "./accountsLevel.action";
import { connect } from "react-redux";
import { isEmpty, get, map, isNull, find, groupBy } from "lodash";
import Spinner from "react-bootstrap/Spinner";

import ReactExport from "react-export-excel";
import Select from "react-select";
import Swal from "sweetalert";
import PDFLevel3 from "./PDFLevel3";
import Level3Upload from "./Level3Upload";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Level3Tap from "./Level3Tap";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const AddLevel3 = (props) => {
  const [levelOneId, setLevelOneId] = useState();
  const [levelTwoId, setLevelTwoId] = useState();
  const [level1Id, setLevel1Id] = useState();
  const [level1Label, setLevel1Label] = useState();
  const [level2Id, setLevel2Id] = useState();
  const [level3, setLevel3Name] = useState();
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  const handleVisible = () => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: "Added successfully...",
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = () => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: "Something went wrong...",
      icon: "error",
      button: "Ok",
    });
  };

  function uploadLevel3() {
    props.AddLevel3Name(
      {
        levelOneId: String(level1Id),
        levelTwoId: String(level2Id),
        levelThreeName: level3,
      },
      handleVisible,
      handleDangerVisible
    );
  }

  const getLevel2List = props.level2List.map((x) => {
    let data = {
      value: x.levelTwoData.levelTwoId,
      label: x.levelTwoData.levelTwoName,
    };
    return data;
  });
  const handleChangeLevel2 = (selectedOption) => {
    setLevel2Id(selectedOption.value);
    // setLevel1Id(find(props.level2List, x => x.levelTwoData.levelTwoId == selectedOption.value).levelTwoData.levelOneId)
  };
  //

  // const handleChangeLevel1 = (selectedOption) => {
  //     setLevelTwoId(selectedOption.value);
  //     //setLevelOneId(find(props.level2List, x => x.levelTwoData.levelTwoId == selectedOption.value).levelTwoData.levelOneId)
  //     props.AddLevel1Name(selectedOption.value)
  // };
  const getLevel1List = props.level1List.map((x) => {
    let data = { value: x.levelOneId, label: x.name };
    return data;
  });
  const handleChangeLevel1 = (selectedOption) => {
    handleChangeLevel2(selectedOption);
    props.getLevelOneName(selectedOption.value);
    setLevel1Id(selectedOption.value);
    setLevel1Label(selectedOption.label);
  };
  // const [data, setdata1] = useState();
  const getLevelTwoList =
    !isEmpty(props.levelTow) &&
    props.levelTow.map((x) => {
      let data = {
        value: x.levelTwoData.levelTwoId,
        label: x.levelTwoData.levelTwoName,
      };
      return data;
    });
  // setdata1(getLevelTwoList);

  const level3Data = props.level3List;
  useEffect(() => {
    props.getLevel1Data();
    // props.getLevel3Data();
  }, []);
  return (
    <>
      {props.isFetchinglevel1List || props.isFetchingLevelTow ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {/* {props.isSavingLevel3Data ? "Saving" : "Loading"} */}
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <div>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>
                      <b> Select Level 1 </b>
                    </Form.Label>
                    <Select
                      value={{ label: level1Label }}
                      placeholder="Select Level 1..."
                      onChange={handleChangeLevel1}
                      options={getLevel1List}
                    />
                  </Form.Group>
                </Col>
              </Row>
              {/* <Row>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>
                                                    <b> Select Level 2 </b>
                                                </Form.Label>
                                                <Select
                                                 isDisabled={isEmpty(getLevelTwoList) || isEmpty(level1Label)}
                                                    placeholder="Select Level 2..."
                                                     onChange={handleChangeLevel2}
                                                      options={getLevelTwoList}
                                                      readOnly
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row> */}
              <Row>
                <Col lg="12" md="12" xs="12">
                  <Form.Group className="mb-3" controlId="garana">
                    <Form.Label>
                      <b>Enter Level 3 Name :</b>
                    </Form.Label>
                    {!level2Id ? (
                      <Form.Control
                        type="text"
                        placeholder="Level Name...."
                        readOnly
                      />
                    ) : (
                      <Form.Control
                        type="text"
                        id="garanaId"
                        placeholder="Level Name...."
                        onMouseLeave={(e) => setLevel3Name(e.target.value)}
                      />
                    )}
                  </Form.Group>
                </Col>
              </Row>

              <div className="sendDiv">
                {isEmpty(level2Id) && isEmpty(level3) ? (
                  <Button
                    disabled
                    style={{
                      marginLeft: "2%",
                      color: "white",
                      width: "20%",
                      backgroundColor: "black",
                      border: "1px solid black",
                    }}
                  >
                    Save
                  </Button>
                ) : (
                  <Button className="sendButton" onClick={uploadLevel3}>
                    Save
                  </Button>
                )}
              </div>
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLevel1Data: () => dispatch(getLevel1Data()),
    getLevel3Data: () => dispatch(getLevel3Data()),
    AddLevel3Name: (data, handleVisible, handleDangerVisible) =>
      dispatch(AddLevel3Name(data, handleVisible, handleDangerVisible)),
    getLevelOneName: (data) => dispatch(getLevelOneName(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  levelTow: state.accountsLevelReducer.levelTow,
  level1List: state.accountsLevelReducer.level1List,
  level2List: state.accountsLevelReducer.level2List,
  level3List: state.accountsLevelReducer.level3List,
  isFetchinglevel3List: state.accountsLevelReducer.isFetchinglevel3List,
  isAddingLevel3: state.accountsLevelReducer.isAddingLevel3,
  isNotAddLevel3: state.accountsLevelReducer.isNotAddLevel3,
  isFetchingLevelTow: state.accountsLevelReducer.isFetchingLevelTow,
  isFetchinglevel1List: state.accountsLevelReducer.isFetchinglevel1List,
  isSavingLevel3Data: state.accountsLevelReducer.isSavingLevel3Data,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddLevel3);
