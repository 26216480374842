import React from "react";
import { Card, Tabs, Tab, Button } from "react-bootstrap";
import { connect } from "react-redux";
import Level5 from "./Level5";
import Level5Upload from "./Level5Upload";
import Level5Report from "./Level5Report";
import { Link } from "react-router-dom";
import { isNull } from "lodash";

const Level5Tabs = (props) => {
  const permissions = JSON.parse(localStorage.getItem("permissions"));

  return (
    <>
      <Tabs
        defaultActiveKey={
          !isNull(permissions) && permissions.some((p) => p["sub_id"] === 41)
            ? "Level 5 Data"
            : !isNull(permissions) &&
              permissions.some((p) => p["sub_id"] === 42)
            ? "Level 5 Details"
            : !isNull(permissions) &&
              permissions.some((p) => p["sub_id"] === 43)
            ? "Level 4 Upload"
            : !isNull(permissions) &&
              permissions.some((p) => p["sub_id"] === 44)
            ? "Level 4 Report"
            : ""
        }
        id="level5Data"
        className="mb-3"
        unmountOnExit={true}
      >
        <Tab
          eventKey="Level 5 Data"
          title="Add Level 4"
          unmountOnExit={true}
          disabled={
            !isNull(permissions) && permissions.some((p) => p["sub_id"] === 41)
              ? false
              : true
          }
        >
          <Level5 />
        </Tab>

        <Tab
          eventKey="Level 4 Details"
          title="Level 4 Details"
          unmountOnExit={true}
          disabled={
            !isNull(permissions) && permissions.some((p) => p["sub_id"] === 42)
              ? false
              : true
          }
        >
          <div className="sendDiv">
            <Link to="/user/level5-details">
              {" "}
              <Button className="sendButton">View Details</Button>
            </Link>
          </div>
        </Tab>

        <Tab
          eventKey="Level 4 Upload"
          title="Level 4 Upload"
          unmountOnExit={true}
          disabled={
            !isNull(permissions) && permissions.some((p) => p["sub_id"] === 43)
              ? false
              : true
          }
        >
          <Level5Upload />
        </Tab>

        <Tab
          eventKey="Level 4 Report"
          title="Level 4 Report"
          unmountOnExit={true}
          disabled={
            !isNull(permissions) && permissions.some((p) => p["sub_id"] === 44)
              ? false
              : true
          }
        >
          <Level5Report />
        </Tab>
      </Tabs>
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
const mapStateToProps = (state, ownProps) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Level5Tabs);
